// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Stylex from "@stylexjs/stylex";

var styles = Stylex.create({
      root: {
        position: "relative"
      },
      offset: {
        position: "absolute"
      }
    });

function styleProps(width, height) {
  return Stylex.props(styles.root, {
              height: String(height) + "px",
              width: String(width) + "px"
            });
}

function offsetStyleProps(top, right, bottom, left) {
  var tmp = {};
  var tmp$1 = Belt_Option.map(bottom, (function (bottom) {
          return String(bottom) + "px";
        }));
  if (tmp$1 !== undefined) {
    tmp.bottom = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Belt_Option.map(left, (function (left) {
          return String(left) + "px";
        }));
  if (tmp$2 !== undefined) {
    tmp.left = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = Belt_Option.map(right, (function (right) {
          return String(right) + "px";
        }));
  if (tmp$3 !== undefined) {
    tmp.right = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = Belt_Option.map(top, (function (top) {
          return String(top) + "px";
        }));
  if (tmp$4 !== undefined) {
    tmp.top = Caml_option.valFromOption(tmp$4);
  }
  return Stylex.props(styles.offset, tmp);
}

function Offset(Props) {
  var children = Props.children;
  var top = Props.top;
  var right = Props.right;
  var bottom = Props.bottom;
  var left = Props.left;
  var widthOpt = Props.width;
  var heightOpt = Props.height;
  var width = widthOpt !== undefined ? widthOpt : 0;
  var height = heightOpt !== undefined ? heightOpt : 0;
  var match = styleProps(width, height);
  var match$1 = offsetStyleProps(top, right, bottom, left);
  var tmp = {};
  if (match.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match.className);
  }
  if (match.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match.style);
  }
  var tmp$1 = {};
  if (match$1.className !== undefined) {
    tmp$1.className = Caml_option.valFromOption(match$1.className);
  }
  if (match$1.style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(match$1.style);
  }
  return React.createElement("div", tmp, React.createElement("div", tmp$1, children));
}

var make = React.memo(Offset);

export {
  make ,
}
/* styles Not a pure module */
