// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../images-and-icons/Icon.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as React from "react";
import * as Tooltip from "./Tooltip.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactDomElement from "../../primitives/ReactDomElement.bs.js";
import * as Stylex from "@stylexjs/stylex";
import * as ReactUpdateDebounced from "../../primitives/ReactUpdateDebounced.bs.js";

var styles = Stylex.create({
      root: {
        cursor: "help",
        display: "contents"
      }
    });

function TooltipIcon(Props) {
  var children = Props.children;
  var variationOpt = Props.variation;
  var arrowedOpt = Props.arrowed;
  var placementOpt = Props.placement;
  var altTriggerRef = Props.altTriggerRef;
  var offsetOpt = Props.offset;
  var crossOffsetOpt = Props.crossOffset;
  var variation = variationOpt !== undefined ? variationOpt : "info";
  var arrowed = arrowedOpt !== undefined ? arrowedOpt : false;
  var placement = placementOpt !== undefined ? placementOpt : "bottom start";
  var offset = offsetOpt !== undefined ? offsetOpt : 2;
  var crossOffset = crossOffsetOpt !== undefined ? crossOffsetOpt : -1;
  var match = Hover.use(altTriggerRef, undefined);
  var hovered = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setOpened = match$1[1];
  var debouncedHovered = ReactUpdateDebounced.use(hovered, hovered ? 75 : 100);
  var opened = Belt_Option.mapWithDefault(altTriggerRef, match$1[0], (function (param) {
          return debouncedHovered;
        }));
  var onOpenChange = function (opened) {
    if (Belt_Option.isNone(altTriggerRef)) {
      return Curry._1(setOpened, (function (param) {
                    return opened;
                  }));
    }
    
  };
  React.useEffect((function () {
          var domElement = Belt_Option.flatMap(altTriggerRef, (function (ref) {
                  return Caml_option.nullable_to_opt(ref.current);
                }));
          if (domElement !== undefined) {
            ReactDomElement.setDomElementStyleProp(Caml_option.valFromOption(domElement), "cursor", "help");
          }
          
        }), [altTriggerRef]);
  var match$2 = Stylex.props(styles.root);
  var tmp = {
    ref: match[0]
  };
  if (match$2.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match$2.className);
  }
  if (match$2.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match$2.style);
  }
  var match$3 = debouncedHovered || opened;
  return React.createElement(Tooltip.make, {
              children: React.createElement("div", tmp, React.createElement(Icon.make, {
                        name: variation === "alert" ? "alert_tip" : "info_tip",
                        fill: variation === "alert" ? (
                            match$3 ? "#25243a" : "#535165"
                          ) : (
                            match$3 ? "#25243a" : "#a2a1b0"
                          ),
                        size: 13
                      })),
              arrowed: arrowed,
              placement: placement,
              offset: offset,
              crossOffset: crossOffset,
              content: children,
              delay: 75,
              closeDelay: 100,
              opened: opened,
              onOpenChange: onOpenChange
            });
}

var make = TooltipIcon;

export {
  make ,
}
/* styles Not a pure module */
