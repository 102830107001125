// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../core/Env.bs.js";
import * as $$Intl from "../primitives/Intl.bs.js";
import * as Json from "../primitives/Json.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Request from "../core/Request.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PaymentMethod from "./PaymentMethod.bs.js";
import * as FiscalYearOpeningMonth from "./FiscalYearOpeningMonth.bs.js";

function decodeFromJson(json) {
  var dict = Json.decodeDict(json);
  if (dict === undefined) {
    return ;
  }
  var dict$1 = Caml_option.valFromOption(dict);
  var maybePaymentMethod = Belt_Option.flatMap(Js_dict.get(dict$1, "paymentMethod"), PaymentMethod.decodeFromJson);
  var maybeAccountNumber = Json.decodeDictFieldString(dict$1, "accountNumber");
  var maybeAccountLabel = Json.decodeDictFieldString(dict$1, "label");
  var isaComptaJournalCode = Json.decodeDictFieldString(dict$1, "isaComptaJournalCode");
  if (maybePaymentMethod !== undefined && maybeAccountNumber !== undefined && maybeAccountLabel !== undefined) {
    return {
            paymentMethod: maybePaymentMethod,
            accountNumber: maybeAccountNumber,
            accountLabel: maybeAccountLabel,
            isaComptaJournalCode: isaComptaJournalCode
          };
  }
  
}

function encodeToJson(value) {
  var dict = Js_dict.fromArray([
        [
          "paymentMethod",
          PaymentMethod.encodeToJson(value.paymentMethod)
        ],
        [
          "accountNumber",
          Json.encodeString(value.accountNumber)
        ],
        [
          "label",
          Json.encodeString(value.accountLabel)
        ]
      ]);
  var isaComptaJournalCode = value.isaComptaJournalCode;
  dict["isaComptaJournalCode"] = isaComptaJournalCode !== undefined ? Json.encodeString(isaComptaJournalCode) : Json.encodedNull;
  return Json.encodeDict(dict);
}

var PaymentAccount = {
  decodeFromJson: decodeFromJson,
  encodeToJson: encodeToJson
};

function autocompleteBasicPaymentsAccounts(vendor) {
  return Belt_Array.map([
              [
                /* Cash */3,
                "5810000000",
                "R1"
              ],
              [
                /* Cheque */4,
                "5820000000",
                "R2"
              ],
              [
                /* DebitCard */8,
                "5830000000",
                "R3"
              ],
              [
                /* BankTransfer */1,
                "5860000000",
                "R6"
              ]
            ], (function (param) {
                var paymentMethod = param[0];
                return {
                        paymentMethod: paymentMethod,
                        accountNumber: param[1],
                        accountLabel: $$Intl.template($$Intl.t("Payment {{paymentMethod}}"), {
                              paymentMethod: PaymentMethod.toLabel(paymentMethod)
                            }, undefined),
                        isaComptaJournalCode: vendor ? param[2] : undefined
                      };
              }));
}

function fillWithStandard(taxRate, taxId) {
  if (taxRate < 5.5) {
    if (taxRate === 0) {
      return {
              taxId: taxId,
              isaComptaCode: "Y",
              productsSold: [
                "70700000",
                "Ventes marchandises TVA 0%"
              ],
              deductibleTax: undefined
            };
    }
    if (taxRate === 2.1) {
      return {
              taxId: taxId,
              isaComptaCode: "V3",
              productsSold: [
                "70780000",
                "Ventes marchandises TVA 2.1%"
              ],
              deductibleTax: [
                "4457180000",
                "TVA collectée 2.1%"
              ]
            };
    }
    
  } else {
    if (taxRate === 5.5) {
      return {
              taxId: taxId,
              isaComptaCode: "V2",
              productsSold: [
                "70700000",
                "Ventes marchandises TVA 5.5%"
              ],
              deductibleTax: [
                "4457100000",
                "TVA collectée 5.5%"
              ]
            };
    }
    if (taxRate === 10) {
      return {
              taxId: taxId,
              isaComptaCode: "V6",
              productsSold: [
                "70730100",
                "Ventes marchandises TVA 10%"
              ],
              deductibleTax: [
                "4457130100",
                "TVA collectée 10%"
              ]
            };
    }
    if (taxRate === 20) {
      return {
              taxId: taxId,
              isaComptaCode: "V5",
              productsSold: [
                "70720100",
                "Ventes marchandises TVA 20%"
              ],
              deductibleTax: [
                "4457120100",
                "TVA collectée 20%"
              ]
            };
    }
    
  }
  return {
          taxId: taxId,
          isaComptaCode: undefined,
          productsSold: [
            "",
            ""
          ],
          deductibleTax: [
            "",
            ""
          ]
        };
}

function decodeFromJson$1(json) {
  var dict = Json.decodeDict(json);
  if (dict === undefined) {
    return ;
  }
  var dict$1 = Caml_option.valFromOption(dict);
  var taxId = Json.decodeDictFieldString(dict$1, "taxId");
  var isaComptaCode = Json.decodeDictFieldString(dict$1, "isaComptaCode");
  var productsSold = Belt_Option.flatMap(Belt_Option.flatMap(Js_dict.get(dict$1, "productsSold"), Json.decodeDict), (function (value) {
          var accountNumber = Json.decodeDictFieldString(value, "accountNumber");
          var label = Json.decodeDictFieldString(value, "label");
          if (accountNumber !== undefined && label !== undefined) {
            return [
                    accountNumber,
                    label
                  ];
          }
          
        }));
  var deductibleTax = Belt_Option.flatMap(Belt_Option.flatMap(Js_dict.get(dict$1, "deductibleTax"), Json.decodeDict), (function (value) {
          var accountNumber = Json.decodeDictFieldString(value, "accountNumber");
          var label = Json.decodeDictFieldString(value, "label");
          if (accountNumber !== undefined && label !== undefined) {
            return [
                    accountNumber,
                    label
                  ];
          }
          
        }));
  if (taxId !== undefined && productsSold !== undefined) {
    return {
            taxId: taxId,
            isaComptaCode: isaComptaCode,
            productsSold: productsSold,
            deductibleTax: deductibleTax
          };
  }
  
}

function encodeToJson$1(param) {
  var deductibleTax = param.deductibleTax;
  var productsSold = param.productsSold;
  var isaComptaCode = param.isaComptaCode;
  return Json.encodeDict(Js_dict.fromArray([
                  [
                    "taxId",
                    Json.encodeString(param.taxId)
                  ],
                  [
                    "isaComptaCode",
                    isaComptaCode !== undefined ? Json.encodeString(isaComptaCode) : Json.encodedNull
                  ],
                  [
                    "productsSold",
                    Json.encodeDict(Js_dict.fromArray([
                              [
                                "accountNumber",
                                Json.encodeString(productsSold[0])
                              ],
                              [
                                "label",
                                Json.encodeString(productsSold[1])
                              ]
                            ]))
                  ],
                  [
                    "deductibleTax",
                    deductibleTax !== undefined ? Json.encodeDict(Js_dict.fromArray([
                                [
                                  "accountNumber",
                                  Json.encodeString(deductibleTax[0])
                                ],
                                [
                                  "label",
                                  Json.encodeString(deductibleTax[1])
                                ]
                              ])) : Json.encodedNull
                  ]
                ]));
}

var TaxAccount = {
  fillWithStandard: fillWithStandard,
  decodeFromJson: decodeFromJson$1,
  encodeToJson: encodeToJson$1
};

function endpoint(shopId) {
  return Env.gatewayUrl(undefined) + "/accounting-export-configurations/" + shopId;
}

function decodeInvalidRequestFailure(error) {
  if (error.kind === "NotFoundAccountingExportConfiguration") {
    return /* NotFoundAccountingExportConfigurationFailure */0;
  } else {
    return /* UnknownServerFailure */1;
  }
}

function decodeRequestError(requestError) {
  var tmp;
  if (typeof requestError === "number" || requestError.TAG !== /* InvalidRequestFailures */2) {
    tmp = {
      TAG: /* Error */1,
      _0: undefined
    };
  } else {
    var firstFailure = Belt_Option.map(Belt_Array.get(requestError._0, 0), decodeInvalidRequestFailure);
    tmp = firstFailure !== undefined && !firstFailure ? ({
          TAG: /* Ok */0,
          _0: undefined
        }) : ({
          TAG: /* Error */1,
          _0: undefined
        });
  }
  return Future.value(tmp);
}

function decodeResponse(response) {
  return Belt_Option.flatMap(Json.decodeDict(response), (function (dict) {
                var isaComptaAccountNumber = Json.decodeDictFieldString(dict, "isaComptaAccountNumber");
                var fiscalYearOpeningMonth = Belt_Option.flatMap(Js_dict.get(dict, "fiscalYearOpeningMonth"), FiscalYearOpeningMonth.decodeFromJson);
                var taxesAccounts = Belt_Option.map(Json.decodeDictFieldArray(dict, "accountingExportTaxAccounts"), (function (value) {
                        return Belt_Array.keepMap(value, decodeFromJson$1);
                      }));
                var shopId = Json.decodeDictFieldString(dict, "shopId");
                var breakdownOfConsumerSalesByCashRegisterDailyReport = Json.decodeDictFieldBoolean(dict, "breakdownOfConsumerSalesByCashRegisterDailyReport");
                var maybePaymentsAccounts = Belt_Option.map(Json.decodeDictFieldArray(dict, "accountingExportPaymentAccounts"), (function (value) {
                        return Belt_Array.keepMap(value, decodeFromJson);
                      }));
                var maybePaymentsAccounts$1 = maybePaymentsAccounts !== undefined && maybePaymentsAccounts.length !== 0 ? maybePaymentsAccounts : undefined;
                if (shopId !== undefined && fiscalYearOpeningMonth !== undefined && taxesAccounts !== undefined && breakdownOfConsumerSalesByCashRegisterDailyReport !== undefined && taxesAccounts.length !== 0) {
                  return {
                          shopId: shopId,
                          isaComptaAccountNumber: isaComptaAccountNumber,
                          fiscalYearOpeningMonth: fiscalYearOpeningMonth,
                          taxesAccounts: taxesAccounts,
                          maybePaymentsAccounts: maybePaymentsAccounts$1,
                          breakdownOfConsumerSalesByCashRegisterDailyReport: breakdownOfConsumerSalesByCashRegisterDailyReport
                        };
                }
                
              }));
}

function request(shopId) {
  return Future.flatMapError(Future.mapOk($$Request.make(undefined, undefined, undefined, undefined, endpoint(shopId)), undefined, decodeResponse), undefined, decodeRequestError);
}

export {
  PaymentAccount ,
  autocompleteBasicPaymentsAccounts ,
  TaxAccount ,
  endpoint ,
  decodeInvalidRequestFailure ,
  decodeRequestError ,
  decodeResponse ,
  request ,
}
/* Intl Not a pure module */
