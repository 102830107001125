// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as Title from "../../resources/typography/Title.bs.js";
import * as React from "react";
import * as Spinner from "../../resources/feedback-indicators/Spinner.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var styles = $$StyleSheet.create({
      root: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(-100)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function LoadingPage(Props) {
  var textOpt = Props.text;
  var text = textOpt !== undefined ? textOpt : $$Intl.t("Loading...");
  return React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.root), undefined, undefined, undefined), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Spinner.make, {
                              size: 40
                            })), undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Stack.make, {
                  children: null
                }, React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Title.make, {
                                  children: text,
                                  level: 3,
                                  align: "center"
                                })), undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                  children: $$Intl.t("Please wait."),
                                  align: "center",
                                  variation: "normal"
                                })), "xhuge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))));
}

var make = LoadingPage;

export {
  styles ,
  make ,
}
/* styles Not a pure module */
