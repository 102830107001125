// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "./Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Touchable from "./Touchable.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactDomElement from "./ReactDomElement.bs.js";

function extensionFromType(_type) {
  if (_type === "spreadsheetopen") {
    return ".ods";
  } else if (_type === "excel") {
    return ".xls";
  } else if (_type === "csv") {
    return ".csv";
  } else {
    return ".xlsx";
  }
}

function mimeFromType(type) {
  if (type === "spreadsheetopen") {
    return "application/vnd.oasis.opendocument.spreadsheet";
  } else if (type === "excel") {
    return "application/vnd.ms-excel";
  } else if (type === "csv") {
    return "text/csv";
  } else {
    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  }
}

function typeFromMime(mime) {
  switch (mime) {
    case "application/vnd.ms-excel" :
        return {
                TAG: /* Ok */0,
                _0: "excel"
              };
    case "application/vnd.oasis.opendocument.spreadsheet" :
        return {
                TAG: /* Ok */0,
                _0: "spreadsheetopen"
              };
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" :
        return {
                TAG: /* Ok */0,
                _0: "spreadsheetml"
              };
    case "text/csv" :
        return {
                TAG: /* Ok */0,
                _0: "csv"
              };
    default:
      return {
              TAG: /* Error */1,
              _0: undefined
            };
  }
}

function messageFromError(type) {
  if (typeof type === "number") {
    if (type !== 0) {
      return $$Intl.t("The imported file is too heavy.");
    } else {
      return $$Intl.t("Couldn't import file.");
    }
  }
  var fileTypes = type._0;
  if (fileTypes.length > 1) {
    return $$Intl.template($$Intl.t("The imported file must be of the following types: {{types}}."), {
                types: Belt_Array.reduce(fileTypes, "", (function (acc, fileType) {
                        return acc + (
                                acc.length > 0 ? ", " : ""
                              ) + extensionFromType(fileType);
                      }))
              }, undefined);
  } else {
    return $$Intl.template($$Intl.t("The imported file must be of type {{type}}."), {
                type: extensionFromType(Belt_Array.getExn(fileTypes, 0))
              }, undefined);
  }
}

var extensionFromFilename = (filename => filename.split('.').pop());

function isFileBadType(file, types) {
  return Belt_Array.every(types, (function (fileType) {
                return mimeFromType(fileType) !== file.type;
              }));
}

function isFileBadExtension(file, types) {
  if (file.type !== "") {
    return true;
  }
  var extension = extensionFromFilename(file.name);
  return Belt_Array.every(types, (function (fileType) {
                return extensionFromType(fileType) !== "." + extension;
              }));
}

function FilePicker(Props) {
  var children = Props.children;
  var types = Props.types;
  var maxSizeMbOpt = Props.maxSizeMb;
  var disabledOpt = Props.disabled;
  var onChange = Props.onChange;
  var onError = Props.onError;
  var maxSizeMb = maxSizeMbOpt !== undefined ? maxSizeMbOpt : 1;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var ref = React.useRef(null);
  var handlePicking = React.useCallback((function ($$event) {
          var files = $$event.currentTarget.files;
          var match = Belt_Array.get(files, 0);
          if (match === undefined) {
            return Curry._1(onError, messageFromError(/* FileNotFound */0));
          }
          var file = Caml_option.valFromOption(match);
          if (types !== undefined && isFileBadType(file, types) && isFileBadExtension(file, types)) {
            return Curry._1(onError, messageFromError(/* FileBadExtension */{
                            _0: types
                          }));
          }
          if (file.size > maxSizeMb * 1048576) {
            return Curry._1(onError, messageFromError(/* FileTooHeavy */1));
          } else {
            return Curry._1(onChange, file);
          }
        }), [types]);
  var onPress = React.useCallback((function (param) {
          Belt_Option.forEach(Belt_Option.flatMap(ReactDomElement.fromRef(ref), (function (prim) {
                      return prim;
                    })), (function (prim) {
                  prim.click();
                }));
        }), [ref]);
  var accept = React.useMemo((function () {
          if (types !== undefined) {
            return Belt_Array.reduce(types, "", (function (acc, fileType) {
                          return acc + mimeFromType(fileType) + ", " + extensionFromType(fileType) + ", ";
                        }));
          }
          
        }), [types]);
  var tmp = {
    ref: ref,
    style: {
      display: "none"
    },
    type: "file",
    onChange: handlePicking
  };
  if (accept !== undefined) {
    tmp.accept = Caml_option.valFromOption(accept);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("input", tmp), React.createElement(Touchable.make, Touchable.makeProps(children, undefined, disabled, undefined, undefined, undefined, onPress, undefined, undefined, undefined)));
}

var make = React.memo(FilePicker);

export {
  isFileBadType ,
  isFileBadExtension ,
  messageFromError ,
  typeFromMime ,
  extensionFromType ,
  make ,
}
/* make Not a pure module */
