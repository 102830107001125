// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CatalogVariantStockThresholdFormInputs from "./CatalogVariantStockThresholdFormInputs.bs.js";
import * as CatalogVariantStockThresholdFormContainer from "./CatalogVariantStockThresholdFormContainer.bs.js";

function CatalogVariantStockThresholdFormCard(Props) {
  var variantId = Props.variantId;
  var maxStockThreshold = Props.maxStockThreshold;
  var minStockThreshold = Props.minStockThreshold;
  var stockOrderTriggerThreshold = Props.stockOrderTriggerThreshold;
  return React.createElement(CatalogVariantStockThresholdFormContainer.make, {
              children: React.createElement(Card.make, {
                    children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(CatalogVariantStockThresholdFormInputs.make, {})), undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                    title: $$Intl.t("Stock control")
                  }),
              variantId: variantId,
              maxStockThreshold: maxStockThreshold,
              minStockThreshold: minStockThreshold,
              stockOrderTriggerThreshold: stockOrderTriggerThreshold
            });
}

var make = React.memo(CatalogVariantStockThresholdFormCard);

export {
  make ,
}
/* make Not a pure module */
