// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Form from "../../bundles/Form/Form.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";

var Tax = {};

function get(values, field) {
  switch (field) {
    case /* Name */0 :
        return values.name;
    case /* Kind */1 :
        return values.kind;
    case /* Color */2 :
        return values.color;
    case /* Tax */3 :
        return values.tax;
    case /* CategoryId */4 :
        return values.categoryId;
    case /* Producer */5 :
        return values.producer;
    case /* Family */6 :
        return values.family;
    case /* Designation */7 :
        return values.designation;
    case /* Country */8 :
        return values.country;
    case /* Region */9 :
        return values.region;
    case /* BeerType */10 :
        return values.beerType;
    case /* WineType */11 :
        return values.wineType;
    case /* WhiteWineType */12 :
        return values.whiteWineType;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* Name */0 :
        return {
                name: value,
                kind: values.kind,
                color: values.color,
                tax: values.tax,
                categoryId: values.categoryId,
                producer: values.producer,
                family: values.family,
                designation: values.designation,
                country: values.country,
                region: values.region,
                beerType: values.beerType,
                wineType: values.wineType,
                whiteWineType: values.whiteWineType
              };
    case /* Kind */1 :
        return {
                name: values.name,
                kind: value,
                color: values.color,
                tax: values.tax,
                categoryId: values.categoryId,
                producer: values.producer,
                family: values.family,
                designation: values.designation,
                country: values.country,
                region: values.region,
                beerType: values.beerType,
                wineType: values.wineType,
                whiteWineType: values.whiteWineType
              };
    case /* Color */2 :
        return {
                name: values.name,
                kind: values.kind,
                color: value,
                tax: values.tax,
                categoryId: values.categoryId,
                producer: values.producer,
                family: values.family,
                designation: values.designation,
                country: values.country,
                region: values.region,
                beerType: values.beerType,
                wineType: values.wineType,
                whiteWineType: values.whiteWineType
              };
    case /* Tax */3 :
        return {
                name: values.name,
                kind: values.kind,
                color: values.color,
                tax: value,
                categoryId: values.categoryId,
                producer: values.producer,
                family: values.family,
                designation: values.designation,
                country: values.country,
                region: values.region,
                beerType: values.beerType,
                wineType: values.wineType,
                whiteWineType: values.whiteWineType
              };
    case /* CategoryId */4 :
        return {
                name: values.name,
                kind: values.kind,
                color: values.color,
                tax: values.tax,
                categoryId: value,
                producer: values.producer,
                family: values.family,
                designation: values.designation,
                country: values.country,
                region: values.region,
                beerType: values.beerType,
                wineType: values.wineType,
                whiteWineType: values.whiteWineType
              };
    case /* Producer */5 :
        return {
                name: values.name,
                kind: values.kind,
                color: values.color,
                tax: values.tax,
                categoryId: values.categoryId,
                producer: value,
                family: values.family,
                designation: values.designation,
                country: values.country,
                region: values.region,
                beerType: values.beerType,
                wineType: values.wineType,
                whiteWineType: values.whiteWineType
              };
    case /* Family */6 :
        return {
                name: values.name,
                kind: values.kind,
                color: values.color,
                tax: values.tax,
                categoryId: values.categoryId,
                producer: values.producer,
                family: value,
                designation: values.designation,
                country: values.country,
                region: values.region,
                beerType: values.beerType,
                wineType: values.wineType,
                whiteWineType: values.whiteWineType
              };
    case /* Designation */7 :
        return {
                name: values.name,
                kind: values.kind,
                color: values.color,
                tax: values.tax,
                categoryId: values.categoryId,
                producer: values.producer,
                family: values.family,
                designation: value,
                country: values.country,
                region: values.region,
                beerType: values.beerType,
                wineType: values.wineType,
                whiteWineType: values.whiteWineType
              };
    case /* Country */8 :
        return {
                name: values.name,
                kind: values.kind,
                color: values.color,
                tax: values.tax,
                categoryId: values.categoryId,
                producer: values.producer,
                family: values.family,
                designation: values.designation,
                country: value,
                region: values.region,
                beerType: values.beerType,
                wineType: values.wineType,
                whiteWineType: values.whiteWineType
              };
    case /* Region */9 :
        return {
                name: values.name,
                kind: values.kind,
                color: values.color,
                tax: values.tax,
                categoryId: values.categoryId,
                producer: values.producer,
                family: values.family,
                designation: values.designation,
                country: values.country,
                region: value,
                beerType: values.beerType,
                wineType: values.wineType,
                whiteWineType: values.whiteWineType
              };
    case /* BeerType */10 :
        return {
                name: values.name,
                kind: values.kind,
                color: values.color,
                tax: values.tax,
                categoryId: values.categoryId,
                producer: values.producer,
                family: values.family,
                designation: values.designation,
                country: values.country,
                region: values.region,
                beerType: value,
                wineType: values.wineType,
                whiteWineType: values.whiteWineType
              };
    case /* WineType */11 :
        return {
                name: values.name,
                kind: values.kind,
                color: values.color,
                tax: values.tax,
                categoryId: values.categoryId,
                producer: values.producer,
                family: values.family,
                designation: values.designation,
                country: values.country,
                region: values.region,
                beerType: values.beerType,
                wineType: value,
                whiteWineType: values.whiteWineType
              };
    case /* WhiteWineType */12 :
        return {
                name: values.name,
                kind: values.kind,
                color: values.color,
                tax: values.tax,
                categoryId: values.categoryId,
                producer: values.producer,
                family: values.family,
                designation: values.designation,
                country: values.country,
                region: values.region,
                beerType: values.beerType,
                wineType: values.wineType,
                whiteWineType: value
              };
    
  }
}

var Lenses = {
  get: get,
  set: set
};

var include = Form.Make(Lenses);

var schema = [
  {
    TAG: /* StringNotEmpty */1,
    _0: /* Name */0
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* Country */8
  },
  {
    TAG: /* Custom */7,
    _0: /* Designation */7,
    _1: (function (param) {
        if (param.designation === "" && param.kind === "WINE") {
          return {
                  TAG: /* Error */1,
                  _0: $$Intl.t("Please fulfill this field.")
                };
        } else {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        }
      })
  },
  {
    TAG: /* Custom */7,
    _0: /* Region */9,
    _1: (function (param) {
        if (param.region === "" && param.kind === "WINE") {
          return {
                  TAG: /* Error */1,
                  _0: $$Intl.t("Please fulfill this field.")
                };
        } else {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        }
      })
  },
  {
    TAG: /* Custom */7,
    _0: /* Color */2,
    _1: (function (param) {
        var color = param.color;
        var kind = param.kind;
        if (color !== undefined) {
          var exit = 0;
          if (color === "ROSE" || color === "RED" || color === "ORANGE" || color === "WHITE") {
            if (kind === "WINE") {
              return {
                      TAG: /* Ok */0,
                      _0: undefined
                    };
            }
            exit = 2;
          } else {
            exit = 2;
          }
          if (exit === 2 && (color === "BLOND" || color === "BLACK" || color === "DARK" || color === "AMBER" || color === "RED" || color === "WHITE") && kind === "BEER") {
            return {
                    TAG: /* Ok */0,
                    _0: undefined
                  };
          }
          
        }
        if (kind === "SPIRITUOUS" || kind === "SIMPLE") {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: $$Intl.t("Please pick a color.")
                };
        }
      })
  },
  {
    TAG: /* Custom */7,
    _0: /* WineType */11,
    _1: (function (param) {
        if (param.wineType !== undefined || param.kind !== "WINE") {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: $$Intl.t("Please pick a wine type.")
                };
        }
      })
  },
  {
    TAG: /* Custom */7,
    _0: /* Family */6,
    _1: (function (param) {
        if (param.family === "" && param.kind === "SPIRITUOUS") {
          return {
                  TAG: /* Error */1,
                  _0: $$Intl.t("Please fulfill this field.")
                };
        } else {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        }
      })
  },
  {
    TAG: /* Custom */7,
    _0: /* BeerType */10,
    _1: (function (param) {
        if (param.beerType === "" && param.kind === "BEER") {
          return {
                  TAG: /* Error */1,
                  _0: $$Intl.t("Please fulfill this field.")
                };
        } else {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        }
      })
  }
];

var Core = include.Core;

var Schema = include.Schema;

var FormLegacyProvider = include.FormLegacyProvider;

var FormProvider = include.FormProvider;

var InputText = include.InputText;

var InputSuggestionComboBox = include.InputSuggestionComboBox;

var InputSelect = include.InputSelect;

var InputTextArea = include.InputTextArea;

var InputPassword = include.InputPassword;

var InputNumber = include.InputNumber;

var InputOptionalNumber = include.InputOptionalNumber;

var InputSegmentedControls = include.InputSegmentedControls;

var InputRadioGroup = include.InputRadioGroup;

var InputPhone = include.InputPhone;

var InputCheckbox = include.InputCheckbox;

var InputToggleSwitch = include.InputToggleSwitch;

var InputDate = include.InputDate;

var InputDateRange = include.InputDateRange;

var SubmitButton = include.SubmitButton;

var CancelButton = include.CancelButton;

var ResetButton = include.ResetButton;

var ControlEnterKey = include.ControlEnterKey;

var AutoSave = include.AutoSave;

var useFormPropState = include.useFormPropState;

var useField = include.useField;

var useFormState = include.useFormState;

var useFormDispatch = include.useFormDispatch;

var validate = include.validate;

export {
  Tax ,
  Lenses ,
  Core ,
  Schema ,
  FormLegacyProvider ,
  FormProvider ,
  InputText ,
  InputSuggestionComboBox ,
  InputSelect ,
  InputTextArea ,
  InputPassword ,
  InputNumber ,
  InputOptionalNumber ,
  InputSegmentedControls ,
  InputRadioGroup ,
  InputPhone ,
  InputCheckbox ,
  InputToggleSwitch ,
  InputDate ,
  InputDateRange ,
  SubmitButton ,
  CancelButton ,
  ResetButton ,
  ControlEnterKey ,
  AutoSave ,
  useFormPropState ,
  useField ,
  useFormState ,
  useFormDispatch ,
  validate ,
  schema ,
}
/* include Not a pure module */
