// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";

var options = [
  "MRS",
  "MR",
  "NEUTRAL"
];

function toShortLabel(civility) {
  if (civility === "MR") {
    return $$Intl.t("Mr.");
  } else if (civility === "MRS") {
    return $$Intl.t("Mrs.");
  } else {
    return "Mx";
  }
}

function toLongLabel(civility) {
  if (civility === "MR") {
    return $$Intl.t("Mister");
  } else if (civility === "MRS") {
    return $$Intl.t("Madam");
  } else {
    return $$Intl.t("Neutral");
  }
}

export {
  options ,
  toShortLabel ,
  toLongLabel ,
}
/* Intl Not a pure module */
