// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Env from "../../core/Env.bs.js";
import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Icon from "../../resources/images-and-icons/Icon.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Json from "../../primitives/Json.bs.js";
import * as Menu from "../../resources/navigation/Menu.bs.js";
import * as Badge from "../../resources/feedback-indicators/Badge.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Group from "../../resources/layout-and-structure/Group.bs.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as Modal from "../../resources/overlays/Modal.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Banner from "../../resources/feedback-indicators/Banner.bs.js";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Dialog from "../../resources/overlays/Dialog.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Select from "../../resources/selection-and-input/Select.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Popover from "../../resources/overlays/Popover.bs.js";
import * as $$Request from "../../core/Request.bs.js";
import * as Spinner from "../../resources/feedback-indicators/Spinner.bs.js";
import * as Tooltip from "../../resources/overlays/Tooltip.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as MenuItem from "../../resources/navigation/MenuItem.bs.js";
import * as JsonCodec from "../../primitives/JsonCodec.bs.js";
import * as Separator from "../../resources/layout-and-structure/Separator.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as EmptyState from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as HelpCenter from "../../core/HelpCenter.bs.js";
import * as TextAction from "../../resources/actions/TextAction.bs.js";
import * as AsyncResult from "../../primitives/AsyncResult.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DraftBanner from "../../resources/feedback-indicators/DraftBanner.bs.js";
import * as CatalogLabel from "../../modules/Catalog/CatalogLabel.bs.js";
import * as Illustration from "../../resources/images-and-icons/Illustration.bs.js";
import * as LegacyRouter from "../../core/LegacyRouter.bs.js";
import * as ResourceList from "../../core/ResourceList.bs.js";
import * as SpinnerModal from "../../resources/feedback-indicators/SpinnerModal.bs.js";
import * as ApolloHelpers from "../../helpers/ApolloHelpers.bs.js";
import * as CatalogRoutes from "./CatalogRoutes.bs.js";
import * as OpeningButton from "../../resources/actions/OpeningButton.bs.js";
import * as Client from "@apollo/client";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as CatalogProduct from "../../modules/Catalog/CatalogProduct.bs.js";
import * as RadioCardGroup from "../../resources/selection-and-input/RadioCardGroup.bs.js";
import * as SessionTracker from "../../core/SessionTracker.bs.js";
import * as SupplierSelect from "../../modules/Supplier/SupplierSelect.bs.js";
import * as RequestMenuItem from "../../resources/actions/RequestMenuItem.bs.js";
import * as ShortIconButton from "../../resources/actions/ShortIconButton.bs.js";
import * as TriggerDownload from "../../primitives/TriggerDownload.bs.js";
import * as UserPreferences from "../../core/UserPreferences.bs.js";
import * as Stylex from "@stylexjs/stylex";
import * as InputNumberField from "../../resources/selection-and-input/InputNumberField.bs.js";
import * as ResourceListPage from "../../core/ResourceListPage.bs.js";
import * as ReactUpdateEffect from "../../primitives/ReactUpdateEffect.bs.js";
import * as CatalogTableActions from "../../modules/Catalog/CatalogTableActions.bs.js";
import * as CatalogProducerSelect from "../../modules/Catalog/CatalogProducerSelect.bs.js";
import * as CatalogCategoryEditCell from "../../modules/Catalog/CatalogCategoryEditCell.bs.js";
import * as CatalogStockRangeSelect from "../../modules/Catalog/CatalogStockRangeSelect.bs.js";
import * as InputOptionalNumberField from "../../resources/selection-and-input/InputOptionalNumberField.bs.js";
import * as CatalogProductStatusBadge from "../../modules/Catalog/CatalogProductStatusBadge.bs.js";
import * as ProductReferenceTableCell from "../../modules/Product/ProductReferenceTableCell.bs.js";
import * as CatalogCategorySelectFilter from "../../modules/Catalog/CatalogCategorySelectFilter.bs.js";
import * as RequestOpenStorageUrlButton from "../../resources/actions/RequestOpenStorageUrlButton.bs.js";
import * as CatalogLabelQuickPrintButton from "../../modules/Catalog/CatalogLabelQuickPrintButton.bs.js";
import * as RequestOpenStorageUrlMenuItem from "../../resources/actions/RequestOpenStorageUrlMenuItem.bs.js";
import * as CatalogProductEditUrlQueryString from "../../modules/Catalog/CatalogProductEditUrlQueryString.bs.js";
import * as CatalogVariantStockThresholdCell from "../../modules/Catalog/CatalogVariantStockThresholdCell.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";
import * as CatalogPriceDifferentialIndicatorCell from "../../modules/Catalog/CatalogPriceDifferentialIndicatorCell.bs.js";

var nonApplicableStringLiteral = "—";

var nonApplicableValueCellElement = React.createElement(Tooltip.make, {
      children: React.createElement(TextStyle.make, {
            children: nonApplicableStringLiteral,
            variation: "normal",
            size: "xsmall"
          }),
      placement: "top",
      content: React.createElement(Tooltip.Span.make, {
            text: $$Intl.t("Please select a shop beforehand.")
          }),
      delay: 0
    });

var Raw = {};

var query = Client.gql(["query CatalogListMultiShopsQuery($search: String, $filterBy: InputVariantsDistinctOnCkuQueryFilter, $orderBy: [InputVariantsDistinctOnCkuOrderBy!], $before: String, $after: String, $first: Int, $last: Int)  {\nvariantsDistinctOnCku(search: $search, filterBy: $filterBy, orderBy: $orderBy, before: $before, after: $after, first: $first, last: $last)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \ncku  \nid  \ncreatedAt  \nname  \nproduct  {\n__typename  \nid  \nname  \nkind  \ncolor  \nwineType  \nwhiteWineType  \nbeerType  \nproducer  \ndesignation  \nfamily  \nregion  \ncountry  \ntax  {\n__typename  \nvalue  \n}\n\n}\n\nstockKeepingUnit  \ninternalCode  \npriceLookUpCode  \nsupplier  {\n__typename  \ncompanyName  \n}\n\nalcoholVolume  \npurchasedPrice  \nformattedCategory  \nbulk  \ncapacityUnit  \nmaxStockThreshold  \nminStockThreshold  \nstockOrderTriggerThreshold  \nstock  {\n__typename  \nformattedShopsNames  \nformattedQuantity  \nstate  \n}\n\nformattedStatus  \n}\n\n}\n\ntotalCount  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variantsDistinctOnCku;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          variantsDistinctOnCku: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.product;
                    var value$3 = value$2.kind;
                    var tmp;
                    switch (value$3) {
                      case "BEER" :
                          tmp = "BEER";
                          break;
                      case "SIMPLE" :
                          tmp = "SIMPLE";
                          break;
                      case "SPIRITUOUS" :
                          tmp = "SPIRITUOUS";
                          break;
                      case "WINE" :
                          tmp = "WINE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$4 = value$2.color;
                    var tmp$1;
                    if (value$4 == null) {
                      tmp$1 = undefined;
                    } else {
                      var tmp$2;
                      switch (value$4) {
                        case "AMBER" :
                            tmp$2 = "AMBER";
                            break;
                        case "BLACK" :
                            tmp$2 = "BLACK";
                            break;
                        case "BLOND" :
                            tmp$2 = "BLOND";
                            break;
                        case "DARK" :
                            tmp$2 = "DARK";
                            break;
                        case "ORANGE" :
                            tmp$2 = "ORANGE";
                            break;
                        case "RED" :
                            tmp$2 = "RED";
                            break;
                        case "ROSE" :
                            tmp$2 = "ROSE";
                            break;
                        case "WHITE" :
                            tmp$2 = "WHITE";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$1 = tmp$2;
                    }
                    var value$5 = value$2.wineType;
                    var tmp$3;
                    if (value$5 == null) {
                      tmp$3 = undefined;
                    } else {
                      var tmp$4;
                      switch (value$5) {
                        case "EFFERVESCENT" :
                            tmp$4 = "EFFERVESCENT";
                            break;
                        case "STILL" :
                            tmp$4 = "STILL";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$3 = tmp$4;
                    }
                    var value$6 = value$2.whiteWineType;
                    var tmp$5;
                    if (value$6 == null) {
                      tmp$5 = undefined;
                    } else {
                      var tmp$6;
                      switch (value$6) {
                        case "DRY" :
                            tmp$6 = "DRY";
                            break;
                        case "SEMIDRY" :
                            tmp$6 = "SEMIDRY";
                            break;
                        case "SOFT" :
                            tmp$6 = "SOFT";
                            break;
                        case "SWEET" :
                            tmp$6 = "SWEET";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$5 = tmp$6;
                    }
                    var value$7 = value$2.beerType;
                    var value$8 = value$2.producer;
                    var value$9 = value$2.designation;
                    var value$10 = value$2.family;
                    var value$11 = value$2.region;
                    var value$12 = value$2.country;
                    var value$13 = value$2.tax;
                    var value$14 = value$1.stockKeepingUnit;
                    var value$15 = value$1.internalCode;
                    var value$16 = value$1.priceLookUpCode;
                    var value$17 = value$1.supplier;
                    var value$18 = value$1.alcoholVolume;
                    var value$19 = value$1.purchasedPrice;
                    var value$20 = value$1.formattedCategory;
                    var value$21 = value$1.bulk;
                    var value$22 = value$1.capacityUnit;
                    var value$23 = value$1.maxStockThreshold;
                    var value$24 = value$1.minStockThreshold;
                    var value$25 = value$1.stockOrderTriggerThreshold;
                    var value$26 = value$1.stock;
                    var value$27 = value$26.formattedShopsNames;
                    var value$28 = value$26.formattedQuantity;
                    var value$29 = value$26.state;
                    var tmp$7;
                    if (value$29 == null) {
                      tmp$7 = undefined;
                    } else {
                      var tmp$8;
                      switch (value$29) {
                        case "ALERT" :
                            tmp$8 = "ALERT";
                            break;
                        case "DANGER" :
                            tmp$8 = "DANGER";
                            break;
                        case "OK" :
                            tmp$8 = "OK";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$7 = tmp$8;
                    }
                    var value$30 = value$1.formattedStatus;
                    var tmp$9;
                    switch (value$30) {
                      case "ACTIVE" :
                          tmp$9 = "ACTIVE";
                          break;
                      case "ARCHIVED" :
                          tmp$9 = "ARCHIVED";
                          break;
                      case "INACTIVE" :
                          tmp$9 = "INACTIVE";
                          break;
                      case "VARIABLE" :
                          tmp$9 = "VARIABLE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              cku: Scalar.CKU.parse(value$1.cku),
                              id: value$1.id,
                              createdAt: Scalar.Datetime.parse(value$1.createdAt),
                              name: value$1.name,
                              product: {
                                __typename: value$2.__typename,
                                id: value$2.id,
                                name: value$2.name,
                                kind: tmp,
                                color: tmp$1,
                                wineType: tmp$3,
                                whiteWineType: tmp$5,
                                beerType: !(value$7 == null) ? value$7 : undefined,
                                producer: !(value$8 == null) ? value$8 : undefined,
                                designation: !(value$9 == null) ? value$9 : undefined,
                                family: !(value$10 == null) ? value$10 : undefined,
                                region: !(value$11 == null) ? value$11 : undefined,
                                country: !(value$12 == null) ? value$12 : undefined,
                                tax: {
                                  __typename: value$13.__typename,
                                  value: value$13.value
                                }
                              },
                              stockKeepingUnit: !(value$14 == null) ? value$14 : undefined,
                              internalCode: !(value$15 == null) ? value$15 : undefined,
                              priceLookUpCode: !(value$16 == null) ? value$16 : undefined,
                              supplier: !(value$17 == null) ? ({
                                    __typename: value$17.__typename,
                                    companyName: value$17.companyName
                                  }) : undefined,
                              alcoholVolume: !(value$18 == null) ? value$18 : undefined,
                              purchasedPrice: !(value$19 == null) ? value$19 : undefined,
                              formattedCategory: !(value$20 == null) ? value$20 : undefined,
                              bulk: !(value$21 == null) ? value$21 : undefined,
                              capacityUnit: !(value$22 == null) ? value$22 : undefined,
                              maxStockThreshold: !(value$23 == null) ? value$23 : undefined,
                              minStockThreshold: !(value$24 == null) ? value$24 : undefined,
                              stockOrderTriggerThreshold: !(value$25 == null) ? value$25 : undefined,
                              stock: {
                                __typename: value$26.__typename,
                                formattedShopsNames: !(value$27 == null) ? value$27 : undefined,
                                formattedQuantity: !(value$28 == null) ? value$28 : undefined,
                                state: tmp$7
                              },
                              formattedStatus: tmp$9
                            }
                          };
                  }), value$5),
            totalCount: value$1.totalCount
          }
        };
}

function serialize(value) {
  var value$1 = value.variantsDistinctOnCku;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.formattedStatus;
          var formattedStatus = value$2 === "INACTIVE" ? "INACTIVE" : (
              value$2 === "ARCHIVED" ? "ARCHIVED" : (
                  value$2 === "ACTIVE" ? "ACTIVE" : "VARIABLE"
                )
            );
          var value$3 = value$1.stock;
          var value$4 = value$3.state;
          var state = value$4 !== undefined ? (
              value$4 === "OK" ? "OK" : (
                  value$4 === "ALERT" ? "ALERT" : "DANGER"
                )
            ) : null;
          var value$5 = value$3.formattedQuantity;
          var formattedQuantity = value$5 !== undefined ? value$5 : null;
          var value$6 = value$3.formattedShopsNames;
          var formattedShopsNames = value$6 !== undefined ? value$6 : null;
          var value$7 = value$3.__typename;
          var stock = {
            __typename: value$7,
            formattedShopsNames: formattedShopsNames,
            formattedQuantity: formattedQuantity,
            state: state
          };
          var value$8 = value$1.stockOrderTriggerThreshold;
          var stockOrderTriggerThreshold = value$8 !== undefined ? value$8 : null;
          var value$9 = value$1.minStockThreshold;
          var minStockThreshold = value$9 !== undefined ? value$9 : null;
          var value$10 = value$1.maxStockThreshold;
          var maxStockThreshold = value$10 !== undefined ? value$10 : null;
          var value$11 = value$1.capacityUnit;
          var capacityUnit = value$11 !== undefined ? value$11 : null;
          var value$12 = value$1.bulk;
          var bulk = value$12 !== undefined ? value$12 : null;
          var value$13 = value$1.formattedCategory;
          var formattedCategory = value$13 !== undefined ? value$13 : null;
          var value$14 = value$1.purchasedPrice;
          var purchasedPrice = value$14 !== undefined ? value$14 : null;
          var value$15 = value$1.alcoholVolume;
          var alcoholVolume = value$15 !== undefined ? value$15 : null;
          var value$16 = value$1.supplier;
          var supplier;
          if (value$16 !== undefined) {
            var value$17 = value$16.companyName;
            var value$18 = value$16.__typename;
            supplier = {
              __typename: value$18,
              companyName: value$17
            };
          } else {
            supplier = null;
          }
          var value$19 = value$1.priceLookUpCode;
          var priceLookUpCode = value$19 !== undefined ? value$19 : null;
          var value$20 = value$1.internalCode;
          var internalCode = value$20 !== undefined ? value$20 : null;
          var value$21 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$21 !== undefined ? value$21 : null;
          var value$22 = value$1.product;
          var value$23 = value$22.tax;
          var value$24 = value$23.value;
          var value$25 = value$23.__typename;
          var tax = {
            __typename: value$25,
            value: value$24
          };
          var value$26 = value$22.country;
          var country = value$26 !== undefined ? value$26 : null;
          var value$27 = value$22.region;
          var region = value$27 !== undefined ? value$27 : null;
          var value$28 = value$22.family;
          var family = value$28 !== undefined ? value$28 : null;
          var value$29 = value$22.designation;
          var designation = value$29 !== undefined ? value$29 : null;
          var value$30 = value$22.producer;
          var producer = value$30 !== undefined ? value$30 : null;
          var value$31 = value$22.beerType;
          var beerType = value$31 !== undefined ? value$31 : null;
          var value$32 = value$22.whiteWineType;
          var whiteWineType = value$32 !== undefined ? (
              value$32 === "SWEET" ? "SWEET" : (
                  value$32 === "SEMIDRY" ? "SEMIDRY" : (
                      value$32 === "SOFT" ? "SOFT" : "DRY"
                    )
                )
            ) : null;
          var value$33 = value$22.wineType;
          var wineType = value$33 !== undefined ? (
              value$33 === "EFFERVESCENT" ? "EFFERVESCENT" : "STILL"
            ) : null;
          var value$34 = value$22.color;
          var color = value$34 !== undefined ? (
              value$34 === "ORANGE" ? "ORANGE" : (
                  value$34 === "RED" ? "RED" : (
                      value$34 === "AMBER" ? "AMBER" : (
                          value$34 === "DARK" ? "DARK" : (
                              value$34 === "BLACK" ? "BLACK" : (
                                  value$34 === "BLOND" ? "BLOND" : (
                                      value$34 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$35 = value$22.kind;
          var kind = value$35 === "SPIRITUOUS" ? "SPIRITUOUS" : (
              value$35 === "BEER" ? "BEER" : (
                  value$35 === "WINE" ? "WINE" : "SIMPLE"
                )
            );
          var value$36 = value$22.name;
          var value$37 = value$22.id;
          var value$38 = value$22.__typename;
          var product = {
            __typename: value$38,
            id: value$37,
            name: value$36,
            kind: kind,
            color: color,
            wineType: wineType,
            whiteWineType: whiteWineType,
            beerType: beerType,
            producer: producer,
            designation: designation,
            family: family,
            region: region,
            country: country,
            tax: tax
          };
          var value$39 = value$1.name;
          var value$40 = value$1.createdAt;
          var value$41 = Scalar.Datetime.serialize(value$40);
          var value$42 = value$1.id;
          var value$43 = value$1.cku;
          var value$44 = Scalar.CKU.serialize(value$43);
          var value$45 = value$1.__typename;
          var node = {
            __typename: value$45,
            cku: value$44,
            id: value$42,
            createdAt: value$41,
            name: value$39,
            product: product,
            stockKeepingUnit: stockKeepingUnit,
            internalCode: internalCode,
            priceLookUpCode: priceLookUpCode,
            supplier: supplier,
            alcoholVolume: alcoholVolume,
            purchasedPrice: purchasedPrice,
            formattedCategory: formattedCategory,
            bulk: bulk,
            capacityUnit: capacityUnit,
            maxStockThreshold: maxStockThreshold,
            minStockThreshold: minStockThreshold,
            stockOrderTriggerThreshold: stockOrderTriggerThreshold,
            stock: stock,
            formattedStatus: formattedStatus
          };
          var value$46 = value.__typename;
          return {
                  __typename: value$46,
                  node: node
                };
        }), value$3);
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var variantsDistinctOnCku = {
    __typename: value$8,
    pageInfo: pageInfo,
    edges: edges,
    totalCount: value$2
  };
  return {
          variantsDistinctOnCku: variantsDistinctOnCku
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputVariantsDistinctOnCkuQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.active;
  var a$2 = inp.archived;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          active: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          archived: a$2 !== undefined ? (
              a$2 === "ONLY" ? "ONLY" : (
                  a$2 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeInputObjectInputVariantsDistinctOnCkuOrderBy(inp) {
  var a = inp.createdAt;
  var a$1 = inp.active;
  var a$2 = inp.name;
  var a$3 = inp.producer;
  var a$4 = inp.purchasedPrice;
  return {
          createdAt: a !== undefined ? (
              a === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          active: a$1 !== undefined ? (
              a$1 === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          name: a$2 !== undefined ? (
              a$2 === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          producer: a$3 !== undefined ? (
              a$3 === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          purchasedPrice: a$4 !== undefined ? (
              a$4 === "DESC" ? "DESC" : "ASC"
            ) : undefined
        };
}

function serializeInputObjectBooleanEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.filterBy;
  var a$2 = inp.orderBy;
  var a$3 = inp.before;
  var a$4 = inp.after;
  var a$5 = inp.first;
  var a$6 = inp.last;
  return {
          search: a !== undefined ? a : undefined,
          filterBy: a$1 !== undefined ? serializeInputObjectInputVariantsDistinctOnCkuQueryFilter(a$1) : undefined,
          orderBy: a$2 !== undefined ? Js_array.map(serializeInputObjectInputVariantsDistinctOnCkuOrderBy, a$2) : undefined,
          before: a$3 !== undefined ? a$3 : undefined,
          after: a$4 !== undefined ? a$4 : undefined,
          first: a$5 !== undefined ? a$5 : undefined,
          last: a$6 !== undefined ? a$6 : undefined
        };
}

function makeVariables(search, filterBy, orderBy, before, after, first, last, param) {
  return {
          search: search,
          filterBy: filterBy,
          orderBy: orderBy,
          before: before,
          after: after,
          first: first,
          last: last
        };
}

function makeInputObjectInputVariantsDistinctOnCkuQueryFilter(shopIds, active, archived, param) {
  return {
          shopIds: shopIds,
          active: active,
          archived: archived
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectBooleanEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectInputVariantsDistinctOnCkuOrderBy(createdAt, active, name, producer, purchasedPrice, param) {
  return {
          createdAt: createdAt,
          active: active,
          name: name,
          producer: producer,
          purchasedPrice: purchasedPrice
        };
}

function makeDefaultVariables(param) {
  return {
          search: undefined,
          filterBy: undefined,
          orderBy: undefined,
          before: undefined,
          after: undefined,
          first: undefined,
          last: undefined
        };
}

var CatalogListMultiShopsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsDistinctOnCkuQueryFilter: serializeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectInputVariantsDistinctOnCkuOrderBy: serializeInputObjectInputVariantsDistinctOnCkuOrderBy,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsDistinctOnCkuQueryFilter: makeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectInputVariantsDistinctOnCkuOrderBy: makeInputObjectInputVariantsDistinctOnCkuOrderBy,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var CatalogListMultiShopsQuery_refetchQueryDescription = include.refetchQueryDescription;

var CatalogListMultiShopsQuery_useLazy = include.useLazy;

var CatalogListMultiShopsQuery_useLazyWithVariables = include.useLazyWithVariables;

var CatalogListMultiShopsQuery = {
  CatalogListMultiShopsQuery_inner: CatalogListMultiShopsQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsDistinctOnCkuQueryFilter: serializeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectInputVariantsDistinctOnCkuOrderBy: serializeInputObjectInputVariantsDistinctOnCkuOrderBy,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsDistinctOnCkuQueryFilter: makeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectInputVariantsDistinctOnCkuOrderBy: makeInputObjectInputVariantsDistinctOnCkuOrderBy,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: CatalogListMultiShopsQuery_refetchQueryDescription,
  use: use,
  useLazy: CatalogListMultiShopsQuery_useLazy,
  useLazyWithVariables: CatalogListMultiShopsQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query CatalogListSingleShopQuery($search: String, $filterBy: InputVariantsQueryFilter, $orderBy: [InputVariantsOrderBy!], $before: String, $after: String, $first: Int, $last: Int)  {\nvariants(search: $search, filterBy: $filterBy, orderBy: $orderBy, before: $before, after: $after, first: $first, last: $last)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\ntotalCount  \nedges  {\n__typename  \nnode  {\n__typename  \ncku  \nid  \ncreatedAt  \nname  \nproduct  {\n__typename  \nid  \nname  \nkind  \ncolor  \nwineType  \nwhiteWineType  \nbeerType  \nproducer  \ndesignation  \nfamily  \nregion  \ncountry  \ntax  {\n__typename  \nvalue  \n}\n\n}\n\npurchasedPrice  \nvariantPrices  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nvalueIncludingTax  \nvalueExcludingTax  \nprice  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\norderProducts(first: 1, filterBy: {receptionFinishedAt: {_after: \"1970-01-01T00:00:00Z\"}})  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nquantity  \ntotalLocalDiscounts  \nfees  \n}\n\n}\n\n}\n\nstockKeepingUnit  \ninternalCode  \npriceLookUpCode  \nsupplier  {\n__typename  \ncompanyName  \n}\n\nalcoholVolume  \nformattedCategory  \nbulk  \ncapacityUnit  \nmaxStockThreshold  \nminStockThreshold  \nstockOrderTriggerThreshold  \nstock  {\n__typename  \nformattedQuantity  \nstate  \nformattedShopsNames  \n}\n\nformattedStatus  \n}\n\n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.variants;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          variants: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            totalCount: value$1.totalCount,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.product;
                    var value$3 = value$2.kind;
                    var tmp;
                    switch (value$3) {
                      case "BEER" :
                          tmp = "BEER";
                          break;
                      case "SIMPLE" :
                          tmp = "SIMPLE";
                          break;
                      case "SPIRITUOUS" :
                          tmp = "SPIRITUOUS";
                          break;
                      case "WINE" :
                          tmp = "WINE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$4 = value$2.color;
                    var tmp$1;
                    if (value$4 == null) {
                      tmp$1 = undefined;
                    } else {
                      var tmp$2;
                      switch (value$4) {
                        case "AMBER" :
                            tmp$2 = "AMBER";
                            break;
                        case "BLACK" :
                            tmp$2 = "BLACK";
                            break;
                        case "BLOND" :
                            tmp$2 = "BLOND";
                            break;
                        case "DARK" :
                            tmp$2 = "DARK";
                            break;
                        case "ORANGE" :
                            tmp$2 = "ORANGE";
                            break;
                        case "RED" :
                            tmp$2 = "RED";
                            break;
                        case "ROSE" :
                            tmp$2 = "ROSE";
                            break;
                        case "WHITE" :
                            tmp$2 = "WHITE";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$1 = tmp$2;
                    }
                    var value$5 = value$2.wineType;
                    var tmp$3;
                    if (value$5 == null) {
                      tmp$3 = undefined;
                    } else {
                      var tmp$4;
                      switch (value$5) {
                        case "EFFERVESCENT" :
                            tmp$4 = "EFFERVESCENT";
                            break;
                        case "STILL" :
                            tmp$4 = "STILL";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$3 = tmp$4;
                    }
                    var value$6 = value$2.whiteWineType;
                    var tmp$5;
                    if (value$6 == null) {
                      tmp$5 = undefined;
                    } else {
                      var tmp$6;
                      switch (value$6) {
                        case "DRY" :
                            tmp$6 = "DRY";
                            break;
                        case "SEMIDRY" :
                            tmp$6 = "SEMIDRY";
                            break;
                        case "SOFT" :
                            tmp$6 = "SOFT";
                            break;
                        case "SWEET" :
                            tmp$6 = "SWEET";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$5 = tmp$6;
                    }
                    var value$7 = value$2.beerType;
                    var value$8 = value$2.producer;
                    var value$9 = value$2.designation;
                    var value$10 = value$2.family;
                    var value$11 = value$2.region;
                    var value$12 = value$2.country;
                    var value$13 = value$2.tax;
                    var value$14 = value$1.purchasedPrice;
                    var value$15 = value$1.variantPrices;
                    var value$16 = value$15.edges;
                    var value$17 = value$1.orderProducts;
                    var value$18 = value$17.edges;
                    var value$19 = value$1.stockKeepingUnit;
                    var value$20 = value$1.internalCode;
                    var value$21 = value$1.priceLookUpCode;
                    var value$22 = value$1.supplier;
                    var value$23 = value$1.alcoholVolume;
                    var value$24 = value$1.formattedCategory;
                    var value$25 = value$1.bulk;
                    var value$26 = value$1.capacityUnit;
                    var value$27 = value$1.maxStockThreshold;
                    var value$28 = value$1.minStockThreshold;
                    var value$29 = value$1.stockOrderTriggerThreshold;
                    var value$30 = value$1.stock;
                    var value$31 = value$30.formattedQuantity;
                    var value$32 = value$30.state;
                    var tmp$7;
                    if (value$32 == null) {
                      tmp$7 = undefined;
                    } else {
                      var tmp$8;
                      switch (value$32) {
                        case "ALERT" :
                            tmp$8 = "ALERT";
                            break;
                        case "DANGER" :
                            tmp$8 = "DANGER";
                            break;
                        case "OK" :
                            tmp$8 = "OK";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$7 = tmp$8;
                    }
                    var value$33 = value$30.formattedShopsNames;
                    var value$34 = value$1.formattedStatus;
                    var tmp$9;
                    switch (value$34) {
                      case "ACTIVE" :
                          tmp$9 = "ACTIVE";
                          break;
                      case "ARCHIVED" :
                          tmp$9 = "ARCHIVED";
                          break;
                      case "INACTIVE" :
                          tmp$9 = "INACTIVE";
                          break;
                      case "VARIABLE" :
                          tmp$9 = "VARIABLE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              cku: Scalar.CKU.parse(value$1.cku),
                              id: value$1.id,
                              createdAt: Scalar.Datetime.parse(value$1.createdAt),
                              name: value$1.name,
                              product: {
                                __typename: value$2.__typename,
                                id: value$2.id,
                                name: value$2.name,
                                kind: tmp,
                                color: tmp$1,
                                wineType: tmp$3,
                                whiteWineType: tmp$5,
                                beerType: !(value$7 == null) ? value$7 : undefined,
                                producer: !(value$8 == null) ? value$8 : undefined,
                                designation: !(value$9 == null) ? value$9 : undefined,
                                family: !(value$10 == null) ? value$10 : undefined,
                                region: !(value$11 == null) ? value$11 : undefined,
                                country: !(value$12 == null) ? value$12 : undefined,
                                tax: {
                                  __typename: value$13.__typename,
                                  value: value$13.value
                                }
                              },
                              purchasedPrice: !(value$14 == null) ? value$14 : undefined,
                              variantPrices: {
                                __typename: value$15.__typename,
                                edges: Js_array.map((function (value) {
                                        var value$1 = value.node;
                                        var value$2 = value$1.price;
                                        return {
                                                __typename: value.__typename,
                                                node: {
                                                  __typename: value$1.__typename,
                                                  id: value$1.id,
                                                  valueIncludingTax: value$1.valueIncludingTax,
                                                  valueExcludingTax: value$1.valueExcludingTax,
                                                  price: !(value$2 == null) ? ({
                                                        __typename: value$2.__typename,
                                                        id: value$2.id
                                                      }) : undefined
                                                }
                                              };
                                      }), value$16)
                              },
                              orderProducts: {
                                __typename: value$17.__typename,
                                edges: Js_array.map((function (value) {
                                        var value$1 = value.node;
                                        return {
                                                __typename: value.__typename,
                                                node: {
                                                  __typename: value$1.__typename,
                                                  quantity: value$1.quantity,
                                                  totalLocalDiscounts: value$1.totalLocalDiscounts,
                                                  fees: value$1.fees
                                                }
                                              };
                                      }), value$18)
                              },
                              stockKeepingUnit: !(value$19 == null) ? value$19 : undefined,
                              internalCode: !(value$20 == null) ? value$20 : undefined,
                              priceLookUpCode: !(value$21 == null) ? value$21 : undefined,
                              supplier: !(value$22 == null) ? ({
                                    __typename: value$22.__typename,
                                    companyName: value$22.companyName
                                  }) : undefined,
                              alcoholVolume: !(value$23 == null) ? value$23 : undefined,
                              formattedCategory: !(value$24 == null) ? value$24 : undefined,
                              bulk: !(value$25 == null) ? value$25 : undefined,
                              capacityUnit: !(value$26 == null) ? value$26 : undefined,
                              maxStockThreshold: !(value$27 == null) ? value$27 : undefined,
                              minStockThreshold: !(value$28 == null) ? value$28 : undefined,
                              stockOrderTriggerThreshold: !(value$29 == null) ? value$29 : undefined,
                              stock: {
                                __typename: value$30.__typename,
                                formattedQuantity: !(value$31 == null) ? value$31 : undefined,
                                state: tmp$7,
                                formattedShopsNames: !(value$33 == null) ? value$33 : undefined
                              },
                              formattedStatus: tmp$9
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.variants;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.formattedStatus;
          var formattedStatus = value$2 === "INACTIVE" ? "INACTIVE" : (
              value$2 === "ARCHIVED" ? "ARCHIVED" : (
                  value$2 === "ACTIVE" ? "ACTIVE" : "VARIABLE"
                )
            );
          var value$3 = value$1.stock;
          var value$4 = value$3.formattedShopsNames;
          var formattedShopsNames = value$4 !== undefined ? value$4 : null;
          var value$5 = value$3.state;
          var state = value$5 !== undefined ? (
              value$5 === "OK" ? "OK" : (
                  value$5 === "ALERT" ? "ALERT" : "DANGER"
                )
            ) : null;
          var value$6 = value$3.formattedQuantity;
          var formattedQuantity = value$6 !== undefined ? value$6 : null;
          var value$7 = value$3.__typename;
          var stock = {
            __typename: value$7,
            formattedQuantity: formattedQuantity,
            state: state,
            formattedShopsNames: formattedShopsNames
          };
          var value$8 = value$1.stockOrderTriggerThreshold;
          var stockOrderTriggerThreshold = value$8 !== undefined ? value$8 : null;
          var value$9 = value$1.minStockThreshold;
          var minStockThreshold = value$9 !== undefined ? value$9 : null;
          var value$10 = value$1.maxStockThreshold;
          var maxStockThreshold = value$10 !== undefined ? value$10 : null;
          var value$11 = value$1.capacityUnit;
          var capacityUnit = value$11 !== undefined ? value$11 : null;
          var value$12 = value$1.bulk;
          var bulk = value$12 !== undefined ? value$12 : null;
          var value$13 = value$1.formattedCategory;
          var formattedCategory = value$13 !== undefined ? value$13 : null;
          var value$14 = value$1.alcoholVolume;
          var alcoholVolume = value$14 !== undefined ? value$14 : null;
          var value$15 = value$1.supplier;
          var supplier;
          if (value$15 !== undefined) {
            var value$16 = value$15.companyName;
            var value$17 = value$15.__typename;
            supplier = {
              __typename: value$17,
              companyName: value$16
            };
          } else {
            supplier = null;
          }
          var value$18 = value$1.priceLookUpCode;
          var priceLookUpCode = value$18 !== undefined ? value$18 : null;
          var value$19 = value$1.internalCode;
          var internalCode = value$19 !== undefined ? value$19 : null;
          var value$20 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$20 !== undefined ? value$20 : null;
          var value$21 = value$1.orderProducts;
          var value$22 = value$21.edges;
          var edges = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.fees;
                  var value$3 = value$1.totalLocalDiscounts;
                  var value$4 = value$1.quantity;
                  var value$5 = value$1.__typename;
                  var node = {
                    __typename: value$5,
                    quantity: value$4,
                    totalLocalDiscounts: value$3,
                    fees: value$2
                  };
                  var value$6 = value.__typename;
                  return {
                          __typename: value$6,
                          node: node
                        };
                }), value$22);
          var value$23 = value$21.__typename;
          var orderProducts = {
            __typename: value$23,
            edges: edges
          };
          var value$24 = value$1.variantPrices;
          var value$25 = value$24.edges;
          var edges$1 = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.price;
                  var price;
                  if (value$2 !== undefined) {
                    var value$3 = value$2.id;
                    var value$4 = value$2.__typename;
                    price = {
                      __typename: value$4,
                      id: value$3
                    };
                  } else {
                    price = null;
                  }
                  var value$5 = value$1.valueExcludingTax;
                  var value$6 = value$1.valueIncludingTax;
                  var value$7 = value$1.id;
                  var value$8 = value$1.__typename;
                  var node = {
                    __typename: value$8,
                    id: value$7,
                    valueIncludingTax: value$6,
                    valueExcludingTax: value$5,
                    price: price
                  };
                  var value$9 = value.__typename;
                  return {
                          __typename: value$9,
                          node: node
                        };
                }), value$25);
          var value$26 = value$24.__typename;
          var variantPrices = {
            __typename: value$26,
            edges: edges$1
          };
          var value$27 = value$1.purchasedPrice;
          var purchasedPrice = value$27 !== undefined ? value$27 : null;
          var value$28 = value$1.product;
          var value$29 = value$28.tax;
          var value$30 = value$29.value;
          var value$31 = value$29.__typename;
          var tax = {
            __typename: value$31,
            value: value$30
          };
          var value$32 = value$28.country;
          var country = value$32 !== undefined ? value$32 : null;
          var value$33 = value$28.region;
          var region = value$33 !== undefined ? value$33 : null;
          var value$34 = value$28.family;
          var family = value$34 !== undefined ? value$34 : null;
          var value$35 = value$28.designation;
          var designation = value$35 !== undefined ? value$35 : null;
          var value$36 = value$28.producer;
          var producer = value$36 !== undefined ? value$36 : null;
          var value$37 = value$28.beerType;
          var beerType = value$37 !== undefined ? value$37 : null;
          var value$38 = value$28.whiteWineType;
          var whiteWineType = value$38 !== undefined ? (
              value$38 === "SWEET" ? "SWEET" : (
                  value$38 === "SEMIDRY" ? "SEMIDRY" : (
                      value$38 === "SOFT" ? "SOFT" : "DRY"
                    )
                )
            ) : null;
          var value$39 = value$28.wineType;
          var wineType = value$39 !== undefined ? (
              value$39 === "EFFERVESCENT" ? "EFFERVESCENT" : "STILL"
            ) : null;
          var value$40 = value$28.color;
          var color = value$40 !== undefined ? (
              value$40 === "ORANGE" ? "ORANGE" : (
                  value$40 === "RED" ? "RED" : (
                      value$40 === "AMBER" ? "AMBER" : (
                          value$40 === "DARK" ? "DARK" : (
                              value$40 === "BLACK" ? "BLACK" : (
                                  value$40 === "BLOND" ? "BLOND" : (
                                      value$40 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$41 = value$28.kind;
          var kind = value$41 === "SPIRITUOUS" ? "SPIRITUOUS" : (
              value$41 === "BEER" ? "BEER" : (
                  value$41 === "WINE" ? "WINE" : "SIMPLE"
                )
            );
          var value$42 = value$28.name;
          var value$43 = value$28.id;
          var value$44 = value$28.__typename;
          var product = {
            __typename: value$44,
            id: value$43,
            name: value$42,
            kind: kind,
            color: color,
            wineType: wineType,
            whiteWineType: whiteWineType,
            beerType: beerType,
            producer: producer,
            designation: designation,
            family: family,
            region: region,
            country: country,
            tax: tax
          };
          var value$45 = value$1.name;
          var value$46 = value$1.createdAt;
          var value$47 = Scalar.Datetime.serialize(value$46);
          var value$48 = value$1.id;
          var value$49 = value$1.cku;
          var value$50 = Scalar.CKU.serialize(value$49);
          var value$51 = value$1.__typename;
          var node = {
            __typename: value$51,
            cku: value$50,
            id: value$48,
            createdAt: value$47,
            name: value$45,
            product: product,
            purchasedPrice: purchasedPrice,
            variantPrices: variantPrices,
            orderProducts: orderProducts,
            stockKeepingUnit: stockKeepingUnit,
            internalCode: internalCode,
            priceLookUpCode: priceLookUpCode,
            supplier: supplier,
            alcoholVolume: alcoholVolume,
            formattedCategory: formattedCategory,
            bulk: bulk,
            capacityUnit: capacityUnit,
            maxStockThreshold: maxStockThreshold,
            minStockThreshold: minStockThreshold,
            stockOrderTriggerThreshold: stockOrderTriggerThreshold,
            stock: stock,
            formattedStatus: formattedStatus
          };
          var value$52 = value.__typename;
          return {
                  __typename: value$52,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.totalCount;
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var variants = {
    __typename: value$8,
    pageInfo: pageInfo,
    totalCount: value$3,
    edges: edges
  };
  return {
          variants: variants
        };
}

function serializeInputObjectNumberRangeFilter(inp) {
  var a = inp._min;
  var a$1 = inp._max;
  var a$2 = inp._between;
  return {
          _min: a !== undefined ? a : undefined,
          _max: a$1 !== undefined ? a$1 : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectInFilter$1(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectNullableStringEqualsFilter(inp) {
  var a = inp._equals;
  return {
          _equals: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInputVariantsOrderBy(inp) {
  var a = inp.createdAt;
  var a$1 = inp.active;
  var a$2 = inp.name;
  var a$3 = inp.producer;
  var a$4 = inp.purchasedPrice;
  return {
          createdAt: a !== undefined ? (
              a === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          active: a$1 !== undefined ? (
              a$1 === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          name: a$2 !== undefined ? (
              a$2 === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          producer: a$3 !== undefined ? (
              a$3 === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          purchasedPrice: a$4 !== undefined ? (
              a$4 === "DESC" ? "DESC" : "ASC"
            ) : undefined
        };
}

function serializeInputObjectInputVariantsQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.stockKeepingUnit;
  var a$2 = inp.ean13;
  var a$3 = inp.active;
  var a$4 = inp.archived;
  var a$5 = inp.createdAt;
  var a$6 = inp.updatedAt;
  var a$7 = inp.categoryId;
  var a$8 = inp.supplierId;
  var a$9 = inp.stock;
  var a$10 = inp.producer;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter$1(a) : undefined,
          stockKeepingUnit: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          ean13: a$2 !== undefined ? ({
                _equals: a$2._equals
              }) : undefined,
          active: a$3 !== undefined ? ({
                _equals: a$3._equals
              }) : undefined,
          archived: a$4 !== undefined ? (
              a$4 === "ONLY" ? "ONLY" : (
                  a$4 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          createdAt: a$5 !== undefined ? serializeInputObjectDateFilter(a$5) : undefined,
          updatedAt: a$6 !== undefined ? serializeInputObjectDateFilter(a$6) : undefined,
          categoryId: a$7 !== undefined ? serializeInputObjectNullableStringEqualsFilter(a$7) : undefined,
          supplierId: a$8 !== undefined ? serializeInputObjectNullableStringEqualsFilter(a$8) : undefined,
          stock: a$9 !== undefined ? serializeInputObjectNumberRangeFilter(a$9) : undefined,
          producer: a$10 !== undefined ? ({
                _equals: a$10._equals
              }) : undefined
        };
}

function serializeInputObjectBooleanEqualsFilter$1(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectStringEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeVariables$1(inp) {
  var a = inp.search;
  var a$1 = inp.filterBy;
  var a$2 = inp.orderBy;
  var a$3 = inp.before;
  var a$4 = inp.after;
  var a$5 = inp.first;
  var a$6 = inp.last;
  return {
          search: a !== undefined ? a : undefined,
          filterBy: a$1 !== undefined ? serializeInputObjectInputVariantsQueryFilter(a$1) : undefined,
          orderBy: a$2 !== undefined ? Js_array.map(serializeInputObjectInputVariantsOrderBy, a$2) : undefined,
          before: a$3 !== undefined ? a$3 : undefined,
          after: a$4 !== undefined ? a$4 : undefined,
          first: a$5 !== undefined ? a$5 : undefined,
          last: a$6 !== undefined ? a$6 : undefined
        };
}

function makeVariables$1(search, filterBy, orderBy, before, after, first, last, param) {
  return {
          search: search,
          filterBy: filterBy,
          orderBy: orderBy,
          before: before,
          after: after,
          first: first,
          last: last
        };
}

function makeInputObjectInputVariantsQueryFilter(shopIds, stockKeepingUnit, ean13, active, archived, createdAt, updatedAt, categoryId, supplierId, stock, producer, param) {
  return {
          shopIds: shopIds,
          stockKeepingUnit: stockKeepingUnit,
          ean13: ean13,
          active: active,
          archived: archived,
          createdAt: createdAt,
          updatedAt: updatedAt,
          categoryId: categoryId,
          supplierId: supplierId,
          stock: stock,
          producer: producer
        };
}

function makeInputObjectInFilter$1(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectBooleanEqualsFilter$1(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeInputObjectNullableStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectNumberRangeFilter(_min, _max, _between, param) {
  return {
          _min: _min,
          _max: _max,
          _between: _between
        };
}

function makeInputObjectInputVariantsOrderBy(createdAt, active, name, producer, purchasedPrice, param) {
  return {
          createdAt: createdAt,
          active: active,
          name: name,
          producer: producer,
          purchasedPrice: purchasedPrice
        };
}

function makeDefaultVariables$1(param) {
  return {
          search: undefined,
          filterBy: undefined,
          orderBy: undefined,
          before: undefined,
          after: undefined,
          first: undefined,
          last: undefined
        };
}

var CatalogListSingleShopQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter$1,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter$1,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectNullableStringEqualsFilter: serializeInputObjectNullableStringEqualsFilter,
  serializeInputObjectNumberRangeFilter: serializeInputObjectNumberRangeFilter,
  serializeInputObjectInputVariantsOrderBy: serializeInputObjectInputVariantsOrderBy,
  makeVariables: makeVariables$1,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter$1,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter$1,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectNullableStringEqualsFilter: makeInputObjectNullableStringEqualsFilter,
  makeInputObjectNumberRangeFilter: makeInputObjectNumberRangeFilter,
  makeInputObjectInputVariantsOrderBy: makeInputObjectInputVariantsOrderBy,
  makeDefaultVariables: makeDefaultVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var CatalogListSingleShopQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var CatalogListSingleShopQuery_useLazy = include$1.useLazy;

var CatalogListSingleShopQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var CatalogListSingleShopQuery = {
  CatalogListSingleShopQuery_inner: CatalogListSingleShopQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter$1,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter$1,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectNullableStringEqualsFilter: serializeInputObjectNullableStringEqualsFilter,
  serializeInputObjectNumberRangeFilter: serializeInputObjectNumberRangeFilter,
  serializeInputObjectInputVariantsOrderBy: serializeInputObjectInputVariantsOrderBy,
  makeVariables: makeVariables$1,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter$1,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter$1,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectNullableStringEqualsFilter: makeInputObjectNullableStringEqualsFilter,
  makeInputObjectNumberRangeFilter: makeInputObjectNumberRangeFilter,
  makeInputObjectInputVariantsOrderBy: makeInputObjectInputVariantsOrderBy,
  makeDefaultVariables: makeDefaultVariables$1,
  refetchQueryDescription: CatalogListSingleShopQuery_refetchQueryDescription,
  use: use$1,
  useLazy: CatalogListSingleShopQuery_useLazy,
  useLazyWithVariables: CatalogListSingleShopQuery_useLazyWithVariables
};

var Raw$2 = {};

var query$2 = Client.gql(["query PricesQuery($filterBy: InputPricesQueryFilter)  {\nprices(first: 50, filterBy: $filterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nname  \nenableByDefault  \ntaxIncluded  \n}\n\n}\n\n}\n\n}\n"]);

function parse$2(value) {
  var value$1 = value.prices;
  var value$2 = value$1.edges;
  return {
          prices: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              name: value$1.name,
                              enableByDefault: value$1.enableByDefault,
                              taxIncluded: value$1.taxIncluded
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize$2(value) {
  var value$1 = value.prices;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.taxIncluded;
          var value$3 = value$1.enableByDefault;
          var value$4 = value$1.name;
          var value$5 = value$1.id;
          var value$6 = value$1.__typename;
          var node = {
            __typename: value$6,
            id: value$5,
            name: value$4,
            enableByDefault: value$3,
            taxIncluded: value$2
          };
          var value$7 = value.__typename;
          return {
                  __typename: value$7,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var prices = {
    __typename: value$3,
    edges: edges
  };
  return {
          prices: prices
        };
}

function serializeInputObjectInFilter$2(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputPricesQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.archived;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter$2(a) : undefined,
          archived: a$1 !== undefined ? (
              a$1 === "ONLY" ? "ONLY" : (
                  a$1 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeVariables$2(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputPricesQueryFilter(a) : undefined
        };
}

function makeVariables$2(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectInputPricesQueryFilter(shopIds, archived, param) {
  return {
          shopIds: shopIds,
          archived: archived
        };
}

function makeInputObjectInFilter$2(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables$2(param) {
  return {
          filterBy: undefined
        };
}

var SingleShopPricesQuery_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  serializeInputObjectInputPricesQueryFilter: serializeInputObjectInputPricesQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter$2,
  makeVariables: makeVariables$2,
  makeInputObjectInputPricesQueryFilter: makeInputObjectInputPricesQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter$2,
  makeDefaultVariables: makeDefaultVariables$2
};

var include$2 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2
    });

var use$2 = include$2.use;

var SingleShopPricesQuery_refetchQueryDescription = include$2.refetchQueryDescription;

var SingleShopPricesQuery_useLazy = include$2.useLazy;

var SingleShopPricesQuery_useLazyWithVariables = include$2.useLazyWithVariables;

var SingleShopPricesQuery = {
  SingleShopPricesQuery_inner: SingleShopPricesQuery_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  serializeInputObjectInputPricesQueryFilter: serializeInputObjectInputPricesQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter$2,
  makeVariables: makeVariables$2,
  makeInputObjectInputPricesQueryFilter: makeInputObjectInputPricesQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter$2,
  makeDefaultVariables: makeDefaultVariables$2,
  refetchQueryDescription: SingleShopPricesQuery_refetchQueryDescription,
  use: use$2,
  useLazy: SingleShopPricesQuery_useLazy,
  useLazyWithVariables: SingleShopPricesQuery_useLazyWithVariables
};

function singleShopQueryVariables(shopIds) {
  return {
          filterBy: {
            shopIds: {
              _in: shopIds
            },
            archived: undefined
          }
        };
}

function singleShopQueryResult(queryResult) {
  return Belt_Array.map(queryResult.prices.edges, (function (edge) {
                return {
                        id: edge.node.id,
                        name: edge.node.name,
                        enableByDefault: edge.node.enableByDefault,
                        taxIncluded: edge.node.taxIncluded
                      };
              }));
}

function getCurrentPricelist(pricelists, currentPriceId) {
  return Belt_Option.orElse(Belt_Array.getBy(pricelists, (function (pricelist) {
                    if (currentPriceId !== undefined) {
                      return pricelist.id === currentPriceId;
                    } else {
                      return false;
                    }
                  })), Belt_Array.get(pricelists, 0));
}

var CatalogListPricelist = {
  SingleShopPricesQuery: SingleShopPricesQuery,
  singleShopQueryVariables: singleShopQueryVariables,
  singleShopQueryResult: singleShopQueryResult,
  getCurrentPricelist: getCurrentPricelist
};

function keyExtractor(param) {
  return param.id;
}

function decodeFeesJsonToFeesList(feesJson) {
  var initialFeesList = {
    transportAmount: 0,
    taxesAmount: 0,
    otherAmount: 0
  };
  var jsonFees = Json.decodeArray(feesJson);
  if (jsonFees !== undefined) {
    return Belt_Array.reduce(jsonFees, initialFeesList, (function (acc, feeJson) {
                  var kind = Json.flatDecodeDictFieldString(Json.decodeDict(feeJson), "kind");
                  var amount = Json.flatDecodeDictFieldFloat(Json.decodeDict(feeJson), "amount");
                  if (kind === undefined) {
                    return acc;
                  }
                  switch (kind) {
                    case "Other" :
                        if (amount !== undefined) {
                          return {
                                  transportAmount: acc.transportAmount,
                                  taxesAmount: acc.taxesAmount,
                                  otherAmount: acc.otherAmount + amount
                                };
                        } else {
                          return acc;
                        }
                    case "Taxes" :
                        if (amount !== undefined) {
                          return {
                                  transportAmount: acc.transportAmount,
                                  taxesAmount: acc.taxesAmount + amount,
                                  otherAmount: acc.otherAmount
                                };
                        } else {
                          return acc;
                        }
                    case "Transport" :
                        if (amount !== undefined) {
                          return {
                                  transportAmount: acc.transportAmount + amount,
                                  taxesAmount: acc.taxesAmount,
                                  otherAmount: acc.otherAmount
                                };
                        } else {
                          return acc;
                        }
                    default:
                      return acc;
                  }
                }));
  } else {
    return initialFeesList;
  }
}

function computeNetPurchasePrice(value, discount) {
  return value - discount;
}

function computeFullPurchasePrice(purchasePrice, taxesFeeAmount, otherFeeAmount) {
  return purchasePrice + taxesFeeAmount + otherFeeAmount;
}

function computeAcquisitionCost(purchasePrice, transportFeeAmount, taxesFeeAmount, otherFeeAmount) {
  return purchasePrice + transportFeeAmount + taxesFeeAmount + otherFeeAmount;
}

var CatalogListTableRow = {
  keyExtractor: keyExtractor,
  decodeFeesJsonToFeesList: decodeFeesJsonToFeesList,
  computeNetPurchasePrice: computeNetPurchasePrice,
  computeFullPurchasePrice: computeFullPurchasePrice,
  computeAcquisitionCost: computeAcquisitionCost
};

var make = React.memo(function (Props) {
      var notification = Props.notification;
      var onRequestClose = Props.onRequestClose;
      if (notification === undefined) {
        return null;
      }
      var tmp;
      tmp = notification.TAG === /* Ok */0 ? React.createElement(Banner.make, {
              textStatus: {
                TAG: /* Success */0,
                _0: notification._0
              },
              onRequestClose: onRequestClose
            }) : React.createElement(Banner.make, {
              textStatus: {
                TAG: /* Danger */1,
                _0: notification._0
              },
              onRequestClose: onRequestClose
            });
      return React.createElement(Box.make, Box.makeProps(Caml_option.some(tmp), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    });

var CatalogListNotificationBanner = {
  make: make
};

function toString(value) {
  if (value) {
    return "net";
  } else {
    return "gross";
  }
}

function fromString(value) {
  switch (value) {
    case "gross" :
        return {
                TAG: /* Ok */0,
                _0: /* Gross */0
              };
    case "net" :
        return {
                TAG: /* Ok */0,
                _0: /* Net */1
              };
    default:
      return {
              TAG: /* Error */1,
              _0: undefined
            };
  }
}

var PurchasePriceType = {
  toString: toString,
  fromString: fromString
};

function toString$1(value) {
  switch (value) {
    case /* Coefficient */0 :
        return "coefficient";
    case /* MarginRate */1 :
        return "margin-rate";
    case /* MarkupRate */2 :
        return "markup-rate";
    
  }
}

function fromString$1(value) {
  switch (value) {
    case "coefficient" :
        return {
                TAG: /* Ok */0,
                _0: /* Coefficient */0
              };
    case "margin-rate" :
        return {
                TAG: /* Ok */0,
                _0: /* MarginRate */1
              };
    case "markup-rate" :
        return {
                TAG: /* Ok */0,
                _0: /* MarkupRate */2
              };
    default:
      return {
              TAG: /* Error */1,
              _0: undefined
            };
  }
}

function toLabel(value) {
  switch (value) {
    case /* Coefficient */0 :
        return $$Intl.t("Coefficient");
    case /* MarginRate */1 :
        return $$Intl.t("Margin rt");
    case /* MarkupRate */2 :
        return $$Intl.t("Markup rt");
    
  }
}

var PriceDifferentialIndicator = {
  toString: toString$1,
  fromString: fromString$1,
  toLabel: toLabel
};

function toLabel$1(value) {
  switch (value) {
    case /* PurchasePrice */0 :
        return $$Intl.t("Purchase price");
    case /* FullPurchasePrice */1 :
        return $$Intl.t("Full purchase price");
    case /* AcquisitionCost */2 :
        return $$Intl.t("Acquisition cost");
    
  }
}

function toString$2(value) {
  switch (value) {
    case /* PurchasePrice */0 :
        return "purchase-price";
    case /* FullPurchasePrice */1 :
        return "full-purchase-price";
    case /* AcquisitionCost */2 :
        return "acquisition-cost";
    
  }
}

function fromString$2(value) {
  switch (value) {
    case "acquisition-cost" :
        return {
                TAG: /* Ok */0,
                _0: /* AcquisitionCost */2
              };
    case "full-purchase-price" :
        return {
                TAG: /* Ok */0,
                _0: /* FullPurchasePrice */1
              };
    case "purchase-price" :
        return {
                TAG: /* Ok */0,
                _0: /* PurchasePrice */0
              };
    default:
      return {
              TAG: /* Error */1,
              _0: undefined
            };
  }
}

var RetailPriceCalculatingMethod = {
  toLabel: toLabel$1,
  toString: toString$2,
  fromString: fromString$2
};

var initialValue = {
  purchasePriceType: /* Gross */0,
  priceDifferentialIndicator: /* Coefficient */0,
  retailPriceCalculatingMethod: /* PurchasePrice */0
};

var jsonCodec = JsonCodec.object3((function (param) {
        return [
                param.purchasePriceType ? "net" : "gross",
                toString$1(param.priceDifferentialIndicator),
                toString$2(param.retailPriceCalculatingMethod)
              ];
      }), (function (param) {
        var match = fromString(param[0]);
        var match$1 = fromString$1(param[1]);
        var match$2 = fromString$2(param[2]);
        if (match.TAG === /* Ok */0 && match$1.TAG === /* Ok */0 && match$2.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    purchasePriceType: match._0,
                    priceDifferentialIndicator: match$1._0,
                    retailPriceCalculatingMethod: match$2._0
                  }
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: {
                    NAME: "SyntaxError",
                    VAL: "Could not decode values"
                  }
                };
        }
      }), JsonCodec.field("purchasePriceType", JsonCodec.string), JsonCodec.field("priceDifferentialIndicator", JsonCodec.string), JsonCodec.field("retailPriceCalculatingMethod", JsonCodec.string));

function encoder(state) {
  return JsonCodec.encodeWith(state, jsonCodec);
}

function decoder(json) {
  return JsonCodec.decodeWith(json, jsonCodec);
}

function read(param) {
  return UserPreferences.read("catalog-page-settings", decoder);
}

function store(state) {
  UserPreferences.store(state, "catalog-page-settings", encoder);
}

var UserPreferences$1 = {
  jsonCodec: jsonCodec,
  encoder: encoder,
  decoder: decoder,
  read: read,
  store: store
};

var CatalogListPricingSettings = {
  PurchasePriceType: PurchasePriceType,
  PriceDifferentialIndicator: PriceDifferentialIndicator,
  RetailPriceCalculatingMethod: RetailPriceCalculatingMethod,
  initialValue: initialValue,
  UserPreferences: UserPreferences$1
};

var priceDifferentialIndicatorItems = [
  {
    value: "coefficient",
    title: $$Intl.t("catalog_list.pricing_settings.section_1.radio_1.title"),
    description: $$Intl.t("catalog_list.pricing_settings.section_1.radio_1.description")
  },
  {
    value: "margin-rate",
    title: $$Intl.t("catalog_list.pricing_settings.section_1.radio_2.title"),
    description: $$Intl.t("catalog_list.pricing_settings.section_1.radio_2.description")
  },
  {
    value: "markup-rate",
    title: $$Intl.t("catalog_list.pricing_settings.section_1.radio_3.title"),
    description: $$Intl.t("catalog_list.pricing_settings.section_1.radio_3.description")
  }
];

var purchasePriceTypeItems = [
  {
    value: "gross",
    title: $$Intl.t("Gross"),
    description: $$Intl.t("catalog_list.pricing_settings.section_2.radio_1.description")
  },
  {
    value: "net",
    title: $$Intl.t("Net"),
    description: $$Intl.t("catalog_list.pricing_settings.section_2.radio_2.description")
  }
];

var retailPriceCalculatingMethodItems = [
  {
    value: "purchase-price",
    title: $$Intl.t("Purchase price"),
    tooltip: $$Intl.t("catalog_list.pricing_settings.section_3.radio_1.tooltip"),
    description: $$Intl.t("catalog_list.pricing_settings.section_3.radio_1.description")
  },
  {
    value: "full-purchase-price",
    title: $$Intl.t("Full purchase price"),
    tooltip: $$Intl.t("catalog_list.pricing_settings.section_3.radio_2.tooltip"),
    description: $$Intl.t("catalog_list.pricing_settings.section_3.radio_2.description")
  },
  {
    value: "acquisition-cost",
    title: $$Intl.t("Acquisition cost"),
    tooltip: $$Intl.t("catalog_list.pricing_settings.section_3.radio_3.tooltip"),
    description: $$Intl.t("catalog_list.pricing_settings.section_3.radio_3.description")
  }
];

var make$1 = React.memo(function (Props) {
      var onChange = Props.onChange;
      var settingsUserPreferencesReadOpt = Props.settingsUserPreferencesRead;
      var settingsUserPreferencesStoreOpt = Props.settingsUserPreferencesStore;
      var settingsUserPreferencesRead = settingsUserPreferencesReadOpt !== undefined ? settingsUserPreferencesReadOpt : read;
      var settingsUserPreferencesStore = settingsUserPreferencesStoreOpt !== undefined ? settingsUserPreferencesStoreOpt : store;
      var match = Popover.useTrigger(undefined, undefined);
      var popoverTriggerRef = match.ref;
      var popoverAriaProps = match.ariaProps;
      var popover = match.state;
      var match$1 = Hover.use(popoverTriggerRef, undefined);
      var triggerHovered = match$1[1];
      var match$2 = React.useState(function () {
            return Belt_Option.getWithDefault(Curry._1(settingsUserPreferencesRead, undefined), initialValue);
          });
      var setInitialSettings = match$2[1];
      var initialSettings = match$2[0];
      var match$3 = React.useState(function () {
            return initialSettings;
          });
      var setSettings = match$3[1];
      var settings = match$3[0];
      var captureEvent = SessionTracker.useCaptureEvent(undefined);
      React.useEffect((function () {
              Curry._1(onChange, initialSettings);
            }), [initialSettings]);
      var onCommitRequestClose = popover.close;
      var onRequestClose = popover.close;
      var onRequestToggle = popover.toggle;
      var popover_isOpen = popover.isOpen;
      var popover_close = function (param) {
        if (!triggerHovered) {
          Curry._1(setSettings, (function (param) {
                  return initialSettings;
                }));
          return Curry._1(onRequestClose, undefined);
        }
        
      };
      var popover_toggle = function (param) {
        if (popover.isOpen) {
          Curry._1(setSettings, (function (param) {
                  return initialSettings;
                }));
          return Curry._1(onRequestToggle, undefined);
        }
        
      };
      var popover$1 = {
        isOpen: popover_isOpen,
        close: popover_close,
        toggle: popover_toggle
      };
      var onCommitSettings = React.useCallback((function (param) {
              Curry._1(setInitialSettings, (function (param) {
                      return settings;
                    }));
              Curry._1(settingsUserPreferencesStore, settings);
              Curry._1(onCommitRequestClose, undefined);
              Curry._1(captureEvent, "catalog_settings_click_save");
            }), [settings]);
      var priceDifferentialIndicatorValue = toString$1(settings.priceDifferentialIndicator);
      var onChangePriceDifferentialIndicatorValue = function (value) {
        Curry._1(setSettings, (function (prev) {
                return {
                        purchasePriceType: prev.purchasePriceType,
                        priceDifferentialIndicator: Belt_Result.getExn(fromString$1(value)),
                        retailPriceCalculatingMethod: prev.retailPriceCalculatingMethod
                      };
              }));
      };
      var purchasePriceTypeValue = settings.purchasePriceType ? "net" : "gross";
      var onChangePurchasePriceTypeValue = function (value) {
        Curry._1(setSettings, (function (prev) {
                return {
                        purchasePriceType: Belt_Result.getExn(fromString(value)),
                        priceDifferentialIndicator: prev.priceDifferentialIndicator,
                        retailPriceCalculatingMethod: prev.retailPriceCalculatingMethod
                      };
              }));
      };
      var retailPriceCalculatingMethodValue = toString$2(settings.retailPriceCalculatingMethod);
      var onChangeRetailPriceCalculatingMethodValue = function (value) {
        Curry._1(setSettings, (function (prev) {
                return {
                        purchasePriceType: prev.purchasePriceType,
                        priceDifferentialIndicator: prev.priceDifferentialIndicator,
                        retailPriceCalculatingMethod: Belt_Result.getExn(fromString$2(value))
                      };
              }));
      };
      return React.createElement(React.Fragment, undefined, React.createElement(ShortIconButton.make, ShortIconButton.makeProps("settings", undefined, popover_isOpen, true, undefined, undefined, popoverAriaProps.triggerProps, {
                          TAG: /* Callback */0,
                          _0: popover_toggle
                        }, undefined, popoverTriggerRef, undefined)), popover_isOpen ? React.createElement(Popover.make, {
                        children: React.createElement(Dialog.make, {
                              ariaProps: popoverAriaProps.overlayProps,
                              children: null,
                              title: $$Intl.t("Settings"),
                              commitDisabled: Caml_obj.equal(settings, initialSettings),
                              commitButtonText: $$Intl.t("Save settings"),
                              onCommit: onCommitSettings,
                              onRequestClose: popover_close
                            }, React.createElement(Dialog.Section.make, {
                                  children: null,
                                  title: $$Intl.t("catalog_list.pricing_settings.section_1.title"),
                                  tooltip: React.createElement(React.Fragment, undefined, React.createElement(Tooltip.Span.make, {
                                            text: $$Intl.t("catalog_list.pricing_settings.section_1.tooltip")
                                          }))
                                }, React.createElement(RadioCardGroup.make, {
                                      items: priceDifferentialIndicatorItems,
                                      value: priceDifferentialIndicatorValue,
                                      onChange: onChangePriceDifferentialIndicatorValue
                                    }), React.createElement(DraftBanner.make, {
                                      title: $$Intl.t("catalog_list.pricing_settings.section_1.banner.title"),
                                      text: $$Intl.t("catalog_list.pricing_settings.section_1.banner.text"),
                                      variation: "info"
                                    })), React.createElement(Dialog.Section.make, {
                                  children: React.createElement(RadioCardGroup.make, {
                                        items: purchasePriceTypeItems,
                                        value: purchasePriceTypeValue,
                                        onChange: onChangePurchasePriceTypeValue
                                      }),
                                  title: $$Intl.t("catalog_list.pricing_settings.section_2.title"),
                                  tooltip: React.createElement(React.Fragment, undefined, React.createElement(Tooltip.Span.make, {
                                            text: $$Intl.t("catalog_list.pricing_settings.section_2.tooltip")
                                          }))
                                }), React.createElement(Dialog.Section.make, {
                                  children: React.createElement(RadioCardGroup.make, {
                                        orientation: "vertical",
                                        items: retailPriceCalculatingMethodItems,
                                        value: retailPriceCalculatingMethodValue,
                                        onChange: onChangeRetailPriceCalculatingMethodValue
                                      }),
                                  title: $$Intl.t("catalog_list.pricing_settings.section_3.title"),
                                  tooltip: React.createElement(React.Fragment, undefined, React.createElement(Tooltip.Span.make, {
                                            text: $$Intl.t("catalog_list.pricing_settings.section_3.tooltip")
                                          }))
                                })),
                        triggerRef: popoverTriggerRef,
                        state: popover$1,
                        placement: "bottom start",
                        animation: "fadeTranslation",
                        modal: false,
                        offset: 4
                      }) : null);
    });

var CatalogListSettingsPopoverButton = {
  priceDifferentialIndicatorItems: priceDifferentialIndicatorItems,
  purchasePriceTypeItems: purchasePriceTypeItems,
  retailPriceCalculatingMethodItems: retailPriceCalculatingMethodItems,
  make: make$1
};

var endpoint = Env.sheetUrl(undefined) + "/inventory-export";

function encodeRequestBodyJson(shopIds, variantsActive, variantsArchived, categoryId, supplierId, producer, stock, param) {
  var filterBy = {};
  filterBy["shopIds"] = Json.encodeDict(Js_dict.fromArray([[
              "_in",
              Json.encodeArray(Belt_Array.map(shopIds, Json.encodeString))
            ]]));
  if (variantsActive !== undefined) {
    filterBy["active"] = Json.encodeDict(Js_dict.fromArray([[
                "_equals",
                Json.encodeBoolean(variantsActive)
              ]]));
  }
  if (variantsArchived !== undefined) {
    filterBy["archived"] = Json.encodeString(variantsArchived ? "ONLY" : "EXCLUDED");
  } else {
    filterBy["archived"] = Json.encodeString("INCLUDED");
  }
  if (categoryId !== undefined) {
    var categoryId$1 = Caml_option.valFromOption(categoryId);
    filterBy["categoryId"] = Json.encodeDict(Js_dict.fromArray([[
                "_equals",
                (categoryId$1 == null) ? Json.encodedNull : Json.encodeString(categoryId$1)
              ]]));
  }
  if (supplierId !== undefined) {
    var supplierId$1 = Caml_option.valFromOption(supplierId);
    filterBy["supplierId"] = Json.encodeDict(Js_dict.fromArray([[
                "_equals",
                (supplierId$1 == null) ? Json.encodedNull : Json.encodeString(supplierId$1)
              ]]));
  }
  if (producer !== undefined) {
    filterBy["producer"] = Json.encodeDict(Js_dict.fromArray([[
                "_equals",
                Json.encodeString(producer)
              ]]));
  }
  if (stock !== undefined) {
    var max = stock.max;
    var min = stock.min;
    filterBy["stock"] = Json.encodeDict(Js_dict.fromArray([
              min !== undefined ? [
                  "_min",
                  Json.encodeNumber(min)
                ] : [
                  "_min",
                  Json.encodedNull
                ],
              max !== undefined ? [
                  "_max",
                  Json.encodeNumber(max)
                ] : [
                  "_max",
                  Json.encodedNull
                ]
            ]));
  }
  return Json.encodeDict(Js_dict.fromArray([
                  [
                    "filterBy",
                    Json.encodeDict(filterBy)
                  ],
                  [
                    "timeZone",
                    Json.encodeString($$Intl.timeZone)
                  ]
                ]));
}

function CatalogListPage$CatalogListInventoryExportShortIconButton(Props) {
  var tooltip = Props.tooltip;
  var shopIds = Props.shopIds;
  var activeFilters = Props.activeFilters;
  var variantsActive = Props.variantsActive;
  var variantsArchived = Props.variantsArchived;
  var categoryId = Props.categoryId;
  var supplierId = Props.supplierId;
  var producer = Props.producer;
  var stock = Props.stock;
  var onRequestErrorNotification = Props.onRequestErrorNotification;
  var request = function (param) {
    return $$Request.make("POST", Caml_option.some(encodeRequestBodyJson(shopIds, variantsActive, variantsArchived, categoryId, supplierId, producer, stock, undefined)), undefined, undefined, endpoint);
  };
  var onFailure = function (error) {
    Curry._1(onRequestErrorNotification, RequestOpenStorageUrlButton.failureErrorToString(error, "inventory"));
  };
  var operableRequest = {
    TAG: /* Ok */0,
    _0: request
  };
  return React.createElement(RequestOpenStorageUrlButton.make, {
              variation: "compact",
              statusLight: activeFilters,
              text: tooltip,
              operableRequest: operableRequest,
              onFailure: onFailure
            });
}

var CatalogListInventoryExportShortIconButton = {
  endpoint: endpoint,
  encodeRequestBodyJson: encodeRequestBodyJson,
  make: CatalogListPage$CatalogListInventoryExportShortIconButton
};

var endpoint$1 = Env.sheetUrl(undefined) + "/variants-price-look-up-codes";

function encodeRequestBodyJson$1(shopId, variantsActive, variantsArchived, param) {
  var body = {};
  body["shopId"] = Json.encodeString(shopId);
  if (variantsActive !== undefined) {
    body["variantsActive"] = Json.encodeBoolean(variantsActive);
  }
  if (variantsArchived !== undefined) {
    body["variantsArchived"] = Json.encodeBoolean(variantsArchived);
  }
  return Json.encodeDict(body);
}

function CatalogListPage$CatalogListPriceLookUpExportMenuItem(Props) {
  var text = Props.text;
  var shopId = Props.shopId;
  var variantsActive = Props.variantsActive;
  var variantsArchived = Props.variantsArchived;
  var onRequestErrorNotification = Props.onRequestErrorNotification;
  var match = Popover.useState(undefined);
  var onRequestClose = match.close;
  var request = function (param) {
    return $$Request.make("POST", Caml_option.some(encodeRequestBodyJson$1(Belt_Option.getWithDefault(shopId, ""), variantsActive, variantsArchived, undefined)), undefined, undefined, endpoint$1);
  };
  var onSuccess = function (param) {
    Curry._1(onRequestClose, undefined);
  };
  var onFailure = function (error) {
    var errorMessage = RequestOpenStorageUrlMenuItem.failureErrorToString(error, "PLU codes");
    Curry._1(onRequestErrorNotification, errorMessage);
    Curry._1(onRequestClose, undefined);
  };
  var operableRequest = shopId !== undefined ? ({
        TAG: /* Ok */0,
        _0: request
      }) : ({
        TAG: /* Error */1,
        _0: $$Intl.t("Please select a shop beforehand with the filter.")
      });
  return React.createElement(RequestOpenStorageUrlMenuItem.make, {
              text: text,
              operableRequest: operableRequest,
              onSuccess: onSuccess,
              onFailure: onFailure
            });
}

var CatalogListPriceLookUpExportMenuItem = {
  endpoint: endpoint$1,
  encodeRequestBodyJson: encodeRequestBodyJson$1,
  make: CatalogListPage$CatalogListPriceLookUpExportMenuItem
};

var endpoint$2 = Env.gatewayUrl(undefined) + "/variants-centralization";

function encodeRequestBodyJson$2(param) {
  return Json.encodeDict({});
}

function decodeRequestResponseJson(json) {
  var decodedIssuesCount = Json.flatDecodeDictField(Json.decodeDict(json), "issuesCount", Json.decodeNumber);
  var decodedPatchesCount = Json.flatDecodeDictField(Json.decodeDict(json), "patchesCount", Json.decodeNumber);
  var decodedFileUrl = Json.flatDecodeDictFieldString(Json.flatDecodeDictField(Json.decodeDict(json), "file", Json.decodeDict), "url");
  if (decodedIssuesCount !== undefined && decodedPatchesCount !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: {
              issuesCount: decodedIssuesCount | 0,
              patchesCount: decodedPatchesCount | 0,
              fileUrl: Belt_Option.map(decodedFileUrl, (function (prim) {
                      return new URL(prim);
                    }))
            }
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: /* MalformedResponse */1
          };
  }
}

function renderPatchesText(patchesCount) {
  return React.createElement(TextStyle.make, {
              children: $$Intl.template("▪ " + $$Intl.t($$Intl.isPlural(patchesCount) ? "{{productCount}} new products have been successfully centralized." : "1 new product has been successfully centralized."), {
                    productCount: String(patchesCount)
                  }, undefined),
              variation: "success",
              weight: "medium"
            });
}

function renderIssuesText(issuesCount) {
  return React.createElement(TextStyle.make, {
              children: $$Intl.template("▪ " + $$Intl.t($$Intl.isPlural(issuesCount) ? "{{productCount}} errors detected, some products could not be centralized." : "1 error detected, a product could not be centralized."), {
                    productCount: String(issuesCount)
                  }, undefined),
              variation: "negative",
              weight: "medium"
            });
}

var make$2 = React.memo(function (Props) {
      var onPressHelpCenterText = Props.onPressHelpCenterText;
      var onRequestClose = Props.onRequestClose;
      return React.createElement(Stack.make, {
                  children: null,
                  space: "xxlarge"
                }, React.createElement(Stack.make, {
                      children: null,
                      space: "medium"
                    }, React.createElement(Stack.make, {
                          children: null
                        }, React.createElement(TextStyle.make, {
                              children: $$Intl.t("Following the launch of the catalog centralization operation, here are the results obtained:")
                            }), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                          children: "▪ " + $$Intl.t("no new product has been centralized."),
                                          weight: "medium"
                                        })), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined))), React.createElement(Inline.make, {
                          children: null,
                          space: "none"
                        }, React.createElement(TextStyle.make, {
                              children: $$Intl.t("To know more about the catalog centralization") + ", "
                            }), React.createElement(TextAction.make, {
                              text: $$Intl.t("please visit the help center."),
                              highlighted: true,
                              onPress: onPressHelpCenterText
                            }))), React.createElement(Inline.make, {
                      children: React.createElement(Button.make, Button.makeProps($$Intl.t("Close"), "large", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                  Curry._1(onRequestClose, undefined);
                                }), undefined, undefined, undefined)),
                      align: "end",
                      grow: true
                    }));
    });

var NoIssueNoPatchView = {
  make: make$2
};

var make$3 = React.memo(function (Props) {
      var issuesCount = Props.issuesCount;
      var onPressHelpCenterText = Props.onPressHelpCenterText;
      var onRequestDownloadFile = Props.onRequestDownloadFile;
      return React.createElement(Stack.make, {
                  children: null,
                  space: "xxlarge"
                }, React.createElement(Stack.make, {
                      children: null,
                      space: "medium"
                    }, React.createElement(Stack.make, {
                          children: null
                        }, React.createElement(TextStyle.make, {
                              children: $$Intl.t("Following the launch of the catalog centralization operation, here are the results obtained:")
                            }), React.createElement(Box.make, Box.makeProps(Caml_option.some(null), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(TextStyle.make, {
                                  children: "▪ " + $$Intl.t("no new product has been centralized."),
                                  weight: "medium"
                                }), renderIssuesText(issuesCount))), React.createElement(TextStyle.make, {
                          children: $$Intl.t("The products could not be centralized because identical SKUs were detected within the same store. Click on the \"Download errors reporting\" button to retrieve the list of products to correct.")
                        }), React.createElement(Inline.make, {
                          children: null,
                          space: "none"
                        }, React.createElement(TextStyle.make, {
                              children: $$Intl.t("To find out what corrections need to be made") + ", "
                            }), React.createElement(TextAction.make, {
                              text: $$Intl.t("please visit the help center."),
                              highlighted: true,
                              onPress: onPressHelpCenterText
                            }))), React.createElement(Inline.make, {
                      children: React.createElement(Button.make, Button.makeProps($$Intl.t("Download errors reporting"), "large", "primary", undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                  Curry._1(onRequestDownloadFile, undefined);
                                }), undefined, undefined, undefined)),
                      align: "end",
                      grow: true
                    }));
    });

var IssueNoPatchView = {
  make: make$3
};

var make$4 = React.memo(function (Props) {
      var patchesCount = Props.patchesCount;
      var onPressHelpCenterText = Props.onPressHelpCenterText;
      var onRequestClose = Props.onRequestClose;
      return React.createElement(Stack.make, {
                  children: React.createElement(Stack.make, {
                        children: null,
                        space: "xxlarge"
                      }, React.createElement(Stack.make, {
                            children: null,
                            space: "medium"
                          }, React.createElement(Stack.make, {
                                children: null
                              }, React.createElement(TextStyle.make, {
                                    children: $$Intl.t("Following the launch of the catalog centralization operation, here are the results obtained:")
                                  }), React.createElement(Box.make, Box.makeProps(Caml_option.some(renderPatchesText(patchesCount)), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined))), React.createElement(Inline.make, {
                                children: null,
                                space: "none"
                              }, React.createElement(TextStyle.make, {
                                    children: $$Intl.t("To know more about the catalog centralization") + ", "
                                  }), React.createElement(TextAction.make, {
                                    text: $$Intl.t("please visit the help center."),
                                    highlighted: true,
                                    onPress: onPressHelpCenterText
                                  }))), React.createElement(Inline.make, {
                            children: React.createElement(Button.make, Button.makeProps($$Intl.t("Close"), "large", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                        Curry._1(onRequestClose, undefined);
                                      }), undefined, undefined, undefined)),
                            align: "end",
                            grow: true
                          })),
                  space: "xxlarge"
                });
    });

var NoIssuePatchView = {
  make: make$4
};

var make$5 = React.memo(function (Props) {
      var issuesCount = Props.issuesCount;
      var patchesCount = Props.patchesCount;
      var onPressHelpCenterText = Props.onPressHelpCenterText;
      var onRequestDownloadFile = Props.onRequestDownloadFile;
      return React.createElement(Stack.make, {
                  children: React.createElement(Stack.make, {
                        children: null,
                        space: "xxlarge"
                      }, React.createElement(Stack.make, {
                            children: null,
                            space: "medium"
                          }, React.createElement(Stack.make, {
                                children: null
                              }, React.createElement(TextStyle.make, {
                                    children: $$Intl.t("Following the launch of the catalog centralization operation, here are the results obtained:")
                                  }), React.createElement(Box.make, Box.makeProps(Caml_option.some(null), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined), renderPatchesText(patchesCount), renderIssuesText(issuesCount))), React.createElement(TextStyle.make, {
                                children: $$Intl.t("The products could not be centralized because identical SKUs were detected within the same store. Click on the \"Download errors reporting\" button to retrieve the list of products to correct.")
                              }), React.createElement(Inline.make, {
                                children: null,
                                space: "none"
                              }, React.createElement(TextStyle.make, {
                                    children: $$Intl.t("To find out what corrections need to be made") + ", "
                                  }), React.createElement(TextAction.make, {
                                    text: $$Intl.t("please visit the help center."),
                                    highlighted: true,
                                    onPress: onPressHelpCenterText
                                  }))), React.createElement(Inline.make, {
                            children: React.createElement(Button.make, Button.makeProps($$Intl.t("Download errors reporting"), "large", "primary", undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                        Curry._1(onRequestDownloadFile, undefined);
                                      }), undefined, undefined, undefined)),
                            align: "end",
                            grow: true
                          })),
                  space: "xxlarge"
                });
    });

var IssuePatchView = {
  make: make$5
};

var make$6 = React.memo(function (Props) {
      var issuesCount = Props.issuesCount;
      var patchesCount = Props.patchesCount;
      var fileUrl = Props.fileUrl;
      var onRequestErrorNotification = Props.onRequestErrorNotification;
      var onRequestClose = Props.onRequestClose;
      var match = React.useState(function () {
            return true;
          });
      var setOpened = match[1];
      var onPressHelpCenterText = function (param) {
        HelpCenter.showArticle(HelpCenter.howCentralizeCatalog);
      };
      var onRequestClose$1 = function (param) {
        Curry._1(setOpened, (function (param) {
                return false;
              }));
        Curry._1(onRequestClose, undefined);
      };
      var onRequestDownloadFile = function (param) {
        Belt_Option.forEach(fileUrl, (function (url) {
                Future.get(Future.mapError(TriggerDownload.fromUrl(undefined, url), undefined, (function (param) {
                            return Curry._1(onRequestErrorNotification, $$Intl.t("An issue when attempting downloading the file occurred."));
                          })), (function (param) {
                        Curry._1(setOpened, (function (param) {
                                return false;
                              }));
                        Curry._1(onRequestClose, undefined);
                      }));
              }));
      };
      var match$1 = issuesCount > 0;
      var match$2 = patchesCount > 0;
      return React.createElement(Modal.make, {
                  children: React.createElement(Box.make, Box.makeProps(Caml_option.some(match$1 ? (
                                  match$2 ? React.createElement(make$5, {
                                          issuesCount: issuesCount,
                                          patchesCount: patchesCount,
                                          onPressHelpCenterText: onPressHelpCenterText,
                                          onRequestDownloadFile: onRequestDownloadFile
                                        }) : React.createElement(make$3, {
                                          issuesCount: issuesCount,
                                          onPressHelpCenterText: onPressHelpCenterText,
                                          onRequestDownloadFile: onRequestDownloadFile
                                        })
                                ) : (
                                  match$2 ? React.createElement(make$4, {
                                          patchesCount: patchesCount,
                                          onPressHelpCenterText: onPressHelpCenterText,
                                          onRequestClose: onRequestClose$1
                                        }) : React.createElement(make$2, {
                                          onPressHelpCenterText: onPressHelpCenterText,
                                          onRequestClose: onRequestClose$1
                                        })
                                )), "xlarge", "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                  opened: match[0],
                  title: $$Intl.t("Centralize catalogs"),
                  variation: "secondary",
                  hideFooter: true,
                  onRequestClose: onRequestClose$1
                });
    });

var ReportModal = {
  renderPatchesText: renderPatchesText,
  renderIssuesText: renderIssuesText,
  NoIssueNoPatchView: NoIssueNoPatchView,
  IssueNoPatchView: IssueNoPatchView,
  NoIssuePatchView: NoIssuePatchView,
  IssuePatchView: IssuePatchView,
  make: make$6
};

function CatalogListPage$CatalogListCentralizeRequestMenuItem(Props) {
  var text = Props.text;
  var onRequestErrorNotification = Props.onRequestErrorNotification;
  var match = Popover.useState(undefined);
  var match$1 = React.useState(function () {
        return /* NotAsked */0;
      });
  var setAsyncResult = match$1[1];
  var asyncResult = match$1[0];
  React.useEffect((function () {
          if (typeof asyncResult !== "number" && asyncResult.TAG === /* Done */1 && asyncResult._0.TAG !== /* Ok */0) {
            Curry._1(onRequestErrorNotification, $$Request.serverErrorMessage);
          }
          
        }), [asyncResult]);
  var request = function (param) {
    return Future.map($$Request.make("POST", Caml_option.some(Json.encodeDict({})), undefined, undefined, endpoint$2), undefined, (function (json) {
                  return Belt_Result.flatMap(json, decodeRequestResponseJson);
                }));
  };
  var onChange = function (asyncResult) {
    if (typeof asyncResult !== "number" && asyncResult.TAG === /* Done */1) {
      var match = asyncResult._0;
      if (match.TAG !== /* Ok */0) {
        return Curry._1(setAsyncResult, (function (param) {
                      return asyncResult;
                    }));
      }
      var heuristicTimeout = 1000 + Math.imul(match._0.patchesCount, 175) | 0;
      setTimeout((function (param) {
              Curry._1(setAsyncResult, (function (param) {
                      return asyncResult;
                    }));
            }), heuristicTimeout);
      return ;
    }
    Curry._1(setAsyncResult, (function (param) {
            return asyncResult;
          }));
  };
  var operableRequest = {
    TAG: /* Ok */0,
    _0: request
  };
  var tmp;
  if (typeof asyncResult === "number" || asyncResult.TAG !== /* Done */1) {
    tmp = null;
  } else {
    var match$2 = asyncResult._0;
    if (match$2.TAG === /* Ok */0) {
      var match$3 = match$2._0;
      tmp = React.createElement(make$6, {
            issuesCount: match$3.issuesCount,
            patchesCount: match$3.patchesCount,
            fileUrl: match$3.fileUrl,
            onRequestErrorNotification: onRequestErrorNotification,
            onRequestClose: match.close
          });
    } else {
      tmp = null;
    }
  }
  return React.createElement(React.Fragment, undefined, React.createElement(SpinnerModal.make, {
                  title: text,
                  opened: AsyncResult.isBusy(asyncResult)
                }), React.createElement(RequestMenuItem.make, {
                  text: text,
                  operableRequest: operableRequest,
                  onChange: onChange
                }), tmp);
}

var CatalogListCentralizeRequestMenuItem = {
  endpoint: endpoint$2,
  encodeRequestBodyJson: encodeRequestBodyJson$2,
  decodeRequestResponseJson: decodeRequestResponseJson,
  ReportModal: ReportModal,
  make: CatalogListPage$CatalogListCentralizeRequestMenuItem
};

var jsonCodec$1 = JsonCodec.object2((function (param) {
        return [
                param.id,
                param.name
              ];
      }), (function (param) {
        return {
                TAG: /* Ok */0,
                _0: {
                  id: param[0],
                  name: param[1]
                }
              };
      }), JsonCodec.field("priceId", JsonCodec.string), JsonCodec.field("priceName", JsonCodec.string));

var CatalogListExtraParamsPrice = {
  jsonCodec: jsonCodec$1
};

function selectByPricelists(pricelists, currentPrice) {
  var pricelist = Belt_Option.orElse(Belt_Array.getBy(pricelists, (function (current) {
              if (currentPrice !== undefined) {
                return currentPrice.id === current.id;
              } else {
                return false;
              }
            })), Belt_Array.getBy(pricelists, (function (current) {
              if (currentPrice !== undefined) {
                return currentPrice.name.toLowerCase() === current.name.toLowerCase();
              } else {
                return false;
              }
            })));
  var defaultPricelist = Belt_Option.orElse(Belt_Array.getBy(pricelists, (function (pricelist) {
              return pricelist.enableByDefault;
            })), Belt_Array.get(pricelists, 0));
  return Belt_Option.orElse(pricelist, defaultPricelist);
}

var make$7 = React.memo(function (Props) {
      var shopId = Props.shopId;
      var pricelistsAsyncResult = Props.pricelistsAsyncResult;
      var price = Props.price;
      var onRequestPriceChange = Props.onRequestPriceChange;
      var match = React.useState(function () {
            
          });
      var setSelectedPrice = match[1];
      var selectedPrice = match[0];
      ReactUpdateEffect.use1((function (param) {
              Curry._1(setSelectedPrice, (function (param) {
                      
                    }));
            }), [shopId]);
      ReactUpdateEffect.use1((function (param) {
              if (typeof pricelistsAsyncResult !== "number" && pricelistsAsyncResult.TAG === /* Done */1) {
                var pricelists = pricelistsAsyncResult._0;
                if (pricelists.TAG === /* Ok */0 && Belt_Option.isNone(selectedPrice)) {
                  var pricelist = selectByPricelists(pricelists._0, price);
                  Curry._1(setSelectedPrice, (function (param) {
                          return pricelist;
                        }));
                }
                
              }
              
            }), [pricelistsAsyncResult]);
      ReactUpdateEffect.use1((function (param) {
              if (selectedPrice !== undefined) {
                Curry._1(onRequestPriceChange, {
                      id: selectedPrice.id,
                      name: selectedPrice.name
                    });
              }
              
            }), [selectedPrice]);
      var tmp;
      if (typeof pricelistsAsyncResult === "number") {
        tmp = [];
      } else if (pricelistsAsyncResult.TAG === /* Done */1) {
        var pricelists = pricelistsAsyncResult._0;
        if (pricelists.TAG === /* Ok */0) {
          var sortedPricelists = Belt_SortArray.stableSortBy(pricelists._0, (function (next, current) {
                  if (current.enableByDefault !== next.enableByDefault) {
                    return 1;
                  } else {
                    return 0;
                  }
                }));
          tmp = Belt_Array.map(sortedPricelists, (function (item) {
                  return {
                          key: item.id,
                          value: item,
                          label: item.name
                        };
                }));
        } else {
          tmp = [];
        }
      } else {
        tmp = [];
      }
      var sections = [{
          items: tmp
        }];
      var renderTriggerView = function (param, param$1, hovered, active, param$2) {
        return React.createElement(Icon.make, {
                    name: "settings_bold",
                    fill: hovered || active ? "#25243a" : "#797885",
                    size: 12
                  });
      };
      var renderItemContent = React.useCallback((function (item) {
              var enableByDefault = Belt_Option.mapWithDefault(item.value, false, (function (value) {
                      return value.enableByDefault;
                    }));
              var formattedLabel = item.label + (
                enableByDefault ? " *" : ""
              );
              var match = item.value;
              var tmp;
              if (match !== undefined) {
                var taxTypename = $$Intl.t(match.taxIncluded ? "VAT incl." : "VAT excl.");
                tmp = React.createElement(Badge.make, {
                      children: taxTypename,
                      size: "small",
                      variation: "neutral"
                    });
              } else {
                tmp = null;
              }
              return React.createElement(Tooltip.make, {
                          children: React.createElement(Inline.make, {
                                children: null,
                                space: "normal",
                                align: "spaceBetween"
                              }, React.createElement("span", undefined, formattedLabel), tmp),
                          placement: "start",
                          content: React.createElement(Tooltip.Span.make, {
                                text: $$Intl.t("By default")
                              }),
                          disabled: !enableByDefault,
                          closeDelay: 0
                        });
            }), []);
      return React.createElement(Select.make, {
                  label: $$Intl.t("Price list"),
                  sections: sections,
                  preset: {
                    NAME: "inputField",
                    VAL: {
                      required: false
                    }
                  },
                  overlayNoResultLabel: $$Intl.t("No price list found"),
                  renderTriggerView: renderTriggerView,
                  renderItemContent: renderItemContent,
                  value: selectedPrice,
                  onChange: (function (value) {
                      Curry._1(setSelectedPrice, (function (param) {
                              return value;
                            }));
                    })
                });
    });

var CatalogListTableHeaderActionPricelistSelect = {
  selectByPricelists: selectByPricelists,
  make: make$7
};

var Raw$3 = {};

var query$3 = Client.gql(["mutation updatePurchasePrice($id: ID!, $input: InputUpdateVariant!)  {\nupdateVariant(id: $id, input: $input)  {\n__typename  \nid  \npurchasedPrice  \n}\n\n}\n"]);

function parse$3(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.purchasedPrice;
  return {
          updateVariant: {
            __typename: value$1.__typename,
            id: value$1.id,
            purchasedPrice: !(value$2 == null) ? value$2 : undefined
          }
        };
}

function serialize$3(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.purchasedPrice;
  var purchasedPrice = value$2 !== undefined ? value$2 : null;
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var updateVariant = {
    __typename: value$4,
    id: value$3,
    purchasedPrice: purchasedPrice
  };
  return {
          updateVariant: updateVariant
        };
}

function serializeInputObjectInputUpdateVariantMetaData(inp) {
  var a = inp.shopifyVariantId;
  return {
          shopifyVariantId: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInputUpdateVariant(inp) {
  var a = inp.stockKeepingUnit;
  var a$1 = inp.name;
  var a$2 = inp.purchasedPrice;
  var a$3 = inp.year;
  var a$4 = inp.capacityValue;
  var a$5 = inp.capacityUnit;
  var a$6 = inp.capacityPrecision;
  var a$7 = inp.alcoholVolume;
  var a$8 = inp.ean13;
  var a$9 = inp.internalNote;
  var a$10 = inp.metadata;
  var a$11 = inp.tastingNote;
  var a$12 = inp.packaging;
  var a$13 = inp.internalCode;
  var a$14 = inp.priceLookUpCode;
  var a$15 = inp.supplierId;
  var a$16 = inp.minStockThreshold;
  var a$17 = inp.maxStockThreshold;
  var a$18 = inp.stockOrderTriggerThreshold;
  return {
          stockKeepingUnit: a !== undefined ? a : undefined,
          name: a$1 !== undefined ? a$1 : undefined,
          purchasedPrice: a$2 !== undefined ? a$2 : undefined,
          year: a$3 !== undefined ? a$3 : undefined,
          capacityValue: a$4 !== undefined ? a$4 : undefined,
          capacityUnit: a$5 !== undefined ? a$5 : undefined,
          capacityPrecision: a$6 !== undefined ? a$6 : undefined,
          alcoholVolume: a$7 !== undefined ? a$7 : undefined,
          ean13: a$8 !== undefined ? a$8 : undefined,
          internalNote: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          metadata: a$10 !== undefined ? serializeInputObjectInputUpdateVariantMetaData(a$10) : undefined,
          tastingNote: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          packaging: a$12 !== undefined ? a$12 : undefined,
          internalCode: a$13 !== undefined ? a$13 : undefined,
          priceLookUpCode: a$14 !== undefined ? a$14 : undefined,
          supplierId: a$15 !== undefined ? a$15 : undefined,
          minStockThreshold: a$16 !== undefined ? a$16 : undefined,
          maxStockThreshold: a$17 !== undefined ? a$17 : undefined,
          stockOrderTriggerThreshold: a$18 !== undefined ? a$18 : undefined
        };
}

function serializeVariables$3(inp) {
  return {
          id: inp.id,
          input: serializeInputObjectInputUpdateVariant(inp.input)
        };
}

function makeVariables$3(id, input, param) {
  return {
          id: id,
          input: input
        };
}

function makeInputObjectInputUpdateVariant(stockKeepingUnit, name, purchasedPrice, year, capacityValue, capacityUnit, capacityPrecision, alcoholVolume, ean13, internalNote, metadata, tastingNote, packaging, internalCode, priceLookUpCode, supplierId, minStockThreshold, maxStockThreshold, stockOrderTriggerThreshold, param) {
  return {
          stockKeepingUnit: stockKeepingUnit,
          name: name,
          purchasedPrice: purchasedPrice,
          year: year,
          capacityValue: capacityValue,
          capacityUnit: capacityUnit,
          capacityPrecision: capacityPrecision,
          alcoholVolume: alcoholVolume,
          ean13: ean13,
          internalNote: internalNote,
          metadata: metadata,
          tastingNote: tastingNote,
          packaging: packaging,
          internalCode: internalCode,
          priceLookUpCode: priceLookUpCode,
          supplierId: supplierId,
          minStockThreshold: minStockThreshold,
          maxStockThreshold: maxStockThreshold,
          stockOrderTriggerThreshold: stockOrderTriggerThreshold
        };
}

function makeInputObjectInputUpdateVariantMetaData(shopifyVariantId, param) {
  return {
          shopifyVariantId: shopifyVariantId
        };
}

var Mutation_inner = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData,
  makeVariables: makeVariables$3,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData
};

var include$3 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$3,
      Raw: Raw$3,
      parse: parse$3,
      serialize: serialize$3,
      serializeVariables: serializeVariables$3
    });

var use$3 = include$3.use;

var Mutation_useWithVariables = include$3.useWithVariables;

var Mutation = {
  Mutation_inner: Mutation_inner,
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData,
  makeVariables: makeVariables$3,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData,
  use: use$3,
  useWithVariables: Mutation_useWithVariables
};

function useMutation(variantId) {
  var match = Curry.app(use$3, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return function (purchasePrice) {
    var input = makeInputObjectInputUpdateVariant(undefined, undefined, purchasePrice, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    return Future.mapError(Future.mapOk(ApolloHelpers.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                            id: variantId,
                            input: input
                          })), undefined, (function (param) {
                      
                    })), undefined, (function (param) {
                  
                }));
  };
}

var amountInputNumberAppender = {
  TAG: /* Custom */1,
  _0: $$Intl.toCurrencySymbol("EUR")
};

function priceInputNumberAppender(bulkUnit) {
  return {
          TAG: /* Custom */1,
          _0: $$Intl.toCurrencySymbol("EUR") + Belt_Option.mapWithDefault(bulkUnit, "", (function (unit) {
                  return " / " + unit + "";
                }))
        };
}

var make$8 = React.memo(function (Props) {
      var priceIsNet = Props.priceIsNet;
      var bulkUnit = Props.bulkUnit;
      var value = Props.value;
      var onChange = Props.onChange;
      var priceInputAppender = priceInputNumberAppender(bulkUnit);
      return React.createElement(Dialog.Section.make, {
                  children: React.createElement(Stack.make, {
                        children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(InputNumberField.make, {
                                          value: value,
                                          minValue: 0,
                                          precision: 3,
                                          label: priceIsNet ? $$Intl.t("Net purchase price") : $$Intl.t("Gross purchase price"),
                                          appender: priceInputAppender,
                                          strongStyle: true,
                                          onChange: onChange
                                        })), undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                        space: "medium"
                      }),
                  title: $$Intl.t("catalog_list.purchase_price_cell.dialog.section_final")
                });
    });

var PurchasePriceCalculatingMethodDialogSections = {
  make: make$8
};

var make$9 = React.memo(function (Props) {
      var priceIsNet = Props.priceIsNet;
      var feesList = Props.feesList;
      var bulkUnit = Props.bulkUnit;
      var purchasePrice = Props.purchasePrice;
      var onChangePurchasePrice = Props.onChangePurchasePrice;
      var otherFeeAmount = feesList.otherAmount;
      var taxesFeeAmount = feesList.taxesAmount;
      var onFullPurchasePriceChange = function (fullPurchasePrice) {
        Curry._1(onChangePurchasePrice, fullPurchasePrice - taxesFeeAmount - otherFeeAmount);
      };
      var fullPurchasePrice = computeFullPurchasePrice(purchasePrice, taxesFeeAmount, otherFeeAmount);
      var priceInputAppender = priceInputNumberAppender(bulkUnit);
      var beforeChargesSectionDefaultCollapsed = purchasePrice === fullPurchasePrice;
      var chargesSectionDefaultCollapsed = taxesFeeAmount === 0 && otherFeeAmount === 0;
      return React.createElement(React.Fragment, undefined, React.createElement(Dialog.Section.make, {
                      children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(InputNumberField.make, {
                                        value: purchasePrice,
                                        minValue: 0,
                                        precision: 3,
                                        label: $$Intl.t("catalog_list.purchase_price_cell.dialog.section_before_charges.input_label" + (
                                              priceIsNet ? ".net" : ".gross"
                                            )),
                                        appender: priceInputAppender,
                                        onChange: onChangePurchasePrice
                                      })), undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                      title: $$Intl.t("catalog_list.purchase_price_cell.dialog.section_before_charges"),
                      collapsable: true,
                      defaultCollapsed: beforeChargesSectionDefaultCollapsed
                    }), React.createElement(Dialog.Section.make, {
                      children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Group.make, {
                                        children: null,
                                        spaceX: "normal"
                                      }, React.createElement(InputNumberField.make, {
                                            value: taxesFeeAmount,
                                            precision: 3,
                                            label: $$Intl.t("catalog_list.purchase_price_cell.dialog.section_charges.taxes_fee.input_label"),
                                            appender: amountInputNumberAppender,
                                            disabled: true,
                                            hideStepper: true,
                                            onChange: (function (param) {
                                                
                                              })
                                          }), React.createElement(InputNumberField.make, {
                                            value: otherFeeAmount,
                                            precision: 3,
                                            label: $$Intl.t("catalog_list.purchase_price_cell.dialog.section_charges.other_fee.input_label"),
                                            appender: amountInputNumberAppender,
                                            disabled: true,
                                            hideStepper: true,
                                            onChange: (function (param) {
                                                
                                              })
                                          }))), undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                      title: $$Intl.t("catalog_list.price_cell.dialog.section_charges"),
                      collapsable: true,
                      defaultCollapsed: chargesSectionDefaultCollapsed
                    }), React.createElement(Dialog.Section.make, {
                      children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(InputNumberField.make, {
                                        value: fullPurchasePrice,
                                        minValue: fullPurchasePrice - purchasePrice,
                                        precision: 3,
                                        label: $$Intl.t("catalog_list.purchase_price_cell.full_purchase_price_calculating_method_dialog.section_final.input_label" + (
                                              priceIsNet ? ".net" : ".gross"
                                            )),
                                        appender: priceInputAppender,
                                        strongStyle: true,
                                        onChange: onFullPurchasePriceChange
                                      })), undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                      title: $$Intl.t("catalog_list.purchase_price_cell.dialog.section_final"),
                      collapsable: true
                    }));
    });

var FullPurchasePriceCalculatingMethodDialogSections = {
  make: make$9
};

var make$10 = React.memo(function (Props) {
      var priceIsNet = Props.priceIsNet;
      var feesList = Props.feesList;
      var bulkUnit = Props.bulkUnit;
      var purchasePrice = Props.purchasePrice;
      var onChangePurchasePrice = Props.onChangePurchasePrice;
      var otherFeeAmount = feesList.otherAmount;
      var taxesFeeAmount = feesList.taxesAmount;
      var transportFeeAmount = feesList.transportAmount;
      var onAcquisitionCostChange = function (acquisitionCost) {
        Curry._1(onChangePurchasePrice, acquisitionCost - transportFeeAmount - taxesFeeAmount - otherFeeAmount);
      };
      var acquisitionCost = computeAcquisitionCost(purchasePrice, transportFeeAmount, taxesFeeAmount, otherFeeAmount);
      var priceInputAppender = priceInputNumberAppender(bulkUnit);
      var beforeChargesSectionDefaultCollapsed = purchasePrice === acquisitionCost;
      var chargesSectionDefaultCollapsed = transportFeeAmount === 0 && taxesFeeAmount === 0 && otherFeeAmount === 0;
      return React.createElement(React.Fragment, undefined, React.createElement(Dialog.Section.make, {
                      children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(InputNumberField.make, {
                                        value: purchasePrice,
                                        minValue: 0,
                                        precision: 3,
                                        label: $$Intl.t("catalog_list.purchase_price_cell.dialog.section_before_charges.input_label" + (
                                              priceIsNet ? ".net" : ".gross"
                                            )),
                                        appender: priceInputAppender,
                                        onChange: onChangePurchasePrice
                                      })), undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                      title: $$Intl.t("catalog_list.purchase_price_cell.dialog.section_before_charges"),
                      collapsable: true,
                      defaultCollapsed: beforeChargesSectionDefaultCollapsed
                    }), React.createElement(Dialog.Section.make, {
                      children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Group.make, {
                                        children: null,
                                        spaceX: "normal"
                                      }, React.createElement(InputNumberField.make, {
                                            value: transportFeeAmount,
                                            precision: 3,
                                            label: $$Intl.t("catalog_list.purchase_price_cell.dialog.section_charges.transport_fee.input_label"),
                                            appender: amountInputNumberAppender,
                                            disabled: true,
                                            hideStepper: true,
                                            onChange: (function (param) {
                                                
                                              })
                                          }), React.createElement(InputNumberField.make, {
                                            value: taxesFeeAmount,
                                            precision: 3,
                                            label: $$Intl.t("catalog_list.purchase_price_cell.dialog.section_charges.taxes_fee.input_label"),
                                            appender: amountInputNumberAppender,
                                            disabled: true,
                                            hideStepper: true,
                                            onChange: (function (param) {
                                                
                                              })
                                          }), React.createElement(InputNumberField.make, {
                                            value: otherFeeAmount,
                                            precision: 3,
                                            label: $$Intl.t("catalog_list.purchase_price_cell.dialog.section_charges.other_fee.input_label"),
                                            appender: amountInputNumberAppender,
                                            disabled: true,
                                            hideStepper: true,
                                            onChange: (function (param) {
                                                
                                              })
                                          }))), undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                      title: $$Intl.t("catalog_list.price_cell.dialog.section_charges"),
                      collapsable: true,
                      defaultCollapsed: chargesSectionDefaultCollapsed
                    }), React.createElement(Dialog.Section.make, {
                      children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(InputNumberField.make, {
                                        value: acquisitionCost,
                                        minValue: acquisitionCost - purchasePrice,
                                        precision: 3,
                                        label: $$Intl.t("catalog_list.purchase_price_cell.acquisition_cost_calculating_method_dialog.section_final.input_label" + (
                                              priceIsNet ? ".net" : ".gross"
                                            )),
                                        appender: priceInputAppender,
                                        strongStyle: true,
                                        onChange: onAcquisitionCostChange
                                      })), undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                      title: $$Intl.t("catalog_list.purchase_price_cell.dialog.section_final"),
                      collapsable: true
                    }));
    });

var AcquisitionCostPriceCalculatingMethodDialogSections = {
  make: make$10
};

var make$11 = React.memo(function (Props) {
      var variantId = Props.variantId;
      var bulkUnit = Props.bulkUnit;
      var feesList = Props.feesList;
      var discount = Props.discount;
      var purchasePriceType = Props.purchasePriceType;
      var calculatingMethod = Props.calculatingMethod;
      var initialValue = Props.value;
      var useMutationOpt = Props.useMutation;
      var useMutation$1 = useMutationOpt !== undefined ? useMutationOpt : useMutation;
      var match = Popover.useTrigger(undefined, undefined);
      var popoverTriggerRef = match.ref;
      var popoverAriaProps = match.ariaProps;
      var popover = match.state;
      var match$1 = Hover.use(popoverTriggerRef, undefined);
      var triggerHovered = match$1[1];
      var match$2 = React.useState(function () {
            return false;
          });
      var setMutationLoading = match$2[1];
      var mutate = Curry._1(useMutation$1, variantId);
      var match$3 = purchasePriceType ? [
          initialValue - discount,
          true
        ] : [
          initialValue,
          false
        ];
      var priceIsNet = match$3[1];
      var initialValue$1 = match$3[0];
      var match$4 = React.useState(function () {
            return initialValue$1;
          });
      var setValue = match$4[1];
      var value = match$4[0];
      ReactUpdateEffect.use1((function (param) {
              if (popover.isOpen) {
                Curry._1(setValue, (function (param) {
                        return initialValue$1;
                      }));
              }
              
            }), [popover.isOpen]);
      var onCommitRequestClose = popover.close;
      var popover_isOpen = popover.isOpen;
      var popover_close = function (param) {
        if (!triggerHovered) {
          return Curry._1(popover.close, undefined);
        }
        
      };
      var popover_toggle = popover.toggle;
      var popover$1 = {
        isOpen: popover_isOpen,
        close: popover_close,
        toggle: popover_toggle
      };
      var onCommit = React.useCallback((function (param) {
              if (value === initialValue$1) {
                return Curry._1(onCommitRequestClose, undefined);
              }
              Curry._1(setMutationLoading, (function (param) {
                      return true;
                    }));
              var rawPurchasePrice = priceIsNet ? value + discount : value;
              Future.get(Curry._1(mutate, rawPurchasePrice), (function (param) {
                      Curry._1(setMutationLoading, (function (param) {
                              return false;
                            }));
                      Curry._1(onCommitRequestClose, undefined);
                    }));
            }), [
            value,
            initialValue$1
          ]);
      var dialogTitle = toLabel$1(calculatingMethod);
      var computedInitialValue;
      switch (calculatingMethod) {
        case /* PurchasePrice */0 :
            computedInitialValue = initialValue$1;
            break;
        case /* FullPurchasePrice */1 :
            computedInitialValue = computeFullPurchasePrice(initialValue$1, feesList.taxesAmount, feesList.otherAmount);
            break;
        case /* AcquisitionCost */2 :
            computedInitialValue = computeAcquisitionCost(initialValue$1, feesList.transportAmount, feesList.taxesAmount, feesList.otherAmount);
            break;
        
      }
      var formattedValue = $$Intl.currencyFormat("EUR", 3, 3, computedInitialValue);
      var tmp;
      switch (calculatingMethod) {
        case /* PurchasePrice */0 :
            tmp = React.createElement(make$8, {
                  priceIsNet: priceIsNet,
                  bulkUnit: bulkUnit,
                  value: value,
                  onChange: (function (value) {
                      Curry._1(setValue, (function (param) {
                              return value;
                            }));
                    })
                });
            break;
        case /* FullPurchasePrice */1 :
            tmp = React.createElement(make$9, {
                  priceIsNet: priceIsNet,
                  feesList: feesList,
                  bulkUnit: bulkUnit,
                  purchasePrice: value,
                  onChangePurchasePrice: (function (value) {
                      Curry._1(setValue, (function (param) {
                              return value;
                            }));
                    })
                });
            break;
        case /* AcquisitionCost */2 :
            tmp = React.createElement(make$10, {
                  priceIsNet: priceIsNet,
                  feesList: feesList,
                  bulkUnit: bulkUnit,
                  purchasePrice: value,
                  onChangePurchasePrice: (function (value) {
                      Curry._1(setValue, (function (param) {
                              return value;
                            }));
                    })
                });
            break;
        
      }
      return React.createElement(React.Fragment, undefined, React.createElement(OpeningButton.make, OpeningButton.makeProps(React.createElement(TextStyle.make, {
                              children: formattedValue,
                              size: "xsmall"
                            }), popoverAriaProps.triggerProps, popover_isOpen, undefined, (function (param) {
                            Curry._1(popover_toggle, undefined);
                          }), undefined, popoverTriggerRef, undefined)), React.createElement(Popover.make, {
                      children: React.createElement(Dialog.make, {
                            ariaProps: popoverAriaProps.overlayProps,
                            children: tmp,
                            width: 385,
                            title: dialogTitle,
                            commitLoading: match$2[0],
                            commitButtonText: $$Intl.t("catalog_list.purchase_price_cell.dialog.commit_button_text"),
                            onCommit: onCommit,
                            onRequestClose: popover_close
                          }),
                      triggerRef: popoverTriggerRef,
                      state: popover$1,
                      placement: "bottom end",
                      modal: false
                    }));
    });

var CatalogListPurchasePriceCell = {
  Mutation: Mutation,
  useMutation: useMutation,
  amountInputNumberAppender: amountInputNumberAppender,
  priceInputNumberAppender: priceInputNumberAppender,
  PurchasePriceCalculatingMethodDialogSections: PurchasePriceCalculatingMethodDialogSections,
  FullPurchasePriceCalculatingMethodDialogSections: FullPurchasePriceCalculatingMethodDialogSections,
  AcquisitionCostPriceCalculatingMethodDialogSections: AcquisitionCostPriceCalculatingMethodDialogSections,
  make: make$11
};

var Raw$4 = {};

var query$4 = Client.gql(["mutation createOrUpdateVariantPrice($input: inputCreateOrUpdateVariantPriceTypeDef!)  {\ncreateOrUpdateVariantPrice(input: $input)  {\n__typename  \nid  \nvalueExcludingTax  \nvalueIncludingTax  \nprice  {\n__typename  \nid  \n}\n\nvariant  {\n__typename  \nid  \nvariantPrices  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse$4(value) {
  var value$1 = value.createOrUpdateVariantPrice;
  var value$2 = value$1.price;
  var value$3 = value$1.variant;
  var tmp;
  if (value$3 == null) {
    tmp = undefined;
  } else {
    var value$4 = value$3.variantPrices;
    var value$5 = value$4.edges;
    tmp = {
      __typename: value$3.__typename,
      id: value$3.id,
      variantPrices: {
        __typename: value$4.__typename,
        edges: Js_array.map((function (value) {
                var value$1 = value.node;
                return {
                        __typename: value.__typename,
                        node: {
                          __typename: value$1.__typename,
                          id: value$1.id
                        }
                      };
              }), value$5)
      }
    };
  }
  return {
          createOrUpdateVariantPrice: {
            __typename: value$1.__typename,
            id: value$1.id,
            valueExcludingTax: value$1.valueExcludingTax,
            valueIncludingTax: value$1.valueIncludingTax,
            price: !(value$2 == null) ? ({
                  __typename: value$2.__typename,
                  id: value$2.id
                }) : undefined,
            variant: tmp
          }
        };
}

function serialize$4(value) {
  var value$1 = value.createOrUpdateVariantPrice;
  var value$2 = value$1.variant;
  var variant;
  if (value$2 !== undefined) {
    var value$3 = value$2.variantPrices;
    var value$4 = value$3.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var value$2 = value$1.id;
            var value$3 = value$1.__typename;
            var node = {
              __typename: value$3,
              id: value$2
            };
            var value$4 = value.__typename;
            return {
                    __typename: value$4,
                    node: node
                  };
          }), value$4);
    var value$5 = value$3.__typename;
    var variantPrices = {
      __typename: value$5,
      edges: edges
    };
    var value$6 = value$2.id;
    var value$7 = value$2.__typename;
    variant = {
      __typename: value$7,
      id: value$6,
      variantPrices: variantPrices
    };
  } else {
    variant = null;
  }
  var value$8 = value$1.price;
  var price;
  if (value$8 !== undefined) {
    var value$9 = value$8.id;
    var value$10 = value$8.__typename;
    price = {
      __typename: value$10,
      id: value$9
    };
  } else {
    price = null;
  }
  var value$11 = value$1.valueIncludingTax;
  var value$12 = value$1.valueExcludingTax;
  var value$13 = value$1.id;
  var value$14 = value$1.__typename;
  var createOrUpdateVariantPrice = {
    __typename: value$14,
    id: value$13,
    valueExcludingTax: value$12,
    valueIncludingTax: value$11,
    price: price,
    variant: variant
  };
  return {
          createOrUpdateVariantPrice: createOrUpdateVariantPrice
        };
}

function serializeInputObjectinputCreateOrUpdateVariantPriceTypeDef(inp) {
  return {
          valueIncludingTax: inp.valueIncludingTax,
          valueExcludingTax: inp.valueExcludingTax,
          priceId: inp.priceId,
          variantId: inp.variantId,
          shopId: inp.shopId
        };
}

function serializeVariables$4(inp) {
  return {
          input: serializeInputObjectinputCreateOrUpdateVariantPriceTypeDef(inp.input)
        };
}

function makeVariables$4(input, param) {
  return {
          input: input
        };
}

function makeInputObjectinputCreateOrUpdateVariantPriceTypeDef(valueIncludingTax, valueExcludingTax, priceId, variantId, shopId, param) {
  return {
          valueIncludingTax: valueIncludingTax,
          valueExcludingTax: valueExcludingTax,
          priceId: priceId,
          variantId: variantId,
          shopId: shopId
        };
}

var Mutation_inner$1 = {
  Raw: Raw$4,
  query: query$4,
  parse: parse$4,
  serialize: serialize$4,
  serializeVariables: serializeVariables$4,
  serializeInputObjectinputCreateOrUpdateVariantPriceTypeDef: serializeInputObjectinputCreateOrUpdateVariantPriceTypeDef,
  makeVariables: makeVariables$4,
  makeInputObjectinputCreateOrUpdateVariantPriceTypeDef: makeInputObjectinputCreateOrUpdateVariantPriceTypeDef
};

var include$4 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$4,
      Raw: Raw$4,
      parse: parse$4,
      serialize: serialize$4,
      serializeVariables: serializeVariables$4
    });

var use$4 = include$4.use;

var Mutation_useWithVariables$1 = include$4.useWithVariables;

var Mutation$1 = {
  Mutation_inner: Mutation_inner$1,
  Raw: Raw$4,
  query: query$4,
  parse: parse$4,
  serialize: serialize$4,
  serializeVariables: serializeVariables$4,
  serializeInputObjectinputCreateOrUpdateVariantPriceTypeDef: serializeInputObjectinputCreateOrUpdateVariantPriceTypeDef,
  makeVariables: makeVariables$4,
  makeInputObjectinputCreateOrUpdateVariantPriceTypeDef: makeInputObjectinputCreateOrUpdateVariantPriceTypeDef,
  use: use$4,
  useWithVariables: Mutation_useWithVariables$1
};

function useMutation$1(variantId, priceId, shopId) {
  var match = Curry.app(use$4, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return function (valueIncludingTax, valueExcludingTax) {
    var input = makeInputObjectinputCreateOrUpdateVariantPriceTypeDef(valueIncludingTax, valueExcludingTax, priceId, variantId, shopId, undefined);
    return Future.mapError(Future.mapOk(ApolloHelpers.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                            input: input
                          })), undefined, (function (param) {
                      
                    })), undefined, (function (param) {
                  
                }));
  };
}

var amountInputNumberAppender$1 = {
  TAG: /* Custom */1,
  _0: $$Intl.toCurrencySymbol("EUR")
};

function priceInputNumberAppender$1(bulkUnit) {
  return {
          TAG: /* Custom */1,
          _0: $$Intl.toCurrencySymbol("EUR") + Belt_Option.mapWithDefault(bulkUnit, "", (function (unit) {
                  return " / " + unit + "";
                }))
        };
}

var make$12 = React.memo(function (Props) {
      var feesList = Props.feesList;
      var bulkUnit = Props.bulkUnit;
      var taxIncluded = Props.taxIncluded;
      var taxRate = Props.taxRate;
      var purchasePrice = Props.purchasePrice;
      var priceExcludingTaxValue = Props.value;
      var onChange = Props.onChange;
      var otherFeeAmountValue = feesList.otherAmount;
      var taxesFeeAmountValue = feesList.taxesAmount;
      var transportFeeAmountValue = feesList.transportAmount;
      var totalChargesAmount = transportFeeAmountValue + taxesFeeAmountValue + otherFeeAmountValue;
      var priceCoefficientValue = (priceExcludingTaxValue - totalChargesAmount) / purchasePrice;
      var priceExcludingChargesAndTaxValue = priceExcludingTaxValue - totalChargesAmount;
      var marginExcludingTaxValue = priceExcludingTaxValue - purchasePrice;
      var onPriceCoefficientChange = function (value) {
        if (value === undefined) {
          return ;
        }
        var priceExcludingChargesAndTax = value * purchasePrice;
        Curry._1(onChange, priceExcludingChargesAndTax + totalChargesAmount);
      };
      var onPriceExcludingChargesAndTaxChange = function (value) {
        Curry._1(onChange, value + totalChargesAmount);
      };
      var priceExcludingTaxValue$1 = priceExcludingChargesAndTaxValue + totalChargesAmount;
      var priceIncludingTaxValue = priceExcludingTaxValue$1 + priceExcludingTaxValue$1 * taxRate / 100;
      var onPriceExcludingTaxChange = Curry.__1(onChange);
      var onPriceIncludingTaxChange = function (value) {
        Curry._1(onChange, value * 100 / (100 + taxRate));
      };
      var chargesSectionDefaultCollapsed = transportFeeAmountValue === 0 && taxesFeeAmountValue === 0 && otherFeeAmountValue === 0;
      return React.createElement(React.Fragment, undefined, React.createElement(Dialog.Section.make, {
                      children: null,
                      title: $$Intl.t("catalog_list.retail_price_cell.dialog.section_before_charges"),
                      collapsable: true
                    }, React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Group.make, {
                                      children: null,
                                      grid: [
                                        "70px",
                                        "auto"
                                      ],
                                      wrap: false,
                                      spaceX: "normal"
                                    }, React.createElement(InputOptionalNumberField.make, InputOptionalNumberField.makeProps(priceCoefficientValue, 1, undefined, undefined, 0, 3, $$Intl.t("Coefficient"), undefined, undefined, $$Intl.t("N/C"), undefined, undefined, undefined, undefined, undefined, purchasePrice === 0, undefined, true, undefined, onPriceCoefficientChange, undefined, undefined, undefined, undefined, undefined)), React.createElement(InputNumberField.make, {
                                          value: priceExcludingChargesAndTaxValue,
                                          minValue: 0,
                                          precision: 3,
                                          label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_before_charges.price.input_label"),
                                          appender: priceInputNumberAppender$1(bulkUnit),
                                          onChange: onPriceExcludingChargesAndTaxChange
                                        }))), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(InputNumberField.make, {
                          value: marginExcludingTaxValue,
                          precision: 3,
                          label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_before_charges.margin.input_label"),
                          appender: priceInputNumberAppender$1(bulkUnit),
                          disabled: true,
                          strongStyle: true,
                          hideStepper: true,
                          onChange: (function (param) {
                              
                            })
                        })), React.createElement(Dialog.Section.make, {
                      children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Group.make, {
                                        children: null,
                                        spaceX: "normal"
                                      }, React.createElement(InputNumberField.make, {
                                            value: transportFeeAmountValue,
                                            precision: 3,
                                            label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_charges.transport_fee.input_label.shorten"),
                                            appender: amountInputNumberAppender$1,
                                            disabled: true,
                                            hideStepper: true,
                                            onChange: (function (param) {
                                                
                                              })
                                          }), React.createElement(InputNumberField.make, {
                                            value: taxesFeeAmountValue,
                                            precision: 3,
                                            label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_charges.taxes_fee.input_label"),
                                            appender: amountInputNumberAppender$1,
                                            disabled: true,
                                            hideStepper: true,
                                            onChange: (function (param) {
                                                
                                              })
                                          }), React.createElement(InputNumberField.make, {
                                            value: otherFeeAmountValue,
                                            precision: 3,
                                            label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_charges.other_fee.input_label"),
                                            appender: amountInputNumberAppender$1,
                                            disabled: true,
                                            hideStepper: true,
                                            onChange: (function (param) {
                                                
                                              })
                                          }))), undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                      title: $$Intl.t("catalog_list.price_cell.dialog.section_charges"),
                      collapsable: true,
                      defaultCollapsed: chargesSectionDefaultCollapsed
                    }), React.createElement(Dialog.Section.make, {
                      children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Group.make, {
                                        children: null,
                                        spaceX: "normal"
                                      }, React.createElement(InputNumberField.make, {
                                            value: priceExcludingTaxValue$1,
                                            minValue: 0,
                                            precision: 2,
                                            label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_final.price_excluding_tax.input_label"),
                                            appender: priceInputNumberAppender$1(bulkUnit),
                                            strongStyle: !taxIncluded,
                                            onChange: onPriceExcludingTaxChange
                                          }), taxIncluded ? React.createElement(InputNumberField.make, {
                                              value: priceIncludingTaxValue,
                                              minValue: 0,
                                              precision: 2,
                                              label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_final.price_including_tax.input_label"),
                                              appender: priceInputNumberAppender$1(bulkUnit),
                                              strongStyle: taxIncluded,
                                              onChange: onPriceIncludingTaxChange
                                            }) : null)), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                      title: $$Intl.t("catalog_list.retail_price_cell.dialog.section_final"),
                      collapsable: true
                    }));
    });

var PurchasePriceCalculatingMethodDialogSections$1 = {
  make: make$12
};

var make$13 = React.memo(function (Props) {
      var feesList = Props.feesList;
      var bulkUnit = Props.bulkUnit;
      var taxIncluded = Props.taxIncluded;
      var taxRate = Props.taxRate;
      var purchasePrice = Props.purchasePrice;
      var priceExcludingTaxValue = Props.value;
      var onChange = Props.onChange;
      var transportFeeAmountValue = feesList.transportAmount;
      var priceCoefficientValue = (priceExcludingTaxValue - transportFeeAmountValue) / purchasePrice;
      var priceExcludingChargesAndTaxValue = priceExcludingTaxValue - transportFeeAmountValue;
      var marginExcludingTaxValue = priceExcludingTaxValue - purchasePrice;
      var onPriceCoefficientChange = function (value) {
        if (value === undefined) {
          return ;
        }
        var priceExcludingChargesAndTax = value * purchasePrice;
        Curry._1(onChange, priceExcludingChargesAndTax + transportFeeAmountValue);
      };
      var onPriceExcludingChargesAndTaxChange = function (value) {
        Curry._1(onChange, value + transportFeeAmountValue);
      };
      var priceIncludingTaxValue = priceExcludingTaxValue + priceExcludingTaxValue * taxRate / 100;
      var onPriceExcludingTaxChange = Curry.__1(onChange);
      var onPriceIncludingTaxChange = function (value) {
        Curry._1(onChange, value * 100 / (100 + taxRate));
      };
      var chargesSectionDefaultCollapsed = transportFeeAmountValue === 0;
      return React.createElement(React.Fragment, undefined, React.createElement(Dialog.Section.make, {
                      children: null,
                      title: $$Intl.t("catalog_list.retail_price_cell.dialog.section_before_charges"),
                      collapsable: true
                    }, React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Group.make, {
                                      children: null,
                                      grid: [
                                        "70px",
                                        "auto"
                                      ],
                                      wrap: false,
                                      spaceX: "normal"
                                    }, React.createElement(InputOptionalNumberField.make, InputOptionalNumberField.makeProps(priceCoefficientValue, 1, undefined, undefined, 0, 3, $$Intl.t("Coefficient"), undefined, undefined, $$Intl.t("N/C"), undefined, undefined, undefined, undefined, undefined, purchasePrice === 0, undefined, true, undefined, onPriceCoefficientChange, undefined, undefined, undefined, undefined, undefined)), React.createElement(InputNumberField.make, {
                                          value: priceExcludingChargesAndTaxValue,
                                          minValue: 0,
                                          precision: 3,
                                          label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_before_charges.price.input_label"),
                                          appender: priceInputNumberAppender$1(bulkUnit),
                                          onChange: onPriceExcludingChargesAndTaxChange
                                        }))), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(InputNumberField.make, {
                          value: marginExcludingTaxValue,
                          precision: 3,
                          label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_before_charges.margin.input_label"),
                          appender: priceInputNumberAppender$1(bulkUnit),
                          disabled: true,
                          strongStyle: true,
                          hideStepper: true,
                          onChange: (function (param) {
                              
                            })
                        })), React.createElement(Dialog.Section.make, {
                      children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(InputNumberField.make, {
                                        value: transportFeeAmountValue,
                                        precision: 3,
                                        label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_charges.transport_fee.input_label"),
                                        appender: amountInputNumberAppender$1,
                                        disabled: true,
                                        hideStepper: true,
                                        onChange: (function (param) {
                                            
                                          })
                                      })), undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                      title: $$Intl.t("catalog_list.price_cell.dialog.section_charges"),
                      collapsable: true,
                      defaultCollapsed: chargesSectionDefaultCollapsed
                    }), React.createElement(Dialog.Section.make, {
                      children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Group.make, {
                                        children: null,
                                        spaceX: "normal"
                                      }, React.createElement(InputNumberField.make, {
                                            value: priceExcludingTaxValue,
                                            minValue: 0,
                                            precision: 2,
                                            label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_final.price_excluding_tax.input_label"),
                                            appender: priceInputNumberAppender$1(bulkUnit),
                                            strongStyle: !taxIncluded,
                                            onChange: onPriceExcludingTaxChange
                                          }), taxIncluded ? React.createElement(InputNumberField.make, {
                                              value: priceIncludingTaxValue,
                                              minValue: 0,
                                              precision: 2,
                                              label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_final.price_including_tax.input_label"),
                                              appender: priceInputNumberAppender$1(bulkUnit),
                                              strongStyle: taxIncluded,
                                              onChange: onPriceIncludingTaxChange
                                            }) : null)), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                      title: $$Intl.t("catalog_list.retail_price_cell.dialog.section_final"),
                      collapsable: true
                    }));
    });

var FullPurchasePriceCalculatingMethodDialogSections$1 = {
  make: make$13
};

var make$14 = React.memo(function (Props) {
      var bulkUnit = Props.bulkUnit;
      var taxIncluded = Props.taxIncluded;
      var taxRate = Props.taxRate;
      var purchasePrice = Props.purchasePrice;
      var priceExcludingTaxValue = Props.value;
      var onChange = Props.onChange;
      var priceCoefficientValue = priceExcludingTaxValue / purchasePrice;
      var marginExcludingTaxValue = priceExcludingTaxValue - purchasePrice;
      var priceIncludingTaxValue = priceExcludingTaxValue + priceExcludingTaxValue * taxRate / 100;
      var onPriceCoefficientChange = function (value) {
        if (value !== undefined) {
          return Curry._1(onChange, value * purchasePrice);
        }
        
      };
      var onPriceExcludingTaxChange = Curry.__1(onChange);
      var onPriceIncludingTaxChange = function (value) {
        Curry._1(onChange, value * 100 / (100 + taxRate));
      };
      return React.createElement(Dialog.Section.make, {
                  children: null,
                  title: $$Intl.t("catalog_list.retail_price_cell.dialog.section_final")
                }, React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Group.make, {
                                  children: null,
                                  grid: [
                                    "70px",
                                    "auto"
                                  ],
                                  wrap: false,
                                  spaceX: "normal"
                                }, React.createElement(InputOptionalNumberField.make, InputOptionalNumberField.makeProps(priceCoefficientValue, 1, undefined, undefined, 0, 3, $$Intl.t("Coefficient"), undefined, undefined, $$Intl.t("N/C"), undefined, undefined, undefined, undefined, undefined, purchasePrice === 0, undefined, true, undefined, onPriceCoefficientChange, undefined, undefined, undefined, undefined, undefined)), React.createElement(InputNumberField.make, {
                                      value: priceExcludingTaxValue,
                                      minValue: 0,
                                      precision: 2,
                                      label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_final.price_excluding_tax.input_label"),
                                      appender: priceInputNumberAppender$1(bulkUnit),
                                      strongStyle: !taxIncluded,
                                      onChange: onPriceExcludingTaxChange
                                    }))), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(InputNumberField.make, {
                      value: marginExcludingTaxValue,
                      precision: 3,
                      label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_final.margin.input_label"),
                      appender: priceInputNumberAppender$1(bulkUnit),
                      disabled: true,
                      strongStyle: true,
                      hideStepper: true,
                      onChange: (function (param) {
                          
                        })
                    }), taxIncluded ? React.createElement(InputNumberField.make, {
                        value: priceIncludingTaxValue,
                        minValue: 0,
                        precision: 2,
                        label: $$Intl.t("catalog_list.retail_price_cell.dialog.section_final.price_including_tax.input_label"),
                        appender: priceInputNumberAppender$1(bulkUnit),
                        strongStyle: taxIncluded,
                        onChange: onPriceIncludingTaxChange
                      }) : null);
    });

var AcquisitionCostCalculatingMethodDialogSection = {
  make: make$14
};

var styles = Stylex.create({
      root: {
        display: "flex",
        flexDirection: "column",
        gap: "2px"
      }
    });

function styleProps(param) {
  return Stylex.props(styles.root);
}

var make$15 = React.memo(function (Props) {
      var variantId = Props.variantId;
      var shopId = Props.shopId;
      var pricelist = Props.pricelist;
      var bulkUnit = Props.bulkUnit;
      var taxRate = Props.taxRate;
      var feesList = Props.feesList;
      var calculatingMethod = Props.calculatingMethod;
      var purchasePrice = Props.purchasePrice;
      var initialValue = Props.value;
      var useMutationOpt = Props.useMutation;
      var useMutation$2 = useMutationOpt !== undefined ? useMutationOpt : useMutation$1;
      var match = Popover.useTrigger(undefined, undefined);
      var popoverTriggerRef = match.ref;
      var popoverAriaProps = match.ariaProps;
      var popover = match.state;
      var match$1 = Hover.use(popoverTriggerRef, undefined);
      var taxIncluded = pricelist.taxIncluded;
      var triggerHovered = match$1[1];
      var match$2 = React.useState(function () {
            return false;
          });
      var setMutationLoading = match$2[1];
      var mutate = Curry._3(useMutation$2, variantId, pricelist.id, shopId);
      var priceExists = Belt_Option.isSome(initialValue);
      var match$3 = Belt_Option.getWithDefault(initialValue, {
            valueExcludingTax: 0,
            valueIncludingTax: 0
          });
      var initialValueExcludingTax = match$3.valueExcludingTax;
      var match$4 = React.useState(function () {
            return initialValueExcludingTax;
          });
      var setValue = match$4[1];
      var value = match$4[0];
      ReactUpdateEffect.use1((function (param) {
              if (popover.isOpen) {
                Curry._1(setValue, (function (param) {
                        return initialValueExcludingTax;
                      }));
              }
              
            }), [popover.isOpen]);
      var onCommitRequestClose = popover.close;
      var popover_isOpen = popover.isOpen;
      var popover_close = function (param) {
        if (!triggerHovered) {
          return Curry._1(popover.close, undefined);
        }
        
      };
      var popover_toggle = popover.toggle;
      var popover$1 = {
        isOpen: popover_isOpen,
        close: popover_close,
        toggle: popover_toggle
      };
      var onCommit = React.useCallback((function (param) {
              var valueExcludingTax = Math.round(value * 100) / 100;
              var valueIncludingTax = valueExcludingTax + valueExcludingTax * taxRate / 100;
              if (valueExcludingTax !== initialValueExcludingTax) {
                Curry._1(setMutationLoading, (function (param) {
                        return true;
                      }));
                return Future.get(Curry._2(mutate, valueIncludingTax, valueExcludingTax), (function (param) {
                              Curry._1(setMutationLoading, (function (param) {
                                      return false;
                                    }));
                              Curry._1(onCommitRequestClose, undefined);
                            }));
              } else {
                return Curry._1(onCommitRequestClose, undefined);
              }
            }), [
            value,
            initialValueExcludingTax
          ]);
      var match$5 = Stylex.props(styles.root);
      var taxTypename = $$Intl.t(taxIncluded ? "VAT incl." : "VAT excl.");
      var dialogTitleStartElement = React.createElement(Badge.make, {
            children: taxTypename,
            size: "small",
            variation: "neutral"
          });
      var formattedRetailPriceExcludingTax = $$Intl.currencyFormat("EUR", undefined, undefined, initialValueExcludingTax);
      var formattedRetailPriceIncludingTax = $$Intl.currencyFormat("EUR", undefined, undefined, match$3.valueIncludingTax);
      var tmp;
      if (taxIncluded && priceExists) {
        var tmp$1 = {};
        if (match$5.className !== undefined) {
          tmp$1.className = Caml_option.valFromOption(match$5.className);
        }
        if (match$5.style !== undefined) {
          tmp$1.style = Caml_option.valFromOption(match$5.style);
        }
        tmp = React.createElement("div", tmp$1, React.createElement(TextStyle.make, {
                  children: formattedRetailPriceIncludingTax,
                  weight: "strong",
                  size: "xxsmall"
                }), React.createElement(TextStyle.make, {
                  children: formattedRetailPriceExcludingTax + " " + $$Intl.t("excl. tax"),
                  variation: "normal",
                  size: "tiny",
                  lineHeight: "xnormal"
                }));
      } else {
        tmp = priceExists ? React.createElement(TextStyle.make, {
                children: formattedRetailPriceExcludingTax,
                weight: "strong",
                size: "xxsmall"
              }) : React.createElement(TextStyle.make, {
                children: $$Intl.t("Undefined"),
                variation: "subdued",
                size: "xsmall"
              });
      }
      var tmp$2;
      switch (calculatingMethod) {
        case /* PurchasePrice */0 :
            tmp$2 = React.createElement(make$12, {
                  feesList: feesList,
                  bulkUnit: bulkUnit,
                  taxIncluded: taxIncluded,
                  taxRate: taxRate,
                  purchasePrice: purchasePrice,
                  value: value,
                  onChange: (function (value) {
                      Curry._1(setValue, (function (param) {
                              return value;
                            }));
                    })
                });
            break;
        case /* FullPurchasePrice */1 :
            tmp$2 = React.createElement(make$13, {
                  feesList: feesList,
                  bulkUnit: bulkUnit,
                  taxIncluded: taxIncluded,
                  taxRate: taxRate,
                  purchasePrice: purchasePrice,
                  value: value,
                  onChange: (function (value) {
                      Curry._1(setValue, (function (param) {
                              return value;
                            }));
                    })
                });
            break;
        case /* AcquisitionCost */2 :
            tmp$2 = React.createElement(make$14, {
                  bulkUnit: bulkUnit,
                  taxIncluded: taxIncluded,
                  taxRate: taxRate,
                  purchasePrice: purchasePrice,
                  value: value,
                  onChange: (function (value) {
                      Curry._1(setValue, (function (param) {
                              return value;
                            }));
                    })
                });
            break;
        
      }
      return React.createElement(React.Fragment, undefined, React.createElement(OpeningButton.make, OpeningButton.makeProps(tmp, popoverAriaProps.triggerProps, popover_isOpen, undefined, (function (param) {
                            Curry._1(popover_toggle, undefined);
                          }), undefined, popoverTriggerRef, undefined)), React.createElement(Popover.make, {
                      children: React.createElement(Dialog.make, {
                            ariaProps: popoverAriaProps.overlayProps,
                            children: tmp$2,
                            width: 385,
                            title: pricelist.name,
                            titleStartElement: dialogTitleStartElement,
                            commitLoading: match$2[0],
                            commitButtonText: $$Intl.t("catalog_list.retail_price_cell.dialog.commit_button_text"),
                            onCommit: onCommit,
                            onRequestClose: popover_close
                          }),
                      triggerRef: popoverTriggerRef,
                      state: popover$1,
                      placement: "bottom end",
                      modal: false
                    }));
    });

var CatalogListRetailPriceCell = {
  Mutation: Mutation$1,
  useMutation: useMutation$1,
  amountInputNumberAppender: amountInputNumberAppender$1,
  priceInputNumberAppender: priceInputNumberAppender$1,
  PurchasePriceCalculatingMethodDialogSections: PurchasePriceCalculatingMethodDialogSections$1,
  FullPurchasePriceCalculatingMethodDialogSections: FullPurchasePriceCalculatingMethodDialogSections$1,
  AcquisitionCostCalculatingMethodDialogSection: AcquisitionCostCalculatingMethodDialogSection,
  styles: styles,
  styleProps: styleProps,
  make: make$15
};

var jsonCodec$2 = JsonCodec.object1((function (param) {
        return param.price;
      }), (function (price) {
        return {
                TAG: /* Ok */0,
                _0: {
                  price: price
                }
              };
      }), JsonCodec.field("price", jsonCodec$1));

var CatalogListExtraParams = {
  jsonCodec: jsonCodec$2
};

function getStatuses(shopsIndependentKind) {
  if (shopsIndependentKind) {
    return [
            /* Unarchived */3,
            /* Archived */2
          ];
  } else {
    return [
            /* Active */0,
            /* Inactive */1,
            /* Archived */2
          ];
  }
}

function getUpdatedStatus(statuses, currentStatus) {
  var match = Belt_Array.get(statuses, 0);
  if (currentStatus === undefined) {
    return ;
  }
  if (match === undefined) {
    return ;
  }
  var currentStatus$1 = Caml_option.valFromOption(currentStatus);
  return Caml_option.some(Belt_Array.some(statuses, (function (status) {
                    return status === currentStatus$1;
                  })) ? currentStatus$1 : Caml_option.valFromOption(match));
}

var supplier = JsonCodec.object2((function (param) {
        return [
                Belt_Option.getWithDefault(Caml_option.nullable_to_opt(param.id), "null"),
                param.name
              ];
      }), (function (param) {
        var id = param[0];
        return {
                TAG: /* Ok */0,
                _0: {
                  id: id === "null" ? null : id,
                  name: param[1]
                }
              };
      }), JsonCodec.field("id", JsonCodec.string), JsonCodec.field("name", JsonCodec.string));

var category = JsonCodec.object2((function (param) {
        return [
                Belt_Option.getWithDefault(Caml_option.nullable_to_opt(param.id), "null"),
                param.name
              ];
      }), (function (param) {
        var id = param[0];
        return {
                TAG: /* Ok */0,
                _0: {
                  id: id === "null" ? null : id,
                  name: param[1]
                }
              };
      }), JsonCodec.field("id", JsonCodec.string), JsonCodec.field("name", JsonCodec.string));

var stock = JsonCodec.object2((function (param) {
        return [
                param.min,
                param.max
              ];
      }), (function (param) {
        return {
                TAG: /* Ok */0,
                _0: {
                  min: param[0],
                  max: param[1]
                }
              };
      }), JsonCodec.optional(JsonCodec.field("min", JsonCodec.$$float)), JsonCodec.optional(JsonCodec.field("max", JsonCodec.$$float)));

function jsonCodec$3(shops) {
  return JsonCodec.object6((function (param) {
                return [
                        Belt_Option.map(param.shop, (function (shop) {
                                return shop.id;
                              })),
                        Belt_Option.map(param.status, CatalogProduct.Status.toString),
                        param.category,
                        param.supplier,
                        param.producer,
                        param.stock
                      ];
              }), (function (param) {
                var shopId = param[0];
                var match = Belt_Option.map(param[1], CatalogProduct.Status.fromString);
                var tmp;
                tmp = match !== undefined && match.TAG === /* Ok */0 ? match._0 : undefined;
                return {
                        TAG: /* Ok */0,
                        _0: {
                          shop: Belt_Array.getBy(shops, (function (shop) {
                                  return shop.id === shopId;
                                })),
                          status: tmp,
                          category: param[2],
                          supplier: param[3],
                          producer: param[4],
                          stock: param[5]
                        }
                      };
              }), JsonCodec.optional(JsonCodec.field("shopId", JsonCodec.string)), JsonCodec.optional(JsonCodec.field("status", JsonCodec.string)), JsonCodec.optional(JsonCodec.field("category", category)), JsonCodec.optional(JsonCodec.field("supplier", supplier)), JsonCodec.optional(JsonCodec.field("producer", JsonCodec.string)), JsonCodec.optional(JsonCodec.field("stock", stock)));
}

var Raw$5 = {};

var query$5 = Client.gql(["query productRegions($country: String)  {\nproductRegions(country: $country)  \n}\n"]);

function parse$5(value) {
  var value$1 = value.productRegions;
  return {
          productRegions: Js_array.map((function (value) {
                  return value;
                }), value$1)
        };
}

function serialize$5(value) {
  var value$1 = value.productRegions;
  var productRegions = Js_array.map((function (value) {
          return value;
        }), value$1);
  return {
          productRegions: productRegions
        };
}

function serializeVariables$5(inp) {
  var a = inp.country;
  return {
          country: a !== undefined ? a : undefined
        };
}

function makeVariables$5(country, param) {
  return {
          country: country
        };
}

function makeDefaultVariables$3(param) {
  return {
          country: undefined
        };
}

var Query_inner = {
  Raw: Raw$5,
  query: query$5,
  parse: parse$5,
  serialize: serialize$5,
  serializeVariables: serializeVariables$5,
  makeVariables: makeVariables$5,
  makeDefaultVariables: makeDefaultVariables$3
};

var include$5 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$5,
      Raw: Raw$5,
      parse: parse$5,
      serialize: serialize$5,
      serializeVariables: serializeVariables$5
    });

var use$5 = include$5.use;

var Query_refetchQueryDescription = include$5.refetchQueryDescription;

var Query_useLazy = include$5.useLazy;

var Query_useLazyWithVariables = include$5.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw$5,
  query: query$5,
  parse: parse$5,
  serialize: serialize$5,
  serializeVariables: serializeVariables$5,
  makeVariables: makeVariables$5,
  makeDefaultVariables: makeDefaultVariables$3,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use$5,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function CatalogListPage$CatalogListFilters$RegionSelect(Props) {
  var disabled = Props.disabled;
  var value = Props.value;
  var onChange = Props.onChange;
  var results = Curry.app(use$5, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          country: "France"
        }
      ]);
  var match = results.data;
  var items = match !== undefined ? Belt_Array.map(Belt_Array.keep(match.productRegions, (function (suggestion) {
                return suggestion !== "";
              })), (function (productRegions) {
            return {
                    key: productRegions,
                    value: productRegions,
                    label: productRegions
                  };
          })) : [];
  var defaultItem_label = $$Intl.t("All");
  var defaultItem_sticky = true;
  var defaultItem = {
    key: "default",
    label: defaultItem_label,
    sticky: defaultItem_sticky
  };
  var sections = [
    {
      items: [defaultItem]
    },
    {
      items: items,
      title: $$Intl.t("Regions")
    }
  ];
  return React.createElement(Tooltip.make, {
              children: React.createElement(Select.make, {
                    label: $$Intl.t("Regions"),
                    disabled: disabled,
                    sections: sections,
                    preset: "filter",
                    size: "compact",
                    value: value,
                    onChange: onChange
                  }),
              placement: "top",
              content: React.createElement(Tooltip.Span.make, {
                    text: $$Intl.t("Please select a shop beforehand.")
                  }),
              disabled: !disabled,
              delay: 0
            });
}

var RegionSelect = {
  Query: Query,
  make: CatalogListPage$CatalogListFilters$RegionSelect
};

var CatalogListFilters = {
  getStatuses: getStatuses,
  getUpdatedStatus: getUpdatedStatus,
  supplier: supplier,
  category: category,
  stock: stock,
  jsonCodec: jsonCodec$3,
  RegionSelect: RegionSelect
};

function toSortDescriptor(sort) {
  switch (sort.TAG | 0) {
    case /* Name */0 :
        return {
                column: "reference",
                direction: sort._0
              };
    case /* Producer */1 :
        return {
                column: "producer",
                direction: sort._0
              };
    case /* PurchasePrice */2 :
        return {
                column: "purchase-price",
                direction: sort._0
              };
    case /* CreatedAt */3 :
        return {
                column: "created-at",
                direction: sort._0
              };
    
  }
}

function fromSortDescriptor(sort) {
  switch (sort.column) {
    case "created-at" :
        return {
                TAG: /* Ok */0,
                _0: {
                  TAG: /* CreatedAt */3,
                  _0: sort.direction
                }
              };
    case "producer" :
        return {
                TAG: /* Ok */0,
                _0: {
                  TAG: /* Producer */1,
                  _0: sort.direction
                }
              };
    case "purchase-price" :
        return {
                TAG: /* Ok */0,
                _0: {
                  TAG: /* PurchasePrice */2,
                  _0: sort.direction
                }
              };
    case "reference" :
        return {
                TAG: /* Ok */0,
                _0: {
                  TAG: /* Name */0,
                  _0: sort.direction
                }
              };
    default:
      return {
              TAG: /* Error */1,
              _0: undefined
            };
  }
}

var jsonCodec$4 = JsonCodec.object2((function (sort) {
        return [
                toSortDescriptor(sort).column,
                toSortDescriptor(sort).direction
              ];
      }), (function (param) {
        var direction = param[1] === "descending" ? "descending" : "ascending";
        var ok = fromSortDescriptor({
              column: param[0],
              direction: direction
            });
        if (ok.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: ok._0
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: {
                    NAME: "SyntaxError",
                    VAL: "Invalid sort descriptor"
                  }
                };
        }
      }), JsonCodec.field("column", JsonCodec.string), JsonCodec.field("direction", JsonCodec.string));

var CatalogListSorting = {
  toSortDescriptor: toSortDescriptor,
  fromSortDescriptor: fromSortDescriptor,
  jsonCodec: jsonCodec$4
};

function makeFromFilters(filters) {
  var status = filters.status;
  var shopId = Belt_Option.map(filters.shop, (function (param) {
          return param.id;
        }));
  var variantsActive = status !== undefined ? (
      status !== 1 ? (
          status !== 0 ? undefined : true
        ) : false
    ) : undefined;
  var variantsArchived = status !== undefined ? (
      status !== 2 ? false : true
    ) : undefined;
  var categoryId = Belt_Option.map(filters.category, (function (category) {
          return category.id;
        }));
  var supplierId = Belt_Option.map(filters.supplier, (function (supplier) {
          return supplier.id;
        }));
  return {
          shopId: shopId,
          variantsActive: variantsActive,
          variantsArchived: variantsArchived,
          categoryId: categoryId,
          supplierId: supplierId,
          producer: filters.producer,
          stock: filters.stock
        };
}

var CatalogListSheetExportInput = {
  makeFromFilters: makeFromFilters
};

function catalogListMultiShopsTableRowsFromQueryResult(queryResult) {
  return Belt_Array.map(queryResult.variantsDistinctOnCku.edges, (function (edge) {
                var match = edge.node.formattedStatus;
                var match$1 = edge.node.capacityUnit;
                var match$2 = Belt_Option.getWithDefault(edge.node.bulk, false);
                return {
                        cku: edge.node.cku,
                        id: edge.node.id,
                        createdAt: edge.node.createdAt,
                        productId: edge.node.product.id,
                        name: edge.node.name,
                        productName: edge.node.product.name,
                        productKind: edge.node.product.kind,
                        productTaxRate: edge.node.product.tax.value,
                        designation: edge.node.product.designation,
                        family: edge.node.product.family,
                        wineType: edge.node.product.wineType,
                        whiteWineType: edge.node.product.whiteWineType,
                        beerType: edge.node.product.beerType,
                        color: edge.node.product.color,
                        producer: edge.node.product.producer,
                        categoryName: Belt_Option.getWithDefault(edge.node.formattedCategory, nonApplicableStringLiteral),
                        supplierName: Belt_Option.map(edge.node.supplier, (function (supplier) {
                                return supplier.companyName;
                              })),
                        region: edge.node.product.region,
                        country: edge.node.product.country,
                        feesList: {
                          transportAmount: 0,
                          taxesAmount: 0,
                          otherAmount: 0
                        },
                        purchasePrice: Belt_Option.getWithDefault(edge.node.purchasedPrice, 0),
                        purchasePriceDiscount: 0,
                        stockKeepingUnit: edge.node.stockKeepingUnit,
                        priceLookUpCode: edge.node.priceLookUpCode,
                        internalCode: edge.node.internalCode,
                        stockQuantity: edge.node.stock.formattedQuantity,
                        stockState: edge.node.stock.state,
                        formattedShopsNames: edge.node.stock.formattedShopsNames,
                        status: match === "INACTIVE" ? /* Inactive */1 : (
                            match === "ARCHIVED" ? /* Archived */2 : (
                                match === "ACTIVE" ? /* Active */0 : undefined
                              )
                          ),
                        bulkUnit: match$1 !== undefined && match$2 ? match$1 : undefined,
                        maxStockThreshold: Belt_Option.getWithDefault(edge.node.maxStockThreshold, 0),
                        minStockThreshold: Belt_Option.getWithDefault(edge.node.minStockThreshold, 0),
                        stockOrderTriggerThreshold: Belt_Option.getWithDefault(edge.node.stockOrderTriggerThreshold, 0),
                        bulk: Belt_Option.getWithDefault(edge.node.bulk, false),
                        alcoholVolume: edge.node.alcoholVolume
                      };
              }));
}

function catalogListSingleShopTableRowsFromQueryResult(queryResult, currentPriceId) {
  return Belt_Array.map(queryResult.variants.edges, (function (edge) {
                var latestOrderProduct = Belt_Option.map(Belt_Array.get(edge.node.orderProducts.edges, 0), (function (edge) {
                        return edge.node;
                      }));
                var latestOrderProduct$1 = Belt_Option.map(Belt_Array.get(edge.node.orderProducts.edges, 0), (function (edge) {
                        return edge.node;
                      }));
                var match = edge.node.formattedStatus;
                var match$1 = edge.node.capacityUnit;
                var match$2 = Belt_Option.getWithDefault(edge.node.bulk, false);
                return {
                        cku: edge.node.cku,
                        id: edge.node.id,
                        createdAt: edge.node.createdAt,
                        productId: edge.node.product.id,
                        name: edge.node.name,
                        productName: edge.node.product.name,
                        productKind: edge.node.product.kind,
                        productTaxRate: edge.node.product.tax.value,
                        designation: edge.node.product.designation,
                        family: edge.node.product.family,
                        wineType: edge.node.product.wineType,
                        whiteWineType: edge.node.product.whiteWineType,
                        beerType: edge.node.product.beerType,
                        color: edge.node.product.color,
                        producer: edge.node.product.producer,
                        categoryName: Belt_Option.getWithDefault(edge.node.formattedCategory, nonApplicableStringLiteral),
                        supplierName: Belt_Option.map(edge.node.supplier, (function (supplier) {
                                return supplier.companyName;
                              })),
                        region: edge.node.product.region,
                        country: edge.node.product.country,
                        feesList: latestOrderProduct !== undefined ? decodeFeesJsonToFeesList(latestOrderProduct.fees) : ({
                              transportAmount: 0,
                              taxesAmount: 0,
                              otherAmount: 0
                            }),
                        purchasePrice: Belt_Option.getWithDefault(edge.node.purchasedPrice, 0),
                        purchasePriceDiscount: latestOrderProduct$1 !== undefined ? latestOrderProduct$1.totalLocalDiscounts / latestOrderProduct$1.quantity : 0,
                        retailPrice: Belt_Option.map(Belt_Array.getBy(edge.node.variantPrices.edges, (function (variantPrice) {
                                    var match = variantPrice.node.price;
                                    if (match !== undefined && currentPriceId !== undefined) {
                                      return match.id === currentPriceId;
                                    } else {
                                      return false;
                                    }
                                  })), (function (variantPrice) {
                                return {
                                        valueExcludingTax: variantPrice.node.valueExcludingTax,
                                        valueIncludingTax: variantPrice.node.valueIncludingTax
                                      };
                              })),
                        stockKeepingUnit: edge.node.stockKeepingUnit,
                        priceLookUpCode: edge.node.priceLookUpCode,
                        internalCode: edge.node.internalCode,
                        stockQuantity: edge.node.stock.formattedQuantity,
                        stockState: edge.node.stock.state,
                        formattedShopsNames: edge.node.stock.formattedShopsNames,
                        status: match === "INACTIVE" ? /* Inactive */1 : (
                            match === "ARCHIVED" ? /* Archived */2 : (
                                match === "ACTIVE" ? /* Active */0 : undefined
                              )
                          ),
                        bulkUnit: match$1 !== undefined && match$2 ? match$1 : undefined,
                        maxStockThreshold: Belt_Option.getWithDefault(edge.node.maxStockThreshold, 0),
                        minStockThreshold: Belt_Option.getWithDefault(edge.node.minStockThreshold, 0),
                        stockOrderTriggerThreshold: Belt_Option.getWithDefault(edge.node.stockOrderTriggerThreshold, 0),
                        bulk: Belt_Option.getWithDefault(edge.node.bulk, false),
                        alcoholVolume: edge.node.alcoholVolume
                      };
              }));
}

function catalogListMultiShopsQueryVariablesFilterBy(param) {
  var status = param.status;
  var shop = param.shop;
  return {
          shopIds: shop !== undefined ? ({
                _in: [shop.id]
              }) : undefined,
          active: status !== undefined ? (
              status !== 1 ? (
                  status !== 0 ? undefined : ({
                        _equals: true
                      })
                ) : ({
                    _equals: false
                  })
            ) : undefined,
          archived: status !== undefined ? (
              status !== 2 ? "EXCLUDED" : "ONLY"
            ) : "INCLUDED"
        };
}

function catalogListMultiShopsQueryVariablesOrderBy(sort) {
  var toRawDirection = function (direction) {
    if (direction === "ascending") {
      return "ASC";
    } else {
      return "DESC";
    }
  };
  switch (sort.TAG | 0) {
    case /* Name */0 :
        return {
                createdAt: undefined,
                active: undefined,
                name: toRawDirection(sort._0),
                producer: undefined,
                purchasedPrice: undefined
              };
    case /* Producer */1 :
        return {
                createdAt: undefined,
                active: undefined,
                name: undefined,
                producer: toRawDirection(sort._0),
                purchasedPrice: undefined
              };
    case /* PurchasePrice */2 :
        return {
                createdAt: undefined,
                active: undefined,
                name: undefined,
                producer: undefined,
                purchasedPrice: toRawDirection(sort._0)
              };
    case /* CreatedAt */3 :
        return {
                createdAt: toRawDirection(sort._0),
                active: undefined,
                name: undefined,
                producer: undefined,
                purchasedPrice: undefined
              };
    
  }
}

function catalogListSingleShopQueryVariablesFilterBy(param) {
  var stock = param.stock;
  var producer = param.producer;
  var supplier = param.supplier;
  var category = param.category;
  var status = param.status;
  var shop = param.shop;
  var tmp;
  if (category !== undefined) {
    var categoryId = category.id;
    tmp = {
      _equals: (categoryId == null) ? null : categoryId
    };
  } else {
    tmp = undefined;
  }
  var tmp$1;
  if (supplier !== undefined) {
    var supplierId = supplier.id;
    tmp$1 = {
      _equals: (supplierId == null) ? null : supplierId
    };
  } else {
    tmp$1 = undefined;
  }
  return {
          shopIds: shop !== undefined ? ({
                _in: [shop.id]
              }) : undefined,
          stockKeepingUnit: undefined,
          ean13: undefined,
          active: status !== undefined ? (
              status !== 1 ? (
                  status !== 0 ? undefined : ({
                        _equals: true
                      })
                ) : ({
                    _equals: false
                  })
            ) : undefined,
          archived: status !== undefined ? (
              status !== 2 ? "EXCLUDED" : "ONLY"
            ) : "INCLUDED",
          createdAt: undefined,
          updatedAt: undefined,
          categoryId: tmp,
          supplierId: tmp$1,
          stock: stock !== undefined ? ({
                _min: stock.min,
                _max: stock.max,
                _between: undefined
              }) : undefined,
          producer: producer !== undefined ? ({
                _equals: producer
              }) : undefined
        };
}

function catalogListSingleShopQueryVariablesOrderBy(sort) {
  var toRawDirection = function (direction) {
    if (direction === "ascending") {
      return "ASC";
    } else {
      return "DESC";
    }
  };
  switch (sort.TAG | 0) {
    case /* Name */0 :
        return {
                createdAt: undefined,
                active: undefined,
                name: toRawDirection(sort._0),
                producer: undefined,
                purchasedPrice: undefined
              };
    case /* Producer */1 :
        return {
                createdAt: undefined,
                active: undefined,
                name: undefined,
                producer: toRawDirection(sort._0),
                purchasedPrice: undefined
              };
    case /* PurchasePrice */2 :
        return {
                createdAt: undefined,
                active: undefined,
                name: undefined,
                producer: undefined,
                purchasedPrice: toRawDirection(sort._0)
              };
    case /* CreatedAt */3 :
        return {
                createdAt: toRawDirection(sort._0),
                active: undefined,
                name: undefined,
                producer: undefined,
                purchasedPrice: undefined
              };
    
  }
}

function catalogListMultiShopsQueryVariables(param) {
  var match = param.connectionArguments;
  return {
          search: param.searchQuery,
          filterBy: catalogListMultiShopsQueryVariablesFilterBy(param.filters),
          orderBy: [catalogListMultiShopsQueryVariablesOrderBy(param.sorting)],
          before: match.before,
          after: match.after,
          first: match.first,
          last: match.last
        };
}

function catalogListSingleShopQueryVariables(param) {
  var match = param.connectionArguments;
  return {
          search: param.searchQuery,
          filterBy: catalogListSingleShopQueryVariablesFilterBy(param.filters),
          orderBy: [catalogListSingleShopQueryVariablesOrderBy(param.sorting)],
          before: match.before,
          after: match.after,
          first: match.first,
          last: match.last
        };
}

function CatalogListPage(Props) {
  var shopsIndependentKind = Props.shopsIndependentKind;
  var variantIdfromLabelEditSettings = Props.variantIdfromLabelEditSettings;
  var shops = Auth.useShops(undefined);
  var authScope = Auth.useScope(undefined);
  var activeShop = Auth.useActiveShop(undefined);
  var statuses = getStatuses(shopsIndependentKind);
  var match = Auth.useScope(undefined);
  var organisationAccount;
  organisationAccount = match.TAG === /* Organisation */0 ? true : false;
  var initialFilters_status = Belt_Array.get(statuses, 0);
  var initialFilters = {
    shop: activeShop,
    status: initialFilters_status,
    category: undefined,
    supplier: undefined,
    producer: undefined,
    stock: undefined
  };
  var initialState = ResourceList.initialState(ResourceList.defaultEdgesPerPage, initialFilters, {
        TAG: /* Name */0,
        _0: "ascending"
      }, undefined, undefined);
  var filtersJsonCodec = jsonCodec$3(shops);
  var resourceListPropState = ResourceList.use(initialState, filtersJsonCodec, jsonCodec$4, Caml_option.some(jsonCodec$2), undefined);
  var dispatch = resourceListPropState[1];
  var state = resourceListPropState[0];
  var match$1 = React.useState(function () {
        
      });
  var setPrinterStatusResult = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setNotification = match$2[1];
  var match$3 = React.useState(function () {
        return initialValue;
      });
  var setPricingSettings = match$3[1];
  var pricingSettings = match$3[0];
  var queryMultiShopsAsyncResult = ApolloHelpers.queryResultToAsyncResult(Curry.app(use, [
            undefined,
            undefined,
            undefined,
            undefined,
            /* CacheAndNetwork */0,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            Belt_Option.isSome(state.filters.shop),
            undefined,
            catalogListMultiShopsQueryVariables(state)
          ]));
  var querySingleShopAsyncResult = ApolloHelpers.queryResultToAsyncResult(Curry.app(use$1, [
            undefined,
            undefined,
            undefined,
            undefined,
            /* CacheAndNetwork */0,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            Belt_Option.isNone(state.filters.shop),
            undefined,
            catalogListSingleShopQueryVariables(state)
          ]));
  var pricelistsAsyncResult = AsyncResult.mapError(AsyncResult.mapOk(ApolloHelpers.queryResultToAsyncResult(Curry.app(use$2, [
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    /* CacheAndNetwork */0,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    Belt_Option.isNone(state.filters.shop),
                    undefined,
                    singleShopQueryVariables(Belt_Option.mapWithDefault(state.filters.shop, [], (function (shop) {
                                return [shop.id];
                              })))
                  ])), singleShopQueryResult), (function (param) {
          
        }));
  var currentPricelist;
  if (typeof pricelistsAsyncResult === "number" || pricelistsAsyncResult.TAG !== /* Done */1) {
    currentPricelist = undefined;
  } else {
    var pricelists = pricelistsAsyncResult._0;
    if (pricelists.TAG === /* Ok */0) {
      var currentPriceId = Belt_Option.map(state.extra, (function (extra) {
              return extra.price.id;
            }));
      currentPricelist = getCurrentPricelist(pricelists._0, currentPriceId);
    } else {
      currentPricelist = undefined;
    }
  }
  var onRequestPaginate = React.useCallback((function (action) {
          var startCursor;
          var endCursor;
          var totalCount;
          var exit = 0;
          var exit$1 = 0;
          var exit$2 = 0;
          if (typeof queryMultiShopsAsyncResult === "number" || queryMultiShopsAsyncResult.TAG !== /* Reloading */0) {
            exit$2 = 4;
          } else {
            var match = queryMultiShopsAsyncResult._0;
            if (match.TAG === /* Ok */0) {
              var match$1 = match._0.variantsDistinctOnCku;
              var match$2 = match$1.pageInfo;
              startCursor = match$2.startCursor;
              endCursor = match$2.endCursor;
              totalCount = match$1.totalCount;
            } else {
              exit$2 = 4;
            }
          }
          if (exit$2 === 4) {
            if (typeof querySingleShopAsyncResult === "number" || querySingleShopAsyncResult.TAG !== /* Reloading */0) {
              exit$1 = 3;
            } else {
              var match$3 = querySingleShopAsyncResult._0;
              if (match$3.TAG === /* Ok */0) {
                var match$4 = match$3._0.variants;
                var match$5 = match$4.pageInfo;
                startCursor = match$5.startCursor;
                endCursor = match$5.endCursor;
                totalCount = match$4.totalCount;
              } else {
                exit$1 = 3;
              }
            }
          }
          if (exit$1 === 3) {
            if (typeof queryMultiShopsAsyncResult === "number" || queryMultiShopsAsyncResult.TAG !== /* Done */1) {
              exit = 2;
            } else {
              var match$6 = queryMultiShopsAsyncResult._0;
              if (match$6.TAG === /* Ok */0) {
                var match$7 = match$6._0.variantsDistinctOnCku;
                var match$8 = match$7.pageInfo;
                startCursor = match$8.startCursor;
                endCursor = match$8.endCursor;
                totalCount = match$7.totalCount;
              } else {
                exit = 2;
              }
            }
          }
          if (exit === 2) {
            if (typeof querySingleShopAsyncResult === "number") {
              return ;
            }
            if (querySingleShopAsyncResult.TAG !== /* Done */1) {
              return ;
            }
            var match$9 = querySingleShopAsyncResult._0;
            if (match$9.TAG !== /* Ok */0) {
              return ;
            }
            var match$10 = match$9._0.variants;
            var match$11 = match$10.pageInfo;
            startCursor = match$11.startCursor;
            endCursor = match$11.endCursor;
            totalCount = match$10.totalCount;
          }
          var totalPages = ResourceList.totalPages(totalCount, ResourceList.defaultEdgesPerPage);
          var nextPage = ResourceList.nextPage(state, action, totalPages);
          var cursors = [
            startCursor,
            endCursor
          ];
          if (nextPage !== undefined) {
            return Curry._1(dispatch, {
                        TAG: /* Navigated */0,
                        nextPage: nextPage,
                        totalCount: totalCount,
                        cursors: cursors
                      });
          }
          
        }), [
        state.currentPage,
        queryMultiShopsAsyncResult,
        querySingleShopAsyncResult
      ]);
  var onTableSortChange = function (sortDescriptor) {
    var sort = fromSortDescriptor(sortDescriptor);
    if (sort.TAG === /* Ok */0) {
      return Curry._1(dispatch, {
                  TAG: /* SortingUpdated */3,
                  _0: sort._0
                });
    }
    
  };
  React.useEffect((function () {
          if (state.filters.shop !== activeShop) {
            Curry._1(dispatch, {
                  TAG: /* FiltersUpdated */2,
                  _0: (function (prev) {
                      return {
                              shop: activeShop,
                              status: prev.status,
                              category: prev.category,
                              supplier: prev.supplier,
                              producer: prev.producer,
                              stock: prev.stock
                            };
                    })
                });
          }
          
        }), []);
  React.useEffect((function () {
          var match = state.filters.shop;
          if (match === undefined) {
            return ;
          }
          var future = Curry._1(CatalogLabel.Print.DefaultPrinterRequest.make, match.id);
          Future.map(future, undefined, (function (result) {
                  Curry._1(setPrinterStatusResult, (function (param) {
                          return result;
                        }));
                }));
          return (function (param) {
                    Future.cancel(future);
                  });
        }), [state.filters.shop]);
  ReactUpdateEffect.use1((function (param) {
          Curry._1(dispatch, {
                TAG: /* FiltersUpdated */2,
                _0: (function (prev) {
                    return {
                            shop: prev.shop,
                            status: prev.status,
                            category: undefined,
                            supplier: undefined,
                            producer: prev.producer,
                            stock: prev.stock
                          };
                  })
              });
        }), [state.filters.shop]);
  ReactUpdateEffect.use1((function (param) {
          Curry._1(dispatch, {
                TAG: /* FiltersUpdated */2,
                _0: (function (prev) {
                    var currentStatus = prev.status;
                    var status = getUpdatedStatus(statuses, currentStatus);
                    return {
                            shop: prev.shop,
                            status: status,
                            category: prev.category,
                            supplier: prev.supplier,
                            producer: prev.producer,
                            stock: prev.stock
                          };
                  })
              });
        }), [shopsIndependentKind]);
  var match$4 = pricingSettings.purchasePriceType;
  var match$5 = pricingSettings.retailPriceCalculatingMethod;
  var tmp;
  if (match$4) {
    switch (match$5) {
      case /* PurchasePrice */0 :
          tmp = $$Intl.t("Net purchase price");
          break;
      case /* FullPurchasePrice */1 :
          tmp = $$Intl.t("Net full PP");
          break;
      case /* AcquisitionCost */2 :
          tmp = $$Intl.t("Net acq. cost");
          break;
      
    }
  } else {
    switch (match$5) {
      case /* PurchasePrice */0 :
          tmp = $$Intl.t("Gross purchase price");
          break;
      case /* FullPurchasePrice */1 :
          tmp = $$Intl.t("Gross full PP");
          break;
      case /* AcquisitionCost */2 :
          tmp = $$Intl.t("Gross acq. cost");
          break;
      
    }
  }
  var match$6 = state.extra;
  var match$7 = state.filters.shop;
  var tableColumns = [
    {
      key: "reference",
      name: $$Intl.t("Name and description"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 405
        },
        width: {
          NAME: "pct",
          VAL: 35
        },
        margin: "xxlarge",
        sticky: true
      },
      allowsSorting: true,
      render: (function (param) {
          var data = param.data;
          var redirectRoute = CatalogRoutes.variantRoute(data.cku);
          var productKind = data.productKind;
          var information_productName = data.productName;
          var information_variantName = data.name;
          var information_sku = data.stockKeepingUnit;
          var information_plu = Belt_Option.map(data.priceLookUpCode, (function (prim) {
                  return String(prim);
                }));
          var information_color = data.color;
          var information_producerName = data.producer;
          var information_designation = data.designation;
          var information_productFamily = data.family;
          var information_region = data.region;
          var information_country = Belt_Option.getWithDefault(data.country, nonApplicableStringLiteral);
          var information_categoryName = data.categoryName;
          var information_supplierName = data.supplierName;
          var information_alcoholVolume = Belt_Option.map(data.alcoholVolume, (function (alcoholVolume) {
                  return $$Intl.decimalFormat(undefined, 1, alcoholVolume) + "°";
                }));
          var information = {
            productName: information_productName,
            variantName: information_variantName,
            sku: information_sku,
            plu: information_plu,
            color: information_color,
            producerName: information_producerName,
            designation: information_designation,
            productFamily: information_productFamily,
            region: information_region,
            country: information_country,
            categoryName: information_categoryName,
            supplierName: information_supplierName,
            alcoholVolume: information_alcoholVolume
          };
          return React.createElement(ProductReferenceTableCell.make, {
                      redirectRoute: redirectRoute,
                      productKind: productKind,
                      information: information
                    });
        })
    },
    {
      key: "producer",
      name: $$Intl.t("Producer/Supplier"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 120
        },
        width: {
          NAME: "pct",
          VAL: 20
        },
        margin: "medium"
      },
      allowsSorting: true,
      render: (function (param) {
          var match = param.data;
          var supplierName = match.supplierName;
          var formattedProducer = Belt_Option.getWithDefault(match.producer, nonApplicableStringLiteral);
          return React.createElement("div", {
                      style: {
                        display: "flex",
                        width: "100%",
                        flexDirection: "column"
                      }
                    }, React.createElement(TextStyle.make, {
                          children: formattedProducer,
                          size: "xsmall",
                          maxLines: 1
                        }), supplierName !== undefined ? React.createElement(TextStyle.make, {
                            children: supplierName,
                            variation: "normal",
                            size: "tiny",
                            lineHeight: "normal",
                            maxLines: 1
                          }) : React.createElement(TextStyle.make, {
                            children: $$Intl.t("Not specified"),
                            variation: "subdued",
                            size: "tiny",
                            lineHeight: "normal",
                            maxLines: 1
                          }));
        })
    },
    {
      key: "category",
      name: $$Intl.t("Category"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 140
        },
        width: {
          NAME: "pct",
          VAL: 16
        },
        margin: "medium"
      },
      render: (function (param) {
          var match = param.data;
          var categoryName = match.categoryName;
          var tmp;
          var exit = 0;
          switch (categoryName) {
            case "Non class\xc3\xa9" :
                tmp = React.createElement(TextStyle.make, {
                      children: $$Intl.t("Not classified"),
                      variation: "subdued",
                      size: "xsmall"
                    });
                break;
            case "VARIABLE" :
                tmp = React.createElement(TextStyle.make, {
                      children: $$Intl.t("Differing"),
                      variation: "subdued",
                      size: "xsmall"
                    });
                break;
            default:
              if (authScope.TAG === /* Organisation */0 && authScope.activeShop === undefined) {
                tmp = React.createElement("div", {
                      style: {
                        display: "flex",
                        width: "100%"
                      }
                    }, React.createElement(TextStyle.make, {
                          children: categoryName,
                          size: "xsmall",
                          maxLines: 1,
                          direction: "rtl"
                        }));
              } else {
                exit = 1;
              }
          }
          if (exit === 1) {
            tmp = React.createElement(CatalogCategoryEditCell.make, {
                  compact: true,
                  value: categoryName,
                  productId: match.productId
                });
          }
          return React.createElement(React.Fragment, undefined, tmp);
        })
    },
    {
      key: "stock",
      name: $$Intl.t("Stock"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 85
        },
        width: {
          NAME: "px",
          VAL: 175
        },
        margin: "small"
      },
      render: (function (param) {
          var match = param.data;
          return React.createElement(CatalogVariantStockThresholdCell.make, {
                      compact: true,
                      stockQuantity: match.stockQuantity,
                      stockState: match.stockState,
                      formattedShopsNames: match.formattedShopsNames,
                      id: match.id,
                      maxStockThreshold: match.maxStockThreshold,
                      minStockThreshold: match.minStockThreshold,
                      stockOrderTriggerThreshold: match.stockOrderTriggerThreshold,
                      bulk: match.bulk
                    });
        })
    },
    {
      key: "purchase-price",
      name: tmp,
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 125
        },
        width: {
          NAME: "px",
          VAL: 170
        }
      },
      allowsSorting: true,
      render: (function (param) {
          var match = param.data;
          if (Belt_Option.isSome(activeShop)) {
            return React.createElement(make$11, {
                        variantId: match.id,
                        bulkUnit: match.bulkUnit,
                        feesList: match.feesList,
                        discount: match.purchasePriceDiscount,
                        purchasePriceType: pricingSettings.purchasePriceType,
                        calculatingMethod: pricingSettings.retailPriceCalculatingMethod,
                        value: match.purchasePrice
                      });
          } else {
            return nonApplicableValueCellElement;
          }
        })
    },
    {
      key: "price-differential-indicator",
      name: toLabel(pricingSettings.priceDifferentialIndicator),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 82
        },
        width: {
          NAME: "px",
          VAL: 160
        }
      },
      render: (function (param) {
          var match = param.data;
          var retailPrice = match.retailPrice;
          var purchasePrice = match.purchasePrice;
          var match$1 = match.feesList;
          var otherAmount = match$1.otherAmount;
          var taxesAmount = match$1.taxesAmount;
          var transportAmount = match$1.transportAmount;
          if (!Belt_Option.isSome(activeShop)) {
            return nonApplicableValueCellElement;
          }
          if (retailPrice === undefined) {
            return React.createElement(TextStyle.make, {
                        children: $$Intl.t("N/C"),
                        variation: "subdued",
                        size: "xsmall"
                      });
          }
          var match$2 = pricingSettings.purchasePriceType;
          var netOrGrossPurchasePrice = match$2 ? purchasePrice - match.purchasePriceDiscount : purchasePrice;
          var match$3 = pricingSettings.retailPriceCalculatingMethod;
          var computedPurchasePrice;
          switch (match$3) {
            case /* PurchasePrice */0 :
                computedPurchasePrice = netOrGrossPurchasePrice;
                break;
            case /* FullPurchasePrice */1 :
                computedPurchasePrice = computeFullPurchasePrice(netOrGrossPurchasePrice, taxesAmount, otherAmount);
                break;
            case /* AcquisitionCost */2 :
                computedPurchasePrice = computeAcquisitionCost(netOrGrossPurchasePrice, transportAmount, taxesAmount, otherAmount);
                break;
            
          }
          var match$4 = pricingSettings.retailPriceCalculatingMethod;
          var totalChargesAmount;
          switch (match$4) {
            case /* PurchasePrice */0 :
                totalChargesAmount = transportAmount + taxesAmount + otherAmount;
                break;
            case /* FullPurchasePrice */1 :
                totalChargesAmount = transportAmount;
                break;
            case /* AcquisitionCost */2 :
                totalChargesAmount = 0;
                break;
            
          }
          var priceExcludingChargesAndTaxValue = retailPrice.valueExcludingTax - totalChargesAmount;
          return React.createElement(CatalogPriceDifferentialIndicatorCell.make, {
                      value: pricingSettings.priceDifferentialIndicator,
                      purchasePrice: computedPurchasePrice,
                      retailPrice: priceExcludingChargesAndTaxValue
                    });
        })
    },
    {
      key: "retail-price",
      name: match$6 !== undefined ? match$6.price.name : $$Intl.t("Retail price"),
      layout: {
        headerActionComponent: Caml_option.some(match$7 !== undefined ? React.createElement(make$7, {
                    shopId: match$7.id,
                    pricelistsAsyncResult: pricelistsAsyncResult,
                    price: Belt_Option.map(state.extra, (function (extra) {
                            return extra.price;
                          })),
                    onRequestPriceChange: (function (value) {
                        Curry._1(dispatch, {
                              TAG: /* ExtraUpdated */4,
                              _0: {
                                price: value
                              }
                            });
                      })
                  }) : null),
        minWidth: Belt_Option.isSome(activeShop) ? ({
              NAME: "px",
              VAL: 110
            }) : ({
              NAME: "px",
              VAL: 80
            }),
        width: {
          NAME: "px",
          VAL: 170
        }
      },
      render: (function (param) {
          var match = param.data;
          var purchasePrice = match.purchasePrice;
          var feesList = match.feesList;
          var otherAmount = feesList.otherAmount;
          var taxesAmount = feesList.taxesAmount;
          if (activeShop === undefined) {
            return nonApplicableValueCellElement;
          }
          if (currentPricelist === undefined) {
            return React.createElement(Spinner.make, {
                        size: 18
                      });
          }
          var match$1 = pricingSettings.purchasePriceType;
          var netOrGrossPurchasePrice = match$1 ? purchasePrice - match.purchasePriceDiscount : purchasePrice;
          var match$2 = pricingSettings.retailPriceCalculatingMethod;
          var computedPurchasePrice;
          switch (match$2) {
            case /* PurchasePrice */0 :
                computedPurchasePrice = netOrGrossPurchasePrice;
                break;
            case /* FullPurchasePrice */1 :
                computedPurchasePrice = computeFullPurchasePrice(netOrGrossPurchasePrice, taxesAmount, otherAmount);
                break;
            case /* AcquisitionCost */2 :
                computedPurchasePrice = computeAcquisitionCost(netOrGrossPurchasePrice, feesList.transportAmount, taxesAmount, otherAmount);
                break;
            
          }
          return React.createElement(make$15, {
                      variantId: match.id,
                      shopId: activeShop.id,
                      pricelist: currentPricelist,
                      bulkUnit: match.bulkUnit,
                      taxRate: match.productTaxRate,
                      feesList: feesList,
                      calculatingMethod: pricingSettings.retailPriceCalculatingMethod,
                      purchasePrice: computedPurchasePrice,
                      value: match.retailPrice
                    });
        })
    },
    {
      key: "created-at",
      name: $$Intl.t("Creation date"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 80
        },
        width: {
          NAME: "px",
          VAL: 140
        }
      },
      allowsSorting: true,
      render: (function (param) {
          var createdAt = param.data.createdAt;
          var formattedDatetime = $$Intl.dateTimeFormat(undefined, undefined, "2-digit", "2-digit", "2-digit", undefined, undefined, undefined, createdAt);
          var match = $$Intl.dateTimeFormat(undefined, undefined, undefined, undefined, undefined, "numeric", "numeric", undefined, createdAt);
          var formattedTime = $$Intl.locale === "fr-FR" ? match.replace(":", "h") : match;
          return React.createElement("div", {
                      style: {
                        display: "flex",
                        width: "100%",
                        columnGap: "2px",
                        flexDirection: "column"
                      }
                    }, React.createElement(TextStyle.make, {
                          children: formattedDatetime,
                          size: "xxsmall",
                          maxLines: 1
                        }), React.createElement(TextStyle.make, {
                          children: formattedTime,
                          variation: "normal",
                          size: "tiny",
                          lineHeight: "normal",
                          maxLines: 1
                        }));
        })
    },
    {
      key: "status",
      name: $$Intl.t("Status"),
      layout: {
        width: {
          NAME: "px",
          VAL: 110
        }
      },
      render: (function (param) {
          var status = param.data.status;
          if (status !== undefined) {
            return React.createElement(CatalogProductStatusBadge.make, {
                        value: status,
                        size: "medium"
                      });
          } else {
            return React.createElement(TextStyle.make, {
                        children: $$Intl.t("Differing"),
                        variation: "subdued",
                        size: "xsmall"
                      });
          }
        })
    },
    {
      key: "quickprint-action",
      layout: {
        hidden: Belt_Option.isNone(state.filters.shop) || Belt_Option.mapWithDefault(match$1[0], true, Belt_Result.isError),
        minWidth: {
          NAME: "px",
          VAL: 40
        },
        width: {
          NAME: "px",
          VAL: 40
        },
        alignX: "flexEnd"
      },
      render: (function (param) {
          var id = param.data.id;
          var match = state.filters.shop;
          if (match !== undefined) {
            return React.createElement(CatalogLabelQuickPrintButton.make, {
                        variation: "vertical",
                        featureLocked: false,
                        fromEditRedirection: variantIdfromLabelEditSettings === id,
                        variantId: id,
                        shopId: match.id,
                        requestBarcodeCompletion: CatalogLabel.BarcodeCompletionRequest.make,
                        requestLabelsPrinting: CatalogLabel.Print.LabelsRequest.make,
                        onRequestNotification: (function (value) {
                            Curry._1(setNotification, (function (param) {
                                    return value;
                                  }));
                          })
                      });
          } else {
            return null;
          }
        })
    },
    {
      key: "actions",
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 40
        },
        width: {
          NAME: "px",
          VAL: 40
        },
        alignX: "flexEnd"
      },
      render: (function (param) {
          var match = param.data;
          var tmp = {
            cku: match.cku,
            id: match.id
          };
          if (match.status !== undefined) {
            tmp.status = Caml_option.valFromOption(match.status);
          }
          return React.createElement(CatalogTableActions.make, tmp);
        })
    }
  ];
  var shopFilter;
  if (organisationAccount) {
    var tmp$1 = {
      variation: "secondary",
      hideLabel: true,
      truncateItemLabel: true,
      onChange: (function (shop) {
          Curry._1(dispatch, {
                TAG: /* FiltersUpdated */2,
                _0: (function (prev) {
                    return {
                            shop: shop,
                            status: prev.status,
                            category: prev.category,
                            supplier: prev.supplier,
                            producer: prev.producer,
                            stock: prev.stock
                          };
                  })
              });
        })
    };
    if (state.filters.shop !== undefined) {
      tmp$1.value = Caml_option.valFromOption(state.filters.shop);
    }
    shopFilter = React.createElement(Auth.SelectShopFilter.make, tmp$1);
  } else {
    shopFilter = null;
  }
  var statuses$1 = getStatuses(shopsIndependentKind);
  var defaultItem_label = $$Intl.t("All");
  var defaultItem = {
    key: "default",
    label: defaultItem_label
  };
  var items = Belt_Array.map(statuses$1, (function (value) {
          return {
                  key: CatalogProduct.Status.toString(value),
                  value: value,
                  label: CatalogProduct.Status.toLabel(value)
                };
        }));
  var filters = [
    React.createElement(Select.make, {
          label: $$Intl.t("Status"),
          highlighted: shopsIndependentKind ? state.filters.status !== /* Unarchived */3 : state.filters.status !== /* Active */0,
          sections: [{
              items: Belt_Array.concat(items, [defaultItem]),
              title: $$Intl.t("Statuses")
            }],
          preset: "filter",
          size: "compact",
          value: state.filters.status,
          onChange: (function (status) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (prev) {
                        return {
                                shop: prev.shop,
                                status: status,
                                category: prev.category,
                                supplier: prev.supplier,
                                producer: prev.producer,
                                stock: prev.stock
                              };
                      })
                  });
            })
        }),
    React.createElement(CatalogCategorySelectFilter.make, {
          shopId: Belt_Option.map(activeShop, (function (shop) {
                  return shop.id;
                })),
          value: state.filters.category,
          onChange: (function (category) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (prev) {
                        return {
                                shop: prev.shop,
                                status: prev.status,
                                category: category,
                                supplier: prev.supplier,
                                producer: prev.producer,
                                stock: prev.stock
                              };
                      })
                  });
            })
        }),
    React.createElement(SupplierSelect.make, {
          size: "compact",
          label: $$Intl.t("Supplier"),
          preset: "filter",
          showDefaultItem: true,
          hideOverlayFooter: true,
          shopId: Belt_Option.map(activeShop, (function (shop) {
                  return shop.id;
                })),
          value: state.filters.supplier,
          onChange: (function (supplier) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (prev) {
                        return {
                                shop: prev.shop,
                                status: prev.status,
                                category: prev.category,
                                supplier: supplier,
                                producer: prev.producer,
                                stock: prev.stock
                              };
                      })
                  });
            })
        }),
    React.createElement(CatalogProducerSelect.make, {
          shopId: Belt_Option.map(activeShop, (function (shop) {
                  return shop.id;
                })),
          value: state.filters.producer,
          onChange: (function (producer) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (prev) {
                        return {
                                shop: prev.shop,
                                status: prev.status,
                                category: prev.category,
                                supplier: prev.supplier,
                                producer: producer,
                                stock: prev.stock
                              };
                      })
                  });
            })
        }),
    React.createElement(CatalogStockRangeSelect.make, {
          disabled: Belt_Option.isNone(activeShop),
          value: state.filters.stock,
          onChange: (function (stock) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (prev) {
                        return {
                                shop: prev.shop,
                                status: prev.status,
                                category: prev.category,
                                supplier: prev.supplier,
                                producer: prev.producer,
                                stock: stock
                              };
                      })
                  });
            })
        })
  ];
  var match$8 = state.filters;
  var status = match$8.status;
  var activeFiltersCount = Belt_Array.keep([
        shopsIndependentKind ? (
            status !== undefined && status !== 2 ? false : true
          ) : (
            status !== undefined ? (
                status !== 0 ? status < 3 : false
              ) : true
          ),
        Belt_Option.isSome(match$8.category),
        Belt_Option.isSome(match$8.supplier),
        Belt_Option.isSome(match$8.producer),
        Belt_Option.isSome(match$8.stock)
      ], (function (active) {
          return active;
        })).length;
  var handleFilterReset = activeFiltersCount > 0 ? (function (param) {
        Curry._1(dispatch, {
              TAG: /* FiltersUpdated */2,
              _0: (function (param) {
                  return initialState.filters;
                })
            });
      }) : undefined;
  var mainAction = React.createElement(make$1, {
        onChange: (function (value) {
            Curry._1(setPricingSettings, (function (param) {
                    return value;
                  }));
          })
      });
  var match$9 = makeFromFilters(state.filters);
  var variantsArchived = match$9.variantsArchived;
  var variantsActive = match$9.variantsActive;
  var shopId = match$9.shopId;
  var match$10 = Auth.useShops(undefined);
  var shopIds = match$10.length !== 0 ? (
      shopId !== undefined ? [shopId] : Belt_Array.map(match$10, (function (shop) {
                return shop.id;
              }))
    ) : undefined;
  var makeMenuItemAction = function (productKind) {
    return {
            TAG: /* OpenLink */1,
            _0: {
              TAG: /* RouteWithQueryString */1,
              _0: LegacyRouter.routeToPathname(/* Catalog */2) + "/product/create",
              _1: CatalogProductEditUrlQueryString.CreateProduct.encode({
                    productKind: productKind
                  })
            }
          };
  };
  var onRequestErrorNotification = function (error) {
    Curry._1(setNotification, (function (param) {
            return {
                    TAG: /* Error */1,
                    _0: error
                  };
          }));
  };
  var tmp$2;
  if (shopIds !== undefined) {
    var tmp$3 = {
      tooltip: activeFiltersCount === 0 ? $$Intl.t("Export inventory") : $$Intl.t("Export filtered inventory"),
      shopIds: shopIds,
      activeFilters: activeFiltersCount > 0,
      onRequestErrorNotification: onRequestErrorNotification
    };
    if (variantsActive !== undefined) {
      tmp$3.variantsActive = Caml_option.valFromOption(variantsActive);
    }
    if (variantsArchived !== undefined) {
      tmp$3.variantsArchived = Caml_option.valFromOption(variantsArchived);
    }
    if (match$9.categoryId !== undefined) {
      tmp$3.categoryId = Caml_option.valFromOption(match$9.categoryId);
    }
    if (match$9.supplierId !== undefined) {
      tmp$3.supplierId = Caml_option.valFromOption(match$9.supplierId);
    }
    if (match$9.producer !== undefined) {
      tmp$3.producer = Caml_option.valFromOption(match$9.producer);
    }
    if (match$9.stock !== undefined) {
      tmp$3.stock = Caml_option.valFromOption(match$9.stock);
    }
    tmp$2 = React.createElement(CatalogListPage$CatalogListInventoryExportShortIconButton, tmp$3);
  } else {
    tmp$2 = null;
  }
  var tmp$4 = {
    text: $$Intl.t("Export PLU codes"),
    shopId: shopId,
    onRequestErrorNotification: onRequestErrorNotification
  };
  if (variantsActive !== undefined) {
    tmp$4.variantsActive = Caml_option.valFromOption(variantsActive);
  }
  if (variantsArchived !== undefined) {
    tmp$4.variantsArchived = Caml_option.valFromOption(variantsArchived);
  }
  var actions = React.createElement(Inline.make, {
        children: null,
        space: "small"
      }, tmp$2, React.createElement(ShortIconButton.make, ShortIconButton.makeProps("printing_label", undefined, undefined, undefined, undefined, $$Intl.t("Generate labels"), undefined, {
                TAG: /* OpenLink */1,
                _0: {
                  TAG: /* Route */0,
                  _0: LegacyRouter.routeToPathname(/* Catalog */2) + "/labels/create"
                }
              }, undefined, undefined, undefined)), React.createElement(Menu.make, {
            children: React.createElement(React.Fragment, undefined, React.createElement(MenuItem.make, {
                      content: {
                        TAG: /* Text */0,
                        _0: $$Intl.t("Import inventory")
                      },
                      size: "normal",
                      action: {
                        TAG: /* OpenLink */1,
                        _0: {
                          TAG: /* Route */0,
                          _0: LegacyRouter.routeToPathname(/* Catalog */2) + "/inventory/import"
                        }
                      }
                    }), React.createElement(CatalogListPage$CatalogListPriceLookUpExportMenuItem, tmp$4), organisationAccount ? React.createElement(CatalogListPage$CatalogListCentralizeRequestMenuItem, {
                        text: $$Intl.t("Centralize catalogs"),
                        onRequestErrorNotification: onRequestErrorNotification
                      }) : null),
            variation: "more_square",
            disabled: Belt_Option.isNone(shopIds),
            overlayPriority: false
          }), React.createElement(Separator.make, {
            size: "large"
          }), React.createElement(Menu.make, {
            children: null,
            buttonText: $$Intl.t("New reference"),
            buttonVariation: "primary",
            buttonSize: "small"
          }, React.createElement(MenuItem.make, {
                content: {
                  TAG: /* Text */0,
                  _0: CatalogProduct.Kind.toLabel(true, undefined, "WINE")
                },
                action: makeMenuItemAction("WINE")
              }), React.createElement(MenuItem.make, {
                content: {
                  TAG: /* Text */0,
                  _0: CatalogProduct.Kind.toLabel(true, undefined, "SPIRITUOUS")
                },
                action: makeMenuItemAction("SPIRITUOUS")
              }), React.createElement(MenuItem.make, {
                content: {
                  TAG: /* Text */0,
                  _0: CatalogProduct.Kind.toLabel(true, undefined, "BEER")
                },
                action: makeMenuItemAction("BEER")
              }), React.createElement(MenuItem.make, {
                content: {
                  TAG: /* Text */0,
                  _0: CatalogProduct.Kind.toLabel(true, undefined, "SIMPLE")
                },
                action: makeMenuItemAction("SIMPLE")
              })));
  var tmp$5 = {
    placeholder: $$Intl.t("Search") + "...",
    onChange: (function (searchQuery) {
        Curry._1(dispatch, {
              TAG: /* Searched */1,
              _0: searchQuery
            });
      })
  };
  if (state.searchQuery !== undefined) {
    tmp$5.value = state.searchQuery;
  }
  var searchBar = React.createElement(ResourceListPage.ResourceListSearchBar.make, tmp$5);
  var tableEmptyState;
  var exit = 0;
  if (state.currentPage !== 1) {
    exit = 1;
  } else {
    var match$11 = state.searchQuery;
    if (match$11 === "") {
      var match$12 = state.filters;
      if (match$12.status !== undefined || match$12.category !== undefined || match$12.supplier !== undefined || match$12.producer !== undefined || match$12.shop !== activeShop) {
        exit = 1;
      } else {
        tableEmptyState = React.createElement(EmptyState.make, {
              illustration: Illustration.create,
              title: $$Intl.t("Welcome to the catalog space.")
            });
      }
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tableEmptyState = React.createElement(EmptyState.make, {
          illustration: Illustration.notFound,
          title: $$Intl.t("No result were found."),
          text: $$Intl.t("Try again with another keyword/filter or:"),
          children: React.createElement(Button.make, Button.makeProps($$Intl.t("Clear search query and filters"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Reset */5,
                            _0: initialState
                          });
                    }), undefined, undefined, undefined))
        });
  }
  var paginationCurrentPage = state.currentPage;
  var match$13;
  var exit$1 = 0;
  var totalCount;
  var exit$2 = 0;
  var exit$3 = 0;
  var exit$4 = 0;
  if (typeof queryMultiShopsAsyncResult === "number" || queryMultiShopsAsyncResult.TAG !== /* Done */1) {
    exit$4 = 4;
  } else {
    var match$14 = queryMultiShopsAsyncResult._0;
    if (match$14.TAG === /* Ok */0) {
      totalCount = match$14._0.variantsDistinctOnCku.totalCount;
      exit$1 = 1;
    } else {
      exit$4 = 4;
    }
  }
  if (exit$4 === 4) {
    if (typeof querySingleShopAsyncResult === "number" || querySingleShopAsyncResult.TAG !== /* Done */1) {
      exit$3 = 3;
    } else {
      var match$15 = querySingleShopAsyncResult._0;
      if (match$15.TAG === /* Ok */0) {
        totalCount = match$15._0.variants.totalCount;
        exit$1 = 1;
      } else {
        exit$3 = 3;
      }
    }
  }
  if (exit$3 === 3) {
    if (typeof queryMultiShopsAsyncResult === "number" || queryMultiShopsAsyncResult.TAG !== /* Reloading */0) {
      exit$2 = 2;
    } else {
      var match$16 = queryMultiShopsAsyncResult._0;
      if (match$16.TAG === /* Ok */0) {
        totalCount = match$16._0.variantsDistinctOnCku.totalCount;
        exit$1 = 1;
      } else {
        exit$2 = 2;
      }
    }
  }
  if (exit$2 === 2) {
    if (typeof querySingleShopAsyncResult === "number" || querySingleShopAsyncResult.TAG !== /* Reloading */0) {
      match$13 = [
        0,
        1
      ];
    } else {
      var match$17 = querySingleShopAsyncResult._0;
      if (match$17.TAG === /* Ok */0) {
        totalCount = match$17._0.variants.totalCount;
        exit$1 = 1;
      } else {
        match$13 = [
          0,
          1
        ];
      }
    }
  }
  if (exit$1 === 1) {
    match$13 = [
      totalCount,
      ResourceList.totalPages(totalCount, ResourceList.defaultEdgesPerPage)
    ];
  }
  var paginationLoading = AsyncResult.isReloading(queryMultiShopsAsyncResult) || AsyncResult.isReloading(querySingleShopAsyncResult);
  var match$18 = state.connectionArguments;
  var paginationLastDirection = match$18.last !== undefined ? /* Backward */1 : /* Forward */0;
  var tableData = Belt_Option.isNone(state.filters.shop) ? AsyncResult.mapError(AsyncResult.mapOk(queryMultiShopsAsyncResult, catalogListMultiShopsTableRowsFromQueryResult), (function (param) {
            
          })) : AsyncResult.mapError(AsyncResult.mapOk(querySingleShopAsyncResult, (function (result) {
                return catalogListSingleShopTableRowsFromQueryResult(result, Belt_Option.map(state.extra, (function (extra) {
                                  return extra.price.id;
                                })));
              })), (function (param) {
            
          }));
  var tmp$6 = {
    title: $$Intl.t("Catalog"),
    shopFilter: shopFilter,
    filters: filters,
    activeFiltersCount: activeFiltersCount,
    mainAction: mainAction,
    actions: actions,
    searchBar: searchBar,
    tableData: tableData,
    tableColumns: tableColumns,
    tableKeyExtractor: keyExtractor,
    tableEmptyState: tableEmptyState,
    paginationCurrentPage: paginationCurrentPage,
    paginationTotalCount: match$13[0],
    paginationTotalPages: match$13[1],
    paginationLoading: paginationLoading,
    paginationLastDirection: paginationLastDirection,
    tableSortDescriptor: toSortDescriptor(state.sorting),
    onTableSortChange: onTableSortChange,
    onRequestPaginate: onRequestPaginate
  };
  if (handleFilterReset !== undefined) {
    tmp$6.onRequestResetFilters = Caml_option.valFromOption(handleFilterReset);
  }
  return React.createElement(ResourceListPage.make, tmp$6);
}

var make$16 = React.memo(CatalogListPage);

export {
  nonApplicableStringLiteral ,
  nonApplicableValueCellElement ,
  CatalogListMultiShopsQuery ,
  CatalogListSingleShopQuery ,
  CatalogListPricelist ,
  CatalogListTableRow ,
  CatalogListNotificationBanner ,
  CatalogListPricingSettings ,
  CatalogListSettingsPopoverButton ,
  CatalogListInventoryExportShortIconButton ,
  CatalogListPriceLookUpExportMenuItem ,
  CatalogListCentralizeRequestMenuItem ,
  CatalogListExtraParamsPrice ,
  CatalogListTableHeaderActionPricelistSelect ,
  CatalogListPurchasePriceCell ,
  CatalogListRetailPriceCell ,
  CatalogListExtraParams ,
  CatalogListFilters ,
  CatalogListSorting ,
  CatalogListSheetExportInput ,
  catalogListMultiShopsTableRowsFromQueryResult ,
  catalogListSingleShopTableRowsFromQueryResult ,
  catalogListMultiShopsQueryVariablesFilterBy ,
  catalogListMultiShopsQueryVariablesOrderBy ,
  catalogListSingleShopQueryVariablesFilterBy ,
  catalogListSingleShopQueryVariablesOrderBy ,
  catalogListMultiShopsQueryVariables ,
  catalogListSingleShopQueryVariables ,
  make$16 as make,
}
/* nonApplicableValueCellElement Not a pure module */
