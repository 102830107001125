// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Group from "../../resources/layout-and-structure/Group.bs.js";
import * as React from "react";
import * as FieldsetLayoutPanel from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";
import * as CatalogProductEditForm from "./CatalogProductEditForm.bs.js";
import * as CatalogProductEditFormInputs from "./CatalogProductEditFormInputs.bs.js";

function CatalogProductEditFormInformationFieldsetPanel(Props) {
  var state = Curry._1(CatalogProductEditForm.useFormState, undefined);
  var productKind = state.initialValues.kind;
  var match = state.values.color;
  return React.createElement(FieldsetLayoutPanel.make, {
              title: $$Intl.t("General information"),
              description: $$Intl.t("Complete the product information.\nThe information of this reference will be later enriched by the information of the variant."),
              children: null
            }, React.createElement(CatalogProductEditForm.InputText.make, {
                  field: /* Name */0,
                  label: $$Intl.t("Product's name"),
                  placeholder: $$Intl.t("Product's name")
                }), productKind === "WINE" || productKind === "BEER" || productKind === "SPIRITUOUS" ? React.createElement(Group.make, {
                    children: null
                  }, productKind === "SPIRITUOUS" ? React.createElement(CatalogProductEditFormInputs.ProductFamilyComboBox.make, {}) : (
                      productKind === "WINE" || productKind === "BEER" ? React.createElement(CatalogProductEditFormInputs.ColorSelect.make, {}) : null
                    ), productKind === "BEER" ? React.createElement(CatalogProductEditFormInputs.ProductBeerTypeComboBox.make, {}) : (
                      productKind === "WINE" ? React.createElement(CatalogProductEditFormInputs.WineTypeSelect.make, {}) : null
                    )) : null, productKind === "WINE" && match === "WHITE" ? React.createElement(CatalogProductEditFormInputs.WhiteWineTypeSelect.make, {}) : null, productKind === "WINE" ? React.createElement(Group.make, {
                    children: null
                  }, React.createElement(CatalogProductEditFormInputs.ProductProducerComboBox.make, {}), React.createElement(CatalogProductEditFormInputs.ProductDesignationComboBox.make, {})) : null, React.createElement(Group.make, {
                  children: null
                }, React.createElement(CatalogProductEditFormInputs.ProductCountrySelect.make, {}), productKind === "WINE" ? React.createElement(CatalogProductEditFormInputs.ProductRegionComboBox.make, {}) : React.createElement(CatalogProductEditFormInputs.ProductProducerComboBox.make, {})), React.createElement(CatalogProductEditFormInputs.ProductTaxSelect.make, {
                  productKind: productKind
                }));
}

var make = React.memo(CatalogProductEditFormInformationFieldsetPanel);

export {
  make ,
}
/* make Not a pure module */
