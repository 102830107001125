// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Spaces from "../theme/Spaces.bs.js";
import * as FontSizes from "../theme/FontSizes.bs.js";
import * as ReactAria from "../../externals/ReactAria.bs.js";
import * as ReactAria$1 from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactStately from "react-stately";
import * as AnimatedRender from "../../primitives/AnimatedRender.bs.js";
import * as Stylex from "@stylexjs/stylex";

var styles = Stylex.create({
      root: {
        font: "normal 400 12px \"Libre Franklin\"",
        lineHeight: "14px",
        whiteSpace: "pre-line"
      }
    });

function styleProps(tone, italic, bold, size) {
  return Stylex.props(styles.root, {
              color: tone === "faded" ? "#bdbdca" : (
                  tone === "success" ? "#1dbb7a" : "#fefefe"
                ),
              fontSize: FontSizes.toPx(size),
              fontStyle: italic ? "italic" : "normal",
              fontWeight: bold ? 700 : 400
            });
}

function Tooltip$Span(Props) {
  var text = Props.text;
  var toneOpt = Props.tone;
  var italicOpt = Props.italic;
  var boldOpt = Props.bold;
  var sizeOpt = Props.size;
  var tone = toneOpt !== undefined ? toneOpt : "neutral";
  var italic = italicOpt !== undefined ? italicOpt : false;
  var bold = boldOpt !== undefined ? boldOpt : false;
  var size = sizeOpt !== undefined ? sizeOpt : "xxsmall";
  var match = styleProps(tone, italic, bold, size);
  var tmp = {};
  if (match.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match.className);
  }
  if (match.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match.style);
  }
  return React.createElement("span", tmp, text);
}

var make = React.memo(Tooltip$Span);

var styles$1 = Stylex.create({
      root: {
        lineHeight: "0px",
        content: "",
        display: "block"
      }
    });

function styleProps$1(space) {
  return Stylex.props(styles$1.root, {
              lineHeight: String(Spaces.toFloat(space)) + "px",
              paddingBottom: String(Spaces.toFloat(space)) + "px"
            });
}

function Tooltip$Br(Props) {
  var spaceOpt = Props.space;
  var space = spaceOpt !== undefined ? spaceOpt : "none";
  var match = styleProps$1(space);
  var tmp = {};
  if (match.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match.className);
  }
  if (match.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match.style);
  }
  return React.createElement("br", tmp);
}

var make$1 = React.memo(Tooltip$Br);

var styles$2 = Stylex.create({
      root: {
        color: "transparent",
        lineHeight: "14px",
        backgroundColor: "#35334b",
        borderRadius: "5px",
        display: "inline-block",
        maxWidth: "350px",
        padding: "8px 12px",
        paddingBottom: "9px"
      },
      arrow: {
        borderBottomColor: "#35334b",
        borderBottomWidth: "0px",
        borderLeftColor: "transparent",
        borderLeftWidth: "5px",
        borderRightColor: "transparent",
        borderRightWidth: "5px",
        borderStyle: "solid",
        borderTopColor: "#35334b",
        borderTopWidth: "6px",
        position: "absolute"
      }
    });

function arrowStyleProps(placement, positionStyleProps, crossOffset) {
  var tmp;
  var exit = 0;
  if (placement === "bottom") {
    var left = positionStyleProps.style.left;
    if (left !== undefined) {
      tmp = {
        left: String(left + crossOffset - 4) + "px",
        top: "-4px"
      };
    } else {
      exit = 1;
    }
  } else if (placement === "right") {
    var top = positionStyleProps.style.top;
    if (top !== undefined) {
      tmp = {
        left: "-7px",
        top: String(top + crossOffset - 3) + "px"
      };
    } else {
      exit = 1;
    }
  } else if (placement === "top") {
    var left$1 = positionStyleProps.style.left;
    if (left$1 !== undefined) {
      tmp = {
        bottom: "-4px",
        left: String(left$1 + crossOffset - 4) + "px"
      };
    } else {
      exit = 1;
    }
  } else {
    var top$1 = positionStyleProps.style.top;
    if (top$1 !== undefined) {
      tmp = {
        right: "-7px",
        top: String(top$1 + crossOffset - 3) + "px"
      };
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tmp = {};
  }
  return Stylex.props(styles$2.arrow, {
              transform: placement === "bottom" ? "rotate(180deg)" : (
                  placement === "right" ? "rotate(90deg)" : (
                      placement === "top" ? "rotate(0deg)" : "rotate(-90deg)"
                    )
                )
            }, tmp);
}

var make$2 = React.memo(function (Props) {
      var arrowedOpt = Props.arrowed;
      var placement = Props.placement;
      var offset = Props.offset;
      var crossOffsetOpt = Props.crossOffset;
      var content = Props.content;
      var state = Props.state;
      var ariaProps = Props.ariaProps;
      var triggerDomRef = Props.triggerDomRef;
      var immediateClose = Props.immediateClose;
      var arrowed = arrowedOpt !== undefined ? arrowedOpt : true;
      var crossOffset = crossOffsetOpt !== undefined ? crossOffsetOpt : 0;
      var domRef = React.useRef(null);
      var props = ReactAria$1.mergeProps(ariaProps, {
            isOpen: state.isOpen
          });
      var match = ReactAria$1.useTooltip(props, state);
      var tooltipProps = match.tooltipProps;
      var position = ReactAria$1.useOverlayPosition({
            targetRef: triggerDomRef,
            overlayRef: domRef,
            isOpen: state.isOpen,
            placement: placement,
            offset: arrowed ? Spaces.small + Belt_Option.getWithDefault(offset, 0) : (
                offset !== undefined ? offset : Spaces.xxsmall
              ),
            crossOffset: crossOffset
          });
      var match$1 = Stylex.props(styles$2.root);
      var match$2 = arrowStyleProps(position.placement, position.arrowProps, crossOffset);
      var tooltipProps$1 = immediateClose ? ReactAria$1.mergeProps(tooltipProps, {
              tabIndex: 0,
              onMouseEnter: null,
              onTouchStart: null,
              onClick: (function ($$event) {
                  $$event.stopPropagation();
                })
            }) : ReactAria$1.mergeProps(tooltipProps, {
              tabIndex: 0,
              onClick: (function ($$event) {
                  $$event.stopPropagation();
                })
            });
      var props$1 = ReactAria$1.mergeProps(tooltipProps$1, position.overlayProps);
      var tmp;
      if (arrowed) {
        var tmp$1 = {};
        if (match$2.className !== undefined) {
          tmp$1.className = Caml_option.valFromOption(match$2.className);
        }
        if (match$2.style !== undefined) {
          tmp$1.style = Caml_option.valFromOption(match$2.style);
        }
        tmp = React.createElement("div", tmp$1);
      } else {
        tmp = null;
      }
      var tmp$2 = {};
      if (match$1.className !== undefined) {
        tmp$2.className = Caml_option.valFromOption(match$1.className);
      }
      if (match$1.style !== undefined) {
        tmp$2.style = Caml_option.valFromOption(match$1.style);
      }
      return React.createElement(ReactAria$1.Overlay, {
                  children: React.createElement(ReactAria.Spread.make, {
                        props: props$1,
                        children: React.createElement("span", {
                              ref: domRef
                            }, React.createElement(AnimatedRender.make, {
                                  children: React.createElement(React.Fragment, undefined, tmp, React.createElement("div", tmp$2, content)),
                                  displayed: state.isOpen,
                                  duration: 50
                                }))
                      })
                });
    });

function Tooltip(Props) {
  var childrenOpt = Props.children;
  var arrowedOpt = Props.arrowed;
  var placementOpt = Props.placement;
  var offset = Props.offset;
  var crossOffset = Props.crossOffset;
  var content = Props.content;
  var disabledOpt = Props.disabled;
  var delayOpt = Props.delay;
  var closeDelayOpt = Props.closeDelay;
  var triggerAlignOpt = Props.triggerAlign;
  var derivedOpened = Props.opened;
  var onOpenChange = Props.onOpenChange;
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var arrowed = arrowedOpt !== undefined ? arrowedOpt : true;
  var placement = placementOpt !== undefined ? placementOpt : "bottom";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var delay = delayOpt !== undefined ? delayOpt : 500;
  var closeDelay = closeDelayOpt !== undefined ? closeDelayOpt : 50;
  var triggerAlign = triggerAlignOpt !== undefined ? triggerAlignOpt : "auto";
  var domRef = React.useRef(null);
  var props_isDisabled = disabled;
  var props_delay = delay;
  var props_closeDelay = closeDelay;
  var props = {
    isDisabled: props_isDisabled,
    isOpen: derivedOpened,
    delay: props_delay,
    closeDelay: props_closeDelay,
    onOpenChange: onOpenChange
  };
  var state = ReactStately.useTooltipTriggerState(props);
  var match = ReactAria$1.useTooltipTrigger(props, state, domRef);
  var opened = state.isOpen && !disabled;
  var triggerStyle = {
    cursor: "inherit",
    display: "flex",
    alignSelf: triggerAlign,
    flexDirection: "column"
  };
  var triggerProps = ReactAria$1.mergeProps(match.triggerProps, {
        onPointerDown: null,
        onMouseDown: null
      });
  var tmp;
  if (opened) {
    var tmp$1 = {
      arrowed: arrowed,
      placement: placement,
      content: content,
      state: state,
      ariaProps: match.tooltipProps,
      triggerDomRef: domRef,
      immediateClose: closeDelay < 50
    };
    if (offset !== undefined) {
      tmp$1.offset = Caml_option.valFromOption(offset);
    }
    if (crossOffset !== undefined) {
      tmp$1.crossOffset = Caml_option.valFromOption(crossOffset);
    }
    tmp = React.createElement(make$2, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(ReactAria.Spread.make, {
                  props: triggerProps,
                  children: React.createElement("div", {
                        ref: domRef,
                        style: triggerStyle
                      }, children)
                }), tmp);
}

var make$3 = React.memo(Tooltip);

var Span = {
  make: make
};

var Br = {
  make: make$1
};

export {
  Span ,
  Br ,
  make$3 as make,
}
/* styles Not a pure module */
