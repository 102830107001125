// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Uuid from "../../externals/Uuid.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ApolloHelpers from "../../helpers/ApolloHelpers.bs.js";
import * as Client from "@apollo/client";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var EmptyProductName = /* @__PURE__ */Caml_exceptions.create("CatalogProductEditFormMutations.EmptyProductName");

var ShopIdNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogProductEditFormMutations.ShopIdNotFound");

var TaxIdNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogProductEditFormMutations.TaxIdNotFound");

var FormIdNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogProductEditFormMutations.FormIdNotFound");

var Raw = {};

var query = Client.gql(["mutation createProduct($input: InputCreateProduct!)  {\ncreateProduct(input: $input)  {\n__typename  \nid  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.createProduct;
  return {
          createProduct: {
            __typename: value$1.__typename,
            id: value$1.id
          }
        };
}

function serialize(value) {
  var value$1 = value.createProduct;
  var value$2 = value$1.id;
  var value$3 = value$1.__typename;
  var createProduct = {
    __typename: value$3,
    id: value$2
  };
  return {
          createProduct: createProduct
        };
}

function serializeInputObjectInputCreateProduct(inp) {
  var a = inp.designation;
  var a$1 = inp.color;
  var a$2 = inp.kind;
  var a$3 = inp.family;
  var a$4 = inp.producer;
  var a$5 = inp.region;
  var a$6 = inp.beerType;
  var a$7 = inp.wineType;
  var a$8 = inp.whiteWineType;
  var a$9 = inp.internalNote;
  var a$10 = inp.categoryId;
  return {
          name: inp.name,
          designation: a !== undefined ? a : undefined,
          color: a$1 !== undefined ? (
              a$1 === "ORANGE" ? "ORANGE" : (
                  a$1 === "RED" ? "RED" : (
                      a$1 === "AMBER" ? "AMBER" : (
                          a$1 === "DARK" ? "DARK" : (
                              a$1 === "BLACK" ? "BLACK" : (
                                  a$1 === "BLOND" ? "BLOND" : (
                                      a$1 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          kind: a$2 === "SPIRITUOUS" ? "SPIRITUOUS" : (
              a$2 === "BEER" ? "BEER" : (
                  a$2 === "WINE" ? "WINE" : "SIMPLE"
                )
            ),
          family: a$3 !== undefined ? a$3 : undefined,
          producer: a$4 !== undefined ? a$4 : undefined,
          country: inp.country,
          region: a$5 !== undefined ? a$5 : undefined,
          beerType: a$6 !== undefined ? a$6 : undefined,
          wineType: a$7 !== undefined ? (
              a$7 === "EFFERVESCENT" ? "EFFERVESCENT" : "STILL"
            ) : undefined,
          whiteWineType: a$8 !== undefined ? (
              a$8 === "SWEET" ? "SWEET" : (
                  a$8 === "SEMIDRY" ? "SEMIDRY" : (
                      a$8 === "SOFT" ? "SOFT" : "DRY"
                    )
                )
            ) : undefined,
          internalNote: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          taxId: inp.taxId,
          categoryId: a$10 !== undefined ? a$10 : undefined,
          shopId: inp.shopId
        };
}

function serializeVariables(inp) {
  return {
          input: serializeInputObjectInputCreateProduct(inp.input)
        };
}

function makeVariables(input, param) {
  return {
          input: input
        };
}

function makeInputObjectInputCreateProduct(name, designation, color, kind, family, producer, country, region, beerType, wineType, whiteWineType, internalNote, taxId, categoryId, shopId, param) {
  return {
          name: name,
          designation: designation,
          color: color,
          kind: kind,
          family: family,
          producer: producer,
          country: country,
          region: region,
          beerType: beerType,
          wineType: wineType,
          whiteWineType: whiteWineType,
          internalNote: internalNote,
          taxId: taxId,
          categoryId: categoryId,
          shopId: shopId
        };
}

var CreateProductMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputCreateProduct: serializeInputObjectInputCreateProduct,
  makeVariables: makeVariables,
  makeInputObjectInputCreateProduct: makeInputObjectInputCreateProduct
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var CreateProductMutation_useWithVariables = include.useWithVariables;

var CreateProductMutation = {
  CreateProductMutation_inner: CreateProductMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputCreateProduct: serializeInputObjectInputCreateProduct,
  makeVariables: makeVariables,
  makeInputObjectInputCreateProduct: makeInputObjectInputCreateProduct,
  use: use,
  useWithVariables: CreateProductMutation_useWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["mutation updateProduct($id: ID!, $input: InputUpdateProduct!)  {\nupdateProduct(id: $id, input: $input)  {\n__typename  \nid  \nupdatedAt  \nname  \nformattedDescription  \nformattedOrigin  \ncategory  {\n__typename  \nid  \nformattedName  \n}\n\ntax  {\n__typename  \nvalue  \n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.updateProduct;
  var value$2 = value$1.formattedDescription;
  var value$3 = value$1.category;
  var value$4 = value$1.tax;
  return {
          updateProduct: {
            __typename: value$1.__typename,
            id: value$1.id,
            updatedAt: Scalar.Datetime.parse(value$1.updatedAt),
            name: value$1.name,
            formattedDescription: !(value$2 == null) ? value$2 : undefined,
            formattedOrigin: value$1.formattedOrigin,
            category: !(value$3 == null) ? ({
                  __typename: value$3.__typename,
                  id: value$3.id,
                  formattedName: value$3.formattedName
                }) : undefined,
            tax: {
              __typename: value$4.__typename,
              value: value$4.value
            }
          }
        };
}

function serialize$1(value) {
  var value$1 = value.updateProduct;
  var value$2 = value$1.tax;
  var value$3 = value$2.value;
  var value$4 = value$2.__typename;
  var tax = {
    __typename: value$4,
    value: value$3
  };
  var value$5 = value$1.category;
  var category;
  if (value$5 !== undefined) {
    var value$6 = value$5.formattedName;
    var value$7 = value$5.id;
    var value$8 = value$5.__typename;
    category = {
      __typename: value$8,
      id: value$7,
      formattedName: value$6
    };
  } else {
    category = null;
  }
  var value$9 = value$1.formattedOrigin;
  var value$10 = value$1.formattedDescription;
  var formattedDescription = value$10 !== undefined ? value$10 : null;
  var value$11 = value$1.name;
  var value$12 = value$1.updatedAt;
  var value$13 = Scalar.Datetime.serialize(value$12);
  var value$14 = value$1.id;
  var value$15 = value$1.__typename;
  var updateProduct = {
    __typename: value$15,
    id: value$14,
    updatedAt: value$13,
    name: value$11,
    formattedDescription: formattedDescription,
    formattedOrigin: value$9,
    category: category,
    tax: tax
  };
  return {
          updateProduct: updateProduct
        };
}

function serializeInputObjectInputUpdateProductMetaData(inp) {
  var a = inp.shopifyProductId;
  return {
          shopifyProductId: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInputUpdateProduct(inp) {
  var a = inp.name;
  var a$1 = inp.designation;
  var a$2 = inp.color;
  var a$3 = inp.kind;
  var a$4 = inp.family;
  var a$5 = inp.producer;
  var a$6 = inp.region;
  var a$7 = inp.beerType;
  var a$8 = inp.wineType;
  var a$9 = inp.whiteWineType;
  var a$10 = inp.internalNote;
  var a$11 = inp.taxId;
  var a$12 = inp.categoryId;
  var a$13 = inp.metadata;
  return {
          name: a !== undefined ? a : undefined,
          designation: a$1 !== undefined ? a$1 : undefined,
          color: a$2 !== undefined ? (
              a$2 === "ORANGE" ? "ORANGE" : (
                  a$2 === "RED" ? "RED" : (
                      a$2 === "AMBER" ? "AMBER" : (
                          a$2 === "DARK" ? "DARK" : (
                              a$2 === "BLACK" ? "BLACK" : (
                                  a$2 === "BLOND" ? "BLOND" : (
                                      a$2 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          kind: a$3 !== undefined ? (
              a$3 === "SPIRITUOUS" ? "SPIRITUOUS" : (
                  a$3 === "BEER" ? "BEER" : (
                      a$3 === "WINE" ? "WINE" : "SIMPLE"
                    )
                )
            ) : undefined,
          family: a$4 !== undefined ? a$4 : undefined,
          producer: a$5 !== undefined ? a$5 : undefined,
          country: inp.country,
          region: a$6 !== undefined ? a$6 : undefined,
          beerType: a$7 !== undefined ? a$7 : undefined,
          wineType: a$8 !== undefined ? (
              a$8 === "EFFERVESCENT" ? "EFFERVESCENT" : "STILL"
            ) : undefined,
          whiteWineType: a$9 !== undefined ? (
              a$9 === "SWEET" ? "SWEET" : (
                  a$9 === "SEMIDRY" ? "SEMIDRY" : (
                      a$9 === "SOFT" ? "SOFT" : "DRY"
                    )
                )
            ) : undefined,
          internalNote: a$10 !== undefined ? Caml_option.valFromOption(a$10) : undefined,
          taxId: a$11 !== undefined ? a$11 : undefined,
          categoryId: a$12 !== undefined ? a$12 : undefined,
          metadata: a$13 !== undefined ? serializeInputObjectInputUpdateProductMetaData(a$13) : undefined
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id,
          input: serializeInputObjectInputUpdateProduct(inp.input)
        };
}

function makeVariables$1(id, input, param) {
  return {
          id: id,
          input: input
        };
}

function makeInputObjectInputUpdateProduct(name, designation, color, kind, family, producer, country, region, beerType, wineType, whiteWineType, internalNote, taxId, categoryId, metadata, param) {
  return {
          name: name,
          designation: designation,
          color: color,
          kind: kind,
          family: family,
          producer: producer,
          country: country,
          region: region,
          beerType: beerType,
          wineType: wineType,
          whiteWineType: whiteWineType,
          internalNote: internalNote,
          taxId: taxId,
          categoryId: categoryId,
          metadata: metadata
        };
}

function makeInputObjectInputUpdateProductMetaData(shopifyProductId, param) {
  return {
          shopifyProductId: shopifyProductId
        };
}

var UpdateProductMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputUpdateProduct: serializeInputObjectInputUpdateProduct,
  serializeInputObjectInputUpdateProductMetaData: serializeInputObjectInputUpdateProductMetaData,
  makeVariables: makeVariables$1,
  makeInputObjectInputUpdateProduct: makeInputObjectInputUpdateProduct,
  makeInputObjectInputUpdateProductMetaData: makeInputObjectInputUpdateProductMetaData
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var UpdateProductMutation_useWithVariables = include$1.useWithVariables;

var UpdateProductMutation = {
  UpdateProductMutation_inner: UpdateProductMutation_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputUpdateProduct: serializeInputObjectInputUpdateProduct,
  serializeInputObjectInputUpdateProductMetaData: serializeInputObjectInputUpdateProductMetaData,
  makeVariables: makeVariables$1,
  makeInputObjectInputUpdateProduct: makeInputObjectInputUpdateProduct,
  makeInputObjectInputUpdateProductMetaData: makeInputObjectInputUpdateProductMetaData,
  use: use$1,
  useWithVariables: UpdateProductMutation_useWithVariables
};

function optionFromString(str) {
  if (str === "") {
    return ;
  } else {
    return str;
  }
}

function makeCreateProductInput(state, shop) {
  var name = state.name;
  var tmp;
  if (name === "") {
    throw {
          RE_EXN_ID: EmptyProductName,
          Error: new Error()
        };
  }
  tmp = name;
  var id = Belt_Option.map(state.tax, (function (tax) {
          return tax.id;
        }));
  var tmp$1;
  if (id !== undefined) {
    tmp$1 = id;
  } else {
    throw {
          RE_EXN_ID: TaxIdNotFound,
          Error: new Error()
        };
  }
  var tmp$2;
  if (shop !== undefined) {
    tmp$2 = shop.id;
  } else {
    throw {
          RE_EXN_ID: ShopIdNotFound,
          Error: new Error()
        };
  }
  return makeInputObjectInputCreateProduct(tmp, optionFromString(state.designation), state.color, state.kind, optionFromString(state.family), Belt_Option.getWithDefault(optionFromString(state.producer), "—"), state.country, optionFromString(state.region), optionFromString(state.beerType), state.wineType, state.whiteWineType, undefined, tmp$1, Belt_Option.map(state.categoryId, (function (prim) {
                    return prim;
                  })), tmp$2, undefined);
}

function useCreateProduct(param) {
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  var activeShop = Auth.useActiveShop(undefined);
  return React.useCallback((function (param) {
                return function (state) {
                  var input = makeCreateProductInput(state, activeShop);
                  return Future.mapOk(ApolloHelpers.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                      input: input
                                    })), undefined, (function (param) {
                                return param.createProduct.id;
                              }));
                };
              }), [activeShop]);
}

function makeUpdateProductInput(state) {
  var name = state.name;
  var tmp;
  if (name === "") {
    throw {
          RE_EXN_ID: EmptyProductName,
          Error: new Error()
        };
  }
  tmp = name;
  var id = Belt_Option.map(state.tax, (function (tax) {
          return tax.id;
        }));
  var tmp$1;
  if (id !== undefined) {
    tmp$1 = id;
  } else {
    throw {
          RE_EXN_ID: TaxIdNotFound,
          Error: new Error()
        };
  }
  return makeInputObjectInputUpdateProduct(tmp, optionFromString(state.designation), state.color, state.kind, optionFromString(state.family), optionFromString(state.producer), state.country, optionFromString(state.region), optionFromString(state.beerType), state.wineType, state.whiteWineType, undefined, tmp$1, undefined, undefined, undefined);
}

function useUpdateProduct(param) {
  var match = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.useCallback((function (productId) {
                return function (state) {
                  var id;
                  if (productId !== undefined) {
                    if (Uuid.fromString(productId) !== undefined) {
                      id = productId;
                    } else {
                      throw {
                            RE_EXN_ID: FormIdNotFound,
                            Error: new Error()
                          };
                    }
                  } else {
                    throw {
                          RE_EXN_ID: FormIdNotFound,
                          Error: new Error()
                        };
                  }
                  var input = makeUpdateProductInput(state);
                  return Future.mapOk(ApolloHelpers.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                      id: id,
                                      input: input
                                    })), undefined, (function (param) {
                                return $$Intl.t("The product information have been correctly updated.");
                              }));
                };
              }), []);
}

export {
  EmptyProductName ,
  ShopIdNotFound ,
  TaxIdNotFound ,
  FormIdNotFound ,
  CreateProductMutation ,
  UpdateProductMutation ,
  optionFromString ,
  makeCreateProductInput ,
  useCreateProduct ,
  makeUpdateProductInput ,
  useUpdateProduct ,
}
/* query Not a pure module */
