// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Field from "../layout-and-structure/Field.bs.js";
import * as React from "react";
import * as Inline from "../layout-and-structure/Inline.bs.js";
import * as Checkbox from "./Checkbox.bs.js";
import * as TextStyle from "../typography/TextStyle.bs.js";
import * as Touchable from "../../primitives/Touchable.bs.js";

function InputCheckboxField(Props) {
  var label = Props.label;
  var text = Props.text;
  var required = Props.required;
  var tooltip = Props.tooltip;
  var errorMessage = Props.errorMessage;
  var value = Props.value;
  var disabledOpt = Props.disabled;
  var onChange = Props.onChange;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return React.createElement(Field.make, Field.makeProps(React.createElement(Inline.make, {
                      children: null,
                      space: "small"
                    }, React.createElement(Checkbox.make, {
                          size: 18,
                          disabled: disabled,
                          value: value,
                          onChange: onChange
                        }), React.createElement(Touchable.make, Touchable.makeProps(React.createElement(TextStyle.make, {
                                  children: text
                                }), undefined, disabled, undefined, undefined, undefined, (function (param) {
                                Curry._1(onChange, !value);
                              }), undefined, undefined, undefined))), label, undefined, tooltip, errorMessage, undefined, required, undefined, undefined, undefined));
}

var make = React.memo(InputCheckboxField);

export {
  make ,
}
/* make Not a pure module */
