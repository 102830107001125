// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../../core/Env.bs.js";
import * as Json from "../../primitives/Json.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Auth__Jwt from "./Auth__Jwt.bs.js";
import * as Auth__Http from "./Auth__Http.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ApolloClient from "rescript-apollo-client/src/ApolloClient.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as ApolloClient__ZenObservable from "rescript-apollo-client/src/zen-observable/ApolloClient__ZenObservable.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";
import * as ApolloClient__Link_Http_HttpLink from "rescript-apollo-client/src/@apollo/client/link/http/ApolloClient__Link_Http_HttpLink.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as ApolloClient__Cache_InMemory_InMemoryCache from "rescript-apollo-client/src/@apollo/client/cache/inmemory/ApolloClient__Cache_InMemory_InMemoryCache.bs.js";

var Raw = {};

var query = Client.gql(["query ShopsQuery  {\nshops(first: 50)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\ntotalCount  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \ntype  \nname  \ncorporateName  \naddress  \npostalCode  \ncity  \ncountry  \nlegalRepresentative  \nemail  \nphoneNumber  \nlogoUri  \nbankName  \ncityOfRegistryOffice  \nwebsite  \nfiscalYearEndClosingMonth  \nlegalForm  \namountOfShareCapital  \ntvaNumber  \nsiretNumber  \nrcsNumber  \napeNafCode  \nbankCode  \nbankAccountHolder  \nbankAccountNumber  \nbicCode  \nibanNumber  \n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.shops;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          shops: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            totalCount: value$1.totalCount,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.type;
                    var tmp;
                    if (value$2 == null) {
                      tmp = undefined;
                    } else {
                      var tmp$1;
                      switch (value$2) {
                        case "AFFILIATED" :
                            tmp$1 = "AFFILIATED";
                            break;
                        case "FRANCHISED" :
                            tmp$1 = "FRANCHISED";
                            break;
                        case "INDEPENDENT" :
                            tmp$1 = "INDEPENDENT";
                            break;
                        case "INTEGRATED" :
                            tmp$1 = "INTEGRATED";
                            break;
                        case "WAREHOUSE" :
                            tmp$1 = "WAREHOUSE";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp = tmp$1;
                    }
                    var value$3 = value$1.email;
                    var value$4 = value$1.phoneNumber;
                    var value$5 = value$1.logoUri;
                    var value$6 = value$1.bankName;
                    var value$7 = value$1.cityOfRegistryOffice;
                    var value$8 = value$1.website;
                    var value$9 = value$1.fiscalYearEndClosingMonth;
                    var value$10 = value$1.legalForm;
                    var value$11 = value$1.amountOfShareCapital;
                    var value$12 = value$1.tvaNumber;
                    var value$13 = value$1.siretNumber;
                    var value$14 = value$1.rcsNumber;
                    var value$15 = value$1.apeNafCode;
                    var value$16 = value$1.bankCode;
                    var value$17 = value$1.bankAccountHolder;
                    var value$18 = value$1.bankAccountNumber;
                    var value$19 = value$1.bicCode;
                    var value$20 = value$1.ibanNumber;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              type: tmp,
                              name: value$1.name,
                              corporateName: value$1.corporateName,
                              address: value$1.address,
                              postalCode: value$1.postalCode,
                              city: value$1.city,
                              country: value$1.country,
                              legalRepresentative: value$1.legalRepresentative,
                              email: !(value$3 == null) ? value$3 : undefined,
                              phoneNumber: !(value$4 == null) ? value$4 : undefined,
                              logoUri: !(value$5 == null) ? value$5 : undefined,
                              bankName: !(value$6 == null) ? value$6 : undefined,
                              cityOfRegistryOffice: !(value$7 == null) ? value$7 : undefined,
                              website: !(value$8 == null) ? value$8 : undefined,
                              fiscalYearEndClosingMonth: !(value$9 == null) ? value$9 : undefined,
                              legalForm: !(value$10 == null) ? value$10 : undefined,
                              amountOfShareCapital: !(value$11 == null) ? value$11 : undefined,
                              tvaNumber: !(value$12 == null) ? value$12 : undefined,
                              siretNumber: !(value$13 == null) ? value$13 : undefined,
                              rcsNumber: !(value$14 == null) ? value$14 : undefined,
                              apeNafCode: !(value$15 == null) ? value$15 : undefined,
                              bankCode: !(value$16 == null) ? value$16 : undefined,
                              bankAccountHolder: !(value$17 == null) ? value$17 : undefined,
                              bankAccountNumber: !(value$18 == null) ? value$18 : undefined,
                              bicCode: !(value$19 == null) ? value$19 : undefined,
                              ibanNumber: !(value$20 == null) ? value$20 : undefined
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.shops;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.ibanNumber;
          var ibanNumber = value$2 !== undefined ? value$2 : null;
          var value$3 = value$1.bicCode;
          var bicCode = value$3 !== undefined ? value$3 : null;
          var value$4 = value$1.bankAccountNumber;
          var bankAccountNumber = value$4 !== undefined ? value$4 : null;
          var value$5 = value$1.bankAccountHolder;
          var bankAccountHolder = value$5 !== undefined ? value$5 : null;
          var value$6 = value$1.bankCode;
          var bankCode = value$6 !== undefined ? value$6 : null;
          var value$7 = value$1.apeNafCode;
          var apeNafCode = value$7 !== undefined ? value$7 : null;
          var value$8 = value$1.rcsNumber;
          var rcsNumber = value$8 !== undefined ? value$8 : null;
          var value$9 = value$1.siretNumber;
          var siretNumber = value$9 !== undefined ? value$9 : null;
          var value$10 = value$1.tvaNumber;
          var tvaNumber = value$10 !== undefined ? value$10 : null;
          var value$11 = value$1.amountOfShareCapital;
          var amountOfShareCapital = value$11 !== undefined ? value$11 : null;
          var value$12 = value$1.legalForm;
          var legalForm = value$12 !== undefined ? value$12 : null;
          var value$13 = value$1.fiscalYearEndClosingMonth;
          var fiscalYearEndClosingMonth = value$13 !== undefined ? value$13 : null;
          var value$14 = value$1.website;
          var website = value$14 !== undefined ? value$14 : null;
          var value$15 = value$1.cityOfRegistryOffice;
          var cityOfRegistryOffice = value$15 !== undefined ? value$15 : null;
          var value$16 = value$1.bankName;
          var bankName = value$16 !== undefined ? value$16 : null;
          var value$17 = value$1.logoUri;
          var logoUri = value$17 !== undefined ? value$17 : null;
          var value$18 = value$1.phoneNumber;
          var phoneNumber = value$18 !== undefined ? value$18 : null;
          var value$19 = value$1.email;
          var email = value$19 !== undefined ? value$19 : null;
          var value$20 = value$1.legalRepresentative;
          var value$21 = value$1.country;
          var value$22 = value$1.city;
          var value$23 = value$1.postalCode;
          var value$24 = value$1.address;
          var value$25 = value$1.corporateName;
          var value$26 = value$1.name;
          var value$27 = value$1.type;
          var type_ = value$27 !== undefined ? (
              value$27 === "WAREHOUSE" ? "WAREHOUSE" : (
                  value$27 === "INTEGRATED" ? "INTEGRATED" : (
                      value$27 === "INDEPENDENT" ? "INDEPENDENT" : (
                          value$27 === "AFFILIATED" ? "AFFILIATED" : "FRANCHISED"
                        )
                    )
                )
            ) : null;
          var value$28 = value$1.id;
          var value$29 = value$1.__typename;
          var node = {
            __typename: value$29,
            id: value$28,
            type: type_,
            name: value$26,
            corporateName: value$25,
            address: value$24,
            postalCode: value$23,
            city: value$22,
            country: value$21,
            legalRepresentative: value$20,
            email: email,
            phoneNumber: phoneNumber,
            logoUri: logoUri,
            bankName: bankName,
            cityOfRegistryOffice: cityOfRegistryOffice,
            website: website,
            fiscalYearEndClosingMonth: fiscalYearEndClosingMonth,
            legalForm: legalForm,
            amountOfShareCapital: amountOfShareCapital,
            tvaNumber: tvaNumber,
            siretNumber: siretNumber,
            rcsNumber: rcsNumber,
            apeNafCode: apeNafCode,
            bankCode: bankCode,
            bankAccountHolder: bankAccountHolder,
            bankAccountNumber: bankAccountNumber,
            bicCode: bicCode,
            ibanNumber: ibanNumber
          };
          var value$30 = value.__typename;
          return {
                  __typename: value$30,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.totalCount;
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var shops = {
    __typename: value$8,
    pageInfo: pageInfo,
    totalCount: value$3,
    edges: edges
  };
  return {
          shops: shops
        };
}

function serializeVariables(param) {
  
}

function makeVariables(param) {
  
}

function makeDefaultVariables(param) {
  
}

var ShopsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var ShopsQuery_refetchQueryDescription = include.refetchQueryDescription;

var ShopsQuery_use = include.use;

var ShopsQuery_useLazy = include.useLazy;

var ShopsQuery_useLazyWithVariables = include.useLazyWithVariables;

var ShopsQuery = {
  ShopsQuery_inner: ShopsQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: ShopsQuery_refetchQueryDescription,
  use: ShopsQuery_use,
  useLazy: ShopsQuery_useLazy,
  useLazyWithVariables: ShopsQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query DevicesQuery  {\ndevices(first: 50, filterBy: {name: {_equals: \"Web\"}, slug: {_equals: \"W\"}})  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \nshop  {\n__typename  \nid  \n}\n\n}\n\n}\n\ntotalCount  \n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.devices;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          devices: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.shop;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              shop: {
                                __typename: value$2.__typename,
                                id: value$2.id
                              }
                            }
                          };
                  }), value$5),
            totalCount: value$1.totalCount
          }
        };
}

function serialize$1(value) {
  var value$1 = value.devices;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.shop;
          var value$3 = value$2.id;
          var value$4 = value$2.__typename;
          var shop = {
            __typename: value$4,
            id: value$3
          };
          var value$5 = value$1.id;
          var value$6 = value$1.__typename;
          var node = {
            __typename: value$6,
            id: value$5,
            shop: shop
          };
          var value$7 = value.__typename;
          return {
                  __typename: value$7,
                  node: node
                };
        }), value$3);
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var devices = {
    __typename: value$8,
    pageInfo: pageInfo,
    edges: edges,
    totalCount: value$2
  };
  return {
          devices: devices
        };
}

function serializeVariables$1(param) {
  
}

function makeVariables$1(param) {
  
}

function makeDefaultVariables$1(param) {
  
}

var DevicesQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  makeDefaultVariables: makeDefaultVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var DevicesQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var DevicesQuery_use = include$1.use;

var DevicesQuery_useLazy = include$1.useLazy;

var DevicesQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var DevicesQuery = {
  DevicesQuery_inner: DevicesQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  makeDefaultVariables: makeDefaultVariables$1,
  refetchQueryDescription: DevicesQuery_refetchQueryDescription,
  use: DevicesQuery_use,
  useLazy: DevicesQuery_useLazy,
  useLazyWithVariables: DevicesQuery_useLazyWithVariables
};

var httpLink = ApolloClient__Link_Http_HttpLink.make((function (param) {
        return Env.gatewayUrl(undefined) + "/graphql";
      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

function authMiddleware(jwt) {
  return Curry._1(ReasonMLCommunity__ApolloClient.Link.make, (function (operation, forward) {
                operation.setContext(Auth__Http.encodeHttpContext(jwt));
                return ApolloClient__ZenObservable.Observable.fromJs(Curry._1(forward, operation));
              }));
}

function fetchShopsWithWebDevice(jwt) {
  var client = ApolloClient.make(undefined, undefined, undefined, Caml_option.some(ReasonMLCommunity__ApolloClient.Link.from([
                authMiddleware(jwt),
                httpLink
              ])), ApolloClient__Cache_InMemory_InMemoryCache.make(undefined, undefined, undefined, undefined, undefined, undefined), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var shopsRequest = Future.map(FuturePromise.fromPromise(Curry._6(client.rescript_query, {
                query: query,
                Raw: Raw,
                parse: parse,
                serialize: serialize,
                serializeVariables: serializeVariables
              }, undefined, undefined, /* NetworkOnly */2, undefined, undefined)), undefined, (function (result) {
          if (result.TAG !== /* Ok */0) {
            return {
                    TAG: /* Error */1,
                    _0: undefined
                  };
          }
          var match = result._0;
          if (match.TAG !== /* Ok */0) {
            return {
                    TAG: /* Error */1,
                    _0: undefined
                  };
          }
          var match$1 = match._0;
          if (match$1.error !== undefined) {
            return {
                    TAG: /* Error */1,
                    _0: undefined
                  };
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: Belt_Array.map(match$1.data.shops.edges, (function (edge) {
                            return edge.node;
                          }))
                  };
          }
        }));
  var devicesRequets = Future.map(FuturePromise.fromPromise(Curry._6(client.rescript_query, {
                query: query$1,
                Raw: Raw$1,
                parse: parse$1,
                serialize: serialize$1,
                serializeVariables: serializeVariables$1
              }, undefined, undefined, /* NetworkOnly */2, undefined, undefined)), undefined, (function (result) {
          if (result.TAG !== /* Ok */0) {
            return {
                    TAG: /* Error */1,
                    _0: undefined
                  };
          }
          var match = result._0;
          if (match.TAG !== /* Ok */0) {
            return {
                    TAG: /* Error */1,
                    _0: undefined
                  };
          }
          var match$1 = match._0;
          if (match$1.error !== undefined) {
            return {
                    TAG: /* Error */1,
                    _0: undefined
                  };
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: Belt_Array.map(match$1.data.devices.edges, (function (edge) {
                            return edge.node;
                          }))
                  };
          }
        }));
  return Future.map(Future.all2([
                  shopsRequest,
                  devicesRequets
                ]), undefined, (function (param) {
                var devices = param[1];
                var shops = param[0];
                if (shops.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
                if (devices.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
                var devices$1 = devices._0;
                var shopsWithWebDevice = Belt_Array.keepMap(shops._0, (function (shop) {
                        var maybeWebDevice = Belt_Array.getBy(devices$1, (function (device) {
                                return device.shop.id === shop.id;
                              }));
                        if (maybeWebDevice !== undefined) {
                          return {
                                  id: shop.id,
                                  kind: Belt_Option.getWithDefault(shop.type, "INDEPENDENT"),
                                  name: shop.name,
                                  corporateName: shop.corporateName,
                                  address: shop.address,
                                  postalCode: shop.postalCode,
                                  city: shop.city,
                                  country: shop.country,
                                  phoneNumber: Belt_Option.getWithDefault(shop.phoneNumber, "?"),
                                  email: Belt_Option.getWithDefault(shop.email, "?"),
                                  legalRepresentative: shop.legalRepresentative,
                                  logoUri: shop.logoUri,
                                  activeWebDeviceId: maybeWebDevice.id,
                                  bankName: shop.bankName,
                                  cityOfRegistryOffice: shop.cityOfRegistryOffice,
                                  website: shop.website,
                                  fiscalYearEndClosingMonth: shop.fiscalYearEndClosingMonth,
                                  legalForm: shop.legalForm,
                                  amountOfShareCapital: shop.amountOfShareCapital,
                                  tvaNumber: shop.tvaNumber,
                                  siretNumber: shop.siretNumber,
                                  rcsNumber: shop.rcsNumber,
                                  apeNafCode: shop.apeNafCode,
                                  bankCode: shop.bankCode,
                                  bankAccountHolder: shop.bankAccountHolder,
                                  bankAccountNumber: shop.bankAccountNumber,
                                  bicCode: shop.bicCode,
                                  ibanNumber: shop.ibanNumber
                                };
                        }
                        
                      }));
                if (shopsWithWebDevice.length !== 0) {
                  return {
                          TAG: /* Ok */0,
                          _0: shopsWithWebDevice
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
              }));
}

function decodeUserResultWithJwt(userResult, jwt) {
  var maybeUserDict = Json.decodeDict(userResult);
  var maybeUserScopeDict = Json.flatDecodeDictFieldDict(maybeUserDict, "scope");
  if (maybeUserDict === undefined) {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
  if (maybeUserScopeDict === undefined) {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
  var userDict = Caml_option.valFromOption(maybeUserDict);
  var user;
  try {
    user = {
      id: Json.decodeDictFieldExn(userDict, "id", Json.decodeString),
      name: Json.decodeDictFieldExn(userDict, "name", Json.decodeString),
      organizationName: Json.decodeDictFieldExn(userDict, "organizationName", Json.decodeString),
      profilePictureUri: Json.decodeDictOptionalField(userDict, "profilePictureUri", Json.decodeString),
      username: Json.decodeDictFieldExn(userDict, "username", Json.decodeString),
      canUseImpersonation: Belt_Option.getWithDefault(Json.decodeDictOptionalField(Caml_option.valFromOption(maybeUserScopeDict), "canUseImpersonation", Json.decodeBoolean), false),
      impersonating: Auth__Jwt.isImpersonated(jwt)
    };
  }
  catch (exn){
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
  return {
          TAG: /* Ok */0,
          _0: user
        };
}

function fetchUser(jwt) {
  return Future.mapResult(Future.mapError(FuturePromise.fromPromise(fetch(Env.gatewayUrl(undefined) + "/users/me", {
                            headers: Caml_option.some(new Headers({
                                      "Content-Type": "application/json",
                                      Authorization: "Bearer " + jwt + ""
                                    }))
                          }).then(function (prim) {
                          return prim.json();
                        })), undefined, (function (param) {
                    
                  })), undefined, (function (userResult) {
                return decodeUserResultWithJwt(userResult, jwt);
              }));
}

var ApolloLink;

var ApolloOperation;

var Observable;

var isImpersonatedJwt = Auth__Jwt.isImpersonated;

var encodeHttpContext = Auth__Http.encodeHttpContext;

export {
  ApolloLink ,
  ApolloOperation ,
  Observable ,
  isImpersonatedJwt ,
  encodeHttpContext ,
  ShopsQuery ,
  DevicesQuery ,
  httpLink ,
  authMiddleware ,
  fetchShopsWithWebDevice ,
  decodeUserResultWithJwt ,
  fetchUser ,
}
/* query Not a pure module */
