// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var PromotionEditDiscountedProductReducer_CouldNotRemoveProduct = /* @__PURE__ */Caml_exceptions.create("PromotionEditDiscountedProductReducer.PromotionEditDiscountedProductReducer_CouldNotRemoveProduct");

var PromotionEditDiscountedProductReducer_CouldNotUpdateDiscount = /* @__PURE__ */Caml_exceptions.create("PromotionEditDiscountedProductReducer.PromotionEditDiscountedProductReducer_CouldNotUpdateDiscount");

var PromotionEditDiscountedProductReducer_CouldReplicateDiscount = /* @__PURE__ */Caml_exceptions.create("PromotionEditDiscountedProductReducer.PromotionEditDiscountedProductReducer_CouldReplicateDiscount");

function make(state, action) {
  switch (action.TAG | 0) {
    case /* Reset */0 :
        return action._0;
    case /* ProductsAdded */1 :
        return Belt_Array.reduce(action._0, state, (function (acc, product) {
                      var oldProduct = Belt_Array.getBy(state, (function (current) {
                              return current.cku === product.cku;
                            }));
                      if (oldProduct === undefined) {
                        return Belt_Array.concat([product], acc);
                      }
                      var updatedProduct_id = product.id;
                      var updatedProduct_cku = product.cku;
                      var updatedProduct_name = product.name;
                      var updatedProduct_description = product.description;
                      var updatedProduct_stockKeepingUnit = product.stockKeepingUnit;
                      var updatedProduct_purchasePrice = product.purchasePrice;
                      var updatedProduct_bulkUnit = product.bulkUnit;
                      var updatedProduct_retailPrices = product.retailPrices;
                      var updatedProduct_discount = oldProduct.discount;
                      var updatedProduct = {
                        id: updatedProduct_id,
                        cku: updatedProduct_cku,
                        name: updatedProduct_name,
                        description: updatedProduct_description,
                        stockKeepingUnit: updatedProduct_stockKeepingUnit,
                        purchasePrice: updatedProduct_purchasePrice,
                        bulkUnit: updatedProduct_bulkUnit,
                        retailPrices: updatedProduct_retailPrices,
                        discount: updatedProduct_discount
                      };
                      return Belt_Array.concat([updatedProduct], Belt_Array.keep(acc, (function (current) {
                                        return current.cku !== product.cku;
                                      })));
                    }));
    case /* ProductRemoved */2 :
        var productCku = action._0;
        if (Belt_Array.some(state, (function (current) {
                  return current.cku === productCku;
                }))) {
          return Belt_Array.keep(state, (function (current) {
                        return current.cku !== productCku;
                      }));
        }
        throw {
              RE_EXN_ID: PromotionEditDiscountedProductReducer_CouldNotRemoveProduct,
              Error: new Error()
            };
    case /* ProductDiscountUpdated */3 :
        var discountKind = action._2;
        var discountAmount = action._1;
        var productCku$1 = action._0;
        if (Belt_Array.some(state, (function (current) {
                  return current.cku === productCku$1;
                }))) {
          return Belt_Array.map(state, (function (current) {
                        if (current.cku === productCku$1) {
                          return {
                                  id: current.id,
                                  cku: current.cku,
                                  name: current.name,
                                  description: current.description,
                                  stockKeepingUnit: current.stockKeepingUnit,
                                  purchasePrice: current.purchasePrice,
                                  bulkUnit: current.bulkUnit,
                                  retailPrices: current.retailPrices,
                                  discount: {
                                    id: current.discount.id,
                                    amount: discountAmount,
                                    kind: discountKind
                                  }
                                };
                        } else {
                          return current;
                        }
                      }));
        }
        throw {
              RE_EXN_ID: PromotionEditDiscountedProductReducer_CouldNotUpdateDiscount,
              Error: new Error()
            };
    case /* ProductDiscountReplicated */4 :
        var productCku$2 = action._0;
        var match = Belt_Array.getBy(state, (function (current) {
                return current.cku === productCku$2;
              }));
        if (match !== undefined) {
          var match$1 = match.discount;
          var kind = match$1.kind;
          var amount = match$1.amount;
          return Belt_Array.map(state, (function (current) {
                        return {
                                id: current.id,
                                cku: current.cku,
                                name: current.name,
                                description: current.description,
                                stockKeepingUnit: current.stockKeepingUnit,
                                purchasePrice: current.purchasePrice,
                                bulkUnit: current.bulkUnit,
                                retailPrices: current.retailPrices,
                                discount: {
                                  id: current.discount.id,
                                  amount: amount,
                                  kind: kind
                                }
                              };
                      }));
        }
        throw {
              RE_EXN_ID: PromotionEditDiscountedProductReducer_CouldReplicateDiscount,
              Error: new Error()
            };
    
  }
}

export {
  PromotionEditDiscountedProductReducer_CouldNotRemoveProduct ,
  PromotionEditDiscountedProductReducer_CouldNotUpdateDiscount ,
  PromotionEditDiscountedProductReducer_CouldReplicateDiscount ,
  make ,
}
/* No side effect */
