// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Badge from "../../resources/feedback-indicators/Badge.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Pastille from "../../resources/feedback-indicators/Pastille.bs.js";
import * as TextLink from "../../resources/navigation/TextLink.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as InlineText from "../../resources/layout-and-structure/InlineText.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LegacyRouter from "../../core/LegacyRouter.bs.js";

function LegacyProductReferenceTableCell(Props) {
  var id = Props.id;
  var cku = Props.cku;
  var variationOpt = Props.variation;
  var badge = Props.badge;
  var name = Props.name;
  var pastilleColor = Props.pastilleColor;
  var description = Props.description;
  var disabledOpt = Props.disabled;
  var errorMessage = Props.errorMessage;
  var openNewTabOpt = Props.openNewTab;
  var variation = variationOpt !== undefined ? variationOpt : "primary";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var openNewTab = openNewTabOpt !== undefined ? openNewTabOpt : false;
  var redirectPageRoute = cku !== undefined ? LegacyRouter.routeToPathname(/* Catalog */2) + "/" + cku : (
      id !== undefined ? LegacyRouter.routeToPathname(/* Catalog */2) + "/redirect/" + id : LegacyRouter.routeToPathname(/* Catalog */2)
    );
  return React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Stack.make, {
                                      children: null,
                                      space: "none"
                                    }, variation === "primary" ? React.createElement("span", undefined, pastilleColor !== undefined ? React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Pastille.make, {
                                                              color: pastilleColor
                                                            })), Caml_option.some(Style.merge([
                                                              Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(5)), undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(-2)), undefined, undefined, undefined),
                                                              Style.unsafeCss({
                                                                    display: "inline-flex"
                                                                  })
                                                            ])), undefined, undefined, undefined)) : null, React.createElement(TextLink.make, {
                                                text: name,
                                                to: {
                                                  TAG: /* Route */0,
                                                  _0: redirectPageRoute
                                                },
                                                openNewTab: openNewTab
                                              })) : React.createElement(InlineText.make, {
                                            children: null
                                          }, React.createElement(TextStyle.make, {
                                                children: name + "  ",
                                                variation: disabled ? "subdued" : "neutral",
                                                weight: disabled ? "regular" : "medium",
                                                size: "xsmall",
                                                lineHeight: "large"
                                              }), badge !== undefined ? React.createElement(Badge.make, {
                                                  children: badge.text,
                                                  size: "small",
                                                  variation: badge.variation
                                                }) : null), React.createElement(InlineText.make, {
                                          children: disabled ? null : React.createElement(TextStyle.make, {
                                                  children: description,
                                                  variation: "normal",
                                                  size: "tiny",
                                                  lineHeight: "small"
                                                }),
                                          linespace: "xxsmall",
                                          maxLines: 2
                                        }), errorMessage !== undefined ? React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                                        children: errorMessage,
                                                        variation: "negative",
                                                        size: "tiny"
                                                      })), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null)), undefined, "xsmall", undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined))), Caml_option.some(Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(100)), undefined, undefined)), undefined, undefined, undefined));
}

var make = React.memo(LegacyProductReferenceTableCell);

export {
  make ,
}
/* make Not a pure module */
