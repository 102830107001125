// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Menu from "../../resources/navigation/Menu.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as MenuItem from "../../resources/navigation/MenuItem.bs.js";
import * as LegacyRouter from "../../core/LegacyRouter.bs.js";
import * as PromotionSheet from "./PromotionSheet.bs.js";
import * as TriggerDownload from "../../primitives/TriggerDownload.bs.js";

function PromotionTableMoreActionsMenu(Props) {
  var cku = Props.cku;
  var id = Props.id;
  var name = Props.name;
  var startDate = Props.startDate;
  var notifier = Props.notifier;
  var match = React.useState(function () {
        return false;
      });
  var setCsvDownloadRequested = match[1];
  var csvDownloadRequested = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setExcelDownloadRequested = match$1[1];
  var excelDownloadRequested = match$1[0];
  var processBlobAndOpenFile = function (future, filename) {
    return Future.tap(Future.flatMap(future, undefined, (function (result) {
                      if (result.TAG === /* Ok */0) {
                        return TriggerDownload.fromBlob(result._0, filename);
                      } else {
                        return Future.value({
                                    TAG: /* Error */1,
                                    _0: undefined
                                  });
                      }
                    })), (function (result) {
                  Curry._1(setCsvDownloadRequested, (function (param) {
                          return false;
                        }));
                  if (result.TAG === /* Ok */0) {
                    return ;
                  } else {
                    return Curry._3(notifier.add, {
                                TAG: /* Error */1,
                                _0: "Something went wrong"
                              }, undefined, undefined);
                  }
                }));
  };
  React.useEffect((function () {
          if (!csvDownloadRequested) {
            return ;
          }
          var filename = PromotionSheet.makeCsvFilename(name)(startDate);
          var future = processBlobAndOpenFile(PromotionSheet.queryAndMakeCsvBlob(id), filename);
          return (function (param) {
                    Future.cancel(future);
                  });
        }), [csvDownloadRequested]);
  React.useEffect((function () {
          if (!excelDownloadRequested) {
            return ;
          }
          var filename = PromotionSheet.makeExcelFilename(name)(startDate);
          var future = processBlobAndOpenFile(PromotionSheet.queryAndMakeExcelBlob(id), filename);
          return (function (param) {
                    Future.cancel(future);
                  });
        }), [excelDownloadRequested]);
  return React.createElement(Menu.make, {
              children: null,
              variation: "more_round"
            }, React.createElement(MenuItem.make, {
                  content: {
                    TAG: /* Text */0,
                    _0: $$Intl.t("See")
                  },
                  action: {
                    TAG: /* OpenLink */1,
                    _0: {
                      TAG: /* Route */0,
                      _0: LegacyRouter.routeToPathname(/* Promotion */3) + "/" + cku
                    }
                  }
                }), React.createElement(MenuItem.make, {
                  content: {
                    TAG: /* Text */0,
                    _0: $$Intl.t("Download CSV")
                  },
                  action: {
                    TAG: /* Callback */0,
                    _0: (function (param) {
                        Curry._1(setCsvDownloadRequested, (function (param) {
                                return true;
                              }));
                      })
                  }
                }), React.createElement(MenuItem.make, {
                  content: {
                    TAG: /* Text */0,
                    _0: $$Intl.t("Download Excel")
                  },
                  action: {
                    TAG: /* Callback */0,
                    _0: (function (param) {
                        Curry._1(setExcelDownloadRequested, (function (param) {
                                return true;
                              }));
                      })
                  }
                }));
}

var make = React.memo(PromotionTableMoreActionsMenu);

var makeCsvFilename = PromotionSheet.makeCsvFilename;

var queryAndMakeCsvBlob = PromotionSheet.queryAndMakeCsvBlob;

var makeExcelFilename = PromotionSheet.makeExcelFilename;

var queryAndMakeExcelBlob = PromotionSheet.queryAndMakeExcelBlob;

export {
  makeCsvFilename ,
  queryAndMakeCsvBlob ,
  makeExcelFilename ,
  queryAndMakeExcelBlob ,
  make ,
}
/* make Not a pure module */
