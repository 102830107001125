// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as InputNumberField from "./InputNumberField.bs.js";

var make = InputNumberField.OptionalValue.make;

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9, prim10, prim11, prim12, prim13, prim14, prim15, prim16, prim17, prim18, prim19, prim20, prim21, prim22, prim23, prim24) {
  var tmp = {
    value: prim0,
    onChange: prim19
  };
  if (prim1 !== undefined) {
    tmp.minValue = prim1;
  }
  if (prim2 !== undefined) {
    tmp.maxValue = prim2;
  }
  if (prim3 !== undefined) {
    tmp.step = prim3;
  }
  if (prim4 !== undefined) {
    tmp.minPrecision = prim4;
  }
  if (prim5 !== undefined) {
    tmp.precision = prim5;
  }
  if (prim6 !== undefined) {
    tmp.label = prim6;
  }
  if (prim7 !== undefined) {
    tmp.tooltip = Caml_option.valFromOption(prim7);
  }
  if (prim8 !== undefined) {
    tmp.errorMessage = prim8;
  }
  if (prim9 !== undefined) {
    tmp.placeholder = prim9;
  }
  if (prim10 !== undefined) {
    tmp.appender = Caml_option.valFromOption(prim10);
  }
  if (prim11 !== undefined) {
    tmp.shrinkInput = prim11;
  }
  if (prim12 !== undefined) {
    tmp.autoFocused = prim12;
  }
  if (prim13 !== undefined) {
    tmp.focused = prim13;
  }
  if (prim14 !== undefined) {
    tmp.required = prim14;
  }
  if (prim15 !== undefined) {
    tmp.disabled = prim15;
  }
  if (prim16 !== undefined) {
    tmp.strongStyle = prim16;
  }
  if (prim17 !== undefined) {
    tmp.hideStepper = prim17;
  }
  if (prim18 !== undefined) {
    tmp.useGrouping = prim18;
  }
  if (prim20 !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(prim20);
  }
  if (prim21 !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(prim21);
  }
  if (prim22 !== undefined) {
    tmp.testLocalization = Caml_option.valFromOption(prim22);
  }
  if (prim23 !== undefined) {
    tmp.key = prim23;
  }
  return tmp;
}

export {
  makeProps ,
  make ,
}
/* InputNumberField Not a pure module */
