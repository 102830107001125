// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../images-and-icons/Icon.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as $$Text from "../../primitives/Text.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../layout-and-structure/Stack.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as FontFaces from "../theme/FontFaces.bs.js";
import * as FontSizes from "../theme/FontSizes.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as InputTextField from "./InputTextField.bs.js";

var styles = $$StyleSheet.create({
      listTypingValidation: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, "wrap", undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(-10)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(8)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      itemTypingValidation: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(50)), "row", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      iconItemTypingValidation: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(-2)), undefined, Caml_option.some(Style.dp(4)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      textItemTypingValidation: Style.merge([
            FontFaces.libreFranklinRegularStyle,
            Style.style(undefined, undefined, undefined, "#535165", undefined, FontSizes.xsmall, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      labelWrapper: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      textLabel: Style.merge([
            FontFaces.libreFranklinMediumStyle,
            Style.style(undefined, undefined, undefined, "#797885", undefined, FontSizes.xsmall, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ])
    });

function ruleToReg(x) {
  switch (x) {
    case /* MinStr */0 :
        return /.{10,}.*/;
    case /* MinLower */1 :
        return /(.*[a-z]){1,}.*/;
    case /* MinUpper */2 :
        return /(.*[A-Z]){1,}.*/;
    case /* MinDigit */3 :
        return /(.*[0-9]){1,}.*/;
    
  }
}

function ruleToErrorMsg(x) {
  switch (x) {
    case /* MinStr */0 :
        return "At least 10 characters";
    case /* MinLower */1 :
        return "One lowercase letter";
    case /* MinUpper */2 :
        return "One uppercase letter";
    case /* MinDigit */3 :
        return "One digit";
    
  }
}

function applyRule(value, rule) {
  return ruleToReg(rule).test(value);
}

function InputPasswordField(Props) {
  var label = Props.label;
  var value = Props.value;
  var focused = Props.focused;
  var required = Props.required;
  var errorMessage = Props.errorMessage;
  var onChange = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var placeholder = Props.placeholder;
  var showTypingValidationOpt = Props.showTypingValidation;
  var showTypingValidation = showTypingValidationOpt !== undefined ? showTypingValidationOpt : false;
  var match = React.useReducer((function (state, action) {
          var textField = action._0;
          return {
                  rules: Belt_Array.map(state.rules, (function (ruleState) {
                          var rule = ruleState[1];
                          var isValid = applyRule(textField, rule);
                          return [
                                  isValid,
                                  rule
                                ];
                        }))
                };
        }), {
        rules: [
          [
            false,
            /* MinStr */0
          ],
          [
            false,
            /* MinLower */1
          ],
          [
            false,
            /* MinUpper */2
          ],
          [
            false,
            /* MinDigit */3
          ]
        ]
      });
  var dispatch = match[1];
  var match$1 = React.useState(function () {
        return true;
      });
  var setShow = match$1[1];
  var show = match$1[0];
  React.useEffect((function () {
          Curry._1(dispatch, /* FieldValueChanged */{
                _0: value
              });
        }), [value]);
  var tmp = {
    label: label,
    fieldAction: {
      text: $$Intl.t(show ? "Show" : "Hide"),
      handler: {
        TAG: /* Callback */0,
        _0: (function (param) {
            Curry._1(setShow, (function (show) {
                    return !show;
                  }));
          })
      }
    },
    focused: focused,
    secureTextEntry: show,
    value: value,
    onFocus: onFocus,
    onBlur: onBlur
  };
  if (required !== undefined) {
    tmp.required = required;
  }
  if (placeholder !== undefined) {
    tmp.placeholder = placeholder;
  }
  if (errorMessage !== undefined) {
    tmp.errorMessage = errorMessage;
  }
  if (onChange !== undefined) {
    tmp.onChange = Caml_option.valFromOption(onChange);
  }
  return React.createElement(Stack.make, {
              children: null
            }, React.createElement(InputTextField.make, tmp), showTypingValidation ? React.createElement(View.make, View.makeProps(Caml_option.some(Belt_Array.mapWithIndex(match[0].rules, (function (i, rule) {
                                  return React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.itemTypingValidation), String(i), undefined, undefined), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Icon.make, {
                                                              name: "oval",
                                                              fill: rule[0] ? "#1dbb7a" : "#e7e7ee"
                                                            })), Caml_option.some(styles.iconItemTypingValidation), undefined, undefined, undefined)), React.createElement($$Text.make, $$Text.makeProps($$Intl.t(ruleToErrorMsg(rule[1])), Caml_option.some(styles.textItemTypingValidation), undefined, undefined, undefined)));
                                }))), Caml_option.some(styles.listTypingValidation), undefined, undefined, undefined)) : null);
}

var make = React.memo(InputPasswordField, (function (oldProps, newProps) {
        if (oldProps.label === newProps.label && oldProps.errorMessage === newProps.errorMessage && oldProps.value === newProps.value && oldProps.focused === newProps.focused && oldProps.placeholder === newProps.placeholder) {
          return oldProps.onBlur === newProps.onBlur;
        } else {
          return false;
        }
      }));

var minStr = /* MinStr */0;

var minLower = /* MinLower */1;

var minUpper = /* MinUpper */2;

var minDigit = /* MinDigit */3;

export {
  applyRule ,
  minStr ,
  minLower ,
  minUpper ,
  minDigit ,
  make ,
}
/* styles Not a pure module */
