// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var UnknownStringValue = /* @__PURE__ */Caml_exceptions.create("OrderStatus.UnknownStringValue");

function toString(status) {
  return status;
}

function toLabel(x) {
  if (x === "TO_PAY") {
    return $$Intl.t("Received - To pay");
  } else if (x === "DRAFT") {
    return $$Intl.t("Draft");
  } else if (x === "NOT_RECEIVED") {
    return $$Intl.t("");
  } else if (x === "REFUSED") {
    return $$Intl.t("Refused");
  } else if (x === "FINALIZED") {
    return $$Intl.t("Finalized");
  } else if (x === "ACCEPTED") {
    return $$Intl.t("Accepted - Not received");
  } else if (x === "PAID") {
    return $$Intl.t("Received - Paid");
  } else if (x === "RECEIVING") {
    return $$Intl.t("Receiving");
  } else if (x === "SENT") {
    return $$Intl.t("Sent");
  } else if (x === "RECEIVED") {
    return $$Intl.t("Received");
  } else {
    return $$Intl.t("Archived");
  }
}

function fromStringExn(value) {
  switch (value) {
    case "ACCEPTED" :
        return "ACCEPTED";
    case "ARCHIVED" :
        return "ARCHIVED";
    case "DRAFT" :
        return "DRAFT";
    case "FINALIZED" :
        return "FINALIZED";
    case "NOT_RECEIVED" :
        return "NOT_RECEIVED";
    case "PAID" :
        return "PAID";
    case "RECEIVED" :
        return "RECEIVED";
    case "RECEIVING" :
        return "RECEIVING";
    case "REFUSED" :
        return "REFUSED";
    case "SENT" :
        return "SENT";
    case "TO_PAY" :
        return "TO_PAY";
    default:
      throw {
            RE_EXN_ID: UnknownStringValue,
            Error: new Error()
          };
  }
}

function has(orderStatuses, predicate) {
  return Belt_Array.some(orderStatuses, (function (status) {
                return status === predicate;
              }));
}

function toAction(x) {
  if (has(x, "DRAFT")) {
    return "FINALIZE";
  } else if (has(x, "FINALIZED")) {
    return "ACCEPT";
  } else if (has(x, "ACCEPTED")) {
    return "START_RECEPTION";
  } else if (has(x, "RECEIVING")) {
    return "FINISH_RECEPTION";
  } else if (has(x, "RECEIVED")) {
    return "PAY";
  } else {
    return "CANCEL";
  }
}

export {
  UnknownStringValue ,
  toString ,
  toLabel ,
  fromStringExn ,
  has ,
  toAction ,
}
/* Intl Not a pure module */
