// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../core/Env.bs.js";
import * as $$Intl from "../primitives/Intl.bs.js";
import * as Json from "../primitives/Json.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Request from "../core/Request.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var values = [
  /* January */0,
  /* February */1,
  /* March */2,
  /* April */3,
  /* May */4,
  /* June */5,
  /* July */6,
  /* August */7,
  /* September */8,
  /* October */9,
  /* November */10,
  /* December */11
];

function isEqual(a, b) {
  switch (a) {
    case /* January */0 :
        return b === 0;
    case /* February */1 :
        return b === 1;
    case /* March */2 :
        return b === 2;
    case /* April */3 :
        return b === 3;
    case /* May */4 :
        return b === 4;
    case /* June */5 :
        return b === 5;
    case /* July */6 :
        return b === 6;
    case /* August */7 :
        return b === 7;
    case /* September */8 :
        return b === 8;
    case /* October */9 :
        return b === 9;
    case /* November */10 :
        return b === 10;
    case /* December */11 :
        return b >= 11;
    
  }
}

function toString(value) {
  var tmp;
  switch (value) {
    case /* January */0 :
        tmp = "January";
        break;
    case /* February */1 :
        tmp = "February";
        break;
    case /* March */2 :
        tmp = "March";
        break;
    case /* April */3 :
        tmp = "April";
        break;
    case /* May */4 :
        tmp = "May";
        break;
    case /* June */5 :
        tmp = "June";
        break;
    case /* July */6 :
        tmp = "July";
        break;
    case /* August */7 :
        tmp = "August";
        break;
    case /* September */8 :
        tmp = "September";
        break;
    case /* October */9 :
        tmp = "October";
        break;
    case /* November */10 :
        tmp = "November";
        break;
    case /* December */11 :
        tmp = "December";
        break;
    
  }
  return $$Intl.t(tmp);
}

function toDateMonthJsIndex(value) {
  return Belt_Array.getIndexBy(values, (function (item) {
                return value === item;
              }));
}

function toSelectItem(value) {
  return {
          key: toString(value),
          value: value,
          label: toString(value)
        };
}

function decodeFromJson(json) {
  return Belt_Option.flatMap(Json.decodeNumber(json), (function (monthJsIndex) {
                return Belt_Array.get(values, monthJsIndex | 0);
              }));
}

function encodeToJson(value) {
  return Json.encodeNumber(toDateMonthJsIndex(value));
}

function request(shopId) {
  var endpoint = function (shopId) {
    return Env.gatewayUrl(undefined) + "/accounting-export-configurations/" + shopId;
  };
  var decodeRequestError = function (param) {
    
  };
  var decodeResponse = function (response) {
    return Belt_Option.map(Json.decodeDict(response), (function (dict) {
                  return Belt_Option.flatMap(Js_dict.get(dict, "fiscalYearOpeningMonth"), decodeFromJson);
                }));
  };
  return Future.mapError(Future.mapOk($$Request.make(undefined, undefined, undefined, undefined, endpoint(shopId)), undefined, decodeResponse), undefined, decodeRequestError);
}

export {
  values ,
  isEqual ,
  toString ,
  toDateMonthJsIndex ,
  toSelectItem ,
  decodeFromJson ,
  encodeToJson ,
  request ,
}
/* Intl Not a pure module */
