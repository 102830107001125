// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Sheet from "../../primitives/Sheet.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as ApolloConfig from "../../core/ApolloConfig.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query StockTransferSheetQuery($stockTransferId: ID!, $productsFirst: Int, $productsAfter: String)  {\nstockTransfer(id: $stockTransferId)  {\n__typename  \nid  \ncode  \nsenderShop  {\n__typename  \nname  \naddress  \ncity  \npostalCode  \ncountry  \nphoneNumber  \n}\n\nrecipientShop  {\n__typename  \nname  \naddress  \ncity  \npostalCode  \ncountry  \nphoneNumber  \n}\n\ncreatedAt  \nproducts(first: $productsFirst, after: $productsAfter)  {\n__typename  \ntotalCount  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nrecipientVariant  {\n__typename  \nformattedName  \nformattedDescription  \nstockKeepingUnit  \n}\n\nquantity  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.stockTransfer;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.senderShop;
    var value$3 = value$2.phoneNumber;
    var value$4 = value$1.recipientShop;
    var value$5 = value$4.phoneNumber;
    var value$6 = value$1.products;
    var value$7 = value$6.pageInfo;
    var value$8 = value$7.endCursor;
    var value$9 = value$7.hasNextPage;
    var value$10 = value$6.edges;
    tmp = {
      __typename: value$1.__typename,
      id: value$1.id,
      code: value$1.code,
      senderShop: {
        __typename: value$2.__typename,
        name: value$2.name,
        address: value$2.address,
        city: value$2.city,
        postalCode: value$2.postalCode,
        country: value$2.country,
        phoneNumber: !(value$3 == null) ? value$3 : undefined
      },
      recipientShop: {
        __typename: value$4.__typename,
        name: value$4.name,
        address: value$4.address,
        city: value$4.city,
        postalCode: value$4.postalCode,
        country: value$4.country,
        phoneNumber: !(value$5 == null) ? value$5 : undefined
      },
      createdAt: Scalar.Datetime.parse(value$1.createdAt),
      products: {
        __typename: value$6.__typename,
        totalCount: value$6.totalCount,
        pageInfo: {
          __typename: value$7.__typename,
          endCursor: !(value$8 == null) ? value$8 : undefined,
          hasNextPage: !(value$9 == null) ? value$9 : undefined
        },
        edges: Js_array.map((function (value) {
                var value$1 = value.node;
                var value$2 = value$1.recipientVariant;
                var value$3 = value$2.stockKeepingUnit;
                return {
                        __typename: value.__typename,
                        node: {
                          __typename: value$1.__typename,
                          recipientVariant: {
                            __typename: value$2.__typename,
                            formattedName: value$2.formattedName,
                            formattedDescription: value$2.formattedDescription,
                            stockKeepingUnit: !(value$3 == null) ? value$3 : undefined
                          },
                          quantity: value$1.quantity
                        }
                      };
              }), value$10)
      }
    };
  }
  return {
          stockTransfer: tmp
        };
}

function serialize(value) {
  var value$1 = value.stockTransfer;
  var stockTransfer;
  if (value$1 !== undefined) {
    var value$2 = value$1.products;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var value$2 = value$1.quantity;
            var value$3 = value$1.recipientVariant;
            var value$4 = value$3.stockKeepingUnit;
            var stockKeepingUnit = value$4 !== undefined ? value$4 : null;
            var value$5 = value$3.formattedDescription;
            var value$6 = value$3.formattedName;
            var value$7 = value$3.__typename;
            var recipientVariant = {
              __typename: value$7,
              formattedName: value$6,
              formattedDescription: value$5,
              stockKeepingUnit: stockKeepingUnit
            };
            var value$8 = value$1.__typename;
            var node = {
              __typename: value$8,
              recipientVariant: recipientVariant,
              quantity: value$2
            };
            var value$9 = value.__typename;
            return {
                    __typename: value$9,
                    node: node
                  };
          }), value$3);
    var value$4 = value$2.pageInfo;
    var value$5 = value$4.hasNextPage;
    var hasNextPage = value$5 !== undefined ? value$5 : null;
    var value$6 = value$4.endCursor;
    var endCursor = value$6 !== undefined ? value$6 : null;
    var value$7 = value$4.__typename;
    var pageInfo = {
      __typename: value$7,
      endCursor: endCursor,
      hasNextPage: hasNextPage
    };
    var value$8 = value$2.totalCount;
    var value$9 = value$2.__typename;
    var products = {
      __typename: value$9,
      totalCount: value$8,
      pageInfo: pageInfo,
      edges: edges
    };
    var value$10 = value$1.createdAt;
    var value$11 = Scalar.Datetime.serialize(value$10);
    var value$12 = value$1.recipientShop;
    var value$13 = value$12.phoneNumber;
    var phoneNumber = value$13 !== undefined ? value$13 : null;
    var value$14 = value$12.country;
    var value$15 = value$12.postalCode;
    var value$16 = value$12.city;
    var value$17 = value$12.address;
    var value$18 = value$12.name;
    var value$19 = value$12.__typename;
    var recipientShop = {
      __typename: value$19,
      name: value$18,
      address: value$17,
      city: value$16,
      postalCode: value$15,
      country: value$14,
      phoneNumber: phoneNumber
    };
    var value$20 = value$1.senderShop;
    var value$21 = value$20.phoneNumber;
    var phoneNumber$1 = value$21 !== undefined ? value$21 : null;
    var value$22 = value$20.country;
    var value$23 = value$20.postalCode;
    var value$24 = value$20.city;
    var value$25 = value$20.address;
    var value$26 = value$20.name;
    var value$27 = value$20.__typename;
    var senderShop = {
      __typename: value$27,
      name: value$26,
      address: value$25,
      city: value$24,
      postalCode: value$23,
      country: value$22,
      phoneNumber: phoneNumber$1
    };
    var value$28 = value$1.code;
    var value$29 = value$1.id;
    var value$30 = value$1.__typename;
    stockTransfer = {
      __typename: value$30,
      id: value$29,
      code: value$28,
      senderShop: senderShop,
      recipientShop: recipientShop,
      createdAt: value$11,
      products: products
    };
  } else {
    stockTransfer = null;
  }
  return {
          stockTransfer: stockTransfer
        };
}

function serializeVariables(inp) {
  var a = inp.productsFirst;
  var a$1 = inp.productsAfter;
  return {
          stockTransferId: inp.stockTransferId,
          productsFirst: a !== undefined ? a : undefined,
          productsAfter: a$1 !== undefined ? a$1 : undefined
        };
}

function makeVariables(stockTransferId, productsFirst, productsAfter, param) {
  return {
          stockTransferId: stockTransferId,
          productsFirst: productsFirst,
          productsAfter: productsAfter
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_use = include.use;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: Query_use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function makeFilename(code, extension) {
  return $$Intl.t("transferorder") + "_" + code + "." + extension;
}

function makeCsvFilename(param) {
  return makeFilename(param, Sheet.csvFileExtension);
}

function makeExcelFilename(param) {
  return makeFilename(param, Sheet.excelFileExtension);
}

function makeRows(stockTransfer) {
  var titleRow = [
    $$Intl.t("Transfer of") + " " + $$Intl.dateTimeFormat("short", undefined, undefined, undefined, undefined, undefined, undefined, undefined, stockTransfer.createdAt) + " " + $$Intl.t("at") + " " + $$Intl.dateTimeFormat(undefined, "short", undefined, undefined, undefined, undefined, undefined, undefined, stockTransfer.createdAt),
    $$Intl.template($$Intl.t("Reference{{entilted}}{{code}}"), {
          entilted: " : ",
          code: stockTransfer.code
        }, undefined)
  ];
  var headers = [
    $$Intl.t("Sender") + " :",
    $$Intl.t("Recipient") + " :"
  ];
  var shopNameRows = [
    stockTransfer.senderShop.name,
    stockTransfer.recipientShop.name
  ];
  var shopAdressRows = [
    stockTransfer.senderShop.address + " " + stockTransfer.senderShop.postalCode + " " + stockTransfer.senderShop.city + ", " + stockTransfer.senderShop.country,
    stockTransfer.recipientShop.address + " " + stockTransfer.recipientShop.postalCode + " " + stockTransfer.recipientShop.city + ", " + stockTransfer.recipientShop.country
  ];
  var shopPhoneNumberRows = [
    Belt_Option.getWithDefault(stockTransfer.senderShop.phoneNumber, ""),
    Belt_Option.getWithDefault(stockTransfer.recipientShop.phoneNumber, "")
  ];
  var productHeaderRows = [
    "SKU",
    $$Intl.t("Product's name"),
    "Description",
    $$Intl.t("Transferred quantity")
  ];
  var productsRows = Belt_Array.map(stockTransfer.products.edges, (function (product) {
          return [
                  Belt_Option.getWithDefault(product.node.recipientVariant.stockKeepingUnit, ""),
                  product.node.recipientVariant.formattedName,
                  product.node.recipientVariant.formattedDescription,
                  product.node.quantity.toString()
                ];
        }));
  return Belt_Array.concatMany([
              [titleRow],
              [[]],
              [headers],
              [shopNameRows],
              [shopAdressRows],
              [shopPhoneNumberRows],
              [[]],
              [productHeaderRows],
              Belt_Array.map(productsRows, (function (row) {
                      return row;
                    }))
            ]);
}

function makeCsvPlainText(stockTransfer) {
  return Sheet.makeCsvPlainText(undefined, makeRows(stockTransfer));
}

function makeCsvBlob(stockTransfer) {
  return Belt_Result.map(Sheet.makeCsvPlainText(undefined, makeRows(stockTransfer)), Sheet.makeCsvBlobFromPlainText);
}

function query$1(stockTransferId, productsFirst, productsAfter) {
  return FuturePromise.fromPromise(Curry._6(ApolloConfig.makeClient(undefined).rescript_query, {
                  query: query,
                  Raw: Raw,
                  parse: parse,
                  serialize: serialize,
                  serializeVariables: serializeVariables
                }, undefined, undefined, undefined, undefined, {
                  stockTransferId: stockTransferId,
                  productsFirst: productsFirst,
                  productsAfter: productsAfter
                }));
}

function queryWithAllProducts(stockTransferId, previousResult, productEdgesEndCursor, productEdgesPerFetchOpt, param) {
  var productEdgesPerFetch = productEdgesPerFetchOpt !== undefined ? productEdgesPerFetchOpt : ApolloConfig.edgesPerFetchLimit;
  return Future.flatMap(query$1(stockTransferId, productEdgesPerFetch, productEdgesEndCursor), undefined, (function (result) {
                if (result.TAG !== /* Ok */0) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: /* QueryFailure */0
                            });
                }
                var match = result._0;
                if (match.TAG !== /* Ok */0) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: /* QueryFailure */0
                            });
                }
                var match$1 = match._0;
                var stockTransfer = match$1.data.stockTransfer;
                if (stockTransfer === undefined) {
                  if (match$1.error !== undefined) {
                    return Future.value({
                                TAG: /* Error */1,
                                _0: /* QueryFailure */0
                              });
                  } else {
                    return Future.value({
                                TAG: /* Ok */0,
                                _0: undefined
                              });
                  }
                }
                if (match$1.error !== undefined) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: /* QueryFailure */0
                            });
                }
                var stockTransfer$1;
                if (previousResult !== undefined) {
                  var init = stockTransfer.products;
                  stockTransfer$1 = {
                    __typename: stockTransfer.__typename,
                    id: stockTransfer.id,
                    code: stockTransfer.code,
                    senderShop: stockTransfer.senderShop,
                    recipientShop: stockTransfer.recipientShop,
                    createdAt: stockTransfer.createdAt,
                    products: {
                      __typename: init.__typename,
                      totalCount: init.totalCount,
                      pageInfo: init.pageInfo,
                      edges: Belt_Array.concat(previousResult.products.edges, stockTransfer.products.edges)
                    }
                  };
                } else {
                  stockTransfer$1 = stockTransfer;
                }
                var match$2 = stockTransfer$1.products.pageInfo;
                var match$3 = match$2.hasNextPage;
                if (match$3 !== undefined && match$3) {
                  return queryWithAllProducts(stockTransferId, stockTransfer$1, match$2.endCursor, productEdgesPerFetch, undefined);
                } else {
                  return Future.value({
                              TAG: /* Ok */0,
                              _0: stockTransfer$1
                            });
                }
              }));
}

function queryAndMakeCsvBlob(stockTransferId) {
  return Future.map(queryWithAllProducts(stockTransferId, undefined, undefined, undefined, undefined), undefined, (function (results) {
                if (results.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: results._0
                        };
                }
                var stockTransfer = results._0;
                if (stockTransfer === undefined) {
                  return {
                          TAG: /* Error */1,
                          _0: /* NoDataFailure */1
                        };
                }
                var csvBlob = Belt_Result.map(Sheet.makeCsvPlainText(undefined, makeRows(stockTransfer)), Sheet.makeCsvBlobFromPlainText);
                if (csvBlob.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: csvBlob._0
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: /* WriteBlobFailure */2
                        };
                }
              }));
}

function makeExcelBlob(stockTransfer) {
  return Future.mapError(Sheet.makeExcelBlob(makeRows(stockTransfer), $$Intl.t("Stock transfer")), undefined, (function (param) {
                return /* WriteBlobFailure */2;
              }));
}

function queryAndMakeExcelBlob(stockTransferId) {
  return Future.flatMap(queryWithAllProducts(stockTransferId, undefined, undefined, undefined, undefined), undefined, (function (results) {
                if (results.TAG !== /* Ok */0) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: results._0
                            });
                }
                var stockTransfer = results._0;
                if (stockTransfer !== undefined) {
                  return makeExcelBlob(stockTransfer);
                } else {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: /* NoDataFailure */1
                            });
                }
              }));
}

export {
  Query ,
  makeFilename ,
  makeCsvFilename ,
  makeExcelFilename ,
  makeRows ,
  makeCsvPlainText ,
  makeCsvBlob ,
  query$1 as query,
  queryWithAllProducts ,
  queryAndMakeCsvBlob ,
  makeExcelBlob ,
  queryAndMakeExcelBlob ,
}
/* query Not a pure module */
