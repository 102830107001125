// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Menu from "../../resources/navigation/Menu.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as MenuItem from "../../resources/navigation/MenuItem.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as PromotionEditForm from "./PromotionEditForm.bs.js";
import * as PromotionEditActions from "./PromotionEditActions.bs.js";
import * as PromotionEditFormMutations from "./PromotionEditFormMutations.bs.js";

function PromotionEditFormPageActions(Props) {
  var status = Props.status;
  var promotionCreated = Props.promotionCreated;
  var editing = Props.editing;
  var onRequestEditing = Props.onRequestEditing;
  var onRequestCsvDownload = Props.onRequestCsvDownload;
  var onRequestExcelDownload = Props.onRequestExcelDownload;
  var match = Navigation.useGoBack(undefined);
  var onGoBack = match[1];
  var canGoBack = match[0];
  var state = Curry._1(PromotionEditForm.useFormState, undefined);
  var createGlobalCampaign = PromotionEditFormMutations.useCreateGlobalCampaign(undefined);
  var updateGlobalCampaign = PromotionEditFormMutations.useUpdateGlobalCampaign(undefined);
  var startGlobalCampaign = PromotionEditFormMutations.useStartGlobalCampaign(undefined);
  React.useEffect((function () {
          if (status === "NOT_PROGRAMMED" || status === "DRAFT" || status === "PROGRAMMED") {
            var match = state.status;
            var match$1 = state.submission;
            var exit = 0;
            if (typeof match$1 === "number") {
              if (match$1 === /* Pending */0 && !editing) {
                exit = 1;
              }
              
            } else if (match$1.TAG === /* Failed */0) {
              if (!editing) {
                exit = 1;
              }
              
            } else if (editing) {
              Curry._1(onRequestEditing, false);
            } else {
              exit = 1;
            }
            if (exit === 1) {
              if (match !== 0) {
                Curry._1(onRequestEditing, true);
              }
              
            }
            
          }
          
        }), [
        state.status,
        state.submission
      ]);
  var match$1 = Auth.useScope(undefined);
  var tmp;
  tmp = match$1.TAG === /* Organisation */0 ? "none" : "small";
  var tmp$1;
  if (editing) {
    tmp$1 = React.createElement(Inline.make, {
          children: null,
          space: "small"
        }, React.createElement(PromotionEditForm.ResetButton.make, {
              variation: "neutral",
              size: "medium",
              onPress: (function (param) {
                  if (promotionCreated || !canGoBack) {
                    return Curry._1(onRequestEditing, false);
                  } else {
                    return Curry._1(onGoBack, undefined);
                  }
                }),
              text: $$Intl.t("Discard")
            }), React.createElement(PromotionEditForm.SubmitButton.make, {
              variation: "success",
              size: "medium",
              onSubmit: promotionCreated ? updateGlobalCampaign : createGlobalCampaign,
              text: $$Intl.t(promotionCreated ? "Save" : "Create draft")
            }));
  } else if (status === "DRAFT") {
    tmp$1 = promotionCreated ? React.createElement(PromotionEditForm.SubmitButton.make, {
            variation: "primary",
            size: "medium",
            onSubmit: startGlobalCampaign,
            text: $$Intl.t("Schedule")
          }) : null;
  } else if (promotionCreated) {
    var match$2 = Belt_Array.get(state.values.rootCampaigns, 0);
    if (match$2 !== undefined) {
      var status$1 = match$2.status;
      tmp$1 = status$1 !== undefined ? React.createElement(PromotionEditActions.make, {
              variation: "actionBar",
              id: match$2.id,
              status: status$1
            }) : null;
    } else {
      tmp$1 = null;
    }
  } else {
    tmp$1 = null;
  }
  return React.createElement(Inline.make, {
              children: null,
              space: tmp
            }, editing || !(status === "NOT_PROGRAMMED" || status === "PROGRAMMED") ? null : React.createElement(Menu.make, {
                    children: null
                  }, status === "NOT_PROGRAMMED" || status === "PROGRAMMED" ? React.createElement(MenuItem.make, {
                          content: {
                            TAG: /* Text */0,
                            _0: $$Intl.t("Edit campaign")
                          },
                          action: {
                            TAG: /* Callback */0,
                            _0: (function (param) {
                                Curry._1(onRequestEditing, true);
                              })
                          }
                        }) : null, React.createElement(MenuItem.make, {
                        content: {
                          TAG: /* Text */0,
                          _0: $$Intl.t("Download CSV")
                        },
                        action: {
                          TAG: /* Callback */0,
                          _0: (function (param) {
                              Curry._1(onRequestCsvDownload, state.values);
                            })
                        }
                      }), React.createElement(MenuItem.make, {
                        content: {
                          TAG: /* Text */0,
                          _0: $$Intl.t("Download Excel")
                        },
                        action: {
                          TAG: /* Callback */0,
                          _0: (function (param) {
                              Curry._1(onRequestExcelDownload, state.values);
                            })
                        }
                      })), tmp$1);
}

var make = React.memo(PromotionEditFormPageActions);

var Mutations;

export {
  Mutations ,
  make ,
}
/* make Not a pure module */
