// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../images-and-icons/Icon.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var styles = $$StyleSheet.create({
      view: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 50, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      xxsmallView: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(26)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(20)), undefined, undefined),
      xsmallView: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(26)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(26)), undefined, undefined),
      smallView: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(35)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(35)), undefined, undefined),
      normalView: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(40)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(40)), undefined, undefined)
    });

function viewStyleFromParams(size) {
  var tmp;
  var exit = 0;
  if (size !== undefined) {
    if (size === "xxsmall") {
      tmp = styles.xxsmallView;
    } else if (size === "xsmall") {
      tmp = styles.xsmallView;
    } else if (size === "small") {
      tmp = styles.smallView;
    } else if (size === "normal") {
      tmp = styles.normalView;
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }
  return Style.arrayStyle([tmp]);
}

function toRoute(route) {
  return {
          TAG: /* Route */0,
          _0: route
        };
}

var make = React.forwardRef(function (Props, ref) {
      var to = Props.to;
      var openNewTabOpt = Props.openNewTab;
      var name = Props.name;
      var marginSize = Props.marginSize;
      var colorOpt = Props.color;
      var hoveredColor = Props.hoveredColor;
      var disabledOpt = Props.disabled;
      var openNewTab = openNewTabOpt !== undefined ? openNewTabOpt : false;
      var color = colorOpt !== undefined ? colorOpt : "#797885";
      var disabled = disabledOpt !== undefined ? disabledOpt : false;
      var match = Hover.use((ref == null) ? undefined : Caml_option.some(ref), undefined);
      return React.createElement(Navigation.Link.make, {
                  children: React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Icon.make, {
                                    name: name,
                                    fill: match[1] && !(disabled || hoveredColor === undefined) ? hoveredColor : color
                                  })), Caml_option.some(Style.arrayStyle([
                                    styles.view,
                                    viewStyleFromParams(marginSize)
                                  ])), undefined, match[0], undefined)),
                  to: to,
                  openNewTab: openNewTab,
                  disabled: disabled
                });
    });

var make$1 = React.memo(make);

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9) {
  var tmp = {
    to: prim0,
    name: prim2
  };
  if (prim1 !== undefined) {
    tmp.openNewTab = Caml_option.valFromOption(prim1);
  }
  if (prim3 !== undefined) {
    tmp.marginSize = Caml_option.valFromOption(prim3);
  }
  if (prim4 !== undefined) {
    tmp.color = Caml_option.valFromOption(prim4);
  }
  if (prim5 !== undefined) {
    tmp.hoveredColor = Caml_option.valFromOption(prim5);
  }
  if (prim6 !== undefined) {
    tmp.disabled = Caml_option.valFromOption(prim6);
  }
  if (prim7 !== undefined) {
    tmp.key = prim7;
  }
  if (prim8 !== undefined) {
    tmp.ref = Caml_option.valFromOption(prim8);
  }
  return tmp;
}

export {
  toRoute ,
  makeProps ,
  make$1 as make,
}
/* styles Not a pure module */
