// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Column from "../../resources/layout-and-structure/Column.bs.js";
import * as Columns from "../../resources/layout-and-structure/Columns.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as OrderStatus from "../Order/OrderStatus.bs.js";
import * as CartGlobalDiscountView from "./CartGlobalDiscountView.bs.js";
import * as CartTaxApplicationView from "./CartTaxApplicationView.bs.js";

function CartOptionsView(Props) {
  var cart = Props.cart;
  var statuses = Props.statuses;
  var dispatch = Props.onRequestDispatch;
  var match = cart.discounts.length;
  return React.createElement(Columns.make, {
              children: React.createElement(Column.make, {
                    children: null,
                    width: "content"
                  }, React.createElement(Stack.make, {
                        children: null,
                        space: "none"
                      }, match !== 0 ? null : React.createElement(CartGlobalDiscountView.make, {
                              warningMessage: cart.formattedTotalAmountExcludingGlobalDiscounts,
                              decimalPrecision: cart.decimalPrecision,
                              onRequestDispatch: dispatch
                            }), Belt_Array.map(cart.discounts, (function (discount) {
                              return React.createElement(CartGlobalDiscountView.make, {
                                          discount: discount,
                                          warningMessage: cart.formattedTotalAmountExcludingGlobalDiscounts,
                                          decimalPrecision: cart.decimalPrecision,
                                          onRequestDispatch: dispatch,
                                          key: discount.id
                                        });
                            }))), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(CartTaxApplicationView.make, {
                                    taxesFree: cart.taxesFree,
                                    orderReceived: OrderStatus.has(statuses, "RECEIVED"),
                                    onRequestDispatch: dispatch
                                  })), undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))),
              space: "medium"
            });
}

var make = React.memo(CartOptionsView, (function (oldProps, newProps) {
        if (oldProps.cart === newProps.cart) {
          return oldProps.statuses === newProps.statuses;
        } else {
          return false;
        }
      }));

var Utils;

export {
  Utils ,
  make ,
}
/* make Not a pure module */
