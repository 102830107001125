// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "./Json.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Exceljs from "exceljs";
import * as Papaparse from "papaparse";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_string from "rescript/lib/es6/caml_string.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

function makeCsvPlainText(delimiterOpt, rows) {
  var delimiter = delimiterOpt !== undefined ? delimiterOpt : ";";
  var rows$1;
  try {
    rows$1 = Papaparse.unparse(rows, {
          delimiter: delimiter,
          newline: "\n"
        });
  }
  catch (exn){
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
  var lines = rows$1.split("\n");
  var maxCols = Belt_Array.reduce(lines, 0, (function (acc, line) {
          var count = line.split(delimiter).length;
          if (count > acc) {
            return count;
          } else {
            return acc;
          }
        }));
  var entries = Belt_Array.reduce(Belt_Array.map(lines, (function (line) {
              var count = line.split(delimiter).length;
              return line + $$String.make(maxCols - count | 0, Caml_string.get(delimiter, 0));
            })), "", (function (acc, entry) {
          return acc + (entry.trim() + "\n");
        }));
  return {
          TAG: /* Ok */0,
          _0: entries
        };
}

function makeCsvBlobFromPlainText(plainText) {
  return new Blob([
              new Uint8Array([
                    239,
                    187,
                    191
                  ]),
              plainText
            ], {
              type: "text/csv;charset=utf-8"
            });
}

function makeExcelBlob(rows, worksheetName) {
  try {
    var workbook = new Exceljs.Workbook();
    var worksheet = workbook.addWorksheet(worksheetName);
    Belt_Array.forEach(rows, (function (row) {
            worksheet.addRow(row);
          }));
    return Future.mapError(FuturePromise.fromPromise(workbook.xlsx.writeBuffer().then(function (data) {
                        return Promise.resolve(new Blob([data], {
                                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                      }));
                      })), undefined, (function (param) {
                  
                }));
  }
  catch (exn){
    return Future.value({
                TAG: /* Error */1,
                _0: undefined
              });
  }
}

function parseExceljsXlsxFile(headerIndexOffsetOpt, file) {
  var headerIndexOffset = headerIndexOffsetOpt !== undefined ? headerIndexOffsetOpt : 1;
  var workbook = new Exceljs.Workbook();
  var xlsx = workbook.xlsx;
  var findWorksheet = function (_indexOpt, workbook) {
    while(true) {
      var indexOpt = _indexOpt;
      var index = indexOpt !== undefined ? indexOpt : 1;
      if (index > 10) {
        var NotFoundWorksheet = /* @__PURE__ */Caml_exceptions.create("NotFoundWorksheet");
        return Promise.reject({
                    RE_EXN_ID: NotFoundWorksheet
                  });
      }
      var worksheet = workbook.getWorksheet(index);
      if (!(worksheet == null)) {
        return Promise.resolve(worksheet);
      }
      _indexOpt = index + 1 | 0;
      continue ;
    };
  };
  var processWorkbook = async function (data) {
    var workbook = await xlsx.load(data);
    var worksheet = await findWorksheet(undefined, workbook);
    var rows = {
      contents: []
    };
    var workingHeaders = Belt_Array.sliceToEnd(worksheet.getRow(headerIndexOffset).values, 1);
    worksheet.eachRow(function (row, rowNumber) {
          if (rowNumber <= headerIndexOffset) {
            return ;
          }
          var dict = {};
          var values = Belt_Array.sliceToEnd(row.values, 1);
          Belt_Array.forEachWithIndex(workingHeaders, (function (index, header) {
                  var header$1 = Belt_Option.flatMap(header, Js_json.decodeString);
                  var match = Belt_Array.get(values, index);
                  var cell;
                  if (match !== undefined) {
                    var json = Caml_option.valFromOption(match);
                    if (json !== undefined) {
                      var json$1 = Caml_option.valFromOption(json);
                      var resultFromFormulaJson = Belt_Option.flatMap(Json.decodeDict(json$1), (function (dict) {
                              return Js_dict.get(dict, "result");
                            }));
                      cell = resultFromFormulaJson !== undefined ? Caml_option.valFromOption(resultFromFormulaJson) : json$1;
                    } else {
                      cell = Json.encodedNull;
                    }
                  } else {
                    cell = Json.encodedNull;
                  }
                  if (header$1 === undefined) {
                    return ;
                  }
                  if (header$1 === "") {
                    return ;
                  }
                  dict[header$1] = cell;
                }));
          rows.contents.push(dict);
        });
    return Belt_Array.map(rows.contents, (function (prim) {
                  return prim;
                }));
  };
  return Future.mapError(FuturePromise.fromPromise(file.arrayBuffer().then(processWorkbook)), undefined, (function (param) {
                
              }));
}

function parseCsvFile(file) {
  return Future.make(function (resolve) {
              var data = [];
              var step = function (param) {
                data.push(param.data);
              };
              var complete = function (param) {
                Curry._1(resolve, {
                      TAG: /* Ok */0,
                      _0: data
                    });
              };
              var error = function (param) {
                Curry._1(resolve, {
                      TAG: /* Error */1,
                      _0: undefined
                    });
              };
              try {
                var config_skipEmptyLines = Caml_option.some(Json.encodeString("greedy"));
                var config = {
                  step: step,
                  complete: complete,
                  error: error,
                  skipEmptyLines: config_skipEmptyLines,
                  worker: true
                };
                Papaparse.parse(file, config);
              }
              catch (exn){
                Curry._1(resolve, {
                      TAG: /* Error */1,
                      _0: undefined
                    });
              }
            });
}

function CsvParserAndDecoder(Config) {
  var make = function (file) {
    return Future.make(function (resolve) {
                var data = {
                  contents: []
                };
                var errors = {
                  contents: []
                };
                var index = {
                  contents: 0
                };
                var step = function (param) {
                  var entry = param.data;
                  var entryIndex = {
                    contents: 0
                  };
                  var valid = Belt_Array.every(entry, (function (cellValue) {
                          var cell = Curry._2(Config.cellFromEntryIndex, entryIndex.contents, cellValue);
                          var error = Curry._1(Config.validateCellFromLabel, cell);
                          entryIndex.contents = entryIndex.contents + 1 | 0;
                          if (error.TAG === /* Ok */0) {
                            return true;
                          }
                          errors.contents = Belt_Array.concat(errors.contents, [/* InvalidCell */{
                                  entryIndex: entryIndex.contents,
                                  rowIndex: index.contents + 1 | 0,
                                  message: error._0,
                                  value: cellValue
                                }]);
                          return false;
                        }));
                  if (valid) {
                    data.contents = Belt_Array.concat(data.contents, [Curry._1(Config.rowFromEntry, entry)]);
                  }
                  index.contents = index.contents + 1 | 0;
                };
                var complete = function (param) {
                  Curry._1(resolve, {
                        TAG: /* Ok */0,
                        _0: [
                          data.contents,
                          errors.contents
                        ]
                      });
                };
                var error = function (param) {
                  Curry._1(resolve, {
                        TAG: /* Error */1,
                        _0: undefined
                      });
                };
                var config_skipEmptyLines = Caml_option.some(Json.encodeBoolean(true));
                var config = {
                  step: step,
                  complete: complete,
                  error: error,
                  skipEmptyLines: config_skipEmptyLines,
                  worker: true
                };
                try {
                  Papaparse.parse(file, config);
                }
                catch (exn){
                  Curry._1(resolve, {
                        TAG: /* Error */1,
                        _0: undefined
                      });
                }
              });
  };
  return {
          make: make
        };
}

var csvFileExtension = "csv";

var excelFileExtension = "xlsx";

export {
  csvFileExtension ,
  makeCsvPlainText ,
  makeCsvBlobFromPlainText ,
  excelFileExtension ,
  makeExcelBlob ,
  parseExceljsXlsxFile ,
  parseCsvFile ,
  CsvParserAndDecoder ,
}
/* Json Not a pure module */
