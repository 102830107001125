// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Select from "../../resources/selection-and-input/Select.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Scaffold from "../../bundles/Scaffold/Scaffold.bs.js";
import * as JsonCodec from "../../primitives/JsonCodec.bs.js";
import * as SearchBar from "../../resources/selection-and-input/SearchBar.bs.js";
import * as Separator from "../../resources/layout-and-structure/Separator.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DeviceName from "../../modules/DeviceName.bs.js";
import * as EmptyState from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Placeholder from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as Illustration from "../../resources/images-and-icons/Illustration.bs.js";
import * as Client from "@apollo/client";
import * as StockActivityKind from "../../modules/StockActivity/StockActivityKind.bs.js";
import * as TableDatetimeCell from "../../resources/tables/TableDatetimeCell.bs.js";
import * as StockActivityReason from "../../modules/StockActivity/StockActivityReason.bs.js";
import * as SelectDateRangeFilter from "../../resources/selection-and-input/SelectDateRangeFilter.bs.js";
import * as StockActivityTypeTableCell from "../../modules/StockActivity/StockActivityTypeTableCell.bs.js";
import * as StockActivitySourceTableCell from "../../modules/StockActivity/StockActivitySourceTableCell.bs.js";
import * as StockActivityQuantityTableCell from "../../modules/StockActivity/StockActivityQuantityTableCell.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query CatalogVariantStockActivityPageParentQuery($cku: CKU!)  {\nvariantsByCku(cku: $cku, first: 1)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \ncku  \nformattedName  \n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variantsByCku;
  var value$2 = value$1.edges;
  return {
          variantsByCku: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              cku: Scalar.CKU.parse(value$1.cku),
                              formattedName: value$1.formattedName
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.variantsByCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.formattedName;
          var value$3 = value$1.cku;
          var value$4 = Scalar.CKU.serialize(value$3);
          var value$5 = value$1.id;
          var value$6 = value$1.__typename;
          var node = {
            __typename: value$6,
            id: value$5,
            cku: value$4,
            formattedName: value$2
          };
          var value$7 = value.__typename;
          return {
                  __typename: value$7,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var variantsByCku = {
    __typename: value$3,
    edges: edges
  };
  return {
          variantsByCku: variantsByCku
        };
}

function serializeVariables(inp) {
  return {
          cku: inp.cku
        };
}

function makeVariables(cku, param) {
  return {
          cku: cku
        };
}

var ParentQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var ParentQuery_refetchQueryDescription = include.refetchQueryDescription;

var ParentQuery_useLazy = include.useLazy;

var ParentQuery_useLazyWithVariables = include.useLazyWithVariables;

var ParentQuery = {
  ParentQuery_inner: ParentQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: ParentQuery_refetchQueryDescription,
  use: use,
  useLazy: ParentQuery_useLazy,
  useLazyWithVariables: ParentQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query CatalogVariantStockActivityPageQuery($cku: CKU!, $filterBy: InputStockActivitiesQueryFilter, $search: String, $before: String, $after: String, $first: Int, $last: Int)  {\nstockActivitiesByVariantCku(cku: $cku, search: $search, filterBy: $filterBy, before: $before, after: $after, first: $first, last: $last)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\ntotalCount  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nkind  \nreason  \ncomment  \nshop  {\n__typename  \nname  \n}\n\ndevice  {\n__typename  \nname  \nslug  \n}\n\ncreatedAt  \nquantity  \nvariant  {\n__typename  \ncapacityPrecision  \ncapacityUnit  \nbulk  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.stockActivitiesByVariantCku;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          stockActivitiesByVariantCku: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            totalCount: value$1.totalCount,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.kind;
                    var tmp;
                    switch (value$2) {
                      case "CREDIT_MEMO" :
                          tmp = "CREDIT_MEMO";
                          break;
                      case "DELIVERY" :
                          tmp = "DELIVERY";
                          break;
                      case "DELIVERY_RECEIPT" :
                          tmp = "DELIVERY_RECEIPT";
                          break;
                      case "INCOMING_TRANSFER" :
                          tmp = "INCOMING_TRANSFER";
                          break;
                      case "LOSS" :
                          tmp = "LOSS";
                          break;
                      case "OUTGOING_TRANSFER" :
                          tmp = "OUTGOING_TRANSFER";
                          break;
                      case "RECEPTION" :
                          tmp = "RECEPTION";
                          break;
                      case "REFUND" :
                          tmp = "REFUND";
                          break;
                      case "RESET" :
                          tmp = "RESET";
                          break;
                      case "SALE" :
                          tmp = "SALE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$3 = value$1.reason;
                    var tmp$1;
                    if (value$3 == null) {
                      tmp$1 = undefined;
                    } else {
                      var tmp$2;
                      switch (value$3) {
                        case "CORKY" :
                            tmp$2 = "CORKY";
                            break;
                        case "DAMAGE" :
                            tmp$2 = "DAMAGE";
                            break;
                        case "LOSS" :
                            tmp$2 = "LOSS";
                            break;
                        case "OTHER" :
                            tmp$2 = "OTHER";
                            break;
                        case "OUTDATED_DLUO" :
                            tmp$2 = "OUTDATED_DLUO";
                            break;
                        case "SALE_BY_GLASS" :
                            tmp$2 = "SALE_BY_GLASS";
                            break;
                        case "TASTING" :
                            tmp$2 = "TASTING";
                            break;
                        case "THEFT" :
                            tmp$2 = "THEFT";
                            break;
                        case "TRADE_IN_SUPPLIER" :
                            tmp$2 = "TRADE_IN_SUPPLIER";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$1 = tmp$2;
                    }
                    var value$4 = value$1.comment;
                    var value$5 = value$1.shop;
                    var value$6 = value$1.device;
                    var value$7 = value$1.variant;
                    var tmp$3;
                    if (value$7 == null) {
                      tmp$3 = undefined;
                    } else {
                      var value$8 = value$7.capacityPrecision;
                      var value$9 = value$7.capacityUnit;
                      var value$10 = value$7.bulk;
                      tmp$3 = {
                        __typename: value$7.__typename,
                        capacityPrecision: !(value$8 == null) ? value$8 : undefined,
                        capacityUnit: !(value$9 == null) ? value$9 : undefined,
                        bulk: !(value$10 == null) ? value$10 : undefined
                      };
                    }
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              kind: tmp,
                              reason: tmp$1,
                              comment: !(value$4 == null) ? Scalar.$$Text.parse(value$4) : undefined,
                              shop: {
                                __typename: value$5.__typename,
                                name: value$5.name
                              },
                              device: {
                                __typename: value$6.__typename,
                                name: value$6.name,
                                slug: value$6.slug
                              },
                              createdAt: Scalar.Datetime.parse(value$1.createdAt),
                              quantity: value$1.quantity,
                              variant: tmp$3
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.stockActivitiesByVariantCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.variant;
          var variant;
          if (value$2 !== undefined) {
            var value$3 = value$2.bulk;
            var bulk = value$3 !== undefined ? value$3 : null;
            var value$4 = value$2.capacityUnit;
            var capacityUnit = value$4 !== undefined ? value$4 : null;
            var value$5 = value$2.capacityPrecision;
            var capacityPrecision = value$5 !== undefined ? value$5 : null;
            var value$6 = value$2.__typename;
            variant = {
              __typename: value$6,
              capacityPrecision: capacityPrecision,
              capacityUnit: capacityUnit,
              bulk: bulk
            };
          } else {
            variant = null;
          }
          var value$7 = value$1.quantity;
          var value$8 = value$1.createdAt;
          var value$9 = Scalar.Datetime.serialize(value$8);
          var value$10 = value$1.device;
          var value$11 = value$10.slug;
          var value$12 = value$10.name;
          var value$13 = value$10.__typename;
          var device = {
            __typename: value$13,
            name: value$12,
            slug: value$11
          };
          var value$14 = value$1.shop;
          var value$15 = value$14.name;
          var value$16 = value$14.__typename;
          var shop = {
            __typename: value$16,
            name: value$15
          };
          var value$17 = value$1.comment;
          var comment = value$17 !== undefined ? Scalar.$$Text.serialize(value$17) : null;
          var value$18 = value$1.reason;
          var reason = value$18 !== undefined ? (
              value$18 === "DAMAGE" ? "DAMAGE" : (
                  value$18 === "OUTDATED_DLUO" ? "OUTDATED_DLUO" : (
                      value$18 === "SALE_BY_GLASS" ? "SALE_BY_GLASS" : (
                          value$18 === "THEFT" ? "THEFT" : (
                              value$18 === "TASTING" ? "TASTING" : (
                                  value$18 === "LOSS" ? "LOSS" : (
                                      value$18 === "OTHER" ? "OTHER" : (
                                          value$18 === "TRADE_IN_SUPPLIER" ? "TRADE_IN_SUPPLIER" : "CORKY"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$19 = value$1.kind;
          var kind = value$19 === "DELIVERY_RECEIPT" ? "DELIVERY_RECEIPT" : (
              value$19 === "RESET" ? "RESET" : (
                  value$19 === "INCOMING_TRANSFER" ? "INCOMING_TRANSFER" : (
                      value$19 === "DELIVERY" ? "DELIVERY" : (
                          value$19 === "REFUND" ? "REFUND" : (
                              value$19 === "LOSS" ? "LOSS" : (
                                  value$19 === "CREDIT_MEMO" ? "CREDIT_MEMO" : (
                                      value$19 === "SALE" ? "SALE" : (
                                          value$19 === "RECEPTION" ? "RECEPTION" : "OUTGOING_TRANSFER"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            );
          var value$20 = value$1.id;
          var value$21 = value$1.__typename;
          var node = {
            __typename: value$21,
            id: value$20,
            kind: kind,
            reason: reason,
            comment: comment,
            shop: shop,
            device: device,
            createdAt: value$9,
            quantity: value$7,
            variant: variant
          };
          var value$22 = value.__typename;
          return {
                  __typename: value$22,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.totalCount;
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var stockActivitiesByVariantCku = {
    __typename: value$8,
    pageInfo: pageInfo,
    totalCount: value$3,
    edges: edges
  };
  return {
          stockActivitiesByVariantCku: stockActivitiesByVariantCku
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInOrNotInFilter(inp) {
  var a = inp._in;
  var a$1 = inp._notIn;
  return {
          _in: a !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a) : undefined,
          _notIn: a$1 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$1) : undefined
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectInputStockActivitiesQueryFilter(inp) {
  var a = inp.kind;
  var a$1 = inp.reason;
  var a$2 = inp.date;
  var a$3 = inp.shopIds;
  return {
          kind: a !== undefined ? serializeInputObjectInOrNotInFilter(a) : undefined,
          reason: a$1 !== undefined ? serializeInputObjectInFilter(a$1) : undefined,
          date: a$2 !== undefined ? serializeInputObjectDateFilter(a$2) : undefined,
          shopIds: a$3 !== undefined ? serializeInputObjectInFilter(a$3) : undefined
        };
}

function serializeVariables$1(inp) {
  var a = inp.filterBy;
  var a$1 = inp.search;
  var a$2 = inp.before;
  var a$3 = inp.after;
  var a$4 = inp.first;
  var a$5 = inp.last;
  return {
          cku: inp.cku,
          filterBy: a !== undefined ? serializeInputObjectInputStockActivitiesQueryFilter(a) : undefined,
          search: a$1 !== undefined ? a$1 : undefined,
          before: a$2 !== undefined ? a$2 : undefined,
          after: a$3 !== undefined ? a$3 : undefined,
          first: a$4 !== undefined ? a$4 : undefined,
          last: a$5 !== undefined ? a$5 : undefined
        };
}

function makeVariables$1(cku, filterBy, search, before, after, first, last, param) {
  return {
          cku: cku,
          filterBy: filterBy,
          search: search,
          before: before,
          after: after,
          first: first,
          last: last
        };
}

function makeInputObjectInputStockActivitiesQueryFilter(kind, reason, date, shopIds, param) {
  return {
          kind: kind,
          reason: reason,
          date: date,
          shopIds: shopIds
        };
}

function makeInputObjectInOrNotInFilter(_in, _notIn, param) {
  return {
          _in: _in,
          _notIn: _notIn
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

var Query_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputStockActivitiesQueryFilter: serializeInputObjectInputStockActivitiesQueryFilter,
  serializeInputObjectInOrNotInFilter: serializeInputObjectInOrNotInFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables$1,
  makeInputObjectInputStockActivitiesQueryFilter: makeInputObjectInputStockActivitiesQueryFilter,
  makeInputObjectInOrNotInFilter: makeInputObjectInOrNotInFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var Query_refetchQueryDescription = include$1.refetchQueryDescription;

var Query_useLazy = include$1.useLazy;

var Query_useLazyWithVariables = include$1.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputStockActivitiesQueryFilter: serializeInputObjectInputStockActivitiesQueryFilter,
  serializeInputObjectInOrNotInFilter: serializeInputObjectInOrNotInFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables$1,
  makeInputObjectInputStockActivitiesQueryFilter: makeInputObjectInputStockActivitiesQueryFilter,
  makeInputObjectInOrNotInFilter: makeInputObjectInOrNotInFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use$1,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function encoder(param) {
  return [
          Belt_Option.map(param.shop, (function (shop) {
                  return shop.id;
                })),
          Belt_Option.map(param.dateRange, (function (param) {
                  return [
                          param[0].valueOf(),
                          param[1].valueOf()
                        ];
                })),
          Belt_Option.map(param.kind, StockActivityKind.toString),
          Belt_Option.map(param.reason, StockActivityReason.toString)
        ];
}

function decoder(shops, param) {
  var shopId = param[0];
  return {
          TAG: /* Ok */0,
          _0: {
            shop: Belt_Array.getBy(shops, (function (shop) {
                    return shop.id === shopId;
                  })),
            dateRange: Belt_Option.flatMap(param[1], (function (range) {
                    if (range.length !== 2) {
                      return ;
                    }
                    var start = range[0];
                    var end = range[1];
                    return [
                            new Date(start),
                            new Date(end)
                          ];
                  })),
            kind: Belt_Option.map(param[2], StockActivityKind.fromStringExn),
            reason: Belt_Option.map(param[3], StockActivityReason.fromStringExn)
          }
        };
}

function useJsonCodec(param) {
  var shops = Auth.useShops(undefined);
  return JsonCodec.object4(encoder, (function (param) {
                return decoder(shops, param);
              }), JsonCodec.optional(JsonCodec.field("shopId", JsonCodec.string)), JsonCodec.optional(JsonCodec.field("dateRange", JsonCodec.array(JsonCodec.$$float))), JsonCodec.optional(JsonCodec.field("kind", JsonCodec.string)), JsonCodec.optional(JsonCodec.field("reason", JsonCodec.string)));
}

var Filters = {
  encoder: encoder,
  decoder: decoder,
  useJsonCodec: useJsonCodec
};

var Row = {};

function makeQueryVariables(defaultQueryVariables, connectionArguments, search, filterBy, param) {
  return {
          cku: defaultQueryVariables.cku,
          filterBy: filterBy,
          search: search,
          before: connectionArguments.before,
          after: connectionArguments.after,
          first: connectionArguments.first,
          last: connectionArguments.last
        };
}

function makeQueryVariablesFilterBy(param) {
  var reason = param.reason;
  var kind = param.kind;
  var dateRange = param.dateRange;
  var shop = param.shop;
  return makeInputObjectInputStockActivitiesQueryFilter(kind !== undefined ? ({
                  _in: [StockActivityKind.toString(kind)],
                  _notIn: undefined
                }) : undefined, reason !== undefined ? ({
                  _in: [StockActivityReason.toString(reason)]
                }) : undefined, dateRange !== undefined ? ({
                  _after: undefined,
                  _before: undefined,
                  _between: [
                    Scalar.Datetime.serialize(dateRange[0]),
                    Scalar.Datetime.serialize(dateRange[1])
                  ]
                }) : undefined, shop !== undefined ? ({
                  _in: [shop.id]
                }) : undefined, undefined);
}

function totalCountFromQueryData(param) {
  return param.stockActivitiesByVariantCku.totalCount;
}

function cursorsFromQueryData(param) {
  var stockActivitiesByVariantCku = param.stockActivitiesByVariantCku;
  return [
          stockActivitiesByVariantCku.pageInfo.startCursor,
          stockActivitiesByVariantCku.pageInfo.endCursor
        ];
}

function rowsFromQueryDataAndState(param, param$1) {
  return Belt_Array.keepMap(param.stockActivitiesByVariantCku.edges, (function (param) {
                var node = param.node;
                var variant = node.variant;
                if (variant === undefined) {
                  return ;
                }
                var match = variant.bulk;
                var match$1 = variant.capacityPrecision;
                var match$2 = variant.bulk;
                var match$3 = variant.capacityUnit;
                return {
                        id: node.id,
                        formattedKind: StockActivityKind.toLabel(node.kind),
                        kind: node.kind,
                        reason: node.reason,
                        quantity: node.quantity,
                        variantCapacityPrecision: match !== undefined && match && match$1 !== undefined ? match$1 : undefined,
                        variantCapacityUnit: match$2 !== undefined && match$2 && match$3 !== undefined ? match$3 : undefined,
                        description: node.comment,
                        date: node.createdAt,
                        shopName: node.shop.name,
                        deviceName: DeviceName.format(DeviceName.decode(node.device.slug, node.device.name))
                      };
              }));
}

function keyExtractor(param) {
  return param.id;
}

var Scaffolded = Scaffold.Make({
      useFiltersJsonCodec: useJsonCodec,
      QueryInner: Query_inner,
      useQuery: use$1,
      makeQueryVariables: makeQueryVariables,
      makeQueryVariablesFilterBy: makeQueryVariablesFilterBy,
      cursorsFromQueryData: cursorsFromQueryData,
      totalCountFromQueryData: totalCountFromQueryData,
      rowsFromQueryDataAndState: rowsFromQueryDataAndState,
      keyExtractor: keyExtractor
    });

function use$2(initialState) {
  var match = Curry._1(Scaffolded.use, (function (param) {
          return initialState;
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          var match = state.filters;
          var match$1 = match.kind;
          var exit = 0;
          if (match$1 !== "LOSS") {
            exit = 1;
          }
          if (exit === 1) {
            if (match.reason !== undefined) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (prev) {
                        return {
                                shop: prev.shop,
                                dateRange: prev.dateRange,
                                kind: prev.kind,
                                reason: undefined
                              };
                      })
                  });
            }
            
          }
          
        }), [state.filters]);
  return [
          state,
          dispatch
        ];
}

function CatalogVariantStockActivityPage(Props) {
  var cku = Props.cku;
  var scope = Auth.useScope(undefined);
  var activeShop = Auth.useActiveShop(undefined);
  var hasOrganisationScope;
  hasOrganisationScope = scope.TAG === /* Organisation */0 ? true : false;
  var cku$1 = Scalar.CKU.serialize(cku);
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          cku: cku$1
        }
      ]);
  var match$1 = match.data;
  var pageSubtitle;
  var exit = 0;
  if (match$1 !== undefined) {
    var match$2 = match$1.variantsByCku.edges;
    if (match$2.length !== 1) {
      exit = 1;
    } else {
      var match$3 = match$2[0];
      pageSubtitle = match$3.node.formattedName;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    pageSubtitle = match.error !== undefined ? undefined : $$Intl.t("Loading") + "...";
  }
  var initialState = Curry._1(Scaffolded.makeInitialState, {
        shop: activeShop,
        dateRange: undefined,
        kind: undefined,
        reason: undefined
      });
  var match$4 = use$2(initialState);
  var dispatch = match$4[1];
  var state = match$4[0];
  var defaultQueryVariables = makeVariables$1(cku$1, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var columns = [
    {
      name: $$Intl.t("Type"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 100
        },
        width: {
          NAME: "fr",
          VAL: 0.5
        }
      },
      render: (function (param) {
          return React.createElement(StockActivityTypeTableCell.make, {
                      value: param.formattedKind,
                      reason: param.reason
                    });
        })
    },
    {
      name: $$Intl.t("Quantity"),
      layout: {
        width: {
          NAME: "fr",
          VAL: 0.5
        },
        alignX: "center",
        sticky: true
      },
      render: (function (param) {
          var tmp = {
            value: param.quantity,
            kind: param.kind
          };
          if (param.variantCapacityPrecision !== undefined) {
            tmp.capacityPrecision = Caml_option.valFromOption(param.variantCapacityPrecision);
          }
          if (param.variantCapacityUnit !== undefined) {
            tmp.capacityUnit = Caml_option.valFromOption(param.variantCapacityUnit);
          }
          return React.createElement(StockActivityQuantityTableCell.make, tmp);
        })
    },
    {
      name: $$Intl.t("Comment"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        },
        margin: "normal"
      },
      render: (function (param) {
          return React.createElement(TextStyle.make, {
                      children: Belt_Option.getWithDefault(param.description, "—")
                    });
        })
    },
    {
      name: $$Intl.t("Datetime"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        }
      },
      render: (function (param) {
          return React.createElement(TableDatetimeCell.make, {
                      value: param.date
                    });
        })
    },
    {
      name: $$Intl.t("Source"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 120
        }
      },
      render: (function (param) {
          var shopName;
          shopName = scope.TAG === /* Organisation */0 && scope.activeShop === undefined ? param.shopName : undefined;
          var tmp = {
            deviceName: param.deviceName
          };
          if (shopName !== undefined) {
            tmp.shopName = Caml_option.valFromOption(shopName);
          }
          return React.createElement(StockActivitySourceTableCell.make, tmp);
        })
    }
  ];
  var tmp;
  if (hasOrganisationScope) {
    var tmp$1 = {
      onChange: (function (shop) {
          Curry._1(dispatch, {
                TAG: /* FiltersUpdated */2,
                _0: (function (prev) {
                    return {
                            shop: shop,
                            dateRange: prev.dateRange,
                            kind: prev.kind,
                            reason: prev.reason
                          };
                  })
              });
        })
    };
    if (state.filters.shop !== undefined) {
      tmp$1.value = Caml_option.valFromOption(state.filters.shop);
    }
    tmp = React.createElement(Auth.SelectShopFilter.make, tmp$1);
  } else {
    tmp = null;
  }
  var defaultItem_label = $$Intl.t("All");
  var defaultItem_sticky = true;
  var defaultItem = {
    key: "default",
    label: defaultItem_label,
    sticky: defaultItem_sticky
  };
  var items = Belt_Array.map(StockActivityKind.values, (function (value) {
          return {
                  key: StockActivityKind.toString(value),
                  value: value,
                  label: StockActivityKind.toLabel(value)
                };
        }));
  var match$5 = state.filters.kind;
  var tmp$2;
  if (match$5 === "LOSS") {
    var defaultItem_label$1 = $$Intl.template($$Intl.t("All{{feminine}}"), undefined, undefined);
    var defaultItem_sticky$1 = true;
    var defaultItem$1 = {
      key: "default",
      label: defaultItem_label$1,
      sticky: defaultItem_sticky$1
    };
    var items$1 = Belt_Array.map(StockActivityReason.values, (function (value) {
            return {
                    key: StockActivityReason.toString(value),
                    value: value,
                    label: StockActivityReason.toLabel(value)
                  };
          }));
    tmp$2 = React.createElement(Select.make, {
          label: $$Intl.t("Reason"),
          sections: [
            {
              items: [defaultItem$1]
            },
            {
              items: items$1,
              title: $$Intl.t("Reasons")
            }
          ],
          preset: "filter",
          value: state.filters.reason,
          onChange: (function (reason) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (prev) {
                        return {
                                shop: prev.shop,
                                dateRange: prev.dateRange,
                                kind: prev.kind,
                                reason: reason
                              };
                      })
                  });
            })
        });
  } else {
    tmp$2 = null;
  }
  var tmp$3 = {
    placeholder: $$Intl.t("Since the beginning"),
    onChange: (function (dateRange) {
        Curry._1(dispatch, {
              TAG: /* FiltersUpdated */2,
              _0: (function (prev) {
                  return {
                          shop: prev.shop,
                          dateRange: dateRange,
                          kind: prev.kind,
                          reason: prev.reason
                        };
                })
            });
      }),
    triggerLabelDisplay: "showPreset"
  };
  if (state.filters.dateRange !== undefined) {
    tmp$3.value = Caml_option.valFromOption(state.filters.dateRange);
  }
  var match$6 = state.filters;
  var tmp$4;
  var exit$1 = 0;
  if (match$6.dateRange !== undefined || match$6.kind !== undefined) {
    exit$1 = 1;
  } else {
    tmp$4 = null;
  }
  if (exit$1 === 1) {
    tmp$4 = React.createElement(Scaffold.ResetFiltersButton.make, {
          onPress: (function (param) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (param) {
                        return initialState.filters;
                      })
                  });
            })
        });
  }
  var filters = React.createElement(Inline.make, {
        children: null,
        space: "small"
      }, tmp, hasOrganisationScope ? React.createElement(Separator.make, {}) : null, React.createElement(Select.make, {
            label: $$Intl.t("Kind"),
            sections: [
              {
                items: [defaultItem]
              },
              {
                items: items,
                title: $$Intl.t("Kinds")
              }
            ],
            preset: "filter",
            value: state.filters.kind,
            onChange: (function (kind) {
                Curry._1(dispatch, {
                      TAG: /* FiltersUpdated */2,
                      _0: (function (prev) {
                          return {
                                  shop: prev.shop,
                                  dateRange: prev.dateRange,
                                  kind: kind,
                                  reason: prev.reason
                                };
                        })
                    });
              })
          }), tmp$2, React.createElement(SelectDateRangeFilter.make, tmp$3), tmp$4);
  var tmp$5 = {
    placeholder: $$Intl.t("Search a stock movement"),
    onChange: (function (searchQuery) {
        Curry._1(dispatch, {
              TAG: /* Searched */1,
              _0: searchQuery
            });
      })
  };
  if (state.searchQuery !== undefined) {
    tmp$5.value = state.searchQuery;
  }
  var searchBar = React.createElement(SearchBar.make, tmp$5);
  var emptyState;
  var exit$2 = 0;
  if (state.currentPage !== 1 || state.searchQuery !== undefined) {
    exit$2 = 1;
  } else {
    var match$7 = state.filters;
    if (match$7.dateRange !== undefined || match$7.kind !== undefined || match$7.reason !== undefined) {
      exit$2 = 1;
    } else {
      var exit$3 = 0;
      if (scope.TAG === /* Organisation */0 && scope.activeShop !== undefined) {
        emptyState = React.createElement(EmptyState.make, {
              illustration: Illustration.notFound,
              title: $$Intl.t("Welcome to the stock movements space."),
              text: $$Intl.t("It seems no data exists yet for this shop.")
            });
      } else {
        exit$3 = 2;
      }
      if (exit$3 === 2) {
        emptyState = React.createElement(EmptyState.make, {
              illustration: Illustration.create,
              title: $$Intl.t("Welcome to the stock movements space.")
            });
      }
      
    }
  }
  if (exit$2 === 1) {
    emptyState = React.createElement(EmptyState.make, {
          illustration: Illustration.notFound,
          title: $$Intl.t("No result were found."),
          text: $$Intl.t("Try again with another keyword/filter or:"),
          children: React.createElement(Button.make, Button.makeProps($$Intl.t("Clear search query and filters"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Reset */3,
                            _0: initialState
                          });
                    }), undefined, undefined, undefined))
        });
  }
  if (pageSubtitle !== undefined) {
    return React.createElement(Scaffolded.make, {
                title: $$Intl.t("Stock activities"),
                subtitle: pageSubtitle,
                state: state,
                dispatch: dispatch,
                columns: columns,
                filters: filters,
                searchBar: searchBar,
                emptyState: emptyState,
                defaultQueryVariables: defaultQueryVariables
              });
  } else {
    return React.createElement(Placeholder.make, {
                status: /* Error */2
              });
  }
}

var make = React.memo(CatalogVariantStockActivityPage);

export {
  ParentQuery ,
  Query ,
  Filters ,
  Row ,
  Scaffolded ,
  use$2 as use,
  make ,
}
/* query Not a pure module */
