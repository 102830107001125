// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Sheet from "../../primitives/Sheet.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Accounting from "@wino/accounting/src/Accounting.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as CartProduct from "../Cart/CartProduct.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as OrderCsvImporting__Config from "./OrderCsvImporting__Config.bs.js";
import * as OrderCsvImporting__Queries from "./OrderCsvImporting__Queries.bs.js";

var OrderCsvParserAndDecoder = Sheet.CsvParserAndDecoder(OrderCsvImporting__Config.ParserConfig);

var OrderCsvImporting_TaxValueNotFound = /* @__PURE__ */Caml_exceptions.create("OrderCsvImporting.OrderCsvImporting_TaxValueNotFound");

function make(file, client, notifier, cartProducts, cartDispatch, onRequestCartLoading) {
  Future.get(Future.mapOk(Curry._1(OrderCsvParserAndDecoder.make, file.src), undefined, (function (value) {
              var data = value[0];
              var fetchingVariants = {
                contents: []
              };
              var fetchedValidCount = {
                contents: 0
              };
              var fetchedInvalidCount = {
                contents: 0
              };
              var updatedValidCount = {
                contents: 0
              };
              Curry._1(notifier.clear, undefined);
              Curry._1(onRequestCartLoading, {
                    message: undefined
                  });
              Belt_Array.forEach(cartProducts, (function (product) {
                      var match;
                      if (product.TAG === /* Unit */0) {
                        var match$1 = product._0;
                        match = [
                          match$1.id,
                          match$1.stockKeepingUnit
                        ];
                      } else {
                        var match$2 = product._0;
                        match = [
                          match$2.id,
                          match$2.stockKeepingUnit
                        ];
                      }
                      var sku = match[1];
                      var match$3 = Belt_Array.some(data, (function (current) {
                              return Caml_obj.equal(current.sku, sku);
                            }));
                      if (match$3) {
                        return ;
                      } else {
                        return Curry._1(cartDispatch, {
                                    TAG: /* ProductRemoved */2,
                                    _0: match[0]
                                  });
                      }
                    }));
              Belt_Array.forEach(data, (function (param) {
                      var unitPrice = param.unitPrice;
                      var quantity = param.quantity;
                      var sku = param.sku;
                      var match = Belt_Array.keepMap(cartProducts, (function (product) {
                              var match;
                              if (product.TAG === /* Unit */0) {
                                var match$1 = product._0;
                                match = [
                                  match$1.id,
                                  match$1.stockKeepingUnit
                                ];
                              } else {
                                var match$2 = product._0;
                                match = [
                                  match$2.id,
                                  match$2.stockKeepingUnit
                                ];
                              }
                              var currentSku = match[1];
                              if (currentSku !== undefined && currentSku === sku) {
                                return [
                                        match[0],
                                        Accounting.isBulk(product)
                                      ];
                              }
                              
                            }));
                      if (match.length !== 1) {
                        fetchingVariants.contents = Belt_Array.concat(fetchingVariants.contents, [Future.mapOk(OrderCsvImporting__Queries.Fetch.fetchVariantFromSKU(client, file.shopId, sku), undefined, (function (response) {
                                      if (response !== undefined) {
                                        fetchedValidCount.contents = fetchedValidCount.contents + 1 | 0;
                                        Curry._1(onRequestCartLoading, {
                                              message: $$Intl.template($$Intl.t("{{count}} products loaded out of {{total}}"), {
                                                    count: String(fetchedValidCount.contents),
                                                    total: String(fetchingVariants.contents.length)
                                                  }, undefined)
                                            });
                                        return {
                                                data: response,
                                                file: {
                                                  TAG: /* Ok */0,
                                                  _0: {
                                                    sku: sku,
                                                    quantity: quantity,
                                                    unitPrice: unitPrice
                                                  }
                                                }
                                              };
                                      } else {
                                        fetchedInvalidCount.contents = fetchedInvalidCount.contents + 1 | 0;
                                        return {
                                                data: undefined,
                                                file: {
                                                  TAG: /* Error */1,
                                                  _0: sku
                                                }
                                              };
                                      }
                                    }))]);
                        return ;
                      }
                      var match$1 = match[0];
                      var cartProductId = match$1[0];
                      updatedValidCount.contents = updatedValidCount.contents + 1 | 0;
                      Curry._1(cartDispatch, {
                            TAG: /* ProductExpectedQuantityUpdated */4,
                            _0: cartProductId,
                            _1: match$1[1] ? ({
                                  TAG: /* BulkQuantity */1,
                                  _0: quantity
                                }) : ({
                                  TAG: /* UnitQuantity */0,
                                  _0: quantity | 0
                                })
                          });
                      if (unitPrice !== undefined) {
                        return Curry._1(cartDispatch, {
                                    TAG: /* ProductUnitPriceUpdated */6,
                                    _0: cartProductId,
                                    _1: unitPrice
                                  });
                      }
                      
                    }));
              var parsingErrors = Belt_Array.map(value[1], (function (error) {
                      return $$Intl.template($$Intl.t("{{msg}} at column {{col}} row {{row}}"), {
                                  row: error.rowIndex,
                                  col: error.entryIndex,
                                  msg: $$Intl.t(error.message)
                                }, undefined);
                    }));
              Future.get(Future.all(fetchingVariants.contents), (function (results) {
                      var productInputs = Belt_Array.keepMap(results, (function (x) {
                              if (x.TAG !== /* Ok */0) {
                                return ;
                              }
                              var match = x._0;
                              var variantData = match.data;
                              if (variantData === undefined) {
                                return ;
                              }
                              var fileData = match.file;
                              if (fileData.TAG !== /* Ok */0) {
                                return ;
                              }
                              var fileData$1 = fileData._0;
                              var match$1 = variantData.bulk;
                              var tmp;
                              if (match$1 !== undefined && match$1) {
                                var precision = variantData.capacityPrecision;
                                tmp = precision !== undefined ? precision : undefined;
                              } else {
                                tmp = undefined;
                              }
                              var productInput = CartProduct.makeProductInput(variantData.id, variantData.formattedName, variantData.formattedDescription, variantData.stockKeepingUnit, variantData.stock.rawQuantity, variantData.packaging, variantData.product.tax.value, variantData.purchasedPrice, undefined, tmp, variantData.capacityUnit, undefined);
                              var updatedUnitPrice;
                              var exit = 0;
                              var unitPrice;
                              unitPrice = productInput.product.unitPrice;
                              exit = 1;
                              if (exit === 1) {
                                var unitPrice$1 = fileData$1.unitPrice;
                                updatedUnitPrice = unitPrice$1 !== undefined ? unitPrice$1 : unitPrice;
                              }
                              var tmp$1;
                              if (productInput.TAG === /* Unit */0) {
                                var product = productInput.product;
                                tmp$1 = {
                                  TAG: /* Unit */0,
                                  product: {
                                    id: product.id,
                                    identifier: product.identifier,
                                    stockKeepingUnit: product.stockKeepingUnit,
                                    name: product.name,
                                    description: product.description,
                                    capacityUnit: product.capacityUnit,
                                    stock: product.stock,
                                    packaging: product.packaging,
                                    quantity: fileData$1.quantity | 0,
                                    expectedQuantity: product.expectedQuantity,
                                    unitPrice: updatedUnitPrice,
                                    fees: product.fees,
                                    discounts: product.discounts,
                                    tax: product.tax
                                  }
                                };
                              } else {
                                var product$1 = productInput.product;
                                tmp$1 = {
                                  TAG: /* Bulk */1,
                                  product: {
                                    id: product$1.id,
                                    identifier: product$1.identifier,
                                    stockKeepingUnit: product$1.stockKeepingUnit,
                                    name: product$1.name,
                                    description: product$1.description,
                                    capacityUnit: product$1.capacityUnit,
                                    stock: product$1.stock,
                                    packaging: product$1.packaging,
                                    quantity: fileData$1.quantity,
                                    expectedQuantity: product$1.expectedQuantity,
                                    unitPrice: updatedUnitPrice,
                                    fees: product$1.fees,
                                    discounts: product$1.discounts,
                                    tax: product$1.tax
                                  },
                                  precision: productInput.precision
                                };
                              }
                              return tmp$1;
                            }));
                      var errorsMessages = Belt_Array.keepMap(results, (function (x) {
                              if (x.TAG !== /* Ok */0) {
                                return ;
                              }
                              var match = x._0;
                              if (match.data !== undefined) {
                                return ;
                              }
                              var sku = match.file;
                              if (sku.TAG === /* Ok */0) {
                                return ;
                              } else {
                                return $$Intl.template($$Intl.t("{{sku}} could not be found in the current catalog (SKU)"), {
                                            sku: sku._0
                                          }, undefined);
                              }
                            }));
                      Curry._1(cartDispatch, {
                            TAG: /* BatchProductAdded */1,
                            _0: productInputs
                          });
                      Curry._1(onRequestCartLoading, undefined);
                      if ((parsingErrors.length + fetchedInvalidCount.contents | 0) > 0) {
                        var count = parsingErrors.length + fetchedInvalidCount.contents | 0;
                        Curry._3(notifier.add, {
                              TAG: /* Error */1,
                              _0: $$Intl.template($$Intl.t(count > 1 ? "{{count}} products from the imported CSV have not been added to the order" : "{{count}} product from the imported CSV have not been added to the order") + ".", {
                                    count: count
                                  }, undefined)
                            }, Belt_Array.concat(parsingErrors, errorsMessages), undefined);
                      }
                      if (fetchedValidCount.contents > 0 || updatedValidCount.contents > 0) {
                        return Curry._3(notifier.add, {
                                    TAG: /* Success */0,
                                    _0: $$Intl.template($$Intl.t((parsingErrors.length + fetchedInvalidCount.contents | 0) > 0 ? "{{count}} products from the imported CSV have been successfully added to the cart!" : "All products from the imported CSV have been successfully added to the cart!"), {
                                          count: fetchedValidCount.contents + updatedValidCount.contents | 0
                                        }, undefined)
                                  }, undefined, undefined);
                      }
                      
                    }));
            })), (function (result) {
          if (result.TAG === /* Ok */0) {
            return ;
          } else {
            return Curry._3(notifier.reset, {
                        TAG: /* Error */1,
                        _0: $$Intl.t("Something wrong happened when importing file, please try again.")
                      }, undefined, undefined);
          }
        }));
}

var CartConfig;

var Config;

var Fetch;

export {
  CartConfig ,
  Config ,
  Fetch ,
  OrderCsvParserAndDecoder ,
  OrderCsvImporting_TaxValueNotFound ,
  make ,
}
/* OrderCsvParserAndDecoder Not a pure module */
