// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Form from "../../bundles/Form/Form.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as CatalogPriceLookUpInput from "./CatalogPriceLookUpInput.bs.js";

function get(values, field) {
  switch (field) {
    case /* Name */0 :
        return values.name;
    case /* CapacityUnit */1 :
        return values.capacityUnit;
    case /* CapacityValue */2 :
        return values.capacityValue;
    case /* CapacityPrecision */3 :
        return values.capacityPrecision;
    case /* Ean13 */4 :
        return values.ean13;
    case /* StockKeepingUnit */5 :
        return values.stockKeepingUnit;
    case /* PriceLookUpCode */6 :
        return values.priceLookUpCode;
    case /* InternalCode */7 :
        return values.internalCode;
    case /* AlcoholVolume */8 :
        return values.alcoholVolume;
    case /* Year */9 :
        return values.year;
    case /* Bulk */10 :
        return values.bulk;
    case /* PurchasePrice */11 :
        return values.purchasePrice;
    case /* SupplierId */12 :
        return values.supplierId;
    case /* VariantPrices */13 :
        return values.variantPrices;
    case /* InitialStockQuantity */14 :
        return values.initialStockQuantity;
    case /* InitialStockComment */15 :
        return values.initialStockComment;
    case /* MaxStockThreshold */16 :
        return values.maxStockThreshold;
    case /* MinStockThreshold */17 :
        return values.minStockThreshold;
    case /* StockOrderTriggerThreshold */18 :
        return values.stockOrderTriggerThreshold;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* Name */0 :
        return {
                name: value,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* CapacityUnit */1 :
        return {
                name: values.name,
                capacityUnit: value,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* CapacityValue */2 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: value,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* CapacityPrecision */3 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: value,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* Ean13 */4 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: value,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* StockKeepingUnit */5 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: value,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* PriceLookUpCode */6 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: value,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* InternalCode */7 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: value,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* AlcoholVolume */8 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: value,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* Year */9 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: value,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* Bulk */10 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: value,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* PurchasePrice */11 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: value,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* SupplierId */12 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: value,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* VariantPrices */13 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: value,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* InitialStockQuantity */14 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: value,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* InitialStockComment */15 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: value,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* MaxStockThreshold */16 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: value,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* MinStockThreshold */17 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: value,
                stockOrderTriggerThreshold: values.stockOrderTriggerThreshold
              };
    case /* StockOrderTriggerThreshold */18 :
        return {
                name: values.name,
                capacityUnit: values.capacityUnit,
                capacityValue: values.capacityValue,
                capacityPrecision: values.capacityPrecision,
                ean13: values.ean13,
                stockKeepingUnit: values.stockKeepingUnit,
                priceLookUpCode: values.priceLookUpCode,
                internalCode: values.internalCode,
                alcoholVolume: values.alcoholVolume,
                year: values.year,
                bulk: values.bulk,
                purchasePrice: values.purchasePrice,
                supplierId: values.supplierId,
                variantPrices: values.variantPrices,
                initialStockQuantity: values.initialStockQuantity,
                initialStockComment: values.initialStockComment,
                maxStockThreshold: values.maxStockThreshold,
                minStockThreshold: values.minStockThreshold,
                stockOrderTriggerThreshold: value
              };
    
  }
}

var Lenses = {
  get: get,
  set: set
};

var include = Form.Make(Lenses);

function validateMinStockThreshold(state) {
  if (state.maxStockThreshold === 0 && state.minStockThreshold === 0 && state.stockOrderTriggerThreshold === 0) {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
  var minStockThreshold = state.minStockThreshold;
  if (minStockThreshold > state.stockOrderTriggerThreshold) {
    return {
            TAG: /* Error */1,
            _0: $$Intl.t("The minimum stock should be less or equal to the value triggering a new order.")
          };
  } else if (minStockThreshold >= state.maxStockThreshold) {
    return {
            TAG: /* Error */1,
            _0: $$Intl.t("The minimum stock should be less than the maximum stock.")
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
}

function validateStockOrderTriggerThreshold(state) {
  var exit = 0;
  if (state.maxStockThreshold !== 0 || state.minStockThreshold !== 0) {
    exit = 1;
  } else {
    if (state.stockOrderTriggerThreshold === 0) {
      return {
              TAG: /* Ok */0,
              _0: undefined
            };
    }
    exit = 1;
  }
  if (exit === 1) {
    var maxStockThreshold = state.maxStockThreshold;
    var exit$1 = 0;
    if (maxStockThreshold !== 0) {
      exit$1 = 2;
    } else {
      if (state.stockOrderTriggerThreshold > 0) {
        return {
                TAG: /* Error */1,
                _0: $$Intl.t("You have to define the maximum stock beforehand.")
              };
      }
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (state.stockOrderTriggerThreshold >= maxStockThreshold) {
        return {
                TAG: /* Error */1,
                _0: $$Intl.t("This value should be less than maximum stock value.")
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    }
    
  }
  
}

function validatePriceLookupCode(state) {
  var match = state.priceLookUpCode;
  var value = match.value;
  if (value === undefined) {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
  if (value > CatalogPriceLookUpInput.pluRange[1]) {
    return {
            TAG: /* Error */1,
            _0: $$Intl.t("The PLU code must be between 1 and 9997.")
          };
  }
  if (!Belt_Array.some(match.invalidValues, (function (plu) {
            return plu === value;
          }))) {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
  var match$1 = CatalogPriceLookUpInput.makeValue(state.priceLookUpCode.invalidValues, undefined, undefined);
  if (match$1.TAG === /* Ok */0) {
    return {
            TAG: /* Error */1,
            _0: $$Intl.t("This PLU code is used by another product.")
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: $$Intl.t("There is no more PLU code available.")
          };
  }
}

var schema = [
  {
    TAG: /* StringNotEmpty */1,
    _0: /* Name */0
  },
  {
    TAG: /* Custom */7,
    _0: /* MinStockThreshold */17,
    _1: validateMinStockThreshold
  },
  {
    TAG: /* Custom */7,
    _0: /* StockOrderTriggerThreshold */18,
    _1: validateStockOrderTriggerThreshold
  },
  {
    TAG: /* Custom */7,
    _0: /* PriceLookUpCode */6,
    _1: validatePriceLookupCode
  }
];

var Core = include.Core;

var Schema = include.Schema;

var FormLegacyProvider = include.FormLegacyProvider;

var FormProvider = include.FormProvider;

var InputText = include.InputText;

var InputSuggestionComboBox = include.InputSuggestionComboBox;

var InputSelect = include.InputSelect;

var InputTextArea = include.InputTextArea;

var InputPassword = include.InputPassword;

var InputNumber = include.InputNumber;

var InputOptionalNumber = include.InputOptionalNumber;

var InputSegmentedControls = include.InputSegmentedControls;

var InputRadioGroup = include.InputRadioGroup;

var InputPhone = include.InputPhone;

var InputCheckbox = include.InputCheckbox;

var InputToggleSwitch = include.InputToggleSwitch;

var InputDate = include.InputDate;

var InputDateRange = include.InputDateRange;

var SubmitButton = include.SubmitButton;

var CancelButton = include.CancelButton;

var ResetButton = include.ResetButton;

var ControlEnterKey = include.ControlEnterKey;

var AutoSave = include.AutoSave;

var useFormPropState = include.useFormPropState;

var useField = include.useField;

var useFormState = include.useFormState;

var useFormDispatch = include.useFormDispatch;

var validate = include.validate;

export {
  Lenses ,
  Core ,
  Schema ,
  FormLegacyProvider ,
  FormProvider ,
  InputText ,
  InputSuggestionComboBox ,
  InputSelect ,
  InputTextArea ,
  InputPassword ,
  InputNumber ,
  InputOptionalNumber ,
  InputSegmentedControls ,
  InputRadioGroup ,
  InputPhone ,
  InputCheckbox ,
  InputToggleSwitch ,
  InputDate ,
  InputDateRange ,
  SubmitButton ,
  CancelButton ,
  ResetButton ,
  ControlEnterKey ,
  AutoSave ,
  useFormPropState ,
  useField ,
  useFormState ,
  useFormDispatch ,
  validate ,
  validateMinStockThreshold ,
  validateStockOrderTriggerThreshold ,
  validatePriceLookupCode ,
  schema ,
}
/* include Not a pure module */
