// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var UnknownStringValue = /* @__PURE__ */Caml_exceptions.create("StockActivityReason.UnknownStringValue");

var values = [
  "LOSS",
  "THEFT",
  "DAMAGE",
  "TASTING",
  "OUTDATED_DLUO",
  "TRADE_IN_SUPPLIER",
  "SALE_BY_GLASS",
  "CORKY",
  "OTHER"
];

function toString(reason) {
  if (reason === "DAMAGE") {
    return "DAMAGE";
  } else if (reason === "OUTDATED_DLUO") {
    return "OUTDATED_DLUO";
  } else if (reason === "SALE_BY_GLASS") {
    return "SALE_BY_GLASS";
  } else if (reason === "THEFT") {
    return "THEFT";
  } else if (reason === "TASTING") {
    return "TASTING";
  } else if (reason === "LOSS") {
    return "LOSS";
  } else if (reason === "OTHER") {
    return "OTHER";
  } else if (reason === "TRADE_IN_SUPPLIER") {
    return "TRADE_IN_SUPPLIER";
  } else {
    return "CORKY";
  }
}

function toLabel(reason) {
  if (reason === "DAMAGE") {
    return $$Intl.t("Damage");
  } else if (reason === "OUTDATED_DLUO") {
    return $$Intl.t("Outdated DLUO");
  } else if (reason === "SALE_BY_GLASS") {
    return $$Intl.t("Sale by glass");
  } else if (reason === "THEFT") {
    return $$Intl.t("Theft");
  } else if (reason === "TASTING") {
    return $$Intl.t("Tasting");
  } else if (reason === "LOSS") {
    return $$Intl.t("Loss");
  } else if (reason === "OTHER") {
    return $$Intl.t("Other");
  } else if (reason === "TRADE_IN_SUPPLIER") {
    return $$Intl.t("Supplier trade in");
  } else {
    return $$Intl.t("Corky");
  }
}

function fromStringExn(value) {
  switch (value) {
    case "CORKY" :
        return "CORKY";
    case "DAMAGE" :
        return "DAMAGE";
    case "LOSS" :
        return "LOSS";
    case "OTHER" :
        return "OTHER";
    case "OUTDATED_DLUO" :
        return "OUTDATED_DLUO";
    case "SALE_BY_GLASS" :
        return "SALE_BY_GLASS";
    case "TASTING" :
        return "TASTING";
    case "THEFT" :
        return "THEFT";
    case "TRADE_IN_SUPPLIER" :
        return "TRADE_IN_SUPPLIER";
    default:
      throw {
            RE_EXN_ID: UnknownStringValue,
            Error: new Error()
          };
  }
}

export {
  UnknownStringValue ,
  values ,
  toString ,
  toLabel ,
  fromStringExn ,
}
/* Intl Not a pure module */
