// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as React from "react";
import * as Popover from "../overlays/Popover.bs.js";
import * as TextStyle from "../typography/TextStyle.bs.js";
import * as Touchable from "../../primitives/Touchable.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DayPickerSingle from "./DayPickerSingle.bs.js";
import * as OverlayTriggerView from "../overlays/OverlayTriggerView.bs.js";

function InputDateField(Props) {
  var label = Props.label;
  var requiredOpt = Props.required;
  var errorMessage = Props.errorMessage;
  var disabledFutureDaysOpt = Props.disabledFutureDays;
  var value = Props.value;
  var onChange = Props.onChange;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var disabledFutureDays = disabledFutureDaysOpt !== undefined ? disabledFutureDaysOpt : false;
  var match = Popover.useTrigger(undefined, undefined);
  var popoverTriggerRef = match.ref;
  var popoverAriaProps = match.ariaProps;
  var popover = match.state;
  var match$1 = Hover.use(popoverTriggerRef, undefined);
  var match$2 = React.useState(function () {
        return false;
      });
  var setFocused = match$2[1];
  React.useEffect((function () {
          Curry._1(setFocused, (function (param) {
                  return popover.isOpen;
                }));
        }), [popover.isOpen]);
  var onChange$1 = function (value) {
    Curry._1(onChange, value);
    Curry._1(popover.close, undefined);
  };
  var isDayBlocked = function (day) {
    var day$1 = new Date(day.getTime());
    var now = new Date();
    day$1.setHours(0);
    day$1.setMinutes(0);
    now.setHours(0);
    now.setMinutes(0);
    if (disabledFutureDays) {
      return day$1.getTime() > now.getTime();
    } else {
      return false;
    }
  };
  var tmp = {
    children: value !== undefined ? React.createElement(TextStyle.make, {
            children: $$Intl.dateTimeFormat("long", undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.valFromOption(value))
          }) : React.createElement(TextStyle.make, {
            children: $$Intl.t("Select a date"),
            variation: "normal"
          }),
    preset: {
      NAME: "inputField",
      VAL: {
        required: required,
        errorMessage: errorMessage
      }
    },
    icon: "calendar",
    hovered: match$1[1],
    active: popover.isOpen,
    focused: match$2[0]
  };
  if (label !== undefined) {
    tmp.label = label;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Touchable.make, Touchable.makeProps(React.createElement(OverlayTriggerView.make, tmp), popoverAriaProps.triggerProps, undefined, undefined, undefined, undefined, (function (param) {
                        Curry._1(popover.toggle, undefined);
                      }), undefined, popoverTriggerRef, undefined)), popover.isOpen ? React.createElement(Popover.make, {
                    children: React.createElement(Popover.Dialog.make, {
                          children: React.createElement(DayPickerSingle.make, {
                                value: value,
                                onChange: onChange$1,
                                isDayBlocked: isDayBlocked
                              }),
                          ariaProps: popoverAriaProps.overlayProps,
                          style: {
                            maxWidth: "316px",
                            minHeight: "300px"
                          }
                        }),
                    triggerRef: popoverTriggerRef,
                    state: {
                      isOpen: popover.isOpen,
                      close: popover.close,
                      toggle: popover.toggle
                    },
                    placement: "bottom start",
                    dismissable: false
                  }) : null);
}

var make = React.memo(InputDateField);

export {
  make ,
}
/* make Not a pure module */
