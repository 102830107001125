// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Tooltip from "../../resources/overlays/Tooltip.bs.js";
import * as TableView from "../../resources/tables/TableView.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TooltipIcon from "../../resources/overlays/TooltipIcon.bs.js";
import * as CatalogTableActions from "./CatalogTableActions.bs.js";
import * as CatalogProductStatusBadge from "./CatalogProductStatusBadge.bs.js";
import * as CatalogProductNameTableCell from "./CatalogProductNameTableCell.bs.js";
import * as CatalogVariantStockThresholdCell from "./CatalogVariantStockThresholdCell.bs.js";
import * as PricePurchaseTableEditCellWrapper from "../Price/PricePurchaseTableEditCellWrapper.bs.js";

function keyExtractor(row) {
  return row.id + "-variant";
}

var Row = {
  keyExtractor: keyExtractor
};

var make = React.memo(function (Props) {
      var formattedRetailPrice = Props.formattedRetailPrice;
      var retailPrices = Props.retailPrices;
      var ref = React.useRef(null);
      return React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Inline.make, {
                              children: null,
                              space: "small"
                            }, React.createElement(TextStyle.make, {
                                  children: formattedRetailPrice
                                }), retailPrices.length !== 0 ? React.createElement(TooltipIcon.make, {
                                    children: Belt_Array.map(retailPrices, (function (param) {
                                            var formattedTaxType = $$Intl.t(param.taxIncluded ? "VAT incl." : "VAT excl.");
                                            var formattedPrice = "" + param.name + " " + formattedTaxType + " :  " + param.formattedValue + "\n";
                                            return React.createElement(React.Fragment, undefined, React.createElement(Tooltip.Span.make, {
                                                            text: formattedPrice,
                                                            bold: param.default
                                                          }), React.createElement(Tooltip.Br.make, {
                                                            space: "xxsmall"
                                                          }));
                                          })),
                                    variation: "info",
                                    altTriggerRef: ref
                                  }) : null)), undefined, undefined, ref, undefined));
    });

var RetailPriceDetailsCell = {
  make: make
};

function tableColumns(allShopsFiltered) {
  return [
          {
            key: "variant-name",
            name: $$Intl.t("Name"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 170
              },
              width: {
                NAME: "fr",
                VAL: 1.5
              }
            },
            render: (function (param) {
                var match = param.data;
                var shopName = allShopsFiltered ? match.shopName : undefined;
                var tmp = {
                  cku: match.cku,
                  name: match.name
                };
                if (shopName !== undefined) {
                  tmp.shopName = Caml_option.valFromOption(shopName);
                }
                return React.createElement(CatalogProductNameTableCell.make, tmp);
              })
          },
          {
            key: "variant-purchaseprice",
            name: $$Intl.t("Purchase price"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                var match = param.data;
                var formattedPurchasePrice = match.formattedPurchasePrice;
                if (formattedPurchasePrice === "VARIABLE") {
                  return React.createElement(TextStyle.make, {
                              children: $$Intl.t("Differing"),
                              variation: "normal",
                              size: "small"
                            });
                }
                return React.createElement(PricePurchaseTableEditCellWrapper.make, {
                            value: match.purchasePrice,
                            formattedPurchasePrice: formattedPurchasePrice,
                            bulkUnit: match.bulkUnit,
                            variantId: match.id
                          });
              })
          },
          {
            key: "variant-retailprice",
            name: $$Intl.t("Retail price"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                var match = param.data;
                return React.createElement(make, {
                            formattedRetailPrice: match.formattedRetailPrice,
                            retailPrices: match.retailPrices
                          });
              })
          },
          {
            key: "variant-stock",
            name: $$Intl.t("Stock"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                var match = param.data;
                var stock = match.stock;
                return React.createElement(CatalogVariantStockThresholdCell.make, {
                            stockQuantity: stock.quantity,
                            stockState: stock.state,
                            formattedShopsNames: match.shopName,
                            id: match.id,
                            maxStockThreshold: match.maxStockThreshold,
                            minStockThreshold: match.minStockThreshold,
                            stockOrderTriggerThreshold: match.stockOrderTriggerThreshold,
                            bulk: match.bulk
                          });
              })
          },
          {
            key: "variant-status",
            name: $$Intl.t("Status"),
            render: (function (param) {
                var status = param.data.status;
                if (status !== undefined) {
                  return React.createElement(CatalogProductStatusBadge.make, {
                              value: status
                            });
                } else {
                  return React.createElement(TextStyle.make, {
                              children: $$Intl.t("Differing"),
                              variation: "normal"
                            });
                }
              })
          },
          {
            key: "variant-actions",
            layout: {
              width: {
                NAME: "fr",
                VAL: 0
              },
              alignX: "flexEnd"
            },
            render: (function (param) {
                var match = param.data;
                var tmp = {
                  cku: match.cku,
                  id: match.id
                };
                if (match.status !== undefined) {
                  tmp.status = Caml_option.valFromOption(match.status);
                }
                return React.createElement(CatalogTableActions.make, tmp);
              })
          }
        ];
}

function CatalogProductVariantTableCard(Props) {
  var shopsProductVariants = Props.shopsProductVariants;
  var match = Auth.useScope(undefined);
  var allShopsFiltered;
  allShopsFiltered = match.TAG === /* Organisation */0 ? match.activeShop === undefined : false;
  var columns = React.useMemo((function () {
          return tableColumns(allShopsFiltered);
        }), [allShopsFiltered]);
  var rows = Belt_Array.concatMany(shopsProductVariants);
  return React.createElement(Card.make, {
              children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TableView.make, {
                                data: {
                                  TAG: /* Done */1,
                                  _0: {
                                    TAG: /* Ok */0,
                                    _0: rows
                                  }
                                },
                                columns: columns,
                                keyExtractor: keyExtractor,
                                hideCard: true,
                                maxHeight: 500,
                                placeholderEmptyState: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Inline.make, {
                                                  children: React.createElement(TextStyle.make, {
                                                        children: $$Intl.t("No product variant has been yet recorded"),
                                                        align: "center",
                                                        variation: "normal",
                                                        size: "small"
                                                      }),
                                                  align: "center"
                                                })), "large", "xlarge", undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#f8f8fb", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined))
                              })), undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
              title: $$Intl.t("Product's variants"),
              variation: "table"
            });
}

var make$1 = React.memo(CatalogProductVariantTableCard);

var Config;

export {
  Config ,
  Row ,
  RetailPriceDetailsCell ,
  tableColumns ,
  make$1 as make,
}
/* make Not a pure module */
