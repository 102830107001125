// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LegacyRouter from "../../core/LegacyRouter.bs.js";
import * as CatalogProductEditForm from "./CatalogProductEditForm.bs.js";
import * as CatalogVariantEditUrlState from "./CatalogVariantEditUrlState.bs.js";
import * as CatalogProductEditFormMutations from "./CatalogProductEditFormMutations.bs.js";

function CatalogProductEditFormPageActions(Props) {
  var editionMode = Props.editionMode;
  var updateProduct = CatalogProductEditFormMutations.useUpdateProduct(undefined);
  var navigate = Navigation.useNavigate(undefined);
  var onPressNextStep = React.useCallback((function (param) {
          return function (state) {
            Curry._3(navigate, undefined, Caml_option.some(CatalogVariantEditUrlState.CreateVariantFromProduct.encode(state)), LegacyRouter.routeToPathname(/* Catalog */2) + "/create");
            return Future.value({
                        TAG: /* Ok */0,
                        _0: undefined
                      });
          };
        }), []);
  return React.createElement(Inline.make, {
              children: editionMode ? React.createElement(CatalogProductEditForm.SubmitButton.make, {
                      variation: "success",
                      size: "medium",
                      onSubmit: updateProduct,
                      text: $$Intl.t("Save")
                    }) : React.createElement(CatalogProductEditForm.SubmitButton.make, {
                      variation: "success",
                      size: "medium",
                      onSubmit: onPressNextStep,
                      text: $$Intl.t("Continue")
                    }),
              space: "small"
            });
}

var make = React.memo(CatalogProductEditFormPageActions);

var Mutations;

export {
  Mutations ,
  make ,
}
/* make Not a pure module */
