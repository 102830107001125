// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as $$Text from "../../primitives/Text.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as FontFaces from "../theme/FontFaces.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var styles = $$StyleSheet.create({
      standardView: Style.merge([
            FontFaces.archivoBoldStyle,
            Style.style(undefined, undefined, undefined, "#25243a", undefined, 26, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      compactView: Style.merge([
            FontFaces.archivoRegularStyle,
            Style.style(undefined, undefined, undefined, "#25243a", undefined, 16, undefined, undefined, "_600", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ])
    });

function currencyValue(value, currency) {
  return {
          NAME: "currency",
          VAL: [
            value,
            currency
          ]
        };
}

function percentValue(value) {
  return {
          NAME: "percent",
          VAL: value
        };
}

function decimalValue(value) {
  return {
          NAME: "decimal",
          VAL: value
        };
}

function integerValue(value) {
  return {
          NAME: "integer",
          VAL: value
        };
}

function styleFromParams(variant) {
  return Style.arrayStyle([variant === "compact" ? styles.compactView : styles.standardView]);
}

function formatValue(value) {
  var variant = value.NAME;
  if (variant === "percent") {
    return $$Intl.percentFormat(2, 2, value.VAL);
  }
  if (variant === "integer") {
    return $$Intl.decimalFormat(0, 0, value.VAL);
  }
  if (variant !== "currency") {
    return $$Intl.decimalFormat(2, 2, value.VAL);
  }
  var match = value.VAL;
  return $$Intl.currencyFormat(match[1], 2, 2, match[0]);
}

function ValueIndicator(Props) {
  var value = Props.value;
  var variantOpt = Props.variant;
  var variant = variantOpt !== undefined ? variantOpt : "standard";
  var formattedValue = formatValue(value);
  return React.createElement($$Text.make, $$Text.makeProps(formattedValue, Caml_option.some(styleFromParams(variant)), undefined, undefined, undefined));
}

var make = React.memo(ValueIndicator);

make.displayName = "ValueIndicator";

export {
  currencyValue ,
  percentValue ,
  decimalValue ,
  integerValue ,
  make ,
}
/* styles Not a pure module */
