// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsonCodec from "../../primitives/JsonCodec.bs.js";
import * as QueryString from "../../primitives/QueryString.bs.js";

var baseRoute = "/catalog";

function productRoute(cku) {
  return baseRoute + "/product" + cku;
}

function productEditRoute(cku) {
  return productRoute(cku) + "/edit";
}

function productRedirectRoute(cku) {
  return baseRoute + "/product/redirect" + cku;
}

function productDuplicationRoute(cku) {
  return baseRoute + "/product/duplication" + cku;
}

function variantRoute(cku) {
  return baseRoute + "/" + cku;
}

function variantEditRoute(cku) {
  return variantRoute(cku) + "/edit";
}

function variantCreateRoute(cku) {
  return variantRoute(cku) + "/create";
}

function variantDuplicationRoute(cku) {
  return baseRoute + "/duplication" + cku;
}

function variantRedirectByIdRoute(id) {
  return baseRoute + "/redirect" + id;
}

function variantStockActivitiesRoute(cku) {
  return variantRoute(cku) + "/stockactivities";
}

function variantOrderSuppliesRoute(cku) {
  return variantRoute(cku) + "/ordersupplies";
}

var labelsCreateRoute = baseRoute + "/labels/create";

var labelsCreateSettingsRoute = labelsCreateRoute + "/settings";

var inventoryImportRoute = baseRoute + "/inventory/import";

function encoder(param) {
  return [
          param.variantIdfromLabelEditSettings,
          param.fromPathname
        ];
}

function decoder(param) {
  return {
          TAG: /* Ok */0,
          _0: {
            variantIdfromLabelEditSettings: param[0],
            fromPathname: param[1]
          }
        };
}

var value = JsonCodec.object2(encoder, decoder, JsonCodec.optional(JsonCodec.field("variantIdfromLabelEditSettings", JsonCodec.string)), JsonCodec.optional(JsonCodec.field("fromPathname", JsonCodec.string)));

var Codecs = {
  encoder: encoder,
  decoder: decoder,
  value: value
};

function encode(state) {
  return QueryString.stringify(JsonCodec.encodeWith(state, value));
}

function decode(query) {
  var state = JsonCodec.decodeWith(QueryString.parse(query), value);
  if (state.TAG === /* Ok */0) {
    return state._0;
  } else {
    return {
            variantIdfromLabelEditSettings: undefined,
            fromPathname: undefined
          };
  }
}

var CatalogLabelQueryString = {
  Codecs: Codecs,
  encode: encode,
  decode: decode
};

export {
  baseRoute ,
  productRoute ,
  productEditRoute ,
  productRedirectRoute ,
  productDuplicationRoute ,
  variantRoute ,
  variantEditRoute ,
  variantCreateRoute ,
  variantDuplicationRoute ,
  variantRedirectByIdRoute ,
  variantStockActivitiesRoute ,
  variantOrderSuppliesRoute ,
  labelsCreateRoute ,
  labelsCreateSettingsRoute ,
  inventoryImportRoute ,
  CatalogLabelQueryString ,
}
/* value Not a pure module */
