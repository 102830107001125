// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as SupplierCivility from "../Supplier/SupplierCivility.bs.js";
import * as OrderSupplierInformationCard__Config__Formatters from "./OrderSupplierInformationCard__Config__Formatters.bs.js";

function supplierContactFromData(data) {
  return Belt_Option.map(Belt_Array.getBy(data.contacts.edges, (function (param) {
                    return param.node.isDefault;
                  })), (function (param) {
                var contact = param.node;
                return {
                        formattedName: OrderSupplierInformationCard__Config__Formatters.makeContactName(Belt_Option.map(contact.civility, SupplierCivility.toShortLabel), contact.lastName, contact.firstName),
                        email: contact.email,
                        phoneNumber: contact.phoneNumber,
                        mobileNumber: contact.mobileNumber
                      };
              }));
}

function supplierLocationFromData(data) {
  return Belt_Option.map(Belt_Array.get(data.locations.edges, 0), (function (param) {
                var $$location = param.node;
                return {
                        recipient: $$location.recipient,
                        address: $$location.address,
                        formattedCity: OrderSupplierInformationCard__Config__Formatters.makeCity($$location.postalCode, $$location.city),
                        country: Belt_Option.getWithDefault($$location.country, "France")
                      };
              }));
}

var Formatter;

export {
  Formatter ,
  supplierContactFromData ,
  supplierLocationFromData ,
}
/* SupplierCivility Not a pure module */
