// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Animated from "../../primitives/Animated.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";
import * as Animated$ReactNative from "rescript-react-native/src/apis/Animated.bs.js";
import Spinner_neutralPng from "./spinner_neutral.png";
import Spinner_primaryPng from "./spinner_primary.png";
import Spinner_successPng from "./spinner_success.png";

var primaryImageUri = Spinner_primaryPng;

var neutralImageUri = Spinner_neutralPng;

var successImageUri = Spinner_successPng;

function Spinner(Props) {
  var variationOpt = Props.variation;
  var sizeOpt = Props.size;
  var variation = variationOpt !== undefined ? variationOpt : "primary";
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  var animatedValue = React.useRef(new (ReactNative.Animated.Value)(0));
  var loopAnimation = React.useRef(Animated.loop(Animated.timing(animatedValue.current, {
                toValue: 1,
                easing: ReactNative.Easing.bezier(0.17, 0.67, 0.83, 0.67),
                duration: 1200,
                useNativeDriver: false
              })));
  React.useLayoutEffect((function () {
          loopAnimation.current.start(undefined);
          return (function (param) {
                    loopAnimation.current.stop();
                  });
        }), []);
  var rotateInterpolate = Animated$ReactNative.Value.interpolate(animatedValue.current, {
        inputRange: [
          0,
          1
        ],
        outputRange: [
          "0deg",
          "360deg"
        ]
      });
  var imageSource = {
    uri: variation === "primary" ? primaryImageUri : (
        variation === "success" ? successImageUri : neutralImageUri
      )
  };
  return React.createElement(Animated$ReactNative.$$Image.make, {
              accessibilityLabel: "spinner",
              source: imageSource,
              style: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [{
                      rotate: rotateInterpolate
                    }], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(size)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(size)), undefined, undefined)
            });
}

var make = React.memo(Spinner);

export {
  make ,
}
/* primaryImageUri Not a pure module */
