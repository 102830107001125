// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as React from "react";
import * as Inline from "../layout-and-structure/Inline.bs.js";
import * as Offset from "../layout-and-structure/Offset.bs.js";
import * as Tooltip from "../overlays/Tooltip.bs.js";
import * as ReactAria from "../../externals/ReactAria.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactAria$1 from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TooltipIcon from "../overlays/TooltipIcon.bs.js";
import * as ReactStately from "react-stately";
import * as Stylex from "@stylexjs/stylex";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";

var styles = Stylex.create({
      RadioCardItem_RadioButton_root: {
        background: "white",
        borderRadius: "50%",
        outline: "solid",
        transition: "outline-color .1s ease-in",
        height: "16px",
        minWidth: "16px",
        width: "16px"
      }
    });

function styleProps(selected, disabled, hovered, pressed) {
  var tmp;
  var exit = 0;
  if (selected) {
    if (disabled) {
      exit = 1;
    } else {
      tmp = {
        outlineOffset: "-5.5px",
        outlineWidth: "5px"
      };
    }
  } else if (pressed && !disabled) {
    tmp = {
      outlineOffset: "-3.5px",
      outlineWidth: "3px"
    };
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = {
      outlineOffset: "-2.5px",
      outlineWidth: "1.5px"
    };
  }
  return Stylex.props(styles.RadioCardItem_RadioButton_root, tmp, {
              outlineColor: disabled ? "#d7d7e0" : (
                  selected ? "#a01e72" : (
                      hovered ? "#8a0262" : "#a2a1b0"
                    )
                )
            }, {
              transition: selected ? "outline-width .045s cubic-bezier(0, 0, 0, 1)" : "none"
            }, {
              background: disabled ? "#f8f8fb" : "white"
            });
}

var make = React.memo(function (Props) {
      var selected = Props.selected;
      var disabled = Props.disabled;
      var hovered = Props.hovered;
      var pressed = Props.pressed;
      var match = styleProps(selected, disabled, hovered, pressed);
      var tmp = {};
      if (match.className !== undefined) {
        tmp.className = Caml_option.valFromOption(match.className);
      }
      if (match.style !== undefined) {
        tmp.style = Caml_option.valFromOption(match.style);
      }
      return React.createElement("div", tmp);
    });

var styles$1 = Stylex.create({
      RadioCardItem_root: {
        boxSizing: "border-box",
        borderRadius: "5px",
        outline: "solid",
        transition: "outline-color 0.05s ease-out",
        flex: "1",
        padding: "18px"
      },
      RadioCardItem_container: {
        display: "flex",
        flexDirection: "row",
        gap: "10px"
      },
      RadioCardItem_textContainer: {
        display: "flex",
        flex: "1",
        flexDirection: "column",
        gap: "6px"
      },
      RadioCardItem_title: {
        font: "normal 700 16px \"Archivo\"",
        lineHeight: "16px",
        wordBreak: "normal"
      },
      RadioCardItem_description: {
        font: "normal 400 12px \"Libre Franklin\"",
        lineHeight: "14px"
      }
    });

function styleProps$1(selected, disabled, hovered, pressed) {
  var tmp;
  var exit = 0;
  exit = selected ? (
      pressed || disabled ? 1 : 2
    ) : (
      pressed && !disabled ? 2 : 1
    );
  switch (exit) {
    case 1 :
        tmp = {
          outlineOffset: "-1.5px",
          outlineWidth: "1.5px"
        };
        break;
    case 2 :
        tmp = {
          outlineOffset: "-2px",
          outlineWidth: "2px"
        };
        break;
    
  }
  return Stylex.props(styles$1.RadioCardItem_root, tmp, {
              outlineColor: disabled ? "#d7d7e0" : (
                  selected || hovered ? "#a01e72" : "#a2a1b0"
                )
            }, {
              background: disabled ? "#f8f8fb" : "white"
            });
}

function titleStyleProps(disabled) {
  return Stylex.props(styles$1.RadioCardItem_title, {
              color: disabled ? "#8c8B9b" : "#25243a"
            });
}

function descriptionStyleProps(disabled) {
  return Stylex.props(styles$1.RadioCardItem_description, {
              color: disabled ? "#8c8B9b" : "#25243a"
            });
}

var make$1 = React.memo(function (Props) {
      var title = Props.title;
      var tooltip = Props.tooltip;
      var description = Props.description;
      var groupState = Props.groupState;
      var selected = Props.selected;
      var disabledOpt = Props.disabled;
      var value = Props.value;
      var disabled = disabledOpt !== undefined ? disabledOpt : false;
      var match = Hover.use(undefined, undefined);
      var hovered = match[1];
      var match$1 = ReactAria$1.usePress({
            allowTextSelectionOnPress: false
          });
      var pressed = match$1.isPressed;
      var inputDomRef = React.useRef(null);
      var match$2 = styleProps$1(selected, disabled, hovered, pressed);
      var match$3 = Stylex.props(styles$1.RadioCardItem_container);
      var match$4 = Stylex.props(styles$1.RadioCardItem_textContainer);
      var match$5 = titleStyleProps(disabled);
      var match$6 = descriptionStyleProps(disabled);
      var tmp = {};
      if (match$3.className !== undefined) {
        tmp.className = Caml_option.valFromOption(match$3.className);
      }
      var tmp$1 = {};
      if (match$4.className !== undefined) {
        tmp$1.className = Caml_option.valFromOption(match$4.className);
      }
      var tmp$2 = {};
      if (match$5.className !== undefined) {
        tmp$2.className = Caml_option.valFromOption(match$5.className);
      }
      if (match$5.style !== undefined) {
        tmp$2.style = Caml_option.valFromOption(match$5.style);
      }
      var tmp$3;
      if (description !== undefined) {
        var tmp$4 = {};
        if (match$6.className !== undefined) {
          tmp$4.className = Caml_option.valFromOption(match$6.className);
        }
        if (match$6.style !== undefined) {
          tmp$4.style = Caml_option.valFromOption(match$6.style);
        }
        tmp$3 = React.createElement("div", tmp$4, description);
      } else {
        tmp$3 = null;
      }
      var children = React.createElement("div", tmp, React.createElement(make, {
                selected: selected,
                disabled: disabled,
                hovered: hovered,
                pressed: pressed
              }), React.createElement("div", tmp$1, React.createElement(Inline.make, {
                    children: null,
                    align: "spaceBetween",
                    grow: true
                  }, React.createElement("span", tmp$2, title), tooltip !== undefined ? React.createElement(Offset.make, {
                          children: React.createElement(TooltipIcon.make, {
                                children: React.createElement(Tooltip.Span.make, {
                                      text: tooltip
                                    })
                              }),
                          top: -8.5,
                          right: 0,
                          width: 10
                        }) : null), tmp$3));
      var props_isDisabled = disabled;
      var props = {
        value: value,
        children: children,
        isDisabled: props_isDisabled
      };
      var match$7 = ReactAria$1.useRadio(props, groupState, inputDomRef);
      var tmp$5 = {
        ref: match[0]
      };
      if (match$2.className !== undefined) {
        tmp$5.className = Caml_option.valFromOption(match$2.className);
      }
      if (match$2.style !== undefined) {
        tmp$5.style = Caml_option.valFromOption(match$2.style);
      }
      return React.createElement(ReactAria.Spread.make, {
                  props: match$1.pressProps,
                  children: React.createElement("label", tmp$5, React.createElement(ReactAria$1.VisuallyHidden, {
                            children: React.createElement(ReactAria.Spread.make, {
                                  props: match$7.inputProps,
                                  children: React.createElement("input", {
                                        ref: inputDomRef
                                      })
                                })
                          }), children)
                });
    });

var styles$2 = Stylex.create({
      root: {
        display: "flex",
        gap: "16px"
      }
    });

function styleProps$2(orientation) {
  return Stylex.props(styles$2.root, {
              flexDirection: orientation === "horizontal" ? "row" : "column"
            });
}

function RadioCardGroup(Props) {
  var orientationOpt = Props.orientation;
  var items = Props.items;
  var value = Props.value;
  var disabledOpt = Props.disabled;
  var onChange = Props.onChange;
  var orientation = orientationOpt !== undefined ? orientationOpt : "horizontal";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var props_orientation = orientation;
  var props_isDisabled = disabled;
  var props = {
    value: value,
    orientation: props_orientation,
    isDisabled: props_isDisabled,
    onChange: onChange
  };
  var state = ReactStately.useRadioGroupState(props);
  var match = ReactAria$1.useRadioGroup(props, state);
  React.useEffect((function () {
          if (!Belt_Array.some(items, (function (item) {
                    return item.value === value;
                  }))) {
            Curry._1(state.setSelectedValue, Js_null_undefined.fromOption(Belt_Option.map(Belt_Array.get(items, 0), (function (item) {
                            return item.value;
                          }))));
          }
          
        }), [
        value,
        items
      ]);
  var match$1 = styleProps$2(orientation);
  var tmp = {};
  if (match$1.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match$1.className);
  }
  if (match$1.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match$1.style);
  }
  return React.createElement(ReactAria.Spread.make, {
              props: match.radioGroupProps,
              children: React.createElement("div", tmp, Belt_Array.map(items, (function (item) {
                          var value = item.value;
                          var disabled$1 = Belt_Option.getWithDefault(item.disabled, false) || disabled;
                          var selected = state.selectedValue === value;
                          var tmp = {
                            title: item.title,
                            groupState: state,
                            selected: selected,
                            disabled: disabled$1,
                            value: value,
                            key: value
                          };
                          if (item.tooltip !== undefined) {
                            tmp.tooltip = Caml_option.valFromOption(item.tooltip);
                          }
                          if (item.description !== undefined) {
                            tmp.description = Caml_option.valFromOption(item.description);
                          }
                          return React.createElement(make$1, tmp);
                        })))
            });
}

var make$2 = React.memo(RadioCardGroup);

export {
  make$2 as make,
}
/* styles Not a pure module */
