// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../bundles/Auth/Auth.bs.js";
import * as React from "react";
import * as Navigation from "../primitives/Navigation.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ApolloConfig from "./ApolloConfig.bs.js";

function Providers(Props) {
  var children = Props.children;
  var history = Props.history;
  var auth = Props.auth;
  var tmp = {
    children: React.createElement(ApolloConfig.Provider.make, {
          children: children
        })
  };
  if (auth !== undefined) {
    tmp.initialState = Caml_option.valFromOption(auth);
  }
  var tmp$1 = {
    children: React.createElement(Auth.Provider.make, tmp)
  };
  if (history !== undefined) {
    tmp$1.history = Caml_option.valFromOption(history);
  }
  return React.createElement(Navigation.Provider.make, tmp$1);
}

var make = Providers;

export {
  make ,
}
/* Auth Not a pure module */
