// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

function cellFromIndex(index, value) {
  switch (index) {
    case 0 :
        return {
                TAG: /* Reference */0,
                _0: value
              };
    case 1 :
        return {
                TAG: /* Quantity */1,
                _0: value
              };
    case 2 :
        return {
                TAG: /* Price */2,
                _0: value
              };
    default:
      return /* Unknown */0;
  }
}

function floatFromString(value) {
  var value$1 = Belt_Float.fromString(value.replace(",", "."));
  if (value$1 !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: value$1
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
}

var referenceRegex = /^[\w-]+$/;

var quantityAndPriceRegex = /^(?:(?:\d+(?:,\d{1,3})?(?:\.\d{1,3})?)|(?:.\d+))$/;

function validateCellFromLabel(cell) {
  if (typeof cell === "number") {
    return {
            TAG: /* Error */1,
            _0: "invalid column"
          };
  }
  switch (cell.TAG | 0) {
    case /* Reference */0 :
        var test = referenceRegex.test(cell._0);
        if (test) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: "invalid reference (SKU)"
                };
        }
    case /* Quantity */1 :
        var value = cell._0;
        var test$1 = quantityAndPriceRegex.test(value);
        var test$2 = test$1 && Belt_Result.mapWithDefault(floatFromString(value), false, (function (quantity) {
                return quantity > 0;
              }));
        if (test$2) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: "invalid quantity"
                };
        }
    case /* Price */2 :
        var valueOptional = cell._0;
        var test$3 = valueOptional !== undefined ? quantityAndPriceRegex.test(valueOptional) : true;
        if (test$3) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: "invalid purchasing price"
                };
        }
    
  }
}

function entryValueFromOption(entry) {
  if (entry !== undefined) {
    return Caml_option.valFromOption(entry);
  } else {
    return Pervasives.failwith("Unexpected error when parsing an Order CSV entry.");
  }
}

var StringToQuantityFailure = /* @__PURE__ */Caml_exceptions.create("OrderCsvImporting__Config.StringToQuantityFailure");

var StringToUnitPriceFailure = /* @__PURE__ */Caml_exceptions.create("OrderCsvImporting__Config.StringToUnitPriceFailure");

function rowFromEntryExn(entry) {
  var reference = entryValueFromOption(Belt_Array.get(entry, 0));
  var quantity = entryValueFromOption(Belt_Array.get(entry, 1));
  var price = Belt_Array.get(entry, 2);
  var value = floatFromString(quantity);
  var tmp;
  if (value.TAG === /* Ok */0) {
    tmp = value._0;
  } else {
    throw {
          RE_EXN_ID: StringToQuantityFailure,
          Error: new Error()
        };
  }
  return {
          sku: reference,
          quantity: tmp,
          unitPrice: Belt_Option.map(price, (function (price) {
                  var value = floatFromString(price);
                  if (value.TAG === /* Ok */0) {
                    return value._0;
                  }
                  throw {
                        RE_EXN_ID: StringToUnitPriceFailure,
                        Error: new Error()
                      };
                }))
        };
}

var ParserConfig = {
  cellFromEntryIndex: cellFromIndex,
  validateCellFromLabel: validateCellFromLabel,
  rowFromEntry: rowFromEntryExn
};

export {
  cellFromIndex ,
  floatFromString ,
  referenceRegex ,
  quantityAndPriceRegex ,
  validateCellFromLabel ,
  entryValueFromOption ,
  StringToQuantityFailure ,
  StringToUnitPriceFailure ,
  rowFromEntryExn ,
  ParserConfig ,
}
/* No side effect */
