// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Env from "../../core/Env.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Json from "../../primitives/Json.bs.js";
import * as Page from "../../resources/layout-and-structure/Page.bs.js";
import * as Badge from "../../resources/feedback-indicators/Badge.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Strong from "../../resources/typography/Strong.bs.js";
import * as $$Request from "../../core/Request.bs.js";
import * as SearchBar from "../../resources/selection-and-input/SearchBar.bs.js";
import * as TableView from "../../resources/tables/TableView.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as BarControl from "../../resources/layout-and-structure/BarControl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as EmptyState from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as AsyncResult from "../../primitives/AsyncResult.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Illustration from "../../resources/images-and-icons/Illustration.bs.js";
import * as KbdClipboard from "../../resources/typography/KbdClipboard.bs.js";
import * as LegacyPagination from "../../resources/navigation/LegacyPagination.bs.js";

function decodeResultItem(json) {
  var dict = Json.decodeDict(json);
  var match = Json.flatDecodeDictFieldString(dict, "id");
  var match$1 = Json.flatDecodeDictFieldString(dict, "name");
  var match$2 = Json.flatDecodeDictFieldString(dict, "corporateName");
  var match$3 = Json.flatDecodeDictFieldString(dict, "legalRepresentative");
  var match$4 = Json.flatDecodeDictFieldString(dict, "email");
  var match$5 = Json.flatDecodeDictFieldString(dict, "type");
  if (match !== undefined && match$1 !== undefined && match$2 !== undefined && match$3 !== undefined && match$4 !== undefined) {
    return {
            id: match,
            name: match$1,
            corporateName: match$2,
            legalRepresentative: match$3,
            email: match$4,
            type_: match$5
          };
  }
  
}

function decodeResult(json) {
  var resultItems = Json.decodeArray(json);
  if (resultItems !== undefined) {
    return Belt_Array.keepMap(resultItems, decodeResultItem);
  } else {
    return [];
  }
}

var endpoint = Env.gatewayUrl(undefined) + "/admin/shops";

function make(param) {
  return Future.mapOk($$Request.make("GET", undefined, undefined, undefined, endpoint), undefined, decodeResult);
}

var QueryAllShopsRequest = {
  decodeResultItem: decodeResultItem,
  decodeResult: decodeResult,
  endpoint: endpoint,
  make: make
};

function keyExtractor(row) {
  return row.id;
}

function sanitize(string) {
  return string.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, "");
}

function match(row, query) {
  if (query === "" || row.id === query || sanitize(row.name).includes(sanitize(query)) || sanitize(row.corporateName).includes(sanitize(query)) || sanitize(row.legalRepresentative).includes(sanitize(query))) {
    return true;
  } else {
    return sanitize(row.email).includes(sanitize(query));
  }
}

function fromQueryItem(queryItem) {
  var match = queryItem.type_;
  var tmp;
  if (match !== undefined) {
    switch (match) {
      case "AFFILIATED" :
          tmp = "AFFILIATED";
          break;
      case "FRANCHISED" :
          tmp = "FRANCHISED";
          break;
      case "INTEGRATED" :
          tmp = "INTEGRATED";
          break;
      case "WAREHOUSE" :
          tmp = "WAREHOUSE";
          break;
      default:
        tmp = "INDEPENDENT";
    }
  } else {
    tmp = "INDEPENDENT";
  }
  return {
          id: queryItem.id,
          name: queryItem.name,
          corporateName: queryItem.corporateName,
          legalRepresentative: queryItem.legalRepresentative,
          email: queryItem.email,
          kind: tmp
        };
}

var TableRow = {
  keyExtractor: keyExtractor,
  sanitize: sanitize,
  match: match,
  fromQueryItem: fromQueryItem
};

function totalPages(rows) {
  return Math.ceil(rows.length / 10) | 0;
}

function search(rows, searchQuery) {
  return Belt_Array.keep(rows, (function (row) {
                return match(row, searchQuery);
              }));
}

function paginate(rows, currentPage) {
  return Belt_Array.slice(rows, Math.imul(currentPage - 1 | 0, 10), 10);
}

function fromQueryAllShops(queryAllShops) {
  return Belt_Array.map(queryAllShops, fromQueryItem);
}

var TableRows = {
  rowsPerPage: 10,
  totalPages: totalPages,
  search: search,
  paginate: paginate,
  fromQueryAllShops: fromQueryAllShops
};

var tableColumns = [
  {
    key: "name",
    name: $$Intl.t("Name"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 260
      },
      width: {
        NAME: "fr",
        VAL: 1.5
      }
    },
    render: (function (param) {
        var match = param.data;
        return React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Stack.make, {
                                children: null,
                                space: "xxsmall"
                              }, React.createElement(Strong.make, {
                                    children: match.name
                                  }), React.createElement(TextStyle.make, {
                                    children: match.corporateName,
                                    variation: "normal",
                                    size: "xxsmall"
                                  }))), undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
      })
  },
  {
    key: "legal-representative",
    name: $$Intl.t("Legal representative"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 160
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle.make, {
                    children: param.data.legalRepresentative
                  });
      })
  },
  {
    key: "email",
    name: $$Intl.t("Email"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 320
      },
      margin: "large"
    },
    render: (function (param) {
        return React.createElement(TextStyle.make, {
                    children: param.data.email
                  });
      })
  },
  {
    key: "kind",
    name: $$Intl.t("Kind"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 160
      }
    },
    render: (function (param) {
        var kind = param.data.kind;
        if (kind === "WAREHOUSE") {
          return React.createElement(Badge.make, {
                      children: $$Intl.t("Warehouse"),
                      variation: "neutral"
                    });
        } else if (kind === "INTEGRATED") {
          return React.createElement(Badge.make, {
                      children: $$Intl.t("Integrated"),
                      variation: "primary"
                    });
        } else if (kind === "INDEPENDENT") {
          return React.createElement(Badge.make, {
                      children: $$Intl.t("Independent"),
                      variation: "information"
                    });
        } else if (kind === "AFFILIATED") {
          return React.createElement(Badge.make, {
                      children: $$Intl.t("Affiliated"),
                      variation: "important"
                    });
        } else {
          return React.createElement(Badge.make, {
                      children: $$Intl.t("Franchised"),
                      variation: "warning"
                    });
        }
      })
  },
  {
    key: "action",
    name: $$Intl.t("Copy ID"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 160
      },
      alignX: "flexEnd"
    },
    render: (function (param) {
        return React.createElement(KbdClipboard.make, {
                    cropValueAt: 10,
                    value: param.data.id
                  });
      })
  }
];

var initialState_asyncResult = AsyncResult.notAsked(undefined);

var initialState = {
  searchQuery: "",
  currentPage: 1,
  asyncResult: initialState_asyncResult
};

function make$1(prevState, action) {
  switch (action.TAG | 0) {
    case /* SearchQueryChanged */0 :
        return {
                searchQuery: action._0,
                currentPage: 1,
                asyncResult: prevState.asyncResult
              };
    case /* AsyncResultGet */1 :
        return {
                searchQuery: prevState.searchQuery,
                currentPage: 1,
                asyncResult: action._0
              };
    case /* Paginated */2 :
        var totalPages = action._1;
        var match = prevState.currentPage;
        var tmp;
        switch (action._0) {
          case /* First */0 :
              tmp = 1;
              break;
          case /* Prev */1 :
              tmp = match !== 1 ? prevState.currentPage - 1 | 0 : 1;
              break;
          case /* Next */2 :
              tmp = match >= totalPages ? match : prevState.currentPage + 1 | 0;
              break;
          case /* Last */3 :
              tmp = totalPages;
              break;
          
        }
        return {
                searchQuery: prevState.searchQuery,
                currentPage: tmp,
                asyncResult: prevState.asyncResult
              };
    
  }
}

var Reducer = {
  initialState: initialState,
  make: make$1
};

function AdminShopListPage(Props) {
  var mounted = React.useRef(false);
  var match = React.useReducer(make$1, initialState);
  var state = match[0];
  var currentPage = state.currentPage;
  var searchQuery = state.searchQuery;
  var dispatch = match[1];
  React.useEffect((function () {
          if (mounted.current) {
            return ;
          }
          var request = Future.mapError(make(undefined), undefined, (function (param) {
                  
                }));
          Curry._1(dispatch, {
                TAG: /* AsyncResultGet */1,
                _0: AsyncResult.loading(undefined)
              });
          Future.get(request, (function (result) {
                  Curry._1(dispatch, {
                        TAG: /* AsyncResultGet */1,
                        _0: AsyncResult.done(result)
                      });
                }));
          return (function (param) {
                    Future.cancel(request);
                  });
        }), []);
  React.useEffect((function () {
          mounted.current = true;
          return (function (param) {
                    mounted.current = false;
                  });
        }), []);
  var tableRows = AsyncResult.mapOk(state.asyncResult, fromQueryAllShops);
  var searchedTableRows = AsyncResult.mapOk(tableRows, (function (rows) {
          return search(rows, searchQuery);
        }));
  var totalPages$1;
  if (typeof searchedTableRows === "number") {
    totalPages$1 = 1;
  } else if (searchedTableRows.TAG === /* Reloading */0) {
    var rows = searchedTableRows._0;
    totalPages$1 = rows.TAG === /* Ok */0 ? totalPages(rows._0) : 1;
  } else {
    var rows$1 = searchedTableRows._0;
    totalPages$1 = rows$1.TAG === /* Ok */0 ? totalPages(rows$1._0) : 1;
  }
  var searchedAndPaginatedTableRows = AsyncResult.mapOk(searchedTableRows, (function (rows) {
          return paginate(rows, currentPage);
        }));
  var onRequestSearch = function (queryString) {
    Curry._1(dispatch, {
          TAG: /* SearchQueryChanged */0,
          _0: queryString
        });
  };
  var onRequestPaginate = function (paginateAction) {
    Curry._1(dispatch, {
          TAG: /* Paginated */2,
          _0: paginateAction,
          _1: totalPages$1
        });
  };
  var match$1 = state.asyncResult;
  var placeholderEmptyState;
  if (typeof searchedAndPaginatedTableRows === "number" || searchedAndPaginatedTableRows.TAG !== /* Done */1) {
    placeholderEmptyState = EmptyState.error;
  } else {
    var match$2 = searchedAndPaginatedTableRows._0;
    if (match$2.TAG === /* Ok */0 && !(match$2._0.length !== 0 || typeof match$1 === "number" || match$1.TAG !== /* Done */1)) {
      var rows$2 = match$1._0;
      placeholderEmptyState = rows$2.TAG === /* Ok */0 && rows$2._0.length !== 0 ? React.createElement(EmptyState.make, {
              illustration: Illustration.notFound,
              title: $$Intl.t("No result were found."),
              text: $$Intl.t("Try again with another keyword or:"),
              children: React.createElement(Button.make, Button.makeProps($$Intl.t("Clear search query"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                          Curry._1(dispatch, {
                                TAG: /* SearchQueryChanged */0,
                                _0: ""
                              });
                        }), undefined, undefined, undefined))
            }) : EmptyState.error;
    } else {
      placeholderEmptyState = EmptyState.error;
    }
  }
  var searchBar = React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(SearchBar.make, {
                    placeholder: $$Intl.t("Search a shop"),
                    value: searchQuery,
                    onChange: onRequestSearch
                  })), "large", undefined, undefined, "xmedium", undefined, undefined, undefined, undefined, undefined, undefined, undefined));
  return React.createElement(Page.make, {
              children: null,
              variation: "compact",
              title: $$Intl.t("Shops"),
              subtitle: $$Intl.t("List of shops"),
              renderTitleEnd: (function (param) {
                  return React.createElement(Badge.make, {
                              children: $$Intl.t("Support"),
                              variation: "primary"
                            });
                })
            }, React.createElement(BarControl.make, {}), React.createElement(TableView.make, {
                  data: searchedAndPaginatedTableRows,
                  columns: tableColumns,
                  keyExtractor: keyExtractor,
                  placeholderEmptyState: placeholderEmptyState,
                  searchBar: searchBar
                }), React.createElement(LegacyPagination.make, {
                  currentPage: currentPage,
                  totalPages: totalPages$1,
                  onRequestPaginate: onRequestPaginate
                }));
}

var make$2 = React.memo(AdminShopListPage);

export {
  QueryAllShopsRequest ,
  TableRow ,
  TableRows ,
  tableColumns ,
  Reducer ,
  make$2 as make,
}
/* endpoint Not a pure module */
