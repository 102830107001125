// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as Icon from "../../resources/images-and-icons/Icon.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as Title from "../../resources/typography/Title.bs.js";
import * as React from "react";
import * as Banner from "../../resources/feedback-indicators/Banner.bs.js";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Touchable from "../../primitives/Touchable.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as InlineText from "../../resources/layout-and-structure/InlineText.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as KbdClipboard from "../../resources/typography/KbdClipboard.bs.js";
import * as CatalogVariant from "./CatalogVariant.bs.js";

var make = React.memo(function (Props) {
      var children = Props.children;
      var label = Props.label;
      if (children !== undefined) {
        return React.createElement(InlineText.make, {
                    children: null
                  }, React.createElement(TextStyle.make, {
                        children: label,
                        variation: "normal"
                      }), React.createElement(TextStyle.make, {
                        children: " "
                      }), React.createElement(TextStyle.make, {
                        children: children
                      }));
      } else {
        return null;
      }
    });

var InfoTextLine = {
  make: make
};

var make$1 = React.memo(function (Props) {
      var variant = Props.variant;
      var displayShopNameOpt = Props.displayShopName;
      var displayVariantIdOpt = Props.displayVariantId;
      var displayShopName = displayShopNameOpt !== undefined ? displayShopNameOpt : true;
      var displayVariantId = displayVariantIdOpt !== undefined ? displayVariantIdOpt : false;
      if (variant === undefined) {
        return null;
      }
      var tmp;
      var exit = 0;
      if (variant.ean13 !== undefined || variant.stockKeepingUnit !== undefined || variant.priceLookUpCode !== undefined || variant.internalCode !== undefined) {
        exit = 1;
      } else {
        tmp = null;
      }
      if (exit === 1) {
        tmp = React.createElement(React.Fragment, undefined, React.createElement(make, {
                  children: variant.ean13,
                  label: $$Intl.t("Bar code")
                }), React.createElement(make, {
                  children: variant.stockKeepingUnit,
                  label: "SKU"
                }), React.createElement(make, {
                  children: Belt_Option.map(variant.priceLookUpCode, (function (prim) {
                          return String(prim);
                        })),
                  label: "PLU"
                }), React.createElement(make, {
                  children: variant.internalCode,
                  label: $$Intl.t("Internal code")
                }));
      }
      var match = variant.id;
      var tmp$1 = match === "" || !displayVariantId ? null : React.createElement(KbdClipboard.make, {
              label: $$Intl.t("Copy ID"),
              value: match
            });
      return React.createElement(Stack.make, {
                  children: null,
                  space: "medium"
                }, React.createElement(Stack.make, {
                      children: null
                    }, displayShopName ? React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                        children: $$Intl.t("From catalog of") + " " + variant.shopName,
                                        variation: "normal",
                                        size: "xxsmall"
                                      })), undefined, undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(TextStyle.make, {
                          children: variant.name,
                          weight: "strong"
                        }), React.createElement(make, {
                          children: variant.formattedCapacity,
                          label: $$Intl.t("Capacity of")
                        }), React.createElement(make, {
                          children: Belt_Option.map(variant.year, (function (year) {
                                  var value = Belt_Int.fromString(year);
                                  if (value !== undefined) {
                                    if (value === 1) {
                                      return year + " " + $$Intl.t("year");
                                    } else if (value < 4) {
                                      return year + " " + $$Intl.t($$Intl.isPlural(value) ? "years" : "year");
                                    } else {
                                      return year;
                                    }
                                  } else {
                                    return year;
                                  }
                                })),
                          label: $$Intl.t("Vintage")
                        }), React.createElement(make, {
                          children: variant.formattedAlcoholVolume,
                          label: $$Intl.t("Alcohol volume of")
                        })), React.createElement(Stack.make, {
                      children: null
                    }, tmp, tmp$1));
    });

var Content = {
  make: make$1
};

var make$2 = React.memo(function (Props) {
      var variant = Props.variant;
      if (variant !== undefined) {
        return React.createElement(Stack.make, {
                    children: null,
                    space: "normal"
                  }, React.createElement(TextStyle.make, {
                        children: $$Intl.t("From catalog of") + " " + variant.shopName,
                        variation: "normal",
                        size: "xxsmall"
                      }), React.createElement(TextStyle.make, {
                        children: variant.name,
                        weight: "strong"
                      }));
      } else {
        return null;
      }
    });

var PreviewContent = {
  make: make$2
};

var make$3 = React.memo(function (Props) {
      var children = Props.children;
      var collapsed = Props.collapsed;
      var onPress = Props.onPress;
      var match = Hover.use(undefined, undefined);
      var tmp = {
        children: $$Intl.t(collapsed ? "Show more" : "Collapse"),
        level: 5,
        weight: "medium"
      };
      var tmp$1 = match[1] ? "#797885" : undefined;
      if (tmp$1 !== undefined) {
        tmp.color = tmp$1;
      }
      return React.createElement(Stack.make, {
                  children: null,
                  space: "none"
                }, children, React.createElement(Touchable.make, Touchable.makeProps(React.createElement(Inline.make, {
                              children: null
                            }, React.createElement(Icon.make, {
                                  name: collapsed ? "arrow_down_light" : "arrow_up_light"
                                }), React.createElement(Title.make, tmp)), undefined, undefined, undefined, undefined, undefined, onPress, undefined, match[0], undefined)));
    });

var ShowMoreWrapper = {
  make: make$3
};

function CatalogVariantInformationCard(Props) {
  var shopsVariant = Props.shopsVariant;
  var match = React.useState(function () {
        return false;
      });
  var setShowMore = match[1];
  var showMore = match[0];
  var scope = Auth.useScope(undefined);
  var match$1 = Navigation.useUrl(undefined);
  var variantDiffering = React.useMemo((function () {
          return CatalogVariant.MultiShops.isReferenceDiffering(shopsVariant, scope, (function (a, b) {
                        if (a.name === b.name && a.formattedCapacity === b.formattedCapacity && a.year === b.year && a.formattedAlcoholVolume === b.formattedAlcoholVolume && a.ean13 === b.ean13 && a.stockKeepingUnit === b.stockKeepingUnit && a.priceLookUpCode === b.priceLookUpCode) {
                          return a.internalCode === b.internalCode;
                        } else {
                          return false;
                        }
                      }));
        }), [
        scope,
        shopsVariant
      ]);
  var tmp = {
    displayShopName: variantDiffering,
    displayVariantId: shopsVariant.length <= 1
  };
  var tmp$1 = Belt_Array.get(shopsVariant, 0);
  if (tmp$1 !== undefined) {
    tmp.variant = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2;
  if (variantDiffering) {
    var tmp$3;
    if (showMore) {
      var tmp$4 = {};
      var tmp$5 = Belt_Array.get(shopsVariant, 1);
      if (tmp$5 !== undefined) {
        tmp$4.variant = Caml_option.valFromOption(tmp$5);
      }
      tmp$3 = React.createElement(make$2, tmp$4);
    } else {
      tmp$3 = React.createElement(Stack.make, {
            children: Belt_Array.map(Belt_Array.slice(shopsVariant, 1, shopsVariant.length), (function (variant) {
                    return React.createElement(make$1, {
                                variant: variant,
                                displayVariantId: shopsVariant.length <= 1
                              });
                  })),
            space: "normal"
          });
    }
    tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(make$3, {
              children: tmp$3,
              collapsed: !showMore,
              onPress: (function (param) {
                  Curry._1(setShowMore, (function (state) {
                          return !state;
                        }));
                })
            }), showMore ? React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Banner.make, {
                            textStatus: {
                              TAG: /* Warning */2,
                              _0: $$Intl.t("Beware, some information differs among the catalogs of the shops.")
                            },
                            compact: false
                          })), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null);
  } else {
    tmp$2 = null;
  }
  return React.createElement(Card.make, {
              children: React.createElement(Stack.make, {
                    children: null,
                    space: "large"
                  }, React.createElement(make$1, tmp), tmp$2),
              title: $$Intl.t("Variant's information"),
              action: {
                icon: "edit_light",
                title: $$Intl.t("Edit variant"),
                handler: {
                  TAG: /* OpenLink */1,
                  _0: {
                    TAG: /* Route */0,
                    _0: match$1.pathname + "/edit"
                  }
                }
              }
            });
}

var make$4 = React.memo(CatalogVariantInformationCard);

var Config;

export {
  Config ,
  InfoTextLine ,
  Content ,
  PreviewContent ,
  ShowMoreWrapper ,
  make$4 as make,
}
/* make Not a pure module */
