// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactDomElement from "./ReactDomElement.bs.js";

function use(forwardedRef, param) {
  var match = React.useState(function () {
        return false;
      });
  var setState = match[1];
  var ref = forwardedRef !== undefined ? forwardedRef : React.useRef(null);
  React.useEffect((function () {
          var onMouseEnter = function (_evt) {
            Curry._1(setState, (function (param) {
                    return true;
                  }));
          };
          var onMouseLeave = function (_evt) {
            Curry._1(setState, (function (param) {
                    return false;
                  }));
          };
          Belt_Option.map(ReactDomElement.fromRef(ref), (function (domElement) {
                  domElement.addEventListener("mouseenter", onMouseEnter);
                  domElement.addEventListener("mouseleave", onMouseLeave);
                }));
          return (function (param) {
                    Belt_Option.map(ReactDomElement.fromRef(ref), (function (domElement) {
                            domElement.removeEventListener("mouseenter", onMouseEnter);
                            domElement.removeEventListener("mouseleave", onMouseLeave);
                          }));
                  });
        }), [ref.current]);
  return [
          ref,
          match[0]
        ];
}

export {
  use ,
}
/* react Not a pure module */
