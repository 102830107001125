// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../layout-and-structure/Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as $$Text from "../../primitives/Text.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Inline from "../layout-and-structure/Inline.bs.js";
import * as Spaces from "../theme/Spaces.bs.js";
import * as FontFaces from "../theme/FontFaces.bs.js";
import * as FontSizes from "../theme/FontSizes.bs.js";
import * as Touchable from "../../primitives/Touchable.bs.js";
import * as IconButton from "../actions/IconButton.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function isButtonDisabled(action, currentPage, totalPages) {
  if (action >= 2) {
    return currentPage >= totalPages;
  } else {
    return currentPage <= 1;
  }
}

var styles = $$StyleSheet.create({
      view: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(-7)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function LegacyPagination$ControlButton(Props) {
  var currentPage = Props.currentPage;
  var totalPages = Props.totalPages;
  var action = Props.action;
  var onPress = Props.onPress;
  var disabled = isButtonDisabled(action, currentPage, totalPages);
  var tmp;
  switch (action) {
    case /* First */0 :
        tmp = "double_arrow_left_light";
        break;
    case /* Prev */1 :
        tmp = "arrow_left_light";
        break;
    case /* Next */2 :
        tmp = "arrow_right_light";
        break;
    case /* Last */3 :
        tmp = "double_arrow_right_light";
        break;
    
  }
  return React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(IconButton.make, IconButton.makeProps(tmp, undefined, "small", disabled ? "#d7d7e0" : "#25243a", undefined, undefined, undefined, disabled, undefined, (function (param) {
                                Curry._1(onPress, action);
                              }), undefined, undefined, undefined))), Caml_option.some(styles.view), undefined, undefined, undefined));
}

var make = React.memo(LegacyPagination$ControlButton);

var styles$1 = $$StyleSheet.create({
      view: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(28)), "center", undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(1)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(28)), undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(5)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      disabledView: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#f3f3f7", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      text: Style.merge([
            FontFaces.libreFranklinRegularStyle,
            Style.style(undefined, undefined, undefined, "#a2a1b0", undefined, FontSizes.xsmall, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      disabledText: Style.merge([
            FontFaces.libreFranklinBoldStyle,
            Style.style(undefined, undefined, undefined, "#25243a", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ])
    });

function viewStyleFromParams(disabled) {
  return Style.arrayOptionStyle([
              Caml_option.some(styles$1.view),
              disabled ? Caml_option.some(styles$1.disabledView) : undefined
            ]);
}

function textStyleFromParams(disabled) {
  return Style.arrayOptionStyle([
              Caml_option.some(styles$1.text),
              disabled ? Caml_option.some(styles$1.disabledText) : undefined
            ]);
}

function LegacyPagination$PageButton(Props) {
  var currentPage = Props.currentPage;
  var totalPages = Props.totalPages;
  var action = Props.action;
  var children = Props.children;
  var onPress = Props.onPress;
  var disabled = action !== undefined ? isButtonDisabled(action, currentPage, totalPages) : true;
  return React.createElement(Touchable.make, Touchable.makeProps(React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement($$Text.make, $$Text.makeProps(children, Caml_option.some(textStyleFromParams(disabled)), undefined, undefined, undefined))), Caml_option.some(viewStyleFromParams(disabled)), undefined, undefined, undefined)), undefined, disabled, undefined, undefined, undefined, (function (param) {
                    if (action !== undefined) {
                      return Curry._1(onPress, action);
                    }
                    
                  }), undefined, undefined, undefined));
}

var make$1 = React.memo(LegacyPagination$PageButton);

var styles$2 = $$StyleSheet.create({
      container: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#fefefe", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "solid", "#e7e7ee", undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, Caml_option.some(Style.dp(64)), "spaceBetween", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.large)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      label: Style.merge([
            FontFaces.libreFranklinRegularStyle,
            Style.style(undefined, undefined, undefined, "#a2a1b0", undefined, FontSizes.small, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ])
    });

function LegacyPagination(Props) {
  var currentPage = Props.currentPage;
  var totalPages = Props.totalPages;
  var onPress = Props.onRequestPaginate;
  return React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles$2.container), undefined, undefined, undefined), React.createElement($$Text.make, $$Text.makeProps("Page " + (String(currentPage) + ($$Intl.t(" of ") + String(totalPages))), Caml_option.some(styles$2.label), undefined, undefined, undefined)), React.createElement(Inline.make, {
                  children: null
                }, React.createElement(make, {
                      currentPage: currentPage,
                      totalPages: totalPages,
                      action: /* First */0,
                      onPress: onPress
                    }), React.createElement(make, {
                      currentPage: currentPage,
                      totalPages: totalPages,
                      action: /* Prev */1,
                      onPress: onPress
                    }), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Inline.make, {
                                  children: null
                                }, currentPage > 1 ? React.createElement(make$1, {
                                        currentPage: currentPage,
                                        totalPages: totalPages,
                                        action: /* Prev */1,
                                        children: currentPage - 1 | 0,
                                        onPress: onPress
                                      }) : null, React.createElement(make$1, {
                                      currentPage: currentPage,
                                      totalPages: totalPages,
                                      children: currentPage,
                                      onPress: onPress
                                    }), currentPage < totalPages ? React.createElement(make$1, {
                                        currentPage: currentPage,
                                        totalPages: totalPages,
                                        action: /* Next */2,
                                        children: currentPage + 1 | 0,
                                        onPress: onPress
                                      }) : null)), "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(make, {
                      currentPage: currentPage,
                      totalPages: totalPages,
                      action: /* Next */2,
                      onPress: onPress
                    }), React.createElement(make, {
                      currentPage: currentPage,
                      totalPages: totalPages,
                      action: /* Last */3,
                      onPress: onPress
                    })));
}

var make$2 = React.memo(LegacyPagination);

export {
  isButtonDisabled ,
  make$2 as make,
}
/* styles Not a pure module */
