// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as TextLink from "../../resources/navigation/TextLink.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LegacyRouter from "../../core/LegacyRouter.bs.js";

function OrderNameTableCell(Props) {
  var value = Props.value;
  var shopName = Props.shopName;
  var id = Props.id;
  var orderBaseRoute = LegacyRouter.routeToPathname(/* Order */0);
  var orderPageRoute = id !== undefined ? orderBaseRoute + "/" + id : orderBaseRoute;
  return React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Stack.make, {
                          children: null,
                          space: Belt_Option.isSome(shopName) ? "xxsmall" : "none"
                        }, React.createElement(TextLink.make, {
                              text: $$Intl.t(value),
                              to: {
                                TAG: /* Route */0,
                                _0: orderPageRoute
                              }
                            }), shopName !== undefined ? React.createElement(TextStyle.make, {
                                children: shopName,
                                variation: "normal",
                                size: "xxsmall"
                              }) : null)), undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = React.memo(OrderNameTableCell);

export {
  make ,
}
/* make Not a pure module */
