// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Stack from "./Stack.bs.js";
import * as Title from "../typography/Title.bs.js";
import * as React from "react";

var make = React.memo(function (Props) {
      var children = Props.children;
      var title = Props.title;
      return React.createElement(Stack.make, {
                  children: null,
                  space: "normal"
                }, React.createElement(Title.make, {
                      children: title.toUpperCase(),
                      level: 5
                    }), React.createElement(Stack.make, {
                      children: children,
                      space: "xsmall"
                    }));
    });

export {
  make ,
}
/* make Not a pure module */
