// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as React from "react";
import * as TableView from "../../resources/tables/TableView.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Placeholder from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";
import * as PricePurchaseTableEditCellWrapper from "../Price/PricePurchaseTableEditCellWrapper.bs.js";
import * as CatalogSupplierTableEditCellWrapper from "./CatalogSupplierTableEditCellWrapper.bs.js";

function keyExtractor(row) {
  return row.variantId + "-purchase";
}

var Row = {
  keyExtractor: keyExtractor
};

function minValueFromRetailPrices(retailPricesData) {
  var minValue = Caml_splice_call.spliceApply(Math.min, [Belt_Array.map(retailPricesData, (function (param) {
                var secondaryValue = param[1];
                var value = param[0];
                if (secondaryValue !== undefined && secondaryValue.TAG === /* PriceExclTax */0) {
                  return secondaryValue._0;
                }
                if (value.TAG === /* PriceExclTax */0 || secondaryValue === undefined) {
                  return value._0;
                } else {
                  return secondaryValue._0;
                }
              }))]);
  if (Number.isFinite(minValue)) {
    return minValue;
  }
  
}

var InnerUtils = {
  minValueFromRetailPrices: minValueFromRetailPrices
};

function tableColumns(allShopsFiltered) {
  return [
          {
            key: "purchasedprice-shopname",
            name: $$Intl.t("Shop/Warehouse"),
            layout: {
              hidden: !allShopsFiltered,
              minWidth: {
                NAME: "px",
                VAL: 200
              },
              width: {
                NAME: "fr",
                VAL: 1.75
              }
            },
            render: (function (param) {
                return React.createElement(TextStyle.make, {
                            children: param.data.shopName
                          });
              })
          },
          {
            key: "supplier-id",
            name: $$Intl.t("Supplier"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 230
              },
              width: {
                NAME: "fr",
                VAL: 2
              }
            },
            render: (function (param) {
                var match = param.data;
                return React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(CatalogSupplierTableEditCellWrapper.make, {
                                        supplierId: match.supplierId,
                                        supplierName: match.supplierName,
                                        variantId: match.variantId,
                                        shopId: match.shopId
                                      })), undefined, undefined, undefined, undefined, undefined, "xxlarge", undefined, undefined, undefined, undefined, undefined));
              })
          },
          {
            key: "purchasedprice-price",
            name: $$Intl.t("Purchase price") + " " + $$Intl.t("HT"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 100
              }
            },
            render: (function (param) {
                var match = param.data;
                return React.createElement(PricePurchaseTableEditCellWrapper.make, {
                            value: match.purchasePrice,
                            formattedPurchasePrice: match.formattedPurchasePrice,
                            bulkUnit: match.bulkUnit,
                            minRetailPrice: match.minRetailPrice,
                            variantId: match.variantId
                          });
              })
          }
        ];
}

function CatalogVariantPurchasePriceTableCard(Props) {
  var shopsVariantPrices = Props.shopsVariantPrices;
  var match = Auth.useScope(undefined);
  var allShopsFiltered;
  allShopsFiltered = match.TAG === /* Organisation */0 ? match.activeShop === undefined : false;
  var columns = React.useMemo((function () {
          return tableColumns(allShopsFiltered);
        }), [allShopsFiltered]);
  var shopsVariantRetailPriceMinValue = React.useMemo((function () {
          return Belt_Array.map(shopsVariantPrices, (function (param) {
                        return minValueFromRetailPrices(Belt_Array.map(param.retailPricesData, (function (data) {
                                          return [
                                                  data.retailPrice,
                                                  data.secondaryRetailPrice
                                                ];
                                        })));
                      }));
        }), [shopsVariantPrices]);
  var rows = Belt_Array.mapWithIndex(shopsVariantPrices, (function (index, param) {
          var purchasePriceData = param.purchasePriceData;
          var match = Belt_Array.get(shopsVariantRetailPriceMinValue, index);
          var tmp;
          if (match !== undefined) {
            var minRetailPrice = Caml_option.valFromOption(match);
            tmp = minRetailPrice !== undefined ? minRetailPrice : 0;
          } else {
            tmp = 0;
          }
          return {
                  variantId: purchasePriceData.id,
                  shopId: purchasePriceData.shopId,
                  shopName: purchasePriceData.shopName,
                  supplierId: purchasePriceData.supplierId,
                  supplierName: purchasePriceData.supplierName,
                  purchasePrice: Belt_Option.getWithDefault(purchasePriceData.purchasePrice, 0),
                  formattedPurchasePrice: purchasePriceData.formattedPurchasedPrice,
                  minRetailPrice: tmp,
                  bulkUnit: purchasePriceData.capacityUnit
                };
        }));
  var placeholderEmptyState = React.createElement(Placeholder.make, {
        status: /* NoDataAvailable */1,
        customText: $$Intl.t("No purchase price has been yet recorded")
      });
  var data = {
    TAG: /* Done */1,
    _0: {
      TAG: /* Ok */0,
      _0: rows
    }
  };
  return React.createElement(Card.make, {
              children: React.createElement(TableView.make, {
                    data: data,
                    columns: columns,
                    keyExtractor: keyExtractor,
                    maxHeight: 325,
                    placeholderEmptyState: placeholderEmptyState
                  }),
              title: $$Intl.t("Purchasing price"),
              variation: "table"
            });
}

var make = React.memo(CatalogVariantPurchasePriceTableCard, (function (oldProps, newProps) {
        return oldProps.shopsVariantPrices === newProps.shopsVariantPrices;
      }));

var Config;

export {
  Config ,
  Row ,
  InnerUtils ,
  tableColumns ,
  make ,
}
/* make Not a pure module */
