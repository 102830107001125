// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SupplierRoutes from "./SupplierRoutes.bs.js";
import * as SupplierNewPage from "./SupplierNewPage.bs.js";
import * as SupplierEditPage from "./SupplierEditPage.bs.js";
import * as SupplierListPage from "./SupplierListPage.bs.js";
import * as SupplierShowPage from "./SupplierShowPage.bs.js";
import * as SupplierOrderListPage from "./SupplierOrderListPage.bs.js";
import * as SupplierEditContactPage from "./SupplierEditContactPage.bs.js";
import * as SupplierEditLocationPage from "./SupplierEditLocationPage.bs.js";

function SupplierRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  var url = Navigation.useUrl(undefined);
  if (!subUrlPath) {
    return React.createElement(SupplierListPage.make, {
                supplierBaseRoute: SupplierRoutes.baseRoute,
                supplierNewRoute: SupplierRoutes.newRoute(undefined, undefined),
                supplierShowRoute: SupplierRoutes.showRoute
              });
  }
  var id = subUrlPath.hd;
  var exit = 0;
  if (id === "new" && !subUrlPath.tl) {
    var shopId = SupplierRoutes.decodeCreateSupplierQueryString(url.query);
    var tmp = {
      supplierShowRoute: SupplierRoutes.showRoute
    };
    if (shopId !== undefined) {
      tmp.shopId = Caml_option.valFromOption(shopId);
    }
    return React.createElement(SupplierNewPage.make, tmp);
  }
  exit = 2;
  if (exit === 2) {
    var match = subUrlPath.tl;
    if (!match) {
      return React.createElement(SupplierShowPage.make, {
                  id: id,
                  supplierEditRoute: SupplierRoutes.editRoute,
                  supplierEditLocationRoute: SupplierRoutes.editLocationRoute,
                  supplierEditContactRoute: SupplierRoutes.editContactRoute,
                  supplierBaseRoute: SupplierRoutes.baseRoute
                });
    }
    switch (match.hd) {
      case "edit" :
          var match$1 = match.tl;
          if (!match$1) {
            return React.createElement(SupplierEditPage.make, {
                        id: id,
                        supplierShowRoute: SupplierRoutes.showRoute
                      });
          }
          switch (match$1.hd) {
            case "contact" :
                if (!match$1.tl) {
                  return React.createElement(SupplierEditContactPage.make, {
                              id: id,
                              supplierShowRoute: SupplierRoutes.showRoute
                            });
                }
                break;
            case "location" :
                if (!match$1.tl) {
                  return React.createElement(SupplierEditLocationPage.make, {
                              id: id,
                              supplierShowRoute: SupplierRoutes.showRoute
                            });
                }
                break;
            default:
              
          }
          break;
      case "orders" :
          if (!match.tl) {
            return React.createElement(SupplierOrderListPage.make, {
                        id: id
                      });
          }
          break;
      default:
        
    }
  }
  return React.createElement(Navigation.Redirect.make, {
              route: SupplierRoutes.baseRoute
            });
}

var make = SupplierRouter;

export {
  make ,
}
/* react Not a pure module */
