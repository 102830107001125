// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as Icon from "../../resources/images-and-icons/Icon.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as $$Text from "../../primitives/Text.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as Modal from "../../resources/overlays/Modal.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Banner from "../../resources/feedback-indicators/Banner.bs.js";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Spaces from "../../resources/theme/Spaces.bs.js";
import * as Popover from "../../resources/overlays/Popover.bs.js";
import * as Tooltip from "../../resources/overlays/Tooltip.bs.js";
import * as FontFaces from "../../resources/theme/FontFaces.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Touchable from "../../primitives/Touchable.bs.js";
import * as HelpCenter from "../../core/HelpCenter.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Placeholder from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as RoundButton from "../../resources/actions/RoundButton.bs.js";
import * as CatalogLabel from "./CatalogLabel.bs.js";
import * as Illustration from "../../resources/images-and-icons/Illustration.bs.js";
import * as CatalogRoutes from "../../app/Catalog/CatalogRoutes.bs.js";
import * as ShortIconButton from "../../resources/actions/ShortIconButton.bs.js";
import * as InputNumberField from "../../resources/selection-and-input/InputNumberField.bs.js";

var make = React.memo(function (Props) {
      var fromEditRedirection = Props.fromEditRedirection;
      var variantId = Props.variantId;
      var shopId = Props.shopId;
      var requestBarcodeCompletion = Props.requestBarcodeCompletion;
      var requestLabelsPrinting = Props.requestLabelsPrinting;
      var opened = Props.opened;
      var onRequestClose = Props.onRequestClose;
      var onRequestNotification = Props.onRequestNotification;
      var stateSettingsStorageUseReadOpt = Props.stateSettingsStorageUseRead;
      var stateSettingsStorageUseRead = stateSettingsStorageUseReadOpt !== undefined ? stateSettingsStorageUseReadOpt : CatalogLabel.StateSettingsUserPreferences.useRead;
      var printSettings = Curry._1(stateSettingsStorageUseRead, undefined);
      var match = React.useState(function () {
            return 1;
          });
      var setPrintingQuantity = match[1];
      var printingQuantity = match[0];
      var navigate = Navigation.useNavigate(undefined);
      var match$1 = Navigation.useUrl(undefined);
      var editRedirectionQueryString = CatalogRoutes.CatalogLabelQueryString.encode({
            variantIdfromLabelEditSettings: variantId,
            fromPathname: match$1.pathname
          });
      var hasSettings = printSettings !== undefined && printSettings.labelFormat.TAG !== /* LabelSheet */0 ? true : false;
      var onCommit = function (param) {
        if (!hasSettings) {
          return Curry._3(navigate, undefined, undefined, CatalogRoutes.labelsCreateSettingsRoute + "?" + editRedirectionQueryString);
        }
        if (printSettings === undefined) {
          return ;
        }
        var labelFormat = printSettings.labelFormat;
        if (labelFormat.TAG === /* LabelSheet */0) {
          return ;
        } else {
          return Future.get(Curry.app(CatalogLabel.Print.CombineRequests.make, [
                          requestBarcodeCompletion,
                          requestLabelsPrinting,
                          printSettings.priceId,
                          printSettings.priceName,
                          printSettings.productCode,
                          printSettings.productBarcodeDisplayed,
                          /* Picked */{
                            _0: [{
                                variantId: variantId,
                                repetitions: printingQuantity | 0
                              }]
                          },
                          labelFormat._0,
                          printSettings.missingProductBarcodeGenerated,
                          printSettings.priceDisplayed,
                          printSettings.producerDisplayed,
                          printSettings.alcoholVolumeDisplayed,
                          shopId
                        ]), (function (result) {
                        if (result.TAG === /* Ok */0) {
                          return Curry._1(onRequestNotification, {
                                      TAG: /* Ok */0,
                                      _0: $$Intl.template(printingQuantity > 1 ? $$Intl.t("{{quantity}} prints of the label has been started.") : $$Intl.t("{{quantity}} print of the label has been started."), {
                                            quantity: printingQuantity
                                          }, undefined) + $$Intl.template($$Intl.t("[Monitor printing from your StarPrinter Online account]({{link}})"), {
                                            link: "https://portal.starprinter.online/Dashboard"
                                          }, undefined)
                                    });
                        } else {
                          return Curry._1(onRequestNotification, {
                                      TAG: /* Error */1,
                                      _0: result._0
                                    });
                        }
                      }));
        }
      };
      var modalContentElement;
      var exit = 0;
      if (printSettings !== undefined) {
        var labelFormat = printSettings.labelFormat;
        if (labelFormat.TAG === /* LabelSheet */0) {
          exit = 1;
        } else {
          var match$2 = printSettings.productCode;
          var tmp;
          switch (match$2) {
            case /* Hidden */0 :
                tmp = null;
                break;
            case /* SKU */1 :
                tmp = $$Intl.t("Display SKU");
                break;
            case /* InternalCode */2 :
                tmp = $$Intl.t("Display internal code");
                break;
            
          }
          modalContentElement = React.createElement(Stack.make, {
                children: null,
                space: "large"
              }, fromEditRedirection ? React.createElement(Banner.make, {
                      textStatus: {
                        TAG: /* Success */0,
                        _0: $$Intl.t("Print parameters set correctly!")
                      }
                    }) : null, React.createElement(InputNumberField.make, {
                    value: printingQuantity,
                    minValue: 1,
                    precision: 0,
                    label: $$Intl.t("Copies"),
                    onChange: (function (value) {
                        Curry._1(setPrintingQuantity, (function (param) {
                                return value;
                              }));
                      })
                  }), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Card.make, {
                                children: React.createElement(Stack.make, {
                                      children: null,
                                      space: "normal"
                                    }, React.createElement(Stack.make, {
                                          children: null
                                        }, React.createElement(TextStyle.make, {
                                              children: printSettings.priceName
                                            }), React.createElement(TextStyle.make, {
                                              children: $$Intl.template($$Intl.t("Format: {{format}}"), {
                                                    format: Curry._1(CatalogLabel.Print.LabelFormat.toFormatLabel, labelFormat._0)
                                                  }, undefined)
                                            })), React.createElement(Stack.make, {
                                          children: null
                                        }, printSettings.productBarcodeDisplayed ? React.createElement(TextStyle.make, {
                                                children: $$Intl.t("Display barcode") + (
                                                  printSettings.missingProductBarcodeGenerated ? " " + $$Intl.t("(generate automatically if missing)") : ""
                                                )
                                              }) : null, printSettings.priceDisplayed ? React.createElement(TextStyle.make, {
                                                children: $$Intl.t("Display retail price")
                                              }) : null, printSettings.producerDisplayed ? React.createElement(TextStyle.make, {
                                                children: $$Intl.t("Display producer")
                                              }) : null, printSettings.alcoholVolumeDisplayed ? React.createElement(TextStyle.make, {
                                                children: $$Intl.t("Display alcohol volume")
                                              }) : null, React.createElement(TextStyle.make, {
                                              children: tmp
                                            }))),
                                title: $$Intl.t("Print settings"),
                                action: {
                                  icon: "edit_light",
                                  title: $$Intl.t("Edit settings"),
                                  handler: {
                                    TAG: /* OpenLink */1,
                                    _0: {
                                      TAG: /* RouteWithQueryString */1,
                                      _0: CatalogRoutes.labelsCreateSettingsRoute,
                                      _1: editRedirectionQueryString
                                    }
                                  }
                                }
                              })), Caml_option.some(Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#d7d7e0", undefined, undefined, undefined, undefined, 4, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined)));
        }
      } else {
        exit = 1;
      }
      if (exit === 1) {
        modalContentElement = React.createElement(Placeholder.make, {
              status: /* Pending */{
                title: $$Intl.t("No print settings set"),
                message: $$Intl.t("To print a label from a product page, you must first enter the print settings."),
                illustration: Caml_option.some(Illustration.warningTriangle)
              }
            });
      }
      return React.createElement(Modal.make, {
                  children: React.createElement(Box.make, Box.makeProps(Caml_option.some(modalContentElement), "large", undefined, "xlarge", "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                  opened: opened,
                  title: $$Intl.t("Print label"),
                  abortButtonText: $$Intl.t("Cancel"),
                  commitButtonVariation: "primary",
                  commitButtonText: hasSettings ? $$Intl.t("Print label") : $$Intl.t("Set settings"),
                  commitButtonCallback: onCommit,
                  onRequestClose: onRequestClose
                });
    });

var CatalogLabelQuickPrintModal = {
  make: make
};

var styles = $$StyleSheet.create({
      buttonBackbone: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 5, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, Caml_option.some(Style.dp(42)), "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(24)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      buttonTextLocked: Style.merge([
            FontFaces.archivoSemiBoldStyle,
            Style.style(undefined, undefined, undefined, "#a01e72", undefined, 16, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      buttonStyleLocked: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#fbe8f8", undefined, undefined, undefined, undefined, undefined, undefined, "#c04695", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "solid", undefined, undefined, undefined, undefined, undefined, undefined, Style.hairlineWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      overlayBackbone: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.medium)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(400)), undefined, undefined),
      overlayTitleText: Style.merge([
            FontFaces.libreFranklinSemiBoldStyle,
            Style.style(undefined, undefined, undefined, "#a01e72", undefined, 18, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      overlayTitleIcon: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#fbe8f8", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 5, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(6)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      overlayButtonStyle: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#fbe8f8", undefined, undefined, undefined, undefined, undefined, undefined, "#c04695", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function buttonStyleFromParams(hovered, active) {
  if (active) {
    return Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#f4e4ee", undefined, undefined, undefined, undefined, undefined, undefined, "#8a0262", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (hovered) {
    return Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#f4e4ee", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }
}

var make$1 = React.memo(function (Props) {
      var triggerRef = Props.triggerRef;
      var state = Props.state;
      var overlayProps = Props.overlayProps;
      var match = Hover.use(undefined, undefined);
      return React.createElement(Popover.make, {
                  children: React.createElement(Popover.Dialog.make, {
                        children: React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Stack.make, {
                                          children: null,
                                          space: "xmedium"
                                        }, React.createElement(Inline.make, {
                                              children: null,
                                              space: "normal"
                                            }, React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Icon.make, {
                                                              name: "printer_locked",
                                                              fill: "#a01e72",
                                                              size: 20
                                                            })), Caml_option.some(styles.overlayTitleIcon), undefined, undefined, undefined)), React.createElement($$Text.make, $$Text.makeProps($$Intl.t("Print label"), Caml_option.some(styles.overlayTitleText), undefined, undefined, undefined))), React.createElement(TextStyle.make, {
                                              children: $$Intl.t($$Intl.t("This new feature lets you print a label directly from a product page with a Star printer."))
                                            }), React.createElement(Touchable.make, Touchable.makeProps(React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement($$Text.make, $$Text.makeProps($$Intl.t("Get the feature"), Caml_option.some(styles.buttonTextLocked), undefined, undefined, undefined))), Caml_option.some(Style.arrayStyle([
                                                                  styles.buttonBackbone,
                                                                  styles.buttonTextLocked,
                                                                  styles.overlayButtonStyle,
                                                                  buttonStyleFromParams(match[1], false)
                                                                ])), undefined, undefined, undefined)), undefined, undefined, undefined, undefined, undefined, (function (param) {
                                                    HelpCenter.showArticle(HelpCenter.getLabelPrintFeature);
                                                  }), undefined, match[0], undefined)))), Caml_option.some(styles.overlayBackbone), undefined, undefined, undefined)),
                        ariaProps: overlayProps
                      }),
                  triggerRef: triggerRef,
                  state: state,
                  borderColor: "#a01e72",
                  placement: "bottom start",
                  modal: false
                });
    });

var CatalogLabelQuickPrintButtonPopover = {
  make: make$1
};

var make$2 = React.memo(function (Props) {
      var variationOpt = Props.variation;
      var featureLocked = Props.featureLocked;
      var fromEditRedirectionOpt = Props.fromEditRedirection;
      var variantId = Props.variantId;
      var shopId = Props.shopId;
      var requestBarcodeCompletion = Props.requestBarcodeCompletion;
      var requestLabelsPrinting = Props.requestLabelsPrinting;
      var onRequestNotification = Props.onRequestNotification;
      var stateSettingsStorageUseReadOpt = Props.stateSettingsStorageUseRead;
      var variation = variationOpt !== undefined ? variationOpt : "standard";
      var fromEditRedirection = fromEditRedirectionOpt !== undefined ? fromEditRedirectionOpt : false;
      var stateSettingsStorageUseRead = stateSettingsStorageUseReadOpt !== undefined ? stateSettingsStorageUseReadOpt : CatalogLabel.StateSettingsUserPreferences.useRead;
      var match = Popover.useTrigger(undefined, undefined);
      var popoverTriggerRef = match.ref;
      var popoverAriaProps = match.ariaProps;
      var popover = match.state;
      var match$1 = Hover.use(popoverTriggerRef, undefined);
      var triggerHovered = match$1[1];
      var match$2 = React.useState(function () {
            return fromEditRedirection;
          });
      var setPrintModalOpened = match$2[1];
      var printModalOpened = match$2[0];
      var handleOnPress = function (param) {
        if (featureLocked) {
          return Curry._1(popover.toggle, undefined);
        } else {
          return Curry._1(setPrintModalOpened, (function (param) {
                        return true;
                      }));
        }
      };
      var onRequestClose = popover.close;
      var popover_isOpen = popover.isOpen;
      var popover_close = function (param) {
        if (!triggerHovered) {
          return Curry._1(onRequestClose, undefined);
        }
        
      };
      var popover_toggle = popover.toggle;
      var popover$1 = {
        isOpen: popover_isOpen,
        close: popover_close,
        toggle: popover_toggle
      };
      if (variation === "standard") {
        return React.createElement(React.Fragment, undefined, featureLocked ? React.createElement(Touchable.make, Touchable.makeProps(React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Inline.make, {
                                              children: null,
                                              space: "xnormal"
                                            }, React.createElement(Icon.make, {
                                                  name: "printer_locked",
                                                  fill: "#a01e72",
                                                  size: 18
                                                }), React.createElement($$Text.make, $$Text.makeProps($$Intl.t("Print label"), Caml_option.some(styles.buttonTextLocked), undefined, undefined, undefined)))), Caml_option.some(Style.arrayStyle([
                                              styles.buttonBackbone,
                                              styles.buttonStyleLocked,
                                              buttonStyleFromParams(triggerHovered, popover_isOpen)
                                            ])), undefined, undefined, undefined)), undefined, undefined, undefined, undefined, undefined, handleOnPress, undefined, popoverTriggerRef, undefined)) : React.createElement(React.Fragment, undefined, React.createElement(Button.make, Button.makeProps(React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Inline.make, {
                                                  children: null,
                                                  space: "xnormal"
                                                }, React.createElement(Icon.make, {
                                                      name: "printer",
                                                      size: 18
                                                    }), $$Intl.t("Print label"))), undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, handleOnPress, undefined, undefined, undefined)), React.createElement(make, {
                              fromEditRedirection: fromEditRedirection,
                              variantId: variantId,
                              shopId: shopId,
                              requestBarcodeCompletion: requestBarcodeCompletion,
                              requestLabelsPrinting: requestLabelsPrinting,
                              opened: printModalOpened,
                              onRequestClose: (function (param) {
                                  Curry._1(setPrintModalOpened, (function (param) {
                                          return false;
                                        }));
                                }),
                              onRequestNotification: onRequestNotification,
                              stateSettingsStorageUseRead: stateSettingsStorageUseRead
                            })), popover_isOpen ? React.createElement(make$1, {
                          triggerRef: popoverTriggerRef,
                          state: popover$1,
                          overlayProps: popoverAriaProps.overlayProps
                        }) : null);
      } else if (variation === "rounded") {
        if (featureLocked) {
          return null;
        } else {
          return React.createElement(React.Fragment, undefined, React.createElement(Tooltip.make, {
                          children: React.createElement(RoundButton.make, RoundButton.makeProps(popoverAriaProps.triggerProps, "printer", undefined, undefined, popover_isOpen, handleOnPress, undefined, popoverTriggerRef, undefined)),
                          arrowed: false,
                          placement: "top end",
                          content: React.createElement(Tooltip.Span.make, {
                                text: $$Intl.t("Print label")
                              }),
                          closeDelay: 0
                        }), React.createElement(make, {
                          fromEditRedirection: fromEditRedirection,
                          variantId: variantId,
                          shopId: shopId,
                          requestBarcodeCompletion: requestBarcodeCompletion,
                          requestLabelsPrinting: requestLabelsPrinting,
                          opened: printModalOpened,
                          onRequestClose: (function (param) {
                              Curry._1(setPrintModalOpened, (function (param) {
                                      return false;
                                    }));
                            }),
                          onRequestNotification: onRequestNotification,
                          stateSettingsStorageUseRead: stateSettingsStorageUseRead
                        }));
        }
      } else if (featureLocked) {
        return null;
      } else {
        return React.createElement(React.Fragment, undefined, React.createElement(Tooltip.make, {
                        children: React.createElement(ShortIconButton.make, ShortIconButton.makeProps("printer", undefined, popover_isOpen, false, undefined, undefined, popoverAriaProps.triggerProps, {
                                  TAG: /* Callback */0,
                                  _0: handleOnPress
                                }, undefined, popoverTriggerRef, undefined)),
                        arrowed: false,
                        placement: "top end",
                        content: React.createElement(Tooltip.Span.make, {
                              text: $$Intl.t("Print label")
                            }),
                        closeDelay: 0
                      }), React.createElement(make, {
                        fromEditRedirection: fromEditRedirection,
                        variantId: variantId,
                        shopId: shopId,
                        requestBarcodeCompletion: requestBarcodeCompletion,
                        requestLabelsPrinting: requestLabelsPrinting,
                        opened: printModalOpened,
                        onRequestClose: (function (param) {
                            Curry._1(setPrintModalOpened, (function (param) {
                                    return false;
                                  }));
                          }),
                        onRequestNotification: onRequestNotification,
                        stateSettingsStorageUseRead: stateSettingsStorageUseRead
                      }));
      }
    });

export {
  CatalogLabelQuickPrintModal ,
  styles ,
  buttonStyleFromParams ,
  CatalogLabelQuickPrintButtonPopover ,
  make$2 as make,
}
/* make Not a pure module */
