// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as SalesListPage from "./SalesListPage.bs.js";

var baseRoute = "/sales";

function SalesRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  if (subUrlPath) {
    return React.createElement(Navigation.Redirect.make, {
                route: baseRoute
              });
  } else {
    return React.createElement(SalesListPage.make, {
                request: SalesListPage.SalesExportRequest.make
              });
  }
}

var make = SalesRouter;

export {
  baseRoute ,
  make ,
}
/* react Not a pure module */
