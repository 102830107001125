// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";

function kindToText(kind) {
  switch (kind) {
    case /* Currency */0 :
        return $$Intl.t("EURO");
    case /* Percent */1 :
        return $$Intl.t("%");
    case /* Free */2 :
        return $$Intl.t("Free");
    
  }
}

function discountWarningToText(warning) {
  return $$Intl.t("Cannot be greater than {{max}}");
}

function productExpectedQuantityWarningToText(warning) {
  return $$Intl.t("Quantity by {{packaging}}");
}

export {
  kindToText ,
  discountWarningToText ,
  productExpectedQuantityWarningToText ,
}
/* Intl Not a pure module */
