// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Page from "../../resources/layout-and-structure/Page.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as Title from "../../resources/typography/Title.bs.js";
import * as React from "react";
import * as Column from "../../resources/layout-and-structure/Column.bs.js";
import * as Columns from "../../resources/layout-and-structure/Columns.bs.js";
import * as Notifier from "../../bundles/Notifier/Notifier.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactUpdateEffect from "../../primitives/ReactUpdateEffect.bs.js";
import * as CatalogDuplicationForm from "../../modules/Catalog/CatalogDuplicationForm.bs.js";
import * as CatalogDuplicationFormSourceCard from "../../modules/Catalog/CatalogDuplicationFormSourceCard.bs.js";
import * as CatalogDuplicationFormPageActions from "../../modules/Catalog/CatalogDuplicationFormPageActions.bs.js";
import * as CatalogDuplicationFormSelectionTableCard from "../../modules/Catalog/CatalogDuplicationFormSelectionTableCard.bs.js";

function CatalogDuplicationPage(Props) {
  var cku = Props.cku;
  var references = Props.references;
  var duplicationMode = Props.duplicationMode;
  var shopsData = Props.shopsData;
  var state = Curry._1(CatalogDuplicationForm.useFormState, undefined);
  var notifier = Notifier.use(undefined, undefined);
  ReactUpdateEffect.use1((function (param) {
          Curry._1(notifier.clear, undefined);
        }), [state.values.source]);
  var renderActions = function (param) {
    return React.createElement(CatalogDuplicationFormPageActions.make, {
                duplicationMode: duplicationMode
              });
  };
  var title = duplicationMode ? $$Intl.t("Duplicate the variant") : $$Intl.t("Duplicate the product and its variants");
  var subtitle = React.useMemo((function () {
          var match = state.values.source;
          var tmp;
          if (match !== undefined) {
            var shopId = match._0.shopId;
            tmp = shopId !== undefined ? Belt_Array.getBy(references, (function (reference) {
                      return reference.shopId === shopId;
                    })) : Belt_Array.get(references, 0);
          } else {
            tmp = Belt_Array.get(references, 0);
          }
          return Belt_Option.mapWithDefault(tmp, "...", (function (param) {
                        return param.formattedName;
                      }));
        }), [
        references,
        state.values.source
      ]);
  var reference = React.useMemo((function () {
          var match = state.values.source;
          if (match === undefined) {
            return ;
          }
          var shopId = match._0.shopId;
          if (shopId !== undefined) {
            return Belt_Array.getBy(references, (function (reference) {
                          return reference.shopId === shopId;
                        }));
          }
          
        }), [state.values.source]);
  return React.createElement(Page.make, {
              children: null,
              title: title,
              subtitle: subtitle,
              renderActions: renderActions
            }, React.createElement(Notifier.Banner.make, {
                  notifier: notifier
                }), React.createElement(Box.make, Box.makeProps(undefined, undefined, undefined, undefined, "xxlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Stack.make, {
                  children: React.createElement(Stack.make, {
                        children: null,
                        space: "medium"
                      }, React.createElement(Columns.make, {
                            children: null,
                            space: "xlarge"
                          }, React.createElement(Column.make, {
                                children: React.createElement(Stack.make, {
                                      children: null,
                                      space: "small"
                                    }, React.createElement(Title.make, {
                                          children: $$Intl.t("Shop catalog to duplicate"),
                                          level: 3,
                                          weight: "strong"
                                        }), React.createElement(TextStyle.make, {
                                          children: $$Intl.t("Select the shop catalog from which the " + ((
                                                  duplicationMode ? "variant" : "product and its variants"
                                                ) + " will be duplicated.")),
                                          variation: "normal"
                                        })),
                                width: "quarter"
                              }), React.createElement(Column.make, {
                                children: React.createElement(CatalogDuplicationFormSourceCard.make, {
                                      shopsData: shopsData,
                                      references: Belt_Array.map(references, (function (param) {
                                              return {
                                                      id: param.id,
                                                      shopId: param.shopId
                                                    };
                                            })),
                                      duplicationMode: duplicationMode
                                    }),
                                width: "three_quarter"
                              })), React.createElement(Columns.make, {
                            children: null,
                            space: "xlarge"
                          }, React.createElement(Column.make, {
                                children: React.createElement(Stack.make, {
                                      children: null,
                                      space: "small"
                                    }, React.createElement(Title.make, {
                                          children: $$Intl.t("Shop catalog to duplicate"),
                                          level: 3,
                                          weight: "strong"
                                        }), React.createElement(TextStyle.make, {
                                          children: $$Intl.t("Select the shop catalog from which the " + ((
                                                  duplicationMode ? "variant" : "product and its variants"
                                                ) + " will be duplicated.")),
                                          variation: "normal"
                                        })),
                                width: "quarter"
                              }), React.createElement(Column.make, {
                                children: React.createElement(CatalogDuplicationFormSelectionTableCard.make, {
                                      reference: reference !== undefined ? ({
                                            variantsCku: reference.variantsCku,
                                            productName: reference.productName,
                                            productKind: reference.productKind,
                                            productColor: reference.productColor,
                                            productProducer: reference.productProducer
                                          }) : undefined,
                                      duplicationMode: duplicationMode,
                                      onError: (function (duplicationStatus) {
                                          Curry._3(notifier.reset, {
                                                TAG: /* Warning */2,
                                                _0: $$Intl.t(duplicationMode ? (
                                                        duplicationStatus >= 2 ? $$Intl.template($$Intl.t("You cannot duplicate the product variant only. It is necessary to duplicate the whole product. [Duplicate product]({{link}})"), {
                                                                link: "/catalog/product/duplication/" + cku + ""
                                                              }, undefined) : "The variant of the target catalog cannot be duplicated because no reference matches the duplication criteria."
                                                      ) : "The product of the target catalog cannot be duplicated as its reference already exists inside the catalogs of all your shops.")
                                              }, undefined, undefined);
                                        })
                                    }),
                                width: "three_quarter"
                              }))),
                  space: "xxlarge"
                }));
}

var make = React.memo(CatalogDuplicationPage);

var Config;

export {
  Config ,
  make ,
}
/* make Not a pure module */
