// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function referencesFromProductData(data, shops) {
  return Belt_Array.keep(Belt_Array.map(data.productsByVariantCku.edges, (function (param) {
                    var product = param.node;
                    return {
                            id: product.id,
                            shopId: product.shop.id,
                            formattedName: product.name,
                            variantsCku: Belt_Array.map(product.variants.edges, (function (param) {
                                    return param.node.cku;
                                  })),
                            productName: product.name,
                            productKind: product.kind,
                            productColor: product.color,
                            productProducer: product.producer
                          };
                  })), (function (product) {
                return Belt_Option.isSome(Belt_Array.getBy(shops, (function (shop) {
                                  return shop.id === product.shopId;
                                })));
              }));
}

function referencesFromVariantData(data, shops) {
  return Belt_Array.keep(Belt_Array.keepMap(data.variantsByCku.edges, (function (param) {
                    var variant = param.node;
                    return {
                            id: variant.id,
                            shopId: variant.shop.id,
                            formattedName: variant.formattedName,
                            variantsCku: Belt_Array.map(variant.product.variants.edges, (function (param) {
                                    return param.node.cku;
                                  })),
                            productName: variant.product.name,
                            productKind: variant.product.kind,
                            productColor: variant.product.color,
                            productProducer: variant.product.producer
                          };
                  })), (function (variant) {
                return Belt_Option.isSome(Belt_Array.getBy(shops, (function (shop) {
                                  return shop.id === variant.shopId;
                                })));
              }));
}

function shopsDataFromReferences(references, shops) {
  return Belt_Array.map(shops, (function (shop) {
                var match = Belt_Array.getBy(references, (function (reference) {
                        return reference.shopId === shop.id;
                      }));
                return {
                        dataId: shop.id,
                        dataName: shop.name,
                        duplicability: match !== undefined ? /* ExistsAlready */1 : /* Duplicable */0
                      };
              }));
}

function use(productResponse, variantResponse, duplicationMode) {
  var scope = Auth.useScope(undefined);
  var shops = Auth.useShops(undefined);
  var activeShop = Auth.useActiveShop(undefined);
  if (shops.length === 0) {
    return /* Loading */1;
  }
  if (duplicationMode) {
    if (variantResponse.TAG !== /* Executed */0) {
      return /* Loading */1;
    }
    var match = variantResponse._0;
    var variantData = match.data;
    if (match.loading) {
      return /* Loading */1;
    }
    if (variantData === undefined) {
      return /* Error */0;
    }
    if (scope.TAG !== /* Organisation */0) {
      return /* Error */0;
    }
    var references = referencesFromVariantData(variantData, shops);
    var shopsData = shopsDataFromReferences(references, shops);
    var match$1 = Belt_Array.getBy(references, (function (param) {
            return param.shopId === Belt_Option.map(activeShop, (function (param) {
                          return param.id;
                        }));
          }));
    var source = activeShop !== undefined && match$1 !== undefined ? ({
          TAG: /* Variant */1,
          _0: {
            id: match$1.id,
            shopId: activeShop.id
          }
        }) : undefined;
    var availableShops = Belt_Array.keep(shopsData, (function (shop) {
            return shop.duplicability === /* ExistsAlready */1;
          }));
    var source$1 = availableShops.length === 1 ? Belt_Option.mapWithDefault(Belt_Array.getBy(references, (function (param) {
                  return param.shopId === Belt_Array.getExn(availableShops, 0).dataId;
                })), source, (function (param) {
              return {
                      TAG: /* Variant */1,
                      _0: {
                        id: param.id,
                        shopId: param.shopId
                      }
                    };
            })) : source;
    return /* Data */{
            _0: {
              references: references,
              shopsData: shopsData,
              initialValues: {
                source: source$1,
                destinations: []
              }
            }
          };
  }
  if (productResponse.TAG !== /* Executed */0) {
    return /* Loading */1;
  }
  var match$2 = productResponse._0;
  var productData = match$2.data;
  if (match$2.loading) {
    return /* Loading */1;
  }
  if (productData === undefined) {
    return /* Error */0;
  }
  if (scope.TAG !== /* Organisation */0) {
    return /* Error */0;
  }
  var references$1 = referencesFromProductData(productData, shops);
  var shopsData$1 = shopsDataFromReferences(references$1, shops);
  var match$3 = Belt_Array.getBy(references$1, (function (param) {
          return param.shopId === Belt_Option.map(activeShop, (function (param) {
                        return param.id;
                      }));
        }));
  var source$2 = activeShop !== undefined && match$3 !== undefined ? ({
        TAG: /* Product */0,
        _0: {
          id: match$3.id,
          shopId: activeShop.id
        }
      }) : undefined;
  var availableShops$1 = Belt_Array.keep(shopsData$1, (function (shop) {
          return shop.duplicability === /* ExistsAlready */1;
        }));
  var source$3 = availableShops$1.length === 1 ? Belt_Option.mapWithDefault(Belt_Array.getBy(references$1, (function (param) {
                return param.shopId === Belt_Array.getExn(availableShops$1, 0).dataId;
              })), source$2, (function (param) {
            return {
                    TAG: /* Product */0,
                    _0: {
                      id: param.id,
                      shopId: param.shopId
                    }
                  };
          })) : source$2;
  return /* Data */{
          _0: {
            references: references$1,
            shopsData: shopsData$1,
            initialValues: {
              source: source$3,
              destinations: []
            }
          }
        };
}

var ProductsQuery;

var VariantsQuery;

export {
  ProductsQuery ,
  VariantsQuery ,
  referencesFromProductData ,
  referencesFromVariantData ,
  shopsDataFromReferences ,
  use ,
}
/* Auth Not a pure module */
