// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactDom from "react-dom";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function fromRef(param) {
  var current = param.current;
  return Belt_Option.map(Belt_Option.map((current == null) ? undefined : Caml_option.some(current), (function (prim) {
                    return ReactDom.findDOMNode(prim);
                  })), (function (prim) {
                return prim;
              }));
}

var setDomElementStyleProp = ((domElement, styleProp, value) =>  domElement.style[styleProp] = value);

function toOptionalDomElement(prim) {
  return Caml_option.nullable_to_opt(prim.current);
}

function focus(prim) {
  prim.focus();
}

function blur(prim) {
  prim.blur();
}

export {
  fromRef ,
  toOptionalDomElement ,
  focus ,
  blur ,
  setDomElementStyleProp ,
}
/* react-dom Not a pure module */
