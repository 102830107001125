// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Page from "../../resources/layout-and-structure/Page.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Notifier from "../../bundles/Notifier/Notifier.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CatalogProduct from "../../modules/Catalog/CatalogProduct.bs.js";
import * as CatalogProductEditFormPageActions from "../../modules/Catalog/CatalogProductEditFormPageActions.bs.js";
import * as CatalogProductEditFormInformationFieldsetPanel from "../../modules/Catalog/CatalogProductEditFormInformationFieldsetPanel.bs.js";
import * as CatalogProductEditFormOrganisationFieldsetPanel from "../../modules/Catalog/CatalogProductEditFormOrganisationFieldsetPanel.bs.js";

function CatalogProductEditPage(Props) {
  var shopName = Props.shopName;
  var editionMode = Props.editionMode;
  var productKind = Props.productKind;
  var contextData = Props.contextData;
  var notifier = Notifier.use(undefined, undefined);
  var renderActions = function (param) {
    return React.createElement(CatalogProductEditFormPageActions.make, {
                editionMode: editionMode
              });
  };
  var formattedTitle;
  var exit = 0;
  if (editionMode && contextData !== undefined) {
    formattedTitle = $$Intl.t("Editing") + " " + contextData.name;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var productLabel = CatalogProduct.Kind.toLabel(undefined, false, productKind);
    formattedTitle = $$Intl.template($$Intl.t("Step {{step}} | Create {{fulfill}}"), {
          step: "1",
          fulfill: $$Intl.t("a new " + productLabel)
        }, undefined);
  }
  return React.createElement(Page.make, {
              children: null,
              title: formattedTitle,
              subtitle: shopName,
              renderActions: renderActions
            }, React.createElement(Notifier.Banner.make, {
                  notifier: notifier
                }), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Stack.make, {
                              children: null,
                              space: "xlarge"
                            }, React.createElement(CatalogProductEditFormInformationFieldsetPanel.make, {}), editionMode ? null : React.createElement(CatalogProductEditFormOrganisationFieldsetPanel.make, {}))), undefined, undefined, "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var make = React.memo(CatalogProductEditPage);

var Config;

export {
  Config ,
  make ,
}
/* make Not a pure module */
