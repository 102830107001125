// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Banner from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Notifier__Context from "./Notifier__Context.bs.js";
import * as Notifier__Reducer from "./Notifier__Reducer.bs.js";

var autoClearRef = {
  contents: false
};

function createContext(param) {
  var match = React.useReducer(Notifier__Reducer.reducer, Notifier__Reducer.initialState);
  var dispatch = match[1];
  var url = Navigation.useUrl(undefined);
  React.useEffect((function () {
          if (autoClearRef.contents) {
            Curry._1(dispatch, /* ClearRequested */0);
          }
          
        }), [url.pathname]);
  return [
          match[0],
          dispatch
        ];
}

function add(dispatch, action, resetOpt, details, param) {
  var reset = resetOpt !== undefined ? resetOpt : false;
  var node;
  switch (action.TAG | 0) {
    case /* Success */0 :
        node = {
          kind: "success",
          message: action._0,
          details: details,
          reset: reset
        };
        break;
    case /* Error */1 :
        node = {
          kind: "error",
          message: action._0,
          details: details,
          reset: reset
        };
        break;
    case /* Warning */2 :
        node = {
          kind: "warning",
          message: action._0,
          details: details,
          reset: reset
        };
        break;
    
  }
  Curry._1(dispatch, {
        TAG: /* AddRequested */0,
        _0: node
      });
}

function use(clearPolicyOpt, param) {
  var clearPolicy = clearPolicyOpt !== undefined ? clearPolicyOpt : /* ClearOnHistoryChanges */0;
  var match = Notifier__Context.Context.use(undefined);
  var dispatch = match[1];
  autoClearRef.contents = clearPolicy === /* ClearOnHistoryChanges */0;
  var make = function (raw) {
    return {
            id: raw.id,
            content: raw.content,
            onClose: (function (param) {
                Curry._1(dispatch, {
                      TAG: /* RemoveRequested */1,
                      _0: raw.id
                    });
              })
          };
  };
  return {
          get: Belt_List.map(match[0], make),
          add: (function (action, details, param) {
              add(dispatch, action, undefined, details, undefined);
            }),
          reset: (function (action, details, param) {
              add(dispatch, action, true, details, undefined);
            }),
          clear: (function (param) {
              Curry._1(dispatch, /* ClearRequested */0);
            })
        };
}

function Notifier$Banner(Props) {
  var notifier = Props.notifier;
  return React.createElement(View.make, View.makeProps(Caml_option.some(Belt_List.toArray(Belt_List.map(notifier.get, (function (param) {
                                var content = param.content;
                                var match = content.kind;
                                var textStatus = match === "warning" ? ({
                                      TAG: /* Warning */2,
                                      _0: content.message
                                    }) : (
                                    match === "success" ? ({
                                          TAG: /* Success */0,
                                          _0: content.message
                                        }) : ({
                                          TAG: /* Danger */1,
                                          _0: content.message
                                        })
                                  );
                                var tmp = {
                                  textStatus: textStatus,
                                  onRequestClose: param.onClose,
                                  key: param.id
                                };
                                if (content.details !== undefined) {
                                  tmp.details = Caml_option.valFromOption(content.details);
                                }
                                return React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Banner.make, tmp)), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
                              })))), Caml_option.some(Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(100)), undefined, undefined)), undefined, undefined, undefined));
}

var make = React.memo(Notifier$Banner, (function (oldProps, newProps) {
        return oldProps.notifier.get === newProps.notifier.get;
      }));

var Banner$1 = {
  make: make
};

var Context = Notifier__Context.Context;

var Provider = Notifier__Context.Provider;

var Types;

export {
  Context ,
  Provider ,
  Types ,
  autoClearRef ,
  createContext ,
  add ,
  use ,
  Banner$1 as Banner,
}
/* make Not a pure module */
