// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as CatalogVariantStockThresholdForm from "./CatalogVariantStockThresholdForm.bs.js";

function CatalogVariantStockThresholdFormInputs(Props) {
  return React.createElement(Stack.make, {
              children: null,
              space: "medium"
            }, React.createElement(CatalogVariantStockThresholdForm.InputNumber.make, {
                  field: /* MaxStockThreshold */0,
                  label: $$Intl.t("Maximum stock"),
                  hideRequired: true,
                  minValue: 0,
                  precision: 0
                }), React.createElement(CatalogVariantStockThresholdForm.InputNumber.make, {
                  field: /* MinStockThreshold */1,
                  label: $$Intl.t("Minimum stock"),
                  hideRequired: true,
                  minValue: 0,
                  precision: 0
                }), React.createElement(CatalogVariantStockThresholdForm.InputNumber.make, {
                  field: /* StockOrderTriggerThreshold */2,
                  label: $$Intl.t("Stock triggering the order"),
                  hideRequired: true,
                  minValue: 0,
                  precision: 0
                }));
}

var make = React.memo(CatalogVariantStockThresholdFormInputs);

export {
  make ,
}
/* make Not a pure module */
