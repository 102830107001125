// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Field from "../layout-and-structure/Field.bs.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Touchable from "../../primitives/Touchable.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Stylex from "@stylexjs/stylex";

var styles = Stylex.create({
      root: {
        backgroundColor: "#fefefe",
        alignItems: "center",
        display: "flex",
        flex: "1",
        flexDirection: "row",
        height: "40px",
        overflow: "hidden"
      },
      item: {
        boxSizing: "border-box",
        borderRadius: "5px",
        alignItems: "center",
        display: "flex",
        flex: "1",
        height: "40px",
        justifyContent: "center",
        minWidth: "40px",
        padding: "0 15px"
      }
    });

function itemStyleProps(active, compact, firstPosition, lastPosition) {
  return Stylex.props(styles.item, {
              backgroundColor: active ? "#f8f8fb" : "white",
              border: "1px solid " + (
                active ? "#797885" : "#d7d7e0"
              ),
              borderRight: "1px solid transparent",
              padding: compact ? "0 10px" : "0 15px"
            }, firstPosition ? (
                lastPosition ? ({
                      borderRadius: "5px"
                    }) : ({
                      borderBottomRightRadius: "0px",
                      borderRightColor: active ? "#797885" : "#d7d7e0",
                      borderTopRightRadius: "0px"
                    })
              ) : (
                lastPosition ? ({
                      borderBottomLeftRadius: "0px",
                      borderRightColor: active ? "#797885" : "#d7d7e0",
                      borderTopLeftRadius: "0px"
                    }) : ({
                      borderRadius: "0px"
                    })
              ));
}

function itemTextStyleProps(compact) {
  return Stylex.props(compact ? ({
                  color: "#797785",
                  font: "normal 400 16px \"Libre Franklin\""
                }) : ({
                  color: "#25243a",
                  font: "normal 400 14px \"Archivo\""
                }));
}

function InputSegmentedControlsField(Props) {
  var label = Props.label;
  var growOpt = Props.grow;
  var compactOpt = Props.compact;
  var tooltip = Props.tooltip;
  var derivedValue = Props.value;
  var required = Props.required;
  var onChange = Props.onChange;
  var errorMessage = Props.errorMessage;
  var options = Props.options;
  var optionToText = Props.optionToText;
  var grow = growOpt !== undefined ? growOpt : false;
  var compact = compactOpt !== undefined ? compactOpt : false;
  var match = React.useState(function () {
        return derivedValue;
      });
  var setValue = match[1];
  var value = match[0];
  React.useEffect((function () {
          if (Caml_obj.notequal(derivedValue, value)) {
            Curry._1(setValue, (function (param) {
                    return derivedValue;
                  }));
          }
          
        }), [derivedValue]);
  var match$1 = Stylex.props(styles.root);
  var match$2 = itemTextStyleProps(compact);
  var itemTextClassName = match$2.className;
  var itemTextStyle = match$2.style;
  var tmp = {};
  if (match$1.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match$1.className);
  }
  if (match$1.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match$1.style);
  }
  return React.createElement(Field.make, Field.makeProps(React.createElement("div", tmp, Belt_List.toArray(Belt_List.mapWithIndex(options, (function (index, item) {
                                var match = itemStyleProps(item === value, compact, index === 0, (index + 1 | 0) === Belt_List.length(options));
                                var tmp = {};
                                if (match.className !== undefined) {
                                  tmp.className = Caml_option.valFromOption(match.className);
                                }
                                if (match.style !== undefined) {
                                  tmp.style = Caml_option.valFromOption(match.style);
                                }
                                var tmp$1 = {};
                                if (itemTextClassName !== undefined) {
                                  tmp$1.className = Caml_option.valFromOption(itemTextClassName);
                                }
                                if (itemTextStyle !== undefined) {
                                  tmp$1.style = Caml_option.valFromOption(itemTextStyle);
                                }
                                return React.createElement(Touchable.make, Touchable.makeProps(React.createElement("div", tmp, React.createElement("span", tmp$1, Curry._1(optionToText, item))), undefined, undefined, undefined, undefined, {
                                                display: grow ? "flex" : "block",
                                                flex: grow ? "1" : "auto"
                                              }, (function (param) {
                                                  Curry._1(setValue, (function (param) {
                                                          return item;
                                                        }));
                                                  requestAnimationFrame(function (param) {
                                                        Curry._1(onChange, item);
                                                      });
                                                }), undefined, undefined, undefined));
                              })))), label, undefined, tooltip, errorMessage, undefined, required, undefined, undefined, undefined));
}

var make = InputSegmentedControlsField;

export {
  make ,
}
/* styles Not a pure module */
