// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Json from "../../primitives/Json.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as $$Request from "../../core/Request.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as ReactUpdateDebounced from "../../primitives/ReactUpdateDebounced.bs.js";
import * as InputSearchComboBoxField from "../../resources/selection-and-input/InputSearchComboBoxField.bs.js";

function decodeItem(result) {
  var dict = Json.flatDecodeDictFieldDict(Json.decodeDict(result), "properties");
  var match = Json.flatDecodeDictFieldString(dict, "label");
  var match$1 = Json.flatDecodeDictFieldString(dict, "name");
  var match$2 = Belt_Option.getWithDefault(Json.flatDecodeDictFieldString(dict, "postcode"), "");
  var match$3 = Belt_Option.getWithDefault(Json.flatDecodeDictFieldString(dict, "city"), "");
  var match$4 = Belt_Option.getWithDefault(Json.flatDecodeDictFieldString(dict, "country"), "France");
  if (match !== undefined && match$1 !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: {
              label: match,
              name: match$1,
              postcode: match$2,
              city: match$3,
              country: match$4
            }
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
}

function decode(result) {
  var decodedResult;
  try {
    decodedResult = Belt_Option.getExn(Belt_Option.map(Json.flatDecodeDictFieldArray(Json.decodeDict(result), "features"), (function (arrayResult) {
                return Belt_Array.map(arrayResult, (function (address) {
                              return Belt_Result.getExn(decodeItem(address));
                            }));
              })));
  }
  catch (exn){
    return {
            TAG: /* Error */1,
            _0: /* MalformedResponse */1
          };
  }
  return {
          TAG: /* Ok */0,
          _0: decodedResult
        };
}

function make(query, param) {
  var endpoint = "https://api-adresse.data.gouv.fr/search?q=" + query;
  return Future.mapResult($$Request.make("GET", undefined, false, true, endpoint), undefined, decode);
}

var AddressListRequest = {
  decodeItem: decodeItem,
  decode: decode,
  make: make
};

function SupplierAddressComboBoxField(Props) {
  var errorMessage = Props.errorMessage;
  var requiredOpt = Props.required;
  var value = Props.addressName;
  var onInputChange = Props.onInputChange;
  var onRequestAutoComplete = Props.onRequestAutoComplete;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var match = React.useState(function () {
        return [];
      });
  var setSearchResults = match[1];
  var searchResults = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoading = match$1[1];
  var loading = ReactUpdateDebounced.use(match$1[0], 500);
  var shouldFetch = value.length >= 10;
  React.useEffect((function () {
          if (shouldFetch) {
            Curry._1(setLoading, (function (param) {
                    return true;
                  }));
            var request = make(value, undefined);
            var future = Future.tapOk(request, (function (searchResults) {
                    Curry._1(setSearchResults, (function (param) {
                            return searchResults;
                          }));
                  }));
            return (function (param) {
                      Future.cancel(future);
                    });
          }
          Curry._1(setSearchResults, (function (param) {
                  return [];
                }));
        }), [value]);
  React.useEffect((function () {
          Curry._1(setLoading, (function (param) {
                  return false;
                }));
        }), [searchResults]);
  React.useEffect((function () {
          if (value === "") {
            Curry._1(onRequestAutoComplete, {
                  label: "",
                  name: "",
                  postcode: "",
                  city: "",
                  country: ""
                });
          }
          
        }), [value]);
  var items = Belt_Array.map(searchResults, (function (address) {
          return {
                  key: address.label,
                  label: "" + address.name + ", " + address.postcode + " " + address.city + "",
                  value: address
                };
        }));
  var onInputChange$1 = React.useCallback((function (value) {
          var itemFromLabel = Belt_Array.getBy(items, (function (item) {
                  return item.label === value;
                }));
          if (itemFromLabel !== undefined) {
            return Curry._1(onInputChange, itemFromLabel.value.name);
          } else {
            return Curry._1(onInputChange, value);
          }
        }), [items]);
  var onSelectItem = React.useCallback((function (item) {
          Curry._1(onRequestAutoComplete, item.value);
        }), []);
  var showResults = ReactUpdateDebounced.use(shouldFetch, 350);
  var tmp = {
    label: $$Intl.t("Address"),
    required: required,
    loading: loading,
    placeholder: $$Intl.t("Search an address"),
    noResultLabel: $$Intl.t("No address suggestion"),
    showResults: showResults,
    items: items,
    value: value,
    onInputChange: onInputChange$1,
    onSelectionChange: onSelectItem
  };
  if (errorMessage !== undefined) {
    tmp.errorMessage = errorMessage;
  }
  return React.createElement(InputSearchComboBoxField.make, tmp);
}

var queryValueThreshold = 10;

var make$1 = SupplierAddressComboBoxField;

export {
  AddressListRequest ,
  queryValueThreshold ,
  make$1 as make,
}
/* Intl Not a pure module */
