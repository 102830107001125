// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../layout-and-structure/Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as Table from "./Table.bs.js";
import * as React from "react";
import * as DropZone from "../selection-and-input/DropZone.bs.js";
import * as AsyncData from "../../primitives/AsyncData.bs.js";
import * as SearchBar from "../selection-and-input/SearchBar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Placeholder from "../layout-and-structure/Placeholder.bs.js";
import * as SliderTrack from "../layout-and-structure/SliderTrack.bs.js";

function TableView$TableFilterBar(Props) {
  var filters = Props.filters;
  return React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(SliderTrack.make, {
                          children: filters,
                          compact: true,
                          gap: "small",
                          layout: /* Adaptative */0
                        })), "large", undefined, undefined, "xmedium", undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = React.memo(function (Props) {
      var children = Props.children;
      var displayed = Props.displayed;
      if (displayed) {
        return React.createElement(Box.make, Box.makeProps(Caml_option.some(children), undefined, undefined, "xmedium", undefined, undefined, undefined, true, Caml_option.some(Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#fefefe", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 6, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined));
      } else {
        return children;
      }
    });

function TableView(Props) {
  var data = Props.data;
  var columns = Props.columns;
  var keyExtractor = Props.keyExtractor;
  var disabledRowsKeys = Props.disabledRowsKeys;
  var erroredRowsMap = Props.erroredRowsMap;
  var selectionEnabled = Props.selectionEnabled;
  var selectAllEnabled = Props.selectAllEnabled;
  var initialAllSelected = Props.initialAllSelected;
  var hideCardOpt = Props.hideCard;
  var hideReloadingPlaceholderOpt = Props.hideReloadingPlaceholder;
  var maxWidth = Props.maxWidth;
  var minHeight = Props.minHeight;
  var maxHeight = Props.maxHeight;
  var compactRows = Props.compactRows;
  var placeholderEmptyStateOpt = Props.placeholderEmptyState;
  var searchBar = Props.searchBar;
  var searchPlaceholderOpt = Props.searchPlaceholder;
  var filtersOpt = Props.filters;
  var typesDrop = Props.typesDrop;
  var sortDescriptor = Props.sortDescriptor;
  var onSortChange = Props.onSortChange;
  var onSearchQueryChange = Props.onSearchQueryChange;
  var onLoadMoreProp = Props.onLoadMore;
  var onSelectChange = Props.onSelectChange;
  var onSuccessDrop = Props.onSuccessDrop;
  var onErrorDrop = Props.onErrorDrop;
  var hideCard = hideCardOpt !== undefined ? hideCardOpt : false;
  var hideReloadingPlaceholder = hideReloadingPlaceholderOpt !== undefined ? hideReloadingPlaceholderOpt : false;
  var placeholderEmptyState = placeholderEmptyStateOpt !== undefined ? Caml_option.valFromOption(placeholderEmptyStateOpt) : React.createElement(Placeholder.make, {
          status: /* NoDataAvailable */1
        });
  var searchPlaceholder = searchPlaceholderOpt !== undefined ? searchPlaceholderOpt : $$Intl.t("Search");
  var filters = filtersOpt !== undefined ? filtersOpt : [];
  var onLoadMore = AsyncData.isIdle(data) ? onLoadMoreProp : undefined;
  var footerComponent;
  var exit = 0;
  if (typeof data === "number") {
    footerComponent = data === /* NotAsked */0 ? Caml_option.some(placeholderEmptyState) : Caml_option.some(React.createElement(Placeholder.make, {
                status: /* Loading */0
              }));
  } else if (data.TAG === /* Reloading */0) {
    if (hideReloadingPlaceholder) {
      exit = data._0.TAG === /* Ok */0 ? 2 : 1;
    } else {
      footerComponent = Caml_option.some(React.createElement(Placeholder.make, {
                status: /* Loading */0
              }));
    }
  } else {
    var match = data._0;
    if (match.TAG === /* Ok */0) {
      if (match._0.length !== 0) {
        exit = 2;
      } else {
        footerComponent = Caml_option.some(placeholderEmptyState);
      }
    } else {
      exit = 1;
    }
  }
  switch (exit) {
    case 1 :
        footerComponent = Caml_option.some(React.createElement(Placeholder.make, {
                  status: /* Error */2
                }));
        break;
    case 2 :
        footerComponent = Belt_Option.isSome(onLoadMoreProp) && AsyncData.isReloading(data) ? Caml_option.some(React.createElement(Placeholder.make, {
                    status: /* Loading */0,
                    compact: true
                  })) : undefined;
        break;
    
  }
  var rows;
  if (typeof data === "number") {
    rows = data === /* NotAsked */0 ? [] : [];
  } else if (data.TAG === /* Reloading */0) {
    var rows$1 = data._0;
    rows = rows$1.TAG === /* Ok */0 ? rows$1._0 : [];
  } else {
    var rows$2 = data._0;
    rows = rows$2.TAG === /* Ok */0 ? rows$2._0 : [];
  }
  var tmp = {
    columns: columns,
    rows: rows,
    keyExtractor: keyExtractor
  };
  if (disabledRowsKeys !== undefined) {
    tmp.disabledRowsKeys = Caml_option.valFromOption(disabledRowsKeys);
  }
  if (erroredRowsMap !== undefined) {
    tmp.erroredRowsMap = Caml_option.valFromOption(erroredRowsMap);
  }
  if (selectionEnabled !== undefined) {
    tmp.selectionEnabled = selectionEnabled;
  }
  if (selectAllEnabled !== undefined) {
    tmp.selectAllEnabled = selectAllEnabled;
  }
  if (initialAllSelected !== undefined) {
    tmp.initialAllSelected = initialAllSelected;
  }
  if (sortDescriptor !== undefined) {
    tmp.sortDescriptor = Caml_option.valFromOption(sortDescriptor);
  }
  if (maxWidth !== undefined) {
    tmp.maxWidth = maxWidth;
  }
  if (minHeight !== undefined) {
    tmp.minHeight = minHeight;
  }
  if (maxHeight !== undefined) {
    tmp.maxHeight = maxHeight;
  }
  if (compactRows !== undefined) {
    tmp.compactRows = compactRows;
  }
  if (footerComponent !== undefined) {
    tmp.footerComponent = Caml_option.valFromOption(footerComponent);
  }
  if (onLoadMore !== undefined) {
    tmp.onLoadMore = Caml_option.valFromOption(onLoadMore);
  }
  if (onSelectChange !== undefined) {
    tmp.onSelectChange = Caml_option.valFromOption(onSelectChange);
  }
  if (onSortChange !== undefined) {
    tmp.onSortChange = Caml_option.valFromOption(onSortChange);
  }
  var tableComponent = React.createElement(Table.make, tmp);
  var tmp$1;
  if (onSuccessDrop !== undefined && onErrorDrop !== undefined) {
    var tmp$2 = {
      children: tableComponent,
      onSuccess: onSuccessDrop,
      onError: onErrorDrop
    };
    if (typesDrop !== undefined) {
      tmp$2.types = Caml_option.valFromOption(typesDrop);
    }
    tmp$1 = React.createElement(DropZone.make, tmp$2);
  } else {
    tmp$1 = tableComponent;
  }
  return React.createElement(make, {
              children: null,
              displayed: !hideCard
            }, searchBar !== undefined ? Caml_option.valFromOption(searchBar) : (
                onSearchQueryChange !== undefined ? React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(SearchBar.make, {
                                    placeholder: searchPlaceholder,
                                    onChange: onSearchQueryChange
                                  })), "large", undefined, undefined, "xmedium", undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null
              ), filters.length !== 0 ? React.createElement(TableView$TableFilterBar, {
                    filters: filters
                  }) : null, tmp$1);
}

var make$1 = TableView;

export {
  make$1 as make,
}
/* make Not a pure module */
