// Generated by ReScript, PLEASE EDIT WITH CARE

import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Form__Status from "./Form__Status.bs.js";
import * as ReactDomElement from "../../primitives/ReactDomElement.bs.js";
import * as ReactUpdateEffect from "../../primitives/ReactUpdateEffect.bs.js";
import * as ReactUpdateDebounced from "../../primitives/ReactUpdateDebounced.bs.js";

function Make(Params) {
  var Form__Elements__Accessibility$Make$ControlEnterKey = function (Props) {
    var onSubmit = Props.onSubmit;
    var ref = React.useRef(null);
    var match = Curry._1(Params.useSubmit, onSubmit);
    var onSubmit$1 = match.onSubmit;
    var disabled = match.disabled;
    var onKeyDown = React.useCallback((function (evt) {
            var match = evt.key;
            if (match !== "Enter") {
              return ;
            }
            if (disabled) {
              return ;
            }
            var htmlDocument = document;
            var parentDomElement = Belt_Option.flatMap(ReactDomElement.fromRef(ref), (function (prim) {
                    return Caml_option.nullable_to_opt(prim.parentElement);
                  }));
            var match$1 = htmlDocument.activeElement;
            if (parentDomElement !== undefined && !(match$1 == null)) {
              return Belt_Array.forEach(Array.prototype.slice.call(Caml_option.valFromOption(parentDomElement).getElementsByTagName("input")), (function (inputNode) {
                            if (inputNode === match$1) {
                              return Curry._1(onSubmit$1, undefined);
                            }
                            
                          }));
            }
            
          }), [disabled]);
    React.useEffect((function () {
            var domElement = document;
            domElement.addEventListener("keydown", onKeyDown);
            return (function (param) {
                      domElement.removeEventListener("keydown", onKeyDown);
                    });
          }), [ref]);
    return React.createElement(View.make, View.makeProps(undefined, undefined, undefined, ref, undefined));
  };
  var ControlEnterKey = {
    make: Form__Elements__Accessibility$Make$ControlEnterKey
  };
  var Form__Elements__Accessibility$Make$AutoSave = function (Props) {
    var onSubmit = Props.onSubmit;
    var disabledOpt = Props.disabled;
    var delayOpt = Props.delay;
    var disabled = disabledOpt !== undefined ? disabledOpt : false;
    var delay = delayOpt !== undefined ? delayOpt : 750;
    var match = Curry._1(Params.useSubmit, onSubmit);
    var onSubmit$1 = match.onSubmit;
    var submitDisabled = match.disabled;
    var status = Curry._1(Params.useFormStatus, undefined);
    var values = Curry._1(Params.useFormValues, undefined);
    var debouncedValues = ReactUpdateDebounced.use(values, delay);
    ReactUpdateEffect.use1((function (param) {
            if (Form__Status.isValid(status) && !submitDisabled && !disabled) {
              Curry._1(onSubmit$1, undefined);
            }
            
          }), [debouncedValues]);
    return null;
  };
  var AutoSave = {
    make: Form__Elements__Accessibility$Make$AutoSave
  };
  return {
          ControlEnterKey: ControlEnterKey,
          AutoSave: AutoSave
        };
}

var Status;

var Submission;

export {
  Status ,
  Submission ,
  Make ,
}
/* View Not a pure module */
