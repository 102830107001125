// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Page from "../../resources/layout-and-structure/Page.bs.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BillingAccountInvoicesTable from "../../modules/Billing/BillingAccountInvoicesTable.bs.js";

function SettingsBillingAccountListInvoicesPage(Props) {
  var shopId = Props.shopId;
  var invoicesRequest = Props.invoicesRequest;
  var shops = Auth.useShops(undefined);
  var shop = Belt_Array.getBy(shops, (function (shop) {
          return shop.id === shopId;
        }));
  return React.createElement(Page.make, {
              children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(BillingAccountInvoicesTable.make, {
                                shopId: shopId,
                                invoicesRequest: invoicesRequest
                              })), undefined, undefined, "medium", undefined, undefined, undefined, true, undefined, undefined, undefined, undefined)),
              title: $$Intl.t("Invoice history"),
              subtitle: shop.name
            });
}

var make = React.memo(SettingsBillingAccountListInvoicesPage);

export {
  make ,
}
/* make Not a pure module */
