// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as React from "react";
import * as OrderEditForm from "./OrderEditForm.bs.js";

function OrderMemoCard(Props) {
  return React.createElement(Card.make, {
              children: React.createElement(OrderEditForm.InputTextArea.make, {
                    field: /* Note */5,
                    label: $$Intl.t("Internal memo")
                  }),
              title: "Note"
            });
}

var make = React.memo(OrderMemoCard);

export {
  make ,
}
/* make Not a pure module */
