// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Qs from "qs";
import * as Json from "./Json.bs.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function stringifyEncoder(primitive) {
  return encodeURIComponent(Belt_Option.getWithDefault(Belt_Option.orElse(Belt_Option.orElse(Belt_Option.orElse(Belt_Option.map(Json.decodeString(primitive), (function (string) {
                                    return "\"" + string.replace(/"/g, "\\\"") + "\"";
                                  })), Belt_Option.map(Json.decodeBoolean(primitive), Pervasives.string_of_bool)), Belt_Option.map(Json.decodeNumber(primitive), (function (prim) {
                                return String(prim);
                              }))), Belt_Option.map(Json.decodeNull(primitive), (function (param) {
                            return "null";
                          }))), ""));
}

function stringify(json) {
  return Qs.stringify(json, {
              encodeValuesOnly: true,
              encoder: stringifyEncoder
            });
}

function parseDecoder(string, param, param$1, type_) {
  if (type_ !== "value") {
    return Json.encodeString(string);
  }
  var decodedString = decodeURIComponent(string);
  switch (decodedString) {
    case "false" :
        return Json.encodeBoolean(false);
    case "null" :
        return Json.encodedNull;
    case "true" :
        return Json.encodeBoolean(true);
    case "" :
    case "undefined" :
        return ;
    default:
      if (decodedString.startsWith("\"") && decodedString.endsWith("\"")) {
        return Json.encodeString(decodedString.slice(1, -1).replace(/\\"/g, "\""));
      } else if (/^-?\d+(.\d+)?$/.test(string)) {
        return Json.encodeNumber(Number(string));
      } else {
        return Json.encodeString(string);
      }
  }
}

function parse(query) {
  return Qs.parse(query, {
              ignoreQueryPrefix: true,
              decoder: parseDecoder
            });
}

export {
  stringifyEncoder ,
  stringify ,
  parseDecoder ,
  parse ,
}
/* qs Not a pure module */
