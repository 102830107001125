// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactUpdateEffect from "./ReactUpdateEffect.bs.js";

function use(value, delay) {
  var match = React.useState(function () {
        return value;
      });
  var setDebouncedValue = match[1];
  ReactUpdateEffect.use1((function (param) {
          var handlerId = setTimeout((function (param) {
                  Curry._1(setDebouncedValue, (function (param) {
                          return value;
                        }));
                }), delay);
          return (function (param) {
                    clearTimeout(handlerId);
                  });
        }), [value]);
  return match[0];
}

export {
  use ,
}
/* react Not a pure module */
