// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Accounting__Utils from "@wino/accounting/src/Accounting__Utils.bs.js";

var styles = $$StyleSheet.create({
      wrapper: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexEnd", undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      row: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(1)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(3)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      subrow: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(2)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(5)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(3)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      label: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(194)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      amount: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexStart", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(100)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function currencyFormat(value) {
  return $$Intl.currencyFormat("EUR", 3, 3, value);
}

function CartAmountsView(Props) {
  var cart = Props.cart;
  var formattedTotalAmountExcludingTaxes = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(cart.totalAmountExcludingTaxes)));
  var formattedTotalAmountExcludingGlobalDiscounts = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(cart.totalAmountExcludingGlobalDiscounts)));
  var formattedTotalAmountOfGoods = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(cart.totalAmountOfGoods)));
  var formattedTotalAmountIncludingTaxes = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(cart.totalAmountIncludingTaxes)));
  var discount = Belt_Array.get(cart.discounts, 0);
  var tmp;
  if (discount !== undefined && discount.value > 0) {
    var formattedDiscountAmount = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(discount.amount)));
    tmp = React.createElement(Box.make, Box.makeProps(Caml_option.some(null), undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.row), undefined, undefined, undefined), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                              children: $$Intl.t("Sub total excl. VAT"),
                              size: "large"
                            })), Caml_option.some(styles.label), undefined, undefined, undefined)), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                              children: formattedTotalAmountExcludingGlobalDiscounts,
                              size: "large"
                            })), Caml_option.some(styles.amount), undefined, undefined, undefined))), React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.row), undefined, undefined, undefined), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                              children: $$Intl.template($$Intl.t("Discount {{amount}}"), {
                                    amount: Belt_Option.getExn(discount.formattedValue)
                                  }, undefined),
                              size: "large"
                            })), Caml_option.some(styles.label), undefined, undefined, undefined)), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                              children: formattedDiscountAmount,
                              size: "large"
                            })), Caml_option.some(styles.amount), undefined, undefined, undefined))));
  } else {
    tmp = null;
  }
  var taxes = cart.taxes;
  return React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.wrapper), undefined, undefined, undefined), tmp, React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.row), undefined, undefined, undefined), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                  children: $$Intl.t("Total amount excl. VAT:"),
                                  weight: cart.taxesFree ? "strong" : "regular",
                                  size: "large"
                                })), Caml_option.some(styles.label), undefined, undefined, undefined)), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                  children: formattedTotalAmountExcludingTaxes,
                                  weight: cart.taxesFree ? "strong" : "regular",
                                  size: "large"
                                })), Caml_option.some(styles.amount), undefined, undefined, undefined))), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Stack.make, {
                              children: null,
                              space: "xxsmall"
                            }, React.createElement(Inline.make, {
                                  children: null
                                }, React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                                  children: $$Intl.t("Goods total excl. VAT"),
                                                  variation: "normal",
                                                  size: "xsmall"
                                                })), Caml_option.some(styles.label), undefined, undefined, undefined)), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                                  children: formattedTotalAmountOfGoods,
                                                  variation: "normal",
                                                  size: "xsmall"
                                                })), Caml_option.some(styles.amount), undefined, undefined, undefined))), Belt_Array.mapWithIndex(Belt_Option.getExn(cart.fees), (function (index, fee) {
                                    var formattedFeeAmount = currencyFormat(Accounting__Utils.bigToFloat(fee.amount));
                                    var match = fee.kind;
                                    var tmp;
                                    switch (match) {
                                      case /* Transport */0 :
                                          tmp = $$Intl.t("Transport");
                                          break;
                                      case /* Taxes */1 :
                                          tmp = $$Intl.t("Taxes");
                                          break;
                                      case /* Other */2 :
                                          tmp = $$Intl.t("Other");
                                          break;
                                      
                                    }
                                    return React.createElement(Inline.make, {
                                                children: null,
                                                key: String(index)
                                              }, React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                                                children: $$Intl.template($$Intl.t("Total {{feeKind}}"), {
                                                                      feeKind: tmp
                                                                    }, undefined),
                                                                variation: "normal",
                                                                size: "xsmall"
                                                              })), Caml_option.some(styles.label), undefined, undefined, undefined)), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                                                children: formattedFeeAmount,
                                                                variation: "normal",
                                                                size: "xsmall"
                                                              })), Caml_option.some(styles.amount), undefined, undefined, undefined)));
                                  })), React.createElement(Box.make, Box.makeProps(undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)))), Caml_option.some(styles.subrow), undefined, undefined, undefined)), taxes !== undefined ? Belt_Array.map(taxes, (function (tax) {
                      var formattedTaxAmount = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(tax.amount)));
                      return React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.row), formattedTaxAmount + tax.rate.toString(), undefined, undefined), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                                  children: $$Intl.template($$Intl.t("VAT {{amount}}"), {
                                                        amount: tax.rate.toString().replace(".", ",")
                                                      }, undefined),
                                                  size: "large"
                                                })), Caml_option.some(styles.label), undefined, undefined, undefined)), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                                  children: formattedTaxAmount,
                                                  size: "large"
                                                })), Caml_option.some(styles.amount), undefined, undefined, undefined)));
                    })) : null, cart.taxesFree ? React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                children: $$Intl.template($$Intl.t("{{amount}} of deductible VAT (0.00%)"), {
                                      amount: currencyFormat(0.00)
                                    }, undefined),
                                variation: "normal",
                                size: "large"
                              })), Caml_option.some(Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexStart", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(28)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined)) : React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.row), undefined, undefined, undefined), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                    children: $$Intl.t("Total amount incl. VAT:"),
                                    weight: "strong",
                                    size: "large"
                                  })), Caml_option.some(styles.label), undefined, undefined, undefined)), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                    children: formattedTotalAmountIncludingTaxes,
                                    weight: "strong",
                                    size: "large"
                                  })), Caml_option.some(styles.amount), undefined, undefined, undefined))));
}

var make = React.memo(CartAmountsView, (function (oldProps, newProps) {
        return oldProps.cart === newProps.cart;
      }));

var bigToFloat = Accounting__Utils.bigToFloat;

export {
  styles ,
  bigToFloat ,
  currencyFormat ,
  make ,
}
/* styles Not a pure module */
