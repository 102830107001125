// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Svg from "../../primitives/Svg.bs.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Stylex from "@stylexjs/stylex";

var styles = Stylex.create({
      root: {
        borderRadius: "4px",
        display: "flex",
        gap: "10px",
        minHeight: "40px",
        padding: "12px"
      },
      content: {
        display: "flex",
        flexDirection: "column",
        gap: "6px"
      },
      contentTitle: {
        font: "normal 600 16px \"Archivo\""
      },
      contentText: {
        font: "normal 400 14px \"Libre Franklin\"",
        lineHeight: "16px"
      }
    });

function styleProps(variation) {
  return Stylex.props(styles.root, {
              backgroundColor: "#F2F6FC"
            });
}

function contentStyleProps(param) {
  return Stylex.props(styles.content);
}

function contentTitleStyleProps(variation) {
  return Stylex.props(styles.contentTitle, {
              color: "#354473"
            });
}

function contentTextStyleProps(variation) {
  return Stylex.props(styles.contentText, {
              color: "#354473"
            });
}

function iconElement(variation) {
  return React.createElement(Svg.make, {
              children: null,
              width: "35",
              height: "18",
              viewBox: "0 0 16 16"
            }, React.createElement(Svg.Path.make, {
                  d: "M7.19314 5.5998C7.19314 5.15798 7.55131 4.7998 7.99314 4.7998H8.00647C8.4483 4.7998 8.80647 5.15798 8.80647 5.5998C8.80647 6.04163 8.4483 6.3998 8.00647 6.3998H7.99314C7.55131 6.3998 7.19314 6.04163 7.19314 5.5998Z",
                  fill: "#4F70C1"
                }), React.createElement(Svg.Path.make, {
                  d: "M7.9998 7.1998C8.44163 7.1998 8.7998 7.55798 8.7998 7.9998V9.5998C9.24163 9.5998 9.5998 9.95798 9.5998 10.3998C9.5998 10.8416 9.24163 11.1998 8.7998 11.1998H7.1998C6.75798 11.1998 6.3998 10.8416 6.3998 10.3998C6.3998 9.95798 6.75798 9.5998 7.1998 9.5998V8.7998C6.75798 8.7998 6.3998 8.44163 6.3998 7.9998C6.3998 7.55798 6.75798 7.1998 7.1998 7.1998H7.9998Z",
                  fill: "#4F70C1"
                }), React.createElement(Svg.Path.make, {
                  d: "M0.799805 7.9998C0.799805 4.02335 4.02335 0.799805 7.9998 0.799805C11.9763 0.799805 15.1998 4.02335 15.1998 7.9998C15.1998 11.9763 11.9763 15.1998 7.9998 15.1998C4.02335 15.1998 0.799805 11.9763 0.799805 7.9998ZM7.9998 2.3998C4.90701 2.3998 2.3998 4.90701 2.3998 7.9998C2.3998 11.0926 4.90701 13.5998 7.9998 13.5998C11.0926 13.5998 13.5998 11.0926 13.5998 7.9998C13.5998 4.90701 11.0926 2.3998 7.9998 2.3998Z",
                  fill: "#4F70C1"
                }));
}

function DraftBanner(Props) {
  var title = Props.title;
  var text = Props.text;
  var variation = Props.variation;
  var match = styleProps(variation);
  var match$1 = Stylex.props(styles.content);
  var match$2 = contentTitleStyleProps(variation);
  var match$3 = contentTextStyleProps(variation);
  var tmp = {};
  if (match.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match.className);
  }
  if (match.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match.style);
  }
  var tmp$1 = {};
  if (match$1.className !== undefined) {
    tmp$1.className = Caml_option.valFromOption(match$1.className);
  }
  if (match$1.style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(match$1.style);
  }
  var tmp$2 = {};
  if (match$2.className !== undefined) {
    tmp$2.className = Caml_option.valFromOption(match$2.className);
  }
  if (match$2.style !== undefined) {
    tmp$2.style = Caml_option.valFromOption(match$2.style);
  }
  var tmp$3 = {};
  if (match$3.className !== undefined) {
    tmp$3.className = Caml_option.valFromOption(match$3.className);
  }
  if (match$3.style !== undefined) {
    tmp$3.style = Caml_option.valFromOption(match$3.style);
  }
  return React.createElement("div", tmp, iconElement(variation), React.createElement("div", tmp$1, React.createElement("span", tmp$2, title), React.createElement("span", tmp$3, text)));
}

var make = DraftBanner;

export {
  styles ,
  styleProps ,
  contentStyleProps ,
  contentTitleStyleProps ,
  contentTextStyleProps ,
  iconElement ,
  make ,
}
/* styles Not a pure module */
