// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Uuid from "../../externals/Uuid.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as CatalogProductEdit__Queries from "./CatalogProductEdit__Queries.bs.js";
import * as CatalogProductEditUrlQueryString from "../../modules/Catalog/CatalogProductEditUrlQueryString.bs.js";

var CatalogProductEdit_ProductNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogProductEdit__Config.CatalogProductEdit_ProductNotFound");

function makeFilterBy(activeShopId) {
  return CatalogProductEdit__Queries.ProductQuery.makeInputObjectInputProductsByVariantCkuQueryFilter(activeShopId !== undefined ? CatalogProductEdit__Queries.ProductQuery.makeInputObjectInFilter([activeShopId], undefined) : undefined, "INCLUDED", undefined);
}

function initialValuesFromProductKind(productKind) {
  var initialValues = {
    name: "",
    kind: productKind,
    color: undefined,
    tax: undefined,
    categoryId: undefined,
    producer: "",
    family: "",
    designation: "",
    country: "France",
    region: "",
    beerType: "",
    wineType: undefined,
    whiteWineType: undefined
  };
  if (productKind === "BEER") {
    return {
            name: "",
            kind: productKind,
            color: "BLOND",
            tax: undefined,
            categoryId: undefined,
            producer: "",
            family: "",
            designation: "",
            country: "France",
            region: "",
            beerType: "",
            wineType: undefined,
            whiteWineType: undefined
          };
  } else if (productKind === "WINE") {
    return {
            name: "",
            kind: productKind,
            color: "RED",
            tax: undefined,
            categoryId: undefined,
            producer: "",
            family: "",
            designation: "",
            country: "France",
            region: "",
            beerType: "",
            wineType: "STILL",
            whiteWineType: undefined
          };
  } else {
    return initialValues;
  }
}

function productFromData(data) {
  var match = Belt_Array.get(data.productsByVariantCku.edges, 0);
  if (match !== undefined) {
    return match.node;
  }
  throw {
        RE_EXN_ID: CatalogProductEdit_ProductNotFound,
        Error: new Error()
      };
}

function productContextFromData(data) {
  var match = Belt_Array.get(data.productsByVariantCku.edges, 0);
  if (match !== undefined) {
    var product = match.node;
    return {
            id: product.id,
            name: product.name
          };
  }
  throw {
        RE_EXN_ID: CatalogProductEdit_ProductNotFound,
        Error: new Error()
      };
}

function use(response) {
  var shops = Auth.useShops(undefined);
  var activeShop = Auth.useActiveShop(undefined);
  var productKind = CatalogProductEditUrlQueryString.CreateProduct.decode(Navigation.useUrl(undefined).query);
  if (shops.length === 0) {
    return /* Loading */1;
  }
  if (response.TAG === /* Executed */0) {
    var match = response._0;
    var data = match.data;
    if (match.loading) {
      return /* Loading */1;
    }
    if (data === undefined) {
      return /* Error */0;
    }
    if (activeShop === undefined) {
      return /* Error */0;
    }
    var product = productFromData(data);
    var productContext = productContextFromData(data);
    var uuid = Uuid.fromString(product.tax.id);
    return /* Data */{
            _0: {
              initialValues: {
                name: product.name,
                kind: product.kind,
                color: product.color,
                tax: uuid !== undefined ? ({
                      id: Caml_option.valFromOption(uuid),
                      value: product.tax.value
                    }) : undefined,
                categoryId: Belt_Option.flatMap(product.category, (function (param) {
                        var uuid = Uuid.fromString(param.id);
                        if (uuid !== undefined) {
                          return Caml_option.some(Caml_option.valFromOption(uuid));
                        }
                        
                      })),
                producer: Belt_Option.getWithDefault(product.producer, ""),
                family: Belt_Option.getWithDefault(product.family, ""),
                designation: Belt_Option.getWithDefault(product.designation, ""),
                country: Belt_Option.getWithDefault(product.country, ""),
                region: Belt_Option.getWithDefault(product.region, ""),
                beerType: Belt_Option.getWithDefault(product.beerType, ""),
                wineType: product.wineType,
                whiteWineType: product.whiteWineType
              },
              productKind: product.kind,
              contextData: productContext,
              shopName: activeShop.name
            }
          };
  }
  if (productKind.TAG !== /* Ok */0) {
    return /* Error */0;
  }
  if (activeShop === undefined) {
    return /* Error */0;
  }
  var productKind$1 = productKind._0.productKind;
  return /* Data */{
          _0: {
            initialValues: initialValuesFromProductKind(productKind$1),
            productKind: productKind$1,
            contextData: undefined,
            shopName: activeShop.name
          }
        };
}

var Query;

export {
  Query ,
  CatalogProductEdit_ProductNotFound ,
  makeFilterBy ,
  initialValuesFromProductKind ,
  productFromData ,
  productContextFromData ,
  use ,
}
/* Auth Not a pure module */
