// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ListBox from "./ListBox.bs.js";
import * as Popover from "../overlays/Popover.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactAria from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactStately from "react-stately";
import * as InputTextField from "./InputTextField.bs.js";
import * as ReactUpdateEffect from "../../primitives/ReactUpdateEffect.bs.js";
import * as ReactDomEventTarget from "../../primitives/ReactDomEventTarget.bs.js";

function InputSuggestionComboBoxField(Props) {
  var label = Props.label;
  var required = Props.required;
  var disabledOpt = Props.disabled;
  var derivedFocusedOpt = Props.focused;
  var placeholder = Props.placeholder;
  var errorMessage = Props.errorMessage;
  var items = Props.items;
  var value = Props.value;
  var onChange = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var derivedFocused = derivedFocusedOpt !== undefined ? derivedFocusedOpt : false;
  var match = React.useState(function () {
        return derivedFocused;
      });
  var setFocused = match[1];
  var match$1 = ReactAria.useFilter({
        sensitivity: "base"
      });
  var match$2 = React.useState(function () {
        return ListBox.defaultItemsPerScroll;
      });
  var setItemsLimit = match$2[1];
  var triggerRef = React.useRef(null);
  var inputRef = React.useRef(null);
  var popoverRef = React.useRef(null);
  var listBoxRef = React.useRef(null);
  ReactUpdateEffect.use1((function (param) {
          Curry._1(setFocused, (function (param) {
                  return derivedFocused;
                }));
        }), [derivedFocused]);
  var children = React.useMemo((function () {
          return [React.createElement(ReactStately.Section, {
                        children: Belt_Array.map(items, (function (item) {
                                return React.createElement(ReactStately.Item, {
                                            children: React.createElement("span", undefined, item.value),
                                            textValue: item.value,
                                            key: item.value
                                          });
                              })),
                        title: $$Intl.t("Suggestions")
                      })];
        }), [items]);
  var onFocusChange = React.useCallback((function (focused) {
          Curry._1(setFocused, (function (param) {
                  return focused;
                }));
        }), []);
  var props_aria$label = label;
  var props_defaultFilter = match$1.contains;
  var props_onInputChange = onChange;
  var props_defaultSelectedKey = value;
  var props_onFocusChange = onFocusChange;
  var props = {
    children: children,
    "aria-label": props_aria$label,
    isDisabled: disabled,
    placeholder: placeholder,
    defaultFilter: props_defaultFilter,
    allowsCustomValue: true,
    menuTrigger: "input",
    inputValue: value,
    onInputChange: props_onInputChange,
    allowsEmptyCollection: false,
    defaultSelectedKey: props_defaultSelectedKey,
    onFocusChange: props_onFocusChange,
    onFocus: onFocus,
    onBlur: onBlur
  };
  var state = ReactStately.useComboBoxState(props);
  var newrecord = Caml_obj.obj_dup(props);
  var match$3 = ReactAria.useComboBox((newrecord.popoverRef = Caml_option.some(popoverRef), newrecord.listBoxRef = Caml_option.some(listBoxRef), newrecord.inputRef = Caml_option.some(inputRef), newrecord), state);
  var onScroll = React.useCallback((function ($$event) {
          var element = ReactDomEventTarget.toUnsafeDomEventTarget($$event.currentTarget);
          var scrollHeight = element.scrollHeight;
          var clientHeight = element.clientHeight;
          var scrollOffset = scrollHeight - (clientHeight << 1) | 0;
          if (element.scrollTop > scrollOffset) {
            return Curry._1(setItemsLimit, (function (itemsLimit) {
                          return itemsLimit + ListBox.defaultItemsPerScroll | 0;
                        }));
          }
          
        }), []);
  var openedList = state.isOpen;
  var errored = Belt_Option.isSome(errorMessage);
  var hasNoItem = items.length === 0;
  var focused = match[0] || openedList;
  var tmp = {
    variation: {
      NAME: "suggestion",
      VAL: {
        opened: openedList,
        toggleButtonDisabled: hasNoItem,
        toggleButtonProps: match$3.buttonProps,
        onRequestClear: (function (param) {
            Curry._1(onChange, "");
          })
      }
    },
    label: label,
    disabled: disabled,
    focused: focused,
    containerRef: triggerRef,
    inputRef: inputRef,
    ariaProps: match$3.inputProps
  };
  if (required !== undefined) {
    tmp.required = required;
  }
  if (placeholder !== undefined) {
    tmp.placeholder = placeholder;
  }
  if (errorMessage !== undefined) {
    tmp.errorMessage = errorMessage;
  }
  var tmp$1;
  if (openedList) {
    var tmp$2 = {
      children: React.createElement(ListBox.make, {
            domRef: listBoxRef,
            state: state,
            props: match$3.listBoxProps,
            itemsLimit: match$2[0],
            onScroll: onScroll
          }),
      popoverRef: popoverRef,
      triggerRef: triggerRef,
      state: {
        isOpen: state.isOpen,
        close: state.close,
        toggle: state.toggle
      },
      layout: "triggerStrictWidth",
      modal: false
    };
    var tmp$3 = errored ? -16 : undefined;
    if (tmp$3 !== undefined) {
      tmp$2.offset = tmp$3;
    }
    tmp$1 = React.createElement(Popover.make, tmp$2);
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(InputTextField.make, tmp), tmp$1);
}

var make = InputSuggestionComboBoxField;

export {
  make ,
}
/* Intl Not a pure module */
