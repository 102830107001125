// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Label from "../../primitives/Label.bs.js";
import * as React from "react";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as OrderEditForm from "./OrderEditForm.bs.js";

function OrderConditionAndNotesCard(Props) {
  var editable = Props.editable;
  var state = Curry._1(OrderEditForm.useFormState, undefined);
  var tmp;
  if (editable) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(OrderEditForm.InputTextArea.make, {
              field: /* Condition */0,
              label: $$Intl.t("Conditions")
            }), React.createElement(Box.make, Box.makeProps(undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(OrderEditForm.InputTextArea.make, {
              field: /* NoteForSupplier */1,
              label: $$Intl.t("Supplier note")
            }));
  } else {
    var condition = state.values.condition;
    var noteForSupplier = state.values.noteForSupplier;
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Label.make, {
              text: $$Intl.t("Conditions")
            }), React.createElement(Box.make, Box.makeProps(Caml_option.some(condition.length > 0 ? React.createElement(TextStyle.make, {
                            children: condition,
                            size: "large"
                          }) : React.createElement(TextStyle.make, {
                            children: $$Intl.t("Empty condition"),
                            variation: "subdued"
                          })), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Box.make, Box.makeProps(undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Label.make, {
              text: $$Intl.t("Supplier note")
            }), React.createElement(Box.make, Box.makeProps(Caml_option.some(noteForSupplier.length > 0 ? React.createElement(TextStyle.make, {
                            children: noteForSupplier,
                            size: "large"
                          }) : React.createElement(TextStyle.make, {
                            children: $$Intl.t("Empty note"),
                            variation: "subdued"
                          })), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
  }
  return React.createElement(Card.make, {
              children: tmp,
              title: "Notes"
            });
}

var make = React.memo(OrderConditionAndNotesCard);

export {
  make ,
}
/* make Not a pure module */
