// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Form from "../../bundles/Form/Form.bs.js";

var initialCart_products = [];

var initialCart_discounts = [];

var initialCart = {
  products: initialCart_products,
  discounts: initialCart_discounts,
  decimalPrecision: 3,
  currency: /* Eur */0,
  taxesIncluded: false,
  taxesFree: false
};

function get(values, field) {
  switch (field) {
    case /* Condition */0 :
        return values.condition;
    case /* NoteForSupplier */1 :
        return values.noteForSupplier;
    case /* SupplierId */2 :
        return values.supplierId;
    case /* IssueDate */3 :
        return values.issueDate;
    case /* EstimatedReceptionDate */4 :
        return values.estimatedReceptionDate;
    case /* Note */5 :
        return values.note;
    case /* Cart */6 :
        return values.cart;
    case /* ShopId */7 :
        return values.shopId;
    case /* DeviceId */8 :
        return values.deviceId;
    case /* SupplierName */9 :
        return values.supplierName;
    case /* SupplierAddress */10 :
        return values.supplierAddress;
    case /* SupplierPostalCode */11 :
        return values.supplierPostalCode;
    case /* SupplierCity */12 :
        return values.supplierCity;
    case /* SupplierCountry */13 :
        return values.supplierCountry;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* Condition */0 :
        return {
                condition: value,
                noteForSupplier: values.noteForSupplier,
                supplierId: values.supplierId,
                issueDate: values.issueDate,
                estimatedReceptionDate: values.estimatedReceptionDate,
                note: values.note,
                cart: values.cart,
                shopId: values.shopId,
                deviceId: values.deviceId,
                supplierName: values.supplierName,
                supplierAddress: values.supplierAddress,
                supplierPostalCode: values.supplierPostalCode,
                supplierCity: values.supplierCity,
                supplierCountry: values.supplierCountry
              };
    case /* NoteForSupplier */1 :
        return {
                condition: values.condition,
                noteForSupplier: value,
                supplierId: values.supplierId,
                issueDate: values.issueDate,
                estimatedReceptionDate: values.estimatedReceptionDate,
                note: values.note,
                cart: values.cart,
                shopId: values.shopId,
                deviceId: values.deviceId,
                supplierName: values.supplierName,
                supplierAddress: values.supplierAddress,
                supplierPostalCode: values.supplierPostalCode,
                supplierCity: values.supplierCity,
                supplierCountry: values.supplierCountry
              };
    case /* SupplierId */2 :
        return {
                condition: values.condition,
                noteForSupplier: values.noteForSupplier,
                supplierId: value,
                issueDate: values.issueDate,
                estimatedReceptionDate: values.estimatedReceptionDate,
                note: values.note,
                cart: values.cart,
                shopId: values.shopId,
                deviceId: values.deviceId,
                supplierName: values.supplierName,
                supplierAddress: values.supplierAddress,
                supplierPostalCode: values.supplierPostalCode,
                supplierCity: values.supplierCity,
                supplierCountry: values.supplierCountry
              };
    case /* IssueDate */3 :
        return {
                condition: values.condition,
                noteForSupplier: values.noteForSupplier,
                supplierId: values.supplierId,
                issueDate: value,
                estimatedReceptionDate: values.estimatedReceptionDate,
                note: values.note,
                cart: values.cart,
                shopId: values.shopId,
                deviceId: values.deviceId,
                supplierName: values.supplierName,
                supplierAddress: values.supplierAddress,
                supplierPostalCode: values.supplierPostalCode,
                supplierCity: values.supplierCity,
                supplierCountry: values.supplierCountry
              };
    case /* EstimatedReceptionDate */4 :
        return {
                condition: values.condition,
                noteForSupplier: values.noteForSupplier,
                supplierId: values.supplierId,
                issueDate: values.issueDate,
                estimatedReceptionDate: value,
                note: values.note,
                cart: values.cart,
                shopId: values.shopId,
                deviceId: values.deviceId,
                supplierName: values.supplierName,
                supplierAddress: values.supplierAddress,
                supplierPostalCode: values.supplierPostalCode,
                supplierCity: values.supplierCity,
                supplierCountry: values.supplierCountry
              };
    case /* Note */5 :
        return {
                condition: values.condition,
                noteForSupplier: values.noteForSupplier,
                supplierId: values.supplierId,
                issueDate: values.issueDate,
                estimatedReceptionDate: values.estimatedReceptionDate,
                note: value,
                cart: values.cart,
                shopId: values.shopId,
                deviceId: values.deviceId,
                supplierName: values.supplierName,
                supplierAddress: values.supplierAddress,
                supplierPostalCode: values.supplierPostalCode,
                supplierCity: values.supplierCity,
                supplierCountry: values.supplierCountry
              };
    case /* Cart */6 :
        return {
                condition: values.condition,
                noteForSupplier: values.noteForSupplier,
                supplierId: values.supplierId,
                issueDate: values.issueDate,
                estimatedReceptionDate: values.estimatedReceptionDate,
                note: values.note,
                cart: value,
                shopId: values.shopId,
                deviceId: values.deviceId,
                supplierName: values.supplierName,
                supplierAddress: values.supplierAddress,
                supplierPostalCode: values.supplierPostalCode,
                supplierCity: values.supplierCity,
                supplierCountry: values.supplierCountry
              };
    case /* ShopId */7 :
        return {
                condition: values.condition,
                noteForSupplier: values.noteForSupplier,
                supplierId: values.supplierId,
                issueDate: values.issueDate,
                estimatedReceptionDate: values.estimatedReceptionDate,
                note: values.note,
                cart: values.cart,
                shopId: value,
                deviceId: values.deviceId,
                supplierName: values.supplierName,
                supplierAddress: values.supplierAddress,
                supplierPostalCode: values.supplierPostalCode,
                supplierCity: values.supplierCity,
                supplierCountry: values.supplierCountry
              };
    case /* DeviceId */8 :
        return {
                condition: values.condition,
                noteForSupplier: values.noteForSupplier,
                supplierId: values.supplierId,
                issueDate: values.issueDate,
                estimatedReceptionDate: values.estimatedReceptionDate,
                note: values.note,
                cart: values.cart,
                shopId: values.shopId,
                deviceId: value,
                supplierName: values.supplierName,
                supplierAddress: values.supplierAddress,
                supplierPostalCode: values.supplierPostalCode,
                supplierCity: values.supplierCity,
                supplierCountry: values.supplierCountry
              };
    case /* SupplierName */9 :
        return {
                condition: values.condition,
                noteForSupplier: values.noteForSupplier,
                supplierId: values.supplierId,
                issueDate: values.issueDate,
                estimatedReceptionDate: values.estimatedReceptionDate,
                note: values.note,
                cart: values.cart,
                shopId: values.shopId,
                deviceId: values.deviceId,
                supplierName: value,
                supplierAddress: values.supplierAddress,
                supplierPostalCode: values.supplierPostalCode,
                supplierCity: values.supplierCity,
                supplierCountry: values.supplierCountry
              };
    case /* SupplierAddress */10 :
        return {
                condition: values.condition,
                noteForSupplier: values.noteForSupplier,
                supplierId: values.supplierId,
                issueDate: values.issueDate,
                estimatedReceptionDate: values.estimatedReceptionDate,
                note: values.note,
                cart: values.cart,
                shopId: values.shopId,
                deviceId: values.deviceId,
                supplierName: values.supplierName,
                supplierAddress: value,
                supplierPostalCode: values.supplierPostalCode,
                supplierCity: values.supplierCity,
                supplierCountry: values.supplierCountry
              };
    case /* SupplierPostalCode */11 :
        return {
                condition: values.condition,
                noteForSupplier: values.noteForSupplier,
                supplierId: values.supplierId,
                issueDate: values.issueDate,
                estimatedReceptionDate: values.estimatedReceptionDate,
                note: values.note,
                cart: values.cart,
                shopId: values.shopId,
                deviceId: values.deviceId,
                supplierName: values.supplierName,
                supplierAddress: values.supplierAddress,
                supplierPostalCode: value,
                supplierCity: values.supplierCity,
                supplierCountry: values.supplierCountry
              };
    case /* SupplierCity */12 :
        return {
                condition: values.condition,
                noteForSupplier: values.noteForSupplier,
                supplierId: values.supplierId,
                issueDate: values.issueDate,
                estimatedReceptionDate: values.estimatedReceptionDate,
                note: values.note,
                cart: values.cart,
                shopId: values.shopId,
                deviceId: values.deviceId,
                supplierName: values.supplierName,
                supplierAddress: values.supplierAddress,
                supplierPostalCode: values.supplierPostalCode,
                supplierCity: value,
                supplierCountry: values.supplierCountry
              };
    case /* SupplierCountry */13 :
        return {
                condition: values.condition,
                noteForSupplier: values.noteForSupplier,
                supplierId: values.supplierId,
                issueDate: values.issueDate,
                estimatedReceptionDate: values.estimatedReceptionDate,
                note: values.note,
                cart: values.cart,
                shopId: values.shopId,
                deviceId: values.deviceId,
                supplierName: values.supplierName,
                supplierAddress: values.supplierAddress,
                supplierPostalCode: values.supplierPostalCode,
                supplierCity: values.supplierCity,
                supplierCountry: value
              };
    
  }
}

var Lenses = {
  get: get,
  set: set
};

var include = Form.Make(Lenses);

var Core = include.Core;

var Schema = include.Schema;

var FormLegacyProvider = include.FormLegacyProvider;

var FormProvider = include.FormProvider;

var InputText = include.InputText;

var InputSuggestionComboBox = include.InputSuggestionComboBox;

var InputSelect = include.InputSelect;

var InputTextArea = include.InputTextArea;

var InputPassword = include.InputPassword;

var InputNumber = include.InputNumber;

var InputOptionalNumber = include.InputOptionalNumber;

var InputSegmentedControls = include.InputSegmentedControls;

var InputRadioGroup = include.InputRadioGroup;

var InputPhone = include.InputPhone;

var InputCheckbox = include.InputCheckbox;

var InputToggleSwitch = include.InputToggleSwitch;

var InputDate = include.InputDate;

var InputDateRange = include.InputDateRange;

var SubmitButton = include.SubmitButton;

var CancelButton = include.CancelButton;

var ResetButton = include.ResetButton;

var ControlEnterKey = include.ControlEnterKey;

var AutoSave = include.AutoSave;

var useFormPropState = include.useFormPropState;

var useField = include.useField;

var useFormState = include.useFormState;

var useFormDispatch = include.useFormDispatch;

var validate = include.validate;

export {
  initialCart ,
  Lenses ,
  Core ,
  Schema ,
  FormLegacyProvider ,
  FormProvider ,
  InputText ,
  InputSuggestionComboBox ,
  InputSelect ,
  InputTextArea ,
  InputPassword ,
  InputNumber ,
  InputOptionalNumber ,
  InputSegmentedControls ,
  InputRadioGroup ,
  InputPhone ,
  InputCheckbox ,
  InputToggleSwitch ,
  InputDate ,
  InputDateRange ,
  SubmitButton ,
  CancelButton ,
  ResetButton ,
  ControlEnterKey ,
  AutoSave ,
  useFormPropState ,
  useField ,
  useFormState ,
  useFormDispatch ,
  validate ,
}
/* include Not a pure module */
