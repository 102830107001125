// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Text from "../../primitives/Text.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as FontFaces from "../../resources/theme/FontFaces.bs.js";
import * as FontSizes from "../../resources/theme/FontSizes.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as StockActivityKind from "./StockActivityKind.bs.js";
import * as StockActivityQuantity from "./StockActivityQuantity.bs.js";

function StockActivityQuantityTableCell(Props) {
  var value = Props.value;
  var capacityPrecision = Props.capacityPrecision;
  var capacityUnit = Props.capacityUnit;
  var kind = Props.kind;
  return React.createElement($$Text.make, $$Text.makeProps(StockActivityQuantity.format(kind, capacityPrecision, capacityUnit, value), Caml_option.some(Style.arrayStyle([
                          FontFaces.libreFranklinSemiBoldStyle,
                          FontSizes.styleFromSize("normal"),
                          Style.style(undefined, undefined, undefined, StockActivityKind.toColor(kind), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
                        ])), undefined, undefined, undefined));
}

var make = React.memo(StockActivityQuantityTableCell);

export {
  make ,
}
/* make Not a pure module */
