// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "../../primitives/Json.bs.js";

function encodeHttpContext(jwt) {
  var headers = {};
  headers["Authorization"] = Json.encodeString("Bearer " + jwt);
  var context = {};
  context["headers"] = Json.encodeDict(headers);
  return Json.encodeDict(context);
}

export {
  encodeHttpContext ,
}
/* Json Not a pure module */
