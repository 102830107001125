// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Notifier from "../../bundles/Notifier/Notifier.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Placeholder from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as CatalogProductEditForm from "../../modules/Catalog/CatalogProductEditForm.bs.js";
import * as CatalogProductEditPage from "./CatalogProductEditPage.bs.js";
import * as CatalogProductEdit__Config from "./CatalogProductEdit__Config.bs.js";
import * as CatalogProductEdit__Queries from "./CatalogProductEdit__Queries.bs.js";
import * as CatalogPlaceholderShopPicker from "../../modules/Catalog/CatalogPlaceholderShopPicker.bs.js";

function CatalogProductEditContainer(Props) {
  var cku = Props.cku;
  var mode = Props.mode;
  var match = Curry.app(CatalogProductEdit__Queries.ProductsQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var productsQueryResults = match[1];
  var executeProductsQuery = match[0];
  var match$1 = Curry.app(CatalogProductEdit__Queries.ProductQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var executeProductQuery = match$1[0];
  var onGoBack = Navigation.useGoBack(undefined)[1];
  var notifier = Notifier.use(/* KeepOnHistoryChanges */1, undefined);
  var shops = Auth.useShops(undefined);
  var activeShop = Auth.useActiveShop(undefined);
  var activeShopId = Belt_Option.map(activeShop, (function (shop) {
          return shop.id;
        }));
  React.useEffect((function () {
          if (cku !== undefined && shops.length > 1) {
            Curry._3(executeProductsQuery, undefined, undefined, CatalogProductEdit__Queries.ProductsQuery.makeVariables(CatalogProductEdit__Queries.ProductsQuery.makeInputObjectInputProductsQueryFilter(undefined, undefined, undefined, undefined, CatalogProductEdit__Queries.ProductsQuery.makeInputObjectInFilter([cku], undefined), undefined, "INCLUDED", undefined), undefined));
          }
          
        }), []);
  var disabledShopIds = React.useMemo((function () {
          if (productsQueryResults.TAG !== /* Executed */0) {
            return [];
          }
          var match = productsQueryResults._0.data;
          if (match === undefined) {
            return [];
          }
          var products = match.products.edges;
          return Belt_Array.keepMap(shops, (function (param) {
                        var id = param.id;
                        var match = Belt_Array.getBy(products, (function (param) {
                                return param.node.shop.id === id;
                              }));
                        if (match !== undefined) {
                          return ;
                        } else {
                          return id;
                        }
                      }));
        }), [productsQueryResults]);
  var renderShopSelectionItem = React.useCallback((function (shop) {
          var shopName = Belt_Option.mapWithDefault(shop.value, "", (function (shop) {
                  return shop.name;
                }));
          var match = Belt_Option.isSome(shop.value);
          var match$1 = Belt_Option.getWithDefault(shop.disabled, false);
          if (match) {
            if (match$1) {
              return React.createElement(Inline.make, {
                          children: null,
                          align: "spaceBetween",
                          alignY: "top",
                          grow: true
                        }, React.createElement(TextStyle.make, {
                              children: shopName,
                              variation: "placeholder"
                            }), React.createElement(Inline.make, {
                              children: React.createElement(TextStyle.make, {
                                    children: $$Intl.t("Non existing product"),
                                    variation: "subdued",
                                    size: "xsmall",
                                    lineHeight: "large"
                                  }),
                              align: "end",
                              grow: true
                            }));
            } else {
              return React.createElement(TextStyle.make, {
                          children: shopName
                        });
            }
          } else {
            return React.createElement(TextStyle.make, {
                        children: $$Intl.t("Select a shop"),
                        variation: "normal"
                      });
          }
        }), []);
  React.useEffect((function () {
          if (mode && activeShop !== undefined && cku !== undefined) {
            Curry._3(executeProductQuery, undefined, undefined, CatalogProductEdit__Queries.ProductQuery.makeVariables(Scalar.CKU.serialize(cku), CatalogProductEdit__Config.makeFilterBy(activeShopId), undefined));
          }
          
        }), [activeShopId]);
  var onSubmitSuccess = React.useCallback((function (message) {
          Curry._3(notifier.reset, {
                TAG: /* Success */0,
                _0: Belt_Option.getWithDefault(message, "")
              }, undefined, undefined);
          Curry._1(onGoBack, undefined);
        }), []);
  var onSubmitFailure = React.useCallback((function (message) {
          Curry._3(notifier.reset, {
                TAG: /* Error */1,
                _0: message
              }, undefined, undefined);
        }), []);
  var results = CatalogProductEdit__Config.use(match$1[1]);
  var tmp;
  if (typeof results === "number") {
    tmp = results !== 0 ? React.createElement(Placeholder.make, {
            status: /* Loading */0
          }) : React.createElement(Placeholder.make, {
            status: /* Error */2
          });
  } else {
    var match$2 = results._0;
    var contextData = match$2.contextData;
    var tmp$1 = {
      initialValues: match$2.initialValues,
      schema: CatalogProductEditForm.schema,
      children: React.createElement(CatalogProductEditPage.make, {
            shopName: match$2.shopName,
            editionMode: mode === /* Edit */1,
            productKind: match$2.productKind,
            contextData: contextData
          }),
      onSubmitFailure: onSubmitFailure,
      onSubmitSuccess: onSubmitSuccess
    };
    var tmp$2 = Belt_Option.map(contextData, (function (param) {
            return param.id;
          }));
    if (tmp$2 !== undefined) {
      tmp$1.id = Caml_option.valFromOption(tmp$2);
    }
    tmp = React.createElement(CatalogProductEditForm.Core.LegacyProvider.make, tmp$1);
  }
  return React.createElement(CatalogPlaceholderShopPicker.make, {
              children: tmp,
              message: $$Intl.template($$Intl.t("Please select the shop in which you would like to {{action}} the reference."), {
                    action: $$Intl.t(mode === /* Edit */1 ? "edit" : "create")
                  }, undefined),
              disabledIds: disabledShopIds,
              renderItemContent: renderShopSelectionItem
            });
}

var make = React.memo(CatalogProductEditContainer);

var Config;

var ProductsQuery;

var ProductQuery;

export {
  Config ,
  ProductsQuery ,
  ProductQuery ,
  make ,
}
/* make Not a pure module */
