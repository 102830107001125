// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Banner from "../../resources/feedback-indicators/Banner.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ApolloConfig from "../../core/ApolloConfig.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as ReactUpdateEffect from "../../primitives/ReactUpdateEffect.bs.js";
import * as InputTextAreaField from "../../resources/selection-and-input/InputTextAreaField.bs.js";
import * as ReactUpdateDebounced from "../../primitives/ReactUpdateDebounced.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = Client.gql(["mutation updateProduct_internalNote($id: ID!, $input: InputUpdateProduct!)  {\nupdateProduct(id: $id, input: $input)  {\n__typename  \nid  \ninternalNote  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.updateProduct;
  var value$2 = value$1.internalNote;
  return {
          updateProduct: {
            __typename: value$1.__typename,
            id: value$1.id,
            internalNote: !(value$2 == null) ? Scalar.$$Text.parse(value$2) : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.updateProduct;
  var value$2 = value$1.internalNote;
  var internalNote = value$2 !== undefined ? Scalar.$$Text.serialize(value$2) : null;
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var updateProduct = {
    __typename: value$4,
    id: value$3,
    internalNote: internalNote
  };
  return {
          updateProduct: updateProduct
        };
}

function serializeInputObjectInputUpdateProductMetaData(inp) {
  var a = inp.shopifyProductId;
  return {
          shopifyProductId: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInputUpdateProduct(inp) {
  var a = inp.name;
  var a$1 = inp.designation;
  var a$2 = inp.color;
  var a$3 = inp.kind;
  var a$4 = inp.family;
  var a$5 = inp.producer;
  var a$6 = inp.region;
  var a$7 = inp.beerType;
  var a$8 = inp.wineType;
  var a$9 = inp.whiteWineType;
  var a$10 = inp.internalNote;
  var a$11 = inp.taxId;
  var a$12 = inp.categoryId;
  var a$13 = inp.metadata;
  return {
          name: a !== undefined ? a : undefined,
          designation: a$1 !== undefined ? a$1 : undefined,
          color: a$2 !== undefined ? (
              a$2 === "ORANGE" ? "ORANGE" : (
                  a$2 === "RED" ? "RED" : (
                      a$2 === "AMBER" ? "AMBER" : (
                          a$2 === "DARK" ? "DARK" : (
                              a$2 === "BLACK" ? "BLACK" : (
                                  a$2 === "BLOND" ? "BLOND" : (
                                      a$2 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          kind: a$3 !== undefined ? (
              a$3 === "SPIRITUOUS" ? "SPIRITUOUS" : (
                  a$3 === "BEER" ? "BEER" : (
                      a$3 === "WINE" ? "WINE" : "SIMPLE"
                    )
                )
            ) : undefined,
          family: a$4 !== undefined ? a$4 : undefined,
          producer: a$5 !== undefined ? a$5 : undefined,
          country: inp.country,
          region: a$6 !== undefined ? a$6 : undefined,
          beerType: a$7 !== undefined ? a$7 : undefined,
          wineType: a$8 !== undefined ? (
              a$8 === "EFFERVESCENT" ? "EFFERVESCENT" : "STILL"
            ) : undefined,
          whiteWineType: a$9 !== undefined ? (
              a$9 === "SWEET" ? "SWEET" : (
                  a$9 === "SEMIDRY" ? "SEMIDRY" : (
                      a$9 === "SOFT" ? "SOFT" : "DRY"
                    )
                )
            ) : undefined,
          internalNote: a$10 !== undefined ? Caml_option.valFromOption(a$10) : undefined,
          taxId: a$11 !== undefined ? a$11 : undefined,
          categoryId: a$12 !== undefined ? a$12 : undefined,
          metadata: a$13 !== undefined ? serializeInputObjectInputUpdateProductMetaData(a$13) : undefined
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id,
          input: serializeInputObjectInputUpdateProduct(inp.input)
        };
}

function makeVariables(id, input, param) {
  return {
          id: id,
          input: input
        };
}

function makeInputObjectInputUpdateProduct(name, designation, color, kind, family, producer, country, region, beerType, wineType, whiteWineType, internalNote, taxId, categoryId, metadata, param) {
  return {
          name: name,
          designation: designation,
          color: color,
          kind: kind,
          family: family,
          producer: producer,
          country: country,
          region: region,
          beerType: beerType,
          wineType: wineType,
          whiteWineType: whiteWineType,
          internalNote: internalNote,
          taxId: taxId,
          categoryId: categoryId,
          metadata: metadata
        };
}

function makeInputObjectInputUpdateProductMetaData(shopifyProductId, param) {
  return {
          shopifyProductId: shopifyProductId
        };
}

var UpdateProductInternalNoteMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateProduct: serializeInputObjectInputUpdateProduct,
  serializeInputObjectInputUpdateProductMetaData: serializeInputObjectInputUpdateProductMetaData,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateProduct: makeInputObjectInputUpdateProduct,
  makeInputObjectInputUpdateProductMetaData: makeInputObjectInputUpdateProductMetaData
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var UpdateProductInternalNoteMutation_useWithVariables = include.useWithVariables;

var UpdateProductInternalNoteMutation = {
  UpdateProductInternalNoteMutation_inner: UpdateProductInternalNoteMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateProduct: serializeInputObjectInputUpdateProduct,
  serializeInputObjectInputUpdateProductMetaData: serializeInputObjectInputUpdateProductMetaData,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateProduct: makeInputObjectInputUpdateProduct,
  makeInputObjectInputUpdateProductMetaData: makeInputObjectInputUpdateProductMetaData,
  use: use,
  useWithVariables: UpdateProductInternalNoteMutation_useWithVariables
};

function CatalogProductNoteCard(Props) {
  var productId = Props.productId;
  var initialInternalNote = Props.internalNote;
  var match = React.useState(function () {
        return initialInternalNote;
      });
  var setInternalNote = match[1];
  var internalNote = match[0];
  var debouncedInternalNote = ReactUpdateDebounced.use(internalNote, ApolloConfig.mutationDebounceInterval);
  var match$1 = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutateInternalNote = match$1[0];
  var match$2 = React.useState(function () {
        return /* None */0;
      });
  var setError = match$2[1];
  var error = match$2[0];
  var onMutateInternalNoteRequested = React.useCallback((function (internalNote) {
          var input = makeInputObjectInputUpdateProduct(undefined, undefined, undefined, undefined, undefined, undefined, $$Intl.t("France"), undefined, undefined, undefined, undefined, Caml_option.some(Scalar.$$Text.serialize(internalNote)), undefined, undefined, undefined, undefined);
          Future.get(FuturePromise.fromPromise(Curry._8(mutateInternalNote, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                        id: Belt_Option.getWithDefault(productId, ""),
                        input: input
                      })), (function (result) {
                  if (result.TAG === /* Ok */0) {
                    var match = result._0;
                    if (match.TAG === /* Ok */0 && match._0.error === undefined) {
                      return Curry._1(setError, (function (param) {
                                    return /* None */0;
                                  }));
                    }
                    
                  }
                  Curry._1(setInternalNote, (function (param) {
                          return initialInternalNote;
                        }));
                  Curry._1(setError, (function (param) {
                          return /* InternalNoteUpdatingError */{
                                  _0: $$Intl.t("Couldn't update the internal note.")
                                };
                        }));
                }));
        }), [productId]);
  ReactUpdateEffect.use1((function (param) {
          Curry._1(onMutateInternalNoteRequested, debouncedInternalNote);
        }), [debouncedInternalNote]);
  var match$3 = Auth.useScope(undefined);
  var tmp;
  var exit = 0;
  if (match$3.TAG === /* Organisation */0 && match$3.activeShop === undefined) {
    tmp = React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Banner.make, {
                      textStatus: {
                        TAG: /* Info */3,
                        _0: $$Intl.t("Please filter the product page on a single shop to edit its internal memo.")
                      },
                      compact: false
                    })), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var tmp$1 = {
      label: $$Intl.t("Internal memo"),
      value: internalNote,
      onChange: (function (value) {
          Curry._1(setInternalNote, (function (param) {
                  return value;
                }));
        })
    };
    var tmp$2 = error ? error._0 : undefined;
    if (tmp$2 !== undefined) {
      tmp$1.errorMessage = tmp$2;
    }
    tmp = React.createElement(Stack.make, {
          children: React.createElement(InputTextAreaField.make, tmp$1),
          space: "xlarge"
        });
  }
  return React.createElement(Card.make, {
              children: tmp,
              title: "Note"
            });
}

var make = React.memo(CatalogProductNoteCard);

export {
  UpdateProductInternalNoteMutation ,
  make ,
}
/* query Not a pure module */
