// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as InlineText from "../../resources/layout-and-structure/InlineText.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";

function CatalogProductInformationCard(Props) {
  var product = Props.product;
  var match = Navigation.useUrl(undefined);
  var description = product.description;
  return React.createElement(Card.make, {
              children: React.createElement(Stack.make, {
                    children: null,
                    space: "xxsmall"
                  }, React.createElement(TextStyle.make, {
                        children: product.name,
                        weight: "strong"
                      }), description !== undefined && description.length !== 0 ? React.createElement(TextStyle.make, {
                          children: description
                        }) : null, React.createElement(InlineText.make, {
                        children: null
                      }, React.createElement(TextStyle.make, {
                            children: $$Intl.t("From "),
                            variation: "normal"
                          }), React.createElement(TextStyle.make, {
                            children: product.formattedOrigin
                          })), React.createElement(InlineText.make, {
                        children: null
                      }, React.createElement(TextStyle.make, {
                            children: $$Intl.t("Default VAT of "),
                            variation: "normal"
                          }), React.createElement(TextStyle.make, {
                            children: String(product.taxValue).replace(".", ",") + " %"
                          }))),
              title: $$Intl.t("Product's information"),
              action: {
                icon: "edit_light",
                title: $$Intl.t("Edit product"),
                handler: {
                  TAG: /* OpenLink */1,
                  _0: {
                    TAG: /* Route */0,
                    _0: match.pathname + "/edit"
                  }
                }
              }
            });
}

var make = React.memo(CatalogProductInformationCard);

var Config;

export {
  Config ,
  make ,
}
/* make Not a pure module */
