// Generated by ReScript, PLEASE EDIT WITH CARE

import * as OrderStatus from "../../modules/Order/OrderStatus.bs.js";

function isBeforeReception(statuses) {
  return !(OrderStatus.has(statuses, "RECEIVED") || OrderStatus.has(statuses, "RECEIVING"));
}

function isInitialFullEdition(statuses, created) {
  if (OrderStatus.has(statuses, "DRAFT") && !created) {
    return true;
  } else if (OrderStatus.has(statuses, "RECEIVING")) {
    return created;
  } else {
    return false;
  }
}

function isLimitedEdition(statuses) {
  if (OrderStatus.has(statuses, "RECEIVED")) {
    return !OrderStatus.has(statuses, "PAID");
  } else {
    return false;
  }
}

export {
  isBeforeReception ,
  isInitialFullEdition ,
  isLimitedEdition ,
}
/* OrderStatus Not a pure module */
