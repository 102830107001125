// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Banner from "../../resources/feedback-indicators/Banner.bs.js";
import * as Divider from "../../resources/layout-and-structure/Divider.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as InlineText from "../../resources/layout-and-structure/InlineText.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PromotionEditForm from "./PromotionEditForm.bs.js";
import * as PromotionEditPriceNameSelect from "./PromotionEditPriceNameSelect.bs.js";

var make = React.memo(function (Props) {
      var match = Curry._1(PromotionEditForm.useFormState, undefined);
      var validation = match.validation;
      var dispatch = Curry._1(PromotionEditForm.useFormDispatch, undefined);
      var priceNameError;
      priceNameError = validation.TAG === /* Ok */0 ? undefined : Belt_Array.get(Belt_Array.keepMap(validation._0, (function (param) {
                    if (param[0]._0 !== 2) {
                      return ;
                    } else {
                      return param[1];
                    }
                  })), 0);
      var onChange = React.useCallback((function (value) {
              Curry._1(dispatch, {
                    TAG: /* FieldValueChanged */2,
                    _0: /* PriceName */2,
                    _1: (function (param) {
                        if (value !== "") {
                          return value;
                        }
                        
                      })
                  });
            }), []);
      var errorMessage;
      if (priceNameError !== undefined) {
        var tmp = match.submission;
        errorMessage = typeof tmp === "number" || tmp.TAG !== /* Failed */0 ? undefined : $$Intl.t(priceNameError);
      } else {
        errorMessage = undefined;
      }
      var tmp$1 = {
        required: true,
        value: Belt_Option.getWithDefault(match.values.priceName, ""),
        onChange: onChange
      };
      if (errorMessage !== undefined) {
        tmp$1.errorMessage = Caml_option.valFromOption(errorMessage);
      }
      return React.createElement(Stack.make, {
                  children: null,
                  space: "xsmall"
                }, React.createElement(PromotionEditPriceNameSelect.make, tmp$1), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Banner.make, {
                                  textStatus: {
                                    TAG: /* Warning */2,
                                    _0: $$Intl.t("Beware ! Once the draft is saved, you will no longer be able to edit the price list.")
                                  },
                                  compact: false
                                })), undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
    });

var PromotionEditPriceNameFormSelect = {
  make: make
};

function PromotionEditFormInformationCard(Props) {
  var editable = Props.editable;
  var promotionCreated = Props.promotionCreated;
  var match = Curry._1(PromotionEditForm.useFormState, undefined).values;
  var period = match.period;
  var priceName = match.priceName;
  return React.createElement(Card.make, {
              children: null,
              title: $$Intl.t("Information"),
              variation: "common"
            }, editable ? (
                promotionCreated ? (
                    priceName !== undefined ? React.createElement(Stack.make, {
                            children: null,
                            space: "xxsmall"
                          }, React.createElement(PromotionEditForm.InputText.make, {
                                field: /* Name */1,
                                label: $$Intl.t("Name of the campaign"),
                                placeholder: $$Intl.t("Wine fair 2020")
                              }), React.createElement(Box.make, Box.makeProps(undefined, undefined, undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(InlineText.make, {
                                children: null
                              }, React.createElement(TextStyle.make, {
                                    children: $$Intl.t("Price list") + " ",
                                    variation: "normal"
                                  }), React.createElement(TextStyle.make, {
                                    children: priceName
                                  }))) : null
                  ) : React.createElement(Stack.make, {
                        children: null,
                        space: "large"
                      }, React.createElement(PromotionEditForm.InputText.make, {
                            field: /* Name */1,
                            label: $$Intl.t("Name of the campaign"),
                            placeholder: $$Intl.t("Wine fair 2020")
                          }), React.createElement(make, {}))
              ) : (
                priceName !== undefined ? React.createElement(Stack.make, {
                        children: null,
                        space: "xxsmall"
                      }, React.createElement(InlineText.make, {
                            children: null
                          }, React.createElement(TextStyle.make, {
                                children: $$Intl.t("Campaign") + " ",
                                variation: "normal"
                              }), React.createElement(TextStyle.make, {
                                children: match.name,
                                weight: "semibold"
                              })), React.createElement(InlineText.make, {
                            children: null
                          }, React.createElement(TextStyle.make, {
                                children: $$Intl.t("Price list") + " ",
                                variation: "normal"
                              }), React.createElement(TextStyle.make, {
                                children: priceName
                              }))) : null
              ), React.createElement(Divider.make, {}), editable ? React.createElement(PromotionEditForm.InputDateRange.make, {
                    label: $$Intl.t("Dates of the campaign"),
                    placeholder: $$Intl.t("Select a period"),
                    field: /* Period */3
                  }) : (
                period !== undefined ? React.createElement(Stack.make, {
                        children: null,
                        space: "xxsmall"
                      }, React.createElement(InlineText.make, {
                            children: null
                          }, React.createElement(TextStyle.make, {
                                children: $$Intl.t("Start of the campaign at the") + " ",
                                variation: "normal"
                              }), React.createElement(TextStyle.make, {
                                children: $$Intl.dateTimeFormat("short", undefined, undefined, undefined, undefined, undefined, undefined, undefined, period[0])
                              })), React.createElement(InlineText.make, {
                            children: null
                          }, React.createElement(TextStyle.make, {
                                children: $$Intl.t("End of the campaign at the") + " ",
                                variation: "normal"
                              }), React.createElement(TextStyle.make, {
                                children: $$Intl.dateTimeFormat("short", undefined, undefined, undefined, undefined, undefined, undefined, undefined, period[1])
                              }))) : null
              ));
}

var make$1 = React.memo(PromotionEditFormInformationCard);

export {
  PromotionEditPriceNameFormSelect ,
  make$1 as make,
}
/* make Not a pure module */
