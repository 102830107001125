// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function PriceRetailTableCell(Props) {
  var value = Props.value;
  var secondaryValue = Props.secondaryValue;
  var capacityUnit = Props.capacityUnit;
  var formattedRetailPrice = $$Intl.currencyFormat("EUR", undefined, undefined, value._0) + Belt_Option.mapWithDefault(capacityUnit, "", (function (unit) {
          return "/" + unit + "";
        }));
  var tmp;
  if (secondaryValue !== undefined && secondaryValue.TAG === /* PriceExclTax */0) {
    var formattedSecondaryRetailPrice = $$Intl.currencyFormat("EUR", undefined, undefined, secondaryValue._0) + (" " + $$Intl.t("HT"));
    tmp = React.createElement(TextStyle.make, {
          children: formattedSecondaryRetailPrice,
          variation: "normal",
          size: "xxsmall"
        });
  } else {
    tmp = null;
  }
  return React.createElement(Stack.make, {
              children: null,
              space: secondaryValue === undefined ? "none" : "xxsmall"
            }, React.createElement(TextStyle.make, {
                  children: formattedRetailPrice
                }), tmp);
}

var make = React.memo(PriceRetailTableCell);

export {
  make ,
}
/* make Not a pure module */
