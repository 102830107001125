// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Scalar from "../../core/Scalar.bs.js";
import * as TextLink from "../../resources/navigation/TextLink.bs.js";
import * as TableView from "../../resources/tables/TableView.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Placeholder from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as LegacyRouter from "../../core/LegacyRouter.bs.js";
import * as TableDateCell from "../../resources/tables/TableDateCell.bs.js";
import * as SupplierRoutes from "../../app/Supplier/SupplierRoutes.bs.js";
import * as AmountTableCell from "../AmountTableCell.bs.js";
import * as ShowAllDataLink from "../../resources/navigation/ShowAllDataLink.bs.js";
import * as OrderNameTableCell from "../Order/OrderNameTableCell.bs.js";
import * as CatalogVariantOrderSupplyTableCard__Queries from "./CatalogVariantOrderSupplyTableCard__Queries.bs.js";

function keyExtractor(row) {
  return row.id + "-supply";
}

var Row = {
  keyExtractor: keyExtractor
};

function tableColumns(allShopsFiltered) {
  return [
          {
            key: "supply-name",
            name: $$Intl.t("Number"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 160
              },
              width: {
                NAME: "pct",
                VAL: 30
              },
              margin: "normal"
            },
            render: (function (param) {
                var match = param.data;
                var shopName = allShopsFiltered ? match.shopName : undefined;
                var tmp = {
                  value: match.name,
                  id: match.id
                };
                if (shopName !== undefined) {
                  tmp.shopName = Caml_option.valFromOption(shopName);
                }
                return React.createElement(OrderNameTableCell.make, tmp);
              })
          },
          {
            key: "supply-supplier",
            name: $$Intl.t("Supplier"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 140
              },
              width: {
                NAME: "pct",
                VAL: 20
              },
              margin: "small"
            },
            render: (function (param) {
                var match = param.data;
                return React.createElement(TextLink.make, {
                            text: match.supplierCompanyName,
                            to: {
                              TAG: /* Route */0,
                              _0: SupplierRoutes.showRoute(match.supplierId)
                            }
                          });
              })
          },
          {
            key: "supply-date",
            name: $$Intl.t("Reception date"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 150
              },
              width: {
                NAME: "fr",
                VAL: 2
              },
              margin: "huge"
            },
            render: (function (param) {
                return React.createElement(TableDateCell.make, {
                            value: param.data.receptionDate
                          });
              })
          },
          {
            key: "supply-amount",
            name: $$Intl.t("Amount"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 110
              }
            },
            render: (function (param) {
                var match = param.data;
                return React.createElement(AmountTableCell.make, {
                            value: match.totalAmountExcludingTaxes,
                            secondaryValue: match.totalAmountIncludingTaxes,
                            decimalPrecision: 3
                          });
              })
          },
          {
            key: "supply-quantity",
            name: $$Intl.t("Quantity"),
            layout: {
              width: {
                NAME: "fr",
                VAL: 2
              },
              alignX: "center"
            },
            render: (function (param) {
                return React.createElement(TextStyle.make, {
                            children: param.data.quantity
                          });
              })
          }
        ];
}

function CatalogVariantOrderSupplyTableCard(Props) {
  var cku = Props.cku;
  var match = Curry.app(CatalogVariantOrderSupplyTableCard__Queries.OrderSuppliesQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var queryResults = match[1];
  var executeQuery = match[0];
  var activeShop = Auth.useActiveShop(undefined);
  var match$1 = Navigation.useUrl(undefined);
  React.useEffect((function () {
          Curry._3(executeQuery, undefined, undefined, CatalogVariantOrderSupplyTableCard__Queries.OrderSuppliesQuery.makeVariables(Scalar.CKU.serialize(cku), CatalogVariantOrderSupplyTableCard__Queries.edgesPerFetch, CatalogVariantOrderSupplyTableCard__Queries.OrderSuppliesQuery.makeInputObjectInputOrderProductsByVariantCkuQueryFilter("INCLUDED", {
                        _after: undefined,
                        _before: Caml_option.some(Scalar.Datetime.serialize(new Date(Date.now()))),
                        _between: undefined
                      }, activeShop !== undefined ? CatalogVariantOrderSupplyTableCard__Queries.OrderSuppliesQuery.makeInputObjectInFilter([activeShop.id], undefined) : undefined, undefined), undefined));
        }), [activeShop]);
  var match$2 = Auth.useScope(undefined);
  var allShopsFiltered;
  allShopsFiltered = match$2.TAG === /* Organisation */0 ? match$2.activeShop === undefined : false;
  var columns = React.useMemo((function () {
          return tableColumns(allShopsFiltered);
        }), [allShopsFiltered]);
  var tmp;
  var exit = 0;
  if (queryResults.TAG === /* Executed */0) {
    var match$3 = queryResults._0;
    var match$4 = match$3.data;
    if (match$4 !== undefined) {
      var match$5 = match$4.orderProductsByVariantCku;
      var rows = Belt_Array.keepMap(match$5.edges, (function (param) {
              var orderProduct = param.node;
              var match = orderProduct.order;
              var match$1 = Belt_Option.flatMap(orderProduct.order, (function (order) {
                      return order.supplier;
                    }));
              if (match !== undefined && match$1 !== undefined) {
                return {
                        id: match.id,
                        shopName: match.shopName,
                        name: Belt_Option.getWithDefault(match.name, "?"),
                        receptionDate: match.receptionFinishedAt,
                        quantity: String(orderProduct.quantity),
                        totalAmountExcludingTaxes: orderProduct.totalAmountExcludingTaxes,
                        totalAmountIncludingTaxes: orderProduct.totalAmountIncludingTaxes,
                        supplierId: match$1.id,
                        supplierCompanyName: match.supplierCompanyName
                      };
              }
              
            }));
      var placeholderEmptyState = React.createElement(Placeholder.make, {
            status: /* NoDataAvailable */1,
            customText: $$Intl.t("No order has been yet recorded")
          });
      var data = {
        TAG: /* Done */1,
        _0: {
          TAG: /* Ok */0,
          _0: rows
        }
      };
      var tmp$1;
      if (match$5.pageInfo.hasNextPage === true) {
        var pathname = match$1.pathname + LegacyRouter.routeToPathname(/* OrderSupply */1);
        tmp$1 = React.createElement(ShowAllDataLink.make, {
              to: {
                TAG: /* Route */0,
                _0: pathname
              },
              text: $$Intl.t("Show all data")
            });
      } else {
        tmp$1 = null;
      }
      tmp = React.createElement(React.Fragment, undefined, React.createElement(TableView.make, {
                data: data,
                columns: columns,
                keyExtractor: keyExtractor,
                placeholderEmptyState: placeholderEmptyState
              }), tmp$1);
    } else if (match$3.loading) {
      exit = 1;
    } else {
      tmp = React.createElement(Placeholder.make, {
            status: /* Error */2
          });
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Placeholder.make, {
          status: /* Loading */0
        });
  }
  return React.createElement(Card.make, {
              children: tmp,
              title: $$Intl.t("Orders"),
              variation: "table"
            });
}

var make = React.memo(CatalogVariantOrderSupplyTableCard);

var OrderSupplies;

var Query;

export {
  OrderSupplies ,
  Query ,
  Row ,
  tableColumns ,
  make ,
}
/* make Not a pure module */
