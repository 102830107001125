// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../../core/Env.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Auth__Jwt from "./Auth__Jwt.bs.js";
import * as Auth__Mock from "./Auth__Mock.bs.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Auth__Reducer from "./Auth__Reducer.bs.js";
import * as ReactUpdateReducer from "../../primitives/ReactUpdateReducer.bs.js";

var storageKey = "auth";

var context = React.createContext([
      undefined,
      (function (param) {
          
        })
    ]);

function use(param) {
  var match = React.useContext(context);
  var state = match[0];
  if (state !== undefined) {
    return [
            state,
            match[1]
          ];
  } else {
    return Pervasives.failwith("Could not find Auth context value");
  }
}

function createState(initialState, param) {
  var mounted = React.useRef(false);
  var match = ReactUpdateReducer.useWithMapState(Auth__Reducer.make, (function (param) {
          var match = Auth__Jwt.get(undefined);
          if (initialState !== undefined) {
            if (match !== undefined) {
              return initialState;
            } else {
              return /* Unlogged */0;
            }
          }
          if (match === undefined) {
            return /* Unlogged */0;
          }
          var match$1 = Belt_Option.map(Caml_option.null_to_opt(localStorage.getItem(storageKey)), Auth__Reducer.parse);
          if (match$1 === undefined) {
            return /* Unlogged */0;
          }
          if (match$1.TAG !== /* Ok */0) {
            return /* Unlogged */0;
          }
          var auth = match$1._0;
          if (auth.user.canUseImpersonation) {
            return {
                    TAG: /* Logging */0,
                    jwt: match,
                    impersonating: Auth__Jwt.isImpersonated(match)
                  };
          } else {
            return {
                    TAG: /* Logged */1,
                    _0: auth
                  };
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (!mounted.current && Env.context(undefined) !== "test") {
            var match = Auth__Jwt.get(undefined);
            if (typeof state !== "number") {
              if (state.TAG === /* Logging */0) {
                var impersonating = state.impersonating;
                if (impersonating !== undefined && match !== undefined) {
                  Curry._1(dispatch, {
                        TAG: /* LogRequested */0,
                        jwt: match,
                        impersonate: impersonating
                      });
                }
                
              } else if (state._0.user.impersonating || match === undefined) {
                
              } else {
                Curry._1(dispatch, {
                      TAG: /* LogRequested */0,
                      jwt: match
                    });
              }
            }
            
          }
          
        }), []);
  React.useEffect((function () {
          if (mounted.current) {
            var match = Auth__Reducer.stringify(state);
            if (typeof state !== "number" && state.TAG !== /* Logging */0) {
              if (state._0.user.impersonating || match === undefined) {
                
              } else {
                localStorage.setItem(storageKey, match);
              }
            }
            
          }
          if (typeof state === "number") {
            localStorage.removeItem(storageKey);
            Auth__Jwt.remove(undefined);
          } else if (state.TAG === /* Logging */0) {
            Auth__Jwt.set(state.jwt, state.impersonating, undefined);
          }
          
        }), [state]);
  React.useEffect((function () {
          mounted.current = true;
          return (function (param) {
                    mounted.current = false;
                  });
        }), []);
  return [
          state,
          dispatch
        ];
}

var contextProvider = context.Provider;

function Auth__Context$Provider(Props) {
  var children = Props.children;
  var initialState = Props.initialState;
  var match = Env.context(undefined);
  var initialState$1 = initialState !== undefined ? initialState : (
      match === "test" ? ({
            TAG: /* Logged */1,
            _0: Auth__Mock.mockAuthState(undefined, undefined, undefined)
          }) : undefined
    );
  return React.createElement(contextProvider, {
              children: children,
              value: createState(initialState$1, undefined)
            });
}

var Provider = {
  contextProvider: contextProvider,
  make: Auth__Context$Provider
};

var Reducer;

var Mock;

var Jwt;

export {
  Reducer ,
  Mock ,
  Jwt ,
  storageKey ,
  context ,
  use ,
  createState ,
  Provider ,
}
/* context Not a pure module */
