// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Select from "../../resources/selection-and-input/Select.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

var values = [
  /* TaxFranceVAT */0,
  /* TaxFree */1
];

function toLabel(value) {
  if (value) {
    return $$Intl.t("No VAT applied");
  } else {
    return $$Intl.t("VAT - France applied");
  }
}

var TaxApplication = {
  values: values,
  toLabel: toLabel
};

function CartTaxApplicationView(Props) {
  var taxesFree = Props.taxesFree;
  var orderReceived = Props.orderReceived;
  var dispatch = Props.onRequestDispatch;
  var value = taxesFree ? /* TaxFree */1 : /* TaxFranceVAT */0;
  var onChange = React.useCallback((function (option) {
          if (option) {
            if (value) {
              return ;
            } else {
              return Curry._1(dispatch, /* TaxesFreeToggleRequested */0);
            }
          } else if (value) {
            return Curry._1(dispatch, /* TaxesFreeToggleRequested */0);
          } else {
            return ;
          }
        }), [value]);
  var sections = [{
      items: Belt_Array.map(values, (function (taxApplication) {
              return {
                      key: toLabel(taxApplication),
                      value: taxApplication,
                      label: toLabel(taxApplication)
                    };
            }))
    }];
  return React.createElement(Select.make, {
              label: $$Intl.t("VAT option"),
              disabled: orderReceived,
              sections: sections,
              preset: {
                NAME: "inputField",
                VAL: {
                  required: false
                }
              },
              value: value,
              onChange: onChange
            });
}

var make = React.memo(CartTaxApplicationView, (function (oldProps, newProps) {
        if (oldProps.taxesFree === newProps.taxesFree) {
          return oldProps.orderReceived === newProps.orderReceived;
        } else {
          return false;
        }
      }));

export {
  TaxApplication ,
  make ,
}
/* make Not a pure module */
