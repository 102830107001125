// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "./Env.bs.js";
import * as Auth from "../bundles/Auth/Auth.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Logrocket from "logrocket";
import * as PosthogJs from "posthog-js";

function updateUserFields(userId, name, email) {
  if (Env.context(undefined) === "production") {
    Logrocket.default.identify(userId, {
          name: name,
          email: email
        });
    PosthogJs.default.identify(userId, {
          name: name,
          email: email
        });
    return ;
  }
  
}

function onSession(handler) {
  if (Env.context(undefined) === "production") {
    Logrocket.default.getSessionURL(Curry.__1(handler));
    return ;
  }
  
}

var $$Event = {};

function useCaptureEvent(param) {
  var auth = Auth.useState(undefined);
  return function ($$event) {
    if (typeof auth === "number" || auth.TAG === /* Logging */0 || auth._0.user.impersonating) {
      return ;
    } else if (Env.context(undefined) === "production") {
      PosthogJs.default.capture($$event);
      return ;
    } else {
      return ;
    }
  };
}

export {
  $$Event ,
  updateUserFields ,
  onSession ,
  useCaptureEvent ,
}
/* Auth Not a pure module */
