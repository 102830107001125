// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Spaces from "../../resources/theme/Spaces.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as ReactUpdateEffect from "../../primitives/ReactUpdateEffect.bs.js";
import * as InputOptionalNumberField from "../../resources/selection-and-input/InputOptionalNumberField.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var styles = $$StyleSheet.create({
      wrapper: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, "row", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      button: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.small)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function buttonStyleFromParams(errored) {
  if (errored) {
    return Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(2)), undefined, undefined, undefined);
  } else {
    return Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexEnd", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }
}

var Raw = {};

var query = Client.gql(["query variantsPriceLookUpCodes($shopId: String!)  {\nvariantsPriceLookUpCodes(shopId: $shopId)  \n}\n"]);

function parse(value) {
  var value$1 = value.variantsPriceLookUpCodes;
  return {
          variantsPriceLookUpCodes: Js_array.map((function (value) {
                  return value;
                }), value$1)
        };
}

function serialize(value) {
  var value$1 = value.variantsPriceLookUpCodes;
  var variantsPriceLookUpCodes = Js_array.map((function (value) {
          return value;
        }), value$1);
  return {
          variantsPriceLookUpCodes: variantsPriceLookUpCodes
        };
}

function serializeVariables(inp) {
  return {
          shopId: inp.shopId
        };
}

function makeVariables(shopId, param) {
  return {
          shopId: shopId
        };
}

var ConsummedPriceLookUpCodesQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var ConsummedPriceLookUpCodesQuery_refetchQueryDescription = include.refetchQueryDescription;

var ConsummedPriceLookUpCodesQuery_useLazy = include.useLazy;

var ConsummedPriceLookUpCodesQuery_useLazyWithVariables = include.useLazyWithVariables;

var ConsummedPriceLookUpCodesQuery = {
  ConsummedPriceLookUpCodesQuery_inner: ConsummedPriceLookUpCodesQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: ConsummedPriceLookUpCodesQuery_refetchQueryDescription,
  use: use,
  useLazy: ConsummedPriceLookUpCodesQuery_useLazy,
  useLazyWithVariables: ConsummedPriceLookUpCodesQuery_useLazyWithVariables
};

function makeValue(alreadyConsummedValues, pluRangeOpt, param) {
  var pluRange = pluRangeOpt !== undefined ? pluRangeOpt : [
      1,
      9997
    ];
  return Belt_Option.mapWithDefault(Belt_Array.getBy(Belt_Array.makeBy((pluRange[1] - pluRange[0] | 0) + 1 | 0, (function (index) {
                        return index + 1 | 0;
                      })), (function (plu) {
                    return !Belt_Array.some(alreadyConsummedValues, (function (current) {
                                  return current === plu;
                                }));
                  })), {
              TAG: /* Error */1,
              _0: undefined
            }, (function (plu) {
                return {
                        TAG: /* Ok */0,
                        _0: plu
                      };
              }));
}

function CatalogPriceLookUpInput(Props) {
  var value = Props.value;
  var errorMessage = Props.errorMessage;
  var onAlreadyConsummedValuesFetched = Props.onAlreadyConsummedValuesFetched;
  var onChange = Props.onChange;
  var originalPluRef = React.useRef(value);
  var shopId = Belt_Option.map(Auth.useActiveShop(undefined), (function (shop) {
          return shop.id;
        }));
  var queryResults = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* CacheAndNetwork */0,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        Belt_Option.isNone(shopId),
        undefined,
        {
          shopId: Belt_Option.getWithDefault(shopId, "")
        }
      ]);
  var match = queryResults.data;
  var alreadyConsummedValues = match !== undefined ? Belt_Array.keep(match.variantsPriceLookUpCodes, (function (plu) {
            return plu !== originalPluRef.current;
          })) : [];
  ReactUpdateEffect.use1((function (param) {
          var match = queryResults.data;
          if (alreadyConsummedValues.length !== 0 || match !== undefined) {
            Curry._1(onAlreadyConsummedValuesFetched, alreadyConsummedValues);
          }
          
        }), [queryResults]);
  var onPressGeneratePlu = React.useCallback((function (param) {
          Belt_Result.map(makeValue(alreadyConsummedValues, undefined, undefined), (function (plu) {
                  Curry._1(onChange, plu);
                }));
        }), [alreadyConsummedValues]);
  var onChange$1 = function (value) {
    Curry._1(onChange, Belt_Option.map(value, (function (prim) {
                return prim | 0;
              })));
  };
  var generatePluButtonDisabled = queryResults.loading || Belt_Option.isNone(shopId);
  return React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.wrapper), undefined, undefined, undefined), React.createElement(InputOptionalNumberField.make, InputOptionalNumberField.makeProps(Belt_Option.map(value, (function (prim) {
                            return prim;
                          })), 1, 9997, undefined, undefined, 0, $$Intl.t("PLU code"), undefined, errorMessage, queryResults.loading ? $$Intl.t("Loading") + "..." : "1-9997", undefined, undefined, undefined, undefined, undefined, queryResults.loading, undefined, undefined, false, onChange$1, undefined, undefined, undefined, undefined, undefined)), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Button.make, Button.makeProps($$Intl.t("Generate code"), "small", "neutral", undefined, undefined, generatePluButtonDisabled, undefined, undefined, undefined, onPressGeneratePlu, undefined, undefined, undefined))), Caml_option.some(Style.arrayStyle([
                              styles.button,
                              buttonStyleFromParams(Belt_Option.isSome(errorMessage))
                            ])), undefined, undefined, undefined)));
}

var make = React.memo(CatalogPriceLookUpInput);

var pluRange = [
  1,
  9997
];

export {
  pluRange ,
  styles ,
  buttonStyleFromParams ,
  ConsummedPriceLookUpCodesQuery ,
  makeValue ,
  make ,
}
/* styles Not a pure module */
