// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Text from "../../primitives/Text.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as FontFaces from "../theme/FontFaces.bs.js";
import * as FontSizes from "../theme/FontSizes.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var styles = $$StyleSheet.create({
      view: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#e7e7ee", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 3, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexStart", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(8.0)), undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(4.0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      text: Style.merge([
            Style.style(undefined, undefined, undefined, "#07061e", undefined, FontSizes.xsmall, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(1.5)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            FontFaces.libreFranklinRegularStyle
          ])
    });

function Tag(Props) {
  var children = Props.children;
  return React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement($$Text.make, $$Text.makeProps(children, Caml_option.some(Style.arrayStyle([styles.text])), undefined, undefined, undefined))), Caml_option.some(Style.arrayStyle([styles.view])), undefined, undefined, undefined));
}

var make = React.memo(Tag);

export {
  make ,
}
/* styles Not a pure module */
