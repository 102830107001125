// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "./Env.bs.js";
import * as Auth from "../bundles/Auth/Auth.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Logrocket from "logrocket";
import * as AuthRoutes from "../app/Auth/AuthRoutes.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as BugTracker from "./BugTracker.bs.js";
import * as Navigation from "../primitives/Navigation.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ApolloClient from "rescript-apollo-client/src/ApolloClient.bs.js";
import * as Client from "@apollo/client";
import * as ApolloClient__Utilities from "rescript-apollo-client/src/@apollo/client/utilities/ApolloClient__Utilities.bs.js";
import * as ApolloClient__Link_Error from "rescript-apollo-client/src/@apollo/client/link/error/ApolloClient__Link_Error.bs.js";
import * as ApolloClient__ZenObservable from "rescript-apollo-client/src/zen-observable/ApolloClient__ZenObservable.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";
import * as ApolloClient__Link_Http_HttpLink from "rescript-apollo-client/src/@apollo/client/link/http/ApolloClient__Link_Http_HttpLink.bs.js";
import * as ApolloClient__Cache_InMemory_InMemoryCache from "rescript-apollo-client/src/@apollo/client/cache/inmemory/ApolloClient__Cache_InMemory_InMemoryCache.bs.js";

var fetcher = ((...args) => window.fetch(...args));

var match = Env.context(undefined);

var httpGatewayLink = match === "test" ? ApolloClient__Link_Http_HttpLink.make((function (param) {
          return "http://localhost/graphql";
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined) : ApolloClient__Link_Http_HttpLink.make((function (param) {
          return Env.gatewayUrl(undefined) + "/graphql";
        }), undefined, Caml_option.some(fetcher), undefined, undefined, undefined, undefined, undefined);

var match$1 = Env.context(undefined);

var httpAnalyticsLink = match$1 === "test" ? ApolloClient__Link_Http_HttpLink.make((function (param) {
          return "http://localhost/graphql";
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined) : ApolloClient__Link_Http_HttpLink.make((function (param) {
          return Env.analyticsUrl(undefined) + "/graphql";
        }), undefined, Caml_option.some(fetcher), undefined, undefined, undefined, undefined, undefined);

var terminatingLink = ReasonMLCommunity__ApolloClient.Link.split((function (param) {
        var match = ApolloClient__Utilities.getOperationDefinition(param.query);
        if (match === undefined) {
          return false;
        }
        var match$1 = match.name;
        if (match$1 !== undefined && match.kind === "OperationDefinition" && match.operation === "query") {
          return match$1.value.startsWith("Analytics");
        } else {
          return false;
        }
      }), httpAnalyticsLink, httpGatewayLink);

var authMiddleware = Curry._1(ReasonMLCommunity__ApolloClient.Link.make, (function (operation, forward) {
        var jwt = Auth.getJwt(undefined);
        if (jwt !== undefined) {
          operation.setContext(Auth.encodeHttpContext(jwt));
        }
        return ApolloClient__ZenObservable.Observable.fromJs(Curry._1(forward, operation));
      }));

function errorMiddleware(param) {
  return ApolloClient__Link_Error.ErrorLink.make(function (errors) {
              var errors$1 = errors.graphQLErrors;
              if (errors$1 !== undefined) {
                Belt_Array.forEach(errors$1, (function (err) {
                        var exn = err.originalError;
                        if (!(exn == null)) {
                          BugTracker.reportException(exn);
                          Logrocket.default.captureException(exn);
                          return ;
                        }
                        
                      }));
              }
              var error = errors.networkError;
              if (error !== undefined) {
                switch (error.TAG | 0) {
                  case /* FetchFailure */0 :
                      var exn = error._0;
                      BugTracker.reportException(exn);
                      Logrocket.default.captureException(exn);
                      break;
                  case /* BadStatus */1 :
                      if (error._0 !== 401) {
                        
                      } else {
                        Navigation.openRoute(AuthRoutes.logoutSessionExpiredRoute);
                      }
                      break;
                  case /* BadBody */2 :
                      break;
                  
                }
              }
              
            });
}

function makeClient(param) {
  return ApolloClient.make(undefined, undefined, undefined, Caml_option.some(ReasonMLCommunity__ApolloClient.Link.from([
                      errorMiddleware(undefined),
                      authMiddleware,
                      terminatingLink
                    ])), ApolloClient__Cache_InMemory_InMemoryCache.make(undefined, undefined, undefined, undefined, undefined, undefined), undefined, undefined, Env.context(undefined) === "dev", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

function ApolloConfig$Provider(Props) {
  var children = Props.children;
  var authState = Auth.useState(undefined);
  var client = makeClient(undefined);
  React.useEffect((function () {
          if (typeof authState === "number") {
            Curry._1(client.rescript_clearStore, undefined);
          }
          
        }), [authState]);
  return React.createElement(Client.ApolloProvider, {
              client: client,
              children: children
            });
}

var Provider = {
  make: ApolloConfig$Provider
};

var edgesPerFetchLimit = 50;

var mutationDebounceInterval = 750;

export {
  edgesPerFetchLimit ,
  mutationDebounceInterval ,
  makeClient ,
  Provider ,
}
/* match Not a pure module */
