// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Popover from "../../resources/overlays/Popover.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CartProductDiscountFieldset from "./CartProductDiscountFieldset.bs.js";

function CartProductDiscountPopover(Props) {
  var product = Props.product;
  var popover = Props.popover;
  var popoverAriaProps = Props.popoverAriaProps;
  var popoverTriggerRef = Props.popoverTriggerRef;
  var decimalPrecision = Props.decimalPrecision;
  var dispatch = Props.onRequestDispatch;
  var match;
  if (product.TAG === /* Unit */0) {
    var match$1 = product._0;
    match = [
      match$1.id,
      Belt_Array.get(match$1.discounts, 0)
    ];
  } else {
    var match$2 = product._0;
    match = [
      match$2.id,
      Belt_Array.get(match$2.discounts, 0)
    ];
  }
  var discount = match[1];
  var productId = match[0];
  React.useEffect((function () {
          if (!popover.isOpen && discount !== undefined) {
            if (discount.kind >= 2) {
              if (discount.quantity.toNumber() === 0) {
                Curry._1(dispatch, {
                      TAG: /* ProductDiscountRemoved */16,
                      _0: productId,
                      _1: discount.id
                    });
              }
              
            } else if (discount.value !== 0) {
              
            } else {
              Curry._1(dispatch, {
                    TAG: /* ProductDiscountRemoved */16,
                    _0: productId,
                    _1: discount.id
                  });
            }
          }
          
        }), [popover.isOpen]);
  var onPressRemoveDiscount = React.useCallback((function (param) {
          Curry._1(popover.close, undefined);
        }), []);
  var tmp = {
    product: product,
    decimalPrecision: decimalPrecision,
    onPressRemoveDiscount: onPressRemoveDiscount,
    onRequestDispatch: dispatch
  };
  if (discount !== undefined) {
    tmp.discount = Caml_option.valFromOption(discount);
  }
  return React.createElement(Popover.make, {
              children: React.createElement(Popover.Dialog.make, {
                    children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(CartProductDiscountFieldset.make, tmp)), "large", "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                    ariaProps: popoverAriaProps.overlayProps
                  }),
              triggerRef: popoverTriggerRef,
              state: popover,
              placement: "bottom end",
              modal: false
            });
}

var make = React.memo(CartProductDiscountPopover);

export {
  make ,
}
/* make Not a pure module */
