// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Stack from "../layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as TextStyle from "../typography/TextStyle.bs.js";

function TableDatetimeCell(Props) {
  var value = Props.value;
  var formattedDate = $$Intl.dateTimeFormat("short", undefined, undefined, undefined, undefined, undefined, undefined, undefined, value);
  var match = $$Intl.dateTimeFormat(undefined, "short", undefined, undefined, undefined, undefined, undefined, undefined, value);
  var formattedTime = $$Intl.locale === "fr-FR" ? match.replace(":", "h") : match;
  return React.createElement(Stack.make, {
              children: null
            }, React.createElement(TextStyle.make, {
                  children: formattedDate
                }), React.createElement(TextStyle.make, {
                  children: $$Intl.t("at") + (" " + formattedTime),
                  variation: "normal",
                  size: "xxsmall"
                }));
}

var make = React.memo(TableDatetimeCell);

export {
  make ,
}
/* make Not a pure module */
