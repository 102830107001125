// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as React from "react";
import * as Spaces from "../theme/Spaces.bs.js";
import * as StyleX from "../../externals/StyleX.bs.js";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RoundButton from "../actions/RoundButton.bs.js";
import * as Stylex from "@stylexjs/stylex";
import * as Utils from "@react-aria/utils";
import * as ReactUpdateDebounced from "../../primitives/ReactUpdateDebounced.bs.js";

var styles = Stylex.create({
      PaginationIndicator_root: {
        lineHeight: "0px",
        textAlign: "right",
        margin: "-2px 0 12px",
        position: "absolute",
        right: "0",
        top: "0",
        zIndex: 1
      },
      PaginationIndicator_indicator: {
        display: "inline-block",
        height: "2px",
        marginLeft: "2px !important",
        width: "24px"
      }
    });

function indicatorStyleProps(active) {
  return Stylex.props(styles.PaginationIndicator_indicator, {
              backgroundColor: active ? "#25243a" : "#d7d7e0"
            });
}

function SliderTrack$PaginationIndicator(Props) {
  var itemsInView = Props.itemsInView;
  var gap = Props.gap;
  var trackRef = Props.trackRef;
  var match = React.useState(function () {
        return 0;
      });
  var setTotalPages = match[1];
  var totalPages = match[0];
  var match$1 = React.useState(function () {
        return 0;
      });
  var setCurrentPage = match$1[1];
  var currentPage = match$1[0];
  var handleTotalPages = React.useCallback((function (param) {
          var domElement = trackRef.current;
          if (domElement == null) {
            return ;
          }
          var scrollWidth = domElement.scrollWidth;
          var clientWidth = domElement.clientWidth;
          var totalGapShift = Spaces.toFloat(gap) * itemsInView;
          var nextTotalPages = Js_math.ceil_int((scrollWidth - totalGapShift) / clientWidth);
          if (totalPages !== nextTotalPages) {
            setTimeout((function (param) {
                    Curry._1(setTotalPages, (function (param) {
                            return nextTotalPages;
                          }));
                  }), 0);
            return ;
          }
          
        }), [
        trackRef,
        totalPages
      ]);
  Utils.useResizeObserver({
        ref: trackRef,
        onResize: handleTotalPages
      });
  var handleCurrentPage = React.useCallback((function (param) {
          var domElement = trackRef.current;
          if (domElement == null) {
            return ;
          }
          var scrollLeft = domElement.scrollLeft;
          var clientWidth = domElement.clientWidth;
          var gapShift = Spaces.toFloat(gap) * (currentPage - 1 | 0);
          var nextCurrentPage = Js_math.ceil_int((scrollLeft - gapShift) / clientWidth) + 1 | 0;
          if (currentPage !== nextCurrentPage) {
            setTimeout((function (param) {
                    Curry._1(setCurrentPage, (function (param) {
                            return nextCurrentPage;
                          }));
                  }), 0);
            return ;
          }
          
        }), [
        trackRef,
        currentPage
      ]);
  React.useLayoutEffect((function () {
          var domElement = trackRef.current;
          if (!(domElement == null)) {
            Curry._1(handleTotalPages, undefined);
            Curry._1(handleCurrentPage, undefined);
            domElement.addEventListener("scroll", handleCurrentPage);
            return (function (param) {
                      domElement.removeEventListener("scroll", handleCurrentPage);
                    });
          }
          
        }), [
        trackRef.current,
        handleTotalPages,
        handleCurrentPage
      ]);
  var debouncedCurrentPage = ReactUpdateDebounced.use(currentPage, 20);
  var match$2 = Stylex.props(styles.PaginationIndicator_root);
  if (totalPages > 10) {
    return null;
  }
  var tmp = {};
  if (match$2.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match$2.className);
  }
  if (match$2.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match$2.style);
  }
  return React.createElement("ul", tmp, Belt_Array.makeBy(totalPages, (function (index) {
                    var active = index === (debouncedCurrentPage - 1 | 0);
                    var match = indicatorStyleProps(active);
                    var tmp = {
                      key: String(index)
                    };
                    if (match.className !== undefined) {
                      tmp.className = Caml_option.valFromOption(match.className);
                    }
                    if (match.style !== undefined) {
                      tmp.style = Caml_option.valFromOption(match.style);
                    }
                    return React.createElement("li", tmp);
                  })));
}

var styles$1 = Stylex.create({
      "SideOverlay.shade": {
        pointerEvents: "none",
        transition: "opacity .25s ease",
        bottom: "0",
        height: "100%",
        position: "absolute",
        width: "35px",
        zIndex: 1
      },
      "SideOverlay.buttonContainer": {
        borderRadius: "100%",
        transition: "opacity .15s ease",
        position: "absolute",
        top: "50%",
        zIndex: 10
      }
    });

function shadeStyleProps(end, show) {
  return Stylex.props(styles$1["SideOverlay.shade"], {
              background: "linear-gradient(to " + (
                end ? "left" : "right"
              ) + ",\n                     #f3f3f7 0%, rgba(255, 255, 255, 0) 100%)",
              opacity: show ? 1 : 0,
              boxShadow: "" + (
                end ? "" : "—"
              ) + "20px 0px 20px 0px #f3f3f7",
              left: end ? "initial" : "0",
              right: end ? "0" : "initial"
            });
}

function buttonContainerStyleProps(end, show, pressed) {
  return Stylex.props(styles$1["SideOverlay.buttonContainer"], {
              opacity: show ? 1 : 0,
              pointerEvents: show ? "auto" : "none",
              transform: end ? "translate(35%, -50%)" : "translate(-35%, -50%)",
              boxShadow: "rgba(0, 0, 0, " + (
                pressed ? "0" : "0.07"
              ) + ")\n                    0px 3px 4px,rgba(0, 0, 0, 0.04) 0px 0px 4px",
              left: end ? "initial" : "0",
              right: end ? "0" : "initial"
            });
}

function SliderTrack$SideOverlay(Props) {
  var endOverlayOpt = Props.end;
  var showScrollButton = Props.showScrollButton;
  var gap = Props.gap;
  var containerRef = Props.containerRef;
  var trackRef = Props.trackRef;
  var endOverlay = endOverlayOpt !== undefined ? endOverlayOpt : false;
  var match = Hover.use(containerRef, undefined);
  var match$1 = React.useState(function () {
        return false;
      });
  var setPressed = match$1[1];
  var pressed = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setScrollable = match$2[1];
  var scrollable = match$2[0];
  var handleControl = React.useCallback((function (param) {
          var domElement = trackRef.current;
          if (domElement == null) {
            return ;
          }
          var gap$1 = Spaces.toFloat(gap) | 0;
          var clientWidth = domElement.clientWidth;
          var scrollAmount = endOverlay ? clientWidth + gap$1 | 0 : (-clientWidth | 0) - gap$1 | 0;
          domElement.scrollBy({
                left: scrollAmount,
                behavior: "smooth"
              });
        }), [trackRef]);
  var handleScrollable = React.useCallback((function (param) {
          var domElement = trackRef.current;
          if (domElement == null) {
            return ;
          }
          var next = endOverlay ? ((domElement.scrollLeft | 0) + domElement.clientWidth | 0) < (domElement.scrollWidth - 1 | 0) : domElement.scrollLeft > 1;
          if (scrollable !== next) {
            return Curry._1(setScrollable, (function (param) {
                          return next;
                        }));
          }
          
        }), [
        trackRef,
        scrollable
      ]);
  React.useLayoutEffect((function () {
          var domElement = trackRef.current;
          if (!(domElement == null)) {
            Curry._1(handleScrollable, undefined);
            domElement.addEventListener("scroll", handleScrollable);
            return (function (param) {
                      domElement.removeEventListener("scroll", handleScrollable);
                    });
          }
          
        }), [
        trackRef.current,
        handleScrollable
      ]);
  var ariaProps_elementType = "div";
  var ariaProps_onPressStart = (function (param) {
      Curry._1(setPressed, (function (param) {
              return true;
            }));
    });
  var ariaProps_onPressEnd = (function (param) {
      Curry._1(setPressed, (function (param) {
              return false;
            }));
    });
  var ariaProps = {
    elementType: ariaProps_elementType,
    onPressStart: ariaProps_onPressStart,
    onPressEnd: ariaProps_onPressEnd
  };
  var icon = endOverlay ? "queue_arrow_right_light" : "queue_arrow_left_light";
  var match$3 = shadeStyleProps(endOverlay, scrollable);
  var match$4 = buttonContainerStyleProps(endOverlay, match[1] && scrollable, pressed);
  var tmp = {};
  if (match$3.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match$3.className);
  }
  if (match$3.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match$3.style);
  }
  var tmp$1;
  if (showScrollButton) {
    var tmp$2 = {};
    if (match$4.className !== undefined) {
      tmp$2.className = Caml_option.valFromOption(match$4.className);
    }
    if (match$4.style !== undefined) {
      tmp$2.style = Caml_option.valFromOption(match$4.style);
    }
    tmp$1 = React.createElement("div", tmp$2, React.createElement(RoundButton.make, RoundButton.makeProps(ariaProps, icon, undefined, undefined, pressed, (function (param) {
                    Curry._1(handleControl, undefined);
                  }), undefined, undefined, undefined)));
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", tmp), tmp$1);
}

var styles$2 = Stylex.create({
      root: {
        minWidth: "0",
        position: "relative"
      },
      track: {
        scrollbarWidth: "0",
        scrollSnapType: "inline mandatory",
        display: "flex",
        overflow: "auto"
      },
      item: {
        scrollSnapAlign: "end",
        flexShrink: "0"
      }
    });

function trackStyleProps(gap) {
  return Stylex.props(styles$2.track, {
              columnGap: StyleX.toPx(Spaces.toFloat(gap))
            });
}

function itemStyleFromParams(cols, gap) {
  var cols$1 = String(cols);
  var gapPx = StyleX.toPx(Spaces.toFloat(gap));
  return Stylex.props(styles$2.item, {
              flexBasis: "calc(100% / " + cols$1 + " - " + gapPx + " / " + cols$1 + " * (" + cols$1 + " - 1))"
            });
}

function SliderTrack(Props) {
  var children = Props.children;
  var compactOpt = Props.compact;
  var gapOpt = Props.gap;
  var layoutOpt = Props.layout;
  var showIndicatorOpt = Props.showIndicator;
  var trackRef = Props.trackRef;
  var compact = compactOpt !== undefined ? compactOpt : false;
  var gap = gapOpt !== undefined ? gapOpt : "normal";
  var layout = layoutOpt !== undefined ? layoutOpt : /* Adaptative */0;
  var showIndicator = showIndicatorOpt !== undefined ? showIndicatorOpt : false;
  var ref = React.useRef(null);
  var trackRef$1 = Belt_Option.getWithDefault(trackRef, React.createRef());
  var match = Stylex.props(styles$2.root);
  var match$1 = trackStyleProps(gap);
  var tmp = {
    ref: ref
  };
  if (match.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match.className);
  }
  if (match.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match.style);
  }
  var tmp$1 = {
    ref: trackRef$1,
    "aria-label": "track"
  };
  if (match$1.className !== undefined) {
    tmp$1.className = Caml_option.valFromOption(match$1.className);
  }
  if (match$1.style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(match$1.style);
  }
  return React.createElement("div", tmp, React.createElement(SliderTrack$SideOverlay, {
                  showScrollButton: !compact,
                  gap: gap,
                  containerRef: ref,
                  trackRef: trackRef$1
                }), React.createElement("div", tmp$1, Belt_Array.mapWithIndex(React.Children.toArray(children), (function (idx, child) {
                        var match = layout ? itemStyleFromParams(layout._0, gap) : Stylex.props(styles$2.item);
                        var tmp = {
                          key: String(idx)
                        };
                        if (match.className !== undefined) {
                          tmp.className = Caml_option.valFromOption(match.className);
                        }
                        if (match.style !== undefined) {
                          tmp.style = Caml_option.valFromOption(match.style);
                        }
                        return React.createElement("div", tmp, child);
                      }))), React.createElement(SliderTrack$SideOverlay, {
                  end: true,
                  showScrollButton: !compact,
                  gap: gap,
                  containerRef: ref,
                  trackRef: trackRef$1
                }), layout && showIndicator ? React.createElement(SliderTrack$PaginationIndicator, {
                    itemsInView: layout._0,
                    gap: gap,
                    trackRef: trackRef$1
                  }) : null);
}

var make = React.memo(SliderTrack);

export {
  make ,
}
/* styles Not a pure module */
