// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Menu from "../../resources/navigation/Menu.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Column from "../../resources/layout-and-structure/Column.bs.js";
import * as Select from "../../resources/selection-and-input/Select.bs.js";
import * as Columns from "../../resources/layout-and-structure/Columns.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as MenuItem from "../../resources/navigation/MenuItem.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as InputNumberField from "../../resources/selection-and-input/InputNumberField.bs.js";
import * as Accounting__Utils from "@wino/accounting/src/Accounting__Utils.bs.js";
import * as CartProductFeeFieldset__Utils from "./CartProductFeeFieldset__Utils.bs.js";

function CartProductFeeFieldset(Props) {
  var displayLabel = Props.displayLabel;
  var fee = Props.fee;
  var product = Props.product;
  var decimalPrecision = Props.decimalPrecision;
  var onRequestProductFeeUpdate = Props.onRequestProductFeeUpdate;
  var onRequestProductFeeReplicate = Props.onRequestProductFeeReplicate;
  var onRequestProductFeeRemove = Props.onRequestProductFeeRemove;
  var feeRef = React.useRef(fee);
  React.useEffect((function () {
          feeRef.current = fee;
        }), [fee]);
  var onChangeKind = function (kind) {
    var fee = feeRef.current;
    Curry._2(onRequestProductFeeUpdate, fee.id, {
          kind: kind,
          amount: fee.amount,
          id: fee.id,
          formattedAmount: fee.formattedAmount,
          totalAmount: fee.totalAmount,
          formattedTotalAmount: fee.formattedTotalAmount
        });
  };
  var onChangeAmount = function (amount) {
    var fee = feeRef.current;
    Curry._2(onRequestProductFeeUpdate, fee.id, {
          kind: fee.kind,
          amount: amount,
          id: fee.id,
          formattedAmount: fee.formattedAmount,
          totalAmount: fee.totalAmount,
          formattedTotalAmount: fee.formattedTotalAmount
        });
  };
  var inputAppender;
  if (product.TAG === /* Unit */0) {
    inputAppender = {
      TAG: /* Currency */0,
      _0: "EUR"
    };
  } else {
    var unit = product._0.capacityUnit;
    inputAppender = unit !== undefined ? ({
          TAG: /* Custom */1,
          _0: $$Intl.toCurrencySymbol("EUR") + ("/" + unit + "")
        }) : ({
          TAG: /* Currency */0,
          _0: "EUR"
        });
  }
  var defaultItem_key = CartProductFeeFieldset__Utils.optionToText(fee.kind);
  var defaultItem_value = fee.kind;
  var defaultItem_label = CartProductFeeFieldset__Utils.optionToText(fee.kind);
  var defaultItem = {
    key: defaultItem_key,
    value: defaultItem_value,
    label: defaultItem_label
  };
  var items = Belt_Array.map(Accounting__Utils.getAvailableFeeKinds(product), (function (feedKind) {
          return {
                  key: CartProductFeeFieldset__Utils.optionToText(feedKind),
                  value: feedKind,
                  label: CartProductFeeFieldset__Utils.optionToText(feedKind)
                };
        }));
  var costTypeSections = [{
      items: Belt_Array.concat([defaultItem], items)
    }];
  var tmp = {
    sections: costTypeSections,
    preset: {
      NAME: "inputField",
      VAL: {
        required: false
      }
    },
    value: fee.kind,
    onChange: onChangeKind
  };
  var tmp$1 = displayLabel ? $$Intl.t("Cost type") : undefined;
  if (tmp$1 !== undefined) {
    tmp.label = tmp$1;
  }
  var tmp$2 = {
    value: fee.amount,
    minValue: 0,
    precision: decimalPrecision,
    appender: inputAppender,
    onChange: onChangeAmount
  };
  var tmp$3 = displayLabel ? $$Intl.t("Unit amount") : undefined;
  if (tmp$3 !== undefined) {
    tmp$2.label = tmp$3;
  }
  return React.createElement(Columns.make, {
              children: null,
              space: "medium",
              align: "end"
            }, React.createElement(Column.make, {
                  children: React.createElement(Select.make, tmp),
                  width: "half"
                }), React.createElement(Column.make, {
                  children: React.createElement(InputNumberField.make, tmp$2),
                  width: "half"
                }), React.createElement(Column.make, {
                  children: React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Menu.make, {
                                    children: null,
                                    variation: "more_round"
                                  }, React.createElement(MenuItem.make, {
                                        content: {
                                          TAG: /* Text */0,
                                          _0: $$Intl.t("Apply to all products")
                                        },
                                        action: {
                                          TAG: /* Callback */0,
                                          _0: (function (param) {
                                              Curry._1(onRequestProductFeeReplicate, fee);
                                            })
                                        }
                                      }), React.createElement(MenuItem.make, {
                                        content: {
                                          TAG: /* Text */0,
                                          _0: $$Intl.t("Remove")
                                        },
                                        action: {
                                          TAG: /* Callback */0,
                                          _0: (function (param) {
                                              Curry._1(onRequestProductFeeRemove, fee.id);
                                            })
                                        }
                                      }))), Caml_option.some(Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(1)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined)),
                  width: "content"
                }));
}

var make = React.memo(CartProductFeeFieldset, (function (oldProps, newProps) {
        return Caml_obj.equal(oldProps.fee, newProps.fee);
      }));

var Utils;

var InnerUtils;

export {
  Utils ,
  InnerUtils ,
  make ,
}
/* make Not a pure module */
