// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Scaffold from "../../bundles/Scaffold/Scaffold.bs.js";
import * as TextLink from "../../resources/navigation/TextLink.bs.js";
import * as JsonCodec from "../../primitives/JsonCodec.bs.js";
import * as SearchBar from "../../resources/selection-and-input/SearchBar.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as EmptyState from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Placeholder from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as Illustration from "../../resources/images-and-icons/Illustration.bs.js";
import * as TableDateCell from "../../resources/tables/TableDateCell.bs.js";
import * as Client from "@apollo/client";
import * as SupplierRoutes from "../Supplier/SupplierRoutes.bs.js";
import * as AmountTableCell from "../../modules/AmountTableCell.bs.js";
import * as OrderNameTableCell from "../../modules/Order/OrderNameTableCell.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query CatalogVariantOrderSupplyPageParentQuery($cku: CKU!)  {\nvariantsByCku(cku: $cku, first: 1)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \ncku  \nformattedName  \n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variantsByCku;
  var value$2 = value$1.edges;
  return {
          variantsByCku: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              cku: Scalar.CKU.parse(value$1.cku),
                              formattedName: value$1.formattedName
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.variantsByCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.formattedName;
          var value$3 = value$1.cku;
          var value$4 = Scalar.CKU.serialize(value$3);
          var value$5 = value$1.id;
          var value$6 = value$1.__typename;
          var node = {
            __typename: value$6,
            id: value$5,
            cku: value$4,
            formattedName: value$2
          };
          var value$7 = value.__typename;
          return {
                  __typename: value$7,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var variantsByCku = {
    __typename: value$3,
    edges: edges
  };
  return {
          variantsByCku: variantsByCku
        };
}

function serializeVariables(inp) {
  return {
          cku: inp.cku
        };
}

function makeVariables(cku, param) {
  return {
          cku: cku
        };
}

var ParentQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var ParentQuery_refetchQueryDescription = include.refetchQueryDescription;

var ParentQuery_useLazy = include.useLazy;

var ParentQuery_useLazyWithVariables = include.useLazyWithVariables;

var ParentQuery = {
  ParentQuery_inner: ParentQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: ParentQuery_refetchQueryDescription,
  use: use,
  useLazy: ParentQuery_useLazy,
  useLazyWithVariables: ParentQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query CatalogVariantOrderSupplyPageQuery($cku: CKU!, $search: String, $filterBy: InputOrderProductsByVariantCkuQueryFilter, $before: String, $after: String, $first: Int, $last: Int)  {\norderProductsByVariantCku(cku: $cku, search: $search, filterBy: $filterBy, before: $before, after: $after, first: $first, last: $last)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\ntotalCount  \nedges  {\n__typename  \nnode  {\n__typename  \nquantity  \ntotalAmountExcludingTaxes  \ntotalAmountIncludingTaxes  \norder  {\n__typename  \nid  \nname  \nestimatedReceptionDate  \nsupplierCompanyName  \nsupplier  {\n__typename  \nid  \n}\n\n}\n\nshop  {\n__typename  \nname  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.orderProductsByVariantCku;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          orderProductsByVariantCku: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            totalCount: value$1.totalCount,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.order;
                    var tmp;
                    if (value$2 == null) {
                      tmp = undefined;
                    } else {
                      var value$3 = value$2.name;
                      var value$4 = value$2.supplier;
                      tmp = {
                        __typename: value$2.__typename,
                        id: value$2.id,
                        name: !(value$3 == null) ? value$3 : undefined,
                        estimatedReceptionDate: Scalar.Datetime.parse(value$2.estimatedReceptionDate),
                        supplierCompanyName: value$2.supplierCompanyName,
                        supplier: !(value$4 == null) ? ({
                              __typename: value$4.__typename,
                              id: value$4.id
                            }) : undefined
                      };
                    }
                    var value$5 = value$1.shop;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              quantity: value$1.quantity,
                              totalAmountExcludingTaxes: value$1.totalAmountExcludingTaxes,
                              totalAmountIncludingTaxes: value$1.totalAmountIncludingTaxes,
                              order: tmp,
                              shop: {
                                __typename: value$5.__typename,
                                name: value$5.name
                              }
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.orderProductsByVariantCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.shop;
          var value$3 = value$2.name;
          var value$4 = value$2.__typename;
          var shop = {
            __typename: value$4,
            name: value$3
          };
          var value$5 = value$1.order;
          var order;
          if (value$5 !== undefined) {
            var value$6 = value$5.supplier;
            var supplier;
            if (value$6 !== undefined) {
              var value$7 = value$6.id;
              var value$8 = value$6.__typename;
              supplier = {
                __typename: value$8,
                id: value$7
              };
            } else {
              supplier = null;
            }
            var value$9 = value$5.supplierCompanyName;
            var value$10 = value$5.estimatedReceptionDate;
            var value$11 = Scalar.Datetime.serialize(value$10);
            var value$12 = value$5.name;
            var name = value$12 !== undefined ? value$12 : null;
            var value$13 = value$5.id;
            var value$14 = value$5.__typename;
            order = {
              __typename: value$14,
              id: value$13,
              name: name,
              estimatedReceptionDate: value$11,
              supplierCompanyName: value$9,
              supplier: supplier
            };
          } else {
            order = null;
          }
          var value$15 = value$1.totalAmountIncludingTaxes;
          var value$16 = value$1.totalAmountExcludingTaxes;
          var value$17 = value$1.quantity;
          var value$18 = value$1.__typename;
          var node = {
            __typename: value$18,
            quantity: value$17,
            totalAmountExcludingTaxes: value$16,
            totalAmountIncludingTaxes: value$15,
            order: order,
            shop: shop
          };
          var value$19 = value.__typename;
          return {
                  __typename: value$19,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.totalCount;
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var orderProductsByVariantCku = {
    __typename: value$8,
    pageInfo: pageInfo,
    totalCount: value$3,
    edges: edges
  };
  return {
          orderProductsByVariantCku: orderProductsByVariantCku
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputOrderProductsByVariantCkuQueryFilter(inp) {
  var a = inp.archived;
  var a$1 = inp.receptionFinishedAt;
  var a$2 = inp.shopIds;
  return {
          archived: a !== undefined ? (
              a === "ONLY" ? "ONLY" : (
                  a === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          receptionFinishedAt: a$1 !== undefined ? serializeInputObjectDateFilter(a$1) : undefined,
          shopIds: a$2 !== undefined ? serializeInputObjectInFilter(a$2) : undefined
        };
}

function serializeVariables$1(inp) {
  var a = inp.search;
  var a$1 = inp.filterBy;
  var a$2 = inp.before;
  var a$3 = inp.after;
  var a$4 = inp.first;
  var a$5 = inp.last;
  return {
          cku: inp.cku,
          search: a !== undefined ? a : undefined,
          filterBy: a$1 !== undefined ? serializeInputObjectInputOrderProductsByVariantCkuQueryFilter(a$1) : undefined,
          before: a$2 !== undefined ? a$2 : undefined,
          after: a$3 !== undefined ? a$3 : undefined,
          first: a$4 !== undefined ? a$4 : undefined,
          last: a$5 !== undefined ? a$5 : undefined
        };
}

function makeVariables$1(cku, search, filterBy, before, after, first, last, param) {
  return {
          cku: cku,
          search: search,
          filterBy: filterBy,
          before: before,
          after: after,
          first: first,
          last: last
        };
}

function makeInputObjectInputOrderProductsByVariantCkuQueryFilter(archived, receptionFinishedAt, shopIds, param) {
  return {
          archived: archived,
          receptionFinishedAt: receptionFinishedAt,
          shopIds: shopIds
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

var Query_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputOrderProductsByVariantCkuQueryFilter: serializeInputObjectInputOrderProductsByVariantCkuQueryFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables$1,
  makeInputObjectInputOrderProductsByVariantCkuQueryFilter: makeInputObjectInputOrderProductsByVariantCkuQueryFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectInFilter: makeInputObjectInFilter
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var Query_refetchQueryDescription = include$1.refetchQueryDescription;

var Query_useLazy = include$1.useLazy;

var Query_useLazyWithVariables = include$1.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputOrderProductsByVariantCkuQueryFilter: serializeInputObjectInputOrderProductsByVariantCkuQueryFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables$1,
  makeInputObjectInputOrderProductsByVariantCkuQueryFilter: makeInputObjectInputOrderProductsByVariantCkuQueryFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use$1,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function encoder(param) {
  return Belt_Option.map(param.shop, (function (shop) {
                return shop.id;
              }));
}

function decoder(shops, shopId) {
  return {
          TAG: /* Ok */0,
          _0: {
            shop: Belt_Array.getBy(shops, (function (shop) {
                    return shop.id === shopId;
                  }))
          }
        };
}

function useJsonCodec(param) {
  var shops = Auth.useShops(undefined);
  return JsonCodec.object1(encoder, (function (param) {
                return decoder(shops, param);
              }), JsonCodec.optional(JsonCodec.field("shopId", JsonCodec.string)));
}

var Filters = {
  encoder: encoder,
  decoder: decoder,
  useJsonCodec: useJsonCodec
};

var Row = {};

function makeQueryVariables(defaultQueryVariables, connectionArguments, search, filterBy, param) {
  return {
          cku: defaultQueryVariables.cku,
          search: search,
          filterBy: filterBy,
          before: connectionArguments.before,
          after: connectionArguments.after,
          first: connectionArguments.first,
          last: connectionArguments.last
        };
}

function makeQueryVariablesFilterBy(param) {
  var shop = param.shop;
  return {
          archived: "INCLUDED",
          receptionFinishedAt: {
            _after: undefined,
            _before: Caml_option.some(Scalar.Datetime.serialize(new Date(Date.now()))),
            _between: undefined
          },
          shopIds: shop !== undefined ? ({
                _in: [shop.id]
              }) : undefined
        };
}

function totalCountFromQueryData(param) {
  return param.orderProductsByVariantCku.totalCount;
}

function cursorsFromQueryData(param) {
  var orderProductsByVariantCku = param.orderProductsByVariantCku;
  return [
          orderProductsByVariantCku.pageInfo.startCursor,
          orderProductsByVariantCku.pageInfo.endCursor
        ];
}

function rowsFromQueryDataAndState(param, param$1) {
  return Belt_Array.keepMap(param.orderProductsByVariantCku.edges, (function (param) {
                var orderProduct = param.node;
                var match = orderProduct.order;
                var match$1 = Belt_Option.flatMap(orderProduct.order, (function (order) {
                        return order.supplier;
                      }));
                if (match !== undefined && match$1 !== undefined) {
                  return {
                          id: match.id,
                          name: Belt_Option.getWithDefault(match.name, "?"),
                          shopName: orderProduct.shop.name,
                          receptionDate: match.estimatedReceptionDate,
                          quantity: String(orderProduct.quantity),
                          totalAmountExcludingTaxes: orderProduct.totalAmountExcludingTaxes,
                          totalAmountIncludingTaxes: orderProduct.totalAmountIncludingTaxes,
                          supplierId: match$1.id,
                          supplierCompanyName: match.supplierCompanyName
                        };
                }
                
              }));
}

function keyExtractor(param) {
  return param.id;
}

var Scaffolded = Scaffold.Make({
      useFiltersJsonCodec: useJsonCodec,
      QueryInner: Query_inner,
      useQuery: use$1,
      makeQueryVariables: makeQueryVariables,
      makeQueryVariablesFilterBy: makeQueryVariablesFilterBy,
      cursorsFromQueryData: cursorsFromQueryData,
      totalCountFromQueryData: totalCountFromQueryData,
      rowsFromQueryDataAndState: rowsFromQueryDataAndState,
      keyExtractor: keyExtractor
    });

function CatalogVariantOrderSupplyPage(Props) {
  var cku = Props.cku;
  var authScope = Auth.useScope(undefined);
  var activeShop = Auth.useActiveShop(undefined);
  var cku$1 = Scalar.CKU.serialize(cku);
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          cku: cku$1
        }
      ]);
  var match$1 = match.data;
  var pageSubtitle;
  var exit = 0;
  if (match$1 !== undefined) {
    var match$2 = match$1.variantsByCku.edges;
    if (match$2.length !== 1) {
      exit = 1;
    } else {
      var match$3 = match$2[0];
      pageSubtitle = match$3.node.formattedName;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    pageSubtitle = match.error !== undefined ? undefined : $$Intl.t("Loading") + "...";
  }
  var initialState = Curry._1(Scaffolded.makeInitialState, {
        shop: activeShop
      });
  var match$4 = Curry._1(Scaffolded.use, (function (param) {
          return initialState;
        }));
  var dispatch = match$4[1];
  var state = match$4[0];
  var defaultQueryVariables = makeVariables$1(cku$1, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var columns = [
    {
      name: $$Intl.t("Number"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 160
        },
        width: {
          NAME: "pct",
          VAL: 30
        },
        margin: "normal",
        sticky: true
      },
      render: (function (param) {
          return React.createElement(OrderNameTableCell.make, {
                      value: param.name,
                      shopName: param.shopName,
                      id: param.id
                    });
        })
    },
    {
      name: $$Intl.t("Supplier"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 180
        },
        width: {
          NAME: "pct",
          VAL: 20
        },
        margin: "small"
      },
      render: (function (param) {
          return React.createElement(TextLink.make, {
                      text: param.supplierCompanyName,
                      to: {
                        TAG: /* Route */0,
                        _0: SupplierRoutes.showRoute(param.supplierId)
                      }
                    });
        })
    },
    {
      name: $$Intl.t("Reception date"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 150
        },
        width: {
          NAME: "fr",
          VAL: 2
        },
        margin: "huge"
      },
      render: (function (param) {
          return React.createElement(TableDateCell.make, {
                      value: Caml_option.some(param.receptionDate)
                    });
        })
    },
    {
      name: $$Intl.t("Amount VAT excl."),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        }
      },
      render: (function (param) {
          return React.createElement(AmountTableCell.make, {
                      value: param.totalAmountExcludingTaxes,
                      secondaryValue: param.totalAmountIncludingTaxes,
                      decimalPrecision: 3
                    });
        })
    },
    {
      name: $$Intl.t("Quantity"),
      layout: {
        width: {
          NAME: "fr",
          VAL: 2
        },
        alignX: "center"
      },
      render: (function (param) {
          return React.createElement(TextStyle.make, {
                      children: param.quantity
                    });
        })
    }
  ];
  var filters;
  if (authScope.TAG === /* Organisation */0) {
    var tmp = {
      onChange: (function (shop) {
          Curry._1(dispatch, {
                TAG: /* FiltersUpdated */2,
                _0: (function (param) {
                    return {
                            shop: shop
                          };
                  })
              });
        })
    };
    if (state.filters.shop !== undefined) {
      tmp.value = Caml_option.valFromOption(state.filters.shop);
    }
    filters = React.createElement(Auth.SelectShopFilter.make, tmp);
  } else {
    filters = null;
  }
  var tmp$1 = {
    placeholder: $$Intl.t("Search an order"),
    onChange: (function (searchQuery) {
        Curry._1(dispatch, {
              TAG: /* Searched */1,
              _0: searchQuery
            });
      })
  };
  if (state.searchQuery !== undefined) {
    tmp$1.value = state.searchQuery;
  }
  var searchBar = React.createElement(SearchBar.make, tmp$1);
  var emptyState;
  var exit$1 = 0;
  if (state.currentPage !== 1 || state.searchQuery !== undefined) {
    exit$1 = 1;
  } else {
    var exit$2 = 0;
    if (authScope.TAG === /* Organisation */0 && authScope.activeShop !== undefined) {
      emptyState = React.createElement(EmptyState.make, {
            illustration: Illustration.notFound,
            title: $$Intl.t("Welcome to the supplier orders space."),
            text: $$Intl.t("It seems no data exists yet for this shop.")
          });
    } else {
      exit$2 = 2;
    }
    if (exit$2 === 2) {
      emptyState = React.createElement(EmptyState.make, {
            illustration: Illustration.create,
            title: $$Intl.t("Welcome to the supplier orders space.")
          });
    }
    
  }
  if (exit$1 === 1) {
    emptyState = React.createElement(EmptyState.make, {
          illustration: Illustration.notFound,
          title: $$Intl.t("No result were found."),
          text: $$Intl.t("Try again with another keyword/filter or:"),
          children: React.createElement(Button.make, Button.makeProps($$Intl.t("Clear search query and filters"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Reset */3,
                            _0: initialState
                          });
                    }), undefined, undefined, undefined))
        });
  }
  if (pageSubtitle !== undefined) {
    return React.createElement(Scaffolded.make, {
                title: $$Intl.t("Received supplier orders"),
                subtitle: pageSubtitle,
                state: state,
                dispatch: dispatch,
                columns: columns,
                filters: filters,
                searchBar: searchBar,
                emptyState: emptyState,
                defaultQueryVariables: defaultQueryVariables
              });
  } else {
    return React.createElement(Placeholder.make, {
                status: /* Error */2
              });
  }
}

var make = React.memo(CatalogVariantOrderSupplyPage);

export {
  ParentQuery ,
  Query ,
  Filters ,
  Row ,
  Scaffolded ,
  make ,
}
/* query Not a pure module */
