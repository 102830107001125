// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../images-and-icons/Icon.bs.js";
import * as $$Text from "../../primitives/Text.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Field from "../layout-and-structure/Field.bs.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as Stack from "../layout-and-structure/Stack.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Spaces from "../theme/Spaces.bs.js";
import * as FontFaces from "../theme/FontFaces.bs.js";
import * as FontSizes from "../theme/FontSizes.bs.js";
import * as Touchable from "../../primitives/Touchable.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var styles = $$StyleSheet.create({
      root: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.xsmall)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(20)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      item: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      itemIcon: Style.merge([
            Style.unsafeCss({
                  height: "16px",
                  width: "16px"
                }),
            Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#fefefe", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 50, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, "#07061e", Caml_option.some(Style.shadowOffset(0.1, 0.0)), 0.08, 1, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      itemIconEnabled: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#797885", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      itemIconDisabled: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#e7e7ee", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      itemText: Style.merge([
            FontFaces.libreFranklinRegularStyle,
            Style.style(undefined, undefined, undefined, undefined, undefined, FontSizes.normal, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(8)), undefined, undefined, Caml_option.some(Style.dp(-1)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      itemTextDisabled: Style.merge([Style.style(undefined, undefined, undefined, "#797885", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)])
    });

function itemIconStyleFromParams(active, hovered) {
  return Style.arrayStyle([
              styles.itemIcon,
              active || hovered ? styles.itemIconEnabled : styles.itemIconDisabled
            ]);
}

function itemTextStyleFromParams(disabled, active) {
  return Style.arrayOptionStyle([
              Caml_option.some(styles.itemText),
              disabled && !active ? Caml_option.some(styles.itemTextDisabled) : undefined
            ]);
}

var make = React.memo(function (Props) {
      var children = Props.children;
      var active = Props.active;
      var onPress = Props.onPress;
      var disabled = Props.disabled;
      var match = Hover.use(undefined, undefined);
      return React.createElement(Touchable.make, Touchable.makeProps(React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.item), undefined, match[0], undefined), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Icon.make, {
                                          name: "inner_oval",
                                          fill: active ? "#8a0262" : "#fefefe",
                                          size: 16
                                        })), Caml_option.some(itemIconStyleFromParams(active, !disabled && match[1])), undefined, undefined, undefined)), React.createElement($$Text.make, $$Text.makeProps(children, Caml_option.some(itemTextStyleFromParams(disabled, active)), undefined, undefined, undefined))), undefined, disabled, undefined, undefined, undefined, (function (param) {
                        Curry._1(onPress, undefined);
                      }), undefined, undefined, undefined));
    });

function InputRadioGroupField(Props) {
  var label = Props.label;
  var value = Props.value;
  var required = Props.required;
  var onChange = Props.onChange;
  var isEqualValueOpt = Props.isEqualValue;
  var errorMessage = Props.errorMessage;
  var options = Props.options;
  var optionToText = Props.optionToText;
  var optionToDisable = Props.optionToDisable;
  var isEqualValue = isEqualValueOpt !== undefined ? isEqualValueOpt : (function (a, b) {
        return a === b;
      });
  return React.createElement(Field.make, Field.makeProps(React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Stack.make, {
                                  children: Belt_Array.map(options, (function (item) {
                                          return React.createElement(make, {
                                                      children: Curry._1(optionToText, item),
                                                      active: Curry._2(isEqualValue, value, item),
                                                      onPress: (function (param) {
                                                          Curry._1(onChange, item);
                                                        }),
                                                      disabled: optionToDisable !== undefined ? Curry._1(optionToDisable, item) : false
                                                    });
                                        })),
                                  space: "normal"
                                })), Caml_option.some(styles.root), undefined, undefined, undefined)), label, undefined, undefined, errorMessage, undefined, required, undefined, undefined, undefined));
}

var make$1 = InputRadioGroupField;

export {
  make$1 as make,
}
/* styles Not a pure module */
