// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as CatalogVariantEditForm from "./CatalogVariantEditForm.bs.js";
import * as CatalogVariantEditFormMutations from "./CatalogVariantEditFormMutations.bs.js";

function CatalogVariantEditFormPageActions(Props) {
  var editionMode = Props.editionMode;
  var productValues = Props.productValues;
  var updateVariant = CatalogVariantEditFormMutations.useUpdateVariant(undefined);
  var createVariant = CatalogVariantEditFormMutations.useCreateVariant(undefined);
  var createProductAndVariant = CatalogVariantEditFormMutations.useCreateProductAndVariant(productValues);
  var onSubmit = editionMode ? updateVariant : (
      productValues !== undefined ? createProductAndVariant : createVariant
    );
  return React.createElement(Inline.make, {
              children: React.createElement(CatalogVariantEditForm.SubmitButton.make, {
                    variation: "success",
                    size: "medium",
                    onSubmit: onSubmit,
                    text: $$Intl.t("Save")
                  }),
              space: "small"
            });
}

var make = React.memo(CatalogVariantEditFormPageActions);

var Mutations;

export {
  Mutations ,
  make ,
}
/* make Not a pure module */
