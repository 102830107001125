// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Sheet from "../../primitives/Sheet.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Accounting__Utils from "@wino/accounting/src/Accounting__Utils.bs.js";
import * as OrderCartCsvExporting__Utils from "./OrderCartCsvExporting__Utils.bs.js";

function purchaseOrder(statuses) {
  var match = Belt_Array.getExn(statuses, 0);
  return !(match === "RECEIVED" || match === "RECEIVING");
}

function makeFilename(statuses, name) {
  return $$Intl.t(purchaseOrder(statuses) ? "purchaseorder" : "receiptorder") + ("_" + (name.replace("N°", "") + ".csv"));
}

function currencyFormat(value) {
  return $$Intl.currencyFormat("EUR", 3, 3, value);
}

function makePlainText(cart, name, statuses, supplierName, supplierAddress, supplierPostalCode, supplierCity, supplierCountry) {
  var titleRow = [[$$Intl.template($$Intl.t("Order{{entilted}}{{name}}"), {
            entilted: " ",
            name: name
          }, undefined) + (" - " + $$Intl.t(purchaseOrder(statuses) ? "Purchase order" : "Order receipt"))]];
  var supplierHeader = [$$Intl.t("Supplier's informations") + " :"];
  var supplierRow = supplierAddress === "" ? "" + supplierPostalCode + " " + supplierCity + " " + supplierCountry + "" : "" + supplierAddress + ", " + supplierPostalCode + " " + supplierCity + " " + supplierCountry + "";
  var fees = cart.fees;
  var feeHeaders = fees !== undefined ? Belt_Array.map(fees, (function (fee) {
            return OrderCartCsvExporting__Utils.kindToHeaderText(fee.kind);
          })) : [$$Intl.t("U. fees")];
  var match = cart.taxesFree;
  var headers = Belt_Array.concatMany([
        [
          "SKU",
          $$Intl.t("Product's name"),
          $$Intl.t("Description"),
          $$Intl.t(purchaseOrder(statuses) ? "Ordered quantity" : "Received quantity"),
          $$Intl.t("Packaging"),
          $$Intl.t("U. price excl. VAT"),
          $$Intl.t("Discount")
        ],
        feeHeaders,
        Belt_Array.concat(match ? [] : [$$Intl.t("VAT")], [$$Intl.t("Total excl. VAT")])
      ]);
  var productRows = Belt_Array.map(cart.products, (function (product) {
          var match;
          if (product.TAG === /* Unit */0) {
            var product$1 = product._0;
            match = [
              product$1.stockKeepingUnit,
              product$1.name,
              product$1.description,
              product$1.formattedQuantity,
              Belt_Option.map(product$1.packaging, (function (prim) {
                      return prim;
                    })),
              currencyFormat(product$1.unitPrice),
              Belt_Option.map(Belt_Option.map(product$1.totalDiscounts, (function (prim) {
                          return prim.toNumber();
                        })), currencyFormat),
              product$1.fees,
              product$1.taxes,
              Belt_Option.map(Belt_Option.map(product$1.totalAmountExcludingTaxes, (function (prim) {
                          return prim.toNumber();
                        })), currencyFormat)
            ];
          } else {
            var product$2 = product._0;
            match = [
              product$2.stockKeepingUnit,
              product$2.name,
              product$2.description,
              product$2.formattedQuantity,
              Belt_Option.map(product$2.packaging, (function (prim) {
                      return prim.toNumber();
                    })),
              currencyFormat(product$2.unitPrice),
              Belt_Option.map(Belt_Option.map(product$2.totalDiscounts, (function (prim) {
                          return prim.toNumber();
                        })), currencyFormat),
              product$2.fees,
              product$2.taxes,
              Belt_Option.map(Belt_Option.map(product$2.totalAmountExcludingTaxes, (function (prim) {
                          return prim.toNumber();
                        })), currencyFormat)
            ];
          }
          var productTaxes = match[8];
          var match$1 = cart.taxesFree;
          return Belt_Array.concatMany([
                      [
                        Belt_Option.getWithDefault(match[0], ""),
                        match[1],
                        match[2],
                        Belt_Option.getWithDefault(match[3], ""),
                        Belt_Option.mapWithDefault(match[4], "", (function (prim) {
                                return String(prim);
                              })),
                        Belt_Option.getWithDefault(match[5], ""),
                        Belt_Option.getWithDefault(match[6], "")
                      ],
                      OrderCartCsvExporting__Utils.getFeesRowData(match[7], cart.fees),
                      Belt_Array.concat(productTaxes !== undefined ? (
                              match$1 ? [] : [OrderCartCsvExporting__Utils.formattedTaxRates(productTaxes)]
                            ) : [], [Belt_Option.getWithDefault(match[9], "")])
                    ]);
        }));
  var formattedTotalAmountExcludingTaxes = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(cart.totalAmountExcludingTaxes)));
  var formattedTotalAmountIncludingTaxes = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(cart.totalAmountIncludingTaxes)));
  var formattedTotalTaxes = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(cart.totalTaxes)));
  var totalsRows = Belt_Array.concatMany(cart.taxesFree ? [[[
              $$Intl.t("Total amount excl. VAT"),
              formattedTotalAmountIncludingTaxes
            ]]] : [
          [[
              $$Intl.t("Excl. VAT total"),
              formattedTotalAmountExcludingTaxes
            ]],
          Belt_Array.map(Belt_Option.getExn(cart.taxes), (function (tax) {
                  var formattedTaxAmount = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(tax.amount)));
                  var formattedTaxRate = $$Intl.percentFormat(undefined, 2, tax.rate / 100);
                  return [
                          $$Intl.t("VAT") + " " + formattedTaxRate,
                          formattedTaxAmount
                        ];
                })),
          [[
              $$Intl.t("VAT total"),
              formattedTotalTaxes
            ]],
          [[
              $$Intl.t("Total amount incl. VAT"),
              formattedTotalAmountIncludingTaxes
            ]]
        ]);
  return Sheet.makeCsvPlainText(undefined, Belt_Array.concatMany([
                  titleRow,
                  [[]],
                  [supplierHeader],
                  [[supplierName]],
                  [[supplierRow.trim()]],
                  [[]],
                  [Belt_Array.keepWithIndex(headers, (function (param, idx) {
                            return Belt_Array.some(productRows, (function (row) {
                                          return Belt_Array.getExn(row, idx) !== "";
                                        }));
                          }))],
                  Belt_Array.map(productRows, (function (row) {
                          return Belt_Array.keepWithIndex(row, (function (param, idx) {
                                        return Belt_Array.some(productRows, (function (row) {
                                                      return Belt_Array.getExn(row, idx) !== "";
                                                    }));
                                      }));
                        })),
                  [[]],
                  totalsRows
                ]));
}

function makeBlob(cart, name, statuses, supplierName, supplierAddress, supplierPostalCode, supplierCity, supplierCountry) {
  return Belt_Result.map(makePlainText(cart, name, statuses, supplierName, supplierAddress, supplierPostalCode, supplierCity, supplierCountry), Sheet.makeCsvBlobFromPlainText);
}

var Utils;

var bigToFloat = Accounting__Utils.bigToFloat;

export {
  Utils ,
  purchaseOrder ,
  makeFilename ,
  bigToFloat ,
  currencyFormat ,
  makePlainText ,
  makeBlob ,
}
/* Intl Not a pure module */
