// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Notifier from "../../bundles/Notifier/Notifier.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Notifier__Context from "../../bundles/Notifier/Notifier__Context.bs.js";
import * as StockTransferListPage from "./StockTransferListPage.bs.js";
import * as StockTransferCreatePage from "./StockTransferCreatePage.bs.js";

var baseRoute = "/stocktransfer";

var createRoute = baseRoute + "/create";

function StockTransferRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  var tmp;
  var exit = 0;
  if (subUrlPath) {
    if (subUrlPath.hd === "create" && !subUrlPath.tl) {
      tmp = React.createElement(StockTransferCreatePage.make, {});
    } else {
      exit = 1;
    }
  } else {
    tmp = React.createElement(StockTransferListPage.make, {
          stockTransferCreateRoute: createRoute
        });
  }
  if (exit === 1) {
    tmp = React.createElement(Navigation.Redirect.make, {
          route: baseRoute
        });
  }
  return React.createElement(Notifier__Context.Provider.make, Notifier__Context.Provider.makeProps(Notifier.createContext(undefined), tmp, undefined));
}

var make = StockTransferRouter;

export {
  baseRoute ,
  createRoute ,
  make ,
}
/* react Not a pure module */
