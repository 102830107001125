// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Price from "../Price/Price.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var CatalogVariantRetailPriceReducer_CouldNotUpdatePrice = /* @__PURE__ */Caml_exceptions.create("CatalogVariantRetailPriceReducer.CatalogVariantRetailPriceReducer_CouldNotUpdatePrice");

function make(state, action) {
  switch (action.TAG | 0) {
    case /* RetailPriceUpdated */0 :
        var newPrice = action._1;
        var idSet = action._0;
        return Belt_Array.map(state, (function (retailPrice) {
                      if (!(retailPrice.id === idSet.id && retailPrice.priceId === idSet.priceId && retailPrice.variantId === idSet.variantId)) {
                        return retailPrice;
                      }
                      if (newPrice.TAG === /* PriceExclTax */0) {
                        return {
                                id: retailPrice.id,
                                priceId: retailPrice.priceId,
                                variantId: retailPrice.variantId,
                                shopName: retailPrice.shopName,
                                name: retailPrice.name,
                                value: newPrice,
                                secondaryValue: retailPrice.secondaryValue,
                                taxRate: retailPrice.taxRate,
                                purchasePrice: retailPrice.purchasePrice,
                                toQuantity: retailPrice.toQuantity,
                                fromQuantity: retailPrice.fromQuantity,
                                capacityUnit: retailPrice.capacityUnit,
                                edited: true
                              };
                      }
                      var value = Price.makeWithTax(newPrice, retailPrice.taxRate);
                      var tmp;
                      if (value !== undefined) {
                        tmp = value;
                      } else {
                        throw {
                              RE_EXN_ID: CatalogVariantRetailPriceReducer_CouldNotUpdatePrice,
                              Error: new Error()
                            };
                      }
                      return {
                              id: retailPrice.id,
                              priceId: retailPrice.priceId,
                              variantId: retailPrice.variantId,
                              shopName: retailPrice.shopName,
                              name: retailPrice.name,
                              value: newPrice,
                              secondaryValue: tmp,
                              taxRate: retailPrice.taxRate,
                              purchasePrice: retailPrice.purchasePrice,
                              toQuantity: retailPrice.toQuantity,
                              fromQuantity: retailPrice.fromQuantity,
                              capacityUnit: retailPrice.capacityUnit,
                              edited: true
                            };
                    }));
    case /* RetailPricesPurchaseValueUpdated */1 :
        var newState = action._0;
        return Belt_Array.map(state, (function (retailPrice) {
                      var match = Belt_Array.getBy(newState, (function (current) {
                              if (current.id === retailPrice.id && current.priceId === retailPrice.priceId) {
                                return current.variantId === retailPrice.variantId;
                              } else {
                                return false;
                              }
                            }));
                      return {
                              id: retailPrice.id,
                              priceId: retailPrice.priceId,
                              variantId: retailPrice.variantId,
                              shopName: retailPrice.shopName,
                              name: retailPrice.name,
                              value: retailPrice.value,
                              secondaryValue: retailPrice.secondaryValue,
                              taxRate: retailPrice.taxRate,
                              purchasePrice: match !== undefined ? match.purchasePrice : retailPrice.purchasePrice,
                              toQuantity: retailPrice.toQuantity,
                              fromQuantity: retailPrice.fromQuantity,
                              capacityUnit: retailPrice.capacityUnit,
                              edited: retailPrice.edited
                            };
                    }));
    case /* Reset */2 :
        return action._0;
    
  }
}

export {
  CatalogVariantRetailPriceReducer_CouldNotUpdatePrice ,
  make ,
}
/* No side effect */
