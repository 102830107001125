// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Banner from "../../resources/feedback-indicators/Banner.bs.js";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Divider from "../../resources/layout-and-structure/Divider.bs.js";
import * as InfoBlock from "../../resources/layout-and-structure/InfoBlock.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as InlineText from "../../resources/layout-and-structure/InlineText.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as OrderEditForm from "./OrderEditForm.bs.js";

function OrderInformationCard(Props) {
  var editable = Props.editable;
  var receptionFinishedAt = Props.receptionFinishedAt;
  var updatedAt = Props.updatedAt;
  var state = Curry._1(OrderEditForm.useFormState, undefined);
  var dispatch = Curry._1(OrderEditForm.useFormDispatch, undefined);
  var scope = Auth.useScope(undefined);
  var shops = Auth.useShops(undefined);
  var renderSelectedShop = function (param) {
    var shop = Belt_Array.getBy(shops, (function (shop) {
            return shop.id === state.values.shopId;
          }));
    if (shop !== undefined) {
      return React.createElement(InlineText.make, {
                  children: null
                }, React.createElement(TextStyle.make, {
                      children: $$Intl.t("By") + " ",
                      variation: "normal"
                    }), React.createElement(TextStyle.make, {
                      children: shop.name,
                      weight: "strong"
                    }));
    } else {
      return null;
    }
  };
  React.useEffect((function () {
          if (state.values.shopId !== state.initialValues.shopId) {
            var maybeShop = Belt_Array.getBy(shops, (function (shop) {
                    return shop.id === state.values.shopId;
                  }));
            if (state.values.supplierId !== "") {
              Curry._1(dispatch, {
                    TAG: /* FieldValueChanged */2,
                    _0: /* SupplierId */2,
                    _1: (function (param) {
                        return "";
                      })
                  });
            }
            if (state.values.cart.products.length !== 0) {
              Curry._1(dispatch, {
                    TAG: /* FieldValueChanged */2,
                    _0: /* Cart */6,
                    _1: (function (param) {
                        return state.initialValues.cart;
                      })
                  });
            }
            if (maybeShop !== undefined) {
              var activeWebDeviceId = maybeShop.activeWebDeviceId;
              Curry._1(dispatch, {
                    TAG: /* FieldValueChanged */2,
                    _0: /* DeviceId */8,
                    _1: (function (param) {
                        return activeWebDeviceId;
                      })
                  });
            }
            
          }
          
        }), [state.values.shopId]);
  var tmp;
  if (editable) {
    var match = state.id;
    var tmp$1;
    if (scope.TAG === /* Organisation */0 && match === undefined) {
      var items = Belt_Array.map(shops, (function (shop) {
              return {
                      key: shop.id,
                      value: shop.id,
                      label: shop.name
                    };
            }));
      tmp$1 = React.createElement(Stack.make, {
            children: null,
            space: "small"
          }, React.createElement(OrderEditForm.InputSelect.make, {
                field: /* ShopId */7,
                label: $$Intl.t("Shop"),
                placeholder: $$Intl.t("Select a shop"),
                sections: [{
                    items: items
                  }]
              }), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Banner.make, {
                            textStatus: {
                              TAG: /* Warning */2,
                              _0: $$Intl.t("Beware ! Once the draft is saved, you will no longer be able to edit the shop.")
                            },
                            compact: false
                          })), undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
    } else {
      tmp$1 = renderSelectedShop(undefined);
    }
    tmp = React.createElement(Stack.make, {
          children: null,
          space: "large"
        }, React.createElement(OrderEditForm.InputDate.make, {
              label: $$Intl.t("Date of issue"),
              field: /* IssueDate */3
            }), tmp$1);
  } else {
    var tmp$2;
    if (updatedAt !== undefined) {
      var datetime = Caml_option.valFromOption(updatedAt);
      tmp$2 = $$Intl.template($$Intl.t("{{date}} to {{time}}"), {
            date: $$Intl.dateTimeFormat("short", undefined, undefined, undefined, undefined, undefined, undefined, undefined, datetime),
            time: $$Intl.dateTimeFormat(undefined, "short", undefined, undefined, undefined, undefined, undefined, undefined, datetime)
          }, undefined);
    } else {
      tmp$2 = $$Intl.t("No registration");
    }
    tmp = React.createElement(Stack.make, {
          children: null,
          space: "xsmall"
        }, React.createElement(InlineText.make, {
              children: null
            }, React.createElement(TextStyle.make, {
                  children: $$Intl.t("Issued on") + " ",
                  variation: "normal"
                }), React.createElement(TextStyle.make, {
                  children: $$Intl.dateTimeFormat("short", undefined, undefined, undefined, undefined, undefined, undefined, undefined, state.values.issueDate)
                })), renderSelectedShop(undefined), React.createElement(Inline.make, {
              children: null,
              space: "none"
            }, React.createElement(TextStyle.make, {
                  children: $$Intl.t("Last modif. on") + " ",
                  variation: "normal"
                }), React.createElement(TextStyle.make, {
                  children: tmp$2
                })));
  }
  var shop = Belt_Array.getBy(shops, (function (shop) {
          return shop.id === state.values.shopId;
        }));
  var tmp$3;
  if (shop !== undefined) {
    var legalRepresentative = shop.legalRepresentative;
    tmp$3 = React.createElement(Stack.make, {
          children: null,
          space: "xlarge"
        }, React.createElement(InfoBlock.make, {
              children: null,
              title: $$Intl.t("CONTACT")
            }, legalRepresentative !== undefined ? React.createElement(TextStyle.make, {
                    children: legalRepresentative
                  }) : null, React.createElement(TextStyle.make, {
                  children: shop.email
                }), React.createElement(TextStyle.make, {
                  children: shop.phoneNumber
                })), React.createElement(InfoBlock.make, {
              children: null,
              title: $$Intl.t("ADDRESS")
            }, React.createElement(TextStyle.make, {
                  children: shop.name
                }), React.createElement(TextStyle.make, {
                  children: shop.address
                }), React.createElement(TextStyle.make, {
                  children: shop.postalCode + shop.city
                }), React.createElement(TextStyle.make, {
                  children: shop.country
                })));
  } else {
    tmp$3 = null;
  }
  return React.createElement(Card.make, {
              children: null,
              title: $$Intl.t("Orders information")
            }, React.createElement(React.Fragment, undefined, tmp), tmp$3, React.createElement(Divider.make, {}), editable ? React.createElement(OrderEditForm.InputDate.make, {
                    label: $$Intl.t("Estimated date of reception"),
                    field: /* EstimatedReceptionDate */4
                  }) : React.createElement(React.Fragment, undefined, React.createElement(InlineText.make, {
                        children: null
                      }, React.createElement(TextStyle.make, {
                            children: $$Intl.t("Reception scheduled on") + " ",
                            variation: "normal"
                          }), React.createElement(TextStyle.make, {
                            children: $$Intl.dateTimeFormat("short", undefined, undefined, undefined, undefined, undefined, undefined, undefined, state.values.estimatedReceptionDate)
                          })), receptionFinishedAt !== undefined ? React.createElement(InlineText.make, {
                          children: null
                        }, React.createElement(TextStyle.make, {
                              children: $$Intl.t("Reception completed on") + " ",
                              variation: "normal"
                            }), React.createElement(TextStyle.make, {
                              children: $$Intl.dateTimeFormat("short", undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.valFromOption(receptionFinishedAt))
                            })) : null));
}

var make = React.memo(OrderInformationCard);

export {
  make ,
}
/* make Not a pure module */
