// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Notifier from "../../bundles/Notifier/Notifier.bs.js";
import * as TextIcon from "../../resources/typography/TextIcon.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Touchable from "../../primitives/Touchable.bs.js";
import * as FilePicker from "../../primitives/FilePicker.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as OrderCavavin from "./OrderCavavin.bs.js";
import * as TextIconButton from "../../resources/actions/TextIconButton.bs.js";
import * as OrderCsvImporting from "./OrderCsvImporting.bs.js";
import * as OverlayTriggerView from "../../resources/overlays/OverlayTriggerView.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";

function OrderCartActionsBar$AddProductModalButton(Props) {
  var onRequestModalOpen = Props.onRequestModalOpen;
  var match = Hover.use(undefined, undefined);
  return React.createElement(Touchable.make, Touchable.makeProps(React.createElement(OverlayTriggerView.make, {
                      children: React.createElement(TextStyle.make, {
                            children: $$Intl.t("Add product") + "  ",
                            variation: "normal"
                          }),
                      preset: {
                        NAME: "inputField",
                        VAL: {
                          required: false
                        }
                      },
                      icon: "search",
                      hovered: match[1]
                    }), undefined, undefined, undefined, undefined, undefined, (function (param) {
                    Curry._1(onRequestModalOpen, undefined);
                  }), undefined, match[0], undefined));
}

var AddProductModalButton = {
  make: OrderCartActionsBar$AddProductModalButton
};

function OrderCartActionsBar(Props) {
  var cartProducts = Props.cartProducts;
  var shopId = Props.shopId;
  var supplierId = Props.supplierId;
  var supplierVariantsCount = Props.supplierVariantsCount;
  var importedCsv = Props.importedCsv;
  var onRequestCartRowsError = Props.onRequestCartRowsError;
  var onRequestFileImport = Props.onRequestFileImport;
  var onRequestCartLoading = Props.onRequestCartLoading;
  var onRequestProductPickerModalOpen = Props.onRequestProductPickerModalOpen;
  var onRequestGlobalFeeModalOpen = Props.onRequestGlobalFeeModalOpen;
  var onRequestSupplierProducts = Props.onRequestSupplierProducts;
  var cartDispatch = Props.onRequestDispatch;
  var authState = Auth.useState(undefined);
  var notifier = Notifier.use(undefined, undefined);
  var client = ReasonMLCommunity__ApolloClient.React.useApolloClient(undefined);
  React.useEffect((function () {
          if (importedCsv !== undefined) {
            OrderCsvImporting.make(importedCsv, client, notifier, cartProducts, cartDispatch, onRequestCartLoading);
          }
          
        }), [importedCsv]);
  var disabledSupplierProductsButton = supplierId === "" || supplierVariantsCount > 50;
  var textTooltipSupplierProductsButton = supplierId !== "" && supplierVariantsCount > 50 ? $$Intl.template($$Intl.t("{{supplierVariantsCount}} products are to be imported,\nthe order may be too large (maximum {{supplierVariantsCountLimit}} products).\nPlease use the product picker to select manually the products you need."), {
          supplierVariantsCount: $$Intl.decimalFormat(undefined, undefined, supplierVariantsCount),
          supplierVariantsCountLimit: $$Intl.decimalFormat(undefined, undefined, 50)
        }, undefined) : undefined;
  var tmp;
  if (typeof authState === "number" || authState.TAG === /* Logging */0) {
    tmp = null;
  } else {
    var match = authState._0;
    var match$1 = match.user;
    tmp = Auth.hasCavavinPermission(match$1.username, match$1.organizationName, match.shops) ? React.createElement(TextIconButton.make, TextIconButton.makeProps($$Intl.t("Send to kviste"), undefined, undefined, cartProducts.length === 0, "export_light", undefined, (function (param) {
                  OrderCavavin.checkProductDataKvisteAPI(cartProducts, shopId, notifier, onRequestCartRowsError, onRequestCartLoading);
                }), undefined, undefined, undefined)) : null;
  }
  return React.createElement(Inline.make, {
              children: null,
              space: "xlarge",
              grow: true
            }, React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(OrderCartActionsBar$AddProductModalButton, {
                              onRequestModalOpen: (function (param) {
                                  Curry._1(onRequestProductPickerModalOpen, true);
                                })
                            })), Caml_option.some(Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(100)), undefined, undefined)), undefined, undefined, undefined)), React.createElement(Inline.make, {
                  children: null,
                  space: "medium"
                }, tmp, React.createElement(TextIconButton.make, TextIconButton.makeProps($$Intl.t("Add supplier's products"), undefined, undefined, disabledSupplierProductsButton, "plus_light", textTooltipSupplierProductsButton, (function (param) {
                            Curry._1(onRequestSupplierProducts, undefined);
                          }), undefined, undefined, undefined)), React.createElement(TextIconButton.make, TextIconButton.makeProps($$Intl.t("order_cart.actions_bar.allocation_of_costs.text_button"), undefined, undefined, cartProducts.length <= 0, "plus_light", undefined, (function (param) {
                            Curry._1(onRequestGlobalFeeModalOpen, true);
                          }), undefined, undefined, undefined)), React.createElement(FilePicker.make, {
                      children: React.createElement(TextIcon.make, {
                            children: $$Intl.t("Import csv"),
                            size: "large",
                            noMargin: true,
                            disabled: shopId === "",
                            icon: "import_light"
                          }),
                      types: [
                        "csv",
                        "excel"
                      ],
                      disabled: shopId === "",
                      onChange: Curry.__1(onRequestFileImport),
                      onError: (function (msg) {
                          Curry._3(notifier.reset, {
                                TAG: /* Error */1,
                                _0: msg
                              }, undefined, undefined);
                        }),
                      key: importedCsv
                    })));
}

var make = React.memo(OrderCartActionsBar, (function (oldProps, newProps) {
        if (oldProps.cartProducts === newProps.cartProducts && oldProps.importedCsv === newProps.importedCsv && oldProps.shopId === newProps.shopId && oldProps.supplierId === newProps.supplierId) {
          return oldProps.supplierVariantsCount === newProps.supplierVariantsCount;
        } else {
          return false;
        }
      }));

var supplierVariantsCountLimit = 50;

export {
  supplierVariantsCountLimit ,
  AddProductModalButton ,
  make ,
}
/* make Not a pure module */
