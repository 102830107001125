// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "./Box.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "./Stack.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as Title from "../typography/Title.bs.js";
import * as React from "react";
import * as Inline from "./Inline.bs.js";
import * as Spaces from "../theme/Spaces.bs.js";
import * as TextStyle from "../typography/TextStyle.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GoBackButton from "../navigation/GoBackButton.bs.js";

var styles = $$StyleSheet.create({
      container: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.xxlarge)), undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.large)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      header: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      title: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      content: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function containerStyleFromParams(variation) {
  if (variation === "compact") {
    return Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(20)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(90)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }
}

function Page(Props) {
  var children = Props.children;
  var variationOpt = Props.variation;
  var title = Props.title;
  var subtitle = Props.subtitle;
  var renderTitleEndOpt = Props.renderTitleEnd;
  var renderActions = Props.renderActions;
  var renderHeaderActions = Props.renderHeaderActions;
  var variation = variationOpt !== undefined ? variationOpt : "standard";
  var renderTitleEnd = renderTitleEndOpt !== undefined ? renderTitleEndOpt : (function (param) {
        return null;
      });
  var match = Navigation.useGoBack(undefined);
  return React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(Style.arrayStyle([
                          styles.container,
                          containerStyleFromParams(variation)
                        ])), undefined, undefined, undefined), variation === "standard" && match[0] ? React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(GoBackButton.make, {})), undefined, undefined, undefined, subtitle !== undefined ? "medium" : "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, title !== undefined ? React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.header), undefined, undefined, undefined), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Inline.make, {
                                    children: React.createElement(Stack.make, {
                                          children: null,
                                          space: "xsmall"
                                        }, React.createElement(Inline.make, {
                                              children: null,
                                              space: "medium",
                                              alignY: "bottom",
                                              wrap: true
                                            }, React.createElement(Title.make, {
                                                  children: title,
                                                  level: 2
                                                }), Curry._1(renderTitleEnd, undefined)), subtitle !== undefined ? React.createElement(TextStyle.make, {
                                                children: subtitle,
                                                variation: "normal",
                                                size: "small"
                                              }) : null),
                                    space: "medium"
                                  })), Caml_option.some(styles.title), undefined, undefined, undefined)), renderActions !== undefined ? Curry._1(renderActions, undefined) : null) : null, renderHeaderActions !== undefined ? React.createElement(Box.make, Box.makeProps(Caml_option.some(Curry._1(renderHeaderActions, undefined)), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(View.make, View.makeProps(Caml_option.some(children), Caml_option.some(styles.content), undefined, undefined, undefined)));
}

var make = React.memo(Page);

export {
  make ,
}
/* styles Not a pure module */
