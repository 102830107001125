// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactStately from "react-stately";

var Manager = {};

var $$Selection = {
  Manager: Manager
};

var toArray = (values => [...values]);

var Collection = {
  toArray: toArray
};

var Select = {};

var RadioGroup = {};

function make(value) {
  if (value.TAG !== /* Static */0) {
    return "" + String(value._0.VAL) + "fr";
  }
  var match = value._0;
  if (match.NAME === "pct") {
    return "" + String(match.VAL) + "%";
  } else {
    return match.VAL;
  }
}

var Size = {
  make: make
};

var Resize = {};

var Column = {
  Size: Size,
  Resize: Resize
};

var Header = {};

var Row = {};

var Cell = {};

var Body = {};

var collectionToArray = (values => [...values]);

function useColumnResizeState(prim0, prim1) {
  return ReactStately.useTableColumnResizeState(prim0, prim1);
}

var Table = {
  Column: Column,
  Header: Header,
  Row: Row,
  Cell: Cell,
  Body: Body,
  collectionToArray: collectionToArray,
  useColumnResizeState: useColumnResizeState
};

var DateSegment = {};

var TimeField = {};

var NumberField = {};

var Trigger = {};

var Tooltip = {
  Trigger: Trigger
};

var Toggle = {};

var ComboBox = {};

var Item = {};

var Section = {};

var ListBox = {
  Item: Item,
  Section: Section
};

var Trigger$1 = {};

var Overlay = {
  Trigger: Trigger$1
};

export {
  $$Selection ,
  Collection ,
  Select ,
  RadioGroup ,
  Table ,
  DateSegment ,
  TimeField ,
  NumberField ,
  Tooltip ,
  Toggle ,
  ComboBox ,
  ListBox ,
  Overlay ,
}
/* react-stately Not a pure module */
