// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "./Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Stack from "./Stack.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as Title from "../typography/Title.bs.js";
import * as React from "react";
import * as Spinner from "../feedback-indicators/Spinner.bs.js";
import * as TextStyle from "../typography/TextStyle.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Illustration from "../images-and-icons/Illustration.bs.js";

var styles = $$StyleSheet.create({
      container: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", "center", undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(-1.5)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(10)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(8)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

var make = React.memo(function (Props) {
      var illustration = Props.illustration;
      var title = Props.title;
      var text = Props.text;
      var children = Props.children;
      return React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.container), undefined, undefined, undefined), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Illustration.make, {
                                  element: illustration
                                })), undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Stack.make, {
                      children: null
                    }, React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Title.make, {
                                      children: title,
                                      level: 3,
                                      align: "center"
                                    })), undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), text !== undefined ? React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                        children: text,
                                        align: "center",
                                        variation: "normal"
                                      })), "xhuge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, children !== undefined ? React.createElement(Box.make, Box.makeProps(Caml_option.some(Caml_option.valFromOption(children)), "xhuge", "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null));
    });

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5) {
  var tmp = {
    illustration: prim0,
    title: prim1
  };
  if (prim2 !== undefined) {
    tmp.text = Caml_option.valFromOption(prim2);
  }
  if (prim3 !== undefined) {
    tmp.children = Caml_option.valFromOption(prim3);
  }
  if (prim4 !== undefined) {
    tmp.key = prim4;
  }
  return tmp;
}

var error = React.createElement(make, makeProps(Illustration.error, $$Intl.t("Loading issue."), $$Intl.t("Please try refreshing the page."), undefined, undefined, undefined));

var loading = React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.container), undefined, undefined, undefined), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Spinner.make, {
                      size: 38
                    })), undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Stack.make, {
          children: null
        }, React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Title.make, {
                          children: $$Intl.t("Loading..."),
                          level: 3,
                          align: "center"
                        })), undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                          children: $$Intl.t("Please wait."),
                          align: "center",
                          variation: "normal"
                        })), "xhuge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))));

export {
  make ,
  error ,
  loading ,
}
/* styles Not a pure module */
