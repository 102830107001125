// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../resources/layout-and-structure/Box.bs.js";
import * as Svg from "../primitives/Svg.bs.js";
import * as Icon from "../resources/images-and-icons/Icon.bs.js";
import * as $$Intl from "../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "../primitives/Hover.bs.js";
import * as Title from "../resources/typography/Title.bs.js";
import * as React from "react";
import * as Inline from "../resources/layout-and-structure/Inline.bs.js";
import * as ReactAria from "../externals/ReactAria.bs.js";
import * as Separator from "../resources/layout-and-structure/Separator.bs.js";
import * as TableView from "../resources/tables/TableView.bs.js";
import * as TextInput from "../primitives/TextInput.bs.js";
import * as TextStyle from "../resources/typography/TextStyle.bs.js";
import * as Touchable from "../primitives/Touchable.bs.js";
import * as CountBadge from "../resources/feedback-indicators/CountBadge.bs.js";
import * as IconButton from "../resources/actions/IconButton.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SliderTrack from "../resources/layout-and-structure/SliderTrack.bs.js";
import * as ResourceList from "./ResourceList.bs.js";
import * as TextFieldStyle from "../resources/selection-and-input/TextFieldStyle.bs.js";
import * as TextIconButton from "../resources/actions/TextIconButton.bs.js";
import * as ReactDomElement from "../primitives/ReactDomElement.bs.js";
import * as Stylex from "@stylexjs/stylex";
import * as ReactUpdateEffect from "../primitives/ReactUpdateEffect.bs.js";
import * as ReactUpdateDebounced from "../primitives/ReactUpdateDebounced.bs.js";
import * as ResourceListPagination from "./ResourceListPagination.bs.js";

var styles = Stylex.create({
      FiltersBarLayout_root: {
        boxSizing: "border-box",
        borderBottom: "1px solid #e7e7ee",
        transition: "height .2s cubic-bezier(.4, 0, .2, 1), opacity .15s cubic-bezier(.4, 0, .2, 1)",
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden",
        padding: "0 20px"
      }
    });

function styleProps(opened) {
  return Stylex.props(styles.FiltersBarLayout_root, {
              opacity: opened ? 1 : 0,
              height: opened ? "60px" : "0px"
            });
}

function ResourceListPage$ResourceListFiltersBarLayout(Props) {
  var children = Props.filters;
  var opened = Props.opened;
  var onRequestToggle = Props.onRequestToggle;
  var onRequestReset = Props.onRequestReset;
  var match = styleProps(opened);
  var tmp = {};
  if (match.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match.className);
  }
  if (match.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match.style);
  }
  return React.createElement(ReactAria.Spread.make, {
              props: !opened ? ({
                    inert: "true"
                  }) : undefined,
              children: React.createElement("div", tmp, React.createElement(SliderTrack.make, {
                        children: children,
                        compact: true,
                        gap: "small",
                        layout: /* Adaptative */0
                      }), React.createElement(Inline.make, {
                        children: null
                      }, React.createElement(Separator.make, {
                            space: "xnormal"
                          }), onRequestReset !== undefined ? React.createElement(TextIconButton.make, TextIconButton.makeProps($$Intl.t("Erase"), "normal", "xsmall", undefined, "reset", undefined, (function (param) {
                                    Curry._1(onRequestReset, undefined);
                                  }), undefined, undefined, undefined)) : React.createElement(TextIconButton.make, TextIconButton.makeProps($$Intl.t("Close"), "normal", "xsmall", undefined, "close_light", undefined, (function (param) {
                                    Curry._1(onRequestToggle, undefined);
                                  }), undefined, undefined, undefined))))
            });
}

var styles$1 = Stylex.create({
      SearchBar_root: {
        boxSizing: "border-box",
        border: "1px solid",
        transition: "width .2s cubic-bezier(.6, 0.7, .5, 1)",
        alignItems: "center",
        columnGap: "8px",
        display: "flex",
        flexDirection: "row",
        height: "40px",
        overflow: "hidden",
        padding: "0 12px",
        width: "100%"
      },
      SearchBar_searchIconWrapper: {
        alignItems: "center",
        display: "flex",
        height: "40px",
        justifyContent: "center",
        left: "-12.5px",
        position: "absolute",
        top: "-20px",
        width: "40px",
        zIndex: 3
      },
      SearchBar_searchIconSvg: {
        positionArea: "left",
        position: "absolute"
      },
      SearchBar_textInput: {
        textOverflow: "ellipsis",
        cursor: "text",
        alignContent: "center",
        flex: "1",
        height: "35px",
        maxWidth: "150px",
        minWidth: "50%",
        overflow: "hidden",
        width: "150px"
      }
    });

function styleProps$1(focused, active, hovered) {
  return Stylex.props(styles$1.SearchBar_root, {
              backgroundColor: TextFieldStyle.backgroundColor(false),
              borderColor: TextFieldStyle.borderColor(focused, hovered, false, false),
              borderRadius: TextFieldStyle.borderRadiusPx,
              cursor: active ? "auto" : "pointer",
              width: active ? "100%" : "40px"
            });
}

function textInputStyleProps(active) {
  return Stylex.props(styles$1.SearchBar_textInput, {
              color: TextFieldStyle.color(false),
              fontSize: TextFieldStyle.fontSizePx,
              opacity: active ? 1 : 0,
              transition: "opacity .1s ease",
              display: "flex"
            });
}

var textInputDOMStyle = {
  marginLeft: "16px",
  width: "0",
  flex: "1"
};

var make = React.memo(function (Props) {
      var placeholder = Props.placeholder;
      var valueOpt = Props.value;
      var onChange = Props.onChange;
      var value = valueOpt !== undefined ? valueOpt : "";
      var inputRef = React.useRef(null);
      var match = Hover.use(undefined, undefined);
      var hovered = match[1];
      var match$1 = React.useState(function () {
            return false;
          });
      var setFocused = match$1[1];
      var focused = match$1[0];
      var match$2 = React.useState(function () {
            return value;
          });
      var setSearchQuery = match$2[1];
      var searchQuery = match$2[0];
      var debouncedSearchQuery = ReactUpdateDebounced.use(searchQuery, 500);
      ReactUpdateEffect.use1((function (param) {
              Curry._1(onChange, debouncedSearchQuery);
            }), [debouncedSearchQuery]);
      ReactUpdateEffect.use1((function (param) {
              Curry._1(setSearchQuery, (function (param) {
                      return value;
                    }));
            }), [value]);
      var focusInput = function (param) {
        var input = inputRef.current;
        if (!(input == null)) {
          return ReactDomElement.focus(input);
        }
        
      };
      var onKeyDown = React.useCallback((function ($$event) {
              var key = $$event.key;
              var inputs = document.querySelectorAll("input");
              var tmp;
              if (inputs == null) {
                tmp = true;
              } else {
                var len = inputs.length;
                if (len !== 1) {
                  tmp = len === 0;
                } else {
                  var searchInput = inputs[0];
                  tmp = inputRef.current === searchInput;
                }
              }
              var allowFocusInput = tmp && (/^[a-zA-Z0-9]$/.test(key) || key === "Backspace") && !$$event.ctrlKey;
              if (allowFocusInput) {
                return focusInput(undefined);
              } else if (key === "Escape" && focused) {
                Curry._1(setSearchQuery, (function (param) {
                        return "";
                      }));
                var input = inputRef.current;
                if (!(input == null)) {
                  return ReactDomElement.blur(input);
                } else {
                  return ;
                }
              } else {
                return ;
              }
            }), [focused]);
      React.useEffect((function () {
              var $$document$1 = document;
              $$document$1.addEventListener("keydown", onKeyDown);
              return (function (param) {
                        $$document$1.removeEventListener("keydown", onKeyDown);
                      });
            }), [onKeyDown]);
      var active = focused || searchQuery !== "";
      var match$3 = styleProps$1(focused, active, hovered);
      var match$4 = Stylex.props(styles$1.SearchBar_searchIconWrapper);
      var match$5 = Stylex.props(styles$1.SearchBar_searchIconSvg);
      var match$6 = textInputStyleProps(active);
      var buttonProps_elementType = "div";
      var buttonProps_onPressStart = (function (param) {
          focusInput(undefined);
        });
      var buttonProps = {
        elementType: buttonProps_elementType,
        onPressStart: buttonProps_onPressStart
      };
      var tmp = {
        ref: match[0]
      };
      if (match$3.className !== undefined) {
        tmp.className = Caml_option.valFromOption(match$3.className);
      }
      if (match$3.style !== undefined) {
        tmp.style = Caml_option.valFromOption(match$3.style);
      }
      var tmp$1 = {};
      if (match$4.className !== undefined) {
        tmp$1.className = Caml_option.valFromOption(match$4.className);
      }
      if (match$4.style !== undefined) {
        tmp$1.style = Caml_option.valFromOption(match$4.style);
      }
      var tmp$2 = {
        children: React.createElement(Svg.Path.make, {
              d: "M9.5 2.5a7 7 0 015.292 11.583l3.062 3.064a.5.5 0 01-.708.706l-3.061-3.063A7 7 0 119.5 2.5zm0 1a6 6 0 100 12 6 6 0 000-12z",
              fill: hovered && !active ? "#25243a" : "#797885"
            }),
        width: "17.5",
        height: "17.5",
        viewBox: "0 0 20 20"
      };
      if (match$5.style !== undefined) {
        tmp$2.style = Caml_option.valFromOption(match$5.style);
      }
      if (match$5.className !== undefined) {
        tmp$2.className = match$5.className;
      }
      var tmp$3 = {};
      if (match$6.className !== undefined) {
        tmp$3.className = Caml_option.valFromOption(match$6.className);
      }
      if (match$6.style !== undefined) {
        tmp$3.style = Caml_option.valFromOption(match$6.style);
      }
      return React.createElement("div", tmp, React.createElement(Touchable.make, Touchable.makeProps(React.createElement("div", tmp$1, React.createElement(Svg.make, tmp$2)), buttonProps, active, true, undefined, undefined, (function (param) {
                            
                          }), undefined, undefined, undefined)), React.createElement("div", tmp$3, React.createElement(TextInput.make, {
                          inputRef: inputRef,
                          placeholder: placeholder,
                          style: textInputDOMStyle,
                          value: searchQuery,
                          onChange: (function (value) {
                              Curry._1(setSearchQuery, (function (param) {
                                      return value;
                                    }));
                            }),
                          onFocus: (function (param) {
                              Curry._1(setFocused, (function (param) {
                                      return true;
                                    }));
                            }),
                          onBlur: (function (param) {
                              Curry._1(setFocused, (function (param) {
                                      return false;
                                    }));
                            })
                        })), React.createElement(IconButton.make, IconButton.makeProps("close_medium", 17, undefined, "#bdbdca", "#797885", undefined, undefined, undefined, undefined, (function (param) {
                            Curry._1(setSearchQuery, (function (param) {
                                    return "";
                                  }));
                          }), undefined, undefined, undefined)));
    });

var styles$2 = Stylex.create({
      HeaderFiltersBarTrigger_root: {
        backgroundColor: "#fefefe",
        boxSizing: "border-box",
        border: "1px solid #d7d7e0",
        borderRadius: "5px",
        alignItems: "center",
        columnGap: "10px",
        display: "flex",
        height: "40px",
        paddingHorizontal: "12px",
        ":hover": {
          borderColor: "#bdbdca"
        }
      },
      HeaderFiltersBarTrigger_focused: {
        borderColor: "#a2a1b0",
        ":hover": {
          borderColor: "#797885"
        }
      },
      HeaderFiltersBarTrigger_active: {
        backgroundColor: "#f3f3f7",
        borderColor: "#797885",
        ":hover": {
          borderColor: "#25243a"
        }
      },
      HeaderFiltersBarTrigger_pressed: {
        backgroundColor: "#f8f8fb",
        borderColor: "#a2a1b0"
      }
    });

function styleProps$2(active, focused, pressed) {
  return Stylex.props(styles$2.HeaderFiltersBarTrigger_root, active ? styles$2.HeaderFiltersBarTrigger_active : ({}), focused ? styles$2.HeaderFiltersBarTrigger_focused : ({}), pressed && !active ? styles$2.HeaderFiltersBarTrigger_pressed : ({}));
}

function ResourceListPage$ResourceListHeader$HeaderFiltersBarTrigger(Props) {
  var filtersBarOpened = Props.filtersBarOpened;
  var activeFiltersCount = Props.activeFiltersCount;
  var onRequestToggle = Props.onRequestToggle;
  var match = Hover.use(undefined, undefined);
  var match$1 = React.useState(function () {
        return false;
      });
  var setPressed = match$1[1];
  var buttonProps_elementType = "div";
  var buttonProps_onPressStart = (function (param) {
      Curry._1(setPressed, (function (param) {
              return true;
            }));
    });
  var buttonProps_onPressEnd = (function (param) {
      Curry._1(setPressed, (function (param) {
              return false;
            }));
    });
  var buttonProps = {
    elementType: buttonProps_elementType,
    onPressStart: buttonProps_onPressStart,
    onPressEnd: buttonProps_onPressEnd
  };
  var active = activeFiltersCount > 0;
  var match$2 = styleProps$2(active, filtersBarOpened, match$1[0]);
  var iconFill = match[1] ? "#25243a" : "#797885";
  var handleToggle = function (param) {
    window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
    if (!filtersBarOpened || filtersBarOpened && window.scrollY < 15) {
      return Curry._1(onRequestToggle, undefined);
    }
    
  };
  var tmp = {};
  if (match$2.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match$2.className);
  }
  if (match$2.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match$2.style);
  }
  return React.createElement(Touchable.make, Touchable.makeProps(React.createElement("div", tmp, React.createElement(Icon.make, {
                          name: "filter",
                          fill: iconFill,
                          size: 15
                        }), React.createElement(Inline.make, {
                          children: null,
                          space: "small"
                        }, React.createElement(TextStyle.make, {
                              children: $$Intl.t("Filters"),
                              variation: active ? "neutral" : "normal"
                            }), active ? React.createElement(CountBadge.make, {
                                children: String(activeFiltersCount),
                                size: "small"
                              }) : null)), buttonProps, undefined, undefined, undefined, undefined, handleToggle, undefined, match[0], undefined));
}

var styles$3 = Stylex.create({
      Header_root: {
        background: "#fefefe",
        boxSizing: "border-box",
        borderBottom: "1px solid #e7e7ee",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        height: "60px",
        padding: "0 20px",
        position: "sticky",
        top: "0",
        zIndex: 3
      }
    });

function ResourceListPage$ResourceListHeader(Props) {
  var title = Props.title;
  var shopFilter = Props.shopFilter;
  var filters = Props.filters;
  var activeFiltersCount = Props.activeFiltersCount;
  var mainAction = Props.mainAction;
  var actions = Props.actions;
  var searchBar = Props.searchBar;
  var onRequestResetFilters = Props.onRequestResetFilters;
  var match = React.useState(function () {
        return activeFiltersCount > 0;
      });
  var setFiltersBarOpened = match[1];
  var filtersBarOpened = match[0];
  var handleFiltersBar = function (param) {
    Curry._1(setFiltersBarOpened, (function (opened) {
            return !opened;
          }));
  };
  var match$1 = Stylex.props(styles$3.Header_root);
  var tmp = {};
  if (match$1.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match$1.className);
  }
  if (match$1.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match$1.style);
  }
  var tmp$1 = {
    filters: filters,
    opened: filtersBarOpened,
    onRequestToggle: handleFiltersBar
  };
  if (onRequestResetFilters !== undefined) {
    tmp$1.onRequestReset = Caml_option.valFromOption(onRequestResetFilters);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", tmp, React.createElement(Title.make, {
                      children: title,
                      level: 2
                    }), React.createElement(Inline.make, {
                      children: null,
                      align: "spaceBetween",
                      grow: true
                    }, React.createElement(Inline.make, {
                          children: null,
                          space: "small",
                          align: "spaceBetween",
                          grow: true
                        }, mainAction, mainAction !== null ? React.createElement(Separator.make, {
                                size: "large"
                              }) : null, shopFilter, React.createElement(ResourceListPage$ResourceListHeader$HeaderFiltersBarTrigger, {
                              filtersBarOpened: filtersBarOpened,
                              activeFiltersCount: activeFiltersCount,
                              onRequestToggle: handleFiltersBar
                            }), searchBar, React.createElement(Box.make, Box.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined))), actions)), React.createElement(ResourceListPage$ResourceListFiltersBarLayout, tmp$1));
}

var styles$4 = Stylex.create({
      root: {
        background: "#fefefe",
        borderLeft: "1px solid #e7e7ee",
        display: "flex",
        flex: "1",
        flexDirection: "column",
        height: "100%",
        zIndex: 1
      }
    });

function ResourceListPage$ResourceListLayout(Props) {
  var children = Props.children;
  var match = Stylex.props(styles$4.root);
  var tmp = {};
  if (match.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match.className);
  }
  if (match.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match.style);
  }
  return React.createElement("div", tmp, children);
}

var make$1 = React.memo(ResourceListPage$ResourceListLayout);

function ResourceListPage(Props) {
  var title = Props.title;
  var shopFilterOpt = Props.shopFilter;
  var filtersOpt = Props.filters;
  var activeFiltersCountOpt = Props.activeFiltersCount;
  var mainActionOpt = Props.mainAction;
  var actionsOpt = Props.actions;
  var searchBarOpt = Props.searchBar;
  var tableData = Props.tableData;
  var tableColumns = Props.tableColumns;
  var tableKeyExtractor = Props.tableKeyExtractor;
  var tableEmptyState = Props.tableEmptyState;
  var paginationCurrentPage = Props.paginationCurrentPage;
  var paginationTotalCount = Props.paginationTotalCount;
  var paginationTotalPages = Props.paginationTotalPages;
  var paginationLoading = Props.paginationLoading;
  var paginationLastDirection = Props.paginationLastDirection;
  var tableSortDescriptor = Props.tableSortDescriptor;
  var onTableSortChange = Props.onTableSortChange;
  var onRequestPaginate = Props.onRequestPaginate;
  var onRequestResetFilters = Props.onRequestResetFilters;
  var shopFilter = shopFilterOpt !== undefined ? Caml_option.valFromOption(shopFilterOpt) : null;
  var filters = filtersOpt !== undefined ? filtersOpt : [];
  var activeFiltersCount = activeFiltersCountOpt !== undefined ? activeFiltersCountOpt : 0;
  var mainAction = mainActionOpt !== undefined ? Caml_option.valFromOption(mainActionOpt) : null;
  var actions = actionsOpt !== undefined ? Caml_option.valFromOption(actionsOpt) : null;
  var searchBar = searchBarOpt !== undefined ? Caml_option.valFromOption(searchBarOpt) : null;
  var tmp = {
    title: title,
    shopFilter: shopFilter,
    filters: filters,
    activeFiltersCount: activeFiltersCount,
    mainAction: mainAction,
    actions: actions,
    searchBar: searchBar
  };
  if (onRequestResetFilters !== undefined) {
    tmp.onRequestResetFilters = Caml_option.valFromOption(onRequestResetFilters);
  }
  var tmp$1 = {
    data: tableData,
    columns: tableColumns,
    keyExtractor: tableKeyExtractor,
    hideCard: true,
    hideReloadingPlaceholder: true,
    compactRows: true,
    placeholderEmptyState: tableEmptyState
  };
  if (tableSortDescriptor !== undefined) {
    tmp$1.sortDescriptor = Caml_option.valFromOption(tableSortDescriptor);
  }
  if (onTableSortChange !== undefined) {
    tmp$1.onSortChange = Caml_option.valFromOption(onTableSortChange);
  }
  return React.createElement(make$1, {
              children: null
            }, React.createElement(ResourceListPage$ResourceListHeader, tmp), React.createElement("div", {
                  style: {
                    display: "flex",
                    paddingTop: "10px",
                    flex: "1"
                  }
                }, React.createElement(TableView.make, tmp$1)), React.createElement(ResourceListPagination.make, {
                  currentPage: paginationCurrentPage,
                  totalPages: paginationTotalPages,
                  totalCount: paginationTotalCount,
                  loading: paginationLoading,
                  lastDirection: paginationLastDirection,
                  itemsPerPage: ResourceList.defaultEdgesPerPage,
                  onRequestPaginate: onRequestPaginate
                }));
}

var ResourceListSearchBar = {
  make: make
};

var make$2 = ResourceListPage;

export {
  ResourceListSearchBar ,
  make$2 as make,
}
/* styles Not a pure module */
