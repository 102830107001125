// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as React from "react";
import * as Colors from "../theme/Colors.bs.js";
import * as ReactAria from "../../externals/ReactAria.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactAria$1 from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactStately from "react-stately";
import * as ReactUpdateEffect from "../../primitives/ReactUpdateEffect.bs.js";
import * as OverlayTriggerView from "../overlays/OverlayTriggerView.bs.js";
import * as $$Date from "@internationalized/date";

function makeStyle(focused, editable, placeholder) {
  var color = focused ? "#a01e72" : (
      placeholder ? Colors.placeholderTextColor : "#25243a"
    );
  return {
          backgroundColor: focused ? "#f4e4ee" : "inherit",
          color: color,
          minWidth: editable ? "2ch" : "default",
          padding: editable ? "4px 1px" : "3px 1px",
          textAlign: "center",
          borderRadius: "3px",
          caretColor: "transparent"
        };
}

var make = React.memo(function (Props) {
      var value = Props.value;
      var state = Props.state;
      var match = React.useState(function () {
            return false;
          });
      var setFocused = match[1];
      var domRef = React.useRef(null);
      var match$1 = ReactAria$1.useDateSegment(value, state, domRef);
      var props = ReactAria$1.mergeProps(match$1.segmentProps, {
            style: makeStyle(match[0], value.isEditable && value.type !== "literal", value.isPlaceholder),
            onFocus: (function (param) {
                Curry._1(setFocused, (function (param) {
                        return true;
                      }));
              }),
            onBlur: (function (param) {
                Curry._1(setFocused, (function (param) {
                        return false;
                      }));
              })
          });
      return React.createElement(ReactAria.Spread.make, {
                  props: props,
                  children: React.createElement("div", {
                        ref: domRef
                      }, value.text)
                });
    });

function internationalizationDatetimeFromJsDateObj(jsDate) {
  return new $$Date.Time(jsDate.getHours(), jsDate.getMinutes());
}

function internationalizationDatetimeToJsDateObj(time) {
  var today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), today.getDate(), time.hour, time.minute);
}

var style = {
  cursor: "text",
  display: "flex"
};

function InputTimeField(Props) {
  var label = Props.label;
  var requiredOpt = Props.required;
  var errorMessage = Props.errorMessage;
  var initialValue = Props.value;
  var onChange = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var testLocalization = Props.testLocalization;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var match = React.useState(function () {
        return Belt_Option.map(initialValue, internationalizationDatetimeFromJsDateObj);
      });
  var setValue = match[1];
  var value = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setFocused = match$1[1];
  var focused = match$1[0];
  var match$2 = Hover.use(undefined, undefined);
  var ref = match$2[0];
  var onFocusChange = React.useCallback((function (value) {
          Curry._1(setFocused, (function (param) {
                  return value;
                }));
        }), []);
  var props_locale = Belt_Option.getWithDefault(testLocalization, $$Intl.locale);
  var props_aria$label = Belt_Option.getWithDefault(label, $$Intl.t("time"));
  var props_onChange = function (value) {
    var value$1 = (value == null) ? undefined : Caml_option.some(value);
    Curry._1(setValue, (function (param) {
            return value$1;
          }));
    if (!(value == null)) {
      return Curry._1(onChange, internationalizationDatetimeToJsDateObj(value));
    }
    
  };
  var props = {
    locale: props_locale,
    shouldForceLeadingZeros: true,
    "aria-label": props_aria$label,
    value: value,
    onChange: props_onChange,
    onFocusChange: onFocusChange,
    onFocus: onFocus,
    onBlur: onBlur
  };
  var state = ReactStately.useTimeFieldState(props);
  var match$3 = ReactAria$1.useTimeField(props, state, ref);
  ReactUpdateEffect.use1((function (param) {
          if (!focused && Belt_Option.isNone(value)) {
            state.clearSegment("hour");
            state.clearSegment("minute");
          }
          
        }), [focused]);
  var tmp = {
    children: React.createElement(ReactAria.Spread.make, {
          props: match$3.fieldProps,
          children: React.createElement("div", {
                style: style
              }, Belt_Array.mapWithIndex(state.segments, (function (index, segment) {
                      return React.createElement(make, {
                                  value: segment,
                                  state: state,
                                  key: String(index)
                                });
                    })))
        }),
    preset: {
      NAME: "inputField",
      VAL: {
        required: required,
        errorMessage: errorMessage
      }
    },
    growContent: true,
    hideIcon: true,
    hovered: match$2[1],
    focused: focused
  };
  if (label !== undefined) {
    tmp.label = label;
  }
  return React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(OverlayTriggerView.make, tmp)), undefined, undefined, ref, undefined));
}

var make$1 = React.memo(InputTimeField);

export {
  internationalizationDatetimeFromJsDateObj ,
  internationalizationDatetimeToJsDateObj ,
  make$1 as make,
}
/* make Not a pure module */
