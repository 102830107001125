// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Svg from "../../primitives/Svg.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Price from "../Price/Price.bs.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Offset from "../../resources/layout-and-structure/Offset.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var coefficientIcon = React.createElement(Svg.make, {
      children: React.createElement(Svg.Path.make, {
            d: "M2.39354 9.182C2.27638 9.29916 2.27638 9.48911 2.39354 9.60626C2.5107 9.72342 2.70065 9.72342 2.8178 9.60626L5.99978 6.42429L9.18176 9.60627C9.29891 9.72342 9.48886 9.72342 9.60602 9.60627C9.72318 9.48911 9.72318 9.29916 9.60602 9.182L6.42404 6.00003L9.60603 2.81804C9.72319 2.70088 9.72319 2.51093 9.60603 2.39378C9.48887 2.27662 9.29892 2.27662 9.18177 2.39378L5.99978 5.57576L2.8178 2.39378C2.70064 2.27662 2.51069 2.27662 2.39353 2.39378C2.27637 2.51093 2.27637 2.70088 2.39353 2.81804L5.57552 6.00003L2.39354 9.182Z",
            fill: "#767589"
          }),
      width: "12",
      height: "12",
      viewBox: "0 0 12 12"
    });

function CatalogPriceDifferentialIndicatorCell(Props) {
  var value = Props.value;
  var purchasePrice = Props.purchasePrice;
  var retailPrice = Props.retailPrice;
  var retailPrice$1 = {
    TAG: /* PriceExclTax */0,
    _0: retailPrice
  };
  var rate = Price.makeRate(value, retailPrice$1, purchasePrice);
  var formattedRate = Belt_Option.map(rate, (function (rate) {
          return $$Intl.decimalFormat(2, 2, rate) + (
                  value === /* Coefficient */0 ? "" : " %"
                );
        }));
  if (value !== 0) {
    if (formattedRate !== undefined) {
      return React.createElement(TextStyle.make, {
                  children: formattedRate,
                  size: "xsmall"
                });
    }
    
  } else if (formattedRate !== undefined) {
    return React.createElement(Inline.make, {
                children: null,
                space: "xsmall"
              }, Belt_Option.isSome(rate) ? React.createElement(Offset.make, {
                      children: coefficientIcon,
                      top: -2.5,
                      width: 12,
                      height: 12
                    }) : null, React.createElement(TextStyle.make, {
                    children: formattedRate,
                    size: "xsmall"
                  }));
  }
  return React.createElement(TextStyle.make, {
              children: $$Intl.t("N/C"),
              variation: "subdued",
              size: "xsmall"
            });
}

var make = React.memo(CatalogPriceDifferentialIndicatorCell);

export {
  coefficientIcon ,
  make ,
}
/* coefficientIcon Not a pure module */
