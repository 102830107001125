// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Modal from "../../resources/overlays/Modal.bs.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as OrderMutations from "./OrderMutations.bs.js";

function OrderArchiveModal(Props) {
  var orderId = Props.orderId;
  var opened = Props.opened;
  var onRequestClose = Props.onRequestClose;
  var commitCallbackUrl = Props.commitCallbackUrl;
  var navigate = Navigation.useNavigate(undefined);
  var archiveOrder = OrderMutations.useArchive(orderId);
  return React.createElement(Modal.make, {
              children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                children: $$Intl.t("Once the order is archived, it will only be visible in the order list by applying the \"Archive\" filter."),
                                variation: "normal"
                              })), "xlarge", "xxlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
              opened: opened,
              title: $$Intl.t("Confirm the archiving of this order"),
              abortButtonText: $$Intl.t("Cancel"),
              commitButtonText: $$Intl.t("Confirm"),
              commitButtonCallback: (function (param) {
                  Future.get(Curry._1(archiveOrder, undefined), (function (result) {
                          if (result.TAG === /* Ok */0 && commitCallbackUrl !== undefined) {
                            return Curry._3(navigate, undefined, undefined, commitCallbackUrl);
                          }
                          
                        }));
                }),
              onRequestClose: onRequestClose
            });
}

var make = React.memo(OrderArchiveModal, (function (oldProps, newProps) {
        if (oldProps.orderId === newProps.orderId) {
          return oldProps.opened === newProps.opened;
        } else {
          return false;
        }
      }));

export {
  make ,
}
/* make Not a pure module */
