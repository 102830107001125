// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Json from "../../primitives/Json.bs.js";
import * as Uuid from "../../externals/Uuid.bs.js";
import * as Sheet from "../../primitives/Sheet.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var fileTypes = [
  "csv",
  "spreadsheetml"
];

function decodeRowVariantId(json) {
  if (json === Json.encodedNull || json === Json.encodeString("")) {
    return {
            TAG: /* Error */1,
            _0: /* Empty */0
          };
  }
  var option = Belt_Option.map(Belt_Option.flatMap(Json.decodeString(json), Uuid.fromString), (function (prim) {
          return prim;
        }));
  if (option !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: option
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: /* Unkown */1
          };
  }
}

function decodeRowStock(json) {
  var number = Belt_Option.orElse(Belt_Option.map(Belt_Option.map(Json.decodeString(json), (function (string) {
                  return Js_string.replace(",", ".", string);
                })), (function (prim) {
              return Number(prim);
            })), Json.decodeNumber(json));
  if (number !== undefined) {
    if (Number.isNaN(number)) {
      return {
              TAG: /* Error */1,
              _0: /* NotNumber */2
            };
    } else if (Number.isFinite(number)) {
      if (number < 0) {
        return {
                TAG: /* Error */1,
                _0: /* Negative */1
              };
      } else if (number === 0 && json === Json.encodeString("")) {
        return {
                TAG: /* Error */1,
                _0: /* Empty */0
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: number
              };
      }
    } else {
      return {
              TAG: /* Error */1,
              _0: /* NonFinite */3
            };
    }
  } else if (json === Json.encodedNull) {
    return {
            TAG: /* Error */1,
            _0: /* Empty */0
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: /* Unknown */4
          };
  }
}

var dictVariantIdKey = $$Intl.t("ID");

var dictStockKey = $$Intl.t("Inventory quantity");

function decodeRowValues(jsonVariantId, jsonStock, index) {
  var variantId = decodeRowVariantId(jsonVariantId);
  var stock = decodeRowStock(jsonStock);
  if (variantId.TAG !== /* Ok */0) {
    if (variantId._0) {
      return {
              TAG: /* Error */1,
              _0: {
                TAG: /* CannotDecodeCellVariantId */2,
                index: index,
                json: jsonVariantId
              }
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: {
                TAG: /* EmptyCellVariantId */6,
                index: index
              }
            };
    }
  }
  if (stock.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              variantId: variantId._0,
              stock: stock._0
            }
          };
  }
  var match = stock._0;
  if (match !== 1) {
    if (match !== 0) {
      return {
              TAG: /* Error */1,
              _0: {
                TAG: /* CannotDecodeCellStock */3,
                index: index,
                json: jsonStock
              }
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: {
                TAG: /* EmptyCellStock */5,
                index: index
              }
            };
    }
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              TAG: /* NegativeCellStock */4,
              index: index,
              json: jsonStock
            }
          };
  }
}

function decodeDictRowWithIndex(index, dictRow) {
  var match = Js_dict.get(dictRow, dictVariantIdKey);
  var match$1 = Js_dict.get(dictRow, dictStockKey);
  if (match !== undefined && match$1 !== undefined) {
    return decodeRowValues(Caml_option.valFromOption(match), Caml_option.valFromOption(match$1), index);
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              TAG: /* CannotDecodeHeaderRow */0,
              index: index,
              json: Json.encodeDict(dictRow)
            }
          };
  }
}

function decodeTupleRowWithIndex(index, tupleRow) {
  var match = Belt_Array.get(tupleRow, 0);
  var match$1 = Belt_Array.get(tupleRow, 1);
  if (match !== undefined && match$1 !== undefined) {
    return decodeRowValues(Caml_option.valFromOption(match), Caml_option.valFromOption(match$1), index);
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              TAG: /* CannotDecodeRow */1,
              index: index,
              json: Json.encodeArray(tupleRow)
            }
          };
  }
}

function decodeRows(headerIndexOffsetOpt, rows) {
  var headerIndexOffset = headerIndexOffsetOpt !== undefined ? headerIndexOffsetOpt : 0;
  var decodedRows = Belt_Array.mapWithIndex(rows, (function (index, jsonRow) {
          var match = Json.decodeArray(jsonRow);
          var match$1 = Json.decodeDict(jsonRow);
          if (match !== undefined) {
            return decodeTupleRowWithIndex(index, match);
          } else if (match$1 !== undefined) {
            return decodeDictRowWithIndex(index + headerIndexOffset | 0, Caml_option.valFromOption(match$1));
          } else {
            return {
                    TAG: /* Error */1,
                    _0: {
                      TAG: /* CannotDecodeRow */1,
                      index: index + headerIndexOffset | 0,
                      json: jsonRow
                    }
                  };
          }
        }));
  var okRowsCount = Belt_Array.keepMap(decodedRows, (function (row) {
          if (row.TAG === /* Ok */0) {
            return row._0;
          }
          
        })).length;
  var errorRowsCount = Belt_Array.keepMap(decodedRows, (function (row) {
          if (row.TAG === /* Ok */0) {
            return ;
          } else {
            return Caml_option.some(undefined);
          }
        })).length;
  if (errorRowsCount === 0 && okRowsCount === 0) {
    return {
            TAG: /* Error */1,
            _0: /* NoRows */1
          };
  }
  if (!(errorRowsCount > 0 && okRowsCount === 0)) {
    return {
            TAG: /* Ok */0,
            _0: decodedRows
          };
  }
  var errorCannotDecodeHeaderRowCount = Belt_Array.keepMap(decodedRows, (function (row) {
          if (row.TAG === /* Ok */0 || row._0.TAG !== /* CannotDecodeHeaderRow */0) {
            return ;
          } else {
            return Caml_option.some(undefined);
          }
        })).length;
  var errorEmptyStockCellCount = Belt_Array.keepMap(decodedRows, (function (row) {
          if (row.TAG === /* Ok */0 || row._0.TAG !== /* EmptyCellStock */5) {
            return ;
          } else {
            return Caml_option.some(undefined);
          }
        })).length;
  if (errorCannotDecodeHeaderRowCount === errorRowsCount) {
    return {
            TAG: /* Error */1,
            _0: /* InvalidHeaderRow */0
          };
  } else if (errorEmptyStockCellCount === errorRowsCount) {
    return {
            TAG: /* Error */1,
            _0: /* EmptyColumnStock */2
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: decodedRows
          };
  }
}

function parseAndDecodeCsvFile(file) {
  return Future.map(Future.flatMapOk(Future.mapError(Sheet.parseCsvFile(file), undefined, (function (param) {
                        return /* Unknown */3;
                      })), undefined, (function (rows) {
                    return Future.value(decodeRows(undefined, rows));
                  })), undefined, (function (result) {
                return {
                        headerOffset: 0,
                        payload: result
                      };
              }));
}

function parseAndDecodeXlsxFile(initialHeaderIndexOffset, file) {
  var headerIndexOffset = Belt_Option.getWithDefault(initialHeaderIndexOffset, 1);
  return Future.map(Future.flatMapError(Future.flatMapOk(Future.mapError(Sheet.parseExceljsXlsxFile(headerIndexOffset, file), undefined, (function (param) {
                            return /* Unknown */3;
                          })), undefined, (function (rows) {
                        return Future.value(decodeRows(headerIndexOffset, rows));
                      })), undefined, (function (error) {
                    if (error !== 0 || !Belt_Option.isNone(initialHeaderIndexOffset)) {
                      return Future.value({
                                  TAG: /* Error */1,
                                  _0: error
                                });
                    } else {
                      return Future.map(parseAndDecodeXlsxFile(0, file), undefined, (function (result) {
                                    return result.payload;
                                  }));
                    }
                  })), undefined, (function (result) {
                return {
                        headerOffset: headerIndexOffset,
                        payload: result
                      };
              }));
}

export {
  fileTypes ,
  decodeRowVariantId ,
  decodeRowStock ,
  dictVariantIdKey ,
  dictStockKey ,
  decodeRowValues ,
  decodeDictRowWithIndex ,
  decodeTupleRowWithIndex ,
  decodeRows ,
  parseAndDecodeCsvFile ,
  parseAndDecodeXlsxFile ,
}
/* dictVariantIdKey Not a pure module */
