// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Env from "../../core/Env.bs.js";
import * as Form from "../../bundles/Form/Form.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Json from "../../primitives/Json.bs.js";
import * as $$Text from "../../primitives/Text.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as Title from "../../resources/typography/Title.bs.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Spaces from "../../resources/theme/Spaces.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Request from "../../core/Request.bs.js";
import * as FontFaces from "../../resources/theme/FontFaces.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AuthLoginPage from "./AuthLoginPage.bs.js";

function get(values, field) {
  return values.email;
}

function set(values, field, value) {
  return {
          email: value
        };
}

var LoginRecoveryFormLenses = {
  get: get,
  set: set
};

var LoginRecoveryForm = Form.Make(LoginRecoveryFormLenses);

var styles = $$StyleSheet.create({
      container: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, "column", undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(-22)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(465)), undefined, undefined),
      title: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.large)), undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(100)), undefined, undefined),
      subtitle: Style.merge([
            FontFaces.libreFranklinRegularStyle,
            Style.style(undefined, undefined, undefined, "#535165", undefined, 14, undefined, undefined, undefined, undefined, undefined, 21, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#f8f8fb", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(15)), undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.normal)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ])
    });

var endpoint = Env.gatewayUrl(undefined) + "/auth/password-forgot";

function encodeBody(username) {
  return Json.encodeDict(Js_dict.fromArray([[
                    "username",
                    Json.encodeString(username)
                  ]]));
}

function make(username) {
  return $$Request.make("POST", Caml_option.some(encodeBody(username)), false, undefined, endpoint);
}

var PasswordRecoveryRequest = {
  endpoint: endpoint,
  encodeBody: encodeBody,
  make: make
};

var schema = [{
    TAG: /* Email */5,
    _0: /* Email */0
  }];

function AuthLoginRecoveryPage(Props) {
  var loginRecoveryEmailSentRoute = Props.loginRecoveryEmailSentRoute;
  var passwordRecoveryRequest = Props.passwordRecoveryRequest;
  var match = React.useState(function () {
        
      });
  var setNotfication = match[1];
  var notification = match[0];
  var onSubmit = function (param, param$1) {
    return Future.mapError(Future.mapOk(Curry._1(passwordRecoveryRequest, param$1.email), undefined, (function (param) {
                      return $$Intl.t("You should have received the recovery link by the next minutes.");
                    })), undefined, (function (param) {
                  return $$Intl.t("An unexpected error occured. Please try again or contact the support.");
                }));
  };
  var navigate = Navigation.useNavigate(undefined);
  var onSubmitSuccess = function (res) {
    if (res !== undefined) {
      return Curry._3(navigate, undefined, undefined, loginRecoveryEmailSentRoute);
    } else {
      return Curry._1(setNotfication, (function (param) {
                    return {
                            TAG: /* Danger */1,
                            _0: $$Intl.t("An unexpected error occured. Please try again or contact the support.")
                          };
                  }));
    }
  };
  var onSubmitFailure = function (error) {
    Curry._1(setNotfication, (function (param) {
            return {
                    TAG: /* Danger */1,
                    _0: error
                  };
          }));
  };
  var formPropState = Curry._1(LoginRecoveryForm.useFormPropState, {
        initialValues: {
          email: ""
        },
        schema: schema,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  var onRequestCloseNotificationBanner = function (param) {
    Curry._1(setNotfication, (function (param) {
            
          }));
  };
  return React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.container), undefined, undefined, undefined), React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.title), undefined, undefined, undefined), React.createElement(Title.make, {
                      children: $$Intl.t("Recover your password"),
                      level: 2,
                      align: "center"
                    }), notification !== undefined ? React.createElement(AuthLoginPage.NotificationBanner.make, {
                        notification: notification,
                        onRequestClose: onRequestCloseNotificationBanner
                      }) : null, React.createElement($$Text.make, $$Text.makeProps($$Intl.t("Type the email address associated with your account, and we will send you a link to reset your password. If you don’t receive the email, please check your spam folder. If no email arrives, it means there is no account associated with that address."), Caml_option.some(styles.subtitle), undefined, undefined, undefined))), React.createElement(LoginRecoveryForm.Core.Provider.make, {
                  children: null,
                  propState: formPropState
                }, React.createElement(LoginRecoveryForm.ControlEnterKey.make, {
                      onSubmit: onSubmit
                    }), React.createElement(Stack.make, {
                      children: null,
                      space: "large"
                    }, React.createElement(LoginRecoveryForm.InputText.make, {
                          field: /* Email */0,
                          label: $$Intl.t("Email"),
                          placeholder: $$Intl.t("name@yourcompany.com"),
                          hideRequired: true
                        }), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(LoginRecoveryForm.SubmitButton.make, {
                                      onSubmit: onSubmit,
                                      text: $$Intl.t("Send a recovery link")
                                    })), undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)))));
}

var passwordRecoveryRequest = make;

var make$1 = AuthLoginRecoveryPage;

export {
  LoginRecoveryFormLenses ,
  LoginRecoveryForm ,
  styles ,
  PasswordRecoveryRequest ,
  passwordRecoveryRequest ,
  schema ,
  make$1 as make,
}
/* LoginRecoveryForm Not a pure module */
