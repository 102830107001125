// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Tooltip from "../../resources/overlays/Tooltip.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as StockTransferForm from "./StockTransferForm.bs.js";

function StockTransferFormRecipientInformationCard(Props) {
  var shops = Auth.useShops(undefined);
  var formState = Curry._1(StockTransferForm.useFormState, undefined);
  var items = Belt_Array.map(shops, (function (shop) {
          return {
                  key: shop.id,
                  value: shop.id,
                  label: shop.name,
                  disabled: shop.id === formState.values.senderShopId
                };
        }));
  var sections = [{
      items: items,
      title: $$Intl.t("Shops")
    }];
  return React.createElement(Card.make, {
              children: React.createElement(Stack.make, {
                    children: React.createElement(StockTransferForm.InputSelect.make, {
                          field: /* RecipientShopId */1,
                          label: $$Intl.t("Recipient"),
                          tooltip: React.createElement(Tooltip.Span.make, {
                                text: $$Intl.t("Changing the recipient will reset the transfer basket.")
                              }),
                          placeholder: $$Intl.t("Select the destination shop"),
                          sections: sections
                        }),
                    space: "large"
                  }),
              title: $$Intl.t("Recipient information")
            });
}

var make = React.memo(StockTransferFormRecipientInformationCard);

export {
  make ,
}
/* make Not a pure module */
