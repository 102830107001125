// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

function Make(Lenses) {
  var assertion = function (field, comparator, error) {
    if (comparator) {
      return ;
    } else {
      return [
              /* Field */{
                _0: field
              },
              error
            ];
    }
  };
  var validate = function (schema, values) {
    var errors = Belt_Array.keepMap(schema, (function (rule) {
            switch (rule.TAG | 0) {
              case /* StringMin */0 :
                  var min = rule._1;
                  var field = rule._0;
                  var value = Curry._2(Lenses.get, values, field);
                  var error = $$Intl.template($$Intl.t("This value must be at least {{min}} character(s)."), {
                        min: min
                      }, undefined);
                  return assertion(field, value.length >= min, error);
              case /* StringNotEmpty */1 :
                  var field$1 = rule._0;
                  var value$1 = Curry._2(Lenses.get, values, field$1);
                  var error$1 = $$Intl.t("Please fulfill this field.");
                  return assertion(field$1, value$1.length >= 1, error$1);
              case /* IntRange */2 :
                  var max = rule._2;
                  var min$1 = rule._1;
                  var field$2 = rule._0;
                  var value$2 = Curry._2(Lenses.get, values, field$2);
                  if (min$1 !== undefined) {
                    if (max !== undefined) {
                      var error$2 = $$Intl.template($$Intl.t("This number must range between {{min}} and {{max}} inclusive."), {
                            min: min$1,
                            max: max
                          }, undefined);
                      return assertion(field$2, value$2 >= min$1 && value$2 <= max, error$2);
                    }
                    var error$3 = $$Intl.template($$Intl.t("This number must be superior or equal to {{min}}."), {
                          min: min$1
                        }, undefined);
                    return assertion(field$2, value$2 >= min$1, error$3);
                  }
                  if (max === undefined) {
                    return ;
                  }
                  var error$4 = $$Intl.template($$Intl.t("This number must be inferior or equal to {{max}}."), {
                        max: max
                      }, undefined);
                  return assertion(field$2, value$2 <= max, error$4);
              case /* FloatRange */3 :
                  var max$1 = rule._2;
                  var min$2 = rule._1;
                  var field$3 = rule._0;
                  var value$3 = Curry._2(Lenses.get, values, field$3);
                  if (min$2 !== undefined) {
                    if (max$1 !== undefined) {
                      var error$5 = $$Intl.template($$Intl.t("This number must range between {{min}} and {{max}} inclusive."), {
                            min: min$2,
                            max: max$1
                          }, undefined);
                      return assertion(field$3, value$3 >= min$2 && value$3 <= max$1, error$5);
                    }
                    var error$6 = $$Intl.template($$Intl.t("This number must be superior or equal to {{min}}."), {
                          min: min$2 - 1
                        }, undefined);
                    return assertion(field$3, value$3 >= min$2, error$6);
                  }
                  if (max$1 === undefined) {
                    return ;
                  }
                  var error$7 = $$Intl.template($$Intl.t("This number must be inferior or equal to {{max}}."), {
                        max: max$1 + 1
                      }, undefined);
                  return assertion(field$3, value$3 <= max$1, error$7);
              case /* PhoneNumber */4 :
                  var field$4 = rule._0;
                  var re = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
                  var value$4 = Curry._2(Lenses.get, values, field$4);
                  var error$8 = $$Intl.t("This value is not a valid phone number.");
                  return assertion(field$4, re.test(value$4), error$8);
              case /* Email */5 :
                  var field$5 = rule._0;
                  var re$1 = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  var value$5 = Curry._2(Lenses.get, values, field$5);
                  var error$9 = $$Intl.t("Invalid email address.");
                  return assertion(field$5, re$1.test(value$5), error$9);
              case /* Password */6 :
                  var field$6 = rule._0;
                  var re$2 = /^(?=.{10,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).*$/;
                  var value$6 = Curry._2(Lenses.get, values, field$6);
                  var error$10 = $$Intl.t("Invalid password.");
                  return assertion(field$6, re$2.test(value$6), error$10);
              case /* Custom */7 :
                  var error$11 = Curry._1(rule._1, values);
                  if (error$11.TAG === /* Ok */0) {
                    return ;
                  } else {
                    return [
                            /* Field */{
                              _0: rule._0
                            },
                            error$11._0
                          ];
                  }
              case /* CustomString */8 :
                  var field$7 = rule._0;
                  var error$12 = Curry._2(rule._1, Curry._2(Lenses.get, values, field$7), values);
                  if (error$12.TAG === /* Ok */0) {
                    return ;
                  } else {
                    return [
                            /* Field */{
                              _0: field$7
                            },
                            error$12._0
                          ];
                  }
              case /* ArrayMin */9 :
                  var min$3 = rule._1;
                  var field$8 = rule._0;
                  var value$7 = Curry._2(Lenses.get, values, field$8);
                  var stringLiteral = $$Intl.t("This value must contain at least {{min}} item(s).");
                  var error$13 = $$Intl.template(stringLiteral, {
                        min: min$3
                      }, undefined);
                  return assertion(field$8, value$7.length >= min$3, error$13);
              
            }
          }));
    if (errors.length === 0) {
      return {
              TAG: /* Ok */0,
              _0: undefined
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: errors
            };
    }
  };
  var required = function (schema, field) {
    return Belt_Array.some(schema, (function (rule) {
                  switch (rule.TAG | 0) {
                    case /* IntRange */2 :
                    case /* FloatRange */3 :
                    case /* Custom */7 :
                    case /* ArrayMin */9 :
                        break;
                    default:
                      return Caml_obj.equal(/* Field */{
                                  _0: rule._0
                                }, /* Field */{
                                  _0: field
                                });
                  }
                  return Caml_obj.equal(/* Field */{
                              _0: rule._0
                            }, /* Field */{
                              _0: field
                            });
                }));
  };
  return {
          assertion: assertion,
          validate: validate,
          required: required
        };
}

export {
  Make ,
}
/* Intl Not a pure module */
