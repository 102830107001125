// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as AccountingPage from "./AccountingPage.bs.js";
import * as AccountingVendor from "../../modules/AccountingVendor.bs.js";
import * as AccountingConfiguration from "../../modules/AccountingConfiguration.bs.js";
import * as AccountingConfigurationPage from "./AccountingConfigurationPage.bs.js";

var baseRoute = "/accounting";

function configurationRoute(shopId, vendor) {
  return baseRoute + "/" + shopId + "/configuration/" + AccountingVendor.toLowercaseString(vendor);
}

function AccountingRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  if (!subUrlPath) {
    return React.createElement(AccountingPage.make, {
                configurationRoute: configurationRoute,
                getShopAccountingConfigurationRequest: AccountingConfiguration.request
              });
  }
  var match = subUrlPath.tl;
  if (match && match.hd === "configuration") {
    var match$1 = match.tl;
    if (match$1 && !match$1.tl) {
      var vendor = AccountingVendor.fromString(match$1.hd);
      if (vendor !== undefined) {
        return React.createElement(AccountingConfigurationPage.make, {
                    getAllShopTaxesRequest: AccountingConfigurationPage.getAllShopTaxesRequest,
                    getShopAccountingConfigurationRequest: AccountingConfiguration.request,
                    createOrUpdateShopAccountingConfigurationRequest: AccountingConfigurationPage.createOrUpdateShopAccountingConfigurationRequest,
                    configurationRoute: configurationRoute,
                    baseRoute: baseRoute,
                    shopId: subUrlPath.hd,
                    vendor: vendor
                  });
      } else {
        return React.createElement(Navigation.Redirect.make, {
                    route: baseRoute
                  });
      }
    }
    
  }
  return React.createElement(Navigation.Redirect.make, {
              route: baseRoute
            });
}

var getAllShopTaxesRequest = AccountingConfigurationPage.getAllShopTaxesRequest;

var createOrUpdateShopAccountingConfigurationRequest = AccountingConfigurationPage.createOrUpdateShopAccountingConfigurationRequest;

var getShopAccountingConfigurationRequest = AccountingConfiguration.request;

var make = AccountingRouter;

export {
  baseRoute ,
  configurationRoute ,
  getAllShopTaxesRequest ,
  createOrUpdateShopAccountingConfigurationRequest ,
  getShopAccountingConfigurationRequest ,
  make ,
}
/* react Not a pure module */
