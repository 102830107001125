// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Select from "../../resources/selection-and-input/Select.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Scaffold from "../../bundles/Scaffold/Scaffold.bs.js";
import * as JsonCodec from "../../primitives/JsonCodec.bs.js";
import * as Separator from "../../resources/layout-and-structure/Separator.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as EmptyState from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DateHelpers from "../../helpers/DateHelpers.bs.js";
import * as Illustration from "../../resources/images-and-icons/Illustration.bs.js";
import * as Client from "@apollo/client";
import * as CatalogProduct from "../../modules/Catalog/CatalogProduct.bs.js";
import * as AmountTableCell from "../../modules/AmountTableCell.bs.js";
import * as SelectDateRangeFilter from "../../resources/selection-and-input/SelectDateRangeFilter.bs.js";
import * as LegacyProductReferenceTableCell from "../../modules/Product/LegacyProductReferenceTableCell.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query AnalyticsTopPerformingProductsPageQuery($filterBy: TopPerformingProductsQueryFilter, $sortBy: TopPerformingProductsQuerySort)  {\ntopPerformingProducts(filterBy: $filterBy, sortBy: $sortBy)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nmargin  \nmarginRate  \nmarkupRate  \ntotalPurchaseCost  \nrevenueIncludingTaxes  \nrevenueExcludingTaxes  \noccurrences  \nquantity  \ncapacityPrecision  \ncapacityUnit  \nname  \ncolor  \nkind  \nstockRawQuantity  \nstockKeepingUnit  \npurchasedPrice  \ncku  \ndescription  \nvariantId  \n}\n\n}\n\ntotalCount  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.topPerformingProducts;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          topPerformingProducts: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.capacityPrecision;
                    var value$3 = value$1.capacityUnit;
                    var value$4 = value$1.color;
                    var tmp;
                    if (value$4 == null) {
                      tmp = undefined;
                    } else {
                      var tmp$1;
                      switch (value$4) {
                        case "AMBER" :
                            tmp$1 = "AMBER";
                            break;
                        case "BLACK" :
                            tmp$1 = "BLACK";
                            break;
                        case "BLOND" :
                            tmp$1 = "BLOND";
                            break;
                        case "DARK" :
                            tmp$1 = "DARK";
                            break;
                        case "ORANGE" :
                            tmp$1 = "ORANGE";
                            break;
                        case "RED" :
                            tmp$1 = "RED";
                            break;
                        case "ROSE" :
                            tmp$1 = "ROSE";
                            break;
                        case "WHITE" :
                            tmp$1 = "WHITE";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp = tmp$1;
                    }
                    var value$5 = value$1.kind;
                    var tmp$2;
                    switch (value$5) {
                      case "BEER" :
                          tmp$2 = "BEER";
                          break;
                      case "SIMPLE" :
                          tmp$2 = "SIMPLE";
                          break;
                      case "SPIRITUOUS" :
                          tmp$2 = "SPIRITUOUS";
                          break;
                      case "WINE" :
                          tmp$2 = "WINE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$6 = value$1.stockRawQuantity;
                    var value$7 = value$1.stockKeepingUnit;
                    var value$8 = value$1.purchasedPrice;
                    var value$9 = value$1.cku;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              margin: value$1.margin,
                              marginRate: value$1.marginRate,
                              markupRate: value$1.markupRate,
                              totalPurchaseCost: value$1.totalPurchaseCost,
                              revenueIncludingTaxes: value$1.revenueIncludingTaxes,
                              revenueExcludingTaxes: value$1.revenueExcludingTaxes,
                              occurrences: value$1.occurrences,
                              quantity: value$1.quantity,
                              capacityPrecision: !(value$2 == null) ? value$2 : undefined,
                              capacityUnit: !(value$3 == null) ? value$3 : undefined,
                              name: value$1.name,
                              color: tmp,
                              kind: tmp$2,
                              stockRawQuantity: !(value$6 == null) ? value$6 : undefined,
                              stockKeepingUnit: !(value$7 == null) ? value$7 : undefined,
                              purchasedPrice: !(value$8 == null) ? value$8 : undefined,
                              cku: !(value$9 == null) ? value$9 : undefined,
                              description: value$1.description,
                              variantId: value$1.variantId
                            }
                          };
                  }), value$5),
            totalCount: value$1.totalCount
          }
        };
}

function serialize(value) {
  var value$1 = value.topPerformingProducts;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.variantId;
          var value$3 = value$1.description;
          var value$4 = value$1.cku;
          var cku = value$4 !== undefined ? value$4 : null;
          var value$5 = value$1.purchasedPrice;
          var purchasedPrice = value$5 !== undefined ? value$5 : null;
          var value$6 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$6 !== undefined ? value$6 : null;
          var value$7 = value$1.stockRawQuantity;
          var stockRawQuantity = value$7 !== undefined ? value$7 : null;
          var value$8 = value$1.kind;
          var kind = value$8 === "SPIRITUOUS" ? "SPIRITUOUS" : (
              value$8 === "BEER" ? "BEER" : (
                  value$8 === "WINE" ? "WINE" : "SIMPLE"
                )
            );
          var value$9 = value$1.color;
          var color = value$9 !== undefined ? (
              value$9 === "ORANGE" ? "ORANGE" : (
                  value$9 === "RED" ? "RED" : (
                      value$9 === "AMBER" ? "AMBER" : (
                          value$9 === "DARK" ? "DARK" : (
                              value$9 === "BLACK" ? "BLACK" : (
                                  value$9 === "BLOND" ? "BLOND" : (
                                      value$9 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$10 = value$1.name;
          var value$11 = value$1.capacityUnit;
          var capacityUnit = value$11 !== undefined ? value$11 : null;
          var value$12 = value$1.capacityPrecision;
          var capacityPrecision = value$12 !== undefined ? value$12 : null;
          var value$13 = value$1.quantity;
          var value$14 = value$1.occurrences;
          var value$15 = value$1.revenueExcludingTaxes;
          var value$16 = value$1.revenueIncludingTaxes;
          var value$17 = value$1.totalPurchaseCost;
          var value$18 = value$1.markupRate;
          var value$19 = value$1.marginRate;
          var value$20 = value$1.margin;
          var value$21 = value$1.__typename;
          var node = {
            __typename: value$21,
            margin: value$20,
            marginRate: value$19,
            markupRate: value$18,
            totalPurchaseCost: value$17,
            revenueIncludingTaxes: value$16,
            revenueExcludingTaxes: value$15,
            occurrences: value$14,
            quantity: value$13,
            capacityPrecision: capacityPrecision,
            capacityUnit: capacityUnit,
            name: value$10,
            color: color,
            kind: kind,
            stockRawQuantity: stockRawQuantity,
            stockKeepingUnit: stockKeepingUnit,
            purchasedPrice: purchasedPrice,
            cku: cku,
            description: value$3,
            variantId: value$2
          };
          var value$22 = value.__typename;
          return {
                  __typename: value$22,
                  node: node
                };
        }), value$3);
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var topPerformingProducts = {
    __typename: value$8,
    pageInfo: pageInfo,
    edges: edges,
    totalCount: value$2
  };
  return {
          topPerformingProducts: topPerformingProducts
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectTopPerformingProductsQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.startDate;
  var a$2 = inp.endDate;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          startDate: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          endDate: a$2 !== undefined ? Caml_option.valFromOption(a$2) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  var a$1 = inp.sortBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectTopPerformingProductsQueryFilter(a) : undefined,
          sortBy: a$1 !== undefined ? (
              a$1 === "QUANTITY" ? "QUANTITY" : (
                  a$1 === "REVENUE_INCLUDING_TAXES" ? "REVENUE_INCLUDING_TAXES" : "MARGIN"
                )
            ) : undefined
        };
}

function makeVariables(filterBy, sortBy, param) {
  return {
          filterBy: filterBy,
          sortBy: sortBy
        };
}

function makeInputObjectTopPerformingProductsQueryFilter(shopIds, startDate, endDate, param) {
  return {
          shopIds: shopIds,
          startDate: startDate,
          endDate: endDate
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined,
          sortBy: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectTopPerformingProductsQueryFilter: serializeInputObjectTopPerformingProductsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectTopPerformingProductsQueryFilter: makeInputObjectTopPerformingProductsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectTopPerformingProductsQueryFilter: serializeInputObjectTopPerformingProductsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectTopPerformingProductsQueryFilter: makeInputObjectTopPerformingProductsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

var values = [
  /* Quantity */0,
  /* RevenueIncludingTaxes */1,
  /* Margin */2
];

function toLabel(value) {
  switch (value) {
    case /* Quantity */0 :
        return $$Intl.t("Sold quantity");
    case /* RevenueIncludingTaxes */1 :
        return $$Intl.t("Revenue");
    case /* Margin */2 :
        return $$Intl.t("Margin");
    
  }
}

function toRawString(value) {
  switch (value) {
    case /* Quantity */0 :
        return "QUANTITY";
    case /* RevenueIncludingTaxes */1 :
        return "REVENUE_INCLUDING_TAXES";
    case /* Margin */2 :
        return "MARGIN";
    
  }
}

function toQueryVariableValue(value) {
  switch (value) {
    case /* Quantity */0 :
        return "QUANTITY";
    case /* RevenueIncludingTaxes */1 :
        return "REVENUE_INCLUDING_TAXES";
    case /* Margin */2 :
        return "MARGIN";
    
  }
}

var SortBy = {
  values: values,
  toLabel: toLabel,
  toRawString: toRawString,
  toQueryVariableValue: toQueryVariableValue
};

function encoder(param) {
  var match = param.dateRange;
  return [
          param.shop.id,
          [
            match[0].valueOf(),
            match[1].valueOf()
          ]
        ];
}

function decoder(shops, param) {
  var dateRange = param[1];
  var shopId = param[0];
  var maybeShop = Belt_Array.getBy(shops, (function (shop) {
          return shop.id === shopId;
        }));
  var startDate = dateRange[0];
  var endDate = dateRange[1];
  return {
          TAG: /* Ok */0,
          _0: {
            shop: maybeShop !== undefined ? maybeShop : shops[0],
            dateRange: [
              new Date(startDate),
              new Date(endDate)
            ]
          }
        };
}

function useJsonCodec(param) {
  var shops = Auth.useShops(undefined);
  return JsonCodec.object2(encoder, (function (param) {
                return decoder(shops, param);
              }), JsonCodec.field("shopId", JsonCodec.string), JsonCodec.field("dateRange", JsonCodec.array(JsonCodec.$$float)));
}

var Filters = {
  encoder: encoder,
  decoder: decoder,
  useJsonCodec: useJsonCodec
};

function unapplyArbitraryPrecision(quantity, precision) {
  return quantity / Math.pow(10, precision);
}

function formatStockQuantity(quantity, capacity) {
  if (!capacity) {
    return $$Intl.decimalFormat(undefined, undefined, quantity);
  }
  var unit = capacity._0;
  var quantity$1 = unapplyArbitraryPrecision(quantity, capacity._1);
  if (unit.TAG === /* Intl */0) {
    return $$Intl.unitFormat(unit._0, undefined, undefined, quantity$1);
  } else {
    return $$Intl.decimalFormat(undefined, undefined, quantity$1) + " " + unit._0;
  }
}

function formatPurchasedPrice(value, capacity, currency) {
  if (!capacity) {
    return $$Intl.currencyFormat(currency, undefined, 3, value);
  }
  var unit = capacity._0;
  var tmp;
  tmp = unit.TAG === /* Intl */0 ? $$Intl.numberUnitToString(unit._0) : unit._0;
  return $$Intl.currencyFormat(currency, undefined, 3, value) + "/" + tmp;
}

function fromQueryDataEdge(param) {
  var node = param.node;
  var match = node.capacityPrecision;
  var match$1 = node.capacityUnit;
  var capacity;
  if (match !== undefined && match$1 !== undefined) {
    switch (match$1) {
      case "G" :
      case "g" :
          capacity = /* Bulk */{
            _0: {
              TAG: /* Intl */0,
              _0: "gram"
            },
            _1: match
          };
          break;
      case "KG" :
      case "kg" :
          capacity = /* Bulk */{
            _0: {
              TAG: /* Intl */0,
              _0: "kilogram"
            },
            _1: match
          };
          break;
      case "L" :
      case "l" :
          capacity = /* Bulk */{
            _0: {
              TAG: /* Intl */0,
              _0: "liter"
            },
            _1: match
          };
          break;
      default:
        capacity = /* Bulk */{
          _0: {
            TAG: /* NonIntl */1,
            _0: match$1
          },
          _1: match
        };
    }
  } else {
    capacity = /* Unit */0;
  }
  return {
          margin: $$Intl.currencyFormat("EUR", undefined, undefined, node.margin),
          marginRate: $$Intl.percentFormat(2, 2, node.marginRate / 100),
          markupRate: $$Intl.percentFormat(2, 2, node.markupRate / 100),
          totalPurchaseCost: $$Intl.currencyFormat("EUR", undefined, undefined, node.totalPurchaseCost),
          revenueIncludingTaxes: node.revenueIncludingTaxes,
          revenueExcludingTaxes: node.revenueExcludingTaxes,
          unitSoldQuantity: capacity ? node.occurrences : node.quantity,
          bulkSoldQuantity: capacity ? formatStockQuantity(node.quantity, capacity) : undefined,
          stockQuantity: Belt_Option.mapWithDefault(node.stockRawQuantity, "0", (function (param) {
                  return formatStockQuantity(param, capacity);
                })),
          purchasedPrice: Belt_Option.mapWithDefault(node.purchasedPrice, "—", (function (param) {
                  return formatPurchasedPrice(param, capacity, "EUR");
                })),
          name: node.name,
          color: node.color,
          kind: node.kind,
          stockKeepingUnit: node.stockKeepingUnit,
          cku: node.cku,
          description: node.description,
          variantId: node.variantId
        };
}

var Row = {
  unapplyArbitraryPrecision: unapplyArbitraryPrecision,
  formatStockQuantity: formatStockQuantity,
  formatPurchasedPrice: formatPurchasedPrice,
  fromQueryDataEdge: fromQueryDataEdge
};

function makeQueryVariables(defaultQueryVariables, connectionArguments, search, filterBy, param) {
  return {
          filterBy: filterBy,
          sortBy: defaultQueryVariables.sortBy
        };
}

function makeQueryVariablesFilterBy(param) {
  var match = param.dateRange;
  return {
          shopIds: {
            _in: [param.shop.id]
          },
          startDate: Caml_option.some(Scalar.Datetime.serialize(match[0])),
          endDate: Caml_option.some(Scalar.Datetime.serialize(match[1]))
        };
}

function totalCountFromQueryData(param) {
  return param.topPerformingProducts.totalCount;
}

function cursorsFromQueryData(param) {
  var topPerformingProducts = param.topPerformingProducts;
  return [
          topPerformingProducts.pageInfo.startCursor,
          topPerformingProducts.pageInfo.endCursor
        ];
}

function rowsFromQueryDataAndState(param, param$1) {
  return Belt_Array.map(Belt_Array.slice(param.topPerformingProducts.edges, Math.imul(param$1.currentPage - 1 | 0, Scaffold.edgesPerPage), Scaffold.edgesPerPage), fromQueryDataEdge);
}

function keyExtractor(param) {
  return param.variantId;
}

var Scaffolded = Scaffold.Make({
      useFiltersJsonCodec: useJsonCodec,
      QueryInner: Query_inner,
      useQuery: use,
      makeQueryVariables: makeQueryVariables,
      makeQueryVariablesFilterBy: makeQueryVariablesFilterBy,
      cursorsFromQueryData: cursorsFromQueryData,
      totalCountFromQueryData: totalCountFromQueryData,
      rowsFromQueryDataAndState: rowsFromQueryDataAndState,
      keyExtractor: keyExtractor
    });

var scaffoldedColumns = [
  {
    name: $$Intl.t("Name and description"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 250
      },
      width: {
        NAME: "fr",
        VAL: 3.5
      },
      margin: "xxlarge",
      sticky: true
    },
    render: (function (param) {
        var pastilleColor = Belt_Option.map(param.color, (function (color) {
                return CatalogProduct.Color.toColorSet(color, "pastille").foregroundColor;
              }));
        var description = Belt_Option.mapWithDefault(param.stockKeepingUnit, "", (function (sku) {
                return "SKU" + $$Intl.t(":") + " " + sku + ", ";
              })) + param.description;
        var tmp = {
          name: param.name,
          description: description
        };
        if (param.cku !== undefined) {
          tmp.cku = Caml_option.valFromOption(param.cku);
        }
        if (pastilleColor !== undefined) {
          tmp.pastilleColor = Caml_option.valFromOption(pastilleColor);
        }
        return React.createElement(LegacyProductReferenceTableCell.make, tmp);
      })
  },
  {
    name: $$Intl.t("Sold Quantity"),
    layout: {
      alignX: "center"
    },
    render: (function (param) {
        var bulkSoldQuantity = param.bulkSoldQuantity;
        return React.createElement(Inline.make, {
                    children: React.createElement(Stack.make, {
                          children: null
                        }, React.createElement(TextStyle.make, {
                              children: param.unitSoldQuantity
                            }), bulkSoldQuantity !== undefined ? React.createElement(TextStyle.make, {
                                children: $$Intl.template($$Intl.t("For {{value}}"), {
                                      value: bulkSoldQuantity
                                    }, undefined),
                                variation: "subdued",
                                size: "xxsmall"
                              }) : null),
                    align: "center"
                  });
      })
  },
  {
    name: $$Intl.t("Revenue excl. VAT"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 110
      },
      margin: "small"
    },
    render: (function (param) {
        return React.createElement(AmountTableCell.make, {
                    value: param.revenueExcludingTaxes,
                    secondaryValue: param.revenueIncludingTaxes
                  });
      })
  },
  {
    name: $$Intl.t("Margin"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 135
      },
      width: {
        NAME: "fr",
        VAL: 1.5
      },
      margin: "large",
      alignX: "flexEnd"
    },
    render: (function (param) {
        return React.createElement(TextStyle.make, {
                    children: param.margin,
                    weight: "semibold"
                  });
      })
  },
  {
    name: $$Intl.t("Margin/Markup rt"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 100
      },
      width: {
        NAME: "fr",
        VAL: 1.5
      }
    },
    render: (function (param) {
        return React.createElement(Stack.make, {
                    children: null,
                    space: "xxsmall"
                  }, React.createElement(TextStyle.make, {
                        children: param.marginRate
                      }), React.createElement(TextStyle.make, {
                        children: param.markupRate,
                        variation: "normal",
                        size: "xxsmall"
                      }));
      })
  },
  {
    name: $$Intl.t("Cost of sales"),
    layout: {
      width: {
        NAME: "fr",
        VAL: 1.5
      },
      alignX: "flexEnd"
    },
    render: (function (param) {
        return React.createElement(TextStyle.make, {
                    children: param.totalPurchaseCost
                  });
      })
  },
  {
    name: $$Intl.t("Purchase price"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 110
      },
      alignX: "flexEnd"
    },
    render: (function (param) {
        return React.createElement(TextStyle.make, {
                    children: param.purchasedPrice
                  });
      })
  },
  {
    name: $$Intl.t("Stock"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 75
      },
      alignX: "center"
    },
    render: (function (param) {
        return React.createElement(TextStyle.make, {
                    children: param.stockQuantity
                  });
      })
  }
];

var now = new Date();

var initialDateRange_0 = DateHelpers.startOfDay(now);

var initialDateRange_1 = DateHelpers.endOfDay(now);

var initialDateRange = [
  initialDateRange_0,
  initialDateRange_1
];

function AnalyticsTopPerformingProductsPage(Props) {
  var scope = Auth.useScope(undefined);
  var organisationAccount;
  organisationAccount = scope.TAG === /* Organisation */0 ? true : false;
  var activeShop;
  if (scope.TAG === /* Organisation */0) {
    var shop = scope.activeShop;
    activeShop = shop !== undefined ? shop : scope.shops[0];
  } else {
    activeShop = scope._0;
  }
  var initialState = Curry._1(Scaffolded.makeInitialState, {
        shop: activeShop,
        dateRange: initialDateRange
      });
  var match = Curry._1(Scaffolded.use, (function (param) {
          return initialState;
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return /* Quantity */0;
      });
  var setSortBy = match$1[1];
  var sortBy = match$1[0];
  var defaultQueryVariables_sortBy = toQueryVariableValue(sortBy);
  var defaultQueryVariables = {
    filterBy: undefined,
    sortBy: defaultQueryVariables_sortBy
  };
  var items = Belt_Array.map(values, (function (value) {
          return {
                  key: toRawString(value),
                  value: value,
                  label: toLabel(value)
                };
        }));
  var filters = React.createElement(Inline.make, {
        children: null,
        space: "small"
      }, organisationAccount ? React.createElement(Auth.SelectSingleShopFilter.make, {
              value: state.filters.shop,
              onChange: (function (shop) {
                  Curry._1(dispatch, {
                        TAG: /* FiltersUpdated */2,
                        _0: (function (prev) {
                            return {
                                    shop: shop,
                                    dateRange: prev.dateRange
                                  };
                          })
                      });
                })
            }) : null, organisationAccount ? React.createElement(Separator.make, {}) : null, React.createElement(Select.make, {
            label: $$Intl.t("Sorting"),
            sections: [{
                items: items,
                title: $$Intl.t("Options")
              }],
            preset: "filter",
            value: sortBy,
            onChange: (function (value) {
                Curry._1(setSortBy, (function (param) {
                        return Belt_Option.getExn(value);
                      }));
              })
          }), React.createElement(SelectDateRangeFilter.make, {
            value: state.filters.dateRange,
            placeholder: $$Intl.t("Select a period"),
            disabledResetButton: true,
            onChange: (function (dateRange) {
                var dateRange$1 = Belt_Option.getWithDefault(dateRange, initialDateRange);
                Curry._1(dispatch, {
                      TAG: /* FiltersUpdated */2,
                      _0: (function (prev) {
                          return {
                                  shop: prev.shop,
                                  dateRange: dateRange$1
                                };
                        })
                    });
              }),
            triggerLabelDisplay: "showPreset"
          }));
  var emptyState = React.createElement(EmptyState.make, {
        illustration: Illustration.notFound,
        title: $$Intl.t("No result were found."),
        text: $$Intl.t("Try again with different filters.")
      });
  return React.createElement(Scaffolded.make, {
              title: $$Intl.t("Top sold products"),
              state: state,
              dispatch: dispatch,
              columns: scaffoldedColumns,
              filters: filters,
              emptyState: emptyState,
              defaultQueryVariables: defaultQueryVariables
            });
}

var make = React.memo(AnalyticsTopPerformingProductsPage);

export {
  Query ,
  SortBy ,
  Filters ,
  Row ,
  Scaffolded ,
  scaffoldedColumns ,
  now ,
  initialDateRange ,
  make ,
}
/* query Not a pure module */
