// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App from "./App.bs.js";
import * as React from "react";
import * as Providers from "./core/Providers.bs.js";
import * as BugTracker from "./core/BugTracker.bs.js";

function Root(Props) {
  return React.createElement(Providers.make, {
              children: React.createElement(BugTracker.Boundary.make, {
                    children: React.createElement(App.make, {})
                  })
            });
}

var make = Root;

export {
  make ,
}
/* App Not a pure module */
