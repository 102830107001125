// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Page from "../../resources/layout-and-structure/Page.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Notifier from "../../bundles/Notifier/Notifier.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as CatalogVariantEditFormPageActions from "../../modules/Catalog/CatalogVariantEditFormPageActions.bs.js";
import * as CatalogVariantEditFormStockFieldsetPanel from "../../modules/Catalog/CatalogVariantEditFormStockFieldsetPanel.bs.js";
import * as CatalogVariantEditFormRetailPriceTablePanel from "../../modules/Catalog/CatalogVariantEditFormRetailPriceTablePanel.bs.js";
import * as CatalogVariantEditFormPurchasePriceTablePanel from "../../modules/Catalog/CatalogVariantEditFormPurchasePriceTablePanel.bs.js";
import * as CatalogVariantEditFormInformationFieldsetPanel from "../../modules/Catalog/CatalogVariantEditFormInformationFieldsetPanel.bs.js";

var CatalogVariantEditPage__CouldNotMakeTitle = /* @__PURE__ */Caml_exceptions.create("CatalogVariantEditPage.CatalogVariantEditPage__CouldNotMakeTitle");

function CatalogVariantEditPage(Props) {
  var editionMode = Props.editionMode;
  var formattedName = Props.formattedName;
  var shopName = Props.shopName;
  var productTaxRate = Props.productTaxRate;
  var productKind = Props.productKind;
  var productValues = Props.productValues;
  var notifier = Notifier.use(undefined, undefined);
  var renderActions = function (param) {
    return React.createElement(CatalogVariantEditFormPageActions.make, {
                editionMode: editionMode,
                productValues: productValues
              });
  };
  var formattedTitle;
  if (editionMode) {
    if (productValues !== undefined) {
      throw {
            RE_EXN_ID: CatalogVariantEditPage__CouldNotMakeTitle,
            Error: new Error()
          };
    }
    formattedTitle = $$Intl.t("Editing") + " " + formattedName;
  } else {
    formattedTitle = productValues !== undefined ? $$Intl.template($$Intl.t("Step {{step}} | Create {{fulfill}}"), {
            step: "2",
            fulfill: $$Intl.t("a variant")
          }, undefined) : $$Intl.t("Create a new variant");
  }
  var formattedSubtitle = editionMode ? shopName : shopName + " - " + formattedName;
  return React.createElement(Page.make, {
              children: null,
              title: formattedTitle,
              subtitle: formattedSubtitle,
              renderActions: renderActions
            }, React.createElement(Notifier.Banner.make, {
                  notifier: notifier
                }), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Stack.make, {
                              children: null,
                              space: "large"
                            }, React.createElement(CatalogVariantEditFormInformationFieldsetPanel.make, {
                                  editionMode: editionMode,
                                  productKind: productKind
                                }), editionMode ? null : React.createElement(Stack.make, {
                                    children: null,
                                    space: "large"
                                  }, React.createElement(CatalogVariantEditFormPurchasePriceTablePanel.make, {}), React.createElement(CatalogVariantEditFormRetailPriceTablePanel.make, {
                                        taxRate: Belt_Option.getWithDefault(productTaxRate, 20)
                                      }), React.createElement(CatalogVariantEditFormStockFieldsetPanel.make, {})))), undefined, undefined, "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var make = React.memo(CatalogVariantEditPage);

var Config;

export {
  Config ,
  CatalogVariantEditPage__CouldNotMakeTitle ,
  make ,
}
/* make Not a pure module */
