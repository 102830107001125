// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as CatalogVariant__Queries from "./CatalogVariant__Queries.bs.js";

var CatalogVariant__Config_TaxRateNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogVariant__Config.CatalogVariant__Config_TaxRateNotFound");

function makeVariantFilterBy(activeShop, param) {
  return CatalogVariant__Queries.VariantQuery.makeInputObjectInputVariantsByCkuQueryFilter(activeShop !== undefined ? CatalogVariant__Queries.VariantQuery.makeInputObjectInFilter([activeShop.id], undefined) : undefined, undefined, "INCLUDED", undefined);
}

function variantsInformationFromData(data) {
  return Belt_Array.map(data.variantsByCku.edges, (function (param) {
                var data = param.node;
                var match = data.formattedStatus;
                var match$1 = data.bulk;
                var match$2 = data.capacityUnit;
                return {
                        id: data.id,
                        shopId: data.shop.id,
                        shopName: data.shop.name,
                        updatedAt: data.updatedAt,
                        status: match === "INACTIVE" ? /* Inactive */1 : (
                            match === "ARCHIVED" ? /* Archived */2 : (
                                match === "ACTIVE" ? /* Active */0 : undefined
                              )
                          ),
                        active: Belt_Option.getWithDefault(data.active, false),
                        name: data.name,
                        formattedName: data.formattedName,
                        formattedCapacity: Belt_Option.map(data.capacityValue, (function (capacityValue) {
                                var capacityUnit = data.capacityUnit;
                                if (capacityUnit !== undefined) {
                                  return $$Intl.decimalFormat(undefined, 2, capacityValue) + " " + capacityUnit;
                                } else {
                                  return $$Intl.decimalFormat(undefined, 2, capacityValue);
                                }
                              })),
                        year: Belt_Option.map(data.year, (function (year) {
                                return String(year);
                              })),
                        formattedAlcoholVolume: Belt_Option.map(data.alcoholVolume, (function (alcoholVolume) {
                                return $$Intl.decimalFormat(undefined, 1, alcoholVolume) + "°";
                              })),
                        ean13: data.ean13,
                        stockKeepingUnit: data.stockKeepingUnit,
                        priceLookUpCode: data.priceLookUpCode,
                        internalCode: data.internalCode,
                        purchasePrice: data.purchasedPrice,
                        taxRate: data.product.tax.value,
                        internalNote: Belt_Option.getWithDefault(data.internalNote, ""),
                        tastingNote: Belt_Option.getWithDefault(data.tastingNote, ""),
                        capacityUnit: match$1 !== undefined && match$1 && match$2 !== undefined ? match$2 : undefined,
                        bulk: Belt_Option.getWithDefault(data.bulk, false)
                      };
              }));
}

function variantsRetailPricesFromData(data) {
  return Belt_Array.map(data.variantsByCku.edges, (function (param) {
                var data = param.node;
                return Belt_Array.keepMap(Belt_SortArray.stableSortBy(data.variantPrices.edges, (function (param, param$1) {
                                  if (Belt_Option.getWithDefault(Belt_Option.map(param.node.price, (function (price) {
                                                return price.enableByDefault;
                                              })), false)) {
                                    return 0;
                                  } else {
                                    return 1;
                                  }
                                })), (function (param) {
                              var variantPrice = param.node;
                              var taxIncluded = Belt_Option.getWithDefault(Belt_Option.map(variantPrice.price, (function (price) {
                                          return price.taxIncluded;
                                        })), false);
                              var match = variantPrice.price;
                              var match$1 = variantPrice.archivedAt;
                              if (match === undefined) {
                                return ;
                              }
                              if (match.archivedAt !== undefined) {
                                return ;
                              }
                              if (match$1 !== undefined) {
                                return ;
                              }
                              var match$2 = data.bulk;
                              var match$3 = data.capacityUnit;
                              return {
                                      id: variantPrice.id,
                                      priceId: match.id,
                                      variantId: data.id,
                                      shopId: data.shop.id,
                                      shopName: data.shop.name,
                                      name: match.name,
                                      retailPrice: taxIncluded ? ({
                                            TAG: /* PriceInclTax */1,
                                            _0: variantPrice.valueIncludingTax
                                          }) : ({
                                            TAG: /* PriceExclTax */0,
                                            _0: variantPrice.valueExcludingTax
                                          }),
                                      secondaryRetailPrice: taxIncluded ? ({
                                            TAG: /* PriceExclTax */0,
                                            _0: variantPrice.valueExcludingTax
                                          }) : undefined,
                                      taxIncluded: taxIncluded,
                                      taxRate: data.product.tax.value,
                                      toQuantity: variantPrice.toQuantity,
                                      fromQuantity: variantPrice.fromQuantity,
                                      capacityUnit: match$2 !== undefined && match$2 && match$3 !== undefined ? match$3 : undefined
                                    };
                            }));
              }));
}

function variantsPurchasePriceFromData(data) {
  return Belt_Array.map(data.variantsByCku.edges, (function (param) {
                var data = param.node;
                var match = data.bulk;
                var match$1 = data.capacityUnit;
                return {
                        id: data.id,
                        shopId: data.shop.id,
                        shopName: data.shop.name,
                        supplierId: Belt_Option.mapWithDefault(data.supplier, "", (function (param) {
                                return param.id;
                              })),
                        supplierName: Belt_Option.mapWithDefault(data.supplier, "", (function (param) {
                                return param.companyName;
                              })),
                        purchasePrice: data.purchasedPrice,
                        formattedPurchasedPrice: data.formattedPurchasedPrice,
                        capacityUnit: match !== undefined && match && match$1 !== undefined ? match$1 : undefined
                      };
              }));
}

function variantsStockInformationFromData(data) {
  return Belt_Array.map(data.variantsByCku.edges, (function (param) {
                var data = param.node;
                var match = data.bulk;
                var match$1 = data.capacityUnit;
                var match$2 = data.bulk;
                var match$3 = data.capacityPrecision;
                return {
                        variantId: data.id,
                        shopId: data.shop.id,
                        shopName: data.shop.name,
                        rawQuantity: Belt_Option.getWithDefault(data.stock.rawQuantity, 0),
                        formattedQuantity: data.stock.formattedQuantity,
                        state: data.stock.state,
                        capacityUnit: match !== undefined && match && match$1 !== undefined ? match$1 : undefined,
                        capacityPrecision: match$2 !== undefined && match$2 && match$3 !== undefined ? match$3 : undefined,
                        maxStockThreshold: Belt_Option.mapWithDefault(data.maxStockThreshold, 0, (function (prim) {
                                return prim;
                              })),
                        minStockThreshold: Belt_Option.mapWithDefault(data.minStockThreshold, 0, (function (prim) {
                                return prim;
                              })),
                        stockOrderTriggerThreshold: Belt_Option.mapWithDefault(data.stockOrderTriggerThreshold, 0, (function (prim) {
                                return prim;
                              }))
                      };
              }));
}

function variantProductsInformationFromData(data) {
  return Belt_Array.map(data.variantsByCku.edges, (function (param) {
                var data = param.node;
                return {
                        id: data.product.id,
                        status: undefined,
                        name: data.product.name,
                        description: data.product.formattedDescription,
                        formattedOrigin: data.product.formattedOrigin,
                        taxValue: data.product.tax.value,
                        categoryId: undefined,
                        formattedCategoryName: Belt_Option.getWithDefault(data.formattedCategory, $$Intl.t("Not classified")),
                        shopId: data.shop.id,
                        shopName: data.shop.name,
                        internalNote: ""
                      };
              }));
}

function makeRetailPricesList(shopsVariant, variantPrices, pricesData) {
  return Belt_Array.reduceWithIndex(shopsVariant, [], (function (acc, variant, index) {
                return Belt_Array.concat(acc, [Belt_Array.concat(Belt_Option.getWithDefault(Belt_Array.get(variantPrices, index), []), Belt_Array.keepMap(pricesData.prices.edges, (function (param) {
                                        var price = param.node;
                                        var match = Belt_Array.some(Belt_Option.getWithDefault(Belt_Array.get(variantPrices, index), []), (function (variantPrice) {
                                                return variantPrice.priceId === price.id;
                                              }));
                                        if (match || price.shop.id !== variant.shopId) {
                                          return ;
                                        } else {
                                          return {
                                                  id: undefined,
                                                  priceId: price.id,
                                                  variantId: variant.id,
                                                  shopId: variant.shopId,
                                                  shopName: variant.shopName,
                                                  name: price.name,
                                                  retailPrice: price.taxIncluded ? ({
                                                        TAG: /* PriceInclTax */1,
                                                        _0: 0
                                                      }) : ({
                                                        TAG: /* PriceExclTax */0,
                                                        _0: 0
                                                      }),
                                                  secondaryRetailPrice: price.taxIncluded ? ({
                                                        TAG: /* PriceExclTax */0,
                                                        _0: 0
                                                      }) : undefined,
                                                  taxIncluded: price.taxIncluded,
                                                  taxRate: variant.taxRate,
                                                  toQuantity: undefined,
                                                  fromQuantity: undefined,
                                                  capacityUnit: variant.capacityUnit
                                                };
                                        }
                                      })))]);
              }));
}

function use(variantResponse, variantShopsResponse, pricesResponse) {
  if (variantResponse.TAG !== /* Executed */0) {
    return /* Loading */1;
  }
  if (variantResponse._0.loading) {
    return /* Loading */1;
  }
  if (variantShopsResponse.TAG !== /* Executed */0) {
    return /* Loading */1;
  }
  if (variantShopsResponse._0.loading) {
    return /* Loading */1;
  }
  if (pricesResponse.TAG !== /* Executed */0) {
    return /* Loading */1;
  }
  if (pricesResponse._0.loading) {
    return /* Loading */1;
  }
  var variantData = variantResponse._0.data;
  if (variantData === undefined) {
    return /* Error */0;
  }
  var variantShopsData = variantShopsResponse._0.data;
  if (variantShopsData === undefined) {
    return /* Error */0;
  }
  var pricesData = pricesResponse._0.data;
  if (pricesData === undefined) {
    return /* Error */0;
  }
  var shopsVariantRetailPrices = makeRetailPricesList(variantsInformationFromData(variantData), variantsRetailPricesFromData(variantData), pricesData);
  var shopsVariantRetailPricesSorted = Belt_Array.map(shopsVariantRetailPrices, (function (variantRetailPrices) {
          return Belt_SortArray.stableSortBy(variantRetailPrices, (function (current, next) {
                        if (Js_string.localeCompare(next.name, current.name) >= 0 && Belt_Option.getWithDefault(current.fromQuantity, 0) >= Belt_Option.getWithDefault(next.fromQuantity, 0)) {
                          return 1;
                        } else {
                          return 0;
                        }
                      }));
        }));
  return /* Data */{
          _0: {
            variantShopIds: Belt_Array.map(variantShopsData.variantsByCku.edges, (function (param) {
                    return param.node.shop.id;
                  })),
            shopsProduct: variantProductsInformationFromData(variantData),
            shopsVariant: variantsInformationFromData(variantData),
            shopsVariantStock: variantsStockInformationFromData(variantData),
            shopsVariantPurchasePrice: variantsPurchasePriceFromData(variantData),
            shopsVariantRetailPrices: shopsVariantRetailPricesSorted
          }
        };
}

var VariantQuery;

var VariantShopsQuery;

var PricesQuery;

var ProductConfig;

export {
  VariantQuery ,
  VariantShopsQuery ,
  PricesQuery ,
  ProductConfig ,
  CatalogVariant__Config_TaxRateNotFound ,
  makeVariantFilterBy ,
  variantsInformationFromData ,
  variantsRetailPricesFromData ,
  variantsPurchasePriceFromData ,
  variantsStockInformationFromData ,
  variantProductsInformationFromData ,
  makeRetailPricesList ,
  use ,
}
/* Intl Not a pure module */
