// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as StockActivityPage from "./StockActivityPage.bs.js";

var baseRoute = "/stockactivities";

function StockActivityRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  if (!subUrlPath) {
    return React.createElement(StockActivityPage.make, {});
  }
  if (subUrlPath.hd === "" && !subUrlPath.tl) {
    return React.createElement(StockActivityPage.make, {});
  }
  return React.createElement(Navigation.Redirect.make, {
              route: baseRoute
            });
}

var make = React.memo(StockActivityRouter);

export {
  baseRoute ,
  make ,
}
/* make Not a pure module */
