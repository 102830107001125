// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as React from "react";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";

var defaultPrice = "0,000";

var defaultCurrency = $$Intl.toCurrencySymbol("EUR");

function ProductPriceTableCell(Props) {
  var value = Props.value;
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : "normal";
  if (value !== undefined) {
    return React.createElement(TextStyle.make, {
                children: value.replace(".", ","),
                size: size,
                lineHeight: "xsmall"
              });
  } else {
    return React.createElement(TextStyle.make, {
                children: "" + defaultPrice + " " + defaultCurrency + "",
                variation: "normal",
                size: size
              });
  }
}

var make = React.memo(ProductPriceTableCell);

export {
  defaultPrice ,
  defaultCurrency ,
  make ,
}
/* defaultCurrency Not a pure module */
