// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Column from "../../resources/layout-and-structure/Column.bs.js";
import * as BigJs from "big.js";
import * as Columns from "../../resources/layout-and-structure/Columns.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as InputNumberField from "../../resources/selection-and-input/InputNumberField.bs.js";
import * as ReactUpdateEffect from "../../primitives/ReactUpdateEffect.bs.js";
import * as CartDiscount__Utils from "./CartDiscount__Utils.bs.js";
import * as InputSegmentedControlsField from "../../resources/selection-and-input/InputSegmentedControlsField.bs.js";

var defaultDiscount_quantity = BigJs.Big(0);

var defaultDiscount_warnings = [];

var defaultDiscount = {
  name: "",
  kind: /* Percent */1,
  value: 0,
  quantity: defaultDiscount_quantity,
  id: "",
  formattedValue: undefined,
  amount: undefined,
  formattedAmount: undefined,
  warnings: defaultDiscount_warnings
};

function CartGlobalDiscountView(Props) {
  var discountOpt = Props.discount;
  var warningMessage = Props.warningMessage;
  var decimalPrecision = Props.decimalPrecision;
  var dispatch = Props.onRequestDispatch;
  var discount = discountOpt !== undefined ? discountOpt : defaultDiscount;
  var match = React.useState(function () {
        return discount.value;
      });
  var setValue = match[1];
  var value = match[0];
  ReactUpdateEffect.use1((function (param) {
          var match = discount.id;
          if (match === "") {
            Curry._1(dispatch, {
                  TAG: /* GlobalDiscountAdded */18,
                  _0: {
                    id: undefined,
                    name: "",
                    kind: /* Percent */1,
                    value: value,
                    quantity: 0
                  }
                });
          } else if (value !== 0) {
            var match$1 = discount.kind;
            var tmp;
            var exit = 0;
            switch (match$1) {
              case /* Currency */0 :
                  tmp = {
                    name: discount.name,
                    kind: discount.kind,
                    value: value,
                    quantity: discount.quantity,
                    id: discount.id,
                    formattedValue: discount.formattedValue,
                    amount: discount.amount,
                    formattedAmount: discount.formattedAmount,
                    warnings: discount.warnings
                  };
                  break;
              case /* Percent */1 :
                  if (value <= 100) {
                    tmp = {
                      name: discount.name,
                      kind: discount.kind,
                      value: value,
                      quantity: discount.quantity,
                      id: discount.id,
                      formattedValue: discount.formattedValue,
                      amount: discount.amount,
                      formattedAmount: discount.formattedAmount,
                      warnings: discount.warnings
                    };
                  } else {
                    exit = 1;
                  }
                  break;
              case /* Free */2 :
                  exit = 1;
                  break;
              
            }
            if (exit === 1) {
              tmp = {
                name: discount.name,
                kind: discount.kind,
                value: 0,
                quantity: discount.quantity,
                id: discount.id,
                formattedValue: discount.formattedValue,
                amount: discount.amount,
                formattedAmount: discount.formattedAmount,
                warnings: discount.warnings
              };
            }
            Curry._1(dispatch, {
                  TAG: /* GlobalDiscountUpdated */20,
                  _0: discount.id,
                  _1: tmp
                });
          } else {
            Curry._1(dispatch, {
                  TAG: /* GlobalDiscountRemoved */19,
                  _0: discount.id
                });
          }
        }), [value]);
  var onGlobalDiscountKindChange = React.useCallback((function (kind) {
          var match = discount.id;
          if (match === "") {
            return Curry._1(dispatch, {
                        TAG: /* GlobalDiscountAdded */18,
                        _0: {
                          id: undefined,
                          name: "",
                          kind: kind,
                          value: 0,
                          quantity: 0
                        }
                      });
          } else {
            return Curry._1(dispatch, {
                        TAG: /* GlobalDiscountUpdated */20,
                        _0: discount.id,
                        _1: {
                          name: discount.name,
                          kind: kind,
                          value: 0,
                          quantity: discount.quantity,
                          id: discount.id,
                          formattedValue: discount.formattedValue,
                          amount: discount.amount,
                          formattedAmount: discount.formattedAmount,
                          warnings: discount.warnings
                        }
                      });
          }
        }), [discount]);
  var match$1 = discount.kind;
  var tmp;
  if (match$1 >= 2) {
    tmp = null;
  } else {
    var tmp$1 = {
      value: discount.value,
      minValue: 0,
      precision: discount.kind === /* Percent */1 ? 2 : decimalPrecision,
      label: $$Intl.t("Global discount"),
      appender: discount.kind === /* Percent */1 ? /* Percent */0 : ({
            TAG: /* Currency */0,
            _0: "EUR"
          }),
      onChange: (function (value) {
          Curry._1(setValue, (function (param) {
                  return value;
                }));
        })
    };
    var tmp$2 = discount.kind === /* Percent */1 ? 100 : undefined;
    if (tmp$2 !== undefined) {
      tmp$1.maxValue = tmp$2;
    }
    var match$2 = discount.warnings;
    var tmp$3;
    if (match$2.length !== 1) {
      tmp$3 = null;
    } else {
      var warning = match$2[0];
      tmp$3 = React.createElement(TextStyle.make, {
            children: $$Intl.template(CartDiscount__Utils.discountWarningToText(warning), {
                  max: warningMessage
                }, undefined),
            size: "xsmall"
          });
    }
    tmp = React.createElement(Stack.make, {
          children: null,
          space: "xsmall"
        }, React.createElement(InputNumberField.make, tmp$1), tmp$3);
  }
  return React.createElement(Columns.make, {
              children: null,
              space: "medium"
            }, React.createElement(Column.make, {
                  children: tmp,
                  width: "content"
                }), React.createElement(Column.make, {
                  children: React.createElement(InputSegmentedControlsField.make, {
                        label: $$Intl.t("Type"),
                        compact: true,
                        value: discount.kind,
                        required: false,
                        onChange: Curry.__1(onGlobalDiscountKindChange),
                        options: {
                          hd: /* Percent */1,
                          tl: {
                            hd: /* Currency */0,
                            tl: /* [] */0
                          }
                        },
                        optionToText: CartDiscount__Utils.kindToText
                      }),
                  width: "content"
                }));
}

var make = React.memo(CartGlobalDiscountView, (function (oldProps, newProps) {
        if (Caml_obj.equal(oldProps.discount, newProps.discount) && oldProps.warningMessage === newProps.warningMessage) {
          return oldProps.decimalPrecision === newProps.decimalPrecision;
        } else {
          return false;
        }
      }));

var Utils;

export {
  Utils ,
  defaultDiscount ,
  make ,
}
/* defaultDiscount Not a pure module */
