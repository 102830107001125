// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as React from "react";
import * as Popover from "../../resources/overlays/Popover.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as OpeningButton from "../../resources/actions/OpeningButton.bs.js";
import * as ProductStockTableCell from "../Product/ProductStockTableCell.bs.js";
import * as CatalogVariantStockThresholdFormPopover from "./CatalogVariantStockThresholdFormPopover.bs.js";

function CatalogVariantStockThresholdCell(Props) {
  var compactOpt = Props.compact;
  var stockQuantity = Props.stockQuantity;
  var stockState = Props.stockState;
  var formattedShopsNames = Props.formattedShopsNames;
  var id = Props.id;
  var maxStockThreshold = Props.maxStockThreshold;
  var minStockThreshold = Props.minStockThreshold;
  var stockOrderTriggerThreshold = Props.stockOrderTriggerThreshold;
  var bulk = Props.bulk;
  var compact = compactOpt !== undefined ? compactOpt : false;
  var match = Popover.useTrigger(undefined, undefined);
  var popoverTriggerRef = match.ref;
  var popoverAriaProps = match.ariaProps;
  var popover = match.state;
  var match$1 = Hover.use(popoverTriggerRef, undefined);
  var triggerHovered = match$1[1];
  var popover_isOpen = popover.isOpen;
  var popover_close = function (param) {
    if (!triggerHovered) {
      return Curry._1(popover.close, undefined);
    }
    
  };
  var popover_toggle = popover.toggle;
  var popover$1 = {
    isOpen: popover_isOpen,
    close: popover_close,
    toggle: popover_toggle
  };
  var match$2 = Auth.useScope(undefined);
  var exit = 0;
  if (match$2.TAG === /* Organisation */0) {
    if (match$2.activeShop !== undefined) {
      exit = bulk ? 2 : 1;
    } else {
      var tmp = {
        value: stockQuantity,
        size: compact ? "xsmall" : "normal"
      };
      if (stockState !== undefined) {
        tmp.state = Caml_option.valFromOption(stockState);
      }
      if (formattedShopsNames !== undefined) {
        tmp.formattedShopsNames = formattedShopsNames;
      }
      return React.createElement(ProductStockTableCell.make, tmp);
    }
  } else {
    exit = bulk ? 2 : 1;
  }
  switch (exit) {
    case 1 :
        var tmp$1 = {
          value: stockQuantity,
          size: compact ? "xsmall" : "normal"
        };
        if (stockState !== undefined) {
          tmp$1.state = Caml_option.valFromOption(stockState);
        }
        return React.createElement(React.Fragment, undefined, React.createElement(OpeningButton.make, OpeningButton.makeProps(React.createElement(ProductStockTableCell.make, tmp$1), popoverAriaProps.triggerProps, popover_isOpen, undefined, (function (param) {
                              Curry._1(popover_toggle, undefined);
                            }), undefined, popoverTriggerRef, undefined)), popover_isOpen ? React.createElement(CatalogVariantStockThresholdFormPopover.make, {
                          popover: popover$1,
                          popoverAriaProps: popoverAriaProps,
                          popoverTriggerRef: popoverTriggerRef,
                          variantId: id,
                          maxStockThreshold: maxStockThreshold,
                          minStockThreshold: minStockThreshold,
                          stockOrderTriggerThreshold: stockOrderTriggerThreshold
                        }) : null);
    case 2 :
        var tmp$2 = {
          value: stockQuantity,
          size: compact ? "xsmall" : "normal"
        };
        if (stockState !== undefined) {
          tmp$2.state = Caml_option.valFromOption(stockState);
        }
        return React.createElement(React.Fragment, undefined, React.createElement(ProductStockTableCell.make, tmp$2));
    
  }
}

var make = React.memo(CatalogVariantStockThresholdCell);

export {
  make ,
}
/* make Not a pure module */
