// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as React from "react";
import * as TableView from "../../resources/tables/TableView.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CatalogProductStatusBadge from "./CatalogProductStatusBadge.bs.js";

function keyExtractor(row) {
  return row.id + "-status";
}

var Row = {
  keyExtractor: keyExtractor
};

var columns = [
  {
    key: "shopname",
    name: $$Intl.t("Shop/Warehouse"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 150
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle.make, {
                    children: param.data.shopName
                  });
      })
  },
  {
    key: "status",
    name: $$Intl.t("Status"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 110
      },
      width: {
        NAME: "fr",
        VAL: 0
      }
    },
    render: (function (param) {
        return React.createElement(CatalogProductStatusBadge.make, {
                    value: param.data.status
                  });
      })
  }
];

function CatalogProductStatusTableCard(Props) {
  var shopsProduct = Props.shopsProduct;
  var rows = Belt_Array.map(shopsProduct, (function (product) {
          return {
                  id: product.id,
                  shopName: product.shopName,
                  status: product.status
                };
        }));
  return React.createElement(Card.make, {
              children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TableView.make, {
                                data: {
                                  TAG: /* Done */1,
                                  _0: {
                                    TAG: /* Ok */0,
                                    _0: rows
                                  }
                                },
                                columns: columns,
                                keyExtractor: keyExtractor,
                                hideCard: true
                              })), undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
              title: $$Intl.t("Statuses"),
              variation: "table"
            });
}

var make = React.memo(CatalogProductStatusTableCard);

var Config;

export {
  Config ,
  Row ,
  columns ,
  make ,
}
/* columns Not a pure module */
