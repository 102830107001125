// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "../../primitives/Json.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Auth__Fetcher from "./Auth__Fetcher.bs.js";

function encodeUser(user) {
  var dict = Js_dict.fromArray([
        [
          "organizationName",
          Json.encodeString(user.organizationName)
        ],
        [
          "id",
          Json.encodeString(user.id)
        ],
        [
          "name",
          Json.encodeString(user.name)
        ],
        [
          "username",
          Json.encodeString(user.username)
        ],
        [
          "canUseImpersonation",
          Json.encodeBoolean(user.canUseImpersonation)
        ],
        [
          "impersonating",
          Json.encodeBoolean(user.impersonating)
        ]
      ]);
  var profilePictureUri = user.profilePictureUri;
  if (profilePictureUri !== undefined) {
    dict["profilePictureUri"] = Json.encodeString(profilePictureUri);
  }
  return Json.encodeDict(dict);
}

function decodeUser(json) {
  var dict = Json.decodeDict(json);
  if (dict === undefined) {
    return ;
  }
  var dict$1 = Caml_option.valFromOption(dict);
  var user;
  try {
    user = {
      id: Json.decodeDictFieldExn(dict$1, "id", Json.decodeString),
      name: Json.decodeDictFieldExn(dict$1, "name", Json.decodeString),
      organizationName: Json.decodeDictFieldExn(dict$1, "organizationName", Json.decodeString),
      profilePictureUri: Json.decodeDictOptionalField(dict$1, "profilePictureUri", Json.decodeString),
      username: Json.decodeDictFieldExn(dict$1, "username", Json.decodeString),
      canUseImpersonation: Belt_Option.getWithDefault(Json.decodeDictOptionalField(dict$1, "canUseImpersonation", Json.decodeBoolean), false),
      impersonating: Belt_Option.getWithDefault(Json.decodeDictOptionalField(dict$1, "impersonating", Json.decodeBoolean), false)
    };
  }
  catch (exn){
    return ;
  }
  return user;
}

function encodeShop(shop) {
  var dict = {};
  dict["name"] = Json.encodeString(shop.name);
  dict["id"] = Json.encodeString(shop.id);
  dict["corporateName"] = Json.encodeString(shop.corporateName);
  dict["activeWebDeviceId"] = Json.encodeString(shop.activeWebDeviceId);
  var match = shop.kind;
  dict["kind"] = Json.encodeString(match === "WAREHOUSE" ? "WAREHOUSE" : (
          match === "INTEGRATED" ? "INTEGRATED" : (
              match === "INDEPENDENT" ? "INDEPENDENT" : (
                  match === "AFFILIATED" ? "AFFILIATED" : "FRANCHISED"
                )
            )
        ));
  dict["address"] = Json.encodeString(shop.address);
  dict["postalCode"] = Json.encodeString(shop.postalCode);
  dict["city"] = Json.encodeString(shop.city);
  dict["country"] = Json.encodeString(shop.country);
  dict["phoneNumber"] = Json.encodeString(shop.phoneNumber);
  dict["email"] = Json.encodeString(shop.email);
  var legalRepresentative = shop.legalRepresentative;
  if (legalRepresentative !== undefined) {
    dict["legalRepresentative"] = Json.encodeString(legalRepresentative);
  }
  var bankName = shop.bankName;
  if (bankName !== undefined) {
    dict["bankName"] = Json.encodeString(bankName);
  }
  var cityOfRegistryOffice = shop.cityOfRegistryOffice;
  if (cityOfRegistryOffice !== undefined) {
    dict["cityOfRegistryOffice"] = Json.encodeString(cityOfRegistryOffice);
  }
  var website = shop.website;
  if (website !== undefined) {
    dict["website"] = Json.encodeString(website);
  }
  var fiscalYearEndClosingMonth = shop.fiscalYearEndClosingMonth;
  if (fiscalYearEndClosingMonth !== undefined) {
    dict["fiscalYearEndClosingMonth"] = Json.encodeString(fiscalYearEndClosingMonth);
  }
  var legalForm = shop.legalForm;
  if (legalForm !== undefined) {
    dict["legalForm"] = Json.encodeString(legalForm);
  }
  var amountOfShareCapital = shop.amountOfShareCapital;
  if (amountOfShareCapital !== undefined) {
    dict["amountOfShareCapital"] = Json.encodeString(amountOfShareCapital);
  }
  var tvaNumber = shop.tvaNumber;
  if (tvaNumber !== undefined) {
    dict["tvaNumber"] = Json.encodeString(tvaNumber);
  }
  var siretNumber = shop.siretNumber;
  if (siretNumber !== undefined) {
    dict["siretNumber"] = Json.encodeString(siretNumber);
  }
  var rcsNumber = shop.rcsNumber;
  if (rcsNumber !== undefined) {
    dict["rcsNumber"] = Json.encodeString(rcsNumber);
  }
  var apeNafCode = shop.apeNafCode;
  if (apeNafCode !== undefined) {
    dict["apeNafCode"] = Json.encodeString(apeNafCode);
  }
  var bankCode = shop.bankCode;
  if (bankCode !== undefined) {
    dict["bankCode"] = Json.encodeString(bankCode);
  }
  var bankAccountHolder = shop.bankAccountHolder;
  if (bankAccountHolder !== undefined) {
    dict["bankAccountHolder"] = Json.encodeString(bankAccountHolder);
  }
  var bankAccountNumber = shop.bankAccountNumber;
  if (bankAccountNumber !== undefined) {
    dict["bankAccountNumber"] = Json.encodeString(bankAccountNumber);
  }
  var bicCode = shop.bicCode;
  if (bicCode !== undefined) {
    dict["bicCode"] = Json.encodeString(bicCode);
  }
  var ibanNumber = shop.ibanNumber;
  if (ibanNumber !== undefined) {
    dict["ibanNumber"] = Json.encodeString(ibanNumber);
  }
  var logoUri = shop.logoUri;
  if (logoUri !== undefined) {
    dict["logoUri"] = Json.encodeString(logoUri);
  }
  return Json.encodeDict(dict);
}

function decodeShopKind(value) {
  var match = Json.decodeString(value);
  if (match === undefined) {
    return "INDEPENDENT";
  }
  switch (match) {
    case "AFFILIATED" :
        return "AFFILIATED";
    case "FRANCHISED" :
        return "FRANCHISED";
    case "INTEGRATED" :
        return "INTEGRATED";
    case "WAREHOUSE" :
        return "WAREHOUSE";
    default:
      return "INDEPENDENT";
  }
}

function decodeShop(json) {
  var dict = Json.decodeDict(json);
  if (dict === undefined) {
    return ;
  }
  var dict$1 = Caml_option.valFromOption(dict);
  var shop;
  try {
    shop = {
      id: Json.decodeDictFieldExn(dict$1, "id", Json.decodeString),
      kind: Belt_Option.getWithDefault(Json.decodeDictOptionalField(dict$1, "kind", decodeShopKind), "INDEPENDENT"),
      name: Json.decodeDictFieldExn(dict$1, "name", Json.decodeString),
      corporateName: Json.decodeDictFieldExn(dict$1, "corporateName", Json.decodeString),
      address: Json.decodeDictFieldExn(dict$1, "address", Json.decodeString),
      postalCode: Json.decodeDictFieldExn(dict$1, "postalCode", Json.decodeString),
      city: Json.decodeDictFieldExn(dict$1, "city", Json.decodeString),
      country: Json.decodeDictFieldExn(dict$1, "country", Json.decodeString),
      phoneNumber: Json.decodeDictFieldExn(dict$1, "phoneNumber", Json.decodeString),
      email: Json.decodeDictFieldExn(dict$1, "email", Json.decodeString),
      legalRepresentative: Json.decodeDictOptionalField(dict$1, "legalRepresentative", Json.decodeString),
      logoUri: Json.decodeDictOptionalField(dict$1, "logoUri", Json.decodeString),
      activeWebDeviceId: Json.decodeDictFieldExn(dict$1, "activeWebDeviceId", Json.decodeString),
      bankName: Json.decodeDictOptionalField(dict$1, "bankName", Json.decodeString),
      cityOfRegistryOffice: Json.decodeDictOptionalField(dict$1, "cityOfRegistryOffice", Json.decodeString),
      website: Json.decodeDictOptionalField(dict$1, "website", Json.decodeString),
      fiscalYearEndClosingMonth: Json.decodeDictOptionalField(dict$1, "fiscalYearEndClosingMonth", Json.decodeString),
      legalForm: Json.decodeDictOptionalField(dict$1, "legalForm", Json.decodeString),
      amountOfShareCapital: Json.decodeDictOptionalField(dict$1, "amountOfShareCapital", Json.decodeString),
      tvaNumber: Json.decodeDictOptionalField(dict$1, "tvaNumber", Json.decodeString),
      siretNumber: Json.decodeDictOptionalField(dict$1, "siretNumber", Json.decodeString),
      rcsNumber: Json.decodeDictOptionalField(dict$1, "rcsNumber", Json.decodeString),
      apeNafCode: Json.decodeDictOptionalField(dict$1, "apeNafCode", Json.decodeString),
      bankCode: Json.decodeDictOptionalField(dict$1, "bankCode", Json.decodeString),
      bankAccountHolder: Json.decodeDictOptionalField(dict$1, "bankAccountHolder", Json.decodeString),
      bankAccountNumber: Json.decodeDictOptionalField(dict$1, "bankAccountNumber", Json.decodeString),
      bicCode: Json.decodeDictOptionalField(dict$1, "bicCode", Json.decodeString),
      ibanNumber: Json.decodeDictOptionalField(dict$1, "ibanNumber", Json.decodeString)
    };
  }
  catch (_exn){
    return ;
  }
  return shop;
}

function encode(state) {
  if (typeof state === "number") {
    return ;
  }
  if (state.TAG === /* Logging */0) {
    return ;
  }
  var auth = state._0;
  var json = {};
  json["user"] = encodeUser(auth.user);
  var shops = Belt_Array.map(auth.shops, encodeShop);
  json["shops"] = Json.encodeArray(shops);
  var shop = auth.activeShop;
  if (shop !== undefined) {
    json["activeShop"] = encodeShop(shop);
  }
  return Caml_option.some(Json.encodeDict(json));
}

function stringify(state) {
  return Belt_Option.map(encode(state), Json.stringify);
}

function decode(json) {
  var auth = Json.decodeDict(json);
  var user = Json.flatDecodeDictField(auth, "user", decodeUser);
  var shops = Belt_Option.getWithDefault(Belt_Option.map(Json.flatDecodeDictField(auth, "shops", Json.decodeArray), (function (shops) {
              return Belt_Array.keepMap(shops, decodeShop);
            })), []);
  var activeShop = Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(auth, (function (json) {
                  return Js_dict.get(json, "activeShop");
                })), decodeShop), (function (activeShop) {
          return Belt_Array.getBy(shops, (function (shop) {
                        return shop.id === activeShop.id;
                      }));
        }));
  if (user !== undefined && shops.length !== 0) {
    return {
            TAG: /* Ok */0,
            _0: {
              user: user,
              shops: shops,
              activeShop: activeShop
            }
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
}

function parse(string) {
  var json;
  try {
    json = Json.parseExn(string);
  }
  catch (exn){
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
  return decode(json);
}

function make(state, action) {
  if (typeof action === "number") {
    return {
            TAG: /* Update */0,
            _0: /* Unlogged */0
          };
  }
  switch (action.TAG | 0) {
    case /* LogRequested */0 :
        var impersonate = action.impersonate;
        var jwt = action.jwt;
        var tmp;
        tmp = typeof state === "number" || state.TAG === /* Logging */0 || impersonate !== undefined && impersonate ? ({
              TAG: /* Logging */0,
              jwt: jwt,
              impersonating: impersonate
            }) : state;
        return {
                TAG: /* UpdateWithSideEffects */1,
                _0: tmp,
                _1: (function (self) {
                    Future.get(Future.all2([
                              Auth__Fetcher.fetchUser(jwt),
                              Auth__Fetcher.fetchShopsWithWebDevice(jwt)
                            ]), (function (result) {
                            if (typeof state !== "number" && state.TAG !== /* Logging */0) {
                              var user = result[0];
                              if (user.TAG !== /* Ok */0) {
                                return Curry._1(self.dispatch, /* LogFailed */0);
                              }
                              var shops = result[1];
                              if (shops.TAG !== /* Ok */0) {
                                return Curry._1(self.dispatch, /* LogFailed */0);
                              }
                              var shops$1 = shops._0;
                              var match = state._0.activeShop;
                              var tmp;
                              var exit = 0;
                              if (match !== undefined && Belt_Array.some(shops$1, (function (param) {
                                        return param.id === match.id;
                                      }))) {
                                tmp = match;
                              } else {
                                exit = 2;
                              }
                              if (exit === 2) {
                                tmp = shops$1.length !== 1 ? undefined : shops$1[0];
                              }
                              return Curry._1(self.dispatch, {
                                          TAG: /* LogSucceeded */1,
                                          _0: {
                                            user: user._0,
                                            shops: shops$1,
                                            activeShop: tmp
                                          }
                                        });
                            }
                            var user$1 = result[0];
                            if (user$1.TAG !== /* Ok */0) {
                              return Curry._1(self.dispatch, /* LogFailed */0);
                            }
                            var shops$2 = result[1];
                            if (shops$2.TAG !== /* Ok */0) {
                              return Curry._1(self.dispatch, /* LogFailed */0);
                            }
                            var shops$3 = shops$2._0;
                            Curry._1(self.dispatch, {
                                  TAG: /* LogSucceeded */1,
                                  _0: {
                                    user: user$1._0,
                                    shops: shops$3,
                                    activeShop: shops$3.length !== 1 ? undefined : shops$3[0]
                                  }
                                });
                          }));
                  })
              };
    case /* LogSucceeded */1 :
        var auth = action._0;
        if (typeof state === "number" || state.TAG === /* Logging */0 || Belt_Option.map(encode(state), Json.stringify) !== Belt_Option.map(encode({
                    TAG: /* Logged */1,
                    _0: auth
                  }), Json.stringify)) {
          return {
                  TAG: /* Update */0,
                  _0: {
                    TAG: /* Logged */1,
                    _0: auth
                  }
                };
        } else {
          return /* NoUpdate */0;
        }
    case /* ActiveShopSet */2 :
        if (typeof state === "number") {
          return /* NoUpdate */0;
        }
        if (state.TAG === /* Logging */0) {
          return /* NoUpdate */0;
        }
        var auth$1 = state._0;
        return {
                TAG: /* Update */0,
                _0: {
                  TAG: /* Logged */1,
                  _0: {
                    user: auth$1.user,
                    shops: auth$1.shops,
                    activeShop: action._0
                  }
                }
              };
    
  }
}

var fetchUser = Auth__Fetcher.fetchUser;

var fetchShopsWithWebDevice = Auth__Fetcher.fetchShopsWithWebDevice;

export {
  fetchUser ,
  fetchShopsWithWebDevice ,
  encodeUser ,
  decodeUser ,
  encodeShop ,
  decodeShopKind ,
  decodeShop ,
  encode ,
  stringify ,
  decode ,
  parse ,
  make ,
}
/* Json Not a pure module */
