// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as StackFields from "../../resources/layout-and-structure/StackFields.bs.js";
import * as CatalogVariant from "./CatalogVariant.bs.js";
import * as FieldsetLayoutPanel from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";
import * as CatalogVariantEditForm from "./CatalogVariantEditForm.bs.js";

function CatalogVariantEditFormStockFieldsetPanel(Props) {
  var match = Curry._1(CatalogVariantEditForm.useFormState, undefined).values;
  var bulk = match.bulk;
  var capacityUnit = match.capacityUnit;
  var tmp = {
    field: /* InitialStockQuantity */14,
    label: $$Intl.t("Initial stock"),
    minValue: 0,
    precision: bulk ? CatalogVariant.defaultBulkCapacityPrecision : 0
  };
  var tmp$1 = capacityUnit !== undefined && bulk ? ({
        TAG: /* Custom */1,
        _0: capacityUnit
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.appender = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(FieldsetLayoutPanel.make, {
              title: $$Intl.t("Stock"),
              description: $$Intl.t("Indicate the initial stock quantity of the reference or leave the stock at zero and create an order for the reception of the product."),
              children: null
            }, React.createElement(CatalogVariantEditForm.InputNumber.make, tmp), React.createElement(CatalogVariantEditForm.InputTextArea.make, {
                  field: /* InitialStockComment */15,
                  label: $$Intl.t("Comment")
                }), bulk ? null : React.createElement(StackFields.make, {
                    children: null
                  }, React.createElement(CatalogVariantEditForm.InputNumber.make, {
                        field: /* MaxStockThreshold */16,
                        label: $$Intl.t("Maximum stock"),
                        hideRequired: true,
                        minValue: 0,
                        precision: 0
                      }), React.createElement(CatalogVariantEditForm.InputNumber.make, {
                        field: /* MinStockThreshold */17,
                        label: $$Intl.t("Minimum stock"),
                        hideRequired: true,
                        minValue: 0,
                        precision: 0
                      }), React.createElement(CatalogVariantEditForm.InputNumber.make, {
                        field: /* StockOrderTriggerThreshold */18,
                        label: $$Intl.t("Stock triggering the order"),
                        hideRequired: true,
                        minValue: 0,
                        precision: 0
                      })));
}

var make = React.memo(CatalogVariantEditFormStockFieldsetPanel);

export {
  make ,
}
/* make Not a pure module */
