// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";

function optionToText(option) {
  switch (option) {
    case /* Transport */0 :
        return $$Intl.t("Transport");
    case /* Taxes */1 :
        return $$Intl.t("Taxes");
    case /* Other */2 :
        return $$Intl.t("Other");
    
  }
}

export {
  optionToText ,
}
/* Intl Not a pure module */
