// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var UnknownStringValue = /* @__PURE__ */Caml_exceptions.create("SupplierStatus.UnknownStringValue");

function toLabel(x) {
  if (x === "UNARCHIVED") {
    return $$Intl.t("Not archived");
  } else {
    return $$Intl.t("Archived");
  }
}

function toString(status) {
  return status;
}

function fromString(value) {
  switch (value) {
    case "ARCHIVED" :
        return "ARCHIVED";
    case "UNARCHIVED" :
        return "UNARCHIVED";
    default:
      throw {
            RE_EXN_ID: UnknownStringValue,
            Error: new Error()
          };
  }
}

var values = [
  "ARCHIVED",
  "UNARCHIVED"
];

export {
  UnknownStringValue ,
  toLabel ,
  toString ,
  fromString ,
  values ,
}
/* Intl Not a pure module */
