// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Form__Core from "./Form__Core.bs.js";
import * as Form__Elements__Inputs from "./Form__Elements__Inputs.bs.js";
import * as Form__Elements__Buttons from "./Form__Elements__Buttons.bs.js";
import * as Form__Elements__Accessibility from "./Form__Elements__Accessibility.bs.js";

function Make(FormLenses) {
  var Core = Form__Core.Make(FormLenses);
  var include = Form__Elements__Inputs.Make({
        useField: Core.useField
      });
  var include$1 = Form__Elements__Buttons.Make({
        useSubmit: Core.useSubmit,
        useCancel: Core.useCancel,
        useReset: Core.useReset
      });
  var include$2 = Form__Elements__Accessibility.Make({
        useFormValues: Core.useFormValues,
        useFormStatus: Core.useFormStatus,
        useSubmit: Core.useSubmit
      });
  var validate = Core.Schema.validate;
  return {
          Core: Core,
          Schema: undefined,
          FormLegacyProvider: undefined,
          FormProvider: undefined,
          InputText: include.InputText,
          InputSuggestionComboBox: include.InputSuggestionComboBox,
          InputSelect: include.InputSelect,
          InputTextArea: include.InputTextArea,
          InputPassword: include.InputPassword,
          InputNumber: include.InputNumber,
          InputOptionalNumber: include.InputOptionalNumber,
          InputSegmentedControls: include.InputSegmentedControls,
          InputRadioGroup: include.InputRadioGroup,
          InputPhone: include.InputPhone,
          InputCheckbox: include.InputCheckbox,
          InputToggleSwitch: include.InputToggleSwitch,
          InputDate: include.InputDate,
          InputDateRange: include.InputDateRange,
          SubmitButton: include$1.SubmitButton,
          CancelButton: include$1.CancelButton,
          ResetButton: include$1.ResetButton,
          ControlEnterKey: include$2.ControlEnterKey,
          AutoSave: include$2.AutoSave,
          useFormPropState: Core.useFormPropState,
          useField: Core.useField,
          useFormState: Core.useFormState,
          useFormDispatch: Core.useFormDispatch,
          validate: validate
        };
}

var Status;

var Submission;

export {
  Status ,
  Submission ,
  Make ,
}
/* Form__Core Not a pure module */
