// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as Form from "../../bundles/Form/Form.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Menu from "../../resources/navigation/Menu.bs.js";
import * as Badge from "../../resources/feedback-indicators/Badge.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Column from "../../resources/layout-and-structure/Column.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Columns from "../../resources/layout-and-structure/Columns.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as MenuItem from "../../resources/navigation/MenuItem.bs.js";
import * as TableView from "../../resources/tables/TableView.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as InlineText from "../../resources/layout-and-structure/InlineText.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as AsyncResult from "../../primitives/AsyncResult.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Placeholder from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as Form__Status from "../../bundles/Form/Form__Status.bs.js";
import * as LegacyRouter from "../../core/LegacyRouter.bs.js";
import * as ApolloHelpers from "../../helpers/ApolloHelpers.bs.js";
import * as TableDateCell from "../../resources/tables/TableDateCell.bs.js";
import * as Client from "@apollo/client";
import * as ValueIndicator from "../../resources/data-visualizations/ValueIndicator.bs.js";
import * as AmountTableCell from "../../modules/AmountTableCell.bs.js";
import * as ShowAllDataLink from "../../resources/navigation/ShowAllDataLink.bs.js";
import * as SupplierCivility from "../../modules/Supplier/SupplierCivility.bs.js";
import * as OrderStatusBadges from "../../modules/Order/OrderStatusBadges.bs.js";
import * as OrderNameTableCell from "../../modules/Order/OrderNameTableCell.bs.js";
import * as OrderUrlQueryString from "../../modules/Order/OrderUrlQueryString.bs.js";
import * as ResourceDetailsPage from "../../core/ResourceDetailsPage.bs.js";
import * as SupplierArchiveModal from "../../modules/Supplier/SupplierArchiveModal.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = Client.gql(["query SupplierShowPageQuery($id: ID!, $ordersFirst: Int)  {\nsupplier(id: $id)  {\n__typename  \nid  \ncompanyName  \nupdatedAt  \narchivedAt  \nintraCommunityVat  \ninternalCode  \nphoneNumber  \nmobileNumber  \nemail  \nnote  \nsiretNumber  \nformattedOrdersTotalAmountIncludingTaxes  \nshop  {\n__typename  \nid  \nname  \n}\n\ncontacts  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nposition  \ncivility  \nfirstName  \nlastName  \nformattedName  \nmobileNumber  \nphoneNumber  \nemail  \n}\n\n}\n\n}\n\nlocations  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nlabel  \nrecipient  \naddress  \npostalCode  \ncity  \ncountry  \n}\n\n}\n\n}\n\norders(first: $ordersFirst)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nformattedStatus  \nformattedName  \nestimatedReceptionDate  \nreceptionFinishedAt  \ntotalAmountExcludingTaxes  \ntotalAmountIncludingTaxes  \ntotalProductsQuantity  \nshop  {\n__typename  \nname  \n}\n\n}\n\n}\n\npageInfo  {\n__typename  \nhasNextPage  \n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.supplier;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.archivedAt;
    var value$3 = value$1.intraCommunityVat;
    var value$4 = value$1.internalCode;
    var value$5 = value$1.phoneNumber;
    var value$6 = value$1.mobileNumber;
    var value$7 = value$1.email;
    var value$8 = value$1.note;
    var value$9 = value$1.siretNumber;
    var value$10 = value$1.shop;
    var value$11 = value$1.contacts;
    var value$12 = value$11.edges;
    var value$13 = value$1.locations;
    var value$14 = value$13.edges;
    var value$15 = value$1.orders;
    var value$16 = value$15.edges;
    var value$17 = value$15.pageInfo;
    var value$18 = value$17.hasNextPage;
    tmp = {
      __typename: value$1.__typename,
      id: value$1.id,
      companyName: value$1.companyName,
      updatedAt: Scalar.Datetime.parse(value$1.updatedAt),
      archivedAt: !(value$2 == null) ? Caml_option.some(Scalar.Datetime.parse(value$2)) : undefined,
      intraCommunityVat: !(value$3 == null) ? value$3 : undefined,
      internalCode: !(value$4 == null) ? value$4 : undefined,
      phoneNumber: !(value$5 == null) ? value$5 : undefined,
      mobileNumber: !(value$6 == null) ? value$6 : undefined,
      email: !(value$7 == null) ? value$7 : undefined,
      note: !(value$8 == null) ? Scalar.$$Text.parse(value$8) : undefined,
      siretNumber: !(value$9 == null) ? value$9 : undefined,
      formattedOrdersTotalAmountIncludingTaxes: value$1.formattedOrdersTotalAmountIncludingTaxes,
      shop: {
        __typename: value$10.__typename,
        id: value$10.id,
        name: value$10.name
      },
      contacts: {
        __typename: value$11.__typename,
        edges: Js_array.map((function (value) {
                var value$1 = value.node;
                var value$2 = value$1.position;
                var value$3 = value$1.civility;
                var tmp;
                if (value$3 == null) {
                  tmp = undefined;
                } else {
                  var tmp$1;
                  switch (value$3) {
                    case "MR" :
                        tmp$1 = "MR";
                        break;
                    case "MRS" :
                        tmp$1 = "MRS";
                        break;
                    case "NEUTRAL" :
                        tmp$1 = "NEUTRAL";
                        break;
                    default:
                      throw {
                            RE_EXN_ID: "Not_found",
                            Error: new Error()
                          };
                  }
                  tmp = tmp$1;
                }
                var value$4 = value$1.firstName;
                var value$5 = value$1.mobileNumber;
                var value$6 = value$1.phoneNumber;
                var value$7 = value$1.email;
                return {
                        __typename: value.__typename,
                        node: {
                          __typename: value$1.__typename,
                          position: !(value$2 == null) ? value$2 : undefined,
                          civility: tmp,
                          firstName: !(value$4 == null) ? value$4 : undefined,
                          lastName: value$1.lastName,
                          formattedName: value$1.formattedName,
                          mobileNumber: !(value$5 == null) ? value$5 : undefined,
                          phoneNumber: !(value$6 == null) ? value$6 : undefined,
                          email: !(value$7 == null) ? value$7 : undefined
                        }
                      };
              }), value$12)
      },
      locations: {
        __typename: value$13.__typename,
        edges: Js_array.map((function (value) {
                var value$1 = value.node;
                var value$2 = value$1.recipient;
                var value$3 = value$1.address;
                var value$4 = value$1.postalCode;
                var value$5 = value$1.city;
                var value$6 = value$1.country;
                return {
                        __typename: value.__typename,
                        node: {
                          __typename: value$1.__typename,
                          label: value$1.label,
                          recipient: !(value$2 == null) ? value$2 : undefined,
                          address: !(value$3 == null) ? value$3 : undefined,
                          postalCode: !(value$4 == null) ? value$4 : undefined,
                          city: !(value$5 == null) ? value$5 : undefined,
                          country: !(value$6 == null) ? value$6 : undefined
                        }
                      };
              }), value$14)
      },
      orders: {
        __typename: value$15.__typename,
        edges: Js_array.map((function (value) {
                var value$1 = value.node;
                var value$2 = value$1.formattedStatus;
                var value$3 = value$1.receptionFinishedAt;
                var value$4 = value$1.shop;
                return {
                        __typename: value.__typename,
                        node: {
                          __typename: value$1.__typename,
                          id: value$1.id,
                          formattedStatus: Js_array.map((function (value) {
                                  switch (value) {
                                    case "ACCEPTED" :
                                        return "ACCEPTED";
                                    case "ARCHIVED" :
                                        return "ARCHIVED";
                                    case "DRAFT" :
                                        return "DRAFT";
                                    case "FINALIZED" :
                                        return "FINALIZED";
                                    case "NOT_RECEIVED" :
                                        return "NOT_RECEIVED";
                                    case "PAID" :
                                        return "PAID";
                                    case "RECEIVED" :
                                        return "RECEIVED";
                                    case "RECEIVING" :
                                        return "RECEIVING";
                                    case "REFUSED" :
                                        return "REFUSED";
                                    case "SENT" :
                                        return "SENT";
                                    case "TO_PAY" :
                                        return "TO_PAY";
                                    default:
                                      throw {
                                            RE_EXN_ID: "Not_found",
                                            Error: new Error()
                                          };
                                  }
                                }), value$2),
                          formattedName: value$1.formattedName,
                          estimatedReceptionDate: Scalar.Datetime.parse(value$1.estimatedReceptionDate),
                          receptionFinishedAt: !(value$3 == null) ? Caml_option.some(Scalar.Datetime.parse(value$3)) : undefined,
                          totalAmountExcludingTaxes: value$1.totalAmountExcludingTaxes,
                          totalAmountIncludingTaxes: value$1.totalAmountIncludingTaxes,
                          totalProductsQuantity: value$1.totalProductsQuantity,
                          shop: {
                            __typename: value$4.__typename,
                            name: value$4.name
                          }
                        }
                      };
              }), value$16),
        pageInfo: {
          __typename: value$17.__typename,
          hasNextPage: !(value$18 == null) ? value$18 : undefined
        }
      }
    };
  }
  return {
          supplier: tmp
        };
}

function serialize(value) {
  var value$1 = value.supplier;
  var supplier;
  if (value$1 !== undefined) {
    var value$2 = value$1.orders;
    var value$3 = value$2.pageInfo;
    var value$4 = value$3.hasNextPage;
    var hasNextPage = value$4 !== undefined ? value$4 : null;
    var value$5 = value$3.__typename;
    var pageInfo = {
      __typename: value$5,
      hasNextPage: hasNextPage
    };
    var value$6 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var value$2 = value$1.shop;
            var value$3 = value$2.name;
            var value$4 = value$2.__typename;
            var shop = {
              __typename: value$4,
              name: value$3
            };
            var value$5 = value$1.totalProductsQuantity;
            var value$6 = value$1.totalAmountIncludingTaxes;
            var value$7 = value$1.totalAmountExcludingTaxes;
            var value$8 = value$1.receptionFinishedAt;
            var receptionFinishedAt = value$8 !== undefined ? Scalar.Datetime.serialize(Caml_option.valFromOption(value$8)) : null;
            var value$9 = value$1.estimatedReceptionDate;
            var value$10 = Scalar.Datetime.serialize(value$9);
            var value$11 = value$1.formattedName;
            var value$12 = value$1.formattedStatus;
            var formattedStatus = Js_array.map((function (value) {
                    if (value === "TO_PAY") {
                      return "TO_PAY";
                    } else if (value === "DRAFT") {
                      return "DRAFT";
                    } else if (value === "NOT_RECEIVED") {
                      return "NOT_RECEIVED";
                    } else if (value === "REFUSED") {
                      return "REFUSED";
                    } else if (value === "FINALIZED") {
                      return "FINALIZED";
                    } else if (value === "ACCEPTED") {
                      return "ACCEPTED";
                    } else if (value === "PAID") {
                      return "PAID";
                    } else if (value === "RECEIVING") {
                      return "RECEIVING";
                    } else if (value === "SENT") {
                      return "SENT";
                    } else if (value === "RECEIVED") {
                      return "RECEIVED";
                    } else {
                      return "ARCHIVED";
                    }
                  }), value$12);
            var value$13 = value$1.id;
            var value$14 = value$1.__typename;
            var node = {
              __typename: value$14,
              id: value$13,
              formattedStatus: formattedStatus,
              formattedName: value$11,
              estimatedReceptionDate: value$10,
              receptionFinishedAt: receptionFinishedAt,
              totalAmountExcludingTaxes: value$7,
              totalAmountIncludingTaxes: value$6,
              totalProductsQuantity: value$5,
              shop: shop
            };
            var value$15 = value.__typename;
            return {
                    __typename: value$15,
                    node: node
                  };
          }), value$6);
    var value$7 = value$2.__typename;
    var orders = {
      __typename: value$7,
      edges: edges,
      pageInfo: pageInfo
    };
    var value$8 = value$1.locations;
    var value$9 = value$8.edges;
    var edges$1 = Js_array.map((function (value) {
            var value$1 = value.node;
            var value$2 = value$1.country;
            var country = value$2 !== undefined ? value$2 : null;
            var value$3 = value$1.city;
            var city = value$3 !== undefined ? value$3 : null;
            var value$4 = value$1.postalCode;
            var postalCode = value$4 !== undefined ? value$4 : null;
            var value$5 = value$1.address;
            var address = value$5 !== undefined ? value$5 : null;
            var value$6 = value$1.recipient;
            var recipient = value$6 !== undefined ? value$6 : null;
            var value$7 = value$1.label;
            var value$8 = value$1.__typename;
            var node = {
              __typename: value$8,
              label: value$7,
              recipient: recipient,
              address: address,
              postalCode: postalCode,
              city: city,
              country: country
            };
            var value$9 = value.__typename;
            return {
                    __typename: value$9,
                    node: node
                  };
          }), value$9);
    var value$10 = value$8.__typename;
    var locations = {
      __typename: value$10,
      edges: edges$1
    };
    var value$11 = value$1.contacts;
    var value$12 = value$11.edges;
    var edges$2 = Js_array.map((function (value) {
            var value$1 = value.node;
            var value$2 = value$1.email;
            var email = value$2 !== undefined ? value$2 : null;
            var value$3 = value$1.phoneNumber;
            var phoneNumber = value$3 !== undefined ? value$3 : null;
            var value$4 = value$1.mobileNumber;
            var mobileNumber = value$4 !== undefined ? value$4 : null;
            var value$5 = value$1.formattedName;
            var value$6 = value$1.lastName;
            var value$7 = value$1.firstName;
            var firstName = value$7 !== undefined ? value$7 : null;
            var value$8 = value$1.civility;
            var civility = value$8 !== undefined ? (
                value$8 === "MR" ? "MR" : (
                    value$8 === "MRS" ? "MRS" : "NEUTRAL"
                  )
              ) : null;
            var value$9 = value$1.position;
            var position = value$9 !== undefined ? value$9 : null;
            var value$10 = value$1.__typename;
            var node = {
              __typename: value$10,
              position: position,
              civility: civility,
              firstName: firstName,
              lastName: value$6,
              formattedName: value$5,
              mobileNumber: mobileNumber,
              phoneNumber: phoneNumber,
              email: email
            };
            var value$11 = value.__typename;
            return {
                    __typename: value$11,
                    node: node
                  };
          }), value$12);
    var value$13 = value$11.__typename;
    var contacts = {
      __typename: value$13,
      edges: edges$2
    };
    var value$14 = value$1.shop;
    var value$15 = value$14.name;
    var value$16 = value$14.id;
    var value$17 = value$14.__typename;
    var shop = {
      __typename: value$17,
      id: value$16,
      name: value$15
    };
    var value$18 = value$1.formattedOrdersTotalAmountIncludingTaxes;
    var value$19 = value$1.siretNumber;
    var siretNumber = value$19 !== undefined ? value$19 : null;
    var value$20 = value$1.note;
    var note = value$20 !== undefined ? Scalar.$$Text.serialize(value$20) : null;
    var value$21 = value$1.email;
    var email = value$21 !== undefined ? value$21 : null;
    var value$22 = value$1.mobileNumber;
    var mobileNumber = value$22 !== undefined ? value$22 : null;
    var value$23 = value$1.phoneNumber;
    var phoneNumber = value$23 !== undefined ? value$23 : null;
    var value$24 = value$1.internalCode;
    var internalCode = value$24 !== undefined ? value$24 : null;
    var value$25 = value$1.intraCommunityVat;
    var intraCommunityVat = value$25 !== undefined ? value$25 : null;
    var value$26 = value$1.archivedAt;
    var archivedAt = value$26 !== undefined ? Scalar.Datetime.serialize(Caml_option.valFromOption(value$26)) : null;
    var value$27 = value$1.updatedAt;
    var value$28 = Scalar.Datetime.serialize(value$27);
    var value$29 = value$1.companyName;
    var value$30 = value$1.id;
    var value$31 = value$1.__typename;
    supplier = {
      __typename: value$31,
      id: value$30,
      companyName: value$29,
      updatedAt: value$28,
      archivedAt: archivedAt,
      intraCommunityVat: intraCommunityVat,
      internalCode: internalCode,
      phoneNumber: phoneNumber,
      mobileNumber: mobileNumber,
      email: email,
      note: note,
      siretNumber: siretNumber,
      formattedOrdersTotalAmountIncludingTaxes: value$18,
      shop: shop,
      contacts: contacts,
      locations: locations,
      orders: orders
    };
  } else {
    supplier = null;
  }
  return {
          supplier: supplier
        };
}

function serializeVariables(inp) {
  var a = inp.ordersFirst;
  return {
          id: inp.id,
          ordersFirst: a !== undefined ? a : undefined
        };
}

function makeVariables(id, ordersFirst, param) {
  return {
          id: id,
          ordersFirst: ordersFirst
        };
}

var SupplierQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var SupplierQuery_refetchQueryDescription = include.refetchQueryDescription;

var SupplierQuery_useLazy = include.useLazy;

var SupplierQuery_useLazyWithVariables = include.useLazyWithVariables;

var SupplierQuery = {
  SupplierQuery_inner: SupplierQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: SupplierQuery_refetchQueryDescription,
  use: use,
  useLazy: SupplierQuery_useLazy,
  useLazyWithVariables: SupplierQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["mutation SupplierEditNoteMutation($id: ID!, $supplierInput: InputUpdateSupplier!)  {\nupdateSupplier(id: $id, supplierInput: $supplierInput)  {\n__typename  \nid  \nnote  \nupdatedAt  \n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.updateSupplier;
  var value$2 = value$1.note;
  return {
          updateSupplier: {
            __typename: value$1.__typename,
            id: value$1.id,
            note: !(value$2 == null) ? Scalar.$$Text.parse(value$2) : undefined,
            updatedAt: Scalar.Datetime.parse(value$1.updatedAt)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.updateSupplier;
  var value$2 = value$1.updatedAt;
  var value$3 = Scalar.Datetime.serialize(value$2);
  var value$4 = value$1.note;
  var note = value$4 !== undefined ? Scalar.$$Text.serialize(value$4) : null;
  var value$5 = value$1.id;
  var value$6 = value$1.__typename;
  var updateSupplier = {
    __typename: value$6,
    id: value$5,
    note: note,
    updatedAt: value$3
  };
  return {
          updateSupplier: updateSupplier
        };
}

function serializeInputObjectInputUpdateSupplier(inp) {
  var a = inp.companyName;
  var a$1 = inp.intraCommunityVat;
  var a$2 = inp.siretNumber;
  var a$3 = inp.phoneNumber;
  var a$4 = inp.mobileNumber;
  var a$5 = inp.email;
  var a$6 = inp.note;
  var a$7 = inp.internalCode;
  return {
          companyName: a !== undefined ? a : undefined,
          intraCommunityVat: a$1 !== undefined ? a$1 : undefined,
          siretNumber: a$2 !== undefined ? a$2 : undefined,
          phoneNumber: a$3 !== undefined ? a$3 : undefined,
          mobileNumber: a$4 !== undefined ? a$4 : undefined,
          email: a$5 !== undefined ? a$5 : undefined,
          note: a$6 !== undefined ? a$6 : undefined,
          internalCode: a$7 !== undefined ? a$7 : undefined
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id,
          supplierInput: serializeInputObjectInputUpdateSupplier(inp.supplierInput)
        };
}

function makeVariables$1(id, supplierInput, param) {
  return {
          id: id,
          supplierInput: supplierInput
        };
}

function makeInputObjectInputUpdateSupplier(companyName, intraCommunityVat, siretNumber, phoneNumber, mobileNumber, email, note, internalCode, param) {
  return {
          companyName: companyName,
          intraCommunityVat: intraCommunityVat,
          siretNumber: siretNumber,
          phoneNumber: phoneNumber,
          mobileNumber: mobileNumber,
          email: email,
          note: note,
          internalCode: internalCode
        };
}

var SupplierEditNoteMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputUpdateSupplier: serializeInputObjectInputUpdateSupplier,
  makeVariables: makeVariables$1,
  makeInputObjectInputUpdateSupplier: makeInputObjectInputUpdateSupplier
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var SupplierEditNoteMutation_useWithVariables = include$1.useWithVariables;

var SupplierEditNoteMutation = {
  SupplierEditNoteMutation_inner: SupplierEditNoteMutation_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputUpdateSupplier: serializeInputObjectInputUpdateSupplier,
  makeVariables: makeVariables$1,
  makeInputObjectInputUpdateSupplier: makeInputObjectInputUpdateSupplier,
  use: use$1,
  useWithVariables: SupplierEditNoteMutation_useWithVariables
};

var Raw$2 = {};

var query$2 = Client.gql(["mutation SupplierUnarchiveMutation($id: ID!)  {\nunarchiveSupplier(id: $id)  {\n__typename  \nid  \narchivedAt  \n}\n\n}\n"]);

function parse$2(value) {
  var value$1 = value.unarchiveSupplier;
  var value$2 = value$1.archivedAt;
  return {
          unarchiveSupplier: {
            __typename: value$1.__typename,
            id: value$1.id,
            archivedAt: !(value$2 == null) ? Caml_option.some(Scalar.Datetime.parse(value$2)) : undefined
          }
        };
}

function serialize$2(value) {
  var value$1 = value.unarchiveSupplier;
  var value$2 = value$1.archivedAt;
  var archivedAt = value$2 !== undefined ? Scalar.Datetime.serialize(Caml_option.valFromOption(value$2)) : null;
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var unarchiveSupplier = {
    __typename: value$4,
    id: value$3,
    archivedAt: archivedAt
  };
  return {
          unarchiveSupplier: unarchiveSupplier
        };
}

function serializeVariables$2(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$2(id, param) {
  return {
          id: id
        };
}

var SupplierUnarchiveMutation_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var include$2 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2
    });

var use$2 = include$2.use;

var SupplierUnarchiveMutation_useWithVariables = include$2.useWithVariables;

var SupplierUnarchiveMutation = {
  SupplierUnarchiveMutation_inner: SupplierUnarchiveMutation_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  use: use$2,
  useWithVariables: SupplierUnarchiveMutation_useWithVariables
};

function SupplierShowPage$SupplierStatusBadge(Props) {
  var status = Props.status;
  if (status === "UNARCHIVED") {
    return null;
  } else {
    return React.createElement(Badge.make, {
                children: $$Intl.t("Archived"),
                variation: "neutral"
              });
  }
}

var SupplierStatusBadge = {
  make: SupplierShowPage$SupplierStatusBadge
};

function get(values, field) {
  return values.note;
}

function set(values, field, value) {
  return {
          note: value
        };
}

var SupplierNoteEditFormLenses = {
  get: get,
  set: set
};

var SupplierNoteEditForm = Form.Make(SupplierNoteEditFormLenses);

function SupplierShowPage$SupplierMenuEditingActions(Props) {
  var id = Props.id;
  var match = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var updateSupplierNote = match[0];
  var updateSupplierNoteVariables = function (param) {
    return {
            id: id,
            supplierInput: makeInputObjectInputUpdateSupplier(undefined, undefined, undefined, undefined, undefined, undefined, param.note, undefined, undefined)
          };
  };
  return React.createElement(Inline.make, {
              children: null,
              space: "small"
            }, React.createElement(SupplierNoteEditForm.CancelButton.make, {
                  text: $$Intl.t("Discard"),
                  size: "medium"
                }), React.createElement(SupplierNoteEditForm.SubmitButton.make, {
                  variation: "success",
                  size: "medium",
                  onSubmit: (function (param, values) {
                      return Future.mapOk(ApolloHelpers.mutationPromiseToFutureResult(Curry._8(updateSupplierNote, undefined, undefined, undefined, undefined, undefined, undefined, undefined, updateSupplierNoteVariables(values))), undefined, (function (param) {
                                    return param.updateSupplier.id;
                                  }));
                    }),
                  text: $$Intl.t("Save")
                }));
}

var SupplierMenuEditingActions = {
  make: SupplierShowPage$SupplierMenuEditingActions
};

function SupplierShowPage$SupplierMenuReadingActions(Props) {
  var id = Props.id;
  var shopId = Props.shopId;
  var status = Props.status;
  var onRequestArchive = Props.onRequestArchive;
  var match = Curry.app(use$2, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var unarchiveSupplier = match[0];
  var unarchiveSupplier$1 = function (param) {
    Curry._8(unarchiveSupplier, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
          id: id
        });
  };
  var createOrderMenuAction = {
    TAG: /* OpenLink */1,
    _0: {
      TAG: /* RouteWithQueryString */1,
      _0: LegacyRouter.routeToPathname(/* Order */0) + "/create",
      _1: OrderUrlQueryString.CreateOrder.encode({
            supplierId: id,
            shopId: shopId
          })
    }
  };
  if (status === "UNARCHIVED") {
    return React.createElement(Menu.make, {
                children: null
              }, React.createElement(MenuItem.make, {
                    content: {
                      TAG: /* Text */0,
                      _0: $$Intl.t("Create order")
                    },
                    action: createOrderMenuAction
                  }), React.createElement(MenuItem.make, {
                    content: {
                      TAG: /* Text */0,
                      _0: $$Intl.t("Archive")
                    },
                    action: {
                      TAG: /* Callback */0,
                      _0: onRequestArchive
                    }
                  }));
  } else {
    return React.createElement(Button.make, Button.makeProps($$Intl.t("Unarchive"), undefined, "primary", undefined, undefined, undefined, undefined, undefined, undefined, unarchiveSupplier$1, undefined, undefined, undefined));
  }
}

var SupplierMenuReadingActions = {
  make: SupplierShowPage$SupplierMenuReadingActions
};

function SupplierShowPage$SupplierMenuActions(Props) {
  var id = Props.id;
  var shopId = Props.shopId;
  var status = Props.status;
  var supplierBaseRoute = Props.supplierBaseRoute;
  var formState = Curry._1(SupplierNoteEditForm.useFormState, undefined);
  var isFormPristine = Form__Status.isPristine(formState.status);
  var match = React.useState(function () {
        return false;
      });
  var setArchiveModalOpened = match[1];
  var tmp;
  if (isFormPristine) {
    var onRequestArchive = function (param) {
      Curry._1(setArchiveModalOpened, (function (param) {
              return true;
            }));
    };
    tmp = React.createElement(SupplierShowPage$SupplierMenuReadingActions, {
          id: id,
          shopId: shopId,
          status: status,
          onRequestArchive: onRequestArchive
        });
  } else {
    tmp = React.createElement(SupplierShowPage$SupplierMenuEditingActions, {
          id: id
        });
  }
  return React.createElement(React.Fragment, undefined, tmp, React.createElement(SupplierArchiveModal.make, {
                  id: id,
                  opened: match[0],
                  onRequestClose: (function (param) {
                      Curry._1(setArchiveModalOpened, (function (param) {
                              return false;
                            }));
                    }),
                  supplierBaseRoute: supplierBaseRoute
                }));
}

var SupplierMenuActions = {
  make: SupplierShowPage$SupplierMenuActions
};

function SupplierShowPage$SupplierInformationsCard(Props) {
  var id = Props.id;
  var companyName = Props.companyName;
  var email = Props.email;
  var phoneNumber = Props.phoneNumber;
  var mobileNumber = Props.mobileNumber;
  var intraCommunityVat = Props.intraCommunityVat;
  var siretNumber = Props.siretNumber;
  var internalCode = Props.internalCode;
  var supplierEditRoute = Props.supplierEditRoute;
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  if (intraCommunityVat !== undefined && intraCommunityVat !== "") {
    exit = 1;
  } else {
    exit$1 = 2;
  }
  if (exit$1 === 2) {
    var exit$2 = 0;
    if (siretNumber !== undefined && siretNumber !== "") {
      exit = 1;
    } else {
      exit$2 = 3;
    }
    if (exit$2 === 3) {
      if (internalCode !== undefined && internalCode !== "") {
        exit = 1;
      } else {
        tmp = null;
      }
    }
    
  }
  if (exit === 1) {
    var tmp$1;
    tmp$1 = intraCommunityVat !== undefined && intraCommunityVat !== "" ? React.createElement(InlineText.make, {
            children: null
          }, React.createElement(TextStyle.make, {
                children: $$Intl.t("VAT n."),
                variation: "normal"
              }), React.createElement(TextStyle.make, {
                children: intraCommunityVat
              })) : null;
    var tmp$2;
    tmp$2 = siretNumber !== undefined && siretNumber !== "" ? React.createElement(InlineText.make, {
            children: null
          }, React.createElement(TextStyle.make, {
                children: $$Intl.t("Siret") + " ",
                variation: "normal"
              }), React.createElement(TextStyle.make, {
                children: siretNumber
              })) : null;
    var tmp$3;
    tmp$3 = internalCode !== undefined && internalCode !== "" ? React.createElement(TextStyle.make, {
            children: internalCode,
            variation: "subdued"
          }) : null;
    tmp = React.createElement(Stack.make, {
          children: null,
          space: "xxsmall"
        }, tmp$1, tmp$2, tmp$3);
  }
  return React.createElement(Card.make, {
              children: React.createElement(Stack.make, {
                    children: null,
                    space: "medium"
                  }, React.createElement(Stack.make, {
                        children: null,
                        space: "xxsmall"
                      }, React.createElement(TextStyle.make, {
                            children: companyName,
                            weight: "strong"
                          }), email !== undefined ? React.createElement(TextStyle.make, {
                              children: email
                            }) : null, phoneNumber !== undefined ? React.createElement(TextStyle.make, {
                              children: phoneNumber
                            }) : null, mobileNumber !== undefined ? React.createElement(TextStyle.make, {
                              children: mobileNumber
                            }) : null), tmp),
              title: $$Intl.t("Company information"),
              action: {
                icon: "edit_light",
                title: $$Intl.t("Edit information"),
                handler: {
                  TAG: /* OpenLink */1,
                  _0: {
                    TAG: /* Route */0,
                    _0: Curry._1(supplierEditRoute, id)
                  }
                }
              }
            });
}

var SupplierInformationsCard = {
  make: SupplierShowPage$SupplierInformationsCard
};

function postalCodeCityCountry(postalCode, city, country, param) {
  var cityCountry = Belt_Array.joinWith(Belt_Array.keepMap([
            city,
            country
          ], (function (maybeString) {
              if (maybeString !== undefined && maybeString !== "") {
                return maybeString;
              }
              
            })), ", ", (function (a) {
          return a;
        }));
  var exit = 0;
  if (postalCode !== undefined && postalCode !== "") {
    if (cityCountry === "") {
      return postalCode;
    }
    
  } else {
    exit = 2;
  }
  if (exit === 2 && cityCountry === "") {
    return ;
  }
  if (postalCode !== undefined && postalCode !== "") {
    return postalCode + " " + cityCountry;
  } else {
    return cityCountry;
  }
}

var cartTitle = $$Intl.t("Location");

function SupplierShowPage$SupplierLocationCard(Props) {
  var id = Props.id;
  var supplierEditLocationRoute = Props.supplierEditLocationRoute;
  var items = Props.items;
  var handler = {
    TAG: /* OpenLink */1,
    _0: {
      TAG: /* Route */0,
      _0: Curry._1(supplierEditLocationRoute, id)
    }
  };
  var match = Belt_Array.get(items, 0);
  if (match === undefined) {
    return React.createElement(Card.make, {
                children: React.createElement(TextStyle.make, {
                      children: $$Intl.t("Location information not specified"),
                      variation: "subdued"
                    }),
                title: cartTitle,
                action: {
                  icon: "edit_light",
                  title: $$Intl.t("New location"),
                  handler: handler
                }
              });
  }
  var address = match.address;
  var recipient = match.recipient;
  var tmp;
  tmp = recipient !== undefined && recipient !== "" ? React.createElement(TextStyle.make, {
          children: recipient
        }) : null;
  var tmp$1;
  tmp$1 = address !== undefined && address !== "" ? React.createElement(TextStyle.make, {
          children: address
        }) : null;
  var postalCodeCityCountry$1 = postalCodeCityCountry(match.postalCode, match.city, match.country, undefined);
  return React.createElement(Card.make, {
              children: React.createElement(Stack.make, {
                    children: null,
                    space: "xxsmall"
                  }, React.createElement(TextStyle.make, {
                        children: match.label,
                        variation: "normal"
                      }), tmp, tmp$1, postalCodeCityCountry$1 !== undefined ? React.createElement(TextStyle.make, {
                          children: postalCodeCityCountry$1
                        }) : null),
              title: cartTitle,
              action: {
                icon: "edit_light",
                title: $$Intl.t("Edit location"),
                handler: handler
              }
            });
}

var SupplierLocationCard = {
  postalCodeCityCountry: postalCodeCityCountry,
  cartTitle: cartTitle,
  make: SupplierShowPage$SupplierLocationCard
};

function title(lastName, civility, firstName, param) {
  return Belt_Array.joinWith(Belt_Array.keepMap([
                  Belt_Option.map(civility, SupplierCivility.toShortLabel),
                  firstName,
                  lastName
                ], (function (maybeString) {
                    return maybeString;
                  })), " ", (function (a) {
                return a;
              }));
}

var cardTitle = $$Intl.t("Contact");

function SupplierShowPage$SupplierContactCard(Props) {
  var id = Props.id;
  var supplierEditContactRoute = Props.supplierEditContactRoute;
  var itemsOpt = Props.items;
  var items = itemsOpt !== undefined ? itemsOpt : [];
  var handler = {
    TAG: /* OpenLink */1,
    _0: {
      TAG: /* Route */0,
      _0: Curry._1(supplierEditContactRoute, id)
    }
  };
  var match = Belt_Array.get(items, 0);
  if (match === undefined) {
    return React.createElement(Card.make, {
                children: React.createElement(TextStyle.make, {
                      children: $$Intl.t("Contact information not specified"),
                      variation: "subdued"
                    }),
                title: cardTitle,
                action: {
                  icon: "edit_light",
                  title: $$Intl.t("New contact"),
                  handler: handler
                }
              });
  }
  var mobileNumber = match.mobileNumber;
  var phoneNumber = match.phoneNumber;
  var position = match.position;
  var email = match.email;
  return React.createElement(Card.make, {
              children: React.createElement(Stack.make, {
                    children: null,
                    space: "xxsmall"
                  }, React.createElement(TextStyle.make, {
                        children: title(match.lastName, match.civility, match.firstName, undefined),
                        weight: "strong"
                      }), position !== undefined ? React.createElement(TextStyle.make, {
                          children: position
                        }) : null, email !== undefined ? React.createElement(TextStyle.make, {
                          children: email
                        }) : null, phoneNumber !== undefined ? React.createElement(TextStyle.make, {
                          children: phoneNumber
                        }) : null, mobileNumber !== undefined ? React.createElement(TextStyle.make, {
                          children: mobileNumber
                        }) : null),
              title: cardTitle,
              action: {
                icon: "edit_light",
                title: $$Intl.t("Edit contact"),
                handler: handler
              }
            });
}

var SupplierContactCard = {
  title: title,
  cardTitle: cardTitle,
  make: SupplierShowPage$SupplierContactCard
};

function SupplierShowPage$SupplierTotalPurchasedCard(Props) {
  var value = Props.value;
  var currencyOpt = Props.currency;
  var currency = currencyOpt !== undefined ? currencyOpt : "EUR";
  var value$1 = Belt_Option.getWithDefault(Belt_Float.fromString(value.slice(0, -1).replace(",", ".")), 0);
  return React.createElement(Card.make, {
              children: React.createElement(ValueIndicator.make, {
                    value: {
                      NAME: "currency",
                      VAL: [
                        value$1,
                        currency
                      ]
                    }
                  }),
              title: $$Intl.t("Amount of orders received including VAT")
            });
}

var SupplierTotalPurchasedCard = {
  make: SupplierShowPage$SupplierTotalPurchasedCard
};

function SupplierShowPage$SupplierOrderTableCard(Props) {
  var id = Props.id;
  var shopId = Props.shopId;
  var rows = Props.rows;
  var hasNextPage = Props.hasNextPage;
  var status = Props.status;
  var authScope = Auth.useScope(undefined);
  var match = Navigation.useUrl(undefined);
  var createOrderPathname = LegacyRouter.routeToPathname(/* Order */0) + "/create";
  var placeholderEmptyState = React.createElement(Placeholder.make, {
        status: /* NoDataAvailable */1,
        customText: $$Intl.t("No order has been recorded yet")
      });
  var columns = [
    {
      key: "order-formatted-name",
      name: $$Intl.t("Number"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 140
        },
        width: {
          NAME: "pct",
          VAL: 30
        },
        margin: "normal"
      },
      render: (function (param) {
          var match = param.data;
          var allShopsFiltered;
          allShopsFiltered = authScope.TAG === /* Organisation */0 ? authScope.activeShop === undefined : false;
          var shopName = allShopsFiltered ? match.shopName : undefined;
          var tmp = {
            value: match.formattedName,
            id: match.id
          };
          if (shopName !== undefined) {
            tmp.shopName = Caml_option.valFromOption(shopName);
          }
          return React.createElement(OrderNameTableCell.make, tmp);
        })
    },
    {
      key: "issue-date",
      name: $$Intl.t("Reception date"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        },
        width: {
          NAME: "fr",
          VAL: 1.7
        }
      },
      render: (function (param) {
          var match = param.data;
          var receptionFinishedAt = match.receptionFinishedAt;
          if (receptionFinishedAt !== undefined) {
            return React.createElement(TableDateCell.make, {
                        value: receptionFinishedAt
                      });
          } else {
            return React.createElement(TableDateCell.make, {
                        value: Caml_option.some(match.estimatedReceptionDate),
                        label: $$Intl.t("estimated")
                      });
          }
        })
    },
    {
      key: "total-excl-vat",
      name: $$Intl.t("Amount VAT excl."),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        }
      },
      render: (function (param) {
          var order = param.data;
          return React.createElement(AmountTableCell.make, {
                      value: order.totalAmountExcludingTaxes,
                      secondaryValue: order.totalAmountIncludingTaxes,
                      decimalPrecision: 3
                    });
        })
    },
    {
      key: "quantity",
      name: $$Intl.t("Quantity"),
      layout: {
        alignX: "center"
      },
      render: (function (param) {
          return React.createElement(TextStyle.make, {
                      children: String(param.data.totalProductsQuantity)
                    });
        })
    },
    {
      key: "status",
      name: $$Intl.t("Status"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 100
        },
        width: {
          NAME: "fr",
          VAL: 2
        }
      },
      render: (function (param) {
          return React.createElement(OrderStatusBadges.make, {
                      statuses: param.data.formattedStatus
                    });
        })
    }
  ];
  var tmp = {
    children: null,
    title: $$Intl.t("Orders history"),
    variation: "table"
  };
  var tmp$1 = status === "UNARCHIVED" ? ({
        icon: "plus_light",
        title: $$Intl.t("Create order"),
        handler: {
          TAG: /* OpenLink */1,
          _0: {
            TAG: /* RouteWithQueryString */1,
            _0: createOrderPathname,
            _1: OrderUrlQueryString.CreateOrder.encode({
                  supplierId: id,
                  shopId: shopId
                })
          }
        }
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.action = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Card.make, tmp, React.createElement(TableView.make, {
                  data: {
                    TAG: /* Done */1,
                    _0: {
                      TAG: /* Ok */0,
                      _0: rows
                    }
                  },
                  columns: columns,
                  keyExtractor: (function (row) {
                      return row.id;
                    }),
                  placeholderEmptyState: placeholderEmptyState
                }), hasNextPage ? React.createElement(ShowAllDataLink.make, {
                    to: {
                      TAG: /* Route */0,
                      _0: match.pathname + LegacyRouter.routeToPathname(/* Order */0)
                    },
                    text: $$Intl.t("Show all data")
                  }) : null);
}

var SupplierOrderTableCard = {
  make: SupplierShowPage$SupplierOrderTableCard
};

function SupplierShowPage(Props) {
  var id = Props.id;
  var supplierEditRoute = Props.supplierEditRoute;
  var supplierEditLocationRoute = Props.supplierEditLocationRoute;
  var supplierEditContactRoute = Props.supplierEditContactRoute;
  var supplierBaseRoute = Props.supplierBaseRoute;
  var scope = Auth.useScope(undefined);
  var asyncResultSupplier = AsyncResult.mapError(AsyncResult.mapOk(ApolloHelpers.queryResultToAsyncResult(Curry.app(use, [
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    {
                      id: id,
                      ordersFirst: 5
                    }
                  ])), (function (param) {
              return param.supplier;
            })), (function (param) {
          
        }));
  var formId;
  if (typeof asyncResultSupplier === "number") {
    formId = undefined;
  } else if (asyncResultSupplier.TAG === /* Reloading */0) {
    var match = asyncResultSupplier._0;
    if (match.TAG === /* Ok */0) {
      var supplier = match._0;
      formId = supplier !== undefined ? supplier.id : undefined;
    } else {
      formId = undefined;
    }
  } else {
    var match$1 = asyncResultSupplier._0;
    if (match$1.TAG === /* Ok */0) {
      var supplier$1 = match$1._0;
      formId = supplier$1 !== undefined ? supplier$1.id : undefined;
    } else {
      formId = undefined;
    }
  }
  var formInitialValues;
  var exit = 0;
  var supplier$2;
  if (typeof asyncResultSupplier === "number") {
    formInitialValues = {
      note: ""
    };
  } else if (asyncResultSupplier.TAG === /* Reloading */0) {
    var match$2 = asyncResultSupplier._0;
    if (match$2.TAG === /* Ok */0) {
      var supplier$3 = match$2._0;
      if (supplier$3 !== undefined) {
        supplier$2 = supplier$3;
        exit = 1;
      } else {
        formInitialValues = {
          note: ""
        };
      }
    } else {
      formInitialValues = {
        note: ""
      };
    }
  } else {
    var match$3 = asyncResultSupplier._0;
    if (match$3.TAG === /* Ok */0) {
      var supplier$4 = match$3._0;
      if (supplier$4 !== undefined) {
        supplier$2 = supplier$4;
        exit = 1;
      } else {
        formInitialValues = {
          note: ""
        };
      }
    } else {
      formInitialValues = {
        note: ""
      };
    }
  }
  if (exit === 1) {
    var note = Belt_Option.getWithDefault(supplier$2.note, "");
    formInitialValues = {
      note: note
    };
  }
  var formPropState = Curry._1(SupplierNoteEditForm.useFormPropState, {
        id: formId,
        initialValues: formInitialValues,
        schema: [],
        resetValuesAfterSubmission: true
      });
  var exit$1 = 0;
  var supplier$5;
  if (typeof asyncResultSupplier === "number") {
    exit$1 = asyncResultSupplier === /* NotAsked */0 ? 3 : 2;
  } else if (asyncResultSupplier.TAG === /* Reloading */0) {
    var match$4 = asyncResultSupplier._0;
    if (match$4.TAG === /* Ok */0) {
      var supplier$6 = match$4._0;
      if (supplier$6 !== undefined) {
        supplier$5 = supplier$6;
        exit$1 = 1;
      } else {
        exit$1 = 2;
      }
    } else {
      exit$1 = 2;
    }
  } else {
    var match$5 = asyncResultSupplier._0;
    if (match$5.TAG === /* Ok */0) {
      var supplier$7 = match$5._0;
      if (supplier$7 === undefined) {
        return React.createElement(Placeholder.make, {
                    status: /* NoDataAvailable */1
                  });
      }
      supplier$5 = supplier$7;
      exit$1 = 1;
    } else {
      exit$1 = 3;
    }
  }
  switch (exit$1) {
    case 1 :
        var match$6 = supplier$5.shop;
        var shopId = match$6.id;
        var id$1 = supplier$5.id;
        var match$7 = supplier$5.archivedAt;
        var status = match$7 !== undefined ? "ARCHIVED" : "UNARCHIVED";
        var subtitle;
        subtitle = scope.TAG === /* Organisation */0 ? match$6.name : undefined;
        var actionsBar = React.createElement(SupplierShowPage$SupplierMenuActions, {
              id: id$1,
              shopId: shopId,
              status: status,
              supplierBaseRoute: supplierBaseRoute
            });
        var statusBadge = React.createElement(SupplierShowPage$SupplierStatusBadge, {
              status: status
            });
        var tmp = {
          id: id$1,
          companyName: supplier$5.companyName,
          supplierEditRoute: supplierEditRoute
        };
        if (supplier$5.email !== undefined) {
          tmp.email = Caml_option.valFromOption(supplier$5.email);
        }
        if (supplier$5.phoneNumber !== undefined) {
          tmp.phoneNumber = Caml_option.valFromOption(supplier$5.phoneNumber);
        }
        if (supplier$5.mobileNumber !== undefined) {
          tmp.mobileNumber = Caml_option.valFromOption(supplier$5.mobileNumber);
        }
        if (supplier$5.intraCommunityVat !== undefined) {
          tmp.intraCommunityVat = Caml_option.valFromOption(supplier$5.intraCommunityVat);
        }
        if (supplier$5.siretNumber !== undefined) {
          tmp.siretNumber = Caml_option.valFromOption(supplier$5.siretNumber);
        }
        if (supplier$5.internalCode !== undefined) {
          tmp.internalCode = Caml_option.valFromOption(supplier$5.internalCode);
        }
        var tmp$1 = {
          title: supplier$5.companyName,
          actionsBar: actionsBar,
          statusBadge: statusBadge,
          children: React.createElement(Columns.make, {
                children: null,
                space: "large"
              }, React.createElement(Column.make, {
                    children: React.createElement(Stack.make, {
                          children: null,
                          space: "large"
                        }, React.createElement(SupplierShowPage$SupplierTotalPurchasedCard, {
                              value: supplier$5.formattedOrdersTotalAmountIncludingTaxes
                            }), React.createElement(SupplierShowPage$SupplierInformationsCard, tmp), React.createElement(SupplierShowPage$SupplierLocationCard, {
                              id: id$1,
                              supplierEditLocationRoute: supplierEditLocationRoute,
                              items: Belt_Array.map(supplier$5.locations.edges, (function (param) {
                                      var $$location = param.node;
                                      return {
                                              label: $$location.label,
                                              recipient: $$location.recipient,
                                              address: $$location.address,
                                              postalCode: $$location.postalCode,
                                              country: $$location.country,
                                              city: $$location.city
                                            };
                                    }))
                            }), React.createElement(SupplierShowPage$SupplierContactCard, {
                              id: id$1,
                              supplierEditContactRoute: supplierEditContactRoute,
                              items: Belt_Array.map(supplier$5.contacts.edges, (function (param) {
                                      var contact = param.node;
                                      return {
                                              civility: contact.civility,
                                              firstName: contact.firstName,
                                              lastName: contact.lastName,
                                              email: contact.email,
                                              position: contact.position,
                                              phoneNumber: contact.phoneNumber,
                                              mobileNumber: contact.mobileNumber
                                            };
                                    }))
                            }), React.createElement(Card.make, {
                              children: React.createElement(SupplierNoteEditForm.InputTextArea.make, {
                                    field: /* Note */0,
                                    label: $$Intl.t("Note")
                                  }),
                              title: $$Intl.t("Note")
                            })),
                    width: "one_third"
                  }), React.createElement(Column.make, {
                    children: React.createElement(SupplierShowPage$SupplierOrderTableCard, {
                          id: id$1,
                          shopId: shopId,
                          rows: Belt_Array.map(supplier$5.orders.edges, (function (param) {
                                  var order = param.node;
                                  return {
                                          id: order.id,
                                          formattedName: order.formattedName,
                                          shopName: order.shop.name,
                                          estimatedReceptionDate: order.estimatedReceptionDate,
                                          receptionFinishedAt: order.receptionFinishedAt,
                                          totalAmountExcludingTaxes: order.totalAmountExcludingTaxes,
                                          totalAmountIncludingTaxes: order.totalAmountIncludingTaxes,
                                          totalProductsQuantity: order.totalProductsQuantity,
                                          formattedStatus: order.formattedStatus
                                        };
                                })),
                          hasNextPage: Belt_Option.getWithDefault(supplier$5.orders.pageInfo.hasNextPage, false),
                          status: status
                        }),
                    width: "three_quarter"
                  }))
        };
        if (subtitle !== undefined) {
          tmp$1.subtitle = subtitle;
        }
        return React.createElement(SupplierNoteEditForm.Core.Provider.make, {
                    children: React.createElement(ResourceDetailsPage.make, tmp$1),
                    propState: formPropState
                  });
    case 2 :
        return React.createElement(Placeholder.make, {
                    status: /* Loading */0
                  });
    case 3 :
        return React.createElement(Placeholder.make, {
                    status: /* Error */2
                  });
    
  }
}

var make = SupplierShowPage;

export {
  SupplierQuery ,
  SupplierEditNoteMutation ,
  SupplierUnarchiveMutation ,
  SupplierStatusBadge ,
  SupplierNoteEditFormLenses ,
  SupplierNoteEditForm ,
  SupplierMenuEditingActions ,
  SupplierMenuReadingActions ,
  SupplierMenuActions ,
  SupplierInformationsCard ,
  SupplierLocationCard ,
  SupplierContactCard ,
  SupplierTotalPurchasedCard ,
  SupplierOrderTableCard ,
  make ,
}
/* query Not a pure module */
