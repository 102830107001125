// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as CatalogVariant from "../../modules/Catalog/CatalogVariant.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as CatalogVariantEditUrlState from "../../modules/Catalog/CatalogVariantEditUrlState.bs.js";
import * as CatalogVariantEdit__Queries from "./CatalogVariantEdit__Queries.bs.js";

var CatalogVariantEdit_VariantNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogVariantEdit__Config.CatalogVariantEdit_VariantNotFound");

var CatalogVariantEdit_VariantProductNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogVariantEdit__Config.CatalogVariantEdit_VariantProductNotFound");

var CatalogVariantEdit_ProductNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogVariantEdit__Config.CatalogVariantEdit_ProductNotFound");

function makeVariantFilterBy(activeShopId) {
  return CatalogVariantEdit__Queries.VariantQuery.makeInputObjectInputVariantsByCkuQueryFilter(activeShopId !== undefined ? CatalogVariantEdit__Queries.VariantQuery.makeInputObjectInFilter([activeShopId], undefined) : undefined, undefined, "INCLUDED", undefined);
}

function makeProductFilterBy(activeShopId) {
  return CatalogVariantEdit__Queries.ProductQuery.makeInputObjectInputProductsByVariantCkuQueryFilter(activeShopId !== undefined ? CatalogVariantEdit__Queries.ProductQuery.makeInputObjectInFilter([activeShopId], undefined) : undefined, "INCLUDED", undefined);
}

function variantFromData(data) {
  var match = Belt_Array.get(data.variantsByCku.edges, 0);
  if (match !== undefined) {
    return match.node;
  }
  throw {
        RE_EXN_ID: CatalogVariantEdit_VariantNotFound,
        Error: new Error()
      };
}

function variantProductFromData(data) {
  var product = Belt_Option.map(Belt_Array.get(data.variantsByCku.edges, 0), (function (param) {
          return param.node.product;
        }));
  if (product !== undefined) {
    return product;
  }
  throw {
        RE_EXN_ID: CatalogVariantEdit_VariantProductNotFound,
        Error: new Error()
      };
}

function productFromData(data) {
  var match = Belt_Array.get(data.productsByVariantCku.edges, 0);
  if (match !== undefined) {
    return match.node;
  }
  throw {
        RE_EXN_ID: CatalogVariantEdit_ProductNotFound,
        Error: new Error()
      };
}

function use(variantResponse, productResponse) {
  var shops = Auth.useShops(undefined);
  var activeShop = Auth.useActiveShop(undefined);
  var productValues = CatalogVariantEditUrlState.CreateVariantFromProduct.decode(Navigation.useUrl(undefined).state);
  if (shops.length === 0) {
    return /* Loading */1;
  }
  var exit = 0;
  var exit$1 = 0;
  if (variantResponse.TAG === /* Executed */0) {
    if (variantResponse._0.loading) {
      return /* Loading */1;
    }
    exit$1 = 3;
  } else if (productResponse.TAG === /* Executed */0) {
    exit$1 = 3;
  } else {
    if (productValues === undefined) {
      return /* Error */0;
    }
    if (productValues.TAG !== /* Ok */0) {
      return /* Error */0;
    }
    if (activeShop === undefined) {
      return /* Error */0;
    }
    var product = productValues._0;
    return /* Data */{
            _0: {
              id: "",
              productKind: product.kind,
              productTaxRate: Belt_Option.map(product.tax, (function (param) {
                      return param.value;
                    })),
              productValues: product,
              initialValues: {
                name: "",
                capacityUnit: CatalogVariant.CapacityUnit.getDefaultFromProductKind(product.kind),
                capacityValue: undefined,
                capacityPrecision: undefined,
                ean13: "",
                stockKeepingUnit: "",
                priceLookUpCode: {
                  invalidValues: [],
                  value: undefined
                },
                internalCode: "",
                alcoholVolume: undefined,
                year: undefined,
                bulk: false,
                purchasePrice: 0,
                supplierId: "",
                variantPrices: [],
                initialStockQuantity: 0,
                initialStockComment: "",
                maxStockThreshold: 0,
                minStockThreshold: 0,
                stockOrderTriggerThreshold: 0
              },
              formattedName: product.name,
              shopName: activeShop.name
            }
          };
  }
  if (exit$1 === 3) {
    if (productResponse.TAG === /* Executed */0) {
      if (productResponse._0.loading) {
        return /* Loading */1;
      }
      exit = 2;
    } else {
      exit = 2;
    }
  }
  if (exit === 2 && variantResponse.TAG === /* Executed */0) {
    var data = variantResponse._0.data;
    if (data !== undefined) {
      if (activeShop === undefined) {
        return /* Error */0;
      }
      var variant = variantFromData(data);
      var product$1 = variantProductFromData(data);
      return /* Data */{
              _0: {
                id: variant.id,
                productKind: product$1.kind,
                productTaxRate: undefined,
                productValues: undefined,
                initialValues: {
                  name: variant.name,
                  capacityUnit: variant.capacityUnit,
                  capacityValue: variant.capacityValue,
                  capacityPrecision: variant.capacityPrecision,
                  ean13: Belt_Option.getWithDefault(variant.ean13, ""),
                  stockKeepingUnit: Belt_Option.getWithDefault(variant.stockKeepingUnit, ""),
                  priceLookUpCode: {
                    invalidValues: [],
                    value: variant.priceLookUpCode
                  },
                  internalCode: Belt_Option.getWithDefault(variant.internalCode, ""),
                  alcoholVolume: variant.alcoholVolume,
                  year: Belt_Option.map(variant.year, (function (prim) {
                          return prim;
                        })),
                  bulk: Belt_Option.getWithDefault(variant.bulk, false),
                  purchasePrice: Belt_Option.getWithDefault(variant.purchasedPrice, 0),
                  supplierId: "",
                  variantPrices: undefined,
                  initialStockQuantity: 0,
                  initialStockComment: "",
                  maxStockThreshold: Belt_Option.getWithDefault(variant.maxStockThreshold, 0),
                  minStockThreshold: Belt_Option.getWithDefault(variant.minStockThreshold, 0),
                  stockOrderTriggerThreshold: Belt_Option.getWithDefault(variant.stockOrderTriggerThreshold, 0)
                },
                formattedName: variant.formattedName,
                shopName: activeShop.name
              }
            };
    }
    
  }
  if (productResponse.TAG !== /* Executed */0) {
    return /* Error */0;
  }
  var data$1 = productResponse._0.data;
  if (data$1 === undefined) {
    return /* Error */0;
  }
  if (activeShop === undefined) {
    return /* Error */0;
  }
  var product$2 = productFromData(data$1);
  return /* Data */{
          _0: {
            id: product$2.id,
            productKind: product$2.kind,
            productTaxRate: product$2.tax.value,
            productValues: undefined,
            initialValues: {
              name: "",
              capacityUnit: CatalogVariant.CapacityUnit.getDefaultFromProductKind(product$2.kind),
              capacityValue: undefined,
              capacityPrecision: undefined,
              ean13: "",
              stockKeepingUnit: "",
              priceLookUpCode: {
                invalidValues: [],
                value: undefined
              },
              internalCode: "",
              alcoholVolume: undefined,
              year: undefined,
              bulk: false,
              purchasePrice: 0,
              supplierId: "",
              variantPrices: [],
              initialStockQuantity: 0,
              initialStockComment: "",
              maxStockThreshold: 0,
              minStockThreshold: 0,
              stockOrderTriggerThreshold: 0
            },
            formattedName: product$2.name,
            shopName: activeShop.name
          }
        };
}

var VariantQuery;

var ProductQuery;

export {
  VariantQuery ,
  ProductQuery ,
  CatalogVariantEdit_VariantNotFound ,
  CatalogVariantEdit_VariantProductNotFound ,
  CatalogVariantEdit_ProductNotFound ,
  makeVariantFilterBy ,
  makeProductFilterBy ,
  variantFromData ,
  variantProductFromData ,
  productFromData ,
  use ,
}
/* Auth Not a pure module */
