// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as FontFaces from "../resources/theme/FontFaces.bs.js";
import * as FontSizes from "../resources/theme/FontSizes.bs.js";
import * as ReactAria from "../externals/ReactAria.bs.js";

var style = Object.assign({}, {
      color: "#797885",
      cursor: "inherit",
      fontFamily: FontFaces.fontFamilyFromFontName("LibreFranklin"),
      fontSize: "" + String(FontSizes.xsmall) + "px",
      fontWeight: "500",
      marginBottom: "7px"
    }, {
      textWrap: "nowrap"
    });

function Label(Props) {
  var textOpt = Props.text;
  var ariaProps = Props.ariaProps;
  var text = textOpt !== undefined ? textOpt : "";
  return React.createElement(ReactAria.Spread.make, {
              props: ariaProps,
              children: React.createElement("label", {
                    style: style
                  }, text)
            });
}

var make = React.memo(Label);

export {
  make ,
}
/* style Not a pure module */
