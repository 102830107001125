// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query promotionCampaignsByCku($cku: CKU!)  {\npromotionCampaignsByCku(cku: $cku, first: 50)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nname  \nstartDate  \nendDate  \nformattedStatus  \ncreatorIdentifier  \nshop  {\n__typename  \nid  \nname  \n}\n\nprice  {\n__typename  \nid  \nname  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.promotionCampaignsByCku;
  var value$2 = value$1.edges;
  return {
          promotionCampaignsByCku: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.formattedStatus;
                    var tmp;
                    switch (value$2) {
                      case "ARCHIVED" :
                          tmp = "ARCHIVED";
                          break;
                      case "DRAFT" :
                          tmp = "DRAFT";
                          break;
                      case "EXPIRED" :
                          tmp = "EXPIRED";
                          break;
                      case "NOT_PROGRAMMED" :
                          tmp = "NOT_PROGRAMMED";
                          break;
                      case "ONGOING" :
                          tmp = "ONGOING";
                          break;
                      case "PROGRAMMED" :
                          tmp = "PROGRAMMED";
                          break;
                      case "STOPPED" :
                          tmp = "STOPPED";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$3 = value$1.shop;
                    var value$4 = value$1.price;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              name: value$1.name,
                              startDate: Scalar.Datetime.parse(value$1.startDate),
                              endDate: Scalar.Datetime.parse(value$1.endDate),
                              formattedStatus: tmp,
                              creatorIdentifier: value$1.creatorIdentifier,
                              shop: {
                                __typename: value$3.__typename,
                                id: value$3.id,
                                name: value$3.name
                              },
                              price: {
                                __typename: value$4.__typename,
                                id: value$4.id,
                                name: value$4.name
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.promotionCampaignsByCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.price;
          var value$3 = value$2.name;
          var value$4 = value$2.id;
          var value$5 = value$2.__typename;
          var price = {
            __typename: value$5,
            id: value$4,
            name: value$3
          };
          var value$6 = value$1.shop;
          var value$7 = value$6.name;
          var value$8 = value$6.id;
          var value$9 = value$6.__typename;
          var shop = {
            __typename: value$9,
            id: value$8,
            name: value$7
          };
          var value$10 = value$1.creatorIdentifier;
          var value$11 = value$1.formattedStatus;
          var formattedStatus = value$11 === "STOPPED" ? "STOPPED" : (
              value$11 === "ARCHIVED" ? "ARCHIVED" : (
                  value$11 === "DRAFT" ? "DRAFT" : (
                      value$11 === "NOT_PROGRAMMED" ? "NOT_PROGRAMMED" : (
                          value$11 === "ONGOING" ? "ONGOING" : (
                              value$11 === "EXPIRED" ? "EXPIRED" : "PROGRAMMED"
                            )
                        )
                    )
                )
            );
          var value$12 = value$1.endDate;
          var value$13 = Scalar.Datetime.serialize(value$12);
          var value$14 = value$1.startDate;
          var value$15 = Scalar.Datetime.serialize(value$14);
          var value$16 = value$1.name;
          var value$17 = value$1.id;
          var value$18 = value$1.__typename;
          var node = {
            __typename: value$18,
            id: value$17,
            name: value$16,
            startDate: value$15,
            endDate: value$13,
            formattedStatus: formattedStatus,
            creatorIdentifier: value$10,
            shop: shop,
            price: price
          };
          var value$19 = value.__typename;
          return {
                  __typename: value$19,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var promotionCampaignsByCku = {
    __typename: value$3,
    edges: edges
  };
  return {
          promotionCampaignsByCku: promotionCampaignsByCku
        };
}

function serializeVariables(inp) {
  return {
          cku: inp.cku
        };
}

function makeVariables(cku, param) {
  return {
          cku: cku
        };
}

var PromotionByCkuQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var PromotionByCkuQuery_refetchQueryDescription = include.refetchQueryDescription;

var PromotionByCkuQuery_use = include.use;

var PromotionByCkuQuery_useLazy = include.useLazy;

var PromotionByCkuQuery_useLazyWithVariables = include.useLazyWithVariables;

var PromotionByCkuQuery = {
  PromotionByCkuQuery_inner: PromotionByCkuQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: PromotionByCkuQuery_refetchQueryDescription,
  use: PromotionByCkuQuery_use,
  useLazy: PromotionByCkuQuery_useLazy,
  useLazyWithVariables: PromotionByCkuQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query promotionCampaign($id: ID!, $after: String, $variantPricesFilterBy: InputVariantVariantPricesFilter)  {\npromotionCampaign(id: $id)  {\n__typename  \ndiscounts(first: 50, after: $after)  {\n__typename  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \nvalue  \nkind  \nvariant  {\n__typename  \nid  \ncku  \nformattedName  \nformattedDescription  \nstockKeepingUnit  \npurchasedPrice  \ncapacityUnit  \nbulk  \nvariantPrices(filterBy: $variantPricesFilterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nvalueIncludingTax  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.promotionCampaign;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.discounts;
    var value$3 = value$2.pageInfo;
    var value$4 = value$3.endCursor;
    var value$5 = value$3.hasNextPage;
    var value$6 = value$2.edges;
    tmp = {
      __typename: value$1.__typename,
      discounts: {
        __typename: value$2.__typename,
        pageInfo: {
          __typename: value$3.__typename,
          endCursor: !(value$4 == null) ? value$4 : undefined,
          hasNextPage: !(value$5 == null) ? value$5 : undefined
        },
        edges: Js_array.map((function (value) {
                var value$1 = value.node;
                var value$2 = value$1.kind;
                var tmp;
                switch (value$2) {
                  case "CURRENCY" :
                      tmp = "CURRENCY";
                      break;
                  case "PERCENT" :
                      tmp = "PERCENT";
                      break;
                  default:
                    throw {
                          RE_EXN_ID: "Not_found",
                          Error: new Error()
                        };
                }
                var value$3 = value$1.variant;
                var value$4 = value$3.stockKeepingUnit;
                var value$5 = value$3.purchasedPrice;
                var value$6 = value$3.capacityUnit;
                var value$7 = value$3.bulk;
                var value$8 = value$3.variantPrices;
                var value$9 = value$8.edges;
                return {
                        __typename: value.__typename,
                        node: {
                          __typename: value$1.__typename,
                          id: value$1.id,
                          value: value$1.value,
                          kind: tmp,
                          variant: {
                            __typename: value$3.__typename,
                            id: value$3.id,
                            cku: Scalar.CKU.parse(value$3.cku),
                            formattedName: value$3.formattedName,
                            formattedDescription: value$3.formattedDescription,
                            stockKeepingUnit: !(value$4 == null) ? value$4 : undefined,
                            purchasedPrice: !(value$5 == null) ? value$5 : undefined,
                            capacityUnit: !(value$6 == null) ? value$6 : undefined,
                            bulk: !(value$7 == null) ? value$7 : undefined,
                            variantPrices: {
                              __typename: value$8.__typename,
                              edges: Js_array.map((function (value) {
                                      var value$1 = value.node;
                                      return {
                                              __typename: value.__typename,
                                              node: {
                                                __typename: value$1.__typename,
                                                valueIncludingTax: value$1.valueIncludingTax
                                              }
                                            };
                                    }), value$9)
                            }
                          }
                        }
                      };
              }), value$6)
      }
    };
  }
  return {
          promotionCampaign: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.promotionCampaign;
  var promotionCampaign;
  if (value$1 !== undefined) {
    var value$2 = value$1.discounts;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var value$2 = value$1.variant;
            var value$3 = value$2.variantPrices;
            var value$4 = value$3.edges;
            var edges = Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.valueIncludingTax;
                    var value$3 = value$1.__typename;
                    var node = {
                      __typename: value$3,
                      valueIncludingTax: value$2
                    };
                    var value$4 = value.__typename;
                    return {
                            __typename: value$4,
                            node: node
                          };
                  }), value$4);
            var value$5 = value$3.__typename;
            var variantPrices = {
              __typename: value$5,
              edges: edges
            };
            var value$6 = value$2.bulk;
            var bulk = value$6 !== undefined ? value$6 : null;
            var value$7 = value$2.capacityUnit;
            var capacityUnit = value$7 !== undefined ? value$7 : null;
            var value$8 = value$2.purchasedPrice;
            var purchasedPrice = value$8 !== undefined ? value$8 : null;
            var value$9 = value$2.stockKeepingUnit;
            var stockKeepingUnit = value$9 !== undefined ? value$9 : null;
            var value$10 = value$2.formattedDescription;
            var value$11 = value$2.formattedName;
            var value$12 = value$2.cku;
            var value$13 = Scalar.CKU.serialize(value$12);
            var value$14 = value$2.id;
            var value$15 = value$2.__typename;
            var variant = {
              __typename: value$15,
              id: value$14,
              cku: value$13,
              formattedName: value$11,
              formattedDescription: value$10,
              stockKeepingUnit: stockKeepingUnit,
              purchasedPrice: purchasedPrice,
              capacityUnit: capacityUnit,
              bulk: bulk,
              variantPrices: variantPrices
            };
            var value$16 = value$1.kind;
            var kind = value$16 === "PERCENT" ? "PERCENT" : "CURRENCY";
            var value$17 = value$1.value;
            var value$18 = value$1.id;
            var value$19 = value$1.__typename;
            var node = {
              __typename: value$19,
              id: value$18,
              value: value$17,
              kind: kind,
              variant: variant
            };
            var value$20 = value.__typename;
            return {
                    __typename: value$20,
                    node: node
                  };
          }), value$3);
    var value$4 = value$2.pageInfo;
    var value$5 = value$4.hasNextPage;
    var hasNextPage = value$5 !== undefined ? value$5 : null;
    var value$6 = value$4.endCursor;
    var endCursor = value$6 !== undefined ? value$6 : null;
    var value$7 = value$4.__typename;
    var pageInfo = {
      __typename: value$7,
      endCursor: endCursor,
      hasNextPage: hasNextPage
    };
    var value$8 = value$2.__typename;
    var discounts = {
      __typename: value$8,
      pageInfo: pageInfo,
      edges: edges
    };
    var value$9 = value$1.__typename;
    promotionCampaign = {
      __typename: value$9,
      discounts: discounts
    };
  } else {
    promotionCampaign = null;
  }
  return {
          promotionCampaign: promotionCampaign
        };
}

function serializeInputObjectStringEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectInputVariantVariantPricesFilter(inp) {
  var a = inp.priceId;
  return {
          priceId: a !== undefined ? ({
                _equals: a._equals
              }) : undefined
        };
}

function serializeVariables$1(inp) {
  var a = inp.after;
  var a$1 = inp.variantPricesFilterBy;
  return {
          id: inp.id,
          after: a !== undefined ? a : undefined,
          variantPricesFilterBy: a$1 !== undefined ? serializeInputObjectInputVariantVariantPricesFilter(a$1) : undefined
        };
}

function makeVariables$1(id, after, variantPricesFilterBy, param) {
  return {
          id: id,
          after: after,
          variantPricesFilterBy: variantPricesFilterBy
        };
}

function makeInputObjectInputVariantVariantPricesFilter(priceId, param) {
  return {
          priceId: priceId
        };
}

function makeInputObjectStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

var PromotionDiscountsQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputVariantVariantPricesFilter: serializeInputObjectInputVariantVariantPricesFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  makeVariables: makeVariables$1,
  makeInputObjectInputVariantVariantPricesFilter: makeInputObjectInputVariantVariantPricesFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var PromotionDiscountsQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var PromotionDiscountsQuery_use = include$1.use;

var PromotionDiscountsQuery_useLazy = include$1.useLazy;

var PromotionDiscountsQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var PromotionDiscountsQuery = {
  PromotionDiscountsQuery_inner: PromotionDiscountsQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputVariantVariantPricesFilter: serializeInputObjectInputVariantVariantPricesFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  makeVariables: makeVariables$1,
  makeInputObjectInputVariantVariantPricesFilter: makeInputObjectInputVariantVariantPricesFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  refetchQueryDescription: PromotionDiscountsQuery_refetchQueryDescription,
  use: PromotionDiscountsQuery_use,
  useLazy: PromotionDiscountsQuery_useLazy,
  useLazyWithVariables: PromotionDiscountsQuery_useLazyWithVariables
};

function runScanDiscounts(discountsFetch, cursor, dataOpt, onDone, param) {
  var data = dataOpt !== undefined ? dataOpt : [];
  Future.mapOk(FuturePromise.fromPromise(Curry._1(discountsFetch, cursor)), undefined, (function (response) {
          if (response.TAG !== /* Ok */0) {
            return Curry._1(onDone, /* Error */1);
          }
          var match = response._0;
          var match$1 = match.data.promotionCampaign;
          if (match$1 === undefined) {
            return Curry._1(onDone, /* Error */1);
          }
          var match$2 = match$1.discounts;
          var match$3 = match$2.pageInfo;
          var match$4 = match$3.hasNextPage;
          if (match$4 !== undefined && match$4) {
            if (match.error !== undefined) {
              return Curry._1(onDone, /* Error */1);
            } else {
              return runScanDiscounts(discountsFetch, match$3.endCursor, Belt_Array.concat(data, match$2.edges), onDone, undefined);
            }
          }
          if (match.error !== undefined) {
            return Curry._1(onDone, /* Error */1);
          } else {
            return Curry._1(onDone, /* Success */{
                        _0: Belt_Array.concat(data, match$2.edges)
                      });
          }
        }));
}

export {
  PromotionByCkuQuery ,
  PromotionDiscountsQuery ,
  runScanDiscounts ,
}
/* query Not a pure module */
