// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Auth__Context from "./Auth__Context.bs.js";

function useLogUser(impersonateOpt, param) {
  var impersonate = impersonateOpt !== undefined ? impersonateOpt : false;
  var match = Auth__Context.use(undefined);
  var dispatch = match[1];
  return function (jwt) {
    Curry._1(dispatch, {
          TAG: /* LogRequested */0,
          jwt: jwt,
          impersonate: impersonate
        });
  };
}

function useUnlogUser(param) {
  var match = Auth__Context.use(undefined);
  var dispatch = match[1];
  return function (param) {
    Curry._1(dispatch, /* Unlogged */1);
  };
}

function useState(param) {
  return Auth__Context.use(undefined)[0];
}

function useDispatch(param) {
  return Auth__Context.use(undefined)[1];
}

function useShops(param) {
  var match = Auth__Context.use(undefined);
  var state = match[0];
  if (typeof state === "number") {
    return [];
  } else if (state.TAG === /* Logging */0) {
    return [];
  } else {
    return state._0.shops;
  }
}

function useActiveShop(param) {
  var match = Auth__Context.use(undefined);
  var state = match[0];
  if (typeof state === "number" || state.TAG === /* Logging */0) {
    return ;
  } else {
    return state._0.activeShop;
  }
}

function useActiveShopExn(param) {
  return Belt_Option.getExn(useActiveShop(undefined));
}

function useScope(param) {
  var shops = useShops(undefined);
  var activeShop = useActiveShop(undefined);
  return React.useMemo((function () {
                if (shops.length > 1) {
                  return {
                          TAG: /* Organisation */0,
                          activeShop: activeShop,
                          shops: shops
                        };
                } else {
                  return {
                          TAG: /* Single */1,
                          _0: Belt_Option.getExn(activeShop)
                        };
                }
              }), [
              shops,
              activeShop
            ]);
}

var Context;

export {
  Context ,
  useLogUser ,
  useUnlogUser ,
  useState ,
  useDispatch ,
  useShops ,
  useActiveShop ,
  useActiveShopExn ,
  useScope ,
}
/* react Not a pure module */
