// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Stack from "./Stack.bs.js";
import * as Title from "../typography/Title.bs.js";
import * as React from "react";
import * as TextStyle from "../typography/TextStyle.bs.js";

function LayoutPanelHeading(Props) {
  var title = Props.title;
  var description = Props.description;
  return React.createElement(Stack.make, {
              children: null,
              space: "small"
            }, React.createElement(Title.make, {
                  children: title,
                  level: 3,
                  weight: "strong"
                }), React.createElement(TextStyle.make, {
                  children: description,
                  variation: "normal"
                }));
}

var make = React.memo(LayoutPanelHeading);

export {
  make ,
}
/* make Not a pure module */
