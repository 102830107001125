// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Env from "../../core/Env.bs.js";
import * as Form from "../../bundles/Form/Form.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Json from "../../primitives/Json.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as Title from "../../resources/typography/Title.bs.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Spaces from "../../resources/theme/Spaces.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Request from "../../core/Request.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AuthLoginPage from "./AuthLoginPage.bs.js";

function get(values, field) {
  if (field) {
    return values.confirm;
  } else {
    return values.password;
  }
}

function set(values, field, value) {
  if (field) {
    return {
            password: values.password,
            confirm: value
          };
  } else {
    return {
            password: value,
            confirm: values.confirm
          };
  }
}

var LoginResetFormLenses = {
  get: get,
  set: set
};

var LoginResetForm = Form.Make(LoginResetFormLenses);

var styles = $$StyleSheet.create({
      container: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, "column", undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(-10)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(465)), undefined, undefined),
      title: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.large)), undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(100)), undefined, undefined)
    });

var endpoint = Env.gatewayUrl(undefined) + "/auth/password-reset";

function encodeBody(tokenId, token, userId, newPassword) {
  return Json.encodeDict(Js_dict.fromArray([
                  [
                    "tokenId",
                    Json.encodeString(tokenId)
                  ],
                  [
                    "token",
                    Json.encodeString(token)
                  ],
                  [
                    "userId",
                    Json.encodeString(userId)
                  ],
                  [
                    "newPassword",
                    Json.encodeString(newPassword)
                  ]
                ]));
}

function decodeInvalidRequestFailure(error) {
  switch (error.kind) {
    case "ExpiredOneTimeToken" :
        return /* ExpiredOneTimeToken */0;
    case "NotFoundPasswordResetOneTimeToken" :
        return /* NotFoundPasswordResetOneTimeToken */1;
    default:
      return /* UnknownServerFailure */2;
  }
}

function make(tokenId, token, userId, newPassword) {
  return Future.mapError($$Request.make("POST", Caml_option.some(encodeBody(tokenId, token, userId, newPassword)), false, undefined, endpoint), undefined, (function (error) {
                if (typeof error === "number" || error.TAG !== /* InvalidRequestFailures */2) {
                  return ;
                } else {
                  return Belt_Option.map(Belt_Array.get(error._0, 0), decodeInvalidRequestFailure);
                }
              }));
}

var ResetPasswordRequest = {
  endpoint: endpoint,
  encodeBody: encodeBody,
  decodeInvalidRequestFailure: decodeInvalidRequestFailure,
  make: make
};

var schema = [
  {
    TAG: /* Password */6,
    _0: /* Password */0
  },
  {
    TAG: /* CustomString */8,
    _0: /* Confirm */1,
    _1: (function (value, values) {
        if (values.password === value) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: $$Intl.t("Password and its confirmation must be identical")
                };
        }
      })
  }
];

function AuthLoginResetPage(Props) {
  var userId = Props.userId;
  var tokenId = Props.tokenId;
  var token = Props.token;
  var successRoute = Props.successRoute;
  var resetPasswordRequest = Props.resetPasswordRequest;
  var match = React.useState(function () {
        
      });
  var setNotfication = match[1];
  var notification = match[0];
  var navigate = Navigation.useNavigate(undefined);
  var onSubmitSuccess = function (res) {
    if (res !== undefined) {
      if (res === $$Intl.t("Your password has been correctly modified")) {
        return Curry._3(navigate, undefined, undefined, successRoute);
      } else {
        return Curry._1(setNotfication, (function (param) {
                      return {
                              TAG: /* Danger */1,
                              _0: res
                            };
                    }));
      }
    }
    
  };
  var onSubmitFailure = function (error) {
    Curry._1(setNotfication, (function (param) {
            return {
                    TAG: /* Danger */1,
                    _0: error
                  };
          }));
  };
  var formPropState = Curry._1(LoginResetForm.useFormPropState, {
        initialValues: {
          password: "",
          confirm: ""
        },
        schema: schema,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  var onSubmit = function (param, param$1) {
    return Future.mapError(Future.mapOk(Curry._4(resetPasswordRequest, tokenId, token, userId, param$1.password), undefined, (function (param) {
                      return $$Intl.t("Your password has been correctly modified");
                    })), undefined, (function (error) {
                  if (error !== undefined && error < 2) {
                    return $$Intl.t("The link has expired");
                  } else {
                    return $$Intl.t("An unexpected error occured. Please try again or contact the support.");
                  }
                }));
  };
  var onRequestCloseNotificationBanner = function (param) {
    Curry._1(setNotfication, (function (param) {
            
          }));
  };
  return React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.container), undefined, undefined, undefined), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Title.make, {
                              children: $$Intl.t("Password resetting"),
                              level: 1
                            })), Caml_option.some(styles.title), undefined, undefined, undefined)), notification !== undefined ? React.createElement(AuthLoginPage.NotificationBanner.make, {
                    notification: notification,
                    onRequestClose: onRequestCloseNotificationBanner
                  }) : null, React.createElement(LoginResetForm.Core.Provider.make, {
                  children: null,
                  propState: formPropState
                }, React.createElement(LoginResetForm.ControlEnterKey.make, {
                      onSubmit: onSubmit
                    }), React.createElement(Stack.make, {
                      children: null,
                      space: "large"
                    }, React.createElement(LoginResetForm.InputPassword.make, {
                          field: /* Password */0,
                          label: $$Intl.t("New password"),
                          placeholder: $$Intl.t("New password"),
                          hideError: true,
                          hideRequired: true,
                          showTypingValidation: true
                        }), React.createElement(LoginResetForm.InputPassword.make, {
                          field: /* Confirm */1,
                          label: $$Intl.t("New password confirmation"),
                          placeholder: $$Intl.t("New password confirmation"),
                          hideRequired: true
                        }), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(LoginResetForm.SubmitButton.make, {
                                      onSubmit: onSubmit,
                                      text: $$Intl.t("Reset password")
                                    })), undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)))));
}

var resetPasswordRequest = make;

var make$1 = AuthLoginResetPage;

export {
  LoginResetFormLenses ,
  LoginResetForm ,
  styles ,
  ResetPasswordRequest ,
  resetPasswordRequest ,
  schema ,
  make$1 as make,
}
/* LoginResetForm Not a pure module */
