// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Card from "./Card.bs.js";
import * as React from "react";
import * as Column from "./Column.bs.js";
import * as Columns from "./Columns.bs.js";
import * as StackFields from "./StackFields.bs.js";
import * as LayoutPanelHeading from "./LayoutPanelHeading.bs.js";

function FieldsetLayoutPanel(Props) {
  var title = Props.title;
  var description = Props.description;
  var children = Props.children;
  return React.createElement(Columns.make, {
              children: null,
              space: "xlarge"
            }, React.createElement(Column.make, {
                  children: React.createElement(LayoutPanelHeading.make, {
                        title: title,
                        description: description
                      }),
                  width: "quarter"
                }), React.createElement(Column.make, {
                  children: React.createElement(Card.make, {
                        children: React.createElement(StackFields.make, {
                              children: children
                            })
                      }),
                  width: "three_quarter"
                }));
}

var make = React.memo(FieldsetLayoutPanel);

export {
  make ,
}
/* make Not a pure module */
