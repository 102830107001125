// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as OrderEditForm from "../../modules/Order/OrderEditForm.bs.js";
import * as OrderEdit__Utils from "./OrderEdit__Utils.bs.js";
import * as Accounting__Maker from "@wino/accounting/src/Accounting__Maker.bs.js";
import * as Accounting__Computer from "@wino/accounting/src/Accounting__Computer.bs.js";
import * as Accounting__Formatter from "@wino/accounting/src/Accounting__Formatter.bs.js";
import * as Accounting__Serializer from "@wino/accounting/src/Accounting__Serializer.bs.js";

var initialCart = Accounting__Formatter.make(Accounting__Computer.make(Accounting__Maker.Cart.make(OrderEditForm.initialCart)));

function makeInitialOrder(supplierIdOpt, shopIdOpt, deviceIdOpt, param) {
  var supplierId = supplierIdOpt !== undefined ? supplierIdOpt : "";
  var shopId = shopIdOpt !== undefined ? shopIdOpt : "";
  var deviceId = deviceIdOpt !== undefined ? deviceIdOpt : "";
  return /* Data */{
          _0: {
            name: "Draft",
            statuses: ["DRAFT"],
            updatedAt: undefined,
            initialValues: {
              condition: "",
              noteForSupplier: "",
              supplierId: supplierId,
              issueDate: new Date(Date.now()),
              estimatedReceptionDate: new Date(Date.now()),
              note: "",
              cart: initialCart,
              shopId: shopId,
              deviceId: deviceId,
              supplierName: "",
              supplierAddress: "",
              supplierPostalCode: "",
              supplierCity: "",
              supplierCountry: ""
            },
            updatedStocks: undefined,
            receptionFinishedAt: Caml_option.some(new Date(Date.now()))
          }
        };
}

function make(id, orderQuery, orderVariantsStockQuery, authState, supplierId, shopId) {
  if (id !== undefined) {
    if (orderQuery.TAG !== /* Executed */0) {
      return /* Loading */1;
    }
    if (orderQuery._0.loading) {
      return /* Loading */1;
    }
    if (orderVariantsStockQuery.loading) {
      return /* Loading */1;
    }
    
  }
  if (typeof authState === "number") {
    return /* Loading */1;
  }
  if (authState.TAG === /* Logging */0) {
    return /* Loading */1;
  }
  if (authState._0.shops.length === 0) {
    return /* Loading */1;
  }
  if (id !== undefined) {
    var match = orderQuery._0.data;
    if (match === undefined) {
      return /* Error */0;
    }
    var order = match.order;
    if (order === undefined) {
      return /* Error */0;
    }
    var match$1 = orderVariantsStockQuery.data;
    if (match$1 !== undefined) {
      var match$2 = match$1.order;
      if (match$2 === undefined) {
        return /* Error */0;
      }
      var updatedStocks = Belt_Array.keepMap(match$2.products.edges, (function (param) {
              var orderProduct = param.node;
              var match = orderProduct.variant;
              if (match === undefined) {
                return ;
              }
              var quantity = match.stock.rawQuantity;
              if (quantity !== undefined) {
                return {
                        productId: orderProduct.id,
                        rawQuantity: quantity
                      };
              }
              
            }));
      return /* Data */{
              _0: {
                name: order.formattedName,
                statuses: order.formattedStatus,
                updatedAt: Caml_option.some(order.updatedAt),
                initialValues: {
                  condition: Belt_Option.getWithDefault(order.condition, ""),
                  noteForSupplier: Belt_Option.getWithDefault(order.noteForSupplier, ""),
                  supplierId: Belt_Option.getWithDefault(Belt_Option.map(order.supplier, (function (supplier) {
                              return supplier.id;
                            })), Belt_Option.getWithDefault(supplierId, "")),
                  issueDate: order.issueDate,
                  estimatedReceptionDate: order.estimatedReceptionDate,
                  note: Belt_Option.getWithDefault(order.note, ""),
                  cart: OrderEdit__Utils.updateCartStocks(Accounting__Serializer.deserialize(order.cart), updatedStocks, order.formattedStatus),
                  shopId: order.shop.id,
                  deviceId: order.device.id,
                  supplierName: Belt_Option.getWithDefault(Belt_Option.map(order.supplier, (function (supplier) {
                              return supplier.companyName;
                            })), ""),
                  supplierAddress: Belt_Option.getWithDefault(Belt_Option.map(order.supplier, (function (supplier) {
                              return Belt_Array.reduce(Belt_Array.keepMap(supplier.locations.edges, (function (param) {
                                                var node = param.node;
                                                var match = node.address;
                                                if (match !== undefined) {
                                                  return node.address;
                                                }
                                                
                                              })), "", (function (acc, address) {
                                            return acc + (
                                                    acc.length > 0 ? " | " : ""
                                                  ) + address;
                                          }));
                            })), ""),
                  supplierPostalCode: Belt_Option.getWithDefault(Belt_Option.map(order.supplier, (function (supplier) {
                              return Belt_Array.reduce(Belt_Array.keepMap(supplier.locations.edges, (function (param) {
                                                var node = param.node;
                                                var match = node.postalCode;
                                                if (match !== undefined) {
                                                  return node.postalCode;
                                                }
                                                
                                              })), "", (function (acc, postalCode) {
                                            return acc + (
                                                    acc.length > 0 ? " | " : ""
                                                  ) + postalCode;
                                          }));
                            })), ""),
                  supplierCity: Belt_Option.getWithDefault(Belt_Option.map(order.supplier, (function (supplier) {
                              return Belt_Array.reduce(Belt_Array.keepMap(supplier.locations.edges, (function (param) {
                                                var node = param.node;
                                                var match = node.city;
                                                if (match !== undefined) {
                                                  return node.city;
                                                }
                                                
                                              })), "", (function (acc, city) {
                                            return acc + (
                                                    acc.length > 0 ? " | " : ""
                                                  ) + city;
                                          }));
                            })), ""),
                  supplierCountry: Belt_Option.getWithDefault(Belt_Option.map(order.supplier, (function (supplier) {
                              return Belt_Array.reduce(Belt_Array.keepMap(supplier.locations.edges, (function (param) {
                                                var node = param.node;
                                                var match = node.country;
                                                if (match !== undefined) {
                                                  return node.country;
                                                }
                                                
                                              })), "", (function (acc, country) {
                                            return acc + (
                                                    acc.length > 0 ? " | " : ""
                                                  ) + country;
                                          }));
                            })), "")
                },
                updatedStocks: updatedStocks,
                receptionFinishedAt: order.receptionFinishedAt
              }
            };
    }
    var networkStatus = orderVariantsStockQuery.networkStatus;
    if (networkStatus === /* SkippedOrNotPresent */7 || networkStatus === /* Ready */5) {
      return /* Data */{
              _0: {
                name: order.formattedName,
                statuses: order.formattedStatus,
                updatedAt: Caml_option.some(order.updatedAt),
                initialValues: {
                  condition: Belt_Option.getWithDefault(order.condition, ""),
                  noteForSupplier: Belt_Option.getWithDefault(order.noteForSupplier, ""),
                  supplierId: Belt_Option.getWithDefault(Belt_Option.map(order.supplier, (function (supplier) {
                              return supplier.id;
                            })), ""),
                  issueDate: order.issueDate,
                  estimatedReceptionDate: order.estimatedReceptionDate,
                  note: Belt_Option.getWithDefault(order.note, ""),
                  cart: Accounting__Serializer.deserialize(order.cart),
                  shopId: order.shop.id,
                  deviceId: order.device.id,
                  supplierName: Belt_Option.getWithDefault(Belt_Option.map(order.supplier, (function (supplier) {
                              return supplier.companyName;
                            })), ""),
                  supplierAddress: Belt_Option.getWithDefault(Belt_Option.map(order.supplier, (function (supplier) {
                              return Belt_Array.reduce(Belt_Array.keepMap(supplier.locations.edges, (function (param) {
                                                var node = param.node;
                                                var match = node.address;
                                                if (match !== undefined) {
                                                  return node.address;
                                                }
                                                
                                              })), "", (function (acc, address) {
                                            return acc + (
                                                    acc.length > 0 ? " | " : ""
                                                  ) + address;
                                          }));
                            })), ""),
                  supplierPostalCode: Belt_Option.getWithDefault(Belt_Option.map(order.supplier, (function (supplier) {
                              return Belt_Array.reduce(Belt_Array.keepMap(supplier.locations.edges, (function (param) {
                                                var node = param.node;
                                                var match = node.postalCode;
                                                if (match !== undefined) {
                                                  return node.postalCode;
                                                }
                                                
                                              })), "", (function (acc, postalCode) {
                                            return acc + (
                                                    acc.length > 0 ? " | " : ""
                                                  ) + postalCode;
                                          }));
                            })), ""),
                  supplierCity: Belt_Option.getWithDefault(Belt_Option.map(order.supplier, (function (supplier) {
                              return Belt_Array.reduce(Belt_Array.keepMap(supplier.locations.edges, (function (param) {
                                                var node = param.node;
                                                var match = node.city;
                                                if (match !== undefined) {
                                                  return node.city;
                                                }
                                                
                                              })), "", (function (acc, city) {
                                            return acc + (
                                                    acc.length > 0 ? " | " : ""
                                                  ) + city;
                                          }));
                            })), ""),
                  supplierCountry: Belt_Option.getWithDefault(Belt_Option.map(order.supplier, (function (supplier) {
                              return Belt_Array.reduce(Belt_Array.keepMap(supplier.locations.edges, (function (param) {
                                                var node = param.node;
                                                var match = node.country;
                                                if (match !== undefined) {
                                                  return node.country;
                                                }
                                                
                                              })), "", (function (acc, country) {
                                            return acc + (
                                                    acc.length > 0 ? " | " : ""
                                                  ) + country;
                                          }));
                            })), "")
                },
                updatedStocks: undefined,
                receptionFinishedAt: order.receptionFinishedAt
              }
            };
    } else {
      return /* Error */0;
    }
  }
  var match$3 = authState._0;
  var activeShop = match$3.activeShop;
  var match$4;
  if (shopId !== undefined) {
    var shop = Belt_Array.getBy(match$3.shops, (function (shop) {
            return shop.id === shopId;
          }));
    match$4 = [
      Belt_Option.map(shop, (function (shop) {
              return shop.id;
            })),
      Belt_Option.map(shop, (function (shop) {
              return shop.activeWebDeviceId;
            }))
    ];
  } else {
    match$4 = activeShop !== undefined ? [
        activeShop.id,
        activeShop.activeWebDeviceId
      ] : [
        undefined,
        undefined
      ];
  }
  return makeInitialOrder(supplierId, match$4[0], match$4[1], undefined);
}

function use(id, authState, response, supplierId, shopId) {
  var match = React.useState(function () {
        return {
                id: id,
                response: response,
                authState: authState
              };
      });
  var setDerived = match[1];
  var derived = match[0];
  React.useEffect((function () {
          var match = derived.response;
          var match$1 = derived.id;
          var exit = 0;
          if (!(response.TAG === /* Executed */0 && response._0.loading && match.TAG === /* Executed */0 && !(match._0.data === undefined && match$1 !== undefined))) {
            exit = 1;
          }
          if (exit === 1) {
            Curry._1(setDerived, (function (param) {
                    return {
                            id: id,
                            response: response,
                            authState: authState
                          };
                  }));
          }
          
        }), [
        response,
        authState
      ]);
  var partial_arg = derived.response;
  var partial_arg$1 = derived.id;
  var arg = derived.authState;
  return function (param) {
    return make(partial_arg$1, partial_arg, param, arg, supplierId, shopId);
  };
}

var Utils;

var OrderQuery;

var OrderVariantsStockQuery;

export {
  Utils ,
  OrderQuery ,
  OrderVariantsStockQuery ,
  initialCart ,
  makeInitialOrder ,
  make ,
  use ,
}
/* initialCart Not a pure module */
