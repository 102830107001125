// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ApolloConfig from "../../core/ApolloConfig.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as InputNumberField from "../../resources/selection-and-input/InputNumberField.bs.js";
import * as ReactUpdateDebounced from "../../primitives/ReactUpdateDebounced.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = Client.gql(["mutation updateVariant_purchasePrice($id: ID!, $input: InputUpdateVariant!)  {\nupdateVariant(id: $id, input: $input)  {\n__typename  \nid  \npurchasedPrice  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.purchasedPrice;
  return {
          updateVariant: {
            __typename: value$1.__typename,
            id: value$1.id,
            purchasedPrice: !(value$2 == null) ? value$2 : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.purchasedPrice;
  var purchasedPrice = value$2 !== undefined ? value$2 : null;
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var updateVariant = {
    __typename: value$4,
    id: value$3,
    purchasedPrice: purchasedPrice
  };
  return {
          updateVariant: updateVariant
        };
}

function serializeInputObjectInputUpdateVariantMetaData(inp) {
  var a = inp.shopifyVariantId;
  return {
          shopifyVariantId: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInputUpdateVariant(inp) {
  var a = inp.stockKeepingUnit;
  var a$1 = inp.name;
  var a$2 = inp.purchasedPrice;
  var a$3 = inp.year;
  var a$4 = inp.capacityValue;
  var a$5 = inp.capacityUnit;
  var a$6 = inp.capacityPrecision;
  var a$7 = inp.alcoholVolume;
  var a$8 = inp.ean13;
  var a$9 = inp.internalNote;
  var a$10 = inp.metadata;
  var a$11 = inp.tastingNote;
  var a$12 = inp.packaging;
  var a$13 = inp.internalCode;
  var a$14 = inp.priceLookUpCode;
  var a$15 = inp.supplierId;
  var a$16 = inp.minStockThreshold;
  var a$17 = inp.maxStockThreshold;
  var a$18 = inp.stockOrderTriggerThreshold;
  return {
          stockKeepingUnit: a !== undefined ? a : undefined,
          name: a$1 !== undefined ? a$1 : undefined,
          purchasedPrice: a$2 !== undefined ? a$2 : undefined,
          year: a$3 !== undefined ? a$3 : undefined,
          capacityValue: a$4 !== undefined ? a$4 : undefined,
          capacityUnit: a$5 !== undefined ? a$5 : undefined,
          capacityPrecision: a$6 !== undefined ? a$6 : undefined,
          alcoholVolume: a$7 !== undefined ? a$7 : undefined,
          ean13: a$8 !== undefined ? a$8 : undefined,
          internalNote: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          metadata: a$10 !== undefined ? serializeInputObjectInputUpdateVariantMetaData(a$10) : undefined,
          tastingNote: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          packaging: a$12 !== undefined ? a$12 : undefined,
          internalCode: a$13 !== undefined ? a$13 : undefined,
          priceLookUpCode: a$14 !== undefined ? a$14 : undefined,
          supplierId: a$15 !== undefined ? a$15 : undefined,
          minStockThreshold: a$16 !== undefined ? a$16 : undefined,
          maxStockThreshold: a$17 !== undefined ? a$17 : undefined,
          stockOrderTriggerThreshold: a$18 !== undefined ? a$18 : undefined
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id,
          input: serializeInputObjectInputUpdateVariant(inp.input)
        };
}

function makeVariables(id, input, param) {
  return {
          id: id,
          input: input
        };
}

function makeInputObjectInputUpdateVariant(stockKeepingUnit, name, purchasedPrice, year, capacityValue, capacityUnit, capacityPrecision, alcoholVolume, ean13, internalNote, metadata, tastingNote, packaging, internalCode, priceLookUpCode, supplierId, minStockThreshold, maxStockThreshold, stockOrderTriggerThreshold, param) {
  return {
          stockKeepingUnit: stockKeepingUnit,
          name: name,
          purchasedPrice: purchasedPrice,
          year: year,
          capacityValue: capacityValue,
          capacityUnit: capacityUnit,
          capacityPrecision: capacityPrecision,
          alcoholVolume: alcoholVolume,
          ean13: ean13,
          internalNote: internalNote,
          metadata: metadata,
          tastingNote: tastingNote,
          packaging: packaging,
          internalCode: internalCode,
          priceLookUpCode: priceLookUpCode,
          supplierId: supplierId,
          minStockThreshold: minStockThreshold,
          maxStockThreshold: maxStockThreshold,
          stockOrderTriggerThreshold: stockOrderTriggerThreshold
        };
}

function makeInputObjectInputUpdateVariantMetaData(shopifyVariantId, param) {
  return {
          shopifyVariantId: shopifyVariantId
        };
}

var VariantPurchasePriceMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var VariantPurchasePriceMutation_useWithVariables = include.useWithVariables;

var VariantPurchasePriceMutation = {
  VariantPurchasePriceMutation_inner: VariantPurchasePriceMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData,
  use: use,
  useWithVariables: VariantPurchasePriceMutation_useWithVariables
};

function PricePurchaseTableEditCell(Props) {
  var value = Props.value;
  var minRetailPrice = Props.minRetailPrice;
  var bulkUnit = Props.bulkUnit;
  var variantId = Props.variantId;
  var autoFocusedOpt = Props.autoFocused;
  var onChange = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var autoFocused = autoFocusedOpt !== undefined ? autoFocusedOpt : false;
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutation = match[1];
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setOptimisticValue = match$1[1];
  var optimisticValue = match$1[0];
  var debouncedValue = ReactUpdateDebounced.use(optimisticValue, ApolloConfig.mutationDebounceInterval);
  var onMutatePurchasePriceRequested = React.useCallback((function (purchasePrice) {
          var input = makeInputObjectInputUpdateVariant(undefined, undefined, purchasePrice, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
          Future.get(FuturePromise.fromPromise(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                        id: variantId,
                        input: input
                      })), (function (response) {
                  if (response.TAG === /* Ok */0) {
                    var match = response._0;
                    if (match.TAG === /* Ok */0 && match._0.error === undefined) {
                      return ;
                    }
                    
                  }
                  Curry._1(setOptimisticValue, (function (param) {
                          
                        }));
                }));
        }), []);
  React.useEffect((function () {
          if (!mutation.loading && debouncedValue !== undefined && optimisticValue !== undefined && value !== optimisticValue) {
            Curry._1(onMutatePurchasePriceRequested, debouncedValue);
          }
          
        }), [debouncedValue]);
  var onChange$1 = React.useCallback((function (value) {
          if (onChange !== undefined) {
            Curry._1(onChange, value);
          }
          Curry._1(setOptimisticValue, (function (param) {
                  return value;
                }));
        }), []);
  var onBlur$1 = Belt_Option.map(onBlur, (function (onBlur, param) {
          if (optimisticValue !== undefined && value !== optimisticValue) {
            Curry._1(onMutatePurchasePriceRequested, optimisticValue);
          }
          Curry._1(onBlur, undefined);
        }));
  var errorMessage = minRetailPrice !== undefined && value > minRetailPrice && minRetailPrice !== 0 ? $$Intl.t("The purchase price shouldn't exceed the entered retail prices.") : undefined;
  var tmp = {
    value: Belt_Option.getWithDefault(optimisticValue, value),
    minValue: 0,
    precision: 3,
    appender: {
      TAG: /* Custom */1,
      _0: $$Intl.toCurrencySymbol("EUR") + Belt_Option.mapWithDefault(bulkUnit, "", (function (unit) {
              return " / " + unit + "";
            }))
    },
    shrinkInput: true,
    autoFocused: autoFocused,
    onChange: onChange$1
  };
  if (errorMessage !== undefined) {
    tmp.errorMessage = errorMessage;
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (onBlur$1 !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur$1);
  }
  return React.createElement(InputNumberField.make, tmp);
}

var make = React.memo(PricePurchaseTableEditCell, (function (oldProps, newProps) {
        if (oldProps.value === newProps.value && oldProps.minRetailPrice === newProps.minRetailPrice && oldProps.bulkUnit === newProps.bulkUnit) {
          return oldProps.variantId === newProps.variantId;
        } else {
          return false;
        }
      }));

export {
  VariantPurchasePriceMutation ,
  make ,
}
/* query Not a pure module */
