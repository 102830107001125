// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function StockActivitySourceTableCell(Props) {
  var deviceName = Props.deviceName;
  var shopName = Props.shopName;
  return React.createElement(Stack.make, {
              children: null,
              space: Belt_Option.isSome(shopName) ? "xxsmall" : "none"
            }, React.createElement(TextStyle.make, {
                  children: deviceName
                }), shopName !== undefined ? React.createElement(TextStyle.make, {
                    children: shopName,
                    variation: "normal",
                    size: "xxsmall"
                  }) : null);
}

var make = React.memo(StockActivitySourceTableCell);

export {
  make ,
}
/* make Not a pure module */
