// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as CatalogDuplicationForm from "./CatalogDuplicationForm.bs.js";
import * as CatalogDuplicationFormMutations from "./CatalogDuplicationFormMutations.bs.js";

function CatalogDuplicationFormPageActions(Props) {
  var duplicationMode = Props.duplicationMode;
  var duplicateProduct = CatalogDuplicationFormMutations.useDuplicateProduct(undefined);
  var duplicateVariant = CatalogDuplicationFormMutations.useDuplicateVariant(undefined);
  return React.createElement(Inline.make, {
              children: React.createElement(CatalogDuplicationForm.SubmitButton.make, {
                    variation: "success",
                    size: "medium",
                    onSubmit: duplicationMode ? duplicateVariant : duplicateProduct,
                    text: $$Intl.t("Duplicate")
                  }),
              space: "small"
            });
}

var make = React.memo(CatalogDuplicationFormPageActions);

var Mutations;

export {
  Mutations ,
  make ,
}
/* make Not a pure module */
