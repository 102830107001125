// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Env from "../../core/Env.bs.js";
import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Json from "../../primitives/Json.bs.js";
import * as Page from "../../resources/layout-and-structure/Page.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as Title from "../../resources/typography/Title.bs.js";
import * as React from "react";
import * as Banner from "../../resources/feedback-indicators/Banner.bs.js";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Select from "../../resources/selection-and-input/Select.bs.js";
import * as Divider from "../../resources/layout-and-structure/Divider.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Request from "../../core/Request.bs.js";
import * as AsyncData from "../../primitives/AsyncData.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as HelpCenter from "../../core/HelpCenter.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as TextAction from "../../resources/actions/TextAction.bs.js";
import * as AsyncResult from "../../primitives/AsyncResult.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DateHelpers from "../../helpers/DateHelpers.bs.js";
import * as Placeholder from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as PaymentMethod from "../../modules/PaymentMethod.bs.js";
import * as SessionTracker from "../../core/SessionTracker.bs.js";
import * as TextIconButton from "../../resources/actions/TextIconButton.bs.js";
import * as TriggerDownload from "../../primitives/TriggerDownload.bs.js";
import * as Stylex from "@stylexjs/stylex";
import * as AccountingVendor from "../../modules/AccountingVendor.bs.js";
import * as ResourceDetailsPage from "../../core/ResourceDetailsPage.bs.js";
import * as SelectDateRangeFilter from "../../resources/selection-and-input/SelectDateRangeFilter.bs.js";
import * as FiscalYearOpeningMonth from "../../modules/FiscalYearOpeningMonth.bs.js";
import Accounting_figurePng from "./accounting_figure.png";

var figureUri = Accounting_figurePng;

function isEqual(a, b) {
  if (typeof a === "number") {
    switch (a) {
      case /* Yesterday */0 :
          return b === 0;
      case /* LastWeek */1 :
          return b === 1;
      case /* LastMonth */2 :
          return b === 2;
      case /* NotDefined */3 :
          if (typeof b === "number") {
            return b >= 3;
          } else {
            return false;
          }
      
    }
  } else {
    switch (a.TAG | 0) {
      case /* CurrentFiscalYear */0 :
          if (typeof b === "number" || b.TAG !== /* CurrentFiscalYear */0) {
            return false;
          } else {
            return FiscalYearOpeningMonth.isEqual(a.fiscalYearOpeningMonth, b.fiscalYearOpeningMonth);
          }
      case /* LastFiscalYear */1 :
          if (typeof b === "number" || b.TAG !== /* LastFiscalYear */1) {
            return false;
          } else {
            return FiscalYearOpeningMonth.isEqual(a.fiscalYearOpeningMonth, b.fiscalYearOpeningMonth);
          }
      case /* Custom */2 :
          if (typeof b === "number" || b.TAG !== /* Custom */2) {
            return false;
          } else {
            return true;
          }
      
    }
  }
}

function isCustomOrNotDefined(value) {
  if (typeof value === "number") {
    if (value === /* NotDefined */3) {
      return true;
    } else {
      return false;
    }
  } else if (value.TAG === /* Custom */2) {
    return true;
  } else {
    return false;
  }
}

function toString(notDefinedPlaceholder, preset) {
  var divider = " → ";
  if (typeof preset === "number") {
    switch (preset) {
      case /* Yesterday */0 :
          return $$Intl.t("Yesterday");
      case /* LastWeek */1 :
          return $$Intl.t("Last week");
      case /* LastMonth */2 :
          return $$Intl.t("Last month");
      case /* NotDefined */3 :
          return notDefinedPlaceholder;
      
    }
  } else {
    switch (preset.TAG | 0) {
      case /* CurrentFiscalYear */0 :
          return $$Intl.t("Current fiscal year");
      case /* LastFiscalYear */1 :
          return $$Intl.t("Last fiscal year");
      case /* Custom */2 :
          var match = preset._0;
          var startDate = match[0];
          if (startDate === undefined) {
            return $$Intl.t("Custom");
          }
          var endDate = match[1];
          var startDate$1 = Caml_option.valFromOption(startDate);
          if (endDate === undefined) {
            return $$Intl.dateTimeFormat("medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, startDate$1) + divider + " …";
          }
          var endDate$1 = Caml_option.valFromOption(endDate);
          if (DateHelpers.isSameDay(startDate$1, endDate$1)) {
            return $$Intl.dateTimeFormat("medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, startDate$1);
          } else {
            return $$Intl.dateTimeFormat("medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, startDate$1) + divider + $$Intl.dateTimeFormat("medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, endDate$1);
          }
      
    }
  }
}

function getCurrentFiscalYearStart(now, fiscalYearOpeningMonth) {
  var fiscalOpeningMonth = FiscalYearOpeningMonth.toDateMonthJsIndex(fiscalYearOpeningMonth);
  if (now.getMonth() < fiscalOpeningMonth) {
    return now.getFullYear() - 1;
  } else {
    return now.getFullYear();
  }
}

function toDateRange(preset) {
  var now = new Date(Date.now());
  if (typeof preset === "number") {
    switch (preset) {
      case /* Yesterday */0 :
          return [
                  DateHelpers.subDays(DateHelpers.startOfDay(now), 1),
                  DateHelpers.endOfDay(DateHelpers.subDays(now, 1))
                ];
      case /* LastWeek */1 :
          return [
                  DateHelpers.startOfWeek(DateHelpers.subWeeks(now, 1)),
                  DateHelpers.endOfWeek(DateHelpers.subWeeks(now, 1))
                ];
      case /* LastMonth */2 :
          return [
                  DateHelpers.startOfMonth(DateHelpers.subMonths(now, 1)),
                  DateHelpers.endOfMonth(DateHelpers.subMonths(now, 1))
                ];
      case /* NotDefined */3 :
          return ;
      
    }
  } else {
    switch (preset.TAG | 0) {
      case /* CurrentFiscalYear */0 :
          var fiscalYearOpeningMonth = preset.fiscalYearOpeningMonth;
          var startDate = DateHelpers.startOfDay(new Date(getCurrentFiscalYearStart(now, fiscalYearOpeningMonth), FiscalYearOpeningMonth.toDateMonthJsIndex(fiscalYearOpeningMonth), 1));
          var endDate = DateHelpers.endOfMonth(DateHelpers.addMonths(startDate, 11));
          return [
                  startDate,
                  endDate
                ];
      case /* LastFiscalYear */1 :
          var fiscalYearOpeningMonth$1 = preset.fiscalYearOpeningMonth;
          var startDate$1 = new Date(getCurrentFiscalYearStart(now, fiscalYearOpeningMonth$1) - 1, FiscalYearOpeningMonth.toDateMonthJsIndex(fiscalYearOpeningMonth$1), 1);
          var endDate$1 = DateHelpers.endOfMonth(DateHelpers.addMonths(startDate$1, 11));
          return [
                  startDate$1,
                  endDate$1
                ];
      case /* Custom */2 :
          var match = preset._0;
          var startDate$2 = match[0];
          if (startDate$2 === undefined) {
            return ;
          }
          var endDate$2 = match[1];
          if (endDate$2 !== undefined) {
            return [
                    Caml_option.valFromOption(startDate$2),
                    Caml_option.valFromOption(endDate$2)
                  ];
          } else {
            return ;
          }
      
    }
  }
}

function fromPartialDateRange(range, presets) {
  var startDateA = range[0];
  if (startDateA === undefined) {
    return /* NotDefined */3;
  }
  var endDateA = range[1];
  if (endDateA === undefined) {
    return {
            TAG: /* Custom */2,
            _0: range
          };
  }
  var endDateA$1 = Caml_option.valFromOption(endDateA);
  var startDateA$1 = Caml_option.valFromOption(startDateA);
  var preset = Belt_Array.getBy(presets, (function (preset) {
          var match = toDateRange(preset);
          if (match !== undefined && DateHelpers.isSameDay(startDateA$1, match[0])) {
            return DateHelpers.isSameDay(endDateA$1, match[1]);
          } else {
            return false;
          }
        }));
  return Belt_Option.getWithDefault(preset, {
              TAG: /* Custom */2,
              _0: range
            });
}

var DateRangePreset = {
  isEqual: isEqual,
  isCustomOrNotDefined: isCustomOrNotDefined,
  toString: toString,
  getCurrentFiscalYearStart: getCurrentFiscalYearStart,
  toDateRange: toDateRange,
  fromPartialDateRange: fromPartialDateRange
};

function AccountingPage$PageLayout(Props) {
  var title = Props.title;
  var children = Props.children;
  return React.createElement(Page.make, {
              children: React.createElement(Box.make, Box.makeProps(Caml_option.some(null), undefined, undefined, "large", undefined, undefined, undefined, true, undefined, undefined, undefined, undefined), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Banner.make, {
                                    textStatus: {
                                      TAG: /* Info */3,
                                      _0: "Nouveau : Export des écritures comptables ! Disponible pour tous jusqu’au 30 avril. Profitez-en gratuitement pendant la phase bêta, puis découvrez nos options d’abonnement pour continuer à en bénéficier."
                                    }
                                  })), undefined, undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Card.make, {
                        children: React.createElement(Stack.make, {
                              children: children,
                              space: "small",
                              align: "center"
                            }),
                        stretch: true,
                        centerContent: true
                      })),
              variation: "compact",
              title: title
            });
}

var PageLayout = {
  make: AccountingPage$PageLayout
};

function endpoint(vendor, shopId) {
  var baseUrl = Env.sheetUrl(undefined) + "/accounting-export/" + shopId;
  if (vendor) {
    return baseUrl + "/ecr-format";
  } else {
    return baseUrl + "/xlsx-format";
  }
}

function encodeBodyJson(shopId, dateRange) {
  return Json.encodeDict(Js_dict.fromArray([
                  [
                    "shopId",
                    Json.encodeString(shopId)
                  ],
                  [
                    "startDate",
                    Json.encodeNumber(dateRange[0].getTime())
                  ],
                  [
                    "endDate",
                    Json.encodeNumber(dateRange[1].getTime())
                  ],
                  [
                    "timeZone",
                    Json.encodeString($$Intl.timeZone)
                  ],
                  [
                    "accountingEntriesView",
                    Json.encodeString("sales")
                  ]
                ]));
}

function decodeInvalidRequestFailure(param) {
  var data = param.data;
  switch (param.kind) {
    case "AccountingPaymentExportItemMissingPaymentMethodConfiguration" :
        var dict = Belt_Option.flatMap(data, Json.decodeDict);
        var paymentMethod = Json.flatDecodeDictField(dict, "paymentMethod", PaymentMethod.decodeFromJson);
        if (paymentMethod !== undefined) {
          return {
                  TAG: /* MissingPaymentMethodAccountFailure */1,
                  _0: paymentMethod
                };
        } else {
          return /* UnknownFailure */3;
        }
    case "InvalidFiscalExportDateRange" :
        return /* InvalidFiscalExportDateRangeFailure */2;
    case "InvalidCreditNoteTax" :
    case "InvalidInvoiceTax" :
    case "InvalidRefundReceiptTax" :
        break;
    case "IsaComptaAccountNumberNotDefined" :
    case "IsaComptaTaxCodeNotDefined" :
        return /* MissingConfigurationForIsacomptaVendorFailure */0;
    case "NotFoundAccountingExportConfiguration" :
        return /* NotFoundAccountingConfigurationFailure */1;
    default:
      return /* UnknownFailure */3;
  }
  var dict$1 = Belt_Option.flatMap(data, Json.decodeDict);
  var name = Json.flatDecodeDictFieldString(dict$1, "name");
  var issueDate = Belt_Option.map(Json.flatDecodeDictFieldFloat(dict$1, "date"), (function (prim) {
          return new Date(prim);
        }));
  if (name !== undefined && issueDate !== undefined) {
    return {
            TAG: /* InvalidSaleTaxFailure */0,
            name: name,
            issueDate: Caml_option.valFromOption(issueDate)
          };
  } else {
    return /* UnknownFailure */3;
  }
}

function decodeRequestError(error) {
  if (typeof error === "number" || error.TAG !== /* InvalidRequestFailures */2) {
    return /* UnknownFailure */3;
  } else {
    return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.get(error._0, 0), decodeInvalidRequestFailure), /* UnknownFailure */3);
  }
}

function decodeResponse(result) {
  return Belt_Option.map(Json.flatDecodeDictFieldString(Json.decodeDict(result), "url"), (function (prim) {
                return new URL(prim);
              }));
}

function make(shopId, dateRange, vendor) {
  return Future.flatMapOk(Future.mapError($$Request.make("POST", Caml_option.some(encodeBodyJson(shopId, dateRange)), undefined, undefined, endpoint(vendor, shopId)), undefined, decodeRequestError), undefined, (function (result) {
                var url = decodeResponse(result);
                return Future.value(url !== undefined ? ({
                                TAG: /* Ok */0,
                                _0: Caml_option.valFromOption(url)
                              }) : ({
                                TAG: /* Error */1,
                                _0: /* UnknownFailure */3
                              }));
              }));
}

var GetShopAccountingExportRequest = {
  endpoint: endpoint,
  encodeBodyJson: encodeBodyJson,
  decodeInvalidRequestFailure: decodeInvalidRequestFailure,
  decodeRequestError: decodeRequestError,
  decodeResponse: decodeResponse,
  make: make
};

var styles = Stylex.create({
      img: {
        height: "auto",
        marginBottom: "12px",
        marginTop: "-50px",
        maxWidth: "300px"
      },
      responsiveImg: {
        "@media screen and (min-height: 900px)": {
          marginBottom: "18px",
          maxWidth: "375px"
        }
      },
      content: {
        paddingTop: "10px"
      }
    });

var now = new Date();

var startPreviousMonth = DateHelpers.startOfMonth(DateHelpers.subMonths(now, 1));

var endPreviousMonth = DateHelpers.endOfMonth(DateHelpers.subMonths(now, 1));

function make$1(shop, vendor, fiscalYearOpeningMonth) {
  return {
          submission: /* NotAsked */0,
          shop: shop,
          dateRange: [
            startPreviousMonth,
            endPreviousMonth
          ],
          fiscalYearOpeningMonth: fiscalYearOpeningMonth,
          vendor: vendor
        };
}

var State = {
  now: now,
  startPreviousMonth: startPreviousMonth,
  endPreviousMonth: endPreviousMonth,
  make: make$1
};

function AccountingPage(Props) {
  var configurationRoute = Props.configurationRoute;
  var getShopAccountingConfigurationRequest = Props.getShopAccountingConfigurationRequest;
  var shops = Auth.useShops(undefined);
  var shop = Belt_Option.getWithDefault(Auth.useActiveShop(undefined), shops[0]);
  var captureEvent = SessionTracker.useCaptureEvent(undefined);
  var match = React.useState(function () {
        return AsyncData.notAsked(undefined);
      });
  var setAsyncState = match[1];
  var asyncState = match[0];
  var navigate = Navigation.useNavigate(undefined);
  React.useEffect((function () {
          Curry._1(setAsyncState, AsyncData.toBusy);
          var future = Curry._1(getShopAccountingConfigurationRequest, shop.id);
          Future.get(future, (function (shopAccountingConfiguration) {
                  if (shopAccountingConfiguration.TAG !== /* Ok */0) {
                    return Curry._1(setAsyncState, (function (param) {
                                  return {
                                          TAG: /* Done */1,
                                          _0: {
                                            TAG: /* Error */1,
                                            _0: undefined
                                          }
                                        };
                                }));
                  }
                  var match = shopAccountingConfiguration._0;
                  if (match === undefined) {
                    return Curry._1(setAsyncState, (function (param) {
                                  return {
                                          TAG: /* Done */1,
                                          _0: {
                                            TAG: /* Ok */0,
                                            _0: make$1(shop, /* Excel */0, /* January */0)
                                          }
                                        };
                                }));
                  }
                  if (match.isaComptaAccountNumber !== undefined) {
                    var fiscalYearOpeningMonth = match.fiscalYearOpeningMonth;
                    return Curry._1(setAsyncState, (function (param) {
                                  return {
                                          TAG: /* Done */1,
                                          _0: {
                                            TAG: /* Ok */0,
                                            _0: make$1(shop, /* IsaCompta */1, fiscalYearOpeningMonth)
                                          }
                                        };
                                }));
                  }
                  var fiscalYearOpeningMonth$1 = match.fiscalYearOpeningMonth;
                  Curry._1(setAsyncState, (function (param) {
                          return {
                                  TAG: /* Done */1,
                                  _0: {
                                    TAG: /* Ok */0,
                                    _0: make$1(shop, /* Excel */0, fiscalYearOpeningMonth$1)
                                  }
                                };
                        }));
                }));
          return (function (param) {
                    Future.cancel(future);
                  });
        }), []);
  var pageTitle = $$Intl.t("Accounting entries");
  if (typeof asyncState !== "number" && asyncState.TAG === /* Done */1) {
    var state = asyncState._0;
    if (state.TAG !== /* Ok */0) {
      return React.createElement(ResourceDetailsPage.make, {
                  variation: "compact",
                  title: pageTitle,
                  children: React.createElement(Placeholder.make, {
                        status: /* Error */2
                      })
                });
    }
    var state$1 = state._0;
    var handleSubmit = function (param) {
      var fn = function (prevState) {
        return {
                submission: /* Loading */1,
                shop: prevState.shop,
                dateRange: prevState.dateRange,
                fiscalYearOpeningMonth: prevState.fiscalYearOpeningMonth,
                vendor: prevState.vendor
              };
      };
      Curry._1(setAsyncState, (function (prevState) {
              return AsyncResult.mapOk(prevState, fn);
            }));
      var match = state$1.vendor;
      if (match) {
        Curry._1(captureEvent, "download_accounting_isacompta_export_file");
      } else {
        Curry._1(captureEvent, "download_accounting_excel_export_file");
      }
      Future.get(Future.flatMapOk(make(state$1.shop.id, state$1.dateRange, state$1.vendor), undefined, (function (url) {
                  return Future.mapError(TriggerDownload.fromUrl(undefined, url), undefined, (function (param) {
                                return /* UnknownFailure */3;
                              }));
                })), (function (result) {
              var fn = function (prevState) {
                return {
                        submission: {
                          TAG: /* Done */1,
                          _0: result
                        },
                        shop: prevState.shop,
                        dateRange: prevState.dateRange,
                        fiscalYearOpeningMonth: prevState.fiscalYearOpeningMonth,
                        vendor: prevState.vendor
                      };
              };
              Curry._1(setAsyncState, (function (prevState) {
                      return AsyncResult.mapOk(prevState, fn);
                    }));
            }));
    };
    var handleCloseBanner = function (param) {
      var fn = function (prevState) {
        return {
                submission: /* NotAsked */0,
                shop: prevState.shop,
                dateRange: prevState.dateRange,
                fiscalYearOpeningMonth: prevState.fiscalYearOpeningMonth,
                vendor: prevState.vendor
              };
      };
      Curry._1(setAsyncState, (function (prevState) {
              return AsyncResult.mapOk(prevState, fn);
            }));
    };
    var match$1 = Stylex.props(styles.content);
    var match$2 = Stylex.props(styles.img, styles.responsiveImg);
    var tmp = {
      src: figureUri
    };
    if (match$2.className !== undefined) {
      tmp.className = Caml_option.valFromOption(match$2.className);
    }
    if (match$2.style !== undefined) {
      tmp.style = Caml_option.valFromOption(match$2.style);
    }
    var tmp$1 = {};
    if (match$1.className !== undefined) {
      tmp$1.className = Caml_option.valFromOption(match$1.className);
    }
    if (match$1.style !== undefined) {
      tmp$1.style = Caml_option.valFromOption(match$1.style);
    }
    var match$3 = state$1.submission;
    var tmp$2;
    if (typeof match$3 === "number" || match$3.TAG !== /* Done */1) {
      tmp$2 = null;
    } else {
      var failure = match$3._0;
      if (failure.TAG === /* Ok */0) {
        var message = $$Intl.t("Your accounting entries have been successfully exported.");
        tmp$2 = React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Banner.make, {
                          textStatus: {
                            TAG: /* Success */0,
                            _0: message
                          },
                          onRequestClose: handleCloseBanner
                        })), undefined, undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined));
      } else {
        var failure$1 = failure._0;
        var tmp$3;
        if (typeof failure$1 === "number") {
          switch (failure$1) {
            case /* MissingConfigurationForIsacomptaVendorFailure */0 :
                tmp$3 = $$Intl.t("accounting.missing_configuration_for_isacompta_vendor_failure");
                break;
            case /* NotFoundAccountingConfigurationFailure */1 :
                tmp$3 = $$Intl.t("accounting.not_found_accounting_configuration_failure");
                break;
            case /* InvalidFiscalExportDateRangeFailure */2 :
                tmp$3 = $$Intl.t("The selected date range exceeds the boundaries of the fiscal year. Please ensure your start and end dates fall within a fiscal year.");
                break;
            case /* UnknownFailure */3 :
                tmp$3 = $$Intl.t("An unexpected error occured. Please try again or contact the support.");
                break;
            
          }
        } else {
          tmp$3 = failure$1.TAG === /* InvalidSaleTaxFailure */0 ? $$Intl.template($$Intl.t("accounting.invalid_sale_tax_failure{{name}}{{issueDate}}"), {
                  name: failure$1.name,
                  issueDate: $$Intl.dateTimeFormat("short", undefined, undefined, undefined, undefined, undefined, undefined, undefined, failure$1.issueDate)
                }, undefined) : $$Intl.template($$Intl.t("accounting.missing_payment_method_account_failure{{paymentMethod}}"), {
                  paymentMethod: PaymentMethod.toLabel(failure$1._0).toLowerCase()
                }, undefined);
        }
        tmp$2 = React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Banner.make, {
                          textStatus: {
                            TAG: /* Danger */1,
                            _0: tmp$3
                          },
                          onRequestClose: handleCloseBanner
                        })), undefined, undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined));
      }
    }
    var fiscalYearOpeningMonth = state$1.fiscalYearOpeningMonth;
    return React.createElement(AccountingPage$PageLayout, {
                title: pageTitle,
                children: null
              }, React.createElement("img", tmp), React.createElement(Title.make, {
                    children: $$Intl.t("Export my accounting entries"),
                    level: 2,
                    align: "center"
                  }), React.createElement("div", tmp$1, tmp$2, React.createElement(Inline.make, {
                        children: null,
                        space: "normal",
                        align: "center"
                      }, shops.length > 1 ? React.createElement(Auth.SelectSingleShopFilter.make, {
                              value: state$1.shop,
                              onChange: (function (shop) {
                                  var fn = function (prevState) {
                                    return {
                                            submission: prevState.submission,
                                            shop: shop,
                                            dateRange: prevState.dateRange,
                                            fiscalYearOpeningMonth: prevState.fiscalYearOpeningMonth,
                                            vendor: prevState.vendor
                                          };
                                  };
                                  Curry._1(setAsyncState, (function (prevState) {
                                          return AsyncResult.mapOk(prevState, fn);
                                        }));
                                })
                            }) : null, React.createElement(Select.make, {
                            label: $$Intl.t("Format"),
                            sections: [{
                                items: Belt_Array.map(AccountingVendor.values, AccountingVendor.toSelectItem)
                              }],
                            preset: "filter",
                            value: state$1.vendor,
                            onChange: (function (value) {
                                var fn = function (prevState) {
                                  return {
                                          submission: prevState.submission,
                                          shop: prevState.shop,
                                          dateRange: prevState.dateRange,
                                          fiscalYearOpeningMonth: prevState.fiscalYearOpeningMonth,
                                          vendor: value
                                        };
                                };
                                Curry._1(setAsyncState, (function (prevState) {
                                        return AsyncResult.mapOk(prevState, fn);
                                      }));
                              })
                          }), React.createElement(SelectDateRangeFilter.make, {
                            value: state$1.dateRange,
                            label: $$Intl.t("Period"),
                            placeholder: $$Intl.t("Select a period"),
                            disabledResetButton: true,
                            preset: {
                              isCustomOrNotDefined: isCustomOrNotDefined,
                              toString: toString,
                              toDateRange: toDateRange,
                              fromPartialDateRange: fromPartialDateRange,
                              isEqual: isEqual
                            },
                            presets: [
                              /* Yesterday */0,
                              /* LastWeek */1,
                              /* LastMonth */2,
                              {
                                TAG: /* CurrentFiscalYear */0,
                                fiscalYearOpeningMonth: fiscalYearOpeningMonth
                              },
                              {
                                TAG: /* LastFiscalYear */1,
                                fiscalYearOpeningMonth: fiscalYearOpeningMonth
                              }
                            ],
                            onChange: (function (dateRange) {
                                Belt_Option.forEach(dateRange, (function (value) {
                                        var fn = function (prevState) {
                                          return {
                                                  submission: prevState.submission,
                                                  shop: prevState.shop,
                                                  dateRange: value,
                                                  fiscalYearOpeningMonth: prevState.fiscalYearOpeningMonth,
                                                  vendor: prevState.vendor
                                                };
                                        };
                                        Curry._1(setAsyncState, (function (prevState) {
                                                return AsyncResult.mapOk(prevState, fn);
                                              }));
                                      }));
                              }),
                            triggerLabelDisplay: "showDateRange"
                          }), React.createElement(Button.make, Button.makeProps($$Intl.t("Export"), undefined, undefined, true, AsyncData.isBusy(state$1.submission), undefined, undefined, undefined, undefined, handleSubmit, undefined, undefined, undefined))), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Stack.make, {
                                    children: null,
                                    space: "small",
                                    align: "center"
                                  }, React.createElement(TextStyle.make, {
                                        children: $$Intl.t("accounting_main.description"),
                                        align: "center"
                                      }), React.createElement(TextIconButton.make, TextIconButton.makeProps($$Intl.t("Setup my accounting accounts"), undefined, undefined, undefined, "edit_light", undefined, (function (param) {
                                              Curry._3(navigate, undefined, undefined, Curry._2(configurationRoute, state$1.shop.id, state$1.vendor));
                                            }), undefined, undefined, undefined)))), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Divider.make, {
                        spaceTop: "large",
                        spaceBottom: "xlarge"
                      }), React.createElement(Stack.make, {
                        children: React.createElement(TextAction.make, {
                              text: $$Intl.t("Learn more about accounting entries"),
                              onPress: (function (param) {
                                  HelpCenter.showArticle(HelpCenter.setupAndExportAccountingEntries);
                                })
                            }),
                        align: "center"
                      })));
  }
  return React.createElement(ResourceDetailsPage.make, {
              variation: "compact",
              title: pageTitle,
              children: React.createElement(Placeholder.make, {
                    status: /* Loading */0
                  })
            });
}

var make$2 = AccountingPage;

export {
  figureUri ,
  DateRangePreset ,
  PageLayout ,
  GetShopAccountingExportRequest ,
  styles ,
  State ,
  make$2 as make,
}
/* figureUri Not a pure module */
