// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as React from "react";
import * as Notifier from "../../bundles/Notifier/Notifier.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as CatalogRoutes from "./CatalogRoutes.bs.js";
import * as CatalogListPage from "./CatalogListPage.bs.js";
import * as Notifier__Context from "../../bundles/Notifier/Notifier__Context.bs.js";
import * as CatalogLabelCreatePage from "./CatalogLabelCreatePage.bs.js";
import * as CatalogVariantByIdPage from "./CatalogVariantByIdPage.bs.js";
import * as CatalogProductByCkuPage from "./CatalogProductByCkuPage.bs.js";
import * as CatalogProductContainer from "./CatalogProductContainer.bs.js";
import * as CatalogVariantContainer from "./CatalogVariantContainer.bs.js";
import * as CatalogInventoryImportPage from "./CatalogInventoryImportPage.bs.js";
import * as CatalogDuplicationContainer from "./CatalogDuplicationContainer.bs.js";
import * as CatalogProductEditContainer from "./CatalogProductEditContainer.bs.js";
import * as CatalogVariantEditContainer from "./CatalogVariantEditContainer.bs.js";
import * as CatalogVariantOrderSupplyPage from "./CatalogVariantOrderSupplyPage.bs.js";
import * as CatalogVariantStockActivityPage from "./CatalogVariantStockActivityPage.bs.js";

function isIndependentKindShops(authScope) {
  if (authScope.TAG !== /* Organisation */0) {
    return authScope._0.kind === "INDEPENDENT";
  }
  var match = authScope.activeShop;
  if (match !== undefined) {
    return match.kind === "INDEPENDENT";
  } else {
    return Belt_Array.every(authScope.shops, (function (shop) {
                  return shop.kind === "INDEPENDENT";
                }));
  }
}

function CatalogRouter(Props) {
  var match = Navigation.useUrl(undefined);
  var query = match.query;
  var shopsIndependentKind = isIndependentKindShops(Auth.useScope(undefined));
  var catalogLabelQueryStringRef = React.useRef(CatalogRoutes.CatalogLabelQueryString.decode(query));
  var match$1 = catalogLabelQueryStringRef.current;
  var fromPathnameLabelEditSettings = match$1.fromPathname;
  var variantIdfromLabelEditSettings = match$1.variantIdfromLabelEditSettings;
  React.useEffect((function () {
          catalogLabelQueryStringRef.current = CatalogRoutes.CatalogLabelQueryString.decode(query);
        }), [match.pathname]);
  var match$2 = Belt_List.fromArray(match.path);
  var tmp;
  var exit = 0;
  if (match$2) {
    var exit$1 = 0;
    if (match$2.hd === "catalog") {
      var match$3 = match$2.tl;
      if (match$3) {
        var cku = match$3.hd;
        var exit$2 = 0;
        switch (cku) {
          case "create" :
              var match$4 = match$3.tl;
              if (match$4) {
                if (match$4.hd === "redirect") {
                  var match$5 = match$4.tl;
                  if (match$5 && !match$5.tl) {
                    tmp = React.createElement(CatalogProductByCkuPage.make, {
                          cku: match$5.hd,
                          redirectToCreateVariant: true
                        });
                  } else {
                    exit = 1;
                  }
                } else {
                  exit$2 = 3;
                }
              } else {
                tmp = React.createElement(CatalogVariantEditContainer.make, {
                      mode: /* Create */0
                    });
              }
              break;
          case "inventory" :
              var match$6 = match$3.tl;
              if (match$6 && match$6.hd === "import") {
                if (match$6.tl) {
                  exit = 1;
                } else {
                  tmp = React.createElement(CatalogInventoryImportPage.make, {});
                }
              } else {
                exit$2 = 3;
              }
              break;
          case "labels" :
              var match$7 = match$3.tl;
              if (match$7 && match$7.hd === "create") {
                var match$8 = match$7.tl;
                if (match$8) {
                  if (match$8.hd === "settings" && !match$8.tl) {
                    tmp = React.createElement(CatalogLabelCreatePage.make, {
                          settingsOnly: fromPathnameLabelEditSettings !== undefined && variantIdfromLabelEditSettings !== undefined ? ({
                                fromPathname: fromPathnameLabelEditSettings,
                                variantIdFromCatalogRedirection: variantIdfromLabelEditSettings
                              }) : undefined
                        });
                  } else {
                    exit = 1;
                  }
                } else {
                  tmp = React.createElement(CatalogLabelCreatePage.make, {});
                }
              } else {
                exit$2 = 3;
              }
              break;
          case "product" :
              var match$9 = match$3.tl;
              if (match$9) {
                var cku$1 = match$9.hd;
                var exit$3 = 0;
                if (cku$1 === "create" && !match$9.tl) {
                  tmp = React.createElement(CatalogProductEditContainer.make, {
                        mode: /* Create */0
                      });
                } else {
                  exit$3 = 4;
                }
                if (exit$3 === 4) {
                  var match$10 = match$9.tl;
                  if (match$10) {
                    if (match$10.hd === "edit") {
                      if (match$10.tl) {
                        exit = 1;
                      } else {
                        tmp = React.createElement(CatalogProductEditContainer.make, {
                              cku: cku$1,
                              mode: /* Edit */1
                            });
                      }
                    } else {
                      switch (cku$1) {
                        case "duplication" :
                            var match$11 = match$9.tl;
                            if (match$11.tl) {
                              exit = 1;
                            } else {
                              tmp = React.createElement(CatalogDuplicationContainer.make, {
                                    cku: match$11.hd,
                                    mode: /* Product */0
                                  });
                            }
                            break;
                        case "redirect" :
                            var match$12 = match$9.tl;
                            if (match$12.tl) {
                              exit = 1;
                            } else {
                              tmp = React.createElement(CatalogProductByCkuPage.make, {
                                    cku: match$12.hd
                                  });
                            }
                            break;
                        default:
                          exit$2 = 3;
                      }
                    }
                  } else {
                    tmp = React.createElement(CatalogProductContainer.make, {
                          cku: cku$1
                        });
                  }
                }
                
              } else {
                exit$2 = 3;
              }
              break;
          default:
            exit$2 = 3;
        }
        if (exit$2 === 3) {
          var match$13 = match$3.tl;
          if (match$13) {
            switch (match$13.hd) {
              case "create" :
                  if (match$13.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(CatalogVariantEditContainer.make, {
                          cku: cku,
                          mode: /* Create */0
                        });
                  }
                  break;
              case "edit" :
                  if (match$13.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(CatalogVariantEditContainer.make, {
                          cku: cku,
                          mode: /* Edit */1
                        });
                  }
                  break;
              default:
                if (cku === "redirect") {
                  var match$14 = match$3.tl;
                  if (match$14.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(CatalogVariantByIdPage.make, {
                          id: match$14.hd
                        });
                  }
                } else {
                  var match$15 = match$3.tl;
                  switch (match$15.hd) {
                    case "ordersupplies" :
                        if (match$15.tl) {
                          exit = 1;
                        } else {
                          tmp = React.createElement(CatalogVariantOrderSupplyPage.make, {
                                cku: cku
                              });
                        }
                        break;
                    case "stockactivities" :
                        if (match$15.tl) {
                          exit = 1;
                        } else {
                          tmp = React.createElement(CatalogVariantStockActivityPage.make, {
                                cku: cku
                              });
                        }
                        break;
                    default:
                      if (cku === "duplication") {
                        var match$16 = match$3.tl;
                        if (match$16.tl) {
                          exit = 1;
                        } else {
                          tmp = React.createElement(CatalogDuplicationContainer.make, {
                                cku: match$16.hd,
                                mode: /* Variant */1
                              });
                        }
                      } else {
                        exit = 1;
                      }
                  }
                }
            }
          } else {
            tmp = React.createElement(CatalogVariantContainer.make, {
                  cku: cku,
                  fromLabelEditSettingsRedirected: Belt_Option.isSome(fromPathnameLabelEditSettings)
                });
          }
        }
        
      } else {
        exit$1 = 2;
      }
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (match$2.tl) {
        exit = 1;
      } else {
        tmp = React.createElement(CatalogListPage.make, {
              shopsIndependentKind: shopsIndependentKind,
              variantIdfromLabelEditSettings: variantIdfromLabelEditSettings
            });
      }
    }
    
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Navigation.Redirect.make, {
          route: CatalogRoutes.baseRoute
        });
  }
  return React.createElement(Notifier__Context.Provider.make, Notifier__Context.Provider.makeProps(Notifier.createContext(undefined), tmp, undefined));
}

var make = CatalogRouter;

export {
  isIndependentKindShops ,
  make ,
}
/* Auth Not a pure module */
