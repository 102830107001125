// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as React from "react";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as StockActivityReason from "./StockActivityReason.bs.js";

function StockActivityTypeTableCell(Props) {
  var kind = Props.value;
  var reason = Props.reason;
  return React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                          children: reason !== undefined && reason !== "LOSS" ? kind + " > " + StockActivityReason.toLabel(reason) : kind
                        })), undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = React.memo(StockActivityTypeTableCell);

export {
  make ,
}
/* make Not a pure module */
