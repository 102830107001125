// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as Icon from "../../resources/images-and-icons/Icon.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Badge from "../../resources/feedback-indicators/Badge.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as Title from "../../resources/typography/Title.bs.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as TextLink from "../../resources/navigation/TextLink.bs.js";
import * as InfoBlock from "../../resources/layout-and-structure/InfoBlock.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Touchable from "../../primitives/Touchable.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as InlineText from "../../resources/layout-and-structure/InlineText.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LegacyRouter from "../../core/LegacyRouter.bs.js";
import * as CatalogVariant from "./CatalogVariant.bs.js";

var make = React.memo(function (Props) {
      var product = Props.product;
      var displayShopNameOpt = Props.displayShopName;
      var displayShopName = displayShopNameOpt !== undefined ? displayShopNameOpt : true;
      if (product !== undefined) {
        return React.createElement(Stack.make, {
                    children: null
                  }, displayShopName ? React.createElement(TextStyle.make, {
                          children: $$Intl.t("From catalog of") + (" " + product.shopName),
                          variation: "normal",
                          size: "xxsmall"
                        }) : null, React.createElement(InlineText.make, {
                        children: null
                      }, React.createElement(TextStyle.make, {
                            children: $$Intl.t("Inside category "),
                            variation: "normal"
                          }), React.createElement(TextStyle.make, {
                            children: product.formattedCategoryName
                          })));
      } else {
        return null;
      }
    });

var OrganisationBlockContent = {
  make: make
};

var make$1 = React.memo(function (Props) {
      var children = Props.children;
      var collapsed = Props.collapsed;
      var onPress = Props.onPress;
      var match = Hover.use(undefined, undefined);
      var tmp = {
        children: $$Intl.t(collapsed ? "Show more" : "Collapse"),
        level: 5,
        weight: "medium"
      };
      var tmp$1 = match[1] ? "#797885" : undefined;
      if (tmp$1 !== undefined) {
        tmp.color = tmp$1;
      }
      return React.createElement(Stack.make, {
                  children: null,
                  space: "small"
                }, collapsed ? null : React.createElement(Box.make, Box.makeProps(Caml_option.some(children), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Touchable.make, Touchable.makeProps(React.createElement(Inline.make, {
                                          children: null
                                        }, React.createElement(Icon.make, {
                                              name: collapsed ? "arrow_down_light" : "arrow_up_light"
                                            }), React.createElement(Title.make, tmp)), undefined, undefined, undefined, undefined, undefined, onPress, undefined, match[0], undefined))), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
    });

var ShowMoreWrapper = {
  make: make$1
};

function CatalogVariantProductInformationCard(Props) {
  var cku = Props.cku;
  var shopsProduct = Props.shopsProduct;
  var match = React.useState(function () {
        return false;
      });
  var setShowMore = match[1];
  var scope = Auth.useScope(undefined);
  var productPageRoute = "/product/" + cku;
  var productPagePathname = LegacyRouter.routeToPathname(/* Catalog */2) + productPageRoute;
  var productCategoryDiffering = React.useMemo((function () {
          return CatalogVariant.MultiShops.isReferenceDiffering(shopsProduct, scope, (function (a, b) {
                        return a.formattedCategoryName === b.formattedCategoryName;
                      }));
        }), [
        scope,
        shopsProduct
      ]);
  var product = Belt_Array.get(shopsProduct, 0);
  var tmp;
  if (product !== undefined) {
    var match$1 = product.status;
    var tmp$1;
    if (match$1 === 2) {
      var exit = 0;
      if (scope.TAG === /* Organisation */0 && scope.activeShop === undefined) {
        tmp$1 = null;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        tmp$1 = React.createElement(Badge.make, {
              children: $$Intl.t("Archived"),
              size: "small",
              variation: "neutral"
            });
      }
      
    } else {
      tmp$1 = null;
    }
    var description = product.description;
    tmp = React.createElement(Stack.make, {
          children: null,
          space: "xxsmall"
        }, tmp$1, React.createElement(TextLink.make, {
              text: product.name,
              to: {
                TAG: /* Route */0,
                _0: LegacyRouter.routeToPathname(/* Catalog */2) + productPageRoute
              }
            }), description !== undefined && description.length !== 0 ? React.createElement(TextStyle.make, {
                children: description
              }) : null, React.createElement(InlineText.make, {
              children: null
            }, React.createElement(TextStyle.make, {
                  children: $$Intl.t("From "),
                  variation: "normal"
                }), React.createElement(TextStyle.make, {
                  children: product.formattedOrigin
                })), React.createElement(InlineText.make, {
              children: null
            }, React.createElement(TextStyle.make, {
                  children: $$Intl.t("Default VAT of "),
                  variation: "normal"
                }), React.createElement(TextStyle.make, {
                  children: String(product.taxValue).replace(".", ",") + " %"
                })));
  } else {
    tmp = null;
  }
  var tmp$2 = {
    displayShopName: productCategoryDiffering
  };
  var tmp$3 = Belt_Array.get(shopsProduct, 0);
  if (tmp$3 !== undefined) {
    tmp$2.product = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement(Card.make, {
              children: null,
              title: $$Intl.t("Bound product"),
              action: {
                icon: "queue_arrow_right_light",
                title: $$Intl.t("See bound product"),
                handler: {
                  TAG: /* OpenLink */1,
                  _0: {
                    TAG: /* Route */0,
                    _0: productPagePathname
                  }
                }
              }
            }, tmp, React.createElement(InfoBlock.make, {
                  children: null,
                  title: "ORGANISATION"
                }, React.createElement(make, tmp$2), productCategoryDiffering ? React.createElement(make$1, {
                        children: React.createElement(Stack.make, {
                              children: Belt_Array.map(Belt_Array.slice(shopsProduct, 1, shopsProduct.length), (function (product) {
                                      return React.createElement(make, {
                                                  product: product
                                                });
                                    })),
                              space: "medium"
                            }),
                        collapsed: !match[0],
                        onPress: (function (param) {
                            Curry._1(setShowMore, (function (state) {
                                    return !state;
                                  }));
                          })
                      }) : null));
}

var make$2 = React.memo(CatalogVariantProductInformationCard);

var Config;

export {
  Config ,
  OrganisationBlockContent ,
  ShowMoreWrapper ,
  make$2 as make,
}
/* make Not a pure module */
