// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function placeholderFromProductKind(kind) {
  if (kind === "SPIRITUOUS") {
    return "0,7L - 10 " + $$Intl.t("years");
  } else if (kind === "BEER") {
    return "0,33L";
  } else if (kind === "WINE") {
    return "0,75L - 2017";
  } else {
    return "0,5kg";
  }
}

function make(capacityValue, capacityUnit, bulk, year) {
  return (
            capacityUnit !== undefined ? (
                bulk ? $$Intl.template($$Intl.t("Bulk by {{unit}}"), {
                        unit: capacityUnit
                      }, undefined) : (
                    capacityValue !== undefined ? String(capacityValue) + capacityUnit : ""
                  )
              ) + Belt_Option.mapWithDefault(year, "", (function (year) {
                      var separator = Belt_Option.isSome(capacityValue) || bulk ? " - " : "";
                      var absoluteYear = String(year);
                      if (year > 999) {
                        return separator + absoluteYear;
                      } else if (year > 1) {
                        return separator + (absoluteYear + (" " + $$Intl.t("years")));
                      } else {
                        return separator + (absoluteYear + (" " + $$Intl.t("year")));
                      }
                    })) : (
                bulk ? "" : $$Intl.t("Piece")
              )
          ).replace(".", ",");
}

var Name = {
  placeholderFromProductKind: placeholderFromProductKind,
  make: make
};

var kg = "kg";

var g = "g";

var l = "L";

var cl = "cL";

var ml = "mL";

function getDefaultFromProductKind(productKind) {
  if (productKind === "SIMPLE") {
    return kg;
  } else {
    return l;
  }
}

function makeOptions(productKind) {
  if (productKind === "WINE" || productKind === "SPIRITUOUS") {
    return [
            l,
            cl
          ];
  } else if (productKind === "BEER") {
    return [
            l,
            cl,
            ml
          ];
  } else {
    return [
            kg,
            g,
            l,
            cl,
            ml
          ];
  }
}

var CapacityUnit = {
  kg: kg,
  g: g,
  l: l,
  cl: cl,
  ml: ml,
  getDefaultFromProductKind: getDefaultFromProductKind,
  makeOptions: makeOptions
};

function placeholderFromProductKind$1(kind) {
  if (kind === "SPIRITUOUS") {
    return "0,70";
  } else if (kind === "BEER") {
    return "0,33";
  } else if (kind === "WINE") {
    return "0,75";
  } else {
    return "0,50";
  }
}

var CapacityValue = {
  placeholderFromProductKind: placeholderFromProductKind$1
};

function placeholderFromProductKind$2(kind) {
  if (kind === "SPIRITUOUS") {
    return "10";
  } else if (kind === "BEER" || kind !== "WINE") {
    return "";
  } else {
    return "2017";
  }
}

var Year = {
  placeholderFromProductKind: placeholderFromProductKind$2
};

function placeholderFromProductKind$3(kind) {
  if (kind === "SPIRITUOUS") {
    return "42,00";
  } else if (kind === "BEER") {
    return "4,50";
  } else if (kind === "WINE") {
    return "13,10";
  } else {
    return "";
  }
}

var Volume = {
  placeholderFromProductKind: placeholderFromProductKind$3
};

function arrayKeepUniqueBy(collection, predicate) {
  return Belt_Array.keepWithIndex(collection, (function (current, index) {
                return Belt_Array.getIndexBy(collection, (function (element) {
                              return Curry._2(predicate, element, current);
                            })) === index;
              }));
}

function isReferenceDiffering(shopsReference, scope, predicate) {
  var match = arrayKeepUniqueBy(shopsReference, predicate);
  if (scope.TAG === /* Organisation */0 && scope.activeShop === undefined) {
    return match.length > 1;
  } else {
    return false;
  }
}

var MultiShops = {
  arrayKeepUniqueBy: arrayKeepUniqueBy,
  isReferenceDiffering: isReferenceDiffering
};

function format(rawValue, capacityPrecision, capacityUnit) {
  return String(capacityPrecision !== undefined ? rawValue / Math.pow(10, capacityPrecision) : rawValue).replace(".", ",") + (
          capacityUnit !== undefined ? capacityUnit : ""
        );
}

var StockQuantity = {
  format: format
};

var defaultBulkCapacityPrecision = 3;

export {
  defaultBulkCapacityPrecision ,
  Name ,
  CapacityUnit ,
  CapacityValue ,
  Year ,
  Volume ,
  MultiShops ,
  StockQuantity ,
}
/* Intl Not a pure module */
