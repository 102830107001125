// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as $$Text from "../../primitives/Text.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Label from "../../primitives/Label.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Inline from "./Inline.bs.js";
import * as Spaces from "../theme/Spaces.bs.js";
import * as TextLink from "../navigation/TextLink.bs.js";
import * as FontFaces from "../theme/FontFaces.bs.js";
import * as FontSizes from "../theme/FontSizes.bs.js";
import * as TextStyle from "../typography/TextStyle.bs.js";
import * as Touchable from "../../primitives/Touchable.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TooltipIcon from "../overlays/TooltipIcon.bs.js";

var styles = $$StyleSheet.create({
      main: Style.merge([
            FontFaces.libreFranklinRegularStyle,
            Style.style(undefined, undefined, undefined, undefined, undefined, FontSizes.xsmall, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.xsmall)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      information: Style.style(undefined, undefined, undefined, "#797885", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      error: Style.style(undefined, undefined, undefined, "#c81c51", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

var make = React.memo(function (Props) {
      var children = Props.children;
      var variation = Props.variation;
      return React.createElement($$Text.make, $$Text.makeProps(children, Caml_option.some(Style.arrayStyle([
                              styles.main,
                              variation === "information" ? styles.information : styles.error
                            ])), undefined, undefined, undefined));
    });

function makeLabel(label, required) {
  if (label === "") {
    if (required) {
      return $$Intl.t("Required field") + " *";
    } else if (required) {
      return label + " *";
    } else {
      return label;
    }
  } else if (required) {
    return label + " *";
  } else {
    return label;
  }
}

var Action = {};

var make$1 = React.forwardRef(function (Props, ref) {
      var children = Props.children;
      var label = Props.label;
      var labelAriaProps = Props.labelAriaProps;
      var tooltip = Props.tooltip;
      var errorMessage = Props.errorMessage;
      var action = Props.action;
      var requiredOpt = Props.required;
      var required = requiredOpt !== undefined ? requiredOpt : false;
      var labelRef = React.useRef(null);
      var tmp;
      if (label !== undefined) {
        if (action !== undefined) {
          var handler = action.handler;
          var text = action.text;
          var tmp$1 = {
            text: makeLabel(label, required)
          };
          if (labelAriaProps !== undefined) {
            tmp$1.ariaProps = Caml_option.valFromOption(labelAriaProps);
          }
          var tmp$2;
          switch (handler.TAG | 0) {
            case /* Callback */0 :
                var action$1 = handler._0;
                tmp$2 = React.createElement(Touchable.make, Touchable.makeProps(React.createElement(TextStyle.make, {
                              children: text,
                              size: "xsmall",
                              underlined: true
                            }), undefined, undefined, true, undefined, {
                          marginBottom: "3px"
                        }, (function (param) {
                            Curry._1(action$1, undefined);
                          }), undefined, undefined, undefined));
                break;
            case /* OpenLink */1 :
                tmp$2 = React.createElement(TextLink.make, {
                      text: text,
                      to: handler._0
                    });
                break;
            case /* OpenLinkNewTab */2 :
                tmp$2 = React.createElement(TextLink.make, {
                      text: text,
                      to: handler._0,
                      openNewTab: true
                    });
                break;
            
          }
          tmp = React.createElement(Inline.make, {
                children: null,
                align: "spaceBetween"
              }, React.createElement(Label.make, tmp$1), tooltip !== undefined ? React.createElement(TooltipIcon.make, {
                      children: Caml_option.valFromOption(tooltip),
                      variation: "info",
                      placement: "bottom start",
                      altTriggerRef: labelRef,
                      crossOffset: -1
                    }) : null, tmp$2);
        } else {
          var tmp$3 = {
            text: makeLabel(label, required)
          };
          if (labelAriaProps !== undefined) {
            tmp$3.ariaProps = Caml_option.valFromOption(labelAriaProps);
          }
          tmp = React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Inline.make, {
                            children: null,
                            space: "xsmall",
                            alignY: "top"
                          }, React.createElement(Label.make, tmp$3), tooltip !== undefined ? React.createElement(TooltipIcon.make, {
                                  children: Caml_option.valFromOption(tooltip),
                                  variation: "info",
                                  placement: "bottom start",
                                  altTriggerRef: labelRef,
                                  crossOffset: -1
                                }) : null)), Caml_option.some(Style.unsafeCss({
                            width: "fit-content"
                          })), undefined, labelRef, undefined));
        }
      } else {
        tmp = null;
      }
      var tmp$4;
      tmp$4 = errorMessage !== undefined && errorMessage !== "" ? React.createElement(make, {
              children: errorMessage,
              variation: "error"
            }) : null;
      return React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, Caml_option.some(ref), undefined), tmp, children, tmp$4);
    });

var make$2 = React.memo(make$1);

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9) {
  var tmp = {
    children: prim0
  };
  if (prim1 !== undefined) {
    tmp.label = Caml_option.valFromOption(prim1);
  }
  if (prim2 !== undefined) {
    tmp.labelAriaProps = Caml_option.valFromOption(prim2);
  }
  if (prim3 !== undefined) {
    tmp.tooltip = Caml_option.valFromOption(prim3);
  }
  if (prim4 !== undefined) {
    tmp.errorMessage = Caml_option.valFromOption(prim4);
  }
  if (prim5 !== undefined) {
    tmp.action = Caml_option.valFromOption(prim5);
  }
  if (prim6 !== undefined) {
    tmp.required = Caml_option.valFromOption(prim6);
  }
  if (prim7 !== undefined) {
    tmp.key = prim7;
  }
  if (prim8 !== undefined) {
    tmp.ref = Caml_option.valFromOption(prim8);
  }
  return tmp;
}

export {
  makeLabel ,
  Action ,
  makeProps ,
  make$2 as make,
}
/* styles Not a pure module */
