// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Svg from "../../primitives/Svg.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as ReactAria from "../../externals/ReactAria.bs.js";
import * as Dimensions from "../../primitives/Dimensions.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as ReactAria$1 from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactStately from "react-stately";
import * as AnimatedRender from "../../primitives/AnimatedRender.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

function useTrigger(defaultOpenedOpt, param) {
  var defaultOpened = defaultOpenedOpt !== undefined ? defaultOpenedOpt : false;
  var ref = React.useRef(null);
  var state = ReactStately.useOverlayTriggerState({
        defaultOpen: defaultOpened
      });
  var ariaProps = ReactAria$1.useOverlayTrigger({
        type: "dialog"
      }, state, ref);
  return {
          state: state,
          ariaProps: ariaProps,
          ref: ref
        };
}

var make = React.memo(function (Props) {
      var children = Props.children;
      var ariaLabelOpt = Props.ariaLabel;
      var overlayProps = Props.ariaProps;
      var style = Props.style;
      var className = Props.className;
      var ariaLabel = ariaLabelOpt !== undefined ? ariaLabelOpt : "popover-dialog";
      var domRef = React.useRef(null);
      var match = ReactAria$1.useDialog({
            "aria-label": ariaLabel
          }, domRef);
      var tmp = {
        ref: domRef
      };
      if (className !== undefined) {
        tmp.className = Caml_option.valFromOption(className);
      }
      if (style !== undefined) {
        tmp.style = Caml_option.valFromOption(style);
      }
      return React.createElement(ReactAria.Spread.make, {
                  props: ReactAria$1.mergeProps(match.dialogProps, overlayProps),
                  children: React.createElement("div", tmp, children)
                });
    });

var Dialog = {
  make: make
};

var context = React.createContext(undefined);

var make$1 = context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var Provider = {
  make: make$1,
  makeProps: makeProps
};

var PopoverContextNotFound = /* @__PURE__ */Caml_exceptions.create("Popover.Context.PopoverContextNotFound");

function use(param) {
  var state = React.useContext(context);
  if (state !== undefined) {
    return state;
  }
  throw {
        RE_EXN_ID: PopoverContextNotFound,
        Error: new Error()
      };
}

var styles = $$StyleSheet.create({
      root: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "absolute", undefined, undefined, undefined, undefined, 1, undefined)
    });

var make$2 = React.memo(function (Props) {
      var arrowProps = Props.arrowProps;
      var placement = Props.placement;
      var crossOffset = Props.crossOffset;
      var tmp;
      var exit = 0;
      if (placement === "bottom") {
        var left = arrowProps.style.left;
        if (left !== undefined) {
          tmp = Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(left + crossOffset - 8)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(-10)), undefined, undefined, undefined);
        } else {
          exit = 1;
        }
      } else if (placement === "right") {
        var top = arrowProps.style.top;
        if (top !== undefined) {
          tmp = Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(-10)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(top + crossOffset - 8)), undefined, undefined, undefined);
        } else {
          exit = 1;
        }
      } else if (placement === "top") {
        var left$1 = arrowProps.style.left;
        if (left$1 !== undefined) {
          tmp = Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(-10)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(left$1 + crossOffset - 8)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
        } else {
          exit = 1;
        }
      } else {
        var top$1 = arrowProps.style.top;
        if (top$1 !== undefined) {
          tmp = Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(-10)), undefined, Caml_option.some(Style.dp(top$1 + crossOffset - 8)), undefined, undefined, undefined);
        } else {
          exit = 1;
        }
      }
      if (exit === 1) {
        tmp = Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
      }
      var style = Style.arrayStyle([
            styles.root,
            Style.unsafeCss({
                  transform: placement === "bottom" ? "rotate(0deg)" : (
                      placement === "right" ? "rotate(-90deg)" : (
                          placement === "top" ? "rotate(180deg)" : "rotate(90deg)"
                        )
                    )
                }),
            tmp
          ]);
      return React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Svg.make, {
                              children: React.createElement(Svg.Path.make, {
                                    d: "M 9.278 6.281 Q 9.989 5.718 10.7 6.281 L 19.741 13.437 Q 20.452 14 19.029 14 L 0.949 14 Q -0.474 14 0.237 13.437 Z",
                                    fill: "#fefefe",
                                    filter: "drop-shadow(rgba(0, 0, 0, 0.05) 0px -1px 1px)",
                                    stroke: "#d7d7e0",
                                    strokeDasharray: "13, 21"
                                  }),
                              width: "16",
                              height: "16",
                              viewBox: "0 0 20 20"
                            })), Caml_option.some(style), undefined, undefined, undefined));
    });

var styles$1 = $$StyleSheet.create({
      root: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#fefefe", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 5, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, "#07061e", Caml_option.some(Style.shadowOffset(8, 0)), 0.1, 14, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "hidden", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function viewStyleFromParams(layout, size, triggerWidth, borderColor) {
  return Style.arrayOptionStyle([
              Caml_option.some(styles$1.root),
              Caml_option.some(Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, borderColor, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
              layout === "triggerMinWidth" ? Caml_option.some(Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(triggerWidth)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : (
                  layout === "triggerStrictWidth" ? Caml_option.some(Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(triggerWidth)), undefined, Caml_option.some(Style.dp(triggerWidth)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : undefined
                ),
              size === "normal" ? undefined : Caml_option.some(Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 3, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))
            ]);
}

function Popover(Props) {
  var children = Props.children;
  var popoverRef = Props.popoverRef;
  var triggerRef = Props.triggerRef;
  var state = Props.state;
  var variationOpt = Props.variation;
  var borderColorOpt = Props.borderColor;
  var sizeOpt = Props.size;
  var placementOpt = Props.placement;
  var layoutOpt = Props.layout;
  var animation = Props.animation;
  var overlayPriorityOpt = Props.overlayPriority;
  var dismissableOpt = Props.dismissable;
  var shouldUpdatePositionOpt = Props.shouldUpdatePosition;
  var modalOpt = Props.modal;
  var offsetOpt = Props.offset;
  var crossOffsetOpt = Props.crossOffset;
  var variation = variationOpt !== undefined ? variationOpt : "normal";
  var borderColor = borderColorOpt !== undefined ? borderColorOpt : "#d7d7e0";
  var size = sizeOpt !== undefined ? sizeOpt : "normal";
  var placement = placementOpt !== undefined ? placementOpt : "bottom";
  var layout = layoutOpt !== undefined ? layoutOpt : "auto";
  var overlayPriority = overlayPriorityOpt !== undefined ? overlayPriorityOpt : true;
  var dismissable = dismissableOpt !== undefined ? dismissableOpt : true;
  var shouldUpdatePosition = shouldUpdatePositionOpt !== undefined ? shouldUpdatePositionOpt : true;
  var modal = modalOpt !== undefined ? modalOpt : true;
  var offset = offsetOpt !== undefined ? offsetOpt : 3;
  var crossOffset = crossOffsetOpt !== undefined ? crossOffsetOpt : 0;
  var ref = Belt_Option.getWithDefault(popoverRef, React.useRef(null));
  var shouldUpdatePosition$1 = shouldUpdatePosition || !shouldUpdatePosition && (ref.current == null);
  var props_placement = placement;
  var props_offset = size === "compact" ? offset - 1 : offset;
  var props_crossOffset = crossOffset;
  var props_isNonModal = !modal;
  var props = {
    triggerRef: triggerRef,
    popoverRef: ref,
    shouldUpdatePosition: shouldUpdatePosition$1,
    placement: props_placement,
    offset: props_offset,
    crossOffset: props_crossOffset,
    isNonModal: props_isNonModal
  };
  var match = Dimensions.use(triggerRef, undefined);
  var triggerWidth = match[1].width;
  var match$1 = ReactAria$1.usePopover(props, state);
  var popoverProps = match$1.popoverProps;
  if (!overlayPriority) {
    Belt_Option.map(Js_dict.get(popoverProps, "style"), (function (style) {
            style["zIndex"] = 1;
          }));
  }
  if (!dismissable) {
    popoverProps["onBlur"] = (function (param) {
        
      });
  }
  var animation$1 = Belt_Option.getWithDefault(animation, triggerWidth > 550 ? "fadeTranslation" : "fadeBubbleTranslation");
  return React.createElement(make$1, makeProps(state, React.createElement(ReactAria$1.Overlay, {
                      children: React.createElement(ReactAria.Spread.make, {
                            props: popoverProps,
                            children: React.createElement("div", {
                                  ref: ref
                                }, React.createElement(AnimatedRender.make, {
                                      children: null,
                                      displayed: state.isOpen,
                                      animation: animation$1,
                                      duration: 75
                                    }, variation === "normal" ? null : React.createElement(make$2, {
                                            arrowProps: match$1.arrowProps,
                                            placement: match$1.placement,
                                            crossOffset: crossOffset
                                          }), React.createElement(ReactAria$1.DismissButton, {
                                          onDismiss: state.close
                                        }), React.createElement(View.make, View.makeProps(Caml_option.some(children), Caml_option.some(viewStyleFromParams(layout, size, triggerWidth, borderColor)), undefined, undefined, undefined)), React.createElement(ReactAria$1.DismissButton, {
                                          onDismiss: state.close
                                        })))
                          })
                    }), undefined));
}

var make$3 = React.memo(Popover, (function (oldProps, newProps) {
        if (oldProps.state.isOpen === newProps.state.isOpen) {
          return !newProps.state.isOpen;
        } else {
          return false;
        }
      }));

var Context = {
  Provider: Provider,
  use: use
};

var useState = use;

export {
  useTrigger ,
  Dialog ,
  Context ,
  useState ,
  make$3 as make,
}
/* make Not a pure module */
