// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactAria from "../externals/ReactAria.bs.js";
import * as ReactAria$1 from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function makeStyle(wrap, disabled) {
  return {
          cursor: disabled ? "default" : "pointer",
          display: wrap ? "block" : "inline-flex",
          position: "relative",
          userSelect: "text"
        };
}

var make = React.forwardRef(function (Props, ref) {
      var children = Props.children;
      var ariaProps = Props.ariaProps;
      var disabled = Props.disabled;
      var excludeFromTabOrder = Props.excludeFromTabOrder;
      var wrapOpt = Props.wrap;
      var style = Props.style;
      var onPress = Props.onPress;
      var wrap = wrapOpt !== undefined ? wrapOpt : true;
      var disabled$1 = Belt_Option.getWithDefault(Belt_Option.orElse(disabled, Belt_Option.flatMap(ariaProps, (function (props) {
                      return props.isDisabled;
                    }))), false);
      var defaultProps_elementType = "div";
      var defaultProps_isDisabled = disabled$1;
      var defaultProps_onPress = onPress;
      var defaultProps = {
        elementType: defaultProps_elementType,
        isDisabled: defaultProps_isDisabled,
        excludeFromTabOrder: excludeFromTabOrder,
        onPress: defaultProps_onPress
      };
      var props = Belt_Option.getWithDefault(Belt_Option.map(ariaProps, (function (props) {
                  return ReactAria$1.mergeProps(props, defaultProps);
                })), defaultProps);
      var match = ReactAria$1.useButton(props, undefined);
      var style$1 = Object.assign({}, makeStyle(wrap, disabled$1), Belt_Option.getWithDefault(style, {}));
      var tmp = {
        style: style$1
      };
      var tmp$1 = Belt_Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
              return prim;
            }));
      if (tmp$1 !== undefined) {
        tmp.ref = Caml_option.valFromOption(tmp$1);
      }
      return React.createElement(ReactAria.Spread.make, {
                  props: match.buttonProps,
                  children: React.createElement("div", tmp, children)
                });
    });

var make$1 = React.memo(make);

make$1.displayName = "Touchable";

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9) {
  var tmp = {
    children: prim0,
    onPress: prim6
  };
  if (prim1 !== undefined) {
    tmp.ariaProps = Caml_option.valFromOption(prim1);
  }
  if (prim2 !== undefined) {
    tmp.disabled = Caml_option.valFromOption(prim2);
  }
  if (prim3 !== undefined) {
    tmp.excludeFromTabOrder = Caml_option.valFromOption(prim3);
  }
  if (prim4 !== undefined) {
    tmp.wrap = Caml_option.valFromOption(prim4);
  }
  if (prim5 !== undefined) {
    tmp.style = Caml_option.valFromOption(prim5);
  }
  if (prim7 !== undefined) {
    tmp.key = prim7;
  }
  if (prim8 !== undefined) {
    tmp.ref = Caml_option.valFromOption(prim8);
  }
  return tmp;
}

export {
  makeProps ,
  make$1 as make,
}
/* make Not a pure module */
