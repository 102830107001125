// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Placeholder from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as CatalogProductPage from "./CatalogProductPage.bs.js";
import * as CatalogProduct__Config from "./CatalogProduct__Config.bs.js";
import * as CatalogProduct__Queries from "./CatalogProduct__Queries.bs.js";

function CatalogProductContainer(Props) {
  var cku = Props.cku;
  var match = Curry.app(CatalogProduct__Queries.ProductQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* CacheAndNetwork */0,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var queryResults = match[1];
  var executeQuery = match[0];
  var match$1 = Curry.app(CatalogProduct__Queries.ProductShopsQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* NetworkOnly */3,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var queryShopsResults = match$1[1];
  var executeShopsQuery = match$1[0];
  var activeShop = Auth.useActiveShop(undefined);
  var activeShopId = Belt_Option.map(activeShop, (function (shop) {
          return shop.id;
        }));
  React.useEffect((function () {
          Curry._3(executeQuery, undefined, undefined, CatalogProduct__Queries.ProductQuery.makeVariables(Scalar.CKU.serialize(cku), CatalogProduct__Config.makeFilterBy(activeShopId), CatalogProduct__Queries.ProductQuery.makeInputObjectInputProductVariantsQueryFilter("INCLUDED", undefined), undefined));
        }), [activeShopId]);
  React.useEffect((function () {
          Curry._3(executeShopsQuery, undefined, undefined, CatalogProduct__Queries.ProductShopsQuery.makeVariables(Scalar.CKU.serialize(cku), undefined));
        }), []);
  var productShopIds;
  if (queryShopsResults.TAG === /* Executed */0) {
    var data = queryShopsResults._0.data;
    productShopIds = data !== undefined ? Belt_Array.map(data.productsByVariantCku.edges, (function (param) {
              return param.node.shop.id;
            })) : [];
  } else {
    productShopIds = [];
  }
  if (queryResults.TAG === /* Executed */0) {
    var match$2 = queryResults._0;
    var data$1 = match$2.data;
    if (data$1 !== undefined) {
      return React.createElement(CatalogProductPage.make, {
                  cku: cku,
                  productShopIds: productShopIds,
                  shopsProduct: CatalogProduct__Config.productsInformationFromData(data$1),
                  shopsProductVariants: CatalogProduct__Config.productsVariantsFromData(data$1)
                });
    }
    if (!match$2.loading) {
      return React.createElement(Placeholder.make, {
                  status: /* Error */2
                });
    }
    
  }
  return React.createElement(Placeholder.make, {
              status: /* Loading */0
            });
}

var make = React.memo(CatalogProductContainer);

var Config;

var ProductQuery;

var ProductShopsQuery;

export {
  Config ,
  ProductQuery ,
  ProductShopsQuery ,
  make ,
}
/* make Not a pure module */
