// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Price from "../Price/Price.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var CatalogVariantRetailPrice_CouldNotMakeMutationValues = /* @__PURE__ */Caml_exceptions.create("CatalogVariantRetailPrice.CatalogVariantRetailPrice_CouldNotMakeMutationValues");

function makeMutationValuesFromRetailPrice(retailPrice, taxRate) {
  var match = Price.isTaxIncluded(retailPrice);
  var match$1 = Price.makeWithTax(retailPrice, taxRate);
  if (match) {
    if (match$1 !== undefined) {
      return {
              valueIncludingTax: Price.toRawValue(retailPrice),
              valueExcludingTax: Price.toRawValue(match$1)
            };
    }
    throw {
          RE_EXN_ID: CatalogVariantRetailPrice_CouldNotMakeMutationValues,
          Error: new Error()
        };
  }
  if (match$1 !== undefined) {
    return {
            valueIncludingTax: Price.toRawValue(match$1),
            valueExcludingTax: Price.toRawValue(retailPrice)
          };
  }
  throw {
        RE_EXN_ID: CatalogVariantRetailPrice_CouldNotMakeMutationValues,
        Error: new Error()
      };
}

export {
  CatalogVariantRetailPrice_CouldNotMakeMutationValues ,
  makeMutationValuesFromRetailPrice ,
}
/* No side effect */
