// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Modal from "../../resources/overlays/Modal.bs.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LegacyRouter from "../../core/LegacyRouter.bs.js";
import * as CatalogVariantMutations from "./CatalogVariantMutations.bs.js";

function CatalogVariantArchiveModal(Props) {
  var id = Props.id;
  var opened = Props.opened;
  var onRequestClose = Props.onRequestClose;
  var url = Navigation.useUrl(undefined);
  var navigate = Navigation.useNavigate(undefined);
  var match = Navigation.useGoBack(undefined);
  var onGoBack = match[1];
  var canGoBack = match[0];
  var archiveVariant = CatalogVariantMutations.useArchive(id);
  var fromVariantPage = url.pathname !== LegacyRouter.routeToPathname(/* Catalog */2) && !url.pathname.includes("/product");
  return React.createElement(Modal.make, {
              children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                children: $$Intl.t("Once the variant is archived, it will only be visible in its product page and the catalog by applying the \"Archive\" filter."),
                                variation: "normal"
                              })), "xlarge", "xxlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
              opened: opened,
              title: $$Intl.t("Confirm the archiving of this variant"),
              abortButtonText: $$Intl.t("Cancel"),
              commitButtonText: $$Intl.t("Confirm"),
              commitButtonCallback: (function (param) {
                  Future.get(Curry._1(archiveVariant, undefined), (function (result) {
                          if (result.TAG !== /* Ok */0) {
                            return ;
                          }
                          if (canGoBack) {
                            if (!fromVariantPage) {
                              return ;
                            }
                            if (url.pathname !== LegacyRouter.routeToPathname(/* Catalog */2)) {
                              return Curry._1(onGoBack, undefined);
                            }
                            
                          }
                          if (fromVariantPage && url.pathname === LegacyRouter.routeToPathname(/* Catalog */2)) {
                            return Curry._3(navigate, undefined, undefined, LegacyRouter.routeToPathname(/* Catalog */2));
                          }
                          
                        }));
                }),
              onRequestClose: onRequestClose
            });
}

var make = React.memo(CatalogVariantArchiveModal, (function (oldProps, newProps) {
        if (oldProps.id === newProps.id) {
          return oldProps.opened === newProps.opened;
        } else {
          return false;
        }
      }));

var Mutations;

export {
  Mutations ,
  make ,
}
/* make Not a pure module */
