// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Select from "../../resources/selection-and-input/Select.bs.js";
import * as Tooltip from "../../resources/overlays/Tooltip.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as AsyncData from "../../primitives/AsyncData.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as SupplierRoutes from "../../app/Supplier/SupplierRoutes.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query SuppliersQuery($filterBy: InputSuppliersQueryFilter, $after: String, $first: Int)  {\nsuppliers(filterBy: $filterBy, after: $after, first: $first)  {\n__typename  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \ncompanyName  \n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.suppliers;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var value$4 = value$2.hasNextPage;
  var value$5 = value$1.edges;
  return {
          suppliers: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              endCursor: !(value$3 == null) ? value$3 : undefined,
              hasNextPage: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              companyName: value$1.companyName
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.suppliers;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.companyName;
          var value$3 = value$1.id;
          var value$4 = value$1.__typename;
          var node = {
            __typename: value$4,
            id: value$3,
            companyName: value$2
          };
          var value$5 = value.__typename;
          return {
                  __typename: value$5,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var hasNextPage = value$4 !== undefined ? value$4 : null;
  var value$5 = value$3.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$3.__typename;
  var pageInfo = {
    __typename: value$6,
    endCursor: endCursor,
    hasNextPage: hasNextPage
  };
  var value$7 = value$1.__typename;
  var suppliers = {
    __typename: value$7,
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          suppliers: suppliers
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputSuppliersQueryFilter(inp) {
  var a = inp.archived;
  var a$1 = inp.shopIds;
  return {
          archived: a !== undefined ? (
              a === "ONLY" ? "ONLY" : (
                  a === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          shopIds: a$1 !== undefined ? serializeInputObjectInFilter(a$1) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  var a$1 = inp.after;
  var a$2 = inp.first;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputSuppliersQueryFilter(a) : undefined,
          after: a$1 !== undefined ? a$1 : undefined,
          first: a$2 !== undefined ? a$2 : undefined
        };
}

function makeVariables(filterBy, after, first, param) {
  return {
          filterBy: filterBy,
          after: after,
          first: first
        };
}

function makeInputObjectInputSuppliersQueryFilter(archived, shopIds, param) {
  return {
          archived: archived,
          shopIds: shopIds
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined,
          after: undefined,
          first: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputSuppliersQueryFilter: serializeInputObjectInputSuppliersQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputSuppliersQueryFilter: makeInputObjectInputSuppliersQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_use = include.use;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputSuppliersQueryFilter: serializeInputObjectInputSuppliersQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputSuppliersQueryFilter: makeInputObjectInputSuppliersQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: Query_use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function paginateSuppliersQuery(query, cursor, dataOpt, param) {
  var data = dataOpt !== undefined ? dataOpt : [];
  return Future.flatMapOk(Future.mapError(FuturePromise.fromPromise(Curry._1(query, cursor)), undefined, (function (param) {
                    
                  })), undefined, (function (response) {
                if (response.TAG !== /* Ok */0) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: undefined
                            });
                }
                var match = response._0;
                var match$1 = match.data.suppliers;
                var match$2 = match$1.pageInfo;
                var match$3 = match$2.hasNextPage;
                if (match$3 !== undefined && match$3) {
                  if (match.error !== undefined) {
                    return Future.value({
                                TAG: /* Error */1,
                                _0: undefined
                              });
                  } else {
                    return paginateSuppliersQuery(query, match$2.endCursor, Belt_Array.concat(data, match$1.edges), undefined);
                  }
                }
                if (match.error !== undefined) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: undefined
                            });
                } else {
                  return Future.value({
                              TAG: /* Ok */0,
                              _0: Belt_Array.concat(data, match$1.edges)
                            });
                }
              }));
}

function useSuppliersQuery(shopId, skip) {
  var apolloClient = ReasonMLCommunity__ApolloClient.React.useApolloClient(undefined);
  var match = React.useState(function () {
        return /* NotAsked */0;
      });
  var setSuppliers = match[1];
  var query$1 = function (after) {
    return Curry._6(apolloClient.rescript_query, {
                query: query,
                Raw: Raw,
                parse: parse,
                serialize: serialize,
                serializeVariables: serializeVariables
              }, undefined, undefined, /* NetworkOnly */2, undefined, {
                filterBy: {
                  archived: "EXCLUDED",
                  shopIds: shopId !== undefined ? ({
                        _in: [shopId]
                      }) : undefined
                },
                after: after,
                first: 50
              });
  };
  React.useEffect((function () {
          Curry._1(setSuppliers, (function (param) {
                  return /* Loading */1;
                }));
          if (Belt_Option.isSome(shopId)) {
            Future.tapOk(paginateSuppliersQuery(query$1, undefined, undefined, undefined), (function (suppliers) {
                    Curry._1(setSuppliers, (function (param) {
                            return {
                                    TAG: /* Done */1,
                                    _0: suppliers
                                  };
                          }));
                  }));
          }
          
        }), []);
  React.useEffect((function () {
          if (!skip && Belt_Option.isSome(shopId)) {
            Curry._1(setSuppliers, (function (value) {
                    if (typeof value === "number" || value.TAG !== /* Done */1) {
                      return /* Loading */1;
                    } else {
                      return {
                              TAG: /* Reloading */0,
                              _0: value._0
                            };
                    }
                  }));
            Future.tapOk(paginateSuppliersQuery(query$1, undefined, undefined, undefined), (function (suppliers) {
                    Curry._1(setSuppliers, (function (param) {
                            return {
                                    TAG: /* Done */1,
                                    _0: suppliers
                                  };
                          }));
                  }));
          }
          
        }), [
        skip,
        shopId
      ]);
  return match[0];
}

function SupplierSelect(Props) {
  var size = Props.size;
  var label = Props.label;
  var preset = Props.preset;
  var defaultOpen = Props.defaultOpen;
  var showDefaultItemOpt = Props.showDefaultItem;
  var hideOverlayFooterOpt = Props.hideOverlayFooter;
  var shopId = Props.shopId;
  var useSuppliersQueryOpt = Props.useSuppliersQuery;
  var value = Props.value;
  var onChange = Props.onChange;
  var onDismiss = Props.onDismiss;
  var showDefaultItem = showDefaultItemOpt !== undefined ? showDefaultItemOpt : false;
  var hideOverlayFooter = hideOverlayFooterOpt !== undefined ? hideOverlayFooterOpt : false;
  var useSuppliersQuery$1 = useSuppliersQueryOpt !== undefined ? useSuppliersQueryOpt : useSuppliersQuery;
  var match = React.useState(function () {
        return false;
      });
  var setOpened = match[1];
  var queryResult = Curry._2(useSuppliersQuery$1, shopId, !match[0]);
  var suppliersResult = AsyncData.map(queryResult, (function (suppliers) {
          return Belt_Array.map(suppliers, (function (supplier) {
                        return {
                                id: supplier.node.id,
                                name: supplier.node.companyName
                              };
                      }));
        }));
  var items = typeof suppliersResult === "number" ? Belt_Option.mapWithDefault(value, [], (function (supplier) {
            return [{
                      key: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(supplier.id), supplier.name),
                      value: supplier,
                      label: supplier.name
                    }];
          })) : Belt_Array.map(suppliersResult._0, (function (supplier) {
            return {
                    key: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(supplier.id), supplier.name),
                    value: supplier,
                    label: supplier.name
                  };
          }));
  var defaultItem_label = $$Intl.t("All");
  var defaultItem_sticky = true;
  var defaultItem = {
    key: "default",
    label: defaultItem_label,
    sticky: defaultItem_sticky
  };
  var notSpecifiedItem_value = {
    id: null,
    name: $$Intl.t("Not specified")
  };
  var notSpecifiedItem_label = $$Intl.template($$Intl.t("Not specified"), undefined, undefined);
  var notSpecifiedItem_sticky = true;
  var notSpecifiedItem = {
    key: "not-specified",
    value: notSpecifiedItem_value,
    label: notSpecifiedItem_label,
    sticky: notSpecifiedItem_sticky
  };
  var sections = showDefaultItem ? [
      {
        items: [
          defaultItem,
          notSpecifiedItem
        ]
      },
      {
        items: items,
        title: $$Intl.t("Suppliers")
      }
    ] : [{
        items: items,
        title: $$Intl.t("Suppliers")
      }];
  var loading = suppliersResult === /* Loading */1;
  React.useEffect((function () {
          if (value !== undefined && typeof suppliersResult !== "number" && suppliersResult.TAG === /* Done */1) {
            if (Belt_Array.every(suppliersResult._0, (function (current) {
                      return current.id !== value.id;
                    })) && !(value.id == null)) {
              Curry._1(onChange, undefined);
            }
            
          }
          
        }), [suppliersResult]);
  var onToggle = React.useCallback((function (opened) {
          Curry._1(setOpened, (function (param) {
                  return opened;
                }));
          if (opened || onDismiss === undefined) {
            return ;
          } else {
            return Curry._1(onDismiss, undefined);
          }
        }), []);
  var disabled = Belt_Option.isNone(shopId);
  var overlayFooterLink = !hideOverlayFooter ? ({
        text: $$Intl.t("Create supplier"),
        to: {
          TAG: /* Route */0,
          _0: SupplierRoutes.newRoute(shopId, undefined)
        }
      }) : undefined;
  var tmp = {
    placeholder: $$Intl.t("Select a supplier"),
    disabled: disabled,
    loading: loading,
    sections: sections,
    preset: preset,
    value: value,
    onChange: onChange,
    onToggle: onToggle
  };
  if (label !== undefined) {
    tmp.label = label;
  }
  if (defaultOpen !== undefined) {
    tmp.defaultOpen = defaultOpen;
  }
  if (size !== undefined) {
    tmp.size = Caml_option.valFromOption(size);
  }
  if (overlayFooterLink !== undefined) {
    tmp.overlayFooterLink = Caml_option.valFromOption(overlayFooterLink);
  }
  return React.createElement(Tooltip.make, {
              children: React.createElement(Select.make, tmp),
              placement: "top",
              content: React.createElement(Tooltip.Span.make, {
                    text: $$Intl.t("Please select a shop beforehand.")
                  }),
              disabled: !disabled,
              delay: 0
            });
}

var make = React.memo(SupplierSelect);

export {
  Query ,
  paginateSuppliersQuery ,
  useSuppliersQuery ,
  make ,
}
/* query Not a pure module */
