// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Uuid from "../../externals/Uuid.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Select from "../../resources/selection-and-input/Select.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query taxes($filterBy: InputTaxQueryFilter)  {\ntaxes(first: 50, filterBy: $filterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nvalue  \nshop  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.taxes;
  var value$2 = value$1.edges;
  return {
          taxes: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.shop;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              value: value$1.value,
                              shop: {
                                __typename: value$2.__typename,
                                id: value$2.id
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.taxes;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.shop;
          var value$3 = value$2.id;
          var value$4 = value$2.__typename;
          var shop = {
            __typename: value$4,
            id: value$3
          };
          var value$5 = value$1.value;
          var value$6 = value$1.id;
          var value$7 = value$1.__typename;
          var node = {
            __typename: value$7,
            id: value$6,
            value: value$5,
            shop: shop
          };
          var value$8 = value.__typename;
          return {
                  __typename: value$8,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var taxes = {
    __typename: value$3,
    edges: edges
  };
  return {
          taxes: taxes
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputTaxQueryFilter(inp) {
  var a = inp.shopIds;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputTaxQueryFilter(a) : undefined
        };
}

function makeVariables(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectInputTaxQueryFilter(shopIds, param) {
  return {
          shopIds: shopIds
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputTaxQueryFilter: serializeInputObjectInputTaxQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputTaxQueryFilter: makeInputObjectInputTaxQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputTaxQueryFilter: serializeInputObjectInputTaxQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputTaxQueryFilter: makeInputObjectInputTaxQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function CatalogProductTaxSelect(Props) {
  var value = Props.value;
  var defaultTaxValue = Props.defaultTaxValue;
  var onChange = Props.onChange;
  var activeShop = Auth.useActiveShop(undefined);
  var queryResults = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* NetworkOnly */3,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          filterBy: {
            shopIds: {
              _in: [Belt_Option.mapWithDefault(activeShop, "", (function (param) {
                        return param.id;
                      }))]
            }
          }
        }
      ]);
  var match = queryResults.data;
  var options = match !== undefined ? Belt_SortArray.stableSortBy(Belt_Array.keepMap(match.taxes.edges, (function (param) {
                var tax = param.node;
                var uuid = Uuid.fromString(tax.id);
                if (uuid !== undefined) {
                  return {
                          id: Caml_option.valFromOption(uuid),
                          value: tax.value
                        };
                }
                
              })), (function (current, next) {
            if (current.value > next.value) {
              return 1;
            } else {
              return 0;
            }
          })) : [];
  React.useEffect((function () {
          if (Belt_Option.isNone(value)) {
            var taxFromQueryOptions = Belt_Array.getBy(options, (function (tax) {
                    return tax.value === defaultTaxValue;
                  }));
            var match = Belt_Array.get(options, 0);
            if (taxFromQueryOptions !== undefined) {
              Curry._1(onChange, taxFromQueryOptions);
            } else if (match !== undefined) {
              Curry._1(onChange, match);
            }
            
          }
          
        }), [queryResults.data]);
  var onChange$1 = React.useCallback((function (tax) {
          Belt_Option.forEach(tax, onChange);
        }), []);
  var items = Belt_Array.map(options, (function (tax) {
          return {
                  key: tax.id,
                  value: tax,
                  label: tax.value.toFixed(2).replace(".", ",") + " %"
                };
        }));
  var sections = [{
      items: items
    }];
  return React.createElement(Select.make, {
              label: $$Intl.t("VAT"),
              placeholder: $$Intl.t("Loading..."),
              disabled: Belt_Option.isNone(value),
              sections: sections,
              preset: {
                NAME: "inputField",
                VAL: {
                  required: true
                }
              },
              value: value,
              onChange: onChange$1
            });
}

var make = React.memo(CatalogProductTaxSelect);

export {
  Query ,
  make ,
}
/* query Not a pure module */
