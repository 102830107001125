// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Menu from "../../resources/navigation/Menu.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as MenuItem from "../../resources/navigation/MenuItem.bs.js";
import * as ButtonLink from "../../resources/actions/ButtonLink.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as LegacyRouter from "../../core/LegacyRouter.bs.js";
import * as CatalogProductMutations from "../../app/Catalog/CatalogProductMutations.bs.js";
import * as CatalogProductArchiveModal from "./CatalogProductArchiveModal.bs.js";

function CatalogProductPageActions(Props) {
  var cku = Props.cku;
  var id = Props.id;
  var status = Props.status;
  var allVariantsArchived = Props.allVariantsArchived;
  var scope = Auth.useScope(undefined);
  var url = Navigation.useUrl(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setArchiveModalOpened = match[1];
  var unarchiveProduct = CatalogProductMutations.useUnarchive(id);
  var tmp;
  var exit = 0;
  if (allVariantsArchived) {
    exit = 1;
  } else if (status !== undefined) {
    if (status !== 2) {
      if (status >= 3) {
        tmp = null;
      } else {
        exit = 1;
      }
    } else {
      var exit$1 = 0;
      if (scope.TAG === /* Organisation */0 && scope.activeShop === undefined) {
        tmp = null;
      } else {
        exit$1 = 2;
      }
      if (exit$1 === 2) {
        tmp = React.createElement(MenuItem.make, {
              content: {
                TAG: /* Text */0,
                _0: $$Intl.t("Unarchive")
              },
              action: {
                TAG: /* Callback */0,
                _0: (function (param) {
                    Curry._1(unarchiveProduct, undefined);
                  })
              }
            });
      }
      
    }
  } else {
    tmp = null;
  }
  if (exit === 1) {
    if (status !== undefined && status < 2) {
      var exit$2 = 0;
      if (scope.TAG === /* Organisation */0 && scope.activeShop === undefined) {
        tmp = null;
      } else {
        exit$2 = 2;
      }
      if (exit$2 === 2) {
        tmp = React.createElement(MenuItem.make, {
              content: {
                TAG: /* Text */0,
                _0: $$Intl.t("Archive")
              },
              action: {
                TAG: /* Callback */0,
                _0: (function (param) {
                    Curry._1(setArchiveModalOpened, (function (param) {
                            return true;
                          }));
                  })
              }
            });
      }
      
    } else {
      tmp = null;
    }
  }
  var tmp$1;
  tmp$1 = scope.TAG === /* Organisation */0 ? React.createElement(MenuItem.make, {
          content: {
            TAG: /* Text */0,
            _0: $$Intl.t("Duplicate")
          },
          action: {
            TAG: /* OpenLink */1,
            _0: {
              TAG: /* Route */0,
              _0: LegacyRouter.routeToPathname(/* Catalog */2) + ("/product/duplication/" + cku + "")
            }
          }
        }) : null;
  return React.createElement(React.Fragment, undefined, React.createElement(Inline.make, {
                  children: null,
                  space: "small"
                }, React.createElement(Menu.make, {
                      children: null
                    }, React.createElement(MenuItem.make, {
                          content: {
                            TAG: /* Text */0,
                            _0: $$Intl.t("Edit")
                          },
                          action: {
                            TAG: /* OpenLink */1,
                            _0: {
                              TAG: /* Route */0,
                              _0: url.pathname + "/edit"
                            }
                          }
                        }), tmp, tmp$1), React.createElement(ButtonLink.make, {
                      children: $$Intl.t("Create new variant"),
                      to: {
                        TAG: /* Route */0,
                        _0: LegacyRouter.routeToPathname(/* Catalog */2) + ("/" + cku + "/create")
                      }
                    })), React.createElement(CatalogProductArchiveModal.make, {
                  id: id,
                  opened: match[0],
                  onRequestClose: (function (param) {
                      Curry._1(setArchiveModalOpened, (function (param) {
                              return false;
                            }));
                    })
                }));
}

var make = React.memo(CatalogProductPageActions);

var Mutations;

export {
  Mutations ,
  make ,
}
/* make Not a pure module */
