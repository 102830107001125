// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as React from "react";
import * as Popover from "../../resources/overlays/Popover.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CatalogVariantStockThresholdFormInputs from "./CatalogVariantStockThresholdFormInputs.bs.js";
import * as CatalogVariantStockThresholdFormContainer from "./CatalogVariantStockThresholdFormContainer.bs.js";

function CatalogVariantStockThresholdFormPopover(Props) {
  var popover = Props.popover;
  var popoverAriaProps = Props.popoverAriaProps;
  var popoverTriggerRef = Props.popoverTriggerRef;
  var variantId = Props.variantId;
  var maxStockThreshold = Props.maxStockThreshold;
  var minStockThreshold = Props.minStockThreshold;
  var stockOrderTriggerThreshold = Props.stockOrderTriggerThreshold;
  return React.createElement(CatalogVariantStockThresholdFormContainer.make, {
              children: React.createElement(Popover.make, {
                    children: React.createElement(Popover.Dialog.make, {
                          children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(CatalogVariantStockThresholdFormInputs.make, {})), "large", "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                          ariaProps: popoverAriaProps.overlayProps
                        }),
                    triggerRef: popoverTriggerRef,
                    state: popover,
                    placement: "bottom end",
                    modal: false
                  }),
              variantId: variantId,
              maxStockThreshold: maxStockThreshold,
              minStockThreshold: minStockThreshold,
              stockOrderTriggerThreshold: stockOrderTriggerThreshold
            });
}

var make = React.memo(CatalogVariantStockThresholdFormPopover);

export {
  make ,
}
/* make Not a pure module */
