// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Modal from "../../resources/overlays/Modal.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Banner from "../../resources/feedback-indicators/Banner.bs.js";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Tooltip from "../../resources/overlays/Tooltip.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SessionTracker from "../../core/SessionTracker.bs.js";
import * as InputNumberField from "../../resources/selection-and-input/InputNumberField.bs.js";
import * as InputSegmentedControlsField from "../../resources/selection-and-input/InputSegmentedControlsField.bs.js";

function globalFeeKindToText(kind) {
  if (kind) {
    return $$Intl.t("Price prorata");
  } else {
    return $$Intl.t("Per unit");
  }
}

function OrderCartGlobalFeePickerModal(Props) {
  var opened = Props.opened;
  var onCommit = Props.onCommit;
  var onRequestClose = Props.onRequestClose;
  var match = React.useState(function () {
        return {
                amount: 0,
                globalFeeKind: /* FeePerUnit */0
              };
      });
  var setValue = match[1];
  var value = match[0];
  var captureEvent = SessionTracker.useCaptureEvent(undefined);
  var onCommit$1 = React.useCallback((function (param) {
          Curry._1(onCommit, value);
          Curry._1(captureEvent, "cart_global_shipping_costs_click_apply");
        }), [value]);
  return React.createElement(Modal.make, {
              children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Stack.make, {
                                children: null,
                                space: "large"
                              }, React.createElement(InputNumberField.make, {
                                    value: value.amount,
                                    minValue: 0,
                                    precision: 2,
                                    label: $$Intl.t("Total amount excl. VAT"),
                                    appender: {
                                      TAG: /* Currency */0,
                                      _0: "EUR"
                                    },
                                    required: false,
                                    onChange: (function (amount) {
                                        Curry._1(setValue, (function (prev) {
                                                return {
                                                        amount: amount,
                                                        globalFeeKind: prev.globalFeeKind
                                                      };
                                              }));
                                      })
                                  }), React.createElement(InputSegmentedControlsField.make, {
                                    label: $$Intl.t("Allocation of costs"),
                                    grow: true,
                                    tooltip: React.createElement(React.Fragment, undefined, React.createElement(Tooltip.Span.make, {
                                              text: $$Intl.t("order_cart.global_fee_picker_modal.allocation_of_costs.tooltip.label_1"),
                                              italic: true,
                                              bold: true
                                            }), React.createElement(Tooltip.Br.make, {}), React.createElement(Tooltip.Span.make, {
                                              text: $$Intl.t("order_cart.global_fee_picker_modal.allocation_of_costs.tooltip.content_1")
                                            }), React.createElement(Tooltip.Br.make, {}), React.createElement(Tooltip.Br.make, {
                                              space: "small"
                                            }), React.createElement(Tooltip.Span.make, {
                                              text: $$Intl.t("order_cart.global_fee_picker_modal.allocation_of_costs.tooltip.label_2"),
                                              italic: true,
                                              bold: true
                                            }), React.createElement(Tooltip.Br.make, {}), React.createElement(Tooltip.Span.make, {
                                              text: $$Intl.t("order_cart.global_fee_picker_modal.allocation_of_costs.tooltip.content_2")
                                            })),
                                    value: value.globalFeeKind,
                                    required: false,
                                    onChange: (function (globalFeeKind) {
                                        Curry._1(setValue, (function (prev) {
                                                return {
                                                        amount: prev.amount,
                                                        globalFeeKind: globalFeeKind
                                                      };
                                              }));
                                      }),
                                    options: {
                                      hd: /* FeePerUnit */0,
                                      tl: {
                                        hd: /* FeeProratedByPrice */1,
                                        tl: /* [] */0
                                      }
                                    },
                                    optionToText: globalFeeKindToText
                                  }), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Banner.make, {
                                                textStatus: {
                                                  TAG: /* Info */3,
                                                  _0: $$Intl.t("order_cart.global_fee_picker_modal.info_banner")
                                                }
                                              })), undefined, undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined)))), "large", "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
              opened: opened,
              title: $$Intl.t("Global shipping costs"),
              variation: "secondary",
              backgroundColor: "#fefefe",
              renderStartText: (function (param) {
                  return React.createElement(Inline.make, {
                              children: null,
                              grow: true
                            });
                }),
              abortButtonText: $$Intl.t("Cancel"),
              commitButtonVariation: "primary",
              commitButtonText: $$Intl.t("Apply"),
              commitButtonCallback: onCommit$1,
              onRequestClose: onRequestClose
            });
}

var make = React.memo(OrderCartGlobalFeePickerModal);

export {
  globalFeeKindToText ,
  make ,
}
/* make Not a pure module */
