// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "../primitives/Json.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactUpdateEffect from "../primitives/ReactUpdateEffect.bs.js";
import * as ReactUpdateDebounced from "../primitives/ReactUpdateDebounced.bs.js";

function read(key, decoder) {
  try {
    var jsonState = Belt_Option.map(Caml_option.null_to_opt(localStorage.getItem(key)), Json.parseExn);
    if (jsonState === undefined) {
      return ;
    }
    var persistedState = Curry._1(decoder, Caml_option.valFromOption(jsonState));
    if (persistedState.TAG === /* Ok */0) {
      return Caml_option.some(persistedState._0);
    }
    localStorage.removeItem(key);
    return ;
  }
  catch (_parsingError){
    localStorage.removeItem(key);
    return ;
  }
}

function store(state, key, encoder) {
  var jsonStringifiedState = Json.stringify(Curry._1(encoder, state));
  localStorage.setItem(key, jsonStringifiedState);
}

function useRead(key, decoder) {
  return React.useState(function () {
                return read(key, decoder);
              })[0];
}

function usePersistOnChange(state, key, disabledOpt, debounceDelayOpt, encoder, decoder, param) {
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var debounceDelay = debounceDelayOpt !== undefined ? debounceDelayOpt : 0;
  var initialValues = useRead(key, decoder);
  var match = React.useState(function () {
        return Belt_Option.isSome(initialValues);
      });
  var setPersisted = match[1];
  var debouncedValues = ReactUpdateDebounced.use(state, debounceDelay);
  var debouncing = Caml_obj.notequal(state, debouncedValues);
  ReactUpdateEffect.use1((function (param) {
          if (!disabled && !debouncing) {
            store(state, key, encoder);
            Curry._1(setPersisted, (function (param) {
                    return true;
                  }));
          }
          
        }), [debouncing]);
  if (match[0]) {
    return !debouncing;
  } else {
    return false;
  }
}

function make(key, encoder, decoder) {
  var read$1 = function (param) {
    return read(key, decoder);
  };
  var store$1 = function (state) {
    store(state, key, encoder);
  };
  var useRead$1 = function (param) {
    return useRead(key, decoder);
  };
  var usePersistOnChange$1 = function (state, disabledOpt, debounceDelayOpt, param) {
    var disabled = disabledOpt !== undefined ? disabledOpt : false;
    var debounceDelay = debounceDelayOpt !== undefined ? debounceDelayOpt : 0;
    return usePersistOnChange(state, key, disabled, debounceDelay, encoder, decoder, undefined);
  };
  return {
          read: read$1,
          store: store$1,
          useRead: useRead$1,
          usePersistOnChange: usePersistOnChange$1
        };
}

export {
  read ,
  store ,
  useRead ,
  usePersistOnChange ,
  make ,
}
/* Json Not a pure module */
