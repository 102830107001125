// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../layout-and-structure/Box.bs.js";
import * as Icon from "../images-and-icons/Icon.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Inline from "../layout-and-structure/Inline.bs.js";
import * as Spinner from "../feedback-indicators/Spinner.bs.js";
import * as FontFaces from "../theme/FontFaces.bs.js";
import * as ReactAria from "../../externals/ReactAria.bs.js";
import * as TextStyle from "../typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactAria$1 from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactStately from "../../externals/ReactStately.bs.js";
import * as ReactDOMStyle from "@rescript/react/src/ReactDOMStyle.bs.js";
import * as AnimatedRender from "../../primitives/AnimatedRender.bs.js";
import * as Utils from "@react-aria/utils";
import * as ReactDomEventTarget from "../../primitives/ReactDomEventTarget.bs.js";

var defaultSectionTitle = $$Intl.t("Results");

function backgroundColor(focused, selected, disabled) {
  if (disabled) {
    return "#f3f3f7";
  } else if (focused) {
    return "#f4e4ee";
  } else {
    return "transparent";
  }
}

function fontColor(focused, selected, disabled) {
  if (disabled) {
    return "#8c8B9b";
  } else if (selected || focused) {
    return "#a01e72";
  } else {
    return "#25243a";
  }
}

var noResultItemStyle = {
  backgroundColor: "transparent",
  color: "#797885",
  cursor: "default",
  fontFamily: FontFaces.fontFamilyFromFontName("LibreFranklin"),
  fontSize: "15px",
  fontStyle: "italic",
  lineHeight: "35px",
  paddingRight: "10px",
  paddingLeft: "10px"
};

function itemStyle(focused, selected, disabled, size) {
  return {
          backgroundColor: backgroundColor(focused, selected, disabled),
          color: fontColor(focused, selected, disabled),
          cursor: disabled ? "default" : "pointer",
          display: "flex",
          fontFamily: FontFaces.fontFamilyFromFontName("LibreFranklin"),
          fontSize: size === "compact" ? "14px" : "15px",
          fontWeight: selected ? "500" : "400",
          lineHeight: size === "compact" ? "20px" : "22px",
          marginTop: "1px",
          marginBottom: "1px",
          minHeight: size === "compact" ? "32px" : "35px",
          paddingRight: "10px",
          paddingLeft: "10px",
          borderRadius: size === "compact" ? "3px" : "5px",
          alignItems: "center",
          flexDirection: "row"
        };
}

var listBottomGradientStyle = {
  backgroundImage: "radial-gradient(ellipse at center, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 75%)",
  backgroundPosition: "center top",
  bottom: "0",
  height: "16px",
  left: "0",
  position: "absolute",
  right: "0",
  zIndex: "1",
  backgroundSize: "100% 200%",
  pointerEvents: "none"
};

var sectionStyle = {
  display: "flex",
  overflow: "hidden",
  flexDirection: "column"
};

function listStyle(size) {
  return ReactDOMStyle.unsafeAddProp({
              display: "flex",
              maxHeight: "235px",
              overflow: "auto",
              padding: size === "compact" ? "2px 3px" : "3px 4px",
              flexDirection: "column"
            }, "scrollPaddingBlock", "5px");
}

var make = React.memo(function (Props) {
      var item = Props.item;
      var state = Props.state;
      var size = Props.size;
      var optionRef = React.useRef(null);
      var match = ReactAria$1.useOption({
            key: item.key
          }, state, optionRef);
      var disabled = match.isDisabled;
      var selected = match.isSelected;
      return React.createElement(ReactAria.Spread.make, {
                  props: match.optionProps,
                  children: React.createElement("div", {
                        ref: optionRef,
                        style: itemStyle(match.isFocused, selected, disabled, size)
                      }, React.createElement(Box.make, Box.makeProps(Caml_option.some(item.rendered), undefined, "xsmall", undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined)), React.createElement("div", {
                            style: {
                              display: "flex",
                              minWidth: "14px",
                              paddingLeft: "12px"
                            }
                          }, selected ? React.createElement(Icon.make, {
                                  name: "tick_bold",
                                  fill: disabled ? "#bdbdca" : "#c04695",
                                  size: size === "compact" ? 12 : 14
                                }) : null))
                });
    });

var make$1 = React.memo(function (Props) {
      var section = Props.section;
      var state = Props.state;
      var size = Props.size;
      var itemsLimit = Props.itemsLimit;
      var match = ReactAria$1.useListBoxSection({
            heading: Caml_option.some(section.rendered),
            "aria-label": "section"
          });
      var children = ReactStately.Collection.toArray(section.childNodes);
      var itemsLimit$1 = Belt_Option.getWithDefault(itemsLimit, children.length);
      var childElements = Belt_Array.slice(children, 0, itemsLimit$1);
      return React.createElement(ReactAria.Spread.make, {
                  props: match.itemProps,
                  children: React.createElement("div", undefined, section.rendered !== null && ReactStately.Collection.toArray(section.childNodes).length !== 0 ? React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                          children: React.createElement(ReactAria.Spread.make, {
                                                props: match.headingProps,
                                                children: React.createElement("span", undefined, section.rendered)
                                              }),
                                          variation: "normal",
                                          size: "xxsmall"
                                        })), undefined, undefined, "xnormal", "xsmall", "xnormal", undefined, undefined, Caml_option.some(Style.unsafeCss({
                                          cursor: "default"
                                        })), undefined, undefined, undefined)) : null, React.createElement(ReactAria.Spread.make, {
                            props: match.groupProps,
                            children: React.createElement("div", {
                                  style: sectionStyle
                                }, Belt_Array.map(childElements, (function (item) {
                                        return React.createElement(make, {
                                                    item: item,
                                                    state: state,
                                                    size: size,
                                                    key: item.key
                                                  });
                                      })))
                          }))
                });
    });

function ListBox(Props) {
  var domRef = Props.domRef;
  var state = Props.state;
  var props = Props.props;
  var sizeOpt = Props.size;
  var itemsLimit = Props.itemsLimit;
  var noResultLabel = Props.noResultLabel;
  var loadingOpt = Props.loading;
  var onScroll = Props.onScroll;
  var size = sizeOpt !== undefined ? sizeOpt : "normal";
  var loading = loadingOpt !== undefined ? loadingOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setScrollable = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setScrollReachedBottom = match$1[1];
  var match$2 = ReactAria$1.useListBox(props, state, domRef);
  var handleScrollable = React.useCallback((function (param) {
          var domElement = domRef.current;
          if (!(domElement == null)) {
            return Curry._1(setScrollable, (function (param) {
                          return domElement.scrollHeight > domElement.clientHeight;
                        }));
          }
          
        }), [domRef]);
  React.useLayoutEffect((function () {
          Curry._1(handleScrollable, undefined);
        }), [
        handleScrollable,
        domRef
      ]);
  Utils.useResizeObserver({
        ref: domRef,
        onResize: handleScrollable
      });
  var onScroll$1 = React.useCallback((function ($$event) {
          var domElement = ReactDomEventTarget.toUnsafeDomEventTarget($$event.currentTarget);
          var scrollBottom = (domElement.scrollHeight - (domElement.scrollTop | 0) | 0) - domElement.clientHeight | 0;
          Curry._1(setScrollable, (function (param) {
                  return scrollBottom > 0;
                }));
          Curry._1(setScrollReachedBottom, (function (param) {
                  return scrollBottom < 15;
                }));
          Belt_Option.forEach(onScroll, (function (fn) {
                  Curry._1(fn, $$event);
                }));
        }), []);
  var noItemPlaceholder = React.createElement("span", {
        style: noResultItemStyle
      }, noResultLabel !== undefined ? noResultLabel : $$Intl.t("No result found"));
  var listBoxProps = ReactAria$1.mergeProps(match$2.listBoxProps, {
        tabIndex: 0
      });
  var collection = ReactStately.Collection.toArray(state.collection);
  var tmp;
  var exit = 0;
  if (loading) {
    tmp = React.createElement(Inline.make, {
          children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Spinner.make, {
                            size: size === "compact" ? 21 : 26
                          })), undefined, size === "compact" ? "xsmall" : "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
          align: "center"
        });
  } else {
    var len = collection.length;
    if (len !== 1) {
      if (len !== 0) {
        exit = 1;
      } else {
        tmp = noItemPlaceholder;
      }
    } else {
      var section = collection[0];
      if (ReactStately.Collection.toArray(section.childNodes).length === 0) {
        tmp = noItemPlaceholder;
      } else {
        exit = 1;
      }
    }
  }
  if (exit === 1) {
    tmp = Belt_Array.map(collection, (function (section) {
            var tmp = {
              section: section,
              state: state,
              size: size,
              key: section.key
            };
            if (itemsLimit !== undefined) {
              tmp.itemsLimit = Caml_option.valFromOption(itemsLimit);
            }
            return React.createElement(make$1, tmp);
          }));
  }
  return React.createElement(ReactAria.Spread.make, {
              props: listBoxProps,
              children: React.createElement("div", {
                    style: {
                      position: "relative"
                    }
                  }, React.createElement("div", {
                        ref: domRef,
                        style: listStyle(size),
                        onScroll: onScroll$1
                      }, tmp, React.createElement(AnimatedRender.make, {
                            children: React.createElement("div", {
                                  style: listBottomGradientStyle
                                }),
                            displayed: match[0] && !match$1[0],
                            animation: "fade",
                            duration: 250
                          })))
            });
}

var make$2 = React.memo(ListBox);

var defaultItemsPerScroll = 20;

export {
  defaultSectionTitle ,
  defaultItemsPerScroll ,
  make$2 as make,
}
/* defaultSectionTitle Not a pure module */
