// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Sheet from "../../primitives/Sheet.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as ApolloConfig from "../../core/ApolloConfig.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query PromotionSheetQuery($id: ID!, $first: Int, $after: String)  {\npromotionCampaign(id: $id)  {\n__typename  \nstartDate  \ndiscounts(first: $first, after: $after)  {\n__typename  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nvalue  \nkind  \nvariant  {\n__typename  \nid  \nstockKeepingUnit  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.promotionCampaign;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.discounts;
    var value$3 = value$2.pageInfo;
    var value$4 = value$3.endCursor;
    var value$5 = value$3.hasNextPage;
    var value$6 = value$2.edges;
    tmp = {
      __typename: value$1.__typename,
      startDate: Scalar.Datetime.parse(value$1.startDate),
      discounts: {
        __typename: value$2.__typename,
        pageInfo: {
          __typename: value$3.__typename,
          endCursor: !(value$4 == null) ? value$4 : undefined,
          hasNextPage: !(value$5 == null) ? value$5 : undefined
        },
        edges: Js_array.map((function (value) {
                var value$1 = value.node;
                var value$2 = value$1.kind;
                var tmp;
                switch (value$2) {
                  case "CURRENCY" :
                      tmp = "CURRENCY";
                      break;
                  case "PERCENT" :
                      tmp = "PERCENT";
                      break;
                  default:
                    throw {
                          RE_EXN_ID: "Not_found",
                          Error: new Error()
                        };
                }
                var value$3 = value$1.variant;
                var value$4 = value$3.stockKeepingUnit;
                return {
                        __typename: value.__typename,
                        node: {
                          __typename: value$1.__typename,
                          value: value$1.value,
                          kind: tmp,
                          variant: {
                            __typename: value$3.__typename,
                            id: value$3.id,
                            stockKeepingUnit: !(value$4 == null) ? value$4 : undefined
                          }
                        }
                      };
              }), value$6)
      }
    };
  }
  return {
          promotionCampaign: tmp
        };
}

function serialize(value) {
  var value$1 = value.promotionCampaign;
  var promotionCampaign;
  if (value$1 !== undefined) {
    var value$2 = value$1.discounts;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var value$2 = value$1.variant;
            var value$3 = value$2.stockKeepingUnit;
            var stockKeepingUnit = value$3 !== undefined ? value$3 : null;
            var value$4 = value$2.id;
            var value$5 = value$2.__typename;
            var variant = {
              __typename: value$5,
              id: value$4,
              stockKeepingUnit: stockKeepingUnit
            };
            var value$6 = value$1.kind;
            var kind = value$6 === "PERCENT" ? "PERCENT" : "CURRENCY";
            var value$7 = value$1.value;
            var value$8 = value$1.__typename;
            var node = {
              __typename: value$8,
              value: value$7,
              kind: kind,
              variant: variant
            };
            var value$9 = value.__typename;
            return {
                    __typename: value$9,
                    node: node
                  };
          }), value$3);
    var value$4 = value$2.pageInfo;
    var value$5 = value$4.hasNextPage;
    var hasNextPage = value$5 !== undefined ? value$5 : null;
    var value$6 = value$4.endCursor;
    var endCursor = value$6 !== undefined ? value$6 : null;
    var value$7 = value$4.__typename;
    var pageInfo = {
      __typename: value$7,
      endCursor: endCursor,
      hasNextPage: hasNextPage
    };
    var value$8 = value$2.__typename;
    var discounts = {
      __typename: value$8,
      pageInfo: pageInfo,
      edges: edges
    };
    var value$9 = value$1.startDate;
    var value$10 = Scalar.Datetime.serialize(value$9);
    var value$11 = value$1.__typename;
    promotionCampaign = {
      __typename: value$11,
      startDate: value$10,
      discounts: discounts
    };
  } else {
    promotionCampaign = null;
  }
  return {
          promotionCampaign: promotionCampaign
        };
}

function serializeVariables(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  return {
          id: inp.id,
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined
        };
}

function makeVariables(id, first, after, param) {
  return {
          id: id,
          first: first,
          after: after
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_use = include.use;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: Query_use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function makeFilename(name, date, extension) {
  return $$Intl.t("promotionsexport") + "_" + Belt_Array.joinWith(name.split(" "), "_", (function (x) {
                return x;
              })) + "_" + Belt_Array.joinWith($$Intl.dateTimeFormat(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, date).split("/"), "-", (function (x) {
                return x;
              })) + "." + extension;
}

function makeCsvFilename(param) {
  return function (param$1) {
    return makeFilename(param, param$1, Sheet.csvFileExtension);
  };
}

function makeExcelFilename(param) {
  return function (param$1) {
    return makeFilename(param, param$1, Sheet.excelFileExtension);
  };
}

function makeRows(promotionCampaign) {
  var discountHeaderRow = [
    $$Intl.t("Variant ID"),
    "SKU",
    $$Intl.t("Promotional value"),
    $$Intl.t("Promotional type")
  ];
  var discountRows = Belt_Array.map(promotionCampaign, (function (discount) {
          var match = discount.kind;
          return [
                  discount.variantId,
                  Belt_Option.getWithDefault(discount.variantStockKeepingUnit, ""),
                  String(discount.value).replace(".", ","),
                  match === "PERCENT" ? "%" : "eur"
                ];
        }));
  return Belt_Array.concatMany([
              [discountHeaderRow],
              discountRows
            ]);
}

function query$1(after, promotionCampaignId, first) {
  return FuturePromise.fromPromise(Curry._6(ApolloConfig.makeClient(undefined).rescript_query, {
                  query: query,
                  Raw: Raw,
                  parse: parse,
                  serialize: serialize,
                  serializeVariables: serializeVariables
                }, undefined, undefined, undefined, undefined, {
                  id: promotionCampaignId,
                  first: first,
                  after: after
                }));
}

function queryPromotionDiscountProducts(promotionCampaignId, previousResult, firstOpt, after, param) {
  var first = firstOpt !== undefined ? firstOpt : 50;
  return Future.flatMap(query$1(after, promotionCampaignId, first), undefined, (function (result) {
                if (result.TAG !== /* Ok */0) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: /* QueryFailure */0
                            });
                }
                var match = result._0;
                if (match.TAG !== /* Ok */0) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: /* QueryFailure */0
                            });
                }
                var match$1 = match._0;
                var campaigns = match$1.data.promotionCampaign;
                if (campaigns === undefined) {
                  if (match$1.error !== undefined) {
                    return Future.value({
                                TAG: /* Error */1,
                                _0: /* QueryFailure */0
                              });
                  } else {
                    return Future.value({
                                TAG: /* Ok */0,
                                _0: undefined
                              });
                  }
                }
                if (match$1.error !== undefined) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: /* QueryFailure */0
                            });
                }
                var promotionCampaign = previousResult !== undefined ? Belt_Array.concat(previousResult, Belt_Array.map(campaigns.discounts.edges, (function (param) {
                              var discount = param.node;
                              return {
                                      value: discount.value,
                                      kind: discount.kind,
                                      variantId: discount.variant.id,
                                      variantStockKeepingUnit: discount.variant.stockKeepingUnit
                                    };
                            }))) : Belt_Array.map(campaigns.discounts.edges, (function (param) {
                          var discount = param.node;
                          return {
                                  value: discount.value,
                                  kind: discount.kind,
                                  variantId: discount.variant.id,
                                  variantStockKeepingUnit: discount.variant.stockKeepingUnit
                                };
                        }));
                var match$2 = campaigns.discounts.pageInfo;
                var endCursor = match$2.endCursor;
                if (endCursor !== undefined) {
                  var match$3 = match$2.hasNextPage;
                  if (match$3 !== undefined && match$3) {
                    return queryPromotionDiscountProducts(promotionCampaignId, promotionCampaign, first, endCursor, undefined);
                  } else {
                    return Future.value({
                                TAG: /* Ok */0,
                                _0: promotionCampaign
                              });
                  }
                }
                var match$4 = match$2.hasNextPage;
                if (match$4 !== undefined && match$4) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: /* QueryFailure */0
                            });
                } else {
                  return Future.value({
                              TAG: /* Ok */0,
                              _0: promotionCampaign
                            });
                }
              }));
}

function makeCsvPlainText(promotionCampaign) {
  return Sheet.makeCsvPlainText(undefined, makeRows(promotionCampaign));
}

function makeCsvBlob(promotionCampaign) {
  return Belt_Result.map(Sheet.makeCsvPlainText(undefined, makeRows(promotionCampaign)), Sheet.makeCsvBlobFromPlainText);
}

function queryAndMakeCsvBlob(promotionCampaignId) {
  return Future.map(queryPromotionDiscountProducts(promotionCampaignId, undefined, undefined, undefined, undefined), undefined, (function (results) {
                if (results.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: results._0
                        };
                }
                var discountProducts = results._0;
                if (discountProducts === undefined) {
                  return {
                          TAG: /* Error */1,
                          _0: /* NoDataFailure */1
                        };
                }
                if (discountProducts.length === 0) {
                  return {
                          TAG: /* Error */1,
                          _0: /* NoDataFailure */1
                        };
                }
                var csvBlob = Belt_Result.map(Sheet.makeCsvPlainText(undefined, makeRows(discountProducts)), Sheet.makeCsvBlobFromPlainText);
                if (csvBlob.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: csvBlob._0
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: /* WriteBlobFailure */2
                        };
                }
              }));
}

function makeExcelBlob(promotionCampaign) {
  return Future.mapError(Sheet.makeExcelBlob(makeRows(promotionCampaign), $$Intl.t("Promotional campaigns")), undefined, (function (param) {
                return /* WriteBlobFailure */2;
              }));
}

function queryAndMakeExcelBlob(promotionCampaignId) {
  return Future.flatMap(queryPromotionDiscountProducts(promotionCampaignId, undefined, undefined, undefined, undefined), undefined, (function (result) {
                if (result.TAG !== /* Ok */0) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: result._0
                            });
                }
                var discountProducts = result._0;
                if (discountProducts !== undefined && discountProducts.length !== 0) {
                  return makeExcelBlob(discountProducts);
                } else {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: /* NoDataFailure */1
                            });
                }
              }));
}

export {
  Query ,
  makeFilename ,
  makeCsvFilename ,
  makeExcelFilename ,
  makeRows ,
  query$1 as query,
  queryPromotionDiscountProducts ,
  makeCsvPlainText ,
  makeCsvBlob ,
  queryAndMakeCsvBlob ,
  makeExcelBlob ,
  queryAndMakeExcelBlob ,
}
/* query Not a pure module */
