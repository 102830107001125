// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Page from "../../resources/layout-and-structure/Page.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Column from "../../resources/layout-and-structure/Column.bs.js";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Columns from "../../resources/layout-and-structure/Columns.bs.js";
import * as Notifier from "../../bundles/Notifier/Notifier.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CatalogVariantNotesCard from "../../modules/Catalog/CatalogVariantNotesCard.bs.js";
import * as CatalogProductStatusBadge from "../../modules/Catalog/CatalogProductStatusBadge.bs.js";
import * as CatalogVariantPageActions from "../../modules/Catalog/CatalogVariantPageActions.bs.js";
import * as CatalogVariantStockTableCard from "../../modules/Catalog/CatalogVariantStockTableCard.bs.js";
import * as CatalogVariantInformationCard from "../../modules/Catalog/CatalogVariantInformationCard.bs.js";
import * as CatalogVariantStatusTableCard from "../../modules/Catalog/CatalogVariantStatusTableCard.bs.js";
import * as CatalogVariantOrderSupplyTableCard from "../../modules/Catalog/CatalogVariantOrderSupplyTableCard.bs.js";
import * as CatalogVariantRetailPriceTableCard from "../../modules/Catalog/CatalogVariantRetailPriceTableCard.bs.js";
import * as CatalogVariantProductInformationCard from "../../modules/Catalog/CatalogVariantProductInformationCard.bs.js";
import * as CatalogVariantPurchasePriceTableCard from "../../modules/Catalog/CatalogVariantPurchasePriceTableCard.bs.js";
import * as CatalogVariantStockActivityTableCard from "../../modules/Catalog/CatalogVariantStockActivityTableCard.bs.js";
import * as CatalogVariantStockThresholdFormCard from "../../modules/Catalog/CatalogVariantStockThresholdFormCard.bs.js";

function mapToVariantPrices(shopsVariantRetailPrices, shopsVariantPurchasePrice) {
  return Belt_Array.map(Belt_Array.concatMany(shopsVariantRetailPrices), (function (retailPrice) {
                return {
                        id: retailPrice.id,
                        priceId: retailPrice.priceId,
                        variantId: retailPrice.variantId,
                        shopName: retailPrice.shopName,
                        name: retailPrice.name,
                        value: retailPrice.retailPrice,
                        secondaryValue: retailPrice.secondaryRetailPrice,
                        taxRate: retailPrice.taxRate,
                        purchasePrice: Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.getBy(shopsVariantPurchasePrice, (function (current) {
                                        return current.shopId === retailPrice.shopId;
                                      })), (function (param) {
                                    return param.purchasePrice;
                                  })), 0),
                        toQuantity: retailPrice.toQuantity,
                        fromQuantity: retailPrice.fromQuantity,
                        capacityUnit: retailPrice.capacityUnit,
                        edited: false
                      };
              }));
}

function CatalogVariantPage$AuthSelectShop(Props) {
  var variantShopIds = Props.variantShopIds;
  var shops = Auth.useShops(undefined);
  var disabledIds = Belt_Array.keepMap(shops, (function (param) {
          var id = param.id;
          var match = Belt_Array.getBy(variantShopIds, (function (current) {
                  return current === id;
                }));
          if (match !== undefined) {
            return ;
          } else {
            return id;
          }
        }));
  return React.createElement(Auth.SelectShopFilter.make, {
              disabledIds: disabledIds
            });
}

var AuthSelectShop = {
  make: CatalogVariantPage$AuthSelectShop
};

function CatalogVariantPage(Props) {
  var fromLabelEditSettingsRedirected = Props.fromLabelEditSettingsRedirected;
  var cku = Props.cku;
  var variantShopIds = Props.variantShopIds;
  var shopsProduct = Props.shopsProduct;
  var shopsVariant = Props.shopsVariant;
  var shopsVariantStock = Props.shopsVariantStock;
  var shopsVariantPurchasePrice = Props.shopsVariantPurchasePrice;
  var shopsVariantRetailPrices = Props.shopsVariantRetailPrices;
  var notifier = Notifier.use(undefined, undefined);
  var scope = Auth.useScope(undefined);
  var someVariant = shopsVariant.length !== 0;
  var onRequestNotification = function (value) {
    if (value.TAG === /* Ok */0) {
      return Curry._3(notifier.reset, {
                  TAG: /* Success */0,
                  _0: value._0
                }, undefined, undefined);
    } else {
      return Curry._3(notifier.reset, {
                  TAG: /* Error */1,
                  _0: value._0
                }, undefined, undefined);
    }
  };
  var renderActions = function (param) {
    var match = Belt_Array.get(shopsVariant, 0);
    if (scope.TAG === /* Organisation */0 || match === undefined) {
      return null;
    } else {
      return React.createElement(CatalogVariantPageActions.make, {
                  cku: cku,
                  variant: match,
                  fromLabelEditSettingsRedirected: fromLabelEditSettingsRedirected,
                  onRequestNotification: onRequestNotification
                });
    }
  };
  var renderHeaderActions;
  if (scope.TAG === /* Organisation */0) {
    var shops = scope.shops;
    renderHeaderActions = (function (param) {
        var variant = Belt_Array.get(shopsVariant, 0);
        return React.createElement(Inline.make, {
                    children: null,
                    align: "spaceBetween",
                    grow: true
                  }, React.createElement(Inline.make, {
                        children: null,
                        space: "normal"
                      }, shops.length < 6 ? React.createElement(CatalogVariantPage$AuthSelectShop, {
                              variantShopIds: variantShopIds
                            }) : null, someVariant ? null : React.createElement(TextStyle.make, {
                              children: $$Intl.t("No variant association to this shop has been recorded."),
                              variation: "negative"
                            })), variant !== undefined ? React.createElement(CatalogVariantPageActions.make, {
                          cku: cku,
                          variant: variant,
                          fromLabelEditSettingsRedirected: fromLabelEditSettingsRedirected,
                          onRequestNotification: onRequestNotification
                        }) : null);
      });
  } else {
    renderHeaderActions = undefined;
  }
  var pageTitle = Belt_Option.map(Belt_Array.get(shopsVariant, 0), (function (param) {
          return param.formattedName;
        }));
  var renderTitleEnd = function (param) {
    var match = Belt_Array.get(shopsVariant, 0);
    var variant;
    if (match === undefined) {
      return null;
    }
    if (scope.TAG === /* Organisation */0) {
      if (scope.activeShop === undefined) {
        return null;
      }
      variant = match;
    } else {
      variant = match;
    }
    return React.createElement(CatalogProductStatusBadge.make, {
                value: variant.status
              });
  };
  var tmp;
  if (someVariant) {
    var tmp$1;
    tmp$1 = scope.TAG === /* Organisation */0 && scope.activeShop === undefined ? React.createElement(CatalogVariantStatusTableCard.make, {
            shopsVariant: shopsVariant
          }) : null;
    var match = Belt_Array.get(shopsVariantStock, 0);
    var match$1 = Belt_Array.get(shopsVariant, 0);
    var tmp$2;
    if (match !== undefined) {
      var exit = 0;
      if (scope.TAG === /* Organisation */0 && scope.activeShop === undefined) {
        tmp$2 = null;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        tmp$2 = match$1 !== undefined && !match$1.bulk ? React.createElement(CatalogVariantStockThresholdFormCard.make, {
                variantId: match.variantId,
                maxStockThreshold: match.maxStockThreshold,
                minStockThreshold: match.minStockThreshold,
                stockOrderTriggerThreshold: match.stockOrderTriggerThreshold
              }) : null;
      }
      
    } else {
      tmp$2 = null;
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Notifier.Banner.make, {
              notifier: notifier
            }), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Stack.make, {
                          children: React.createElement(Columns.make, {
                                children: null,
                                space: "medium"
                              }, React.createElement(Column.make, {
                                    children: React.createElement(Stack.make, {
                                          children: null,
                                          space: "medium"
                                        }, React.createElement(CatalogVariantInformationCard.make, {
                                              shopsVariant: shopsVariant
                                            }), React.createElement(CatalogVariantStockTableCard.make, {
                                              cku: cku,
                                              shopsVariantStock: shopsVariantStock
                                            }), React.createElement(CatalogVariantProductInformationCard.make, {
                                              cku: cku,
                                              shopsProduct: shopsProduct
                                            }), tmp$1, React.createElement(CatalogVariantNotesCard.make, {
                                              shopsNotes: Belt_Array.map(shopsVariant, (function (variant) {
                                                      return {
                                                              variantId: variant.id,
                                                              shopId: variant.shopId,
                                                              internalNote: variant.internalNote,
                                                              tastingNote: variant.tastingNote
                                                            };
                                                    }))
                                            })),
                                    width: "one_third"
                                  }), React.createElement(Column.make, {
                                    children: React.createElement(Stack.make, {
                                          children: null,
                                          space: "medium"
                                        }, React.createElement(CatalogVariantRetailPriceTableCard.make, {
                                              cku: cku,
                                              variantPrices: mapToVariantPrices(shopsVariantRetailPrices, shopsVariantPurchasePrice)
                                            }), React.createElement(CatalogVariantPurchasePriceTableCard.make, {
                                              shopsVariantPrices: Belt_Array.map(Belt_Array.zip(shopsVariantPurchasePrice, shopsVariantRetailPrices), (function (param) {
                                                      return {
                                                              purchasePriceData: param[0],
                                                              retailPricesData: param[1]
                                                            };
                                                    }))
                                            }), React.createElement(CatalogVariantStockActivityTableCard.make, {
                                              cku: cku
                                            }), React.createElement(CatalogVariantOrderSupplyTableCard.make, {
                                              cku: cku
                                            }), tmp$2),
                                    width: "three_quarter"
                                  })),
                          space: "medium"
                        })), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
  } else {
    tmp = null;
  }
  var tmp$3 = {
    children: tmp,
    renderTitleEnd: renderTitleEnd,
    renderActions: renderActions
  };
  if (pageTitle !== undefined) {
    tmp$3.title = pageTitle;
  }
  if (renderHeaderActions !== undefined) {
    tmp$3.renderHeaderActions = Caml_option.valFromOption(renderHeaderActions);
  }
  return React.createElement(Page.make, tmp$3);
}

var make = React.memo(CatalogVariantPage);

var Config;

export {
  Config ,
  mapToVariantPrices ,
  AuthSelectShop ,
  make ,
}
/* make Not a pure module */
