// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../images-and-icons/Icon.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../actions/Button.bs.js";
import * as Offset from "../layout-and-structure/Offset.bs.js";
import * as Popover from "./Popover.bs.js";
import * as Touchable from "../../primitives/Touchable.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TooltipIcon from "./TooltipIcon.bs.js";
import * as AnimatedRender from "../../primitives/AnimatedRender.bs.js";
import * as Stylex from "@stylexjs/stylex";
import * as Utils from "@react-aria/utils";
import * as ReactDomEventTarget from "../../primitives/ReactDomEventTarget.bs.js";

var make = React.memo(function (Props) {
      var content = Props.content;
      var titleWrapperRef = Props.titleWrapperRef;
      return React.createElement("div", {
                  style: {
                    marginRight: "13px",
                    opacity: "0.75",
                    transform: "scale(0.9)"
                  }
                }, React.createElement(Offset.make, {
                      children: React.createElement(TooltipIcon.make, {
                            children: content,
                            altTriggerRef: titleWrapperRef,
                            offset: 3
                          }),
                      top: -0.5
                    }));
    });

var styles = Stylex.create({
      Section_root: {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "4px 20px 8px 20px"
      },
      Section_header: {
        display: "flex",
        gap: "4px"
      },
      Section_headerHoverable: {
        ":hover": {
          filter: "drop-shadow(0.5px 0 0 #00000080)"
        }
      },
      Section_headerDivider: {
        backgroundColor: "#d7d7e0",
        alignSelf: "center",
        flex: "1",
        height: "0.5px"
      },
      Section_titleWrapper: {
        display: "contents"
      },
      Section_title: {
        color: "#25243a",
        font: "normal 400 12px \"Libre Franklin\""
      },
      Section_content: {
        transition: "all .15s cubic-bezier(.4, 0, .2, 1)",
        display: "flex",
        flexDirection: "inherit",
        gap: "inherit",
        overflow: "hidden"
      }
    });

function headerStyleProps(hoverable) {
  return Stylex.props(styles.Section_header, hoverable ? styles.Section_headerHoverable : styles.none);
}

function styleContentProps(hidden) {
  return Stylex.props(styles.Section_content, {
              opacity: hidden ? 0 : 1,
              maxHeight: hidden ? "0" : "300px"
            }, {
              marginBottom: hidden ? "-24px" : "0"
            });
}

var make$1 = React.memo(function (Props) {
      var children = Props.children;
      var title = Props.title;
      var tooltip = Props.tooltip;
      var collapsableOpt = Props.collapsable;
      var defaultCollapsedOpt = Props.defaultCollapsed;
      var collapsable = collapsableOpt !== undefined ? collapsableOpt : false;
      var defaultCollapsed = defaultCollapsedOpt !== undefined ? defaultCollapsedOpt : false;
      var titleWrapperRef = React.useRef(null);
      var match = React.useState(function () {
            if (defaultCollapsed) {
              return !collapsable;
            } else {
              return true;
            }
          });
      var setOpened = match[1];
      var opened = match[0];
      var match$1 = Stylex.props(styles.Section_root);
      var match$2 = headerStyleProps(collapsable);
      var match$3 = Stylex.props(styles.Section_headerDivider);
      var match$4 = Stylex.props(styles.Section_title);
      var match$5 = Stylex.props(styles.Section_titleWrapper);
      var match$6 = styleContentProps(!opened);
      var tmp = {};
      if (match$1.className !== undefined) {
        tmp.className = Caml_option.valFromOption(match$1.className);
      }
      if (match$1.style !== undefined) {
        tmp.style = Caml_option.valFromOption(match$1.style);
      }
      var tmp$1 = {};
      if (match$2.className !== undefined) {
        tmp$1.className = Caml_option.valFromOption(match$2.className);
      }
      if (match$2.style !== undefined) {
        tmp$1.style = Caml_option.valFromOption(match$2.style);
      }
      var tmp$2 = {
        ref: titleWrapperRef
      };
      if (match$5.className !== undefined) {
        tmp$2.className = Caml_option.valFromOption(match$5.className);
      }
      if (match$5.style !== undefined) {
        tmp$2.style = Caml_option.valFromOption(match$5.style);
      }
      var tmp$3 = {};
      if (match$4.className !== undefined) {
        tmp$3.className = Caml_option.valFromOption(match$4.className);
      }
      if (match$4.style !== undefined) {
        tmp$3.style = Caml_option.valFromOption(match$4.style);
      }
      var tmp$4 = {};
      if (match$3.className !== undefined) {
        tmp$4.className = Caml_option.valFromOption(match$3.className);
      }
      var tmp$5 = {};
      if (match$6.className !== undefined) {
        tmp$5.className = Caml_option.valFromOption(match$6.className);
      }
      if (match$6.style !== undefined) {
        tmp$5.style = Caml_option.valFromOption(match$6.style);
      }
      return React.createElement("div", tmp, React.createElement(Touchable.make, Touchable.makeProps(React.createElement("div", tmp$1, collapsable ? React.createElement(Icon.make, {
                                    name: opened ? "arrow_down_light" : "arrow_up_light",
                                    fill: "#25243a",
                                    size: 13
                                  }) : null, React.createElement("div", tmp$2, React.createElement("span", tmp$3, title), tooltip !== undefined ? React.createElement(make, {
                                        content: Caml_option.valFromOption(tooltip),
                                        titleWrapperRef: titleWrapperRef
                                      }) : null, React.createElement("div", tmp$4))), undefined, !collapsable, undefined, undefined, undefined, (function (param) {
                            Curry._1(setOpened, (function (v) {
                                    return !v;
                                  }));
                          }), undefined, undefined, undefined)), React.createElement("div", tmp$5, children));
    });

var styles$1 = Stylex.create({
      root: {
        maxWidth: "550px"
      },
      header: {
        borderBottom: "1px solid #d7d7e0",
        display: "flex",
        gap: "6px",
        padding: "12px 20px",
        paddingBottom: "10px"
      },
      headerTitle: {
        color: "#25243a",
        font: "normal 700 16px \"Archivo\""
      },
      content: {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        maxHeight: "60vh",
        overflow: "auto",
        paddingBottom: "20px",
        paddingTop: "12px"
      },
      footer: {
        borderTop: "1px solid #d7d7e0",
        display: "flex",
        gap: "6px",
        justifyContent: "flex-end",
        padding: "10px 16px"
      }
    });

function styleProps(width) {
  return Stylex.props(styles$1.root, width !== undefined ? ({
                  width: String(width) + "px"
                }) : ({}));
}

var bottomGradientStyle = {
  backgroundImage: "radial-gradient(ellipse at center, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 75%)",
  backgroundPosition: "center top",
  bottom: "0",
  height: "13px",
  left: "0",
  position: "absolute",
  right: "0",
  zIndex: "1",
  backgroundSize: "100% 200%",
  pointerEvents: "none"
};

function Dialog(Props) {
  var ariaProps = Props.ariaProps;
  var children = Props.children;
  var width = Props.width;
  var title = Props.title;
  var titleStartElementOpt = Props.titleStartElement;
  var commitDisabledOpt = Props.commitDisabled;
  var commitLoadingOpt = Props.commitLoading;
  var commitButtonTextOpt = Props.commitButtonText;
  var onCommit = Props.onCommit;
  var onRequestClose = Props.onRequestClose;
  var titleStartElement = titleStartElementOpt !== undefined ? Caml_option.valFromOption(titleStartElementOpt) : null;
  var commitDisabled = commitDisabledOpt !== undefined ? commitDisabledOpt : false;
  var commitLoading = commitLoadingOpt !== undefined ? commitLoadingOpt : false;
  var commitButtonText = commitButtonTextOpt !== undefined ? commitButtonTextOpt : $$Intl.t("Save");
  var contentRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setContentScrollable = match[1];
  var handleScrollable = React.useCallback((function (param) {
          var domElement = contentRef.current;
          if (!(domElement == null)) {
            return Curry._1(setContentScrollable, (function (param) {
                          return domElement.scrollHeight > domElement.clientHeight;
                        }));
          }
          
        }), [contentRef]);
  React.useLayoutEffect((function () {
          Curry._1(handleScrollable, undefined);
        }), [contentRef]);
  Utils.useResizeObserver({
        ref: contentRef,
        onResize: handleScrollable
      });
  var onScroll = React.useCallback((function ($$event) {
          var domElement = ReactDomEventTarget.toUnsafeDomEventTarget($$event.currentTarget);
          var hasReachedBottomScroll = ((domElement.scrollTop | 0) + domElement.clientHeight | 0) >= (domElement.scrollHeight - 10 | 0);
          Curry._1(setContentScrollable, (function (param) {
                  return !hasReachedBottomScroll;
                }));
        }), []);
  var onKeyDown = React.useCallback((function ($$event) {
          var match = $$event.key;
          if (match === "Enter" && !commitDisabled) {
            Curry._1(onCommit, undefined);
            $$event.preventDefault();
            return ;
          }
          
        }), [
        onCommit,
        commitDisabled
      ]);
  React.useEffect((function () {
          var $$document$1 = document;
          $$document$1.addEventListener("keydown", onKeyDown);
          return (function (param) {
                    $$document$1.removeEventListener("keydown", onKeyDown);
                  });
        }), [onKeyDown]);
  var match$1 = styleProps(width);
  var match$2 = Stylex.props(styles$1.header);
  var match$3 = Stylex.props(styles$1.headerTitle);
  var match$4 = Stylex.props(styles$1.content);
  var match$5 = Stylex.props(styles$1.footer);
  var tmp = {
    children: null,
    ariaLabel: title,
    ariaProps: ariaProps
  };
  if (match$1.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match$1.style);
  }
  if (match$1.className !== undefined) {
    tmp.className = match$1.className;
  }
  var tmp$1 = {};
  if (match$2.className !== undefined) {
    tmp$1.className = Caml_option.valFromOption(match$2.className);
  }
  if (match$2.style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(match$2.style);
  }
  var tmp$2 = {};
  if (match$3.className !== undefined) {
    tmp$2.className = Caml_option.valFromOption(match$3.className);
  }
  if (match$3.style !== undefined) {
    tmp$2.style = Caml_option.valFromOption(match$3.style);
  }
  var tmp$3 = {
    ref: contentRef,
    onScroll: onScroll
  };
  if (match$4.className !== undefined) {
    tmp$3.className = Caml_option.valFromOption(match$4.className);
  }
  if (match$4.style !== undefined) {
    tmp$3.style = Caml_option.valFromOption(match$4.style);
  }
  var tmp$4 = {};
  if (match$5.className !== undefined) {
    tmp$4.className = Caml_option.valFromOption(match$5.className);
  }
  if (match$5.style !== undefined) {
    tmp$4.style = Caml_option.valFromOption(match$5.style);
  }
  return React.createElement(Popover.Dialog.make, tmp, React.createElement("div", tmp$1, React.createElement("span", tmp$2, title), titleStartElement), React.createElement("div", {
                  style: {
                    position: "relative"
                  }
                }, React.createElement("div", tmp$3, children), React.createElement(AnimatedRender.make, {
                      children: React.createElement("div", {
                            style: bottomGradientStyle
                          }),
                      displayed: match[0],
                      animation: "fade",
                      duration: 450
                    })), React.createElement("div", tmp$4, React.createElement(Button.make, Button.makeProps($$Intl.t("Discard"), "tiny", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                            Curry._1(onRequestClose, undefined);
                          }), undefined, undefined, undefined)), React.createElement(Button.make, Button.makeProps(commitButtonText, "tiny", "success", undefined, commitLoading, commitDisabled, undefined, undefined, undefined, (function (param) {
                            Curry._1(onCommit, undefined);
                          }), undefined, undefined, undefined))));
}

var make$2 = React.memo(Dialog);

var Section = {
  make: make$1
};

export {
  Section ,
  make$2 as make,
}
/* make Not a pure module */
