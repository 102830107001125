// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Pastille from "../../resources/feedback-indicators/Pastille.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function truncateWithMiddleEllipsis(str, maxLen) {
  if (str.length <= maxLen) {
    return str;
  }
  var len = str.length;
  var halfLen = (maxLen - 3 | 0) / 2 | 0;
  var startStr = str.slice(0, halfLen).trim();
  var endStr = str.slice(len - halfLen | 0, len).trim();
  return startStr + "..." + endStr;
}

function ProductStockTableCell(Props) {
  var value = Props.value;
  var state = Props.state;
  var sizeOpt = Props.size;
  var formattedShopsNames = Props.formattedShopsNames;
  var size = sizeOpt !== undefined ? sizeOpt : "normal";
  if (value === undefined) {
    return React.createElement(TextStyle.make, {
                children: "—",
                size: size
              });
  }
  var tmp;
  if (formattedShopsNames !== undefined) {
    var formattedShopsName = truncateWithMiddleEllipsis(formattedShopsNames, 20);
    tmp = React.createElement(TextStyle.make, {
          children: formattedShopsName,
          variation: "normal",
          size: "tiny",
          maxLines: 1
        });
  } else {
    tmp = null;
  }
  return React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Stack.make, {
                          children: null,
                          space: "none"
                        }, React.createElement(Inline.make, {
                              children: null,
                              space: "none"
                            }, state !== undefined ? React.createElement(Pastille.make, {
                                    variant: state === "OK" ? "success" : (
                                        state === "ALERT" ? "warning" : "danger"
                                      )
                                  }) : null, React.createElement(Box.make, Box.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined)), React.createElement(Box.make, Box.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined)), React.createElement(TextStyle.make, {
                                  children: value,
                                  size: size
                                })), tmp)), undefined, undefined, undefined, undefined));
}

var make = React.memo(ProductStockTableCell);

export {
  truncateWithMiddleEllipsis ,
  make ,
}
/* make Not a pure module */
