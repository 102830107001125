// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../layout-and-structure/Box.bs.js";
import * as React from "react";
import * as TextIcon from "../typography/TextIcon.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function ShowAllDataLink(Props) {
  var to = Props.to;
  var text = Props.text;
  return React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Navigation.Link.make, {
                          children: React.createElement(TextIcon.make, {
                                children: text,
                                size: "large",
                                noMargin: true,
                                icon: "queue_arrow_right_light"
                              }),
                          to: to
                        })), "large", undefined, "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = ShowAllDataLink;

export {
  make ,
}
/* Box Not a pure module */
