// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Scalar from "../../core/Scalar.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query variantsByCku($cku: CKU!, $filterBy: InputVariantsByCkuQueryFilter)  {\nvariantsByCku(cku: $cku, first: 50, filterBy: $filterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nactive  \nformattedStatus  \nname  \nupdatedAt  \nformattedName  \ncapacityValue  \ncapacityUnit  \ncapacityPrecision  \nbulk  \nyear  \nalcoholVolume  \nean13  \nstockKeepingUnit  \npriceLookUpCode  \ninternalCode  \ntastingNote  \ninternalNote  \nmaxStockThreshold  \nminStockThreshold  \nstockOrderTriggerThreshold  \nshop  {\n__typename  \nid  \nname  \n}\n\nstock  {\n__typename  \nformattedShopsNames  \nrawQuantity  \nformattedQuantity  \nstate  \n}\n\nsupplier  {\n__typename  \nid  \ncompanyName  \n}\n\nformattedCategory  \nproduct  {\n__typename  \nid  \nname  \nformattedDescription  \nformattedOrigin  \ntax  {\n__typename  \nvalue  \n}\n\n}\n\npurchasedPrice  \nformattedPurchasedPrice  \nvariantPrices(first: 50)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \narchivedAt  \nvalueIncludingTax  \nvalueExcludingTax  \ntoQuantity  \nfromQuantity  \nprice  {\n__typename  \nid  \nname  \nenableByDefault  \ntaxIncluded  \narchivedAt  \n}\n\n}\n\n}\n\npageInfo  {\n__typename  \nhasNextPage  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variantsByCku;
  var value$2 = value$1.edges;
  return {
          variantsByCku: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.active;
                    var value$3 = value$1.formattedStatus;
                    var tmp;
                    switch (value$3) {
                      case "ACTIVE" :
                          tmp = "ACTIVE";
                          break;
                      case "ARCHIVED" :
                          tmp = "ARCHIVED";
                          break;
                      case "INACTIVE" :
                          tmp = "INACTIVE";
                          break;
                      case "VARIABLE" :
                          tmp = "VARIABLE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$4 = value$1.capacityValue;
                    var value$5 = value$1.capacityUnit;
                    var value$6 = value$1.capacityPrecision;
                    var value$7 = value$1.bulk;
                    var value$8 = value$1.year;
                    var value$9 = value$1.alcoholVolume;
                    var value$10 = value$1.ean13;
                    var value$11 = value$1.stockKeepingUnit;
                    var value$12 = value$1.priceLookUpCode;
                    var value$13 = value$1.internalCode;
                    var value$14 = value$1.tastingNote;
                    var value$15 = value$1.internalNote;
                    var value$16 = value$1.maxStockThreshold;
                    var value$17 = value$1.minStockThreshold;
                    var value$18 = value$1.stockOrderTriggerThreshold;
                    var value$19 = value$1.shop;
                    var value$20 = value$1.stock;
                    var value$21 = value$20.formattedShopsNames;
                    var value$22 = value$20.rawQuantity;
                    var value$23 = value$20.formattedQuantity;
                    var value$24 = value$20.state;
                    var tmp$1;
                    if (value$24 == null) {
                      tmp$1 = undefined;
                    } else {
                      var tmp$2;
                      switch (value$24) {
                        case "ALERT" :
                            tmp$2 = "ALERT";
                            break;
                        case "DANGER" :
                            tmp$2 = "DANGER";
                            break;
                        case "OK" :
                            tmp$2 = "OK";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$1 = tmp$2;
                    }
                    var value$25 = value$1.supplier;
                    var value$26 = value$1.formattedCategory;
                    var value$27 = value$1.product;
                    var value$28 = value$27.formattedDescription;
                    var value$29 = value$27.tax;
                    var value$30 = value$1.purchasedPrice;
                    var value$31 = value$1.formattedPurchasedPrice;
                    var value$32 = value$1.variantPrices;
                    var value$33 = value$32.edges;
                    var value$34 = value$32.pageInfo;
                    var value$35 = value$34.hasNextPage;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              active: !(value$2 == null) ? value$2 : undefined,
                              formattedStatus: tmp,
                              name: value$1.name,
                              updatedAt: Scalar.Datetime.parse(value$1.updatedAt),
                              formattedName: value$1.formattedName,
                              capacityValue: !(value$4 == null) ? value$4 : undefined,
                              capacityUnit: !(value$5 == null) ? value$5 : undefined,
                              capacityPrecision: !(value$6 == null) ? value$6 : undefined,
                              bulk: !(value$7 == null) ? value$7 : undefined,
                              year: !(value$8 == null) ? value$8 : undefined,
                              alcoholVolume: !(value$9 == null) ? value$9 : undefined,
                              ean13: !(value$10 == null) ? value$10 : undefined,
                              stockKeepingUnit: !(value$11 == null) ? value$11 : undefined,
                              priceLookUpCode: !(value$12 == null) ? value$12 : undefined,
                              internalCode: !(value$13 == null) ? value$13 : undefined,
                              tastingNote: !(value$14 == null) ? Scalar.$$Text.parse(value$14) : undefined,
                              internalNote: !(value$15 == null) ? Scalar.$$Text.parse(value$15) : undefined,
                              maxStockThreshold: !(value$16 == null) ? value$16 : undefined,
                              minStockThreshold: !(value$17 == null) ? value$17 : undefined,
                              stockOrderTriggerThreshold: !(value$18 == null) ? value$18 : undefined,
                              shop: {
                                __typename: value$19.__typename,
                                id: value$19.id,
                                name: value$19.name
                              },
                              stock: {
                                __typename: value$20.__typename,
                                formattedShopsNames: !(value$21 == null) ? value$21 : undefined,
                                rawQuantity: !(value$22 == null) ? value$22 : undefined,
                                formattedQuantity: !(value$23 == null) ? value$23 : undefined,
                                state: tmp$1
                              },
                              supplier: !(value$25 == null) ? ({
                                    __typename: value$25.__typename,
                                    id: value$25.id,
                                    companyName: value$25.companyName
                                  }) : undefined,
                              formattedCategory: !(value$26 == null) ? value$26 : undefined,
                              product: {
                                __typename: value$27.__typename,
                                id: value$27.id,
                                name: value$27.name,
                                formattedDescription: !(value$28 == null) ? value$28 : undefined,
                                formattedOrigin: value$27.formattedOrigin,
                                tax: {
                                  __typename: value$29.__typename,
                                  value: value$29.value
                                }
                              },
                              purchasedPrice: !(value$30 == null) ? value$30 : undefined,
                              formattedPurchasedPrice: !(value$31 == null) ? value$31 : undefined,
                              variantPrices: {
                                __typename: value$32.__typename,
                                edges: Js_array.map((function (value) {
                                        var value$1 = value.node;
                                        var value$2 = value$1.archivedAt;
                                        var value$3 = value$1.toQuantity;
                                        var value$4 = value$1.fromQuantity;
                                        var value$5 = value$1.price;
                                        var tmp;
                                        if (value$5 == null) {
                                          tmp = undefined;
                                        } else {
                                          var value$6 = value$5.archivedAt;
                                          tmp = {
                                            __typename: value$5.__typename,
                                            id: value$5.id,
                                            name: value$5.name,
                                            enableByDefault: value$5.enableByDefault,
                                            taxIncluded: value$5.taxIncluded,
                                            archivedAt: !(value$6 == null) ? Caml_option.some(Scalar.Datetime.parse(value$6)) : undefined
                                          };
                                        }
                                        return {
                                                __typename: value.__typename,
                                                node: {
                                                  __typename: value$1.__typename,
                                                  id: value$1.id,
                                                  archivedAt: !(value$2 == null) ? Caml_option.some(Scalar.Datetime.parse(value$2)) : undefined,
                                                  valueIncludingTax: value$1.valueIncludingTax,
                                                  valueExcludingTax: value$1.valueExcludingTax,
                                                  toQuantity: !(value$3 == null) ? value$3 : undefined,
                                                  fromQuantity: !(value$4 == null) ? value$4 : undefined,
                                                  price: tmp
                                                }
                                              };
                                      }), value$33),
                                pageInfo: {
                                  __typename: value$34.__typename,
                                  hasNextPage: !(value$35 == null) ? value$35 : undefined
                                }
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.variantsByCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.variantPrices;
          var value$3 = value$2.pageInfo;
          var value$4 = value$3.hasNextPage;
          var hasNextPage = value$4 !== undefined ? value$4 : null;
          var value$5 = value$3.__typename;
          var pageInfo = {
            __typename: value$5,
            hasNextPage: hasNextPage
          };
          var value$6 = value$2.edges;
          var edges = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.price;
                  var price;
                  if (value$2 !== undefined) {
                    var value$3 = value$2.archivedAt;
                    var archivedAt = value$3 !== undefined ? Scalar.Datetime.serialize(Caml_option.valFromOption(value$3)) : null;
                    var value$4 = value$2.taxIncluded;
                    var value$5 = value$2.enableByDefault;
                    var value$6 = value$2.name;
                    var value$7 = value$2.id;
                    var value$8 = value$2.__typename;
                    price = {
                      __typename: value$8,
                      id: value$7,
                      name: value$6,
                      enableByDefault: value$5,
                      taxIncluded: value$4,
                      archivedAt: archivedAt
                    };
                  } else {
                    price = null;
                  }
                  var value$9 = value$1.fromQuantity;
                  var fromQuantity = value$9 !== undefined ? value$9 : null;
                  var value$10 = value$1.toQuantity;
                  var toQuantity = value$10 !== undefined ? value$10 : null;
                  var value$11 = value$1.valueExcludingTax;
                  var value$12 = value$1.valueIncludingTax;
                  var value$13 = value$1.archivedAt;
                  var archivedAt$1 = value$13 !== undefined ? Scalar.Datetime.serialize(Caml_option.valFromOption(value$13)) : null;
                  var value$14 = value$1.id;
                  var value$15 = value$1.__typename;
                  var node = {
                    __typename: value$15,
                    id: value$14,
                    archivedAt: archivedAt$1,
                    valueIncludingTax: value$12,
                    valueExcludingTax: value$11,
                    toQuantity: toQuantity,
                    fromQuantity: fromQuantity,
                    price: price
                  };
                  var value$16 = value.__typename;
                  return {
                          __typename: value$16,
                          node: node
                        };
                }), value$6);
          var value$7 = value$2.__typename;
          var variantPrices = {
            __typename: value$7,
            edges: edges,
            pageInfo: pageInfo
          };
          var value$8 = value$1.formattedPurchasedPrice;
          var formattedPurchasedPrice = value$8 !== undefined ? value$8 : null;
          var value$9 = value$1.purchasedPrice;
          var purchasedPrice = value$9 !== undefined ? value$9 : null;
          var value$10 = value$1.product;
          var value$11 = value$10.tax;
          var value$12 = value$11.value;
          var value$13 = value$11.__typename;
          var tax = {
            __typename: value$13,
            value: value$12
          };
          var value$14 = value$10.formattedOrigin;
          var value$15 = value$10.formattedDescription;
          var formattedDescription = value$15 !== undefined ? value$15 : null;
          var value$16 = value$10.name;
          var value$17 = value$10.id;
          var value$18 = value$10.__typename;
          var product = {
            __typename: value$18,
            id: value$17,
            name: value$16,
            formattedDescription: formattedDescription,
            formattedOrigin: value$14,
            tax: tax
          };
          var value$19 = value$1.formattedCategory;
          var formattedCategory = value$19 !== undefined ? value$19 : null;
          var value$20 = value$1.supplier;
          var supplier;
          if (value$20 !== undefined) {
            var value$21 = value$20.companyName;
            var value$22 = value$20.id;
            var value$23 = value$20.__typename;
            supplier = {
              __typename: value$23,
              id: value$22,
              companyName: value$21
            };
          } else {
            supplier = null;
          }
          var value$24 = value$1.stock;
          var value$25 = value$24.state;
          var state = value$25 !== undefined ? (
              value$25 === "OK" ? "OK" : (
                  value$25 === "ALERT" ? "ALERT" : "DANGER"
                )
            ) : null;
          var value$26 = value$24.formattedQuantity;
          var formattedQuantity = value$26 !== undefined ? value$26 : null;
          var value$27 = value$24.rawQuantity;
          var rawQuantity = value$27 !== undefined ? value$27 : null;
          var value$28 = value$24.formattedShopsNames;
          var formattedShopsNames = value$28 !== undefined ? value$28 : null;
          var value$29 = value$24.__typename;
          var stock = {
            __typename: value$29,
            formattedShopsNames: formattedShopsNames,
            rawQuantity: rawQuantity,
            formattedQuantity: formattedQuantity,
            state: state
          };
          var value$30 = value$1.shop;
          var value$31 = value$30.name;
          var value$32 = value$30.id;
          var value$33 = value$30.__typename;
          var shop = {
            __typename: value$33,
            id: value$32,
            name: value$31
          };
          var value$34 = value$1.stockOrderTriggerThreshold;
          var stockOrderTriggerThreshold = value$34 !== undefined ? value$34 : null;
          var value$35 = value$1.minStockThreshold;
          var minStockThreshold = value$35 !== undefined ? value$35 : null;
          var value$36 = value$1.maxStockThreshold;
          var maxStockThreshold = value$36 !== undefined ? value$36 : null;
          var value$37 = value$1.internalNote;
          var internalNote = value$37 !== undefined ? Scalar.$$Text.serialize(value$37) : null;
          var value$38 = value$1.tastingNote;
          var tastingNote = value$38 !== undefined ? Scalar.$$Text.serialize(value$38) : null;
          var value$39 = value$1.internalCode;
          var internalCode = value$39 !== undefined ? value$39 : null;
          var value$40 = value$1.priceLookUpCode;
          var priceLookUpCode = value$40 !== undefined ? value$40 : null;
          var value$41 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$41 !== undefined ? value$41 : null;
          var value$42 = value$1.ean13;
          var ean13 = value$42 !== undefined ? value$42 : null;
          var value$43 = value$1.alcoholVolume;
          var alcoholVolume = value$43 !== undefined ? value$43 : null;
          var value$44 = value$1.year;
          var year = value$44 !== undefined ? value$44 : null;
          var value$45 = value$1.bulk;
          var bulk = value$45 !== undefined ? value$45 : null;
          var value$46 = value$1.capacityPrecision;
          var capacityPrecision = value$46 !== undefined ? value$46 : null;
          var value$47 = value$1.capacityUnit;
          var capacityUnit = value$47 !== undefined ? value$47 : null;
          var value$48 = value$1.capacityValue;
          var capacityValue = value$48 !== undefined ? value$48 : null;
          var value$49 = value$1.formattedName;
          var value$50 = value$1.updatedAt;
          var value$51 = Scalar.Datetime.serialize(value$50);
          var value$52 = value$1.name;
          var value$53 = value$1.formattedStatus;
          var formattedStatus = value$53 === "INACTIVE" ? "INACTIVE" : (
              value$53 === "ARCHIVED" ? "ARCHIVED" : (
                  value$53 === "ACTIVE" ? "ACTIVE" : "VARIABLE"
                )
            );
          var value$54 = value$1.active;
          var active = value$54 !== undefined ? value$54 : null;
          var value$55 = value$1.id;
          var value$56 = value$1.__typename;
          var node = {
            __typename: value$56,
            id: value$55,
            active: active,
            formattedStatus: formattedStatus,
            name: value$52,
            updatedAt: value$51,
            formattedName: value$49,
            capacityValue: capacityValue,
            capacityUnit: capacityUnit,
            capacityPrecision: capacityPrecision,
            bulk: bulk,
            year: year,
            alcoholVolume: alcoholVolume,
            ean13: ean13,
            stockKeepingUnit: stockKeepingUnit,
            priceLookUpCode: priceLookUpCode,
            internalCode: internalCode,
            tastingNote: tastingNote,
            internalNote: internalNote,
            maxStockThreshold: maxStockThreshold,
            minStockThreshold: minStockThreshold,
            stockOrderTriggerThreshold: stockOrderTriggerThreshold,
            shop: shop,
            stock: stock,
            supplier: supplier,
            formattedCategory: formattedCategory,
            product: product,
            purchasedPrice: purchasedPrice,
            formattedPurchasedPrice: formattedPurchasedPrice,
            variantPrices: variantPrices
          };
          var value$57 = value.__typename;
          return {
                  __typename: value$57,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var variantsByCku = {
    __typename: value$3,
    edges: edges
  };
  return {
          variantsByCku: variantsByCku
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectBooleanEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectInputVariantsByCkuQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.active;
  var a$2 = inp.archived;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          active: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          archived: a$2 !== undefined ? (
              a$2 === "ONLY" ? "ONLY" : (
                  a$2 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  return {
          cku: inp.cku,
          filterBy: a !== undefined ? serializeInputObjectInputVariantsByCkuQueryFilter(a) : undefined
        };
}

function makeVariables(cku, filterBy, param) {
  return {
          cku: cku,
          filterBy: filterBy
        };
}

function makeInputObjectInputVariantsByCkuQueryFilter(shopIds, active, archived, param) {
  return {
          shopIds: shopIds,
          active: active,
          archived: archived
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectBooleanEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

var VariantQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsByCkuQueryFilter: serializeInputObjectInputVariantsByCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsByCkuQueryFilter: makeInputObjectInputVariantsByCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var VariantQuery_refetchQueryDescription = include.refetchQueryDescription;

var VariantQuery_use = include.use;

var VariantQuery_useLazy = include.useLazy;

var VariantQuery_useLazyWithVariables = include.useLazyWithVariables;

var VariantQuery = {
  VariantQuery_inner: VariantQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsByCkuQueryFilter: serializeInputObjectInputVariantsByCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsByCkuQueryFilter: makeInputObjectInputVariantsByCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  refetchQueryDescription: VariantQuery_refetchQueryDescription,
  use: VariantQuery_use,
  useLazy: VariantQuery_useLazy,
  useLazyWithVariables: VariantQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query variantsByCku_shops($cku: CKU!)  {\nvariantsByCku(cku: $cku, first: 50, filterBy: {archived: INCLUDED})  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nshop  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.variantsByCku;
  var value$2 = value$1.edges;
  return {
          variantsByCku: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.shop;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              shop: {
                                __typename: value$2.__typename,
                                id: value$2.id
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.variantsByCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.shop;
          var value$3 = value$2.id;
          var value$4 = value$2.__typename;
          var shop = {
            __typename: value$4,
            id: value$3
          };
          var value$5 = value$1.id;
          var value$6 = value$1.__typename;
          var node = {
            __typename: value$6,
            id: value$5,
            shop: shop
          };
          var value$7 = value.__typename;
          return {
                  __typename: value$7,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var variantsByCku = {
    __typename: value$3,
    edges: edges
  };
  return {
          variantsByCku: variantsByCku
        };
}

function serializeVariables$1(inp) {
  return {
          cku: inp.cku
        };
}

function makeVariables$1(cku, param) {
  return {
          cku: cku
        };
}

var VariantShopsQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var VariantShopsQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var VariantShopsQuery_use = include$1.use;

var VariantShopsQuery_useLazy = include$1.useLazy;

var VariantShopsQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var VariantShopsQuery = {
  VariantShopsQuery_inner: VariantShopsQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  refetchQueryDescription: VariantShopsQuery_refetchQueryDescription,
  use: VariantShopsQuery_use,
  useLazy: VariantShopsQuery_useLazy,
  useLazyWithVariables: VariantShopsQuery_useLazyWithVariables
};

var Raw$2 = {};

var query$2 = Client.gql(["query variant_cku($id: ID!)  {\nvariant(id: $id)  {\n__typename  \ncku  \n}\n\n}\n"]);

function parse$2(value) {
  var value$1 = value.variant;
  return {
          variant: !(value$1 == null) ? ({
                __typename: value$1.__typename,
                cku: Scalar.CKU.parse(value$1.cku)
              }) : undefined
        };
}

function serialize$2(value) {
  var value$1 = value.variant;
  var variant;
  if (value$1 !== undefined) {
    var value$2 = value$1.cku;
    var value$3 = Scalar.CKU.serialize(value$2);
    var value$4 = value$1.__typename;
    variant = {
      __typename: value$4,
      cku: value$3
    };
  } else {
    variant = null;
  }
  return {
          variant: variant
        };
}

function serializeVariables$2(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$2(id, param) {
  return {
          id: id
        };
}

var VariantCkuQuery_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var include$2 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2
    });

var VariantCkuQuery_refetchQueryDescription = include$2.refetchQueryDescription;

var VariantCkuQuery_use = include$2.use;

var VariantCkuQuery_useLazy = include$2.useLazy;

var VariantCkuQuery_useLazyWithVariables = include$2.useLazyWithVariables;

var VariantCkuQuery = {
  VariantCkuQuery_inner: VariantCkuQuery_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  refetchQueryDescription: VariantCkuQuery_refetchQueryDescription,
  use: VariantCkuQuery_use,
  useLazy: VariantCkuQuery_useLazy,
  useLazyWithVariables: VariantCkuQuery_useLazyWithVariables
};

var Raw$3 = {};

var query$3 = Client.gql(["query prices  {\nprices(first: 50)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nname  \ntaxIncluded  \nshop  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse$3(value) {
  var value$1 = value.prices;
  var value$2 = value$1.edges;
  return {
          prices: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.shop;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              name: value$1.name,
                              taxIncluded: value$1.taxIncluded,
                              shop: {
                                __typename: value$2.__typename,
                                id: value$2.id
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize$3(value) {
  var value$1 = value.prices;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.shop;
          var value$3 = value$2.id;
          var value$4 = value$2.__typename;
          var shop = {
            __typename: value$4,
            id: value$3
          };
          var value$5 = value$1.taxIncluded;
          var value$6 = value$1.name;
          var value$7 = value$1.id;
          var value$8 = value$1.__typename;
          var node = {
            __typename: value$8,
            id: value$7,
            name: value$6,
            taxIncluded: value$5,
            shop: shop
          };
          var value$9 = value.__typename;
          return {
                  __typename: value$9,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var prices = {
    __typename: value$3,
    edges: edges
  };
  return {
          prices: prices
        };
}

function serializeVariables$3(param) {
  
}

function makeVariables$3(param) {
  
}

function makeDefaultVariables(param) {
  
}

var PricesQuery_inner = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3,
  makeDefaultVariables: makeDefaultVariables
};

var include$3 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$3,
      Raw: Raw$3,
      parse: parse$3,
      serialize: serialize$3,
      serializeVariables: serializeVariables$3
    });

var PricesQuery_refetchQueryDescription = include$3.refetchQueryDescription;

var PricesQuery_use = include$3.use;

var PricesQuery_useLazy = include$3.useLazy;

var PricesQuery_useLazyWithVariables = include$3.useLazyWithVariables;

var PricesQuery = {
  PricesQuery_inner: PricesQuery_inner,
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: PricesQuery_refetchQueryDescription,
  use: PricesQuery_use,
  useLazy: PricesQuery_useLazy,
  useLazyWithVariables: PricesQuery_useLazyWithVariables
};

export {
  VariantQuery ,
  VariantShopsQuery ,
  VariantCkuQuery ,
  PricesQuery ,
}
/* query Not a pure module */
