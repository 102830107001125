// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Select from "./Select.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as OverlayTriggerView from "../overlays/OverlayTriggerView.bs.js";

function InputSelectField(Props) {
  var label = Props.label;
  var errorMessage = Props.errorMessage;
  var placeholder = Props.placeholder;
  var required = Props.required;
  var disabled = Props.disabled;
  var tooltip = Props.tooltip;
  var onChange = Props.onChange;
  var searchable = Props.searchable;
  var renderItemContent = Props.renderItemContent;
  var renderTriggerView = Props.renderTriggerView;
  var value = Props.value;
  var sections = Props.sections;
  var renderTriggerView$1 = Belt_Option.getWithDefault(renderTriggerView, (function (children, param, hovered, active, focused) {
          var tmp = {
            children: children,
            preset: {
              NAME: "inputField",
              VAL: {
                required: Belt_Option.getWithDefault(required, false),
                tooltip: tooltip,
                errorMessage: errorMessage
              }
            },
            label: label,
            hovered: hovered,
            active: active,
            focused: focused
          };
          if (disabled !== undefined) {
            tmp.disabled = disabled;
          }
          return React.createElement(OverlayTriggerView.make, tmp);
        }));
  var tmp = {
    label: label,
    sections: sections,
    preset: "filter",
    renderTriggerView: renderTriggerView$1,
    value: value,
    onChange: onChange
  };
  if (placeholder !== undefined) {
    tmp.placeholder = placeholder;
  }
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  if (searchable !== undefined) {
    tmp.searchable = searchable;
  }
  if (renderItemContent !== undefined) {
    tmp.renderItemContent = Caml_option.valFromOption(renderItemContent);
  }
  return React.createElement(Select.make, tmp);
}

var make = InputSelectField;

export {
  make ,
}
/* react Not a pure module */
