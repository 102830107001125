// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Icon from "../../resources/images-and-icons/Icon.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as $$Text from "../../primitives/Text.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as $$Image from "../../primitives/Image.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as Title from "../../resources/typography/Title.bs.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Spaces from "../../resources/theme/Spaces.bs.js";
import * as FontFaces from "../../resources/theme/FontFaces.bs.js";
import * as FontSizes from "../../resources/theme/FontSizes.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as HelpCenter from "../../core/HelpCenter.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import Wino_logoPng from "./wino_logo.png";

var styles = $$StyleSheet.create({
      base: Style.merge([
            FontFaces.libreFranklinSemiBoldStyle,
            Style.style(undefined, undefined, undefined, "#25243a", undefined, FontSizes.normal, undefined, undefined, undefined, undefined, 0.125, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      hovered: Style.style(undefined, undefined, undefined, "#a01e72", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "underline", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function styleFromParams(hovered) {
  return Style.arrayStyle([
              styles.base,
              hovered ? styles.hovered : Style.emptyStyle
            ]);
}

function iconColorFromHovered(hovered) {
  if (hovered) {
    return "#8a0262";
  } else {
    return "#07061e";
  }
}

function AuthSignupSuccessPage$GettingStartedTextIconLink(Props) {
  var match = Hover.use(undefined, undefined);
  var hovered = match[1];
  return React.createElement(Navigation.Link.make, {
              children: React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Inline.make, {
                                children: null,
                                space: "small"
                              }, React.createElement(Icon.make, {
                                    name: "book_bold",
                                    fill: hovered ? "#8a0262" : "#07061e"
                                  }), React.createElement($$Text.make, $$Text.makeProps($$Intl.t("Getting started guide"), Caml_option.some(styleFromParams(hovered)), undefined, undefined, undefined)))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, match[0], undefined)),
              to: {
                TAG: /* Url */2,
                _0: new URL(HelpCenter.gettingStartedGuideLink)
              },
              openNewTab: true
            });
}

var GettingStartedTextIconLink = {
  styles: styles,
  styleFromParams: styleFromParams,
  iconColorFromHovered: iconColorFromHovered,
  make: AuthSignupSuccessPage$GettingStartedTextIconLink
};

var imageUri = Wino_logoPng;

var styles$1 = $$StyleSheet.create({
      image: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(40)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(40)), undefined, undefined)
    });

function AuthSignupSuccessPage$Header(Props) {
  return React.createElement(Inline.make, {
              children: null,
              align: "spaceBetween"
            }, React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Inline.make, {
                              children: null,
                              space: "medium"
                            }, React.createElement($$Image.make, {
                                  style: styles$1.image,
                                  source: $$Image.fromUriSource($$Image.uriSource(imageUri))
                                }), React.createElement(TextStyle.make, {
                                  children: $$Intl.t("How to contact us? By email at hello@wino.fr"),
                                  size: "normal"
                                }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(AuthSignupSuccessPage$GettingStartedTextIconLink, {})), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var Header = {
  imageUri: imageUri,
  styles: styles$1,
  make: AuthSignupSuccessPage$Header
};

var styles$2 = $$StyleSheet.create({
      container: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(100)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(1480)), undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.xxhuge)), undefined, Caml_option.some(Style.dp(Spaces.xlarge)), undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.xlarge)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(100)), undefined, undefined),
      content: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(75)), "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(724)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

var textConfirmation = $$Intl.t("A confirmation email will be sent to you in the next few minutes. Our team will contact you soon to set up the software. You can now close this window.");

function AuthSignupSuccessPage(Props) {
  return React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles$2.container), undefined, undefined, undefined), React.createElement(AuthSignupSuccessPage$Header, {}), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Stack.make, {
                              children: null,
                              space: "normal",
                              align: "center"
                            }, React.createElement(Title.make, {
                                  children: $$Intl.t("🎉 Your account has been created successfully!"),
                                  level: 1,
                                  align: "center"
                                }), React.createElement(TextStyle.make, {
                                  children: textConfirmation,
                                  align: "center",
                                  variation: "normal"
                                }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(styles$2.content), undefined, undefined, undefined)));
}

var make = React.memo(AuthSignupSuccessPage);

export {
  GettingStartedTextIconLink ,
  Header ,
  styles$2 as styles,
  textConfirmation ,
  make ,
}
/* styles Not a pure module */
