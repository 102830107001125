// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "./Env.bs.js";
import * as React from "react";
import * as Logrocket from "logrocket";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as React$1 from "@sentry/react";
import * as FatalErrorPage from "../app/Error/FatalErrorPage.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function reportErrorMessage(prim) {
  React$1.captureMessage(prim);
}

function reportException(prim) {
  React$1.captureException(prim);
}

var contextIsOk = Env.context(undefined) === "production" || Env.context(undefined) === "staging";

function updateUserFields(userId, userName, shopId, shopName, deviceId, param) {
  if (contextIsOk) {
    React$1.setUser({
          userId: userId,
          username: userName,
          shopId: shopId,
          shopName: Caml_option.some(shopName),
          deviceId: Caml_option.some(deviceId)
        });
    return ;
  }
  
}

function updateSession(session) {
  if (contextIsOk) {
    React$1.configureScope(function (scope) {
          scope.setExtra("sessionURL", session);
        });
    return ;
  }
  
}

function BugTracker$Boundary(Props) {
  var children = Props.children;
  if (!contextIsOk) {
    return children;
  }
  var fallback = function (error) {
    var exn = Caml_js_exceptions.as_js_exn(error);
    if (exn !== undefined) {
      Logrocket.default.captureException(Caml_option.valFromOption(exn));
    }
    return React.createElement(FatalErrorPage.make, {});
  };
  return React.createElement(React$1.ErrorBoundary, {
              children: children,
              fallback: fallback
            });
}

var make = React.memo(BugTracker$Boundary);

var Boundary = {
  make: make
};

export {
  reportErrorMessage ,
  reportException ,
  updateUserFields ,
  updateSession ,
  Boundary ,
}
/* contextIsOk Not a pure module */
