// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Placeholder from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as CatalogVariantPage from "./CatalogVariantPage.bs.js";
import * as CatalogVariant__Config from "./CatalogVariant__Config.bs.js";
import * as CatalogVariant__Queries from "./CatalogVariant__Queries.bs.js";

function CatalogVariantContainer(Props) {
  var cku = Props.cku;
  var fromLabelEditSettingsRedirected = Props.fromLabelEditSettingsRedirected;
  var match = Curry.app(CatalogVariant__Queries.VariantQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var executeVariantQuery = match[0];
  var match$1 = Curry.app(CatalogVariant__Queries.VariantShopsQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var executeShopsQuery = match$1[0];
  var match$2 = Curry.app(CatalogVariant__Queries.PricesQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var executePricesQuery = match$2[0];
  var activeShop = Auth.useActiveShop(undefined);
  React.useEffect((function () {
          Curry._3(executeVariantQuery, undefined, undefined, CatalogVariant__Queries.VariantQuery.makeVariables(Scalar.CKU.serialize(cku), CatalogVariant__Config.makeVariantFilterBy(activeShop, undefined), undefined));
          Curry._3(executeShopsQuery, undefined, undefined, CatalogVariant__Queries.VariantShopsQuery.makeVariables(Scalar.CKU.serialize(cku), undefined));
          Curry._3(executePricesQuery, undefined, undefined, CatalogVariant__Queries.PricesQuery.makeVariables(undefined));
        }), [Belt_Option.map(activeShop, (function (param) {
                return param.id;
              }))]);
  var results = CatalogVariant__Config.use(match[1], match$1[1], match$2[1]);
  if (typeof results === "number") {
    if (results !== 0) {
      return React.createElement(Placeholder.make, {
                  status: /* Loading */0
                });
    } else {
      return React.createElement(Placeholder.make, {
                  status: /* Error */2
                });
    }
  }
  var match$3 = results._0;
  return React.createElement(CatalogVariantPage.make, {
              fromLabelEditSettingsRedirected: fromLabelEditSettingsRedirected,
              cku: cku,
              variantShopIds: match$3.variantShopIds,
              shopsProduct: match$3.shopsProduct,
              shopsVariant: match$3.shopsVariant,
              shopsVariantStock: match$3.shopsVariantStock,
              shopsVariantPurchasePrice: match$3.shopsVariantPurchasePrice,
              shopsVariantRetailPrices: match$3.shopsVariantRetailPrices
            });
}

var make = React.memo(CatalogVariantContainer);

var Config;

var VariantQuery;

var VariantShopsQuery;

var PricesQuery;

export {
  Config ,
  VariantQuery ,
  VariantShopsQuery ,
  PricesQuery ,
  make ,
}
/* make Not a pure module */
