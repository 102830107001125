// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "./Box.bs.js";
import * as Card from "./Card.bs.js";
import * as React from "react";
import * as Column from "./Column.bs.js";
import * as Columns from "./Columns.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LayoutPanelHeading from "./LayoutPanelHeading.bs.js";

function TableLayoutPanel(Props) {
  var title = Props.title;
  var description = Props.description;
  var children = Props.children;
  return React.createElement(Columns.make, {
              children: null,
              space: "xlarge"
            }, React.createElement(Column.make, {
                  children: React.createElement(LayoutPanelHeading.make, {
                        title: title,
                        description: description
                      }),
                  width: "quarter"
                }), React.createElement(Column.make, {
                  children: React.createElement(Card.make, {
                        children: React.createElement(Box.make, Box.makeProps(Caml_option.some(children), undefined, undefined, "xxsmall", "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                        variation: "unset"
                      }),
                  width: "three_quarter"
                }));
}

var make = React.memo(TableLayoutPanel);

export {
  make ,
}
/* make Not a pure module */
