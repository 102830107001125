// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../core/Env.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Translator from "@wino/translator";
import * as LibphonenumberJs from "libphonenumber-js";
import EnENJson from "../languages/en-EN.json";
import FrFRJson from "../languages/fr-FR.json";

var match = Env.context(undefined);

var locale = match === "test" ? "en-EN" : "fr-FR";

var languageEnEN = EnENJson;

var languageFrFR = FrFRJson;

var languages = {
  "en-EN": languageEnEN,
  "fr-FR": languageFrFR
};

function t(str) {
  return Translator.t(str, {
              plural: false,
              languages: languages,
              locale: locale
            });
}

function isPlural($$int) {
  return Translator.isPlural($$int, {
              locale: locale
            }) === 1;
}

function phoneNumberFormat(string) {
  var phoneNumber = LibphonenumberJs.parsePhoneNumberFromString(string);
  if (!(phoneNumber == null)) {
    return phoneNumber.format("INTERNATIONAL").trim();
  }
  if (string.startsWith("+") !== false) {
    return string;
  }
  var arr = string.replace(/\s/g, "").match(/.{1,2}/g);
  if (arr !== null && arr.length !== 0) {
    return arr.join(" ");
  } else {
    return "";
  }
}

function toCurrencySymbol(currency) {
  if (currency === "EUR") {
    return "€";
  } else if (currency === "USD") {
    return "$";
  } else {
    return "¥";
  }
}

function numberFormat(locale, value, numberFormatConstructorOptions) {
  return new (Intl.NumberFormat)(locale, numberFormatConstructorOptions).format(value);
}

function currencyFormat(currency, minimumFractionDigitsOpt, maximumFractionDigitsOpt, value) {
  var minimumFractionDigits = minimumFractionDigitsOpt !== undefined ? minimumFractionDigitsOpt : 2;
  var maximumFractionDigits = maximumFractionDigitsOpt !== undefined ? maximumFractionDigitsOpt : 2;
  return numberFormat(locale, value, {
              minimumFractionDigits: minimumFractionDigits,
              maximumFractionDigits: maximumFractionDigits,
              style: "currency",
              currency: currency
            });
}

function decimalFormat(minimumFractionDigits, maximumFractionDigits, value) {
  return numberFormat(locale, value, {
              minimumFractionDigits: minimumFractionDigits,
              maximumFractionDigits: maximumFractionDigits,
              style: "decimal"
            });
}

function percentFormat(minimumFractionDigits, maximumFractionDigits, value) {
  return numberFormat(locale, value, {
              minimumFractionDigits: minimumFractionDigits,
              maximumFractionDigits: maximumFractionDigits,
              style: "percent"
            });
}

function numberUnitToString(unit) {
  if (unit === "kilogram") {
    return "kg";
  } else if (unit === "liter") {
    return "L";
  } else {
    return "g";
  }
}

function unitFormat(unit, minimumFractionDigits, maximumFractionDigits, value) {
  return decimalFormat(minimumFractionDigits, maximumFractionDigits, value) + " " + numberUnitToString(unit);
}

function dateTimeFormat(dateStyle, timeStyle, year, month, day, hour, minute, second, value) {
  var dateTimeFormatOptions = Belt_Option.isSome(dateStyle) || Belt_Option.isSome(timeStyle) ? ({
        dateStyle: dateStyle,
        timeStyle: timeStyle
      }) : ({
        year: year,
        month: month,
        day: day,
        hour: hour,
        minute: minute,
        second: second
      });
  return new (Intl.DateTimeFormat)(locale, dateTimeFormatOptions).format(value);
}

var timeZone = new (Intl.DateTimeFormat)(undefined, undefined).resolvedOptions().timeZone;

function listFormat(style, type_, list) {
  return new (Intl.ListFormat)(locale, {
                style: style,
                type: type_
              }).format(list);
}

function template(prim0, prim1, prim2) {
  return Translator.template(prim0, prim1 !== undefined ? Caml_option.valFromOption(prim1) : undefined);
}

var currencyEur = "EUR";

var currencyUsd = "USD";

var currencyCny = "CNY";

function dateTimeFormatConstructor(prim0, prim1, prim2) {
  return new (Intl.DateTimeFormat)(prim0 !== undefined ? Caml_option.valFromOption(prim0) : undefined, prim1 !== undefined ? Caml_option.valFromOption(prim1) : undefined);
}

export {
  locale ,
  t ,
  isPlural ,
  template ,
  phoneNumberFormat ,
  currencyEur ,
  currencyUsd ,
  currencyCny ,
  toCurrencySymbol ,
  currencyFormat ,
  decimalFormat ,
  percentFormat ,
  numberUnitToString ,
  unitFormat ,
  dateTimeFormatConstructor ,
  dateTimeFormat ,
  timeZone ,
  listFormat ,
}
/* match Not a pure module */
