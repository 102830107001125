// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactAria from "../externals/ReactAria.bs.js";
import * as ReactAria$1 from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactDomElement from "./ReactDomElement.bs.js";
import * as ReactUpdateEffect from "./ReactUpdateEffect.bs.js";

var style = {
  backgroundColor: "inherit",
  border: "none",
  color: "inherit",
  cursor: "inherit",
  fontFamily: "inherit",
  fontSize: "inherit"
};

function TextInput(Props) {
  var inputRef = Props.inputRef;
  var ariaProps = Props.ariaProps;
  var placeholder = Props.placeholder;
  var readOnlyOpt = Props.readOnly;
  var autoTrimOpt = Props.autoTrim;
  var secureTextEntryOpt = Props.secureTextEntry;
  var autoFocusOpt = Props.autoFocus;
  var derivedStyleOpt = Props.style;
  var value = Props.value;
  var onChange = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var readOnly = readOnlyOpt !== undefined ? readOnlyOpt : false;
  var autoTrim = autoTrimOpt !== undefined ? autoTrimOpt : true;
  var secureTextEntry = secureTextEntryOpt !== undefined ? secureTextEntryOpt : false;
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
  var derivedStyle = derivedStyleOpt !== undefined ? Caml_option.valFromOption(derivedStyleOpt) : ({});
  var match = React.useState(function () {
        return false;
      });
  var setFocused = match[1];
  var focused = match[0];
  var domRef = Belt_Option.getWithDefault(inputRef, React.useRef(null));
  React.useEffect((function () {
          var input = domRef.current;
          if (input == null) {
            return ;
          }
          if (!autoFocus) {
            return ;
          }
          var timeoutId = setTimeout((function (param) {
                  ReactDomElement.focus(input);
                }), 75);
          return (function (param) {
                    clearTimeout(timeoutId);
                  });
        }), [autoFocus]);
  ReactUpdateEffect.use1((function (param) {
          if (value === undefined) {
            return ;
          }
          if (onChange === undefined) {
            return ;
          }
          if (!(!focused && autoTrim)) {
            return ;
          }
          var trimmedValue = value.trim().replace(/\s+/g, " ");
          if (trimmedValue !== value) {
            Curry._1(onChange, trimmedValue);
          }
          
        }), [focused]);
  var match$1 = Belt_Option.isSome(value) ? [
      undefined,
      value
    ] : [
      value,
      undefined
    ];
  var onFocusChange = React.useCallback((function (value) {
          Curry._1(setFocused, (function (param) {
                  return value;
                }));
        }), []);
  var props_aria$label = "Input";
  var props_inputElementType = "input";
  var props_type = secureTextEntry ? "password" : "text";
  var props_autoFocus = autoFocus;
  var props_value = match$1[1];
  var props_defaultValue = match$1[0];
  var props_onFocusChange = onFocusChange;
  var props = {
    "aria-label": props_aria$label,
    placeholder: placeholder,
    inputElementType: props_inputElementType,
    type: props_type,
    isReadOnly: readOnly,
    autoFocus: props_autoFocus,
    value: props_value,
    defaultValue: props_defaultValue,
    onChange: onChange,
    onFocus: onFocus,
    onBlur: onBlur,
    onFocusChange: props_onFocusChange
  };
  var match$2 = ReactAria$1.useTextField(props, domRef);
  var props$1 = ReactAria$1.mergeProps(match$2.inputProps, ariaProps);
  var style$1 = Object.assign({}, style, derivedStyle);
  return React.createElement(ReactAria.Spread.make, {
              props: props$1,
              children: React.createElement("input", {
                    ref: domRef,
                    style: style$1
                  })
            });
}

var make = React.memo(TextInput);

export {
  make ,
}
/* make Not a pure module */
