// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Popover from "../../resources/overlays/Popover.bs.js";
import * as Tooltip from "../../resources/overlays/Tooltip.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Touchable from "../../primitives/Touchable.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextIconButton from "../../resources/actions/TextIconButton.bs.js";
import * as ReactUpdateEffect from "../../primitives/ReactUpdateEffect.bs.js";
import * as OverlayTriggerView from "../../resources/overlays/OverlayTriggerView.bs.js";
import * as ReactUpdateDebounced from "../../primitives/ReactUpdateDebounced.bs.js";
import * as InputOptionalNumberField from "../../resources/selection-and-input/InputOptionalNumberField.bs.js";

function CatalogStockRangeSelect(Props) {
  var disabled = Props.disabled;
  var value = Props.value;
  var onChange = Props.onChange;
  var match = Popover.useTrigger(undefined, undefined);
  var popoverTriggerRef = match.ref;
  var popover = match.state;
  var match$1 = Hover.use(popoverTriggerRef, undefined);
  var triggerHovered = match$1[1];
  var match$2 = React.useState(function () {
        return value;
      });
  var setRange = match$2[1];
  var range = match$2[0];
  var debouncedValue = ReactUpdateDebounced.use(range, 1000);
  ReactUpdateEffect.use1((function (param) {
          Curry._1(onChange, debouncedValue);
        }), [debouncedValue]);
  React.useEffect((function () {
          if (value !== range) {
            Curry._1(setRange, (function (param) {
                    return value;
                  }));
          }
          
        }), [value]);
  var handleMinChange = function (value) {
    Curry._1(setRange, (function (range) {
            var next;
            if (range !== undefined) {
              var newrecord = Caml_obj.obj_dup(range);
              next = (newrecord.min = value, newrecord);
            } else {
              next = {
                min: value
              };
            }
            if (next !== undefined && !(next.min !== undefined || next.max !== undefined)) {
              return ;
            } else {
              return next;
            }
          }));
  };
  var handleMaxChange = function (value) {
    Curry._1(setRange, (function (range) {
            var next;
            if (range !== undefined) {
              var newrecord = Caml_obj.obj_dup(range);
              next = (newrecord.max = value, newrecord);
            } else {
              next = {
                max: value
              };
            }
            if (next !== undefined && !(next.min !== undefined || next.max !== undefined)) {
              return ;
            } else {
              return next;
            }
          }));
  };
  var handleReset = function (param) {
    Curry._1(setRange, (function (param) {
            
          }));
    Curry._1(popover.close, undefined);
  };
  var popover_isOpen = popover.isOpen;
  var popover_close = function (param) {
    if (!triggerHovered) {
      return Curry._1(popover.close, undefined);
    }
    
  };
  var popover_toggle = popover.toggle;
  var popover$1 = {
    isOpen: popover_isOpen,
    close: popover_close,
    toggle: popover_toggle
  };
  var formattedRange;
  if (range !== undefined) {
    var min = range.min;
    if (min !== undefined) {
      var max = range.max;
      formattedRange = max !== undefined ? "" + $$Intl.decimalFormat(0, 3, min) + " — " + $$Intl.decimalFormat(0, 3, max) + "" : "≥ " + $$Intl.decimalFormat(0, 3, min) + "";
    } else {
      var max$1 = range.max;
      formattedRange = max$1 !== undefined ? "≤ " + $$Intl.decimalFormat(0, 3, max$1) + "" : $$Intl.t("All");
    }
  } else {
    formattedRange = $$Intl.t("All");
  }
  var rangeMinValue = Belt_Option.flatMap(range, (function (param) {
          return param.min;
        }));
  var rangeMaxValue = Belt_Option.flatMap(range, (function (param) {
          return param.max;
        }));
  return React.createElement("div", {
              style: {
                marginRight: "1px"
              }
            }, React.createElement(Tooltip.make, {
                  children: React.createElement(Touchable.make, Touchable.makeProps(React.createElement(OverlayTriggerView.make, {
                                children: React.createElement("span", undefined, formattedRange),
                                preset: "filter",
                                size: "compact",
                                label: $$Intl.t("Stock"),
                                hovered: triggerHovered,
                                active: popover_isOpen,
                                highlighted: Belt_Option.isSome(range),
                                disabled: disabled
                              }), undefined, disabled, undefined, undefined, undefined, (function (param) {
                              Curry._1(popover_toggle, undefined);
                            }), undefined, popoverTriggerRef, undefined)),
                  placement: "top",
                  content: React.createElement(Tooltip.Span.make, {
                        text: $$Intl.t("Please select a shop beforehand.")
                      }),
                  disabled: !disabled,
                  delay: 0
                }), popover_isOpen ? React.createElement(Popover.make, {
                    children: React.createElement(Popover.Dialog.make, {
                          children: React.createElement(Box.make, Box.makeProps(Caml_option.some(null), "large", "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement("div", {
                                    style: {
                                      display: "flex",
                                      width: "180px",
                                      columnGap: "12px"
                                    }
                                  }, React.createElement(InputOptionalNumberField.make, InputOptionalNumberField.makeProps(rangeMinValue, undefined, rangeMaxValue, undefined, undefined, 3, $$Intl.t("Minimum"), undefined, undefined, "-∞", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, handleMinChange, undefined, undefined, undefined, undefined, undefined)), React.createElement(InputOptionalNumberField.make, InputOptionalNumberField.makeProps(rangeMaxValue, rangeMinValue, undefined, undefined, undefined, 3, $$Intl.t("Maximum"), undefined, undefined, "∞", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, handleMaxChange, undefined, undefined, undefined, undefined, undefined))), React.createElement(Box.make, Box.makeProps(undefined, undefined, undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Inline.make, {
                                    children: React.createElement(TextIconButton.make, TextIconButton.makeProps($$Intl.t("Reset"), undefined, "small", undefined, "close_light", undefined, handleReset, undefined, undefined, undefined))
                                  })),
                          ariaProps: match.ariaProps.overlayProps
                        }),
                    triggerRef: popoverTriggerRef,
                    state: popover$1,
                    placement: "bottom start",
                    shouldUpdatePosition: false,
                    modal: false
                  }) : null);
}

var make = CatalogStockRangeSelect;

export {
  make ,
}
/* Box Not a pure module */
