// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as AnalyticsShopsPage from "./AnalyticsShopsPage.bs.js";
import * as AnalyticsCashFlowPage from "./AnalyticsCashFlowPage.bs.js";
import * as AnalyticsOverviewPage from "./AnalyticsOverviewPage.bs.js";
import * as AnalyticsTopPerformingProductsPage from "./AnalyticsTopPerformingProductsPage.bs.js";

var baseRoute = "/analytics";

var overviewRoute = baseRoute + "/overview";

var shopsRoute = baseRoute + "/shops";

var topPerformingProductsRoute = baseRoute + "/top-performing-products";

var cashFlowRoute = baseRoute + "/cash-flow";

function AnalyticsRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  if (subUrlPath) {
    switch (subUrlPath.hd) {
      case "cash-flow" :
          if (!subUrlPath.tl) {
            return React.createElement(AnalyticsCashFlowPage.make, {});
          }
          break;
      case "overview" :
          if (!subUrlPath.tl) {
            return React.createElement(AnalyticsOverviewPage.make, {});
          }
          break;
      case "shops" :
          if (!subUrlPath.tl) {
            return React.createElement(AnalyticsShopsPage.make, {});
          }
          break;
      case "top-performing-products" :
          if (!subUrlPath.tl) {
            return React.createElement(AnalyticsTopPerformingProductsPage.make, {});
          }
          break;
      default:
        
    }
  }
  return React.createElement(Navigation.Redirect.make, {
              route: overviewRoute
            });
}

var make = AnalyticsRouter;

export {
  baseRoute ,
  overviewRoute ,
  shopsRoute ,
  topPerformingProductsRoute ,
  cashFlowRoute ,
  make ,
}
/* react Not a pure module */
