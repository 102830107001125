// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as AdminShopListPage from "./AdminShopListPage.bs.js";
import * as AdminImpersonationPage from "./AdminImpersonationPage.bs.js";

var baseRoute = "/admin";

var impersonationRoute = baseRoute + "/impersonation";

var shopsRoute = baseRoute + "/shops";

function AdminRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  var appIndexRoute = Props.appIndexRoute;
  if (subUrlPath) {
    switch (subUrlPath.hd) {
      case "impersonation" :
          if (!subUrlPath.tl) {
            return React.createElement(AdminImpersonationPage.make, {});
          }
          break;
      case "shops" :
          if (!subUrlPath.tl) {
            return React.createElement(AdminShopListPage.make, {});
          }
          break;
      default:
        
    }
  }
  return React.createElement(Navigation.Redirect.make, {
              route: appIndexRoute
            });
}

var make = React.memo(AdminRouter);

export {
  baseRoute ,
  impersonationRoute ,
  shopsRoute ,
  make ,
}
/* make Not a pure module */
