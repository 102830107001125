// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../images-and-icons/Icon.bs.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as React from "react";
import * as Touchable from "../../primitives/Touchable.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Stylex from "@stylexjs/stylex";

var styles = Stylex.create({
      root: {
        boxSizing: "border-box",
        border: "1px solid transparent",
        borderRadius: "5px",
        alignItems: "center",
        display: "flex",
        gap: "4px",
        height: "40px",
        padding: "8px",
        ":hover": {
          backgroundColor: "#f8f8fb",
          borderColor: "#bdbdca"
        }
      },
      active: {
        backgroundColor: "#fefefe",
        borderColor: "#a2a1b0",
        ":hover": {
          backgroundColor: "#fefefe",
          borderColor: "#a2a1b0"
        }
      },
      disabled: {
        borderColor: "transparent",
        ":hover": {
          borderColor: "transparent"
        }
      },
      iconWrapper: {
        marginRight: "-1px",
        marginTop: "1px"
      }
    });

function styleProps(disabled, active) {
  return Stylex.props(styles.root, active ? styles.active : ({}), disabled ? styles.disabled : ({}));
}

var make = React.forwardRef(function (Props, ref) {
      var children = Props.children;
      var ariaProps = Props.ariaProps;
      var opened = Props.opened;
      var disabledOpt = Props.disabled;
      var onPress = Props.onPress;
      var disabled = disabledOpt !== undefined ? disabledOpt : false;
      var ref$1 = (ref == null) ? undefined : Caml_option.some(ref);
      var match = Hover.use(ref$1, undefined);
      var wrapperStyle = {
        marginLeft: "-8px"
      };
      var match$1 = styleProps(disabled, opened);
      var match$2 = Stylex.props(styles.iconWrapper);
      var iconColor = disabled ? "#bdbdca" : (
          opened || match[1] ? "#25243a" : "#797885"
        );
      var tmp = {};
      if (match$1.className !== undefined) {
        tmp.className = Caml_option.valFromOption(match$1.className);
      }
      if (match$1.style !== undefined) {
        tmp.style = Caml_option.valFromOption(match$1.style);
      }
      var tmp$1 = {};
      if (match$2.className !== undefined) {
        tmp$1.className = Caml_option.valFromOption(match$2.className);
      }
      if (match$2.style !== undefined) {
        tmp$1.style = Caml_option.valFromOption(match$2.style);
      }
      return React.createElement(Touchable.make, Touchable.makeProps(React.createElement("div", tmp, children, React.createElement("div", tmp$1, React.createElement(Icon.make, {
                                  name: opened ? "arrow_up_light" : "arrow_down_light",
                                  fill: iconColor,
                                  size: 14
                                }))), ariaProps, disabled, undefined, undefined, Caml_option.some(wrapperStyle), onPress, undefined, match[0], undefined));
    });

var make$1 = React.memo(make);

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7) {
  var tmp = {
    children: prim0,
    opened: prim2,
    onPress: prim4
  };
  if (prim1 !== undefined) {
    tmp.ariaProps = Caml_option.valFromOption(prim1);
  }
  if (prim3 !== undefined) {
    tmp.disabled = Caml_option.valFromOption(prim3);
  }
  if (prim5 !== undefined) {
    tmp.key = prim5;
  }
  if (prim6 !== undefined) {
    tmp.ref = Caml_option.valFromOption(prim6);
  }
  return tmp;
}

export {
  makeProps ,
  make$1 as make,
}
/* styles Not a pure module */
