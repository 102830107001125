// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Text from "../../primitives/Text.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Spaces from "../theme/Spaces.bs.js";
import * as TextStyle from "../typography/TextStyle.bs.js";
import * as Touchable from "../../primitives/Touchable.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var styles = $$StyleSheet.create({
      container: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "transparent", undefined, undefined, undefined, undefined, 5, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexStart", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(40)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.normal)), undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.small)), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      containerInteracted: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#f8f8fb", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      containerHovered: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#e7e7ee", undefined, undefined, undefined, undefined, undefined, undefined, "#d7d7e0", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 10, undefined),
      containerFocused: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#e7e7ee", undefined, undefined, undefined, undefined, undefined, undefined, "#bdbdca", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      containerErrored: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#e61e5a", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function containerStyleFromParams(disabled, hovered, focused, errored) {
  return Style.arrayOptionStyle([
              Caml_option.some(styles.container),
              disabled || !(hovered && focused) ? undefined : Caml_option.some(styles.containerInteracted),
              disabled ? undefined : (
                  errored ? Caml_option.some(styles.containerErrored) : (
                      focused ? Caml_option.some(styles.containerFocused) : (
                          hovered ? Caml_option.some(styles.containerHovered) : undefined
                        )
                    )
                )
            ]);
}

var make = React.forwardRef(function (Props, ref) {
      var children = Props.children;
      var disabled = Props.disabled;
      var onPress = Props.onPress;
      if (onPress !== undefined) {
        return React.createElement(Touchable.make, Touchable.makeProps(children, undefined, disabled, undefined, undefined, undefined, (function (param) {
                          Curry._1(onPress, undefined);
                        }), undefined, Caml_option.some(ref), undefined));
      } else {
        return children;
      }
    });

var make$1 = React.forwardRef(function (Props, ref) {
      var children = Props.children;
      var variationOpt = Props.variation;
      var hovered = Props.hovered;
      var compactOpt = Props.compact;
      var focusedOpt = Props.focused;
      var erroredOpt = Props.errored;
      var disabledOpt = Props.disabled;
      var onPress = Props.onPress;
      var variation = variationOpt !== undefined ? variationOpt : "normal";
      var compact = compactOpt !== undefined ? compactOpt : false;
      var focused = focusedOpt !== undefined ? focusedOpt : false;
      var errored = erroredOpt !== undefined ? erroredOpt : false;
      var disabled = disabledOpt !== undefined ? disabledOpt : false;
      var tmp = {
        children: React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement($$Text.make, $$Text.makeProps(React.createElement(TextStyle.make, {
                                  children: children,
                                  variation: variation === "neutral" ? "normal" : (
                                      variation === "subdued" ? "subdued" : "neutral"
                                    ),
                                  weight: variation === "normal" || variation === "subdued" || variation === "neutral" ? "regular" : "medium",
                                  size: compact ? "xsmall" : "normal"
                                }), Caml_option.some(compact ? Style.unsafeCss({
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textAlign: "left",
                                        direction: "rtl"
                                      }) : Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined))), Caml_option.some(containerStyleFromParams(disabled, hovered, focused, errored)), undefined, Caml_option.some(ref), undefined)),
        disabled: disabled
      };
      if (onPress !== undefined) {
        tmp.onPress = Caml_option.valFromOption(onPress);
      }
      return React.createElement(make, tmp);
    });

var make$2 = React.memo(make$1);

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9, prim10) {
  var tmp = {
    children: prim0,
    hovered: prim2
  };
  if (prim1 !== undefined) {
    tmp.variation = Caml_option.valFromOption(prim1);
  }
  if (prim3 !== undefined) {
    tmp.compact = Caml_option.valFromOption(prim3);
  }
  if (prim4 !== undefined) {
    tmp.focused = Caml_option.valFromOption(prim4);
  }
  if (prim5 !== undefined) {
    tmp.errored = Caml_option.valFromOption(prim5);
  }
  if (prim6 !== undefined) {
    tmp.disabled = Caml_option.valFromOption(prim6);
  }
  if (prim7 !== undefined) {
    tmp.onPress = Caml_option.valFromOption(prim7);
  }
  if (prim8 !== undefined) {
    tmp.key = prim8;
  }
  if (prim9 !== undefined) {
    tmp.ref = Caml_option.valFromOption(prim9);
  }
  return tmp;
}

export {
  makeProps ,
  make$2 as make,
}
/* styles Not a pure module */
