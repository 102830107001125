// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../../core/Env.bs.js";
import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Json from "../../primitives/Json.bs.js";
import * as Uuid from "../../externals/Uuid.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Request from "../../core/Request.bs.js";
import * as JsonCodec from "../../primitives/JsonCodec.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as UserPreferences from "../../core/UserPreferences.bs.js";

var RequestProductType = {};

var RequestSheetLabelsType = {};

var values = [
  /* Hidden */0,
  /* SKU */1,
  /* InternalCode */2
];

function toString(value) {
  switch (value) {
    case /* Hidden */0 :
        return "Hidden";
    case /* SKU */1 :
        return "SKU";
    case /* InternalCode */2 :
        return "InternalCode";
    
  }
}

function fromString(value) {
  switch (value) {
    case "Hidden" :
        return {
                TAG: /* Ok */0,
                _0: /* Hidden */0
              };
    case "InternalCode" :
        return {
                TAG: /* Ok */0,
                _0: /* InternalCode */2
              };
    case "SKU" :
        return {
                TAG: /* Ok */0,
                _0: /* SKU */1
              };
    default:
      return {
              TAG: /* Error */1,
              _0: undefined
            };
  }
}

function toLabel(value) {
  switch (value) {
    case /* Hidden */0 :
        return $$Intl.t("Do not display additional product codes");
    case /* SKU */1 :
        return $$Intl.t("Display SKU");
    case /* InternalCode */2 :
        return $$Intl.t("Display internal code");
    
  }
}

var ProductCode = {
  values: values,
  toString: toString,
  fromString: fromString,
  toLabel: toLabel
};

var endpoint = Env.gatewayUrl(undefined) + "/barcode-completions";

function encodeBodyJson(productsValues, shopId) {
  if (productsValues && productsValues._0.length === 0) {
    return {
            TAG: /* Error */1,
            _0: $$Intl.t("Products list empty, please select at least one product before continuing")
          };
  }
  var dict = {};
  if (productsValues) {
    dict["shopId"] = shopId;
    dict["variantIds"] = Belt_Array.map(productsValues._0, (function (param) {
            return param.variantId;
          }));
  } else {
    dict["shopId"] = shopId;
  }
  return {
          TAG: /* Ok */0,
          _0: dict
        };
}

function make(bodyJson) {
  return $$Request.make("POST", Caml_option.some(bodyJson), undefined, true, endpoint);
}

var BarcodeCompletionRequest = {
  endpoint: endpoint,
  encodeBodyJson: encodeBodyJson,
  make: make
};

function toString$1(value) {
  if (value) {
    return "Grid64";
  } else {
    return "Grid21";
  }
}

function fromString$1(value) {
  switch (value) {
    case "Grid21" :
        return {
                TAG: /* Ok */0,
                _0: /* Grid21 */0
              };
    case "Grid64" :
        return {
                TAG: /* Ok */0,
                _0: /* Grid64 */1
              };
    default:
      return {
              TAG: /* Error */1,
              _0: undefined
            };
  }
}

function toMaxOffset(value) {
  if (value) {
    return 64;
  } else {
    return 21;
  }
}

function toFormatLabel(value) {
  if (value) {
    return "4,57 x 1,68 cm";
  } else {
    return "6,35 x 3,81 cm";
  }
}

function toLabel$1(value) {
  return $$Intl.template($$Intl.t("Sheet of {{number}} labels (format {{format}})"), {
              number: value ? 64 : 21,
              format: value ? "4,57 x 1,68 cm" : "6,35 x 3,81 cm"
            }, undefined);
}

var LabelFormat = {
  toString: toString$1,
  fromString: fromString$1,
  toMaxOffset: toMaxOffset,
  toFormatLabel: toFormatLabel,
  toLabel: toLabel$1
};

var values$1 = [
  /* Unsorted */0,
  /* AscProductName */1,
  /* AscProductCategory */2
];

function toString$2(value) {
  switch (value) {
    case /* Unsorted */0 :
        return "Unsorted";
    case /* AscProductName */1 :
        return "AscProductName";
    case /* AscProductCategory */2 :
        return "AscProductCategory";
    
  }
}

function fromString$2(value) {
  switch (value) {
    case "AscProductCategory" :
        return {
                TAG: /* Ok */0,
                _0: /* AscProductCategory */2
              };
    case "AscProductName" :
        return {
                TAG: /* Ok */0,
                _0: /* AscProductName */1
              };
    case "Unsorted" :
        return {
                TAG: /* Ok */0,
                _0: /* Unsorted */0
              };
    default:
      return {
              TAG: /* Error */1,
              _0: undefined
            };
  }
}

function toLabel$2(value) {
  switch (value) {
    case /* Unsorted */0 :
        return $$Intl.t("Do not sort");
    case /* AscProductName */1 :
        return $$Intl.t("Sort alphabetically by product name");
    case /* AscProductCategory */2 :
        return $$Intl.t("Sort alphabetically by product category");
    
  }
}

var LabelSort = {
  values: values$1,
  toString: toString$2,
  fromString: fromString$2,
  toLabel: toLabel$2
};

var endpoint$1 = Env.pdfUrl(undefined) + "/variant-labels";

function encodeBodyJson$1(productsValues, priceId, productBarcodeDisplayed, priceDisplayed, producerDisplayed, alcoholVolumeDisplayed, productCode, labelFormat, printOffset, borderEnabled, sort) {
  if (productsValues && productsValues._0.length === 0) {
    return {
            TAG: /* Error */1,
            _0: $$Intl.t("Products list empty, please select at least one product before continuing")
          };
  }
  var dict = {};
  dict["priceId"] = Json.encodeString(priceId);
  dict["barcodeEnabled"] = Json.encodeBoolean(productBarcodeDisplayed);
  dict["priceEnabled"] = Json.encodeBoolean(priceDisplayed);
  dict["producerEnabled"] = Json.encodeBoolean(producerDisplayed);
  dict["alcoholVolumeEnabled"] = Json.encodeBoolean(alcoholVolumeDisplayed);
  dict["borderEnabled"] = Json.encodeBoolean(borderEnabled);
  dict["offsetLabel"] = Json.encodeNumber(printOffset);
  switch (productCode) {
    case /* Hidden */0 :
        break;
    case /* SKU */1 :
        dict["labelCodeKind"] = Json.encodeString("SKU");
        break;
    case /* InternalCode */2 :
        dict["labelCodeKind"] = Json.encodeString("INTERNAL_CODE");
        break;
    
  }
  if (labelFormat) {
    dict["labelsPerPage"] = Json.encodeString("A4_64");
  } else {
    dict["labelsPerPage"] = Json.encodeString("A4_21");
  }
  switch (sort) {
    case /* Unsorted */0 :
        break;
    case /* AscProductName */1 :
        dict["labelSorting"] = Json.encodeString("ASC_PRODUCT_NAME");
        break;
    case /* AscProductCategory */2 :
        dict["labelSorting"] = Json.encodeString("ASC_CATEGORY");
        break;
    
  }
  if (productsValues) {
    var items = Belt_Array.map(productsValues._0, (function (param) {
            return Js_dict.fromArray([
                        [
                          "variantId",
                          Json.encodeString(param.variantId)
                        ],
                        [
                          "repetitions",
                          Json.encodeNumber(param.repetitions)
                        ]
                      ]);
          }));
    dict["items"] = Json.encodeArray(items);
  }
  return {
          TAG: /* Ok */0,
          _0: Json.encodeDict(dict)
        };
}

function decodeUrl(json) {
  return Json.flatDecodeDictFieldString(json, "url");
}

function decodeInvalidBarcodesVariants(json) {
  return Belt_Option.map(Json.flatDecodeDictFieldArray(json, "invalidBarcodes"), (function (invalidBarcodes) {
                return Belt_Array.keepMap(invalidBarcodes, (function (item) {
                              var variantId = Json.flatDecodeDictFieldString(Json.decodeDict(item), "variantId");
                              var productName = Json.flatDecodeDictFieldString(Json.decodeDict(item), "productName");
                              var variantName = Json.flatDecodeDictFieldString(Json.decodeDict(item), "variantName");
                              if (variantId !== undefined && productName !== undefined && variantName !== undefined) {
                                return {
                                        id: variantId,
                                        productName: productName,
                                        name: variantName
                                      };
                              }
                              
                            }));
              }));
}

function decodeBodyJson(result) {
  var val;
  try {
    val = Belt_Option.map(Belt_Option.map(Json.decodeDict(result), (function (data) {
                return [
                        Json.flatDecodeDictFieldString(Caml_option.some(data), "url"),
                        decodeInvalidBarcodesVariants(Caml_option.some(data))
                      ];
              })), (function (param) {
            return [
                    Belt_Option.map(param[0], (function (prim) {
                            return new URL(prim);
                          })),
                    param[1]
                  ];
          }));
  }
  catch (exn){
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
  if (val === undefined) {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
  var url = val[0];
  if (url === undefined) {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
  var variants = val[1];
  if (variants === undefined) {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
  var url$1 = Caml_option.valFromOption(url);
  if (url$1.protocol === "https:") {
    return {
            TAG: /* Ok */0,
            _0: {
              url: url$1,
              invalidBarcodesVariants: variants
            }
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
}

function make$1(bodyJson) {
  return $$Request.make("POST", Caml_option.some(bodyJson), undefined, true, endpoint$1);
}

var LabelsRequest = {
  endpoint: endpoint$1,
  encodeBodyJson: encodeBodyJson$1,
  decodeUrl: decodeUrl,
  decodeInvalidBarcodesVariants: decodeInvalidBarcodesVariants,
  decodeBodyJson: decodeBodyJson,
  make: make$1
};

function make$2(requestBarcodeCompletion, requestLabelsGenerating, productsValues, priceId, missingProductBarcodeGenerated, productBarcodeDisplayed, priceDisplayed, producerDisplayed, alcoholVolumeDisplayed, productCode, labelFormat, printOffset, borderEnabled, sort, shopId) {
  var variantLabelBodyJson = encodeBodyJson$1(productsValues, priceId, productBarcodeDisplayed, priceDisplayed, producerDisplayed, alcoholVolumeDisplayed, productCode, labelFormat, printOffset, borderEnabled, sort);
  if (variantLabelBodyJson.TAG !== /* Ok */0) {
    return Future.value({
                TAG: /* Error */1,
                _0: variantLabelBodyJson._0
              });
  }
  var variantLabelBodyJson$1 = variantLabelBodyJson._0;
  var preRequestBarcodeCompletion;
  if (missingProductBarcodeGenerated) {
    var barcodeCompletionBodyJson = encodeBodyJson(productsValues, shopId);
    preRequestBarcodeCompletion = barcodeCompletionBodyJson.TAG === /* Ok */0 ? Future.mapError(Future.mapOk(Curry._1(requestBarcodeCompletion, barcodeCompletionBodyJson._0), undefined, (function (param) {
                  
                })), undefined, (function (param) {
              
            })) : Future.value({
            TAG: /* Error */1,
            _0: undefined
          });
  } else {
    preRequestBarcodeCompletion = Future.value({
          TAG: /* Ok */0,
          _0: undefined
        });
  }
  return Future.mapError(Future.mapOk(Future.flatMapOk(preRequestBarcodeCompletion, undefined, (function (param) {
                        return Future.map(Curry._1(requestLabelsGenerating, variantLabelBodyJson$1), undefined, (function (result) {
                                      if (result.TAG === /* Ok */0) {
                                        return decodeBodyJson(result._0);
                                      } else {
                                        return {
                                                TAG: /* Error */1,
                                                _0: undefined
                                              };
                                      }
                                    }));
                      })), undefined, (function (result) {
                    return result;
                  })), undefined, (function (param) {
                return $$Intl.t("An unexpected error occured. Please try again or contact the support.");
              }));
}

var CombineRequests = {
  make: make$2
};

var Sheet = {
  LabelFormat: LabelFormat,
  LabelSort: LabelSort,
  LabelsRequest: LabelsRequest,
  CombineRequests: CombineRequests
};

function toString$3(value) {
  if (value) {
    return "Label57x19";
  } else {
    return "Label31x22";
  }
}

function fromString$3(value) {
  switch (value) {
    case "Label31x22" :
        return {
                TAG: /* Ok */0,
                _0: /* Label31x22 */0
              };
    case "Label57x19" :
        return {
                TAG: /* Ok */0,
                _0: /* Label57x19 */1
              };
    default:
      return {
              TAG: /* Error */1,
              _0: undefined
            };
  }
}

function toFormatLabel$1(value) {
  if (value) {
    return "5,70 x 1,90 cm";
  } else {
    return "3,10 x 2,20 cm";
  }
}

function toLabel$3(value) {
  return $$Intl.template($$Intl.t("Unit label (format {{format}})"), {
              format: value ? "5,70 x 1,90 cm" : "3,10 x 2,20 cm"
            }, undefined);
}

var LabelFormat$1 = {
  toString: toString$3,
  fromString: fromString$3,
  toFormatLabel: toFormatLabel$1,
  toLabel: toLabel$3
};

var printersHubName = "StarPrintersHub";

function endpoint$2(shopId) {
  return Env.gatewayUrl(undefined) + "/printers-hub/" + printersHubName + "/" + shopId + "/default-printer";
}

function decodeInvalidRequestFailure(error) {
  if (error.kind === "NotFoundShopDefaultPrinterConfig") {
    return /* NoPrinterFound */0;
  } else {
    return /* UnexpectedFailure */1;
  }
}

function futureMapResult(futureResult) {
  return Future.mapError(Future.mapOk(futureResult, undefined, (function (param) {
                    
                  })), undefined, (function (error) {
                if (typeof error === "number" || error.TAG !== /* InvalidRequestFailures */2) {
                  return /* UnexpectedFailure */1;
                } else {
                  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.get(error._0, 0), decodeInvalidRequestFailure), /* UnexpectedFailure */1);
                }
              }));
}

function make$3(shopId) {
  return futureMapResult($$Request.make("GET", undefined, undefined, undefined, endpoint$2(shopId)));
}

var DefaultPrinterRequest = {
  endpoint: endpoint$2,
  decodeInvalidRequestFailure: decodeInvalidRequestFailure,
  futureMapResult: futureMapResult,
  make: make$3
};

function endpoint$3(shopId) {
  return Env.gatewayUrl(undefined) + ("/printers-hub/" + printersHubName + "/" + shopId + "/print-variants-labels");
}

function encodeBodyJson$2(priceId, productCode, productBarcodeDisplayed, priceDisplayed, producerDisplayed, alcoholVolumeDisplayed, labelFormat, productsValues) {
  if (productsValues && productsValues._0.length === 0) {
    return {
            TAG: /* Error */1,
            _0: $$Intl.t("Products list empty, please select at least one product before continuing")
          };
  }
  var dict = {};
  dict["priceId"] = Json.encodeString(priceId);
  dict["barcodeEnabled"] = Json.encodeBoolean(productBarcodeDisplayed);
  dict["priceEnabled"] = Json.encodeBoolean(priceDisplayed);
  dict["producerEnabled"] = Json.encodeBoolean(producerDisplayed);
  dict["alcoholVolumeEnabled"] = Json.encodeBoolean(alcoholVolumeDisplayed);
  switch (productCode) {
    case /* Hidden */0 :
        break;
    case /* SKU */1 :
        dict["labelCodeKind"] = Json.encodeString("SKU");
        break;
    case /* InternalCode */2 :
        dict["labelCodeKind"] = Json.encodeString("INTERNAL_CODE");
        break;
    
  }
  if (labelFormat) {
    
  } else {
    dict["labelFormat"] = Json.encodeString("W31_X_H22");
  }
  if (productsValues) {
    var items = Belt_Array.map(productsValues._0, (function (param) {
            return Js_dict.fromArray([
                        [
                          "variantId",
                          Json.encodeString(param.variantId)
                        ],
                        [
                          "repetitions",
                          Json.encodeNumber(param.repetitions)
                        ]
                      ]);
          }));
    dict["items"] = Json.encodeArray(items);
  }
  return {
          TAG: /* Ok */0,
          _0: Json.encodeDict(dict)
        };
}

function decodeInvalidRequestFailure$1(serverFailure) {
  var match = serverFailure.kind;
  switch (match) {
    case "InvalidVariantLabelPrice" :
    case "NotFoundPriceForVariant" :
        return /* PriceUnknown */0;
    default:
      return /* Unknown */1;
  }
}

function parseAndDecodeError(string) {
  var json;
  try {
    json = Json.parseExn(string);
  }
  catch (exn){
    return /* Unknown */1;
  }
  var match = $$Request.decodeResult(json);
  if (match.TAG === /* Ok */0) {
    return ;
  }
  var serverFailures = match._0;
  if (serverFailures !== undefined) {
    return Belt_Option.map(Belt_Array.get(serverFailures, 0), decodeInvalidRequestFailure$1);
  }
  
}

function make$4(bodyJson, shopId) {
  var jwt = Auth.getJwt(undefined);
  if (jwt !== undefined) {
    return Future.map(FuturePromise.fromPromise(fetch(endpoint$3(shopId), {
                          method: "POST",
                          body: Caml_option.some(Json.stringify(bodyJson)),
                          headers: Caml_option.some(new Headers({
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + jwt + ""
                                  }))
                        }).then(function (prim) {
                        return prim.text();
                      })), undefined, (function (result) {
                  if (result.TAG !== /* Ok */0) {
                    return {
                            TAG: /* Error */1,
                            _0: /* Unknown */1
                          };
                  }
                  var errorTextResponse = result._0;
                  if (errorTextResponse === "OK") {
                    return {
                            TAG: /* Ok */0,
                            _0: undefined
                          };
                  } else {
                    return {
                            TAG: /* Error */1,
                            _0: Belt_Option.getWithDefault(parseAndDecodeError(errorTextResponse), /* Unknown */1)
                          };
                  }
                }));
  } else {
    return Future.value({
                TAG: /* Error */1,
                _0: /* Unknown */1
              });
  }
}

var LabelsRequest$1 = {
  endpoint: endpoint$3,
  encodeBodyJson: encodeBodyJson$2,
  decodeInvalidRequestFailure: decodeInvalidRequestFailure$1,
  parseAndDecodeError: parseAndDecodeError,
  make: make$4
};

function make$5(requestBarcodeCompletion, requestLabelsPrinting, priceId, priceName, productCode, productBarcodeDisplayed, productsValues, labelFormat, missingProductBarcodeGenerated, priceDisplayed, producerDisplayed, alcoholVolumeDisplayed, shopId) {
  var bodyJson = encodeBodyJson$2(priceId, productCode, productBarcodeDisplayed, priceDisplayed, producerDisplayed, alcoholVolumeDisplayed, labelFormat, productsValues);
  if (bodyJson.TAG !== /* Ok */0) {
    return Future.value({
                TAG: /* Error */1,
                _0: bodyJson._0
              });
  }
  var bodyJson$1 = bodyJson._0;
  var preRequestBarcodeCompletion;
  if (missingProductBarcodeGenerated) {
    var barcodeCompletionBodyJson = encodeBodyJson(productsValues, shopId);
    preRequestBarcodeCompletion = barcodeCompletionBodyJson.TAG === /* Ok */0 ? Future.mapError(Future.mapOk(Curry._1(requestBarcodeCompletion, barcodeCompletionBodyJson._0), undefined, (function (param) {
                  
                })), undefined, (function (param) {
              
            })) : Future.value({
            TAG: /* Error */1,
            _0: undefined
          });
  } else {
    preRequestBarcodeCompletion = Future.value({
          TAG: /* Ok */0,
          _0: undefined
        });
  }
  return Future.mapError(Future.flatMapOk(Future.mapError(preRequestBarcodeCompletion, undefined, (function (param) {
                        return /* Unknown */1;
                      })), undefined, (function (param) {
                    return Curry._2(requestLabelsPrinting, bodyJson$1, shopId);
                  })), undefined, (function (serverFailure) {
                if (serverFailure) {
                  return $$Intl.t("An unexpected error occured. Please try again or contact the support.");
                } else {
                  return $$Intl.template($$Intl.t("The label printing has failed: please enter the retail price for the price list set up for printing ({{priceName}})."), {
                              priceName: priceName
                            }, undefined);
                }
              }));
}

var CombineRequests$1 = {
  make: make$5
};

var Print = {
  LabelFormat: LabelFormat$1,
  printersHubName: printersHubName,
  DefaultPrinterRequest: DefaultPrinterRequest,
  LabelsRequest: LabelsRequest$1,
  CombineRequests: CombineRequests$1
};

function toString$4(polyValue) {
  if (polyValue.TAG === /* LabelSheet */0) {
    if (polyValue._0) {
      return "Grid64";
    } else {
      return "Grid21";
    }
  } else if (polyValue._0) {
    return "Label57x19";
  } else {
    return "Label31x22";
  }
}

function fromString$4(value) {
  var match = fromString$1(value);
  var match$1 = fromString$3(value);
  if (match.TAG === /* Ok */0) {
    return {
            TAG: /* LabelSheet */0,
            _0: {
              TAG: /* Ok */0,
              _0: match._0
            }
          };
  } else if (match$1.TAG === /* Ok */0) {
    return {
            TAG: /* LabelPrint */1,
            _0: {
              TAG: /* Ok */0,
              _0: match$1._0
            }
          };
  } else {
    return {
            TAG: /* LabelPrint */1,
            _0: {
              TAG: /* Error */1,
              _0: undefined
            }
          };
  }
}

var GlobalLabelFormat = {
  toString: toString$4,
  fromString: fromString$4
};

var jsonCodec = JsonCodec.object12((function (param) {
        return [
                param.priceId,
                param.priceName,
                param.productBarcodeDisplayed,
                param.missingProductBarcodeGenerated,
                param.priceDisplayed,
                param.producerDisplayed,
                param.alcoholVolumeDisplayed,
                toString(param.productCode),
                toString$4(param.labelFormat),
                param.printOffset,
                param.borderEnabled,
                toString$2(param.sort)
              ];
      }), (function (param) {
        var borderEnabled = param[10];
        var printOffset = param[9];
        var alcoholVolumeDisplayed = param[6];
        var producerDisplayed = param[5];
        var priceDisplayed = param[4];
        var missingProductBarcodeGenerated = param[3];
        var productBarcodeDisplayed = param[2];
        var priceName = param[1];
        var match = Uuid.fromString(param[0]);
        var match$1 = fromString(param[7]);
        var match$2 = fromString$4(param[8]);
        var match$3 = fromString$2(param[11]);
        if (match === undefined) {
          return {
                  TAG: /* Error */1,
                  _0: {
                    NAME: "SyntaxError",
                    VAL: "Could not decode"
                  }
                };
        }
        var priceId = Caml_option.valFromOption(match);
        if (match$1.TAG !== /* Ok */0) {
          return {
                  TAG: /* Error */1,
                  _0: {
                    NAME: "SyntaxError",
                    VAL: "Could not decode"
                  }
                };
        }
        var productCode = match$1._0;
        if (match$2.TAG === /* LabelSheet */0) {
          var labelFormat = match$2._0;
          if (labelFormat.TAG === /* Ok */0 && match$3.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      priceId: priceId,
                      priceName: priceName,
                      productBarcodeDisplayed: productBarcodeDisplayed,
                      missingProductBarcodeGenerated: missingProductBarcodeGenerated,
                      priceDisplayed: priceDisplayed,
                      producerDisplayed: producerDisplayed,
                      alcoholVolumeDisplayed: alcoholVolumeDisplayed,
                      productCode: productCode,
                      labelFormat: {
                        TAG: /* LabelSheet */0,
                        _0: labelFormat._0
                      },
                      printOffset: printOffset,
                      borderEnabled: borderEnabled,
                      sort: match$3._0
                    }
                  };
          } else {
            return {
                    TAG: /* Error */1,
                    _0: {
                      NAME: "SyntaxError",
                      VAL: "Could not decode"
                    }
                  };
          }
        }
        var labelFormat$1 = match$2._0;
        if (labelFormat$1.TAG === /* Ok */0 && match$3.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    priceId: priceId,
                    priceName: priceName,
                    productBarcodeDisplayed: productBarcodeDisplayed,
                    missingProductBarcodeGenerated: missingProductBarcodeGenerated,
                    priceDisplayed: priceDisplayed,
                    producerDisplayed: producerDisplayed,
                    alcoholVolumeDisplayed: alcoholVolumeDisplayed,
                    productCode: productCode,
                    labelFormat: {
                      TAG: /* LabelPrint */1,
                      _0: labelFormat$1._0
                    },
                    printOffset: printOffset,
                    borderEnabled: borderEnabled,
                    sort: match$3._0
                  }
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: {
                    NAME: "SyntaxError",
                    VAL: "Could not decode"
                  }
                };
        }
      }), JsonCodec.field("priceId", JsonCodec.string), JsonCodec.field("priceName", JsonCodec.string), JsonCodec.field("productBarcodeDisplayed", JsonCodec.bool), JsonCodec.field("missingProductBarcodeGenerated", JsonCodec.bool), JsonCodec.field("priceDisplayed", JsonCodec.bool), JsonCodec.field("producerDisplayed", JsonCodec.bool), JsonCodec.field("alcoholVolumeDisplayed", JsonCodec.bool), JsonCodec.field("productCode", JsonCodec.string), JsonCodec.field("labelFormat", JsonCodec.string), JsonCodec.field("printOffset", JsonCodec.$$int), JsonCodec.field("borderEnabled", JsonCodec.bool), JsonCodec.field("sort", JsonCodec.string));

function encoder(state) {
  return JsonCodec.encodeWith(state, jsonCodec);
}

function decoder(json) {
  return JsonCodec.decodeWith(json, jsonCodec);
}

function useRead(param) {
  return UserPreferences.useRead("catalog-labels-options", decoder);
}

var StateSettingsUserPreferences = {
  GlobalLabelFormat: GlobalLabelFormat,
  jsonCodec: jsonCodec,
  encoder: encoder,
  decoder: decoder,
  useRead: useRead
};

export {
  RequestProductType ,
  RequestSheetLabelsType ,
  ProductCode ,
  BarcodeCompletionRequest ,
  Sheet ,
  Print ,
  StateSettingsUserPreferences ,
}
/* endpoint Not a pure module */
