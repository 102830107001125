// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Badge from "../../resources/feedback-indicators/Badge.bs.js";
import * as React from "react";
import * as CatalogProduct from "./CatalogProduct.bs.js";

function CatalogProductStatusBadge(Props) {
  var value = Props.value;
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : "medium";
  if (value === undefined) {
    return null;
  }
  switch (value) {
    case /* Active */0 :
        return React.createElement(Badge.make, {
                    children: CatalogProduct.Status.toLabel(/* Active */0),
                    size: size,
                    variation: "success"
                  });
    case /* Inactive */1 :
        return React.createElement(Badge.make, {
                    children: CatalogProduct.Status.toLabel(/* Inactive */1),
                    size: size,
                    variation: "danger"
                  });
    case /* Archived */2 :
        return React.createElement(Badge.make, {
                    children: CatalogProduct.Status.toLabel(/* Archived */2),
                    size: size,
                    variation: "neutral"
                  });
    case /* Unarchived */3 :
        return null;
    
  }
}

var make = React.memo(CatalogProductStatusBadge);

export {
  make ,
}
/* make Not a pure module */
