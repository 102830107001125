// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as $$Image from "../../primitives/Image.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as Title from "../../resources/typography/Title.bs.js";
import * as React from "react";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Spaces from "../../resources/theme/Spaces.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import MaintenancePng from "./maintenance.png";

var imageUri = MaintenancePng;

var styles = $$StyleSheet.create({
      container: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(450)), undefined, undefined),
      image: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(186)), undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.xlarge)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(-150)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(544)), undefined, undefined),
      action: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.large)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function MaintenancePage(Props) {
  return React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.container), undefined, undefined, undefined), React.createElement($$Image.make, {
                  style: styles.image,
                  source: $$Image.fromUriSource($$Image.uriSource(imageUri))
                }), React.createElement(Stack.make, {
                  children: null,
                  space: "normal"
                }, React.createElement(Title.make, {
                      children: $$Intl.t("The dashboard is currently unavailable due to a maintenance."),
                      align: "center"
                    }), React.createElement(TextStyle.make, {
                      children: $$Intl.t("Wino will be back soon, thank you for your patience."),
                      align: "center",
                      size: "huge"
                    }), React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Button.make, Button.makeProps($$Intl.t("Refresh the page"), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                        window.location.reload();
                                      }), undefined, undefined, undefined))), Caml_option.some(styles.action), undefined, undefined, undefined))));
}

var make = MaintenancePage;

export {
  imageUri ,
  styles ,
  make ,
}
/* imageUri Not a pure module */
