// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as ApolloHelpers from "../../helpers/ApolloHelpers.bs.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = Client.gql(["mutation archiveVariant($id: ID!)  {\narchiveVariant(id: $id)  {\n__typename  \nid  \nformattedStatus  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.archiveVariant;
  var value$2 = value$1.formattedStatus;
  var tmp;
  switch (value$2) {
    case "ACTIVE" :
        tmp = "ACTIVE";
        break;
    case "ARCHIVED" :
        tmp = "ARCHIVED";
        break;
    case "INACTIVE" :
        tmp = "INACTIVE";
        break;
    case "VARIABLE" :
        tmp = "VARIABLE";
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$2
      };
  }
  return {
          archiveVariant: {
            __typename: value$1.__typename,
            id: value$1.id,
            formattedStatus: tmp
          }
        };
}

function serialize(value) {
  var value$1 = value.archiveVariant;
  var value$2 = value$1.formattedStatus;
  var formattedStatus = typeof value$2 === "object" ? value$2.VAL : (
      value$2 === "INACTIVE" ? "INACTIVE" : (
          value$2 === "ARCHIVED" ? "ARCHIVED" : (
              value$2 === "ACTIVE" ? "ACTIVE" : "VARIABLE"
            )
        )
    );
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var archiveVariant = {
    __typename: value$4,
    id: value$3,
    formattedStatus: formattedStatus
  };
  return {
          archiveVariant: archiveVariant
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var ArchiveVariantMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var ArchiveVariantMutation_useWithVariables = include.useWithVariables;

var ArchiveVariantMutation = {
  ArchiveVariantMutation_inner: ArchiveVariantMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use,
  useWithVariables: ArchiveVariantMutation_useWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["mutation unarchiveVariant($id: ID!)  {\nunarchiveVariant(id: $id)  {\n__typename  \nid  \nformattedStatus  \n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.unarchiveVariant;
  var value$2 = value$1.formattedStatus;
  var tmp;
  switch (value$2) {
    case "ACTIVE" :
        tmp = "ACTIVE";
        break;
    case "ARCHIVED" :
        tmp = "ARCHIVED";
        break;
    case "INACTIVE" :
        tmp = "INACTIVE";
        break;
    case "VARIABLE" :
        tmp = "VARIABLE";
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$2
      };
  }
  return {
          unarchiveVariant: {
            __typename: value$1.__typename,
            id: value$1.id,
            formattedStatus: tmp
          }
        };
}

function serialize$1(value) {
  var value$1 = value.unarchiveVariant;
  var value$2 = value$1.formattedStatus;
  var formattedStatus = typeof value$2 === "object" ? value$2.VAL : (
      value$2 === "INACTIVE" ? "INACTIVE" : (
          value$2 === "ARCHIVED" ? "ARCHIVED" : (
              value$2 === "ACTIVE" ? "ACTIVE" : "VARIABLE"
            )
        )
    );
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var unarchiveVariant = {
    __typename: value$4,
    id: value$3,
    formattedStatus: formattedStatus
  };
  return {
          unarchiveVariant: unarchiveVariant
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$1(id, param) {
  return {
          id: id
        };
}

var UnarchiveVariantMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var UnarchiveVariantMutation_useWithVariables = include$1.useWithVariables;

var UnarchiveVariantMutation = {
  UnarchiveVariantMutation_inner: UnarchiveVariantMutation_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  use: use$1,
  useWithVariables: UnarchiveVariantMutation_useWithVariables
};

var Raw$2 = {};

var query$2 = Client.gql(["mutation activateVariant($id: ID!)  {\nactivateVariant(id: $id)  {\n__typename  \nid  \nactive  \nformattedStatus  \n}\n\n}\n"]);

function parse$2(value) {
  var value$1 = value.activateVariant;
  var value$2 = value$1.active;
  var value$3 = value$1.formattedStatus;
  var tmp;
  switch (value$3) {
    case "ACTIVE" :
        tmp = "ACTIVE";
        break;
    case "ARCHIVED" :
        tmp = "ARCHIVED";
        break;
    case "INACTIVE" :
        tmp = "INACTIVE";
        break;
    case "VARIABLE" :
        tmp = "VARIABLE";
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$3
      };
  }
  return {
          activateVariant: {
            __typename: value$1.__typename,
            id: value$1.id,
            active: !(value$2 == null) ? value$2 : undefined,
            formattedStatus: tmp
          }
        };
}

function serialize$2(value) {
  var value$1 = value.activateVariant;
  var value$2 = value$1.formattedStatus;
  var formattedStatus = typeof value$2 === "object" ? value$2.VAL : (
      value$2 === "INACTIVE" ? "INACTIVE" : (
          value$2 === "ARCHIVED" ? "ARCHIVED" : (
              value$2 === "ACTIVE" ? "ACTIVE" : "VARIABLE"
            )
        )
    );
  var value$3 = value$1.active;
  var active = value$3 !== undefined ? value$3 : null;
  var value$4 = value$1.id;
  var value$5 = value$1.__typename;
  var activateVariant = {
    __typename: value$5,
    id: value$4,
    active: active,
    formattedStatus: formattedStatus
  };
  return {
          activateVariant: activateVariant
        };
}

function serializeVariables$2(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$2(id, param) {
  return {
          id: id
        };
}

var ActivateVariantMutation_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var include$2 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2
    });

var use$2 = include$2.use;

var ActivateVariantMutation_useWithVariables = include$2.useWithVariables;

var ActivateVariantMutation = {
  ActivateVariantMutation_inner: ActivateVariantMutation_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  use: use$2,
  useWithVariables: ActivateVariantMutation_useWithVariables
};

var Raw$3 = {};

var query$3 = Client.gql(["mutation deactivateVariant($id: ID!)  {\ndeactivateVariant(id: $id)  {\n__typename  \nid  \nactive  \nformattedStatus  \n}\n\n}\n"]);

function parse$3(value) {
  var value$1 = value.deactivateVariant;
  var value$2 = value$1.active;
  var value$3 = value$1.formattedStatus;
  var tmp;
  switch (value$3) {
    case "ACTIVE" :
        tmp = "ACTIVE";
        break;
    case "ARCHIVED" :
        tmp = "ARCHIVED";
        break;
    case "INACTIVE" :
        tmp = "INACTIVE";
        break;
    case "VARIABLE" :
        tmp = "VARIABLE";
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$3
      };
  }
  return {
          deactivateVariant: {
            __typename: value$1.__typename,
            id: value$1.id,
            active: !(value$2 == null) ? value$2 : undefined,
            formattedStatus: tmp
          }
        };
}

function serialize$3(value) {
  var value$1 = value.deactivateVariant;
  var value$2 = value$1.formattedStatus;
  var formattedStatus = typeof value$2 === "object" ? value$2.VAL : (
      value$2 === "INACTIVE" ? "INACTIVE" : (
          value$2 === "ARCHIVED" ? "ARCHIVED" : (
              value$2 === "ACTIVE" ? "ACTIVE" : "VARIABLE"
            )
        )
    );
  var value$3 = value$1.active;
  var active = value$3 !== undefined ? value$3 : null;
  var value$4 = value$1.id;
  var value$5 = value$1.__typename;
  var deactivateVariant = {
    __typename: value$5,
    id: value$4,
    active: active,
    formattedStatus: formattedStatus
  };
  return {
          deactivateVariant: deactivateVariant
        };
}

function serializeVariables$3(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$3(id, param) {
  return {
          id: id
        };
}

var DeactivateVariantMutation_inner = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3
};

var include$3 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$3,
      Raw: Raw$3,
      parse: parse$3,
      serialize: serialize$3,
      serializeVariables: serializeVariables$3
    });

var use$3 = include$3.use;

var DeactivateVariantMutation_useWithVariables = include$3.useWithVariables;

var DeactivateVariantMutation = {
  DeactivateVariantMutation_inner: DeactivateVariantMutation_inner,
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3,
  use: use$3,
  useWithVariables: DeactivateVariantMutation_useWithVariables
};

function useArchive(id) {
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.useCallback((function (param) {
                return Future.mapOk(ApolloHelpers.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                    id: id
                                  })), undefined, (function (param) {
                              return id;
                            }));
              }), [id]);
}

function useUnarchive(id) {
  var match = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.useCallback((function (param) {
                return Future.mapOk(ApolloHelpers.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                    id: id
                                  })), undefined, (function (param) {
                              return id;
                            }));
              }), [id]);
}

function useActivate(id) {
  var match = Curry.app(use$2, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.useCallback((function (param) {
                return Future.mapOk(ApolloHelpers.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                    id: id
                                  })), undefined, (function (param) {
                              return id;
                            }));
              }), [id]);
}

function useDeactivate(id) {
  var match = Curry.app(use$3, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.useCallback((function (param) {
                return Future.mapOk(ApolloHelpers.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                    id: id
                                  })), undefined, (function (param) {
                              return id;
                            }));
              }), [id]);
}

export {
  ArchiveVariantMutation ,
  UnarchiveVariantMutation ,
  ActivateVariantMutation ,
  DeactivateVariantMutation ,
  useArchive ,
  useUnarchive ,
  useActivate ,
  useDeactivate ,
}
/* query Not a pure module */
